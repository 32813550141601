import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Links } from "../../settings";
import { useAuth, usePageDescription } from "../../hooks";
import { Helmet } from "react-helmet";

export const LegalPolicy = () => {
  const { t } = useTranslation();
  const { loggedIn } = useAuth();

  const investor = loggedIn?.qualifiedInvestor;

  usePageDescription({ 
    title: t('title.legal'),
    description: t('description.legal')
  });

  return (
    <>
        <Helmet>
            <meta name='title' content={t('title.legal')} />
            <meta name='description' content={t('description.legal')} />
        </Helmet>
        <div className="flex flex-col md:flex-row lg:py-[50px] md:py-[80px] py-[50px] md:px-[100px] px-[25px] gap-[20%]">
        <p className="text-[35px] font-bold mb-[20px]">
            {t('home.footer.legal')}
        </p>

        <div className="flex flex-col gap-[15px]">
            <Link to={Links.terms.index} className="text-secondary text-[20px] font-medium hover:text-primary">
                {t('home.footer.terms_and_conditions')}
            </Link>

            <Link to={Links.privacy.index} className="text-secondary text-[20px] font-medium hover:text-primary">
                {t('home.footer.privacy_policy')}
            </Link>

            <Link to={Links.cookies.index} className="text-secondary text-[20px] font-medium hover:text-primary">
                {t('home.footer.cookie_policy')}
            </Link>

            <Link to={Links.serviceTerms.index} className="text-secondary text-[20px] font-medium hover:text-primary">
                {t('home.footer.terms_of_service')}
            </Link>

            {loggedIn && investor && (
                <>
                    <Link to={Links.investorTerms.index} className="text-secondary text-[20px] font-medium hover:text-primary">
                        {t('home.footer.terms_of_service_investors')}
                    </Link>

                    {/* <Link to={Links.broker.index} className="text-secondary text-[20px] font-medium hover:text-primary">
                        {t('home.footer.broker_terms')}
                    </Link> */}
                </>
            )}

            <Link to={Links.imprint.index} className="text-secondary text-[20px] font-medium hover:text-primary">
                {t('imprint.title')}
            </Link>

            <Link to={Links.cancellation.index} className="text-secondary text-[20px] font-medium hover:text-primary">
                {t('home.footer.cancellation')}
            </Link>
        </div>
    </div>
    </>

  )
}
