export const handleIconColor = (val: string) => {
    if (val === 'not started') {
        return 'text-[#bdbdbd]';
    }

    if (val === 'started') {
        return 'text-[#B0CC57]';
    }
    
    if (val === 'finished') {
        return 'text-[#11470E]';
    }

    return '';
}