import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
    CreateAccountingLogMutation,
    Flaeche,
    GetOrganizationbyOwnerQuery,
    GetUserInfoBySubQuery,
    UpdateFlaecheMutation,
    UpdateOrganizationMutation,
    UserInfo,
} from '../../../API';
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { getOrganizationbyOwner, getUserInfoBySub } from "../../../graphql/queries";
import { createAccountingLog, updateFlaeche, updateOrganization } from "../../../graphql/mutations";
import { useAuth } from "../../../hooks";

interface Options {
    area?: Flaeche | null;
}

export const useInvestorsList = ({ area }: Options) => {
    const [showInvestors, setShowInvestors] = useState(false);
    const [showWaitList, setShowWaitList] = useState(false);
    const [investors, setInvestors] = useState<UserInfo[]>([]);
    const [waitList, setWaitList] = useState<UserInfo[]>([]);
    const [openDeleteModal, setOpenDeleteModal] = useState<UserInfo | null>(null);

    const { loggedIn } = useAuth();

    const getUsersInfo = async (
        users: string[], 
        setUsers: Dispatch<SetStateAction<UserInfo[]>>
    ) => {
        if (users.length === 0) return;

        try {
            const userQueries = users.map(user => {
                return API.graphql<GraphQLQuery<GetUserInfoBySubQuery>>(graphqlOperation(getUserInfoBySub, {
                    sub: user
                }));
            });

            const userData = await Promise.all(userQueries);

            const userInfoArray = userData
                .map(response => response.data?.getUserInfoBySub?.items[0])
                .filter((user): user is UserInfo => !!user);

            setUsers(userInfoArray);
        } catch (error) {
            console.error('Error fetching user info:', error);
        }
    };

    const revokeInvestorAccess = (user: UserInfo) => {
        setOpenDeleteModal(user);
    }

    const cancelRevoking = () => {
        setOpenDeleteModal(null);
    }

    const approveRevoking = async () => {
        if (!openDeleteModal) return;

        try {
            const { data } = await API.graphql<GraphQLQuery<GetOrganizationbyOwnerQuery>>(
                graphqlOperation(getOrganizationbyOwner, { OwnerOfOrganization: openDeleteModal.sub})
            );

            const organization = data?.getOrganizationbyOwner?.items.find(el => !el?.disabledOrganization);

            if (area?.InvestorsWithApprovedAccess?.includes(openDeleteModal.sub)) {
                const editingArea = {
                    id: area.id,
                    InvestorsWithApprovedAccess: area?.InvestorsWithApprovedAccess.filter(el => el !== openDeleteModal.sub),
                    OrganizationsWithApprovedAccess: area.OrganizationsWithApprovedAccess?.filter(el => el !== organization?.id)
                }

                await API.graphql<GraphQLQuery<UpdateFlaecheMutation>>(
                    graphqlOperation(updateFlaeche, { input: editingArea})
                );
            }

            if (organization) {
                const filteredAccess = organization.AccessToAreaID?.filter(el => el.flaecheId !== area?.id);

                const editingOrganization = {
                    id: organization?.id,
                    AccessToAreaID: filteredAccess?.length ? filteredAccess.map(area => ({
                        flaecheId: area.flaecheId,
                        OrganizationId: organization.id,
                        userInfoSub: area.userInfoSub,
                        DateTime: area.DateTime
                    })) : null
                }

                await API.graphql<GraphQLQuery<UpdateOrganizationMutation>>(
                    graphqlOperation(updateOrganization, { input: editingOrganization })
                );
            }

            const accountingLog = {
                flaecheId: area?.id,
                userInfoSub: openDeleteModal.sub,
                OrganizationID: organization?.id ?? '',
                DateTimeUnix: Math.floor(new Date().getTime() / 1000),
                Description: `${loggedIn.sub} has removed the ${openDeleteModal.sub} from the contacts access of the ${area?.id} area`,
                ActionPerformedbyUserSub: loggedIn.sub,
                TransactionTypeAccessToContactRemoved: true
            }

            await API.graphql<GraphQLQuery<CreateAccountingLogMutation>>(
                graphqlOperation(createAccountingLog, { input: accountingLog})
            );

            const filteredInvestors = investors.filter(el => el.email !== openDeleteModal.email);
            setInvestors(filteredInvestors);
            setOpenDeleteModal(null);
        } catch (error) {
            console.log("Error:", error);
        }
    }

    useEffect(() => {
        if (area?.InvestorsWithApprovedAccess) {
            getUsersInfo(area.InvestorsWithApprovedAccess, setInvestors);
        }

        if (area?.InvestorsWaitlistForAccess) {
            getUsersInfo(area.InvestorsWaitlistForAccess, setWaitList)
        }
    }, [area])

  return {
    showInvestors,
    showWaitList,
    setShowWaitList,
    setShowInvestors,
    setInvestors,
    openDeleteModal,
    revokeInvestorAccess,
    approveRevoking,
    cancelRevoking,
    investors,
    waitList
  }
}
