import { CheckboxesSection } from './components/CheckboxesSection';
import { usePowerGrid } from './hooks/usePowerGrid';

export const PowerGrid = () => {
    const {
        handleCheckboxes,
        mapContainer,
        isOpen,
        toggleFilter,
        powerLayers,
        voltageLayers,
        railwayLayers,
        filterStates,
        setIsOpen,
    } = usePowerGrid();

    return (
        <>
            <div className="mb-[50px] mt-[150px] flex justify-center bg-white">
                <div
                    ref={mapContainer}
                    className="top=[0] left=[0] mapBox_project absolute h-[600px] w-full"
                    id="map-admin"
                >
                    <div
                        className={`absolute left-[10px] top-[10px] z-[10] h-[350px] w-[250px] rounded-[10px] bg-white p-[12px]
                    transition-transform duration-500 ease-in-out md:left-[10px] md:top-[10px] 
                    ${
                        isOpen ? 'translate-x-0' : '-translate-x-[260px]'
                    } flex flex-col`}
                    >
                        <div className="overflow-y-scroll ">
                            <CheckboxesSection
                                title="Energy Infrastructure"
                                layers={powerLayers}
                                isOpen={filterStates.energyInfrastructure}
                                toggleOpen={() =>
                                    toggleFilter('energyInfrastructure')
                                }
                                handleCheckboxes={handleCheckboxes}
                            />
                            <CheckboxesSection
                                title="Power Grid"
                                layers={voltageLayers}
                                isOpen={filterStates.powerGrid}
                                toggleOpen={() => toggleFilter('powerGrid')}
                                handleCheckboxes={handleCheckboxes}
                            />
                            <CheckboxesSection
                                title="Railway Power Grid (Europe)"
                                layers={railwayLayers}
                                isOpen={filterStates.railwayPowerGrid}
                                toggleOpen={() =>
                                    toggleFilter('railwayPowerGrid')
                                }
                                handleCheckboxes={handleCheckboxes}
                            />
                        </div>

                        <button
                            className="absolute -right-[30px] top-[0] z-10 flex h-[40px] w-[20px] items-center justify-center rounded-[5px] bg-white text-center shadow-md hover:text-primary"
                            onClick={() => setIsOpen(!isOpen)}
                            aria-label={
                                isOpen
                                    ? 'Close filter panel'
                                    : 'Open filter panel'
                            }
                        >
                            {isOpen ? '←' : '→'}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};
