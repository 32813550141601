import { useEffect, useMemo, useState } from "react";
import { GetOrganizationbyOwnerQuery, ListUserInfosQuery, UserInfo } from "../../../API";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { getOrganizationbyOwner, listUserInfos } from "../../../graphql/queries";
import { useSearch } from "../../SearchPage/hooks";

export const useUsersData = () => {
    const [users, setUsers] = useState<(UserInfo | null)[]>([]);
    const [filteredUsers, setFilteredUsers] = useState<(UserInfo | null)[]>([]);
    const [userType, setUserType] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const { 
        areas,
        getAreas
    } = useSearch({});

    const getUsers = async (filter: any) => {
        let nextToken: string | null | undefined = null;
        let allUsers: (UserInfo | null)[] = [];
    
        setIsLoading(true);
    
        const fetchUsersBatch = async () => {
            const response = await API.graphql<GraphQLQuery<ListUserInfosQuery>>(
                graphqlOperation(listUserInfos, {
                    limit: 100,
                    filter,
                    nextToken,
                })
            );
            const { items, nextToken: newNextToken } = response.data?.listUserInfos || {};
    
            allUsers = [...allUsers, ...(items || [])];
            nextToken = newNextToken;
        };
    
        const handleFetchUsers = async () => {
            await fetchUsersBatch();
            if (nextToken) {
                await handleFetchUsers();
            }
        };
    
        try {
            await handleFetchUsers();
            setUsers(allUsers);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    };
    

    const sortedUsers = useMemo(() => {
        if (!users) return;
        return users.slice().sort((a, b) => {
            if (a && b) {
                return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
            }
            return 0;
        });
    }, [users]);

    useEffect(() => {
      const filterUsers = async () => {
          if (!sortedUsers) return;

          const resolvedUsers = await Promise.all(sortedUsers.map(async (user) => {
              switch (userType) {
                  case 'admin':
                      return user?.adminCustomerService || user?.adminFull;
                  case 'investor':
                      return user?.qualifiedInvestor;
                  case 'regular user':
                  case 'regelmäßiger benutzer':
                      return !user?.qualifiedInvestor && !user?.adminCustomerService && !user?.adminFull;
                  case 'regular user with active area':
                  case 'benutzer mit aktivem bereich':
                      return areas.some(area => area?.ownerSub === user?.sub && !area?.disabled);
                  case 'user with organization':
                  case 'benutzer mit organisation':
                      const { data } = await API.graphql<GraphQLQuery<GetOrganizationbyOwnerQuery>>(
                          graphqlOperation(getOrganizationbyOwner, { OwnerOfOrganization: user?.sub})
                      );
                      const organization = data?.getOrganizationbyOwner?.items.find(el => !el?.disabledOrganization);
                      return organization || user?.MemberofOrganizationID;
                  default:
                      return user;
              }
          }));

          const filteredUsers = sortedUsers.filter((_, index) => resolvedUsers && resolvedUsers[index]);
          setFilteredUsers(filteredUsers);
      };

      filterUsers();
    }, [userType, areas, sortedUsers]);

    useEffect(() => {
        getAreas();
    }, [])

    return {
        sortedUsers,
        filteredUsers,
        setUserType,
        isLoading,
        getUsers
    }
}