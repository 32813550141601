import { i18n } from "i18next";
import { useMemo } from "react";

export const useLanguage = ({ i18n }: { i18n: i18n}) => {
    const selectedLanguage = useMemo(() => {
        return i18n.language || window.localStorage.i18n
    }, [i18n.language, window.localStorage.i18n]);

    return {
        selectedLanguage
    }
}