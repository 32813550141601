/** @format */

import { Link, NavLink } from 'react-router-dom'
import { Heading } from '../Title'
import { useTranslation } from 'react-i18next'
import { Links } from '../../types';
import { footer, socialMedias } from '../../utils/formLists';

export const Footer = (): JSX.Element => {
    const { t } = useTranslation();

    const currentYear = new Date().getFullYear();

    return (
        <div className="ml-[calc(50%-50vw)] w-screen bg-grey">
            <div className="m-auto max-w-[1448px] px-[30px] py-[81px]">
                <div className="flex flex-col justify-between gap-x-[2rem] md:flex-row lg:px-[25px]">
                    <div>
                        <NavLink to="/">
                            <img
                                src="/Enlapa logo.png"
                                alt="Enlapa Logo"
                                className="h-[25px] w-[143px]"
                            />
                        </NavLink>
                        <p className="mt-[30px] max-w-[345px] text-[#464547]">
                            {t('home.footer.enlapa_stands_for')} <br />
                            <br />
                            <br />
                            {t('home.footer.our_goal')}
                        </p>

                        <div className="mt-[30px] flex gap-x-3">
                            {socialMedias.map((icon, i) => (
                                <a 
                                    href={icon.link}
                                    target='_blank'
                                    rel="noreferrer"
                                    key={icon.name}
                                >
                                    <div
                                        key={icon.name}
                                        className="flex h-[34.91px] w-[34.91px] cursor-pointer items-center justify-center rounded-full bg-white transition duration-500 ease-in-out hover:bg-primary"
                                    >
                                        <img
                                            src={`/assets/images/illustrations/${icon.name}.svg`}
                                            alt={`Enlapa ${icon.name}`}
                                            className='w-[16px] h-[18px]'
                                        />
                                    </div>
                                </a>
                            ))}
                        </div>
                    </div>

                    <div className="flex flex-wrap md:flex-row lg:gap-x-[80px]">
                        {footer.map((section) => (
                            <div
                                key={
                                    (section.title as string) +
                                    Math.random() * 999
                                }
                                className="md:m-none flex-2 mt-[40px] w-1/2 md:flex-1"
                            >
                                <Heading
                                    title={t(section.title as string)}
                                    size="text-lg"
                                    weight="font-semibold"
                                />
                                <ul className='pr-[6px]'>
                                    {(section?.links as Links[]).map(
                                        (link: Links) => (
                                            <li
                                                key={link.name + Math.random() * 999}
                                                className="my-4 font-[400px] text-[#464547] transition duration-500 ease-in-out hover:text-primary"
                                            >
                                                <a href={link.link}>{t(link.name)}</a>
                                            </li>
                                        )
                                    )}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="bg-white px-[25px]">
                <div className="m-auto flex h-[90px] max-w-[1448px] flex-col-reverse items-center justify-center gap-y-2 px-[25px] text-[10px] text-[#bdbdbd] md:h-[70px] md:flex-row md:justify-between md:text-base">
                    <p className='text-sm'>© { currentYear } {t('home.footer.enlapa')}</p>
                    <p className='text-sm text-center'>
                        <Link to='/privacy'>{t('home.footer.privacy_policy')}</Link> •{' '}
                        <Link to='/cookies'>{t('home.footer.cookie_policy')}</Link> •{' '}
                        <Link to="/terms">{t('home.footer.terms_and_conditions')}</Link> •{' '}
                        <Link to="/legal">{t('home.footer.legal')}</Link>
                    </p>
                </div>
            </div>
        </div>
    )
}
