import { useTranslation } from "react-i18next";
import { Button } from "../../../components";

interface ConfirmButtonProps {
    onClick: () => void;
    size: number;
}

export const ConfirmButton = ({ onClick, size }: ConfirmButtonProps) => {
    const { t } = useTranslation();

    return (
        <div
            className="fixed bottom-[10px] right-[10px] z-10 flex max-w-[400px] flex-col 
                items-center gap-[20px] rounded-[12px] border-[2px] border-primary bg-white px-[15px] py-[10px]"
        >
            <p className="text-center text-[17px]">
                {t('confirm.you_entered')} {size} m2. {t('confirm.this')}
            </p>

            <Button
                color="Primary"
                textColor="text-white"
                onClick={onClick}
            >
                {t('confirm.button')}
            </Button>
        </div>
    )
}
