import { API } from "aws-amplify";
import { useAuth } from "./useAuth";
import { GraphQLQuery, graphqlOperation } from "@aws-amplify/api";
import { UpdateUserInfoMutation } from "../API";
import { updateUserInfo } from "../graphql/mutations";

export const useLastLogin = () => {
  const { loggedIn } = useAuth();

  const updateUserLastLogin = async () => {
    if (!loggedIn) return;

    const updatedUser = {
        id: loggedIn.id,
        sub: loggedIn.sub,
        lastloginDateTime: new Date()
    }

    try {
        await API.graphql<GraphQLQuery<UpdateUserInfoMutation>>(
            graphqlOperation(updateUserInfo, { input: updatedUser })
        );
    } catch (error) {
        console.log(error)
    }
  }

  return {
    updateUserLastLogin
  }
}
