import { useTranslation } from "react-i18next";
import { Close } from '@mui/icons-material';

interface SocialErrorProps {
    onClose: () => void;
}

export const SocialError = ({ onClose }: SocialErrorProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col fixed bottom-[20px] right-[20px] 
        z-10 max-w-[250px] rounded-[8px] bg-[#464547] p-[10px] text-center text-white"
    >
        <div 
            className='w-full flex justify-end cursor-pointer'
            onClick={onClose}
        >
            <Close />
        </div>
        {t('social_login_error')}
    </div>
  )
}
