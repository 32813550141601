import { useTranslation } from "react-i18next"
import { useAuth, usePageDescription } from "../../hooks";
import { Helmet } from "react-helmet";

export const BrokerTerms = () => {
    const { loggedIn } = useAuth();
    const { t } = useTranslation();

    usePageDescription({ 
      title: t('title.broker'),
      description: t('description.broker')
    });
  
    if (!loggedIn.qualifiedInvestor) {
      return <div className="flex justify-center items-center py-[90px]">
          <p className="text-[25px] font-bold">
              {t('no_access')}
          </p>
      </div>;
    }

  return (
    <>
        <Helmet>
            <meta name='title' content={t('title.broker')} />
            <meta name='description' content={t('description.broker')} />
        </Helmet>
        <div className="flex flex-col py-[50px] md:px-[100px] px-[25px]">
        <p className="md:text-[38px] text-[25px] font-bold mb-[15px] text-secondary">
            {t('home.footer.broker_terms')}
        </p>

        <div className="text-[20px]">
            <p className="font-bold">1. {t('broker.remarks')}</p>

            <p className="ml-[20px] mb-[25px]">{t('broker.remarks_desc')}</p>

            <p className="font-bold">2. {t('broker.conclusion')}</p>

            <p className="ml-[20px] mb-[25px]">2.1. {t('broker.conclusion_option_one')}</p>
            <p className="ml-[20px] mb-[25px]">2.2. {t('broker.conclusion_option_two')}</p>

            <p className="font-bold">3. {t('broker.commision')}</p>

            <p className="ml-[20px] mb-[25px]">3.1. {t('broker.commision_option_one')}</p>

            <p className="ml-[35px] mb-[25px]">3.1.1. {t('broker.commision_option_two')}</p>
            <p className="ml-[35px] mb-[25px]">3.1.2. {t('broker.commision_option_three')}</p>

            <p className="ml-[20px] mb-[25px]">3.2.  {t('broker.commision_option_four')}</p>
            <p className="ml-[20px] mb-[25px]">3.3.  {t('broker.commision_option_five')}</p>

            <p className="ml-[35px] mb-[25px]">3.3.1. {t('broker.commision_option_six')}</p>
            <p className="ml-[35px] mb-[25px]">3.3.2. {t('broker.commision_option_seven')}</p>
            <p className="ml-[35px] mb-[25px]">3.3.2. {t('broker.commision_option_eight')}</p>

            <p className="ml-[20px] mb-[25px]">3.4. {t('broker.commision_option_nine')}</p>
            <p className="ml-[20px] mb-[25px]">3.5.  {t('broker.commision_option_ten')}</p>

            <p className="font-bold">4. {t('broker.confidentiality')}</p>

            <p className="ml-[20px] mb-[25px]">{t('broker.confidentiality_desc')}</p>

            <p className="font-bold">5. {t('broker.expose')}</p>

            <p className="ml-[20px] mb-[25px]">{t('broker.expose_desc')}</p>

            <p className="font-bold">6. {t('broker.liability')}</p>

            <p className="ml-[20px] mb-[25px]">{t('broker.liability_desc')}</p>

            <p className="font-bold">7. {t('broker.mutual')}</p>

            <p className="ml-[20px] mb-[25px]">{t('broker.mutual_desc')}</p>

            <p className="font-bold">8. {t('broker.limitation')}</p>

            <p className="ml-[20px] mb-[25px]">{t('broker.limitation_desc')}</p>

            <p className="font-bold">9. {t('broker.knowledge')}</p>

            <p className="ml-[20px] mb-[25px]">{t('broker.knowledge_desc')}</p>

            <p className="font-bold">10. {t('broker.penalty')}</p>

            <p className="ml-[20px] mb-[25px]">{t('broker.penalty_desc')}</p>

            <p className="font-bold">11. {t('broker.annulment')}</p>

            <p className="ml-[20px] mb-[25px]">{t('broker.annulment_desc')}</p>

            <p className="font-bold">12. {t('broker.money')}</p>

            <p className="ml-[20px] mb-[25px]">{t('broker.money_desc')}</p>

            <p className="font-bold">13. {t('broker.agreements')}</p>

            <p className="ml-[20px] mb-[25px]">{t('broker.agreements_desc')}</p>

            <p className="font-bold">14. {t('broker.severability')}</p>

            <p className="ml-[20px] mb-[25px]">{t('broker.severability_desc')}</p>

            <p className="font-bold">15. {t('broker.law')}</p>

            <p className="ml-[20px] mb-[25px]">{t('broker.law_desc')}</p>
        </div>
    </div>
    </>

  )
}
