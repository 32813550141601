import { useTranslation } from "react-i18next";
import { usePageDescription } from "../../hooks";
import { Helmet } from "react-helmet";

export const Cookies = () => {
  const { t } = useTranslation();

  usePageDescription({ 
    title: t('title.cookie'),
    description: t('description.cookie')
  });

  return (
    <>
        <Helmet>
            <meta name='title' content={t('title.cookie')} />
            <meta name='description' content={t('description.cookie')} />
        </Helmet>
        <div className="flex flex-col py-[50px] md:px-[100px] px-[25px]">
        <h1 className="md:text-[38px] text-[25px] font-bold mb-[10px] text-secondary">
            {t('home.footer.cookie_policy')}
        </h1>

        <div className='flex flex-col justify-start w-full md:text-[20px]'>
            <p className='mb-[20px]'>
                {t('cookies.thank_you')}
            </p>

            <p className='mb-[20px]'>
                {t('cookies.this_cookie')}
            </p>

            <p className='mb-[20px]'>
                {t('cookies.questions')}
            </p>

            <p className="font-bold">
                {t('cookies.what_cookies_are')}
            </p>

            <p className='mb-[20px]'>
                {t('cookies.on_this_website')}
            </p>

            <p className="font-bold">
                {t('cookies.which_cookies')}
            </p>

            <p className='mb-[20px]'>
                {t('cookies.two_categories')}
            </p>

            <p className='mb-[20px]'>
                <span className="italic">{t('cookies.essential')}</span>
                {t('cookies.essential_desc')}
            </p>

            <p className='mb-[20px]'>
                <span className="italic">{t('cookies.optional')}</span>
                {t('cookies.optional_desc')}
            </p>

            <div className='flex flex-col gap-[15px] mb-[30px]'>
                <li> <span className="italic">{t('cookies.functional')}</span> {t('cookies.functional_desc')}</li>
                <li> <span className="italic">{t('cookies.analysis')}</span> {t('cookies.analysis_desc')}</li>
                <li> <span className="italic">{t('cookies.advertising')}</span> {t('cookies.advertising_desc')}</li>
            </div>

            <p>
                {t('cookies.above')}
            </p>

            <div className='flex flex-col gap-[15px] mb-[30px]'>
                <li>{t('cookies.above_desc1')}</li>
                <li>{t('cookies.above_desc2')}</li>
            </div>

            <p className="font-bold">
                {t('cookies.subject')}
            </p>

            <p className='mb-[20px]'>
                {t('cookies.subject_desc')}
            </p>

            <p className="font-bold">
                {t('cookies.analytics')}
            </p>

            <p className="italic">
                {t('cookies.google')}
            </p>

            <p className='mb-[20px]'>
                {t('cookies.google_desc')} {" "}
                <a 
                    target="_blank"
                    rel="noreferrer"
                    href="https://policies.google.com/privacy?hl=en-US"
                    className="text-primary "
                >
                    {t('cookies.google_link')}
                </a>
                {" "} {t('cookies.google_desc2')}
            </p>

            <div className='flex flex-col gap-[15px] mb-[30px]'>
                <li>{t('cookies.option_one')}</li>
                <li>{t('cookies.option_two')}</li>
                <li>{t('cookies.option_three')}</li>
                <li>{t('cookies.option_four')}</li>
                <li>{t('cookies.option_five')}</li>
                <li>{t('cookies.option_six')}</li>
            </div>

            <p className='mb-[20px]'>
                {t('cookies.google_end')}
            </p>

            <p className="font-bold">
                {t('cookies.online_advertising')}
            </p>

            <p className="italic">
                {t('cookies.google_ads')}
            </p>

            <p className='mb-[20px]'>
                {t('cookies.google_ads_desc')} {" "}
                <a 
                    target="_blank"
                    rel="noreferrer"
                    href="https://policies.google.com/privacy?hl=en-US"
                    className="text-primary "
                >
                    {t('cookies.link')}
                </a>
                {" "} {t('cookies.google_ads_desc2')}
            </p>

            <p className="italic">
                {t('cookies.remarketing')}
            </p>

            <p className='mb-[20px]'>
                {t('cookies.remarketing_desc')}
            </p>

            <p className='mb-[20px]'>
                {t('cookies.remarketing_desc2')}
            </p>

            <p className="italic">
                {t('cookies.tracking')}
            </p>

            <p className='mb-[20px]'>
                {t('cookies.tracking_desc')}
            </p>

            <p className="font-bold">
                {t('cookies.pixel')}
            </p>

            <p className='mb-[20px]'>
                {t('cookies.pixel_desc1')}
            </p>

            <p className='mb-[20px]'>
                {t('cookies.pixel_desc2')}
            </p>

            <p className='mb-[20px]'>
                {t('cookies.pixel_desc3')} {" "}
                <a 
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/privacy/policy/version/20220104/?paipv=0&eav=AfbVpwBpEPEySvmi2m1knhTvvmIfHixxJ9_8RJ5IRM1FpsIIfZAbytq1jw1bJkHjj64&wtsid=rdr_0w6luTHGqD3dOgV5k&_rdr"
                    className="text-primary "
                >
                    {t('cookies.facebook')}
                </a> , {" "}
                <a 
                    target="_blank"
                    rel="noreferrer"
                    href="https://help.instagram.com/155833707900388"
                    className="text-primary "
                >
                    {t('cookies.Instagram')}
                </a> {" "}

                <span>
                    {t('cookies.both')} {" "}
                </span>

                <a 
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.youtube.com/intl/ALL_uk/howyoutubeworks/our-commitments/protecting-user-data/#:~:text=We%20do%20not%20sell%20your,information%20is%20not%20for%20sale."
                    className="text-primary "
                >
                    {t('cookies.youtube')}
                </a> {" "}

                (<a 
                    target="_blank"
                    rel="noreferrer"
                    href="https://policies.google.com/privacy?hl=en-US"
                    className="text-primary "
                >
                    {t('cookies.link')}
                </a>) .
            </p>

            <p className="font-bold">
                {t('cookies.opting')}
            </p>

            <p className="italic">
                {t('cookies.opting_google')}
            </p>

            <p className='mb-[20px]'>
                {t('cookies.opting_google_gesc')} {" "}
                <a 
                    target="_blank"
                    rel="noreferrer"
                    href="http://tools.google.com/dlpage/gaoptout?hl=en"
                    className="text-primary "
                >
                    http://tools.google.com/dlpage/gaoptout?hl=en
                </a> .
            </p>

            <p className="italic">
                {t('cookies.opting_google_ads')}
            </p>

            <p className='mb-[20px]'>
                {t('cookies.opting_google_ads_desc')} {" "}
                <a 
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.google.com/settings/ads/onweb/"
                    className="text-primary "
                >
                    https://www.google.com/settings/ads/onweb/
                </a> .
            </p>

            <p className="italic">
                {t('cookies.facebook')}
            </p>

            <p className='mb-[20px]'>
                {t('cookies.opting_facebook')} {" "}
                <a 
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/settings?tab=ads"
                    className="text-primary "
                >
                    https://www.facebook.com/settings?tab=ads 
                </a> .
            </p>

            <p className="italic">
                {t('cookies.Instagram')}
            </p>

            <p className='mb-[20px]'>
                {t('cookies.opting_instagram')} {" "}
                <a 
                    target="_blank"
                    rel="noreferrer"
                    href="https://help.instagram.com/911879456838933"
                    className="text-primary "
                >
                    https://help.instagram.com/911879456838933 
                </a> .
            </p>

            <p className="italic">
                {t('cookies.youtube')}
            </p>

            <p className='mb-[20px]'>
                {t('cookies.opting_youtube')} {" "}
                <a 
                    target="_blank"
                    rel="noreferrer"
                    href=" https://www.youtube.com/howyoutubeworks/user-settings/ad-settings/"
                    className="text-primary "
                >
                    https://www.youtube.com/howyoutubeworks/user-settings/ad-settings/ 
                </a> .
            </p>

            <p className="italic">
                {t('cookies.general')}
            </p>

            <p className='mb-[20px]'>
                {t('cookies.general_desc')} {" "}
                <a 
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.youronlinechoices.com/"
                    className="text-primary "
                >
                    {t('cookies.your_online')}
                </a> , {" "}
                <a 
                    target="_blank"
                    rel="noreferrer"
                    href="https://youradchoices.ca/"
                    className="text-primary "
                >
                    {t('cookies.digital')}
                </a> , {" "}
                <a 
                    target="_blank"
                    rel="noreferrer"
                    href="https://optout.networkadvertising.org/?c=1"
                    className="text-primary "
                >
                    {t('cookies.network')}
                </a> , {" "}
                <a 
                    target="_blank"
                    rel="noreferrer"
                    href="https://youradchoices.com/"
                    className="text-primary "
                >
                    {t('cookies.adchoices')}
                </a> {" "}
                {t('cookies.and')} {" "}

                <a 
                    target="_blank"
                    rel="noreferrer"
                    href="https://youronlinechoices.eu/"
                    className="text-primary "
                >
                    {t('cookies.european')}
                </a> .
            </p>

            <p className="font-bold">
                {t('cookies.prevent')}
            </p>

            <p className="mb-[20px]">
                {t('cookies.prevent_desc')} {" "}
                <a 
                    target="_blank"
                    rel="noreferrer"
                    href="https://support.google.com/chrome/answer/95647?hl=en-GB&co=GENIE.Platform%3DDesktop"
                    className="text-primary "
                >
                    {t('cookies.chrome')}
                </a> , {" "}

                <a 
                    target="_blank"
                    rel="noreferrer"
                    href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox"
                    className="text-primary "
                >
                    {t('cookies.mozilla')}
                </a> , {" "}

                <a 
                    target="_blank"
                    rel="noreferrer"
                    href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                    className="text-primary "
                >
                    {t('cookies.microsoft')}
                </a> , {" "}

                <a 
                    target="_blank"
                    rel="noreferrer"
                    href="https://blogs.opera.com/tips-and-tricks/2023/04/clean-browser-and-remove-trackers/"
                    className="text-primary "
                >
                    {t('cookies.opera')}
                </a> , {" "}

                <a 
                    target="_blank"
                    rel="noreferrer"
                    href="https://support.apple.com/en-gb/HT201265"
                    className="text-primary "
                >
                    {t('cookies.safari')}
                </a> , {" "}

                <span>
                    {t('cookies.at_any_time')}
                </span>
            </p>

            <p className="font-bold">
                {t('cookies.change')}
            </p>

            <p className="mb-[20px]">
                {t('cookies.change_desc')}
            </p>

            <p className="font-bold">
                {t('cookies.contact')}
            </p>

            <p className="md-[20px]">
                {t('cookies.contact_desc')}
            </p>
        </div>
    </div>
    </>

  )
}
