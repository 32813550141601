import { Delete } from "@mui/icons-material";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { UserInfo } from "../../../API";

interface NotRegisteredTableProps {
    email: string;
    onUser: Dispatch<SetStateAction<string | UserInfo | null | undefined>>;
}

export const NotRegisteredTable = ({
    email,
    onUser
}: NotRegisteredTableProps) => {
    const { t } = useTranslation();

    return (
        <div className="flex border-[1px]">
            <div className="flex w-[200px] items-center justify-center border-r-[1px] py-[5px] text-[14px] [@media(max-width:580px)]:w-[80px]">
                <div className="flex flex-col flex-wrap items-center">
                    <p className="break-words text-center text-[10px] md:text-[12px] [@media(max-width:580px)]:max-w-[78px]">
                        {email}
                    </p>
                </div>
            </div>

            <div
                className="flex w-[110px] items-center justify-center 
                                            border-r-[1px] py-[5px] text-center text-[10px] md:w-[170px] md:text-[12px] [@media(max-width:580px)]:w-[80px]"
            >
                {t('organization.waiting')}
            </div>

            <div
                className="flex w-[110px] items-center justify-center 
                                            border-r-[1px] py-[5px] text-[10px] md:w-[200px] md:text-[12px] [@media(max-width:580px)]:w-[80px]"
            >
                {}
            </div>

            <div
                className="flex w-[110px] cursor-pointer items-center justify-center border-r-[1px] 
                                                py-[5px] text-[14px] text-[#8EAD44] [@media(max-width:580px)]:w-[80px]"
                onClick={() => onUser(email)}
            >
                <Delete />
            </div>
        </div>
    )
}
