import { getLinksFromPaths } from '../helpers/getLinksFromPaths';

/**
 * Relative application URL paths
 */
export const Paths = {
    index: '/',
    login: {
        index: 'login',
    },
    signup: {
        index: 'signup',
    },
    signupConfirmation: {
        index: 'signupconfirmation'
    },
    resetPassword: {
        index: 'reset-password'
    },
    profile: {
        index: 'profile',
    },
    search: {
        index: 'search'
    },
    offer: {
        index: 'offer'
    },
    offerMetaAds: {
        index: 'offer-meta-ads'
    },
    thank_you: {
        index: 'thank-you'
    },
    pricingEnterprise: {
        index: 'pricing-enterprise'
    },
    chat: {
        index: 'chat'
    },
    adminDashboard: {
        index: 'admin-dashboard'
    },
    areas: {
        index: 'your-areas'
    },
    about: {
        index: 'about-us'
    },
    vision: {
        index: 'vision'
    },
    imprint: {
        index: 'imprint'
    },
    terms: {
        index: 'terms'
    },
    privacy: {
        index: 'privacy'
    },
    cookies: {
        index: 'cookies'
    },
    legal: {
        index: 'legal'
    },
    serviceTerms: {
        index: 'terms-of-service'
    },
    contact: {
        index: 'contact-form'
    },
    investorTerms: {
        index: 'terms-investor'
    },
    cancellation: {
        index: 'cancellation-policy'
    },
    broker: {
        index: 'broker-terms'
    },
    investorGetStarted: {
        index: 'investors-get-started'
    },
    investorPricing: {
        index: 'investors-pricing'
    },
    pressContact: {
        index: 'press-contact'
    },
    compensation: {
        index: 'compensation'
    },
    feedback: {
        index: 'feedback'
    },
    ownersFAQ: {
        index: 'owners-faq'
    },
    ownerGetStarted: {
        index: 'owners-get-started'
    },
    blog: {
        index: 'blog'
    },
    jobOffer: {
        index: 'job-offer'
    },
    powerGrid: {
        index: 'power-grid'
    },
    notFound: '404',
} as const;

/**
 * Absolute application URL paths
 */
export const Links = getLinksFromPaths(Paths);