import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    EditingUserInfoSchema,
    TEditingUserInfoSchema,
    formatSignUpError,
} from '../../../helpers';
import { CompanyForm } from './CompanyForm';
import { PersonalForm } from './PersonalForm';
import { useEditInformation, useOrganizations } from '../hooks';
import { Invitation } from './Invitation';
import { MembersTable } from './MembersTable';
import { useMemo, useState } from 'react';
import { Organization, UserInfo } from '../../../API';
import { SearchCriteriaForm } from './SearchCriteria';

interface ProfileFormProps {
    user: UserInfo;
    image: File | null | string;
    onLoading: (val: boolean) => void;
    imageToUpload: File | null;
    onUser: () => void;
}

export const ProfileForm = (props: ProfileFormProps) => {
    const { user, onLoading, imageToUpload, onUser } = props;
    const { t } = useTranslation();

    const [openInvite, setIsOpenInvite] = useState(false);

    const {
        register,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm<TEditingUserInfoSchema>({
        resolver: yupResolver(EditingUserInfoSchema),
        mode: 'all',
    });

    const {
        error,
        onSubmit,
        organization,
        invitedAsOwner,
        setInvitedAsOwner,
        setOrganization,
    } = useEditInformation({
        user,
        imageToUpload,
        onLoading,
        onUser,
    });

    const {
        users,
        memberOf,
        members,
        getMembers,
        invitedIn,
        handleAcceptInvitation,
        handleDeclineInvitation,
        setUsers,
        notRegisteredUsers,
        setNotRegisteredUsers,
    } = useOrganizations({
        organization,
        user,
    });

    const invitations = useMemo(() => {
        const invites = [];

        if (invitedIn) {
            invites.push(...invitedIn);
        }

        if (invitedAsOwner) {
            invites.push(...invitedAsOwner);
        }
        return invites;
    }, [invitedIn, invitedAsOwner]);

    const acceptOwnerInvite = (org: Organization) => {
        const filteredInvites = invitedAsOwner?.filter(
            (el) => el?.id !== org.id
        );

        setInvitedAsOwner(filteredInvites);
        setOrganization(org);
    };

    const declineOwnerInvite = (org: Organization) => {
        const filteredInvites = invitedAsOwner?.filter(
            (el) => el?.id !== org.id
        );
        setInvitedAsOwner(filteredInvites);
    };

    return (
        <form className="w-full xl:w-[50%]" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-[28px] flex justify-between">
                <h1 className="text-[24px] font-medium">
                    {t('profile.personal_info')}
                </h1>

                <button
                    className="hidden whitespace-nowrap rounded-[12px] 
                        bg-[#B0CC55] px-[15px] py-[5px] text-[12px] text-white md:block"
                >
                    {t('profile.buttons.edit_profile')}
                </button>
            </div>

            {error && (
                <div className="mb-[25px] flex">
                    <span className="mt-1 mt-[10px] block text-red-400">
                        {t(formatSignUpError(String(error)))}
                    </span>
                </div>
            )}

            <PersonalForm
                register={register}
                setValue={setValue}
                user={user}
                errors={errors}
            />

            <CompanyForm
                register={register}
                setValue={setValue}
                organization={organization}
                errors={errors}
                openInvite={openInvite}
                onOpenInvite={setIsOpenInvite}
                memberOf={memberOf}
                invited={invitations}
                onInvite={setUsers}
                onNotRegistered={setNotRegisteredUsers}
            />

            {!memberOf && !invitations?.length && (
                <MembersTable
                    members={members}
                    users={users}
                    notRegistered={notRegisteredUsers}
                    onNotRegistered={setNotRegisteredUsers}
                    onUsers={setUsers}
                    onOpenInvite={setIsOpenInvite}
                    organization={organization}
                    getMembers={getMembers}
                />
            )}

            {((invitations && invitations.length > 0) || organization) && (
                <hr className="mt-[20px]" />
            )}

            <div className="mt-[20px]">
                {invitations?.map((org) => (
                    <Invitation
                        key={org?.id}
                        organization={org}
                        user={user}
                        members={members}
                        userCompany={organization}
                        onAccept={handleAcceptInvitation}
                        onDecline={handleDeclineInvitation}
                        onAcceptOwner={acceptOwnerInvite}
                        onDeclineOwner={declineOwnerInvite}
                    />
                ))}
            </div>

            <div className="mt-[25px] w-full px-[20px] mb-[50px]">
                <button
                    className="block w-full whitespace-nowrap rounded-[12px] 
                        bg-[#B0CC55] px-[15px] py-[10px] text-[12px] text-[15px] text-white md:hidden"
                >
                    {t('profile.buttons.edit_profile')}
                </button>
            </div>

            <SearchCriteriaForm user={user} />
        </form>
    );
};
