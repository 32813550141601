import { Splide, SplideSlide } from '@splidejs/react-splide'
import { Flaeche } from '../../../API'
import { Card } from '../../../components'
import { SliderPropsMock } from '../../../types'
import { useAuth } from '../../../hooks'

interface LandsSliderProps {
    images: string[]
    lands: (Flaeche | null)[] | SliderPropsMock[]
    fetchImages: (id?: string | undefined) => Promise<void>
}

export const LandsSlider = (props: LandsSliderProps) => {
    const { lands, images, fetchImages } = props
    const { loggedIn } = useAuth()

    return (
        <div className="hidden w-[97%] md:block">
            <Splide
                options={{
                    type: 'slide',
                    perPage: 4,
                    gap: 20,
                    pagination: false,
                    arrows: false,
                    breakpoints: {
                        1000: {
                            perPage: 3,
                            gap: 10,
                        },
                    },
                }}
            >
                {lands.map((land, i) =>
                    land ? (
                        <SplideSlide key={`roof-${i}`}>
                            <Card
                                key={`roof-${i}`}
                                land={land}
                                image={
                                    loggedIn
                                        ? images.find(
                                              (image) =>
                                                  image.includes(land.id) &&
                                                  (image.includes(
                                                      'Screenshot-300x350.webp'
                                                  ) ||
                                                      image.includes(
                                                          'Screenshot'
                                                      ))
                                          ) ?? ''
                                        : 'img' in land
                                        ? land.img
                                        : ''
                                }
                                fetchImages={fetchImages}
                            />
                        </SplideSlide>
                    ) : null
                )}
            </Splide>
        </div>
    )
}
