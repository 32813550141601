import { useTranslation } from 'react-i18next';
import { AreaProperty } from '../../../types';
import { descBlocks } from '../../../utils/formLists';
import { useState } from 'react';
import { Wrapper } from './Wrapper';
import { FormHeader } from './FormHeader';

interface AreaPropertiesProps {
    onSelect: (val: AreaProperty) => void;
    selected: AreaProperty | null;
}

export const AreaProperties = (props: AreaPropertiesProps) => {
    const { t } = useTranslation();
    const { onSelect, selected } = props;

    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
        setIsOpen(prev => !prev);
    }

    return (
        <div className="mb-[30px] flex w-full flex-col justify-start md:mb-[60px]">
            <div 
                className="mb-[15px] flex justify-between cursor-pointer px-[25px] md:px-[80px] xl:px-[180px] items-start" 
                onClick={handleOpen}
            >
                <FormHeader title={t('addArea.description_title')} />

                <img
                    src="/assets/icons/arrowDown.svg"
                    alt="arrow"
                    className={`cursor-pointer ${isOpen ? 'rotate-180' : ""}`}
                />
            </div>

            {!isOpen && (
                <Wrapper />
            )}

            {isOpen && (
                <>
                    <div className='px-[25px] md:px-[80px] xl:px-[180px]'>
                        <p className="mb-[20px] max-w-[545px] text-[16px] font-normal text-[#9e9e9e] md:mb-[25px]">
                            {t('addArea.description_text')}
                        </p>
                    </div>

                    <div className="flex flex-wrap justify-center gap-[9px] xl:flex-nowrap px-[25px] md:px-[80px] xl:px-[80px]">
                        {descBlocks.map((el) => (
                            <div
                                className={`border-box flex h-[163px] w-[160px] flex-col items-center rounded-[10px] px-[10px] py-[15px] text-center md:h-[200px] md:w-[220px] md:py-[30px]
                                ${
                                    selected?.id === el.id
                                        ? 'border-[2px] border-[#8EAD44]'
                                        : 'border-[1px] border-[#111A2126]'
                                }
                            `}
                                key={el.id}
                                onClick={() => onSelect(el)}
                            >
                                <img src={el.image} alt="block" className="mb-[20px]" />
                                <p className="text-center text-[12px] font-medium md:text-[16px]">
                                    {t(el.title)}
                                </p>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    )
}
