import { useTranslation } from "react-i18next";
import { AreaCard } from "../../SearchPage/components/AreaCard";
import { Button } from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import { Links } from "../../../settings";
import { useCallback, useState } from "react";
import { Loader } from "../../../components/Loader";
import { OrganizationCard } from "./OrganizationCard";
import { Flaeche, UserInfo } from "../../../API";

interface OfferedSpacesProps {
  title: string;
  selected?: boolean;
  areas?: (Flaeche | null | undefined)[];
  images?: string[];
  loading?: boolean;
  user?: UserInfo;
  withOwner?: boolean;
  onDeactivate?: (id: string | undefined) => void;
  onRemoveFavorite?: (id: string | undefined) => void;
  fetchImages: (id?: string | undefined) => Promise<void>;
}

export const OfferedSpaces = (props: OfferedSpacesProps) => {
  const { 
    title, 
    selected, 
    areas, 
    images, 
    loading, 
    onDeactivate, 
    onRemoveFavorite,
    fetchImages,
    withOwner
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [itemsToShow, setItemsToShow] = useState(12);

  const handleNavigate = useCallback((link: string) => {
    navigate(link);
  }, [navigate])

  const handleLoadMore = () => {
    setItemsToShow(prev => prev + 8);
  };

  return (
    <div className="flex flex-col justify-center items-center">
      <h1 className="font-medium lg:text-[38px] text-[32px] mb-[20px] text-center">
        {title}
      </h1>

      {loading ? (
        <Loader />
      ) : areas && areas.length > 0 ? (
        <>
          <div className="flex justify-center favorite-projects">
              <div className="md:flex-row flex flex-col gap-[20px] flex-wrap justify-start mb-[30px]">
                {areas.slice(0, itemsToShow).map((area?: Flaeche | null) =>
                  area ? (
                    !withOwner ? (
                      <AreaCard
                        key={area.id}
                        item={area}
                        image={images?.find((image) => image.includes(area.id) 
                          && (image.includes('Screenshot-300x350.webp') || image.includes('Screenshot'))) 
                          ?? ""}
                        selected={selected}
                        onDeactivate={onDeactivate}
                        onRemoveFavorite={onRemoveFavorite}
                        fetchImages={fetchImages}
                      />
                    ) : (
                      <OrganizationCard
                        key={area.id}
                        item={area}
                        image={images?.find((image) => image.includes(area.id) 
                          && (image.includes('Screenshot-300x350.webp') || image.includes('Screenshot'))) 
                          ?? ""}
                        onDeactivate={onDeactivate}
                        fetchImages={fetchImages}
                      />
                    )
                  ) : null
                )}
              </div>
          </div>

          {areas.length > itemsToShow && (
            <div className="flex w-full justify-center mb-[60px]">
              <button
                className="rounded-[10px] bg-[#11470E] px-[79px] py-[14px] text-white md:px-[44px] md:py-[17px]"
                onClick={handleLoadMore}
              >
                {t('search.load_more')}
              </button>
            </div>
          )}
        </>
      ) : title === t('profile.offered') ? (
        <Button
          color="Primary"
          textColor="text-white"
          onClick={() => handleNavigate(Links.offer.index)}
        >
          {t('buttons.offer_spaces')}
        </Button>
      ) : (
        <Button color="Secondary" onClick={() => handleNavigate(Links.search.index)}>
          {t('buttons.search')}
        </Button>
      )}
    </div>
  )
}

