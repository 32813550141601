import { useNavigate } from 'react-router-dom';
import { CallToAction } from '../../../components';
import { Fragment, useCallback, useState } from 'react';
import { Links } from '../../../settings';
import { ArticleImage } from './Image';
import { useTranslation } from 'react-i18next';
import { ArticleQuestions } from '../../../utils/formLists';

export const TenArticle = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState<number[]>([]);

    const handleNavigate = useCallback(() => {
        navigate(Links.offer.index);
    }, [navigate])

    const handleOpen = (id: number) => {
        if (!isOpen.includes(id)) {
            setIsOpen(prev => [...prev, id]);
            return;
        }
    
        const filteredQuestions = isOpen.filter(question => question !== id);
        setIsOpen(filteredQuestions);
    }

    return (
        <div className="mb-[20px] flex flex-col gap-[25px] text-[16px] text-[#333] md:text-[18px]">
            <span className="uppercase text-primary">{t('articles.info')}</span>

            <h1 className="text-[25px] font-medium md:text-[35px]">
                {t('article_ten.title')}
            </h1>

            <div className="h-[2px] w-[150px] bg-primary" />

            <span className="text-[13px] uppercase text-gray-700 md:text-[16px]">
                {t('articles.article_ten.date')}
            </span>

            <p>{t('article_ten.p_1')}</p>

            <p>{t('article_ten.p_2')}</p>

            <p>{t('article_ten.p_3')}</p>

            <p>{t('article_ten.p_4')}</p>

            <div className='flex gap-[15px] md:flex-row flex-col'>
                <ArticleImage source="/assets/images/tenArticle2-620.png" />
                <ArticleImage source="/assets/images/tenArticle-620.png" />
            </div>

            <p className="text-[13px] font-medium text-gray-500">
                {t('article_ten.project_example')}
            </p>

            <p>{t('article_ten.p_5')}</p>

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_ten.subtitle_1')}
            </h2>

            <p>{t('article_ten.p_6')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_ten.subtitle_2')}
            </h3>
            <p>{t('article_ten.p_7')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_ten.subtitle_3')}
            </h3>
            <p>{t('article_ten.p_8')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_ten.subtitle_4')}
            </h3>
            <p>{t('article_ten.p_9')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_ten.subtitle_5')}
            </h3>
            <p>{t('article_ten.p_10')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_ten.subtitle_6')}
            </h3>

            <p>{t('article_ten.p_11')}</p>
            <p>{t('article_ten.p_12')}</p>

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_ten.subtitle_7')}
            </h2>

            <p>{t('article_ten.p_13')}</p>

            <p>{t('article_ten.p_14')}</p>

            <li className="font-bold">{t('article_ten.point_1')}</li>

            <p>{t('article_ten.p_15')}</p>

            <li className="font-bold">{t('article_ten.point_2')}</li>

            <p>{t('article_ten.p_16')}</p>

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_ten.subtitle_8')}
            </h2>

            <p>{t('article_ten.p_17')}</p>

            <p>{t('article_ten.p_18')}</p>

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_ten.subtitle_9')}
            </h2>

            <p>{t('article_ten.p_19')}</p>

            <p>{t('article_ten.p_20')}</p>

            <p>{t('article_ten.p_21')}</p>

            <p>
                <span className="text-bold">{t('article_ten.bold_1')}</span>{' '}
                {t('article_ten.p_22')}
            </p>

            <p>
                <span className="text-bold">{t('article_ten.bold_2')}</span>{' '}
                {t('article_ten.p_23')}
            </p>

            <p>{t('article_ten.p_24')}</p>

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_ten.subtitle_10')}
            </h2>

            <p>{t('article_ten.p_25')}</p>

            <div className="border-[2px] border-[#E8E8E8] rounded-[12px]">
                {ArticleQuestions.map(el => (
                    <Fragment key={el.id}>
                        <div 
                            className="flex justify-between gap-[10px] cursor-pointer items-start px-[15px] py-[30px]" 
                            onClick={() => handleOpen(el.id)}
                        >
                            <p className="font-medium md:text-[18px] text-[16px]">{t(el.question)}</p>
                            <img
                                src={`${isOpen.includes(el.id) ? "/assets/icons/minus.svg" : "/assets/icons/plus.svg"}`}
                                alt="arrow"
                                className="cursor-pointer"
                            />
                        </div>

                        {isOpen.includes(el.id) && (
                            <p className="text-[15px] mb-[20px] px-[15px]">{t(el.answer)}</p>
                        )}

                        {el.id !== ArticleQuestions[ArticleQuestions.length - 1].id && (
                            <hr className="h-[2px] w-full bg-gray-50" />
                        )}
                    </Fragment>
                ))}
            </div>

            <p>{t('article_ten.p_31')}</p>

            <p className='font-medium'>{t('article_ten.p_32')}</p>

            <div className="mx-[25px] text-[16px] md:mt-[50px]">
                <CallToAction
                    title={t('home.call_to_action_money')}
                    btnTitle={t('buttons.offer_your_space_now')}
                    bgColor="bg-secondary text-white"
                    onClick={handleNavigate}
                    tag="p"
                />
            </div>
        </div>
    )
}
