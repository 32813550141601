import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface AcceptInviteModalProps {
    openModal: string;
    onAccept: () => void;
    onOpenModal: (val: string) => void;
}

export const AcceptInviteModal = ({
    openModal,
    onAccept,
    onOpenModal
}: AcceptInviteModalProps) => {
  const { t } = useTranslation();

  return (
    <Box className="fixed right-0 top-0 z-10 flex h-full w-full items-center justify-center px-[10px]">
        <div className="z-10 rounded-[8px] bg-[#dcedc8] px-[20px] py-[15px] text-center text-secondary">
            <p className="mb-[20px] max-w-[400px] text-[17px]">
                {t(openModal)}
            </p>

            <div className="flex w-full justify-center gap-[10px]">
                <button
                    className="rounded-[8px] bg-secondary px-[10px] py-[5px] text-white"
                    onClick={onAccept}
                    type="button"
                >
                    {t('organization.acc')}
                </button>

                <button
                    className="rounded-[8px] bg-white px-[10px] py-[5px] text-secondary"
                    onClick={() => onOpenModal('')}
                    type="button"
                >
                    {t('organization.cancel')}
                </button>
            </div>
        </div>
    </Box>
  )
}