import { useTranslation } from 'react-i18next';
import { Flaeche, UserInfo } from '../../../API';
import { AreaSize } from '../../../components';
import { useInvestorsList } from '../hooks';
import { DeleteModal } from './DeleteModal';

interface AreaInfoProps {
    area?: Flaeche | null;
    owner: UserInfo | null;
}

export const AreaInfo = ({ area, owner }: AreaInfoProps) => {
    const { t } = useTranslation();

    const {
        investors,
        waitList,
        showInvestors,
        setShowWaitList,
        setShowInvestors,
        showWaitList,
        openDeleteModal,
        approveRevoking,
        cancelRevoking,
        revokeInvestorAccess
    } = useInvestorsList({ area });

    return (
        <>
            <div className="mb-[20px] flex flex-col gap-[10px]">
                <div className="flex gap-[5px]">
                    <p className="text-[15px] font-medium text-secondary">
                        {t('admin_dashboard.name')}:
                    </p>
                    <p className="text-[15px]">{area?.areaName ? t(area?.areaName) : ''}</p>
                </div>

                <div className="flex gap-[5px]">
                    <p className="text-[15px] font-medium text-secondary">
                        {t('admin_dashboard.type')}:
                    </p>
                    <p className="text-[15px]">{t(area!.areaType)}</p>
                </div>

                <div className="flex gap-[20px]">
                    <div className="flex gap-[5px]">
                        <p className="text-[15px] font-medium text-secondary">
                            {t('admin_dashboard.size')}:
                        </p>
                        <p className="text-[15px]">
                            <AreaSize areaDetails={area} />
                        </p>
                    </div>

                    <div className="flex gap-[5px]">
                        <p className="text-[15px] font-medium text-secondary">
                            {t('admin_dashboard.country')}:
                        </p>
                        <p className="text-[15px]">
                            {t(
                                area!.address_country
                                    ? area!.address_country
                                    : ''
                            )}
                        </p>
                    </div>
                </div>

                <div className="flex flex-wrap gap-[20px]">
                    <div className="flex gap-[5px]">
                        <p className="text-[15px] font-medium text-secondary">
                            {t('admin_dashboard.city')}:
                        </p>
                        <p className="text-[15px]">
                            {t(area!.address_city ? area!.address_city : '')}
                        </p>
                    </div>

                    <div className="flex gap-[5px]">
                        <p className="text-[15px] font-medium text-secondary">
                            {t('admin_dashboard.state')}:
                        </p>
                        <p className="text-[15px]">
                            {t(area!.address_state ? area!.address_state : '')}
                        </p>
                    </div>
                </div>

                <div className="flex gap-[5px]">
                    <p className="text-[15px] font-medium text-secondary">
                        {t('admin_dashboard.code')}:
                    </p>
                    <p className="text-[15px]">{t(area!.address_postcode)}</p>
                </div>

                <div 
                    className="flex gap-[5px]" 
                    onMouseMove={() => setShowInvestors(true)}
                    onMouseLeave={() => setShowInvestors(false)}
                >
                    <p className="text-[15px] font-medium text-secondary">
                        {t('admin_dashboard.access')}:
                    </p>
                    <div className='relative'>
                        <p className="text-[15px]">
                            {investors.length || 0}
                        </p>

                        {showInvestors && investors.length > 0 && (
                            <div className="absolute right-[1px] bg-white shadow-md rounded-[8px] p-[5px] z-10">
                                {investors.map(user => (
                                    <div className="flex gap-[10px] mb-[10px]">
                                    <p>{user?.email}</p>

                                    <button 
                                        className="text-[10px] bg-red-600 rounded-[5px] 
                                            text-white px-[4px]"
                                        onClick={() => revokeInvestorAccess(user)}
                                    >
                                        {t('admin_dashboard.revoke')}
                                    </button>
                                </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>

                <div className="flex gap-[5px]">
                    <p className="text-[15px] font-medium text-secondary">
                        {t('admin_dashboard.waitlist')}:
                    </p>
                    <div 
                        className='relative'
                        onMouseMove={() => setShowWaitList(true)}
                        onMouseLeave={() => setShowWaitList(false)}
                    >
                        <p className="text-[15px]">
                            {area?.InvestorsWaitlistForAccess?.length || 0}
                        </p>

                        {showWaitList && waitList.length > 0 && (
                            <div className="absolute right-[1px] bg-white shadow-md rounded-[8px] p-[5px] z-10">
                                {waitList.map(user => (
                                    <p key={user.id}>{user.email}</p>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="mb-[25px] flex gap-[5px]">
                <p className="text-[15px] font-medium text-secondary">{t('admin_dashboard.owner')}:</p>
                <p className="text-[15px]">{owner?.email}</p>
            </div>

            {openDeleteModal && (
                <DeleteModal
                    text='organization.sure'
                    email={openDeleteModal.email}
                    onApproveDelete={approveRevoking}
                    onCancelDelete={cancelRevoking}
                />
            )}
        </>
    )
}
