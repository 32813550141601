import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useTranslation } from "react-i18next";
import { slides } from "../../../utils/formLists";
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

export const EnergySlider = () => {
    const { t } = useTranslation();

    return (
        <div className="mt-[60px] lg:mt-[70px]">
            <h2 className="text-sm uppercase">
                {t('home.energy_revolution.benefits_of_lease')}
            </h2>
            
            <hr className="mb-[30px] mt-[26px]" />

            <div className="relative">
                <Splide
                    options={{
                        classes: {
                            next: 'splide__arrow--next arrow-next',
                        },
                        perPage: 3,
                        perMove: 1,
                        type: 'loop',
                        pagination: false,
                        arrows: true,
                        fixedWidth: 'fit-content',
                        gap: 20,
                        breakpoints: {
                            1000: {
                                perPage: 1,
                                fixedWidth: '100%',
                            },
                        },
                    }}
                >
                    {slides.map((slide, i) => (
                        <SplideSlide key={slide}>
                            <div
                                key={slide}
                                className="flex items-center justify-center sm:ml-[50px]"
                            >
                                <span className="whitespace-nowrap text-sm font-medium text-primary">
                                    0{i + 1} /
                                </span>
                                <span className="text-wrap ml-2.5 text-[14px] sm:whitespace-nowrap sm:text-[20px] md:text-[26px]">
                                    {t(slide)}
                                </span>
                            </div>
                        </SplideSlide>
                    ))}
                </Splide>
            </div>
        </div>
    )
}
