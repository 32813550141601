import { TFunction } from "i18next";
import { PDFDocument, PDFPage, rgb } from "pdf-lib";
import { ProgressArea } from "../types";
import i18n from "../i18n";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { GetUserInfoBySubQuery } from "../API";
import { getUserInfoBySub } from "../graphql/queries";

const FONT_SIZE = 10;
const RIGHT_SPACE = 50;

const checkAreaProgress = (
    t: TFunction<"translation", undefined>,
    active?: ProgressArea[], 
    completed?: ProgressArea[]
) => {
    const checkProgress = (name: string) => {
        if (active?.some(el => t(el.title) === t(name))) {
            return 'started';
        }
    
        if (completed?.some(el => t(el.title) === t(name))) {
            return 'finished';
        }
    
        return 'not started';
    }

    const agreement = checkProgress('addArea.planning_process_items.agreement.title');
    const contract = checkProgress('addArea.planning_process_items.contract.title');
    const grid = checkProgress('addArea.planning_process_items.grid_connection.title');
    const approvalRequest = checkProgress('addArea.planning_process_items.approval_request.title');
    const approvalGrant = checkProgress('addArea.planning_process_items.approval_grant.title');
    const plan = checkProgress('addArea.planning_process_items.plan.title');
    const building = checkProgress('addArea.planning_process_items.building.title');

    return {
        agreement,
        contract,
        grid,
        approvalGrant,
        approvalRequest,
        plan,
        building
    }
}

const addAreaDetails = (
    page: PDFPage, 
    title: string, 
    value: string | number, 
    space: number, 
    height: number, 
    position: number,
    maxWidth: number
) => {
    const titleX = RIGHT_SPACE;
    let titleY = height - position * FONT_SIZE;

    const textX = space;
    let textY = height - position * FONT_SIZE;

    page.drawText(title, { x: titleX, y: titleY, size: FONT_SIZE, color: rgb(0, 0.3, 0) });
    titleY -= FONT_SIZE; 

    const lines = [];

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) return position;

    ctx.font = `${FONT_SIZE}px Helvetica`;

    let line = '';

    for (let i = 0; i < value.toString()?.length; i++) {
        const char = value.toString().replace(/\n/g, ' ')[i];
        const testLine = line + char;
        const width = ctx.measureText(testLine).width;

        if (width <= maxWidth - textX) {
            line = testLine;
        } else {
            lines.push(line);
            line = char;
        }
    }

    lines.push(line);

    lines.forEach((line) => {
        page.drawText(line.trim(), { x: textX, y: textY, size: FONT_SIZE });
        textY -= FONT_SIZE;
    });

    return lines.length ? position + lines.length + 0.5 : position + 1.5;
}


const addOwnerDetails = (page: PDFPage, title: string, value: string, space: number, height: number, position: number,) => {
    page.drawText(`${title}`, { x: 370, y: height - position * FONT_SIZE, size: FONT_SIZE, color: rgb(0, 0.3, 0)});
    page.drawText(`${value}`, { x: space, y: height - position * FONT_SIZE, size: FONT_SIZE });
}

const drawImage = async (page: PDFPage, pdfDoc: PDFDocument, link: string, title: string, height: number, position: number) => {
    const image = await fetch(`/assets/images/${link}.png`).then((response) => response.arrayBuffer());

    const icon = await pdfDoc.embedPng(image);

    page.drawImage(icon, { x: RIGHT_SPACE, y: height - position * FONT_SIZE, width: 14, height: 14 });

    page.drawText(`${title}`, { x: 80, y: height - position * FONT_SIZE, size: FONT_SIZE });
}

export const createPdf = async (
    sub: string,
    data: any, 
    t: TFunction<"translation", undefined>, 
    file: File | null, 
    selectedCriteria?: string | undefined, 
    active?: ProgressArea[], 
    completed?: ProgressArea[]
) => {
    const {
        agreement,
        contract,
        grid,
        approvalGrant,
        approvalRequest,
        plan,
        building
    } = checkAreaProgress(t, active, completed)

    const { data: userData } = await API.graphql<GraphQLQuery<GetUserInfoBySubQuery>>(graphqlOperation(getUserInfoBySub, {
        sub,
    }));

    const user = userData?.getUserInfoBySub?.items[0];

    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage();
    const { height } = page.getSize();

    const selectedLanguage = i18n.language || window.localStorage.i18n;

    const imageBytes = await fetch('/Enlapa logo.png').then((response) => response.arrayBuffer());

    const logoImage = await pdfDoc.embedPng(imageBytes);

    page.drawImage(logoImage, { x: 200, y: height - 4 * FONT_SIZE, width: 150, height: 30 });

    const linkText = 'https://www.enlapa.de';

    page.drawText(linkText, {
        x: 230,
        y: height - 5.5 * FONT_SIZE,
        size: FONT_SIZE,
        color: rgb(0, 0, 0.3),
    });

    page.drawText(t('pdf.details'), { x: selectedLanguage === 'en' ? 250 : 260, y: height - 7.5 * FONT_SIZE, size: 12, color: rgb(0, 0.4, 0) });

    let currentPosition = 11.5;

    currentPosition = addAreaDetails(page, `${t('addArea.placeholders.type')}:`, t(data.type), 120, height, currentPosition, 500);
    currentPosition = addAreaDetails(page, `${t('addArea.placeholders.size')}:`, data.size, selectedLanguage === 'en' ? 150 : 160, height, currentPosition, 500);
    currentPosition = addAreaDetails(page, t('pdf.country'), t(data.country), selectedLanguage === 'en' ? 100 : 80, height, currentPosition, 500);
    currentPosition = addAreaDetails(page, t('pdf.state'), t(data.state), selectedLanguage === 'en' ? 120 : 110, height, currentPosition, 500);
    currentPosition = addAreaDetails(page, t('pdf.city'), data.city, 80, height, currentPosition, 500);
    currentPosition = addAreaDetails(page, `${t('addArea.placeholders.postal_code')}:`, data.postalCode, 110, height, currentPosition, 500);
    currentPosition = addAreaDetails(page, t('pdf.street'), data.street, 90, height, currentPosition, 500);
    currentPosition = addAreaDetails(page, t('pdf.municipality'), data.municipality ? data.municipality : '', selectedLanguage === 'en' ? 110 : 100, height, currentPosition, 500);
    currentPosition = addAreaDetails(page, t('area.desc') + ':', data.moreInfo, selectedLanguage === 'en' ? 110 : 120, height, currentPosition, 550);
    currentPosition = addAreaDetails(page, t('pdf.looking_for'), t(data.forMySpace), selectedLanguage === 'en' ? 188 : 185, height, currentPosition, 500);
    currentPosition = addAreaDetails(page, t('pdf.role'), t(data.role), selectedLanguage === 'en' ? 78 : 78, height, currentPosition, 500);
    currentPosition = addAreaDetails(page, t('pdf.criteria'), t(selectedCriteria ? selectedCriteria : ''), selectedLanguage === 'en' ? 90 : 100, height, currentPosition, 500);

    page.drawText(t('pdf.progress'), { x: 50, y: height - (currentPosition + 1) * FONT_SIZE, size: FONT_SIZE, color: rgb(0, 0.4, 0) });
    page.drawText(t('pdf.contacts'), { x: 370, y: height - (currentPosition + 1) * FONT_SIZE, size: FONT_SIZE, color: rgb(0, 0.4, 0) });

    await drawImage(page, pdfDoc, 'agreement', `${t('addArea.planning_process_items.agreement.title')}: ${t(agreement)}`, height, currentPosition + 3);
    addOwnerDetails(page, t('pdf.name'), `${user?.firstName} ${user?.lastName}` ?? '', selectedLanguage === 'en' ? 420 : 465, height, currentPosition + 3);
    await drawImage(page, pdfDoc, 'leaseContract', `${t('addArea.planning_process_items.contract.title')}: ${t(contract)}`, height, currentPosition + 5);
    addOwnerDetails(page, `${t('placeholders.email')}:`, user?.email ?? '', 445, height, currentPosition + 5);
    await drawImage(page, pdfDoc, 'grid', `${t('addArea.planning_process_items.grid_connection.title')}: ${t(grid)}`, height, currentPosition + 7);
    addOwnerDetails(page, t('pdf.phone'), user?.phone ?? '', 445, height, currentPosition + 7);
    await drawImage(page, pdfDoc, 'planning', `${t('addArea.planning_process_items.approval_request.title')}: ${t(approvalRequest)}`, height, currentPosition + 9);
    await drawImage(page, pdfDoc, 'buildingPermit', `${t('addArea.planning_process_items.approval_grant.title')}: ${t(approvalGrant)}`, height, currentPosition + 11);
    await drawImage(page, pdfDoc, 'construction', `${t('addArea.planning_process_items.plan.title')}: ${t(plan)}`, height, currentPosition + 13);
    await drawImage(page, pdfDoc, 'project', `${t('addArea.planning_process_items.building.title')}: ${t(building)}`, height, currentPosition + 15);

    if (file) {
        try {
            const imageBuffer = await file.arrayBuffer();
            
            const image = await pdfDoc.embedJpg(imageBuffer);
            page.drawImage(image, { x: 47, y: height - (currentPosition + 50) * FONT_SIZE, width: 510, height: 300 });
        } catch (error) {
            console.error(`Error processing image: ${error}`);
        }
    }

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const pdfFile = new File([blob], 'area_details.pdf', { type: 'application/pdf' });

    return pdfFile;
};