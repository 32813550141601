import { MenuItem, TextField } from '@mui/material';
import { useStyles } from '../../../styles/jss/useStyles';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useEffect, useState } from 'react';
import {
    DeepRequired,
    FieldErrorsImpl,
    FieldValues,
    Path,
    SetFieldValue,
    UseControllerProps,
    UseFormRegister,
} from 'react-hook-form';
import { DropDownOptions } from '../../../types';

type TextFieldProps<T extends FieldValues> = {
    label: string;
    value?: string | null;
    register: UseFormRegister<T>;
    name: Path<T>;
    multiline?: boolean;
    type?: 'dropdown';
    options?: DropDownOptions[] | Record<string, string>[];
    setValue?: SetFieldValue<T>;
    error?: FieldErrorsImpl<DeepRequired<T>>[string] | undefined;
} & UseControllerProps<T>;

export const TextFieldProfile = <T extends FieldValues>(
    props: TextFieldProps<T>
) => {
    const {
        label,
        value,
        register,
        name,
        type,
        multiline,
        options,
        setValue,
        error,
    } = props;

    const classes = useStyles();
    const { t } = useTranslation();
    const [inputValue, setInputValue] = useState('');

    const handleChange = (
        event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
        setInputValue(event.target.value);

        if (value && setValue) {
            setValue(name, event.target.value);
        }
    };

    useEffect(() => {
        if (value && setValue) {
            setInputValue(value);
            setValue(name, value);
        }
    }, [name, value, setValue, t]);

    let input = (
        <label className="flex w-full flex-col gap-[15px] text-[14px] text-[#9e9e9e]">
            {label}
            <TextField
                {...register(name)}
                variant="standard"
                value={inputValue || ''}
                className={`text-[18px] text-[#111111] ${classes.root}`}
                multiline={multiline}
                onChange={handleChange}
                inputProps={{
                    sx: {
                        paddingBottom: '10px',
                    },
                }}
                error={Boolean(error)}
            />
        </label>
    );

    if (type === 'dropdown') {
        input = (
            <label className="flex flex-col gap-[15px] text-[14px] text-[#9e9e9e]">
                {label}
                <TextField
                    {...register(name)}
                    id="outlined-size-small"
                    variant="standard"
                    className={`text-[18px] text-[#111111] ${classes.root}`}
                    value={inputValue || ''}
                    onChange={(e) => {
                        if (setValue) {
                            setValue(name, e.target.value);
                        }
                        handleChange(e);
                    }}
                    select
                    SelectProps={{
                        MenuProps: {
                            PaperProps: { sx: { maxHeight: 200 } },
                        },
                        inputProps: {
                            sx: {
                                paddingBottom: '10px',
                            },
                        },
                    }}
                    error={Boolean(error)}
                >
                    {options?.map((option) => (
                        <MenuItem
                            key={t(option.label)}
                            value={option.label}
                            onClick={() => setInputValue(option.label)}
                        >
                            {t(option.label)}
                        </MenuItem>
                    ))}
                </TextField>
            </label>
        );
    }

    return <>{input}</>;
};
