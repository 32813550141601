import { useContext, useEffect, useState } from "react";
import { Client, Message, Conversation } from '@twilio/conversations';
import { useAuth, useProcessTry, useUnreadMessages } from "../../../hooks";
import { MessagesContext } from "../../../contexts";
import { useTranslation } from "react-i18next";

interface Options {
    twilioClient?: Client | null;
    uniqueName?: string | null;
    areaId?: string;
    ownerEmail?: string;
    areaName?: string | null;
}

export const useChat = (options: Options) => {
    const { 
        twilioClient, 
        uniqueName, 
        areaId, 
        ownerEmail,
        areaName
    } = options;

    const [messages, setMessages] = useState<Message[]>([]);
    const [conversation, setConversation] = useState<Conversation>();
    const [isSending, setIsSending] = useState(false);
    const [files, setFiles] = useState<File[]>([]);
    const [error, setError] = useState(false);

    const { loggedIn } = useAuth();
    const { t } = useTranslation();
    const { setUnreadCount } = useContext(MessagesContext);
    const { handleCreateError } = useProcessTry();

    const handleMessageAdded = async (message: Message) => {
        try {
            setMessages(prev => [...prev, message]);
            setIsSending(false);
        } catch (error) {
            console.log("Error updating last message:", error)
        }
    };

    const fetchMessages = async () => {
        if (!twilioClient || !uniqueName) {
            return;
        }

        try {
            const convo = await twilioClient.getSubscribedConversations();

            const foundConvo = convo.items.find(el => el.uniqueName === uniqueName);

            if (foundConvo) {
                const messages = await foundConvo.getMessages();
                setMessages(messages.items);
                setConversation(foundConvo);

                return;
            }

            const newConversation = await twilioClient.createConversation({
                uniqueName: uniqueName,
                attributes: {
                    areaId: areaId ? areaId : '',
                    userId: loggedIn?.id,
                    userEmail: loggedIn?.email,
                    areaName: areaName ? t(areaName) : ''
                },
            });

            await newConversation.join();

            if (ownerEmail) {
                await newConversation.add(ownerEmail);
            }

            setConversation(newConversation);
        } catch (error: any) {
            if (error.status !== 409) {
                setError(true);

                const errorData = {
                    userInfoSub: loggedIn?.id || '',
                    requestBody: JSON.stringify(error),
                    errorMsg: error.message as string,
                    process: 'Creating chat',
                    DateTimeUnix: Math.floor(new Date().getTime() / 1000)
                }
    
                handleCreateError(errorData);
            }

            console.log("Error creating chat:", error);
        }
    }

    const handleDeleteMessage = async (message: Message) => {
        try {
            await message.remove();

            const filteredMessages = messages.filter(el => el.sid !== message.sid);
            setMessages(filteredMessages);
        } catch (error) {
            console.log("Error deleting message:", error)
        }
    }

    const handleSendMessage = async (message: string) => {
        try {
            if (!message && files.length === 0) {
                return;
            }
            
            if (conversation) {
                setIsSending(true);
                const newMessageBuilder = conversation.prepareMessage().setBody(message);

                for (const file of files) {
                    const fileData = new FormData();
                    fileData.set(file.name, file, file.name);

                    newMessageBuilder.addMedia(fileData);
                }

                await newMessageBuilder.build().send();
                setFiles([]);
                setUnreadCount(0);
                await conversation.setAllMessagesRead();
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
       fetchMessages();
    }, [])

    useEffect(() => {
        if (conversation) {
            conversation?.on('messageAdded', handleMessageAdded);
        }

        return () => {
            conversation?.removeListener("messageAdded", handleMessageAdded);
        }
    }, [conversation])

    return {
        messages,
        files,
        error,
        setFiles,
        conversation,
        handleDeleteMessage,
        handleSendMessage,
        isSending
    }
}
