import MapboxDraw from "@mapbox/mapbox-gl-draw";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import mapboxgl from "mapbox-gl";
import { useEffect, useRef, useState } from "react";
import { mapStyles } from "../styles/jss/drawArea";
import toGeoJSON from 'togeojson';
import area from '@turf/area';
import bbox from '@turf/bbox';
import { useTranslation } from "react-i18next";
import { getElevation } from "../helpers";
import i18n from "../i18n";
import MapboxLanguage from "@mapbox/mapbox-gl-language";
import { useLanguage } from "./useLanguage";

const TOKEN = 'pk.eyJ1IjoiZW5sYXBhIiwiYSI6ImNsbzc2dndjbTAybmMydnBjNXE3OTVzbWkifQ.eoRkVu1mm9KFcTTq7plkew';

export const useMap = ({ 
    kml, 
    data,
    zoom,
    setData, 
    setZoom, 
    onElevation, 
    mapVisible, 
    dataToDraw,
    setClickCount,
    onGeolocate
}) => {
    const mapContainerRef = useRef();
    const [polygonArea, setPolygonArea] = useState(0);
    const [map, setMap] = useState(null);
    const [wasZoom, setWasZoom] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [mode, setMode] = useState('');

    const { t } = useTranslation();
    const { selectedLanguage } = useLanguage({ i18n });

    const options = {
        enableHighAccuracy: true,
        maximumAge: 0,
    };

    const success = (pos) => {
        const crd = pos.coords;

        if (map && crd) {
            if (onGeolocate) {
                onGeolocate({
                    latitude: crd.latitude,
                    longitude: crd.longitude
                })
            }

            map.flyTo({
                center: [parseFloat(crd.longitude), parseFloat(crd.latitude)],
                zoom: 16,
            })
        }
    }

    const errors = (err) => {
        console.warn(`ERROR(${err.code}): ${err.message}`);
    }

    const handleClickInsideRef = (event) => {
        if (mapContainerRef.current && mapContainerRef.current.contains(event.target)) {
            setIsClicked(true);
            document.removeEventListener('click', handleClickInsideRef);
        }
    };
    
    const handleFirstClickInsideRef = () => {
        document.addEventListener('click', handleClickInsideRef);
    };
    
    useEffect(() => {
        if (mapContainerRef.current) {
            mapContainerRef.current.addEventListener('click', handleFirstClickInsideRef);
        }

        return () => {
            if (mapContainerRef.current) {
                mapContainerRef.current.removeEventListener('click', handleFirstClickInsideRef);
            }
        };
    }, [mapContainerRef]);

    useEffect(() => {
        if (!kml && !data && mapVisible && !wasZoom && !dataToDraw) {
            if (navigator.geolocation) {
                setWasZoom(true);

                navigator.permissions
                .query({ name: "geolocation" })
                .then(function (result) {
                    if (result.state === "granted") {
                    navigator.geolocation.getCurrentPosition(success, errors, options);
                    } else if (result.state === "prompt") {
                    navigator.geolocation.getCurrentPosition(success, errors, options);
                    }
                });
            } else {
                console.log("Geolocation is not supported by this browser.");
            }
        }
    }, [map, kml, mapVisible, data])

    useEffect(() => {
        mapboxgl.accessToken = TOKEN;

        const map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: 'mapbox://styles/mapbox/satellite-streets-v11',
            center: [10.4515, 51.1657],
            zoom: 12,
            preserveDrawingBuffer: true
        });

        const language = new MapboxLanguage({ defaultLanguage: 'en' });

        setMap(map);

        map.addControl(language);

        map.on('zoom', () => {
            const currentZoom = map.getZoom();
            setZoom(currentZoom);
        });

        const geocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl,
            placeholder: t('addArea.placeholders.search_address'),
        });

        map.addControl(geocoder);

        const draw = new MapboxDraw({
            displayControlsDefault: false,
            controls: {
                polygon: true,
                trash: true,
            },
            active: true,
            defaultMode: 'draw_polygon',
            styles: mapStyles
        });

        map.addControl(draw);
        map.addControl(new mapboxgl.NavigationControl());
        map.addControl(new mapboxgl.GeolocateControl());

        map.on('style.load', async () => {
            map.setStyle(language.setLanguage(map.getStyle(), selectedLanguage));

            if (kml) {
                try {
                    const response = await fetch(kml)
                    const kmlText = await response.text()

                    const parser = new DOMParser()
                    const kmlDocument =  parser.parseFromString(
                        kmlText,
                        'application/xml'
                    )
                    const geoJSON = await toGeoJSON.kml(kmlDocument)

                    if (geoJSON &&
                        geoJSON.features &&
                        geoJSON.features.length > 0) {

                        const coordinates = geoJSON?.features;
                    
                        if (coordinates) {
                            draw.add(geoJSON);
                            draw.changeMode('simple_select');

                            updateArea();
        
                            if (zoom) {
                                map.fitBounds(bbox(geoJSON), { padding: 100, zoom });
                            } else {
                                map.fitBounds(bbox(geoJSON), { padding: 100 });
                            }
                        }
                    }
                } catch (error) {
                    console.error('Error fetching or parsing KML file:', error)
                }
            }

            if (dataToDraw && dataToDraw.features.length > 0) {
                draw.add(dataToDraw);
                const bboxData = bbox(dataToDraw);
                map.fitBounds(bboxData, { padding: 100 });

                const polygons = Object.values(dataToDraw.features);

                let polygonArea = 0;

                for (const polygon of polygons) {
                    polygonArea += area(polygon);
                }

                const rounded_area = Math.round(polygonArea * 100) / 100;

                setPolygonArea(rounded_area);
            }
        })

        map.on('draw.create', updateArea);
        map.on('draw.delete', updateArea);
        map.on('draw.update', updateArea);
        map.on('draw.change', updateArea);

        map.on('click', () => {
            setClickCount(prev => prev + 1)
        });

        map.on('touchstart', () => {
            setClickCount(prev => prev + 1);
            setIsClicked(true);
        });

        const mapMode = draw.getMode();
        setMode(mapMode);

        async function updateArea(e) {
            try {
                const mapMode = draw.getMode();
                setMode(mapMode);

                const data = draw.getAll();

                setClickCount(0);
            
                if (data && data.features.length > 0) {
                    const polygons = Object.values(data.features);

                    let polygonArea = 0;

                    for (const polygon of polygons) {
                        polygonArea += area(polygon);
                    }

                    const rounded_area = Math.round(polygonArea * 100) / 100;

                    const coordinates = data?.features[0]?.geometry?.coordinates[0][0];    

                    const elevation = await getElevation(coordinates, TOKEN);

                    onElevation(elevation);
                    setPolygonArea(rounded_area);
                    setData(data);
                } else {
                    setData(null);
                    setIsClicked(false);
                    setPolygonArea(0)
                    setClickCount(0);
                    if (e.type !== 'draw.delete')
                        alert('Click the map to draw a polygon.')
                }
            } catch (error) {
                console.log(error);
            }
        }
    }, [kml, selectedLanguage])
    
    return {
        polygonArea,
        isClicked,
        mapContainerRef,
        mode
    }
} 