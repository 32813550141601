import { useEffect, useState } from "react";
import { Flaeche } from "../../../API";
import { useTranslation } from "react-i18next";
import { Progress } from "./Progress";

interface MoreInfoProps {
    area: Flaeche | null | undefined;
}

export const MoreInfo = ({ area }: MoreInfoProps) => {
    const [isOpen, setIsOpen] = useState(true);
    const { t } = useTranslation();
    const [image, setImage] = useState('');
    const [desc, setDesc] = useState('');

    useEffect(() => {
        if (area?.criteriaMetConversionArea === 'true') {
            setImage('/assets/icons/biomimetic.svg');
            setDesc('addArea.description_items.conversion_areas');

            return;
        }

        if (area?.criteriaMetHandicapped === 'true') {
            setImage('/assets/icons/field.svg');
            setDesc('addArea.description_items.fields');

            return;
        }

        if (area?.criteriaMetIndustryArea  === 'true') {
            setImage('/assets/icons/factory.svg');
            setDesc('addArea.description_items.industry');

            return;
        }

        if (area?.criteriaMetMargins  === 'true') {
            setImage('/assets/icons/road.svg');
            setDesc('addArea.description_items.railways');

            return;
        }

        if (area?.criteriaMetPlant === 'true') {
            setImage('/assets/icons/skyline.svg');
            setDesc('addArea.description_items.buildings');

            return;
        }

        if (area?.criteriaMetOther === 'true') {
            setImage('/assets/icons/not-applicable.svg');
            setDesc('addArea.description_items.nothing_applicable');

            return;
        }
    }, [area])

    const handleOpen = () => {
        setIsOpen(prev => !prev);
    }

    return (
        <div>
            <div className="mb-[15px] flex justify-between font-semibold cursor-pointer" onClick={handleOpen}>
                <p>{t('area.further_info')}</p>
                <img
                    src="/assets/icons/arrowDown.svg"
                    alt="arrow"
                    className={`cursor-pointer ${isOpen ? 'rotate-180' : ""}`}
                />
            </div>

            {isOpen && area && (
                <>
                    <div className="flex flex md:flex-row flex-col  justify-between lg:gap-[125px] gap-[25px]">
                        <Progress area={area} />

                        <div>
                            <p className="mb-[7px] text-[16px] text-[#9e9e9e]">
                                {t('area.role')}
                            </p>
                            <p className="mb-[36px] text-[18px] font-medium">
                                {area?.identifyAsDeveloper === 'true' ?  t('addArea.role_items.project_developer') : ''}
                                {area?.identifyAsInvestor === 'true' ? t('addArea.role_items.investor')  : ''}
                                {area?.identifyAsLandlord === 'true' ? t('addArea.role_items.landowners')  : ''}
                                {area?.identifyAsOwner === 'true' ? t('addArea.role_items.commisioned')  : ''}
                                {area?.identifyAsOther === 'true' ? area.identifyAsOtherDescription : ''}
                            </p>

                            <p className="mb-[7px] text-[16px] text-[#9e9e9e]">
                                {t('addArea.placeholders.for_my_space')}
                            </p>

                            <p className="mb-[36px] text-[18px] font-medium">
                                {t(area.additionalInformationLookingFor 
                                    ? area.additionalInformationLookingFor 
                                    : ''
                                )}
                            </p>

                            <p className="mb-[7px] text-[16px] text-[#9e9e9e]">
                                {t('area.area')}
                            </p>

                            {image && desc && (
                                <div className="flex items-start gap-[5px]">
                                    <img
                                        src={image}
                                        alt="area"
                                        className="h-[30px]"
                                    />

                                    <p className="text-[18px] font-medium">
                                        {t(desc)}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}
