import { MenuItem, TextField } from "@mui/material";
import { types } from "../../../utils/formLists";
import { Delete } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../../styles/jss/useStyles";

interface AreaTypesProps {
    areaTypes: string[];
    sizeRanges: { min: string, max: string }[];
    typeError: string;
    handleMinSizeChange: (index: number, value: string) => void;
    handleMaxSizeChange: (index: number, value: string) => void;
    handleAreaTypeChange: (index: number, value: string) => void;
    removeAreaType: (index: number) => void;
    addAreaType: () => void;
}

export const AreaTypes = ({
    areaTypes,
    sizeRanges,
    typeError,
    addAreaType,
    removeAreaType,
    handleAreaTypeChange,
    handleMaxSizeChange,
    handleMinSizeChange
}: AreaTypesProps) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <div>
            <p className="mb-[20px] text-[14px] text-gray-500">
                {t('profile.types')}
            </p>

            {areaTypes.map((areaType, index) => (
                <div
                    key={index}
                    className="mb-[10px] flex items-center gap-[5px]"
                >
                    <div className="w-[150px]">
                        <TextField
                            className={classes.root}
                            id="demo-simple-select-helper"
                            label={t('profile.type')}
                            value={areaType}
                            onChange={(e) =>
                                handleAreaTypeChange(index, e.target.value)
                            }
                            select
                            fullWidth
                            SelectProps={{
                                MenuProps: {
                                    PaperProps: {
                                        sx: { maxHeight: 250 },
                                    },
                                },
                                inputProps: {
                                    sx: {
                                        padding: '18px 20px',
                                    },
                                },
                            }}
                        >
                            {types.map((el) => (
                                <MenuItem value={el.value} key={el.value}>
                                    {t(el.label)}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <TextField
                        className={classes.root}
                        label={`${t('profile.min')} ${t(areaType)} (m²)`}
                        value={sizeRanges[index]?.min || ''}
                        onChange={(e) =>
                            handleMinSizeChange(index, e.target.value)
                        }
                    />
                    <TextField
                        className={classes.root}
                        label={`${t('profile.max')} ${t(areaType)} (m²)`}
                        value={sizeRanges[index]?.max || ''}
                        onChange={(e) =>
                            handleMaxSizeChange(index, e.target.value)
                        }
                    />
                    <div
                        onClick={() => removeAreaType(index)}
                        className="cursor-pointer"
                    >
                        <Delete />
                    </div>
                </div>
            ))}
            {typeError && <p className="mb-[20px] text-red-600">{t(typeError)}</p>}
            <button
                className="mb-[20px] rounded-[5px] bg-primary p-[7px] text-white"
                onClick={addAreaType}
                type="button"
            >
                {t('profile.add_type')}
            </button>
        </div>
    )
}
