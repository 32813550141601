import { createContext, ReactNode } from 'react';

import { useLocalStorage } from '../hooks/useLocaleStorage';
import { CHAT_TOKEN, IMAGE, LOGGED_IN, REGISTERED_BEFORE } from '../utils/constants';

interface AuthContextType {
    loggedIn: any | null;
    isAdmin: boolean | null;
    isInvestor: boolean | null;
    registeredUser: boolean | null;
    image: string;
    loginUser: (newUser: any | null) => void;
    logout: () => void;
    setImage: (image: string) => void;
    token: string;
    setToken: (token: string) => void;
}

const initialAuthContext = {
    loggedIn: null,
    isAdmin: null,
    isInvestor: null,
    image: '',
    token: '',
    registeredUser: null,
    loginUser: () => {},
    logout: () => {},
    setImage: () => {},
    setToken: () => {}
};

const AuthContext = createContext<AuthContextType>(initialAuthContext);

interface AuthProviderProps {
    children: ReactNode;
}

const AuthProvider = ({ children }: AuthProviderProps) => {
    const [loggedIn, setLoggedIn] = useLocalStorage<any | null>(
        LOGGED_IN,
        null,
    );

    const [image, setImage] = useLocalStorage(
        IMAGE,
        '',
    );

    const [token, setToken] = useLocalStorage(CHAT_TOKEN, '');

    const [registeredUser, setRegisteredUser] = useLocalStorage<any | null>(
        REGISTERED_BEFORE,
        null,
    );

    const isAdmin = loggedIn?.adminFull;
    const isInvestor = loggedIn?.qualifiedInvestor;

    const loginUser = (userData: any | null) => {
        setLoggedIn(userData)
        setRegisteredUser(true)
    }

    const logout = () => {
        setLoggedIn(null);
        setImage('');
        setToken('');
    };

    return (
        <AuthContext.Provider
            value={{ 
                loggedIn, 
                isAdmin,
                isInvestor,
                loginUser, 
                logout, 
                registeredUser, 
                image, 
                token,
                setToken,
                setImage  
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export { AuthContext, AuthProvider };