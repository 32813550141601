import {
    countriesAddArea,
    federalStates,
    options,
} from '../../../utils/formLists';
import { useTranslation } from 'react-i18next';
import { MultipleSelect } from './MultipleSelect';
import { AreaTypes } from './AreaTypes';
import { useSearchCriteria } from '../hooks';
import { UserInfo } from '../../../API';
import { Loader } from '../../../components';
import { CircularProgress } from '@mui/material';

interface SearchCriteriaFormProps {
    user: UserInfo;
}

export const SearchCriteriaForm = ({ user }: SearchCriteriaFormProps) => {
    const { t } = useTranslation()
    const {
        areaTypes,
        sizeRanges,
        typeError,
        countries,
        offerPreferences,
        germanyStates,
        isLoading,
        isSaved,
        addAreaType,
        removeAreaType,
        handleAreaTypeChange,
        handleCountriesChange,
        handleFormSubmit,
        handleMaxSizeChange,
        handleMinSizeChange,
        handleOfferChange,
        handleStatesChange
    } = useSearchCriteria({ user });

    return (
        <div>
            <h2 className="mb-[20px] text-[24px] font-medium">
                {t('profile.search')}
            </h2>

            <div>
               <AreaTypes
                    areaTypes={areaTypes} 
                    sizeRanges={sizeRanges}
                    typeError={typeError}
                    addAreaType={addAreaType}
                    removeAreaType={removeAreaType}
                    handleAreaTypeChange={handleAreaTypeChange}
                    handleMaxSizeChange={handleMaxSizeChange}
                    handleMinSizeChange={handleMinSizeChange}
               />

                <div className="mb-[20px] flex md:flex-row flex-col w-full gap-[20px]">
                    <MultipleSelect
                        label={t('profile.select_countries')} 
                        placeholder={t('profile.countries')}
                        values={countries}
                        options={countriesAddArea}
                        onValuesChange={handleCountriesChange}
                    />

                    {countries.includes('addArea.countries.Germany') && (
                        <MultipleSelect
                            label={t('profile.select_states')} 
                            placeholder={t('profile.states')}
                            values={germanyStates}
                            options={federalStates}
                            onValuesChange={handleStatesChange}
                        />
                    )}
                </div>

                <MultipleSelect
                    label={t('profile.what_we_offer')}
                    placeholder={t('profile.we_offer')}
                    values={offerPreferences}
                    options={options}
                    onValuesChange={handleOfferChange}
                />

                <button
                    onClick={handleFormSubmit}
                    className="rounded-[5px] bg-primary p-[7px] text-white mt-[20px] min-w-[50px]"
                >
                    {isLoading && !isSaved && <CircularProgress color="success" size={20}/> }
                    {isSaved && t('profile.saved')}
                    {!isLoading && !isSaved && t('profile.buttons.edit_profile')}
                </button>
            </div>
        </div>
    )
}
