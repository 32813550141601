export const convertToKML = (polygons: any[]) => {
  let kmlString = `<?xml version="1.0" encoding="UTF-8"?>
    <kml xmlns="http://www.opengis.net/kml/2.2">
      <Document>
        <Style id="transparentPoly">
          <LineStyle>
            <color>ff0000ff</color>
            <width>1</width>
          </LineStyle>
          <PolyStyle>
            <color>7f00ff00</color> <!-- Alpha value determines transparency -->
            <outline>1</outline>
          </PolyStyle>
        </Style>
  `;

  polygons.forEach((polygon, index) => {
    kmlString += `
        <Placemark>
          <name>Drawn Polygon ${index + 1}</name>
          <styleUrl>#transparentPoly</styleUrl>
          <Polygon>
            <outerBoundaryIs>
              <LinearRing>
                <coordinates>
    `;

    polygon.forEach((coords: any) => {
      kmlString += `${coords[0]},${coords[1]},0\n`;
    });

    kmlString += `</coordinates>
              </LinearRing>
            </outerBoundaryIs>
          </Polygon>
        </Placemark>
    `;
  });

  kmlString += `
      </Document>
    </kml>`;

  return kmlString;
};
