import { useTranslation } from 'react-i18next';
import { TextFieldProfile } from './TextFieldProfile';
import { FieldErrors, SetFieldValue, UseFormRegister } from 'react-hook-form';
import { TEditingUserInfoSchema } from '../../../helpers';
import { Organization } from '../../../API';
import { useMemo } from 'react';
import CountryList from '../../../utils/countryLists.json';
import { CompanySwitcher } from './CompanySwitcher';

interface CompanyInfoProps {
    register: UseFormRegister<TEditingUserInfoSchema>;
    organization?: Organization | null;
    setValue: SetFieldValue<TEditingUserInfoSchema>;
    errors: FieldErrors<TEditingUserInfoSchema>;
}

export const CompanyInfo = ({
    register,
    organization,
    setValue,
    errors
}: CompanyInfoProps) => {
    const { t } = useTranslation();
    const countries: Record<string, string>[] = useMemo(() => CountryList, []);

    return (
        <>
            <div className="mb-[20px] flex w-full flex-col justify-between gap-[20px] md:flex-row">
                <div className='relative w-full'>
                    <TextFieldProfile
                        name="company"
                        register={register}
                        value={organization?.CompanyName}
                        label={t('profile.labels.company_name')}
                        setValue={setValue}
                        error={errors['company' as keyof TEditingUserInfoSchema]}
                    />

                    {errors['company' as keyof TEditingUserInfoSchema] && (
                        <div
                            className={`req_placeholder absolute left-[100px] top-[-5px] text-[24px] text-[#FF0F00]`}
                        >
                            <span className="req_placeholder">*</span>
                        </div>
                    )}
                </div>

                <TextFieldProfile
                    name="website"
                    register={register}
                    value={organization?.websiteDomain}
                    label={t('profile.labels.company_website')}
                    setValue={setValue}
                />
            </div>

            <div className="mb-[20px]">
                <TextFieldProfile
                    name="companyStreet"
                    register={register}
                    value={organization?.addressStreetAddress}
                    label={t('profile.labels.street')}
                    setValue={setValue}
                />
            </div>

            <div className="mb-[20px] flex w-full flex-col justify-between gap-[20px] md:flex-row">
                <TextFieldProfile
                    name="companyPostal"
                    setValue={setValue}
                    register={register}
                    value={organization?.addressPostalCode}
                    label={t('profile.labels.postal_code')}
                />

                <TextFieldProfile
                    name="companyCity"
                    register={register}
                    value={organization?.addressCity}
                    label={t('profile.labels.city')}
                    setValue={setValue}
                />
            </div>

            <div className="mb-[20px] flex w-full flex-col justify-between gap-[20px] md:flex-row">
                <div className="w-full">
                    <TextFieldProfile
                        name="companyCountry"
                        type="dropdown"
                        setValue={setValue}
                        options={countries}
                        register={register}
                        value={organization?.addressCountry}
                        label={t('profile.labels.country')}
                    />
                </div>

                <TextFieldProfile
                    name="VATID"
                    register={register}
                    value={organization?.vatID}
                    label="VATID"
                    setValue={setValue}
                />
            </div>

            {organization && (
                <CompanySwitcher organization={organization} />
            )}
        </>
    )
}
