import { useCallback, useMemo, useState } from "react";
import {
    CreateFlaecheRatingMutation,
    Flaeche,
    FlaecheRating,
    GetFlaecheQuery,
    GetUserInfoBySubQuery,
    ListFlaecheRatingsQuery,
    UpdateFlaecheRatingMutation,
    UserInfo
} from '../../../API';
import { API, graphqlOperation, Storage } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import {
    getFlaeche,
    getUserInfoBySub,
    listFlaecheRatings,
} from '../../../graphql/queries';
import { useNavigate } from "react-router-dom";
import { Links } from "../../../settings";
import { createFlaecheRating, updateFlaecheRating } from '../../../graphql/mutations';
import { useAuth, useProcessTry } from "../../../hooks";

interface Options {
    areaId?: string;
    user?: UserInfo | null;
}

export const useDetails = (options: Options) => {
  const { areaId, user } = options;
  const { handleCreateError } = useProcessTry();
  const { loggedIn } = useAuth();

  const [openDialog, setIsOpenDialog] = useState(false);
  const [loading, setLoading] = useState(() => true);
  const [areaLoading, setAreaLoading] = useState(false);
  const [areaDetails, setAreaDetails] = useState<Flaeche | undefined | null>(() => null);
  const [areaDocuments, setAreaDocuments] = useState<string[] | null>(() => null);
  const [owner, setOwner] = useState<UserInfo | null>(null);
  const [ratedArea, setRatedArea] = useState<FlaecheRating | null>(null);
  const [error, setError] = useState(false);

  const getAreaDetails = useCallback(async () => {
    setAreaLoading(true);

    try {
      setError(false);
      setAreaDetails(null);
      setAreaDocuments(null);
  
      const res = await API.graphql<GraphQLQuery<GetFlaecheQuery>>(graphqlOperation(getFlaeche, {
        id: areaId
      }));
  
      const { results } = await Storage.list(areaId ? `${areaId}` : "");
  
      for (const file of results) {
        if (file?.key && areaId && file?.key.includes(areaId)) {
          try {
            const fileRes = await Storage.get(file.key);

            setAreaDocuments((areaDocuments) => {
              if (!areaDocuments) areaDocuments = [];
              if (!areaDocuments.includes(fileRes))
                return [...areaDocuments, fileRes];
              else return areaDocuments;
            });


            setAreaLoading(false);
          } catch (err) {
            console.error(err);
          }
        }
      }
  
      setAreaDetails(res.data?.getFlaeche);
      setLoading(false);
      setAreaLoading(false);
  
    } catch (error: any) {
      setError(true);
      setLoading(false);
      setAreaLoading(false);
      console.error(error);

      const errorData = {
        userInfoSub: user?.sub,
        requestBody: JSON.stringify({id: areaId}),
        errorMsg: error.message as string,
        process: 'Get area details page',
        DateTimeUnix: Math.floor(new Date().getTime() / 1000)
      };
  
      handleCreateError(errorData);
    }
  }, [areaId]);

  const areaImages = useMemo(() => (
    areaDocuments?.filter((document) => 
      document.includes(".png") 
      || document.includes(".jpeg") 
      || document.includes(".jpg"))  
      ?? []
  ), [areaDocuments])

  const areaFiles = useMemo(() => (
    areaDocuments?.filter((document) => 
      !document.includes(".png") && !document.includes(".jpeg") && !document.includes(".webp")) ?? []
  ), [areaDocuments])

  const isOwner = useMemo(
    () => areaDetails?.ownerSub === loggedIn.sub,
    [areaDetails, loggedIn]
  )

  const handleOpenChat = useCallback(() => {
    setIsOpenDialog(true);
  }, [])

  const handleCloseChat = useCallback(() => {
    setIsOpenDialog(false);
  }, [])

  const navigate = useNavigate();

  const handleNavigate = useCallback(() => {
      navigate(Links.offer.index);
  }, [navigate])

  const handleNavigateToEdit = useCallback(() => {
    navigate(`${Links.offer.index}/${areaDetails?.id}`)
}, [areaDetails, navigate])

  const getRatedArea = async () => {
    if (user && areaId) {
        try {
          const { data } = await API.graphql<GraphQLQuery<ListFlaecheRatingsQuery>>(
              graphqlOperation(listFlaecheRatings, { filter: { userInfoSub: { eq: user.sub } }})
          );

          const foundArea = data?.listFlaecheRatings?.items.find(el => el?.flaecheId === areaId);

          if (foundArea) {
              setRatedArea(foundArea);
          }
        } catch (error: any) {
          console.log('Error getting rating of the area:', error);

          const errorData = {
            userInfoSub: user?.sub,
            requestBody: JSON.stringify({id: areaId}),
            errorMsg: error.message as string,
            process: 'Get area details page, rating data',
            DateTimeUnix: Math.floor(new Date().getTime() / 1000)
          };
      
          handleCreateError(errorData);
        }
    }
  }

  const handleRateArea = async (
      ratingSize: number, 
      ratingGrid: number, 
      ratingProbability: number
  ) => {
      if (user && areaId) {
          const ratedFlaeche = {
              flaecheId: areaId,
              userInfoSub: user.sub,
              rating: 0,
              ratingSizeLocation: ratingSize,
              ratingGrid,
              ratingProbability,
          };

          try {
              if (!ratedArea) {
                  await API.graphql<GraphQLQuery<CreateFlaecheRatingMutation>>(
                      graphqlOperation(createFlaecheRating, { input: ratedFlaeche })
                  );

                  return;
              }

              await API.graphql<GraphQLQuery<UpdateFlaecheRatingMutation>>(
                  graphqlOperation(updateFlaecheRating, { input: {...ratedFlaeche, id: ratedArea.id } })
              );
          } catch (error: any) {
              console.error("Error rating the area:", error);

              const errorData = {
                userInfoSub: user?.sub,
                requestBody: JSON.stringify(ratedFlaeche),
                errorMsg: error.message as string,
                process: 'Rating the area - project details page',
                DateTimeUnix: Math.floor(new Date().getTime() / 1000)
              };
          
              handleCreateError(errorData);
          }
      }
  };

  const getOwner = async () => {
      try {
          if (areaDetails) {
              const { data } = await API.graphql<
                GraphQLQuery<GetUserInfoBySubQuery
              >>(graphqlOperation(getUserInfoBySub, {
                  sub: areaDetails.ownerSub
              }))
      
              if (data?.getUserInfoBySub?.items[0]) {
                  setOwner(data?.getUserInfoBySub?.items[0]);
              }
          } 
      } catch (error: any) {
          console.log("ERROR", error);

          const errorData = {
            userInfoSub: user?.sub,
            requestBody: JSON.stringify({
              sub: areaDetails?.ownerSub
            }),
            errorMsg: error.message as string,
            process: 'Getting owner of the project - project details page',
            DateTimeUnix: Math.floor(new Date().getTime() / 1000)
          };
      
          handleCreateError(errorData);
      }
  }

  const kmlToEdit = useMemo(() => (
    areaDetails && areaFiles.find(el => el.includes('kml')
  )), [areaDetails, areaFiles])

  return {
    error,
    openDialog,
    loading,
    areaLoading,
    areaDetails,
    areaImages,
    areaFiles,
    owner,
    ratedArea,
    getRatedArea,
    getOwner,
    handleRateArea,
    getAreaDetails,
    handleOpenChat,
    handleCloseChat,
    handleNavigate,
    handleNavigateToEdit,
    kmlToEdit,
    setAreaDetails,
    isOwner
  }
}