import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Links } from '../../../settings';
import { ArticleImage } from './Image';
import { CallToAction } from '../../../components';

export const ElevenArticle = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleNavigate = useCallback(() => {
        navigate(Links.offer.index);
    }, [navigate])

    return (
        <div className="mb-[20px] flex flex-col gap-[25px] text-[16px] text-[#333] md:text-[18px]">
            <span className="uppercase text-primary">{t('articles.info')}</span>

            <h1 className="text-[25px] font-medium md:text-[35px]">
                {t('article_11.title')}
            </h1>

            <div className="h-[2px] w-[150px] bg-primary" />

            <span className="text-[13px] uppercase text-gray-700 md:text-[16px]">
                {t('articles.article_11.date')}
            </span>

            <p>
                {t('article_11.p_1')}
            </p>

            <p>
                {t('article_11.p_2')}
            </p>

            <p>
                {t('article_11.p_3')}
            </p>

            <div className="flex flex-col gap-[15px] md:flex-row">
                <ArticleImage source="/assets/images/tenArticle2-620.png" />
                <ArticleImage source="/assets/images/tenArticle-620.png" />
            </div>

            <p className="text-[13px] font-medium text-gray-500">
                {t('article_ten.project_example')}
            </p>

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_11.subtitle_1')}
            </h2>

            <p>
                {t('article_11.p_4')}
            </p>

            <p>
                {t('article_11.p_5')}
            </p>

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_11.subtitle_2')}
            </h2>

            <p>
                {t('article_11.p_6')}
            </p>

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_11.subtitle_3')}
            </h2>

            <p>
                {t('article_11.p_7')}
            </p>

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_11.subtitle_4')}
            </h2>

            <p>
                {t('article_11.p_8')}
            </p>

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_11.subtitle_5')}
            </h2>

            <p>
                {t('article_11.p_9')}
            </p>

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_11.subtitle_6')}
            </h2>

            <p>
                {t('article_11.p_10')}
            </p>

            <p>
                {t('article_11.p_11')}
            </p>

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_11.subtitle_7')}
            </h2>

            <p>
                {t('article_11.p_12')}
            </p>

            <ul className="flex list-disc flex-col gap-[10px] pl-[30px]">
                <li>
                    <h3 className='inline'><b>{t('article_11.bold_1')} </b></h3>{' '}
                    {t('article_11.list_1')}
                </li>

                <li>
                    <h3 className='inline'><b>{t('article_11.bold_2')} </b></h3>{' '}
                    {t('article_11.list_2')}
                </li>

                <li>
                    <h3 className='inline'><b>{t('article_11.bold_3')} </b></h3>{' '}
                    {t('article_11.list_3')}
                </li>

                <li>
                    <h3 className='inline'><b>{t('article_11.bold_4')} </b></h3>{' '}
                    {t('article_11.list_4')}
                </li>

                <li>
                    <h3 className='inline'><b>{t('article_11.bold_5')} </b></h3>{' '}
                    {t('article_11.list_5')}
                </li>

                <li>
                    <h3 className='inline'><b>{t('article_11.bold_6')} </b></h3>{' '}
                    {t('article_11.list_6')}
                </li>

                <li>
                    <h3 className='inline'><b>{t('article_11.bold_7')} </b></h3>{' '}
                    {t('article_11.list_7')}
                </li>
            </ul>

            <div className="mx-[25px] text-[16px] md:mt-[50px]">
                <CallToAction
                    title={t('home.call_to_action_money')}
                    btnTitle={t('buttons.offer_your_space_now')}
                    bgColor="bg-secondary text-white"
                    onClick={handleNavigate}
                    tag="p"
                />
            </div>
        </div>
    )
}
