import { useLayoutEffect, useMemo, useState } from "react"
import { Article } from "./components/BlogCard"
import { recentArticles } from "../../utils";
import { Link } from "react-router-dom";
import { Links } from "../../settings";
import { MainArticle } from "./components";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export const BlogPage = () => {
    const [mainPost, setMainPost] = useState<Article | null>(null)

    const { t } = useTranslation()

    const otherArticles = useMemo(() => {
        return recentArticles.filter((article) => article.id !== mainPost?.id)
    }, [mainPost])

    const blogPageSchema = {
        "@context": "https://schema.org",
        "@type": "ItemList",
        "name": "Enlapa Blog",
        "description": t("schema_blog"),
        "url": "https://www.enlapa.de/blog",
        "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://www.enlapa.de/blog"
        },
        "numberOfItems": recentArticles.length,
        "itemListElement": recentArticles.slice(4).map((article, index) => ({
            "@type": "ListItem",
            "position": index + 1,
            "url": `https://www.enlapa.de/blog/${article.id}`,
            "name": t(article.title),
            "description":t( article.description)
        })),
        "author": {
            "@type": "Organization",
            "name": "Enlapa"
        },
        "publisher": {
            "@type": "Organization",
            "name": "Enlapa",
            "logo": {
            "@type": "ImageObject",
            "url": "https://www.enlapa.de/Enlapa logo.png",
            "width": "150",
            "height": "25"
            }
        },
        "keywords": "blog, real estate, Enlapa, solar, lease, sale, areas, field, roof, acker, dach, solarflächen",
    };

    useLayoutEffect(() => {
        const lastPost = recentArticles[0]

        setMainPost(lastPost)
    }, [])

    return (
        <div className="px-[25px] py-[20px] md:px-[100px] md:py-[80px] lg:py-[20px]">
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify(blogPageSchema)}
                </script>
            </Helmet>
            
            <h1 className="mb-[25px] text-center text-[35px] md:text-start">
                {t('articles.title')}
            </h1>

            <div className="flex flex-col gap-[30px] [@media(min-width:1000px)]:flex-row">
                <MainArticle mainPost={mainPost} />

                <div className="flex-1 overflow-scroll">
                    {otherArticles.map((article) => (
                        <Link
                            to={`${Links.blog.index}/${article.id}`}
                            key={article.id}
                        >
                            <div className="group rounded-[8px] pb-[20px] hover:shadow-md ">
                                <hr className="mb-[30px] w-full" />

                                <span className="mb-[20px] text-[13px] uppercase text-gray-600">
                                    {t(article.published)}
                                </span>

                                <h3 className="mb-[20px] font-medium transition-all group-hover:text-primary">
                                    {t(article.title)}
                                </h3>

                                <p className="text-[15px]">
                                    {t(article.description)}
                                </p>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    )
}
