import { useTranslation } from "react-i18next";
import { usePageDescription } from "../../hooks";
import { Helmet } from "react-helmet";

export const Imprint = () => {
    const { t } = useTranslation();

    usePageDescription({ 
        title: t('title.imprint'),
        description: t('description.imprint')
    });

    return (
        <>
        <Helmet>
            <meta name='title' content={t('title.imprint')} />
            <meta name='description' content={t('description.imprint')} />
        </Helmet>
        <div className="flex flex-col py-[50px] md:px-[100px] px-[25px]">
            <div>
                <h1 className="text-[40px] font-medium text-[#11470E] mb-[20px]">
                    {t('imprint.title')}
                </h1>

                <p className="md:text-[20px] mb-[20px]">
                    {t('imprint.desc')}
                </p>

                <p className="font-medium md:text-[27px] text-[20px] mb-[20px]">
                    ENLAPA GmbH
                </p>
            </div>

            <div className="mb-[50px]">
                <div className="md:text-[20px] font-medium text-[#B0CC55] mb-[15px]">
                    {t('imprint.director')} <span className="text-[#11470E] font-normal">Dr. Klaus Röller</span>
                </div>

                <p className="md:text-[20px] text-[#11470E] mb-[15px]">
                    Radlkoferstr. 2
                </p>


                <p className="md:text-[20px] text-[#11470E] mb-[15px]">
                    81373 {t('imprint.munich')}
                </p>

                <div className="md:text-[20px] font-medium text-[#B0CC55] mb-[15px]">
                    {t('imprint.phone')} <span className="text-[#11470E] font-normal">+49 89 20195 9990</span>
                </div>

                <p className="mb-[15px] max-w-[500px]">
                    {t('imprint.comment')}
                </p>

                <div className="md:text-[20px] font-medium text-[#B0CC55] mb-[15px]">
                    Email: <a href="mailto:impressum@enlapa.de" className="text-[#11470E] font-normal">impressum@enlapa.de</a>
                </div>
            </div>

            <p className="text-[30px] font-medium mb-[15px]">
                {t('imprint.registration')}
            </p>

            <div className="md:text-[20px] font-medium text-[#B0CC55] mb-[15px]">
                {t('imprint.trade')} <span className="text-[#11470E] font-normal">{t('imprint.trade_value')}</span>
            </div>

            <div className="md:text-[20px] font-medium text-[#B0CC55] mb-[50px]">
                {t('imprint.vat')} <span className="text-[#11470E] font-normal">DE363799237</span>
            </div>

            <p className="md:text-[20px] mb-[15px]">
                ENLAPA&reg; {t('imprint.registered')}
            </p>

            <p className="md:text-[20px] mb-[15px]">
                {t('imprint.license')}
            </p>

            <div className="md:text-[20px] font-medium mb-[15px] 2xl:flex gap-[10px]">
                <p className="underline text-[#11470E] mb-[20px]">{t('imprint.authority')} </p>
                <span className="text-black font-normal">{t('imprint.authority_address')}</span>
            </div>
        </div>
        </>

    )
}