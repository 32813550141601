import { FormHeader } from './FormHeader';
import { TOfferAreaSchema } from '../../../helpers';
import { countriesAddArea, federalStates } from '../../../utils/formLists';
import { useTranslation } from 'react-i18next';
import { Flaeche } from '../../../API';
import { getValue } from '../../../helpers/getValue';
import { useMemo } from 'react';
import { Checkbox, LabelUpInput } from '../../../components';
import { useLanguage } from '../../../hooks';
import { FieldErrors, SetFieldValue, UseFormRegister } from 'react-hook-form';

interface LocationProps {
    area?: Flaeche | undefined | null;
    register: UseFormRegister<TOfferAreaSchema>;
    errors: FieldErrors<TOfferAreaSchema>;
    setValue: SetFieldValue<TOfferAreaSchema>;
    country?: string;
    street?: string;
}

export const Location = (props: LocationProps) => {
    const { t, i18n } = useTranslation();
    const { selectedLanguage } = useLanguage({ i18n });

    const { 
        area,
        register, 
        errors, 
        setValue, 
        country,
        street
    } = props;

    const checkCountry = useMemo(() => {
        return country === 'Germany' 
            || country === 'Deutschland' 
            || country === 'addArea.countries.Germany'
    }, [country]);

    return (
        <>
            <div className="flex flex-wrap w-full flex-col items-start md:flex-row px-[25px] md:px-[80px] xl:px-[180px]">
                <FormHeader title={t('addArea.location')} />
                
                    <div className={`flex ${checkCountry ? 'w-full' : 'w-full md:w-[39%]'} md:flex-row flex-col gap-[30px] items-center mb-[30px]`}>
                        <div className={`w-full ${checkCountry ? 'md:mr-0 md:w-[50%] w-full' : 'md:mr-[30px]'}`} key='country'>
                            <LabelUpInput
                                name='country'
                                star={true}
                                value={getValue(area, 'country')}
                                starStyle={
                                    selectedLanguage === 'en'
                                        ? 'left-[120px]'
                                        : 'left-[60px]'
                                }
                                type='dropdown'
                                options={countriesAddArea}
                                setValue={setValue}
                                register={register}
                                error={errors['country' as keyof TOfferAreaSchema]}
                                placeholder={t('addArea.placeholders.country')}
                            />
                        </div>

                        {(country === t('addArea.countries.Germany') || country === 'addArea.countries.Germany') && (
                            <div className="w-full md:w-[50%]" key='state'>
                                <LabelUpInput
                                    name='state'
                                    star={true}
                                    value={getValue(area, 'state')}
                                    starStyle={'left-[115px]'}
                                    type='dropdown'
                                    options={federalStates}
                                    setValue={setValue}
                                    register={register}
                                    error={errors['state' as keyof TOfferAreaSchema]}
                                    placeholder={t('addArea.placeholders.federal_state')}
                                />
                            </div>
                        )}
                    </div>

                    <div className="w-full mb-[30px] md:w-[16%] md:mr-[30px] mb-[30px] md:mb-0" key='postal'>
                        <LabelUpInput
                            name='postalCode'
                            star={true}
                            value={getValue(area, 'postalCode')}
                            starStyle={'left-[105px] md:hidden lg:block'}
                            setValue={setValue}
                            register={register}
                            error={errors['postalCode' as keyof TOfferAreaSchema]}
                            placeholder={t('addArea.placeholders.postal_code')}
                        />
                    </div>

                    <div className="w-full md:w-[40%] mb-[30px]" key="city">
                        <LabelUpInput
                            name='city'
                            value={getValue(area, 'city')}
                            star={true}
                            starStyle={
                                selectedLanguage === 'en'
                                    ? 'left-[95px]'
                                    : 'left-[60px]'
                            }
                            placeholder={t('addArea.placeholders.city')}
                            register={register}
                            setValue={setValue}
                            error={errors['city' as keyof TOfferAreaSchema]}
                        />
                    </div>
            </div>

            <div className="mb-[30px] flex w-full flex-col items-start gap-[30px] md:flex-row px-[25px] md:px-[80px] xl:px-[180px]">
                <div className='flex flex-col gap-[20px] w-full md:w-[50%]'>
                    <div key='street'>
                        <LabelUpInput
                            name='street'
                            value={getValue(area, 'street')}
                            star={street ? false : true}
                            starStyle={
                                selectedLanguage === 'en'
                                    ? 'left-[295px]'
                                    : 'left-[325px] md:hidden lg:block block'
                            }
                            placeholder={t('addArea.placeholders.street')}
                            register={register}
                            setValue={setValue}
                            error={!street && errors['street' as keyof TOfferAreaSchema]}
                        />
                    </div>
                </div>

                {street && (
                    <div key='street' className='w-full md:w-[50%]'>
                        <LabelUpInput
                            name='municipality'
                            value={getValue(area, 'municipality')}
                            star={true}
                            starStyle="lg:block md:hidden block left-[315px]"
                            placeholder={t('addArea.placeholders.municipality')}
                            register={register}
                            setValue={setValue}
                            error={errors['municipality' as keyof TOfferAreaSchema]}
                        />
                    </div>
                )}
            </div>

            <div className='w-full px-[25px] md:px-[80px] xl:px-[180px]'>
                <Checkbox
                    name="checked"
                    label={'addArea.no_street'}
                    key={t('addArea.no_street')}
                    value={area?.address_noStreet ? 'addArea.no_street' : ''}
                    register={register}
                    setValue={setValue}
                    type="checkbox"
                />
            </div>
        </>
    )
}
