import { ChangeEvent, ClipboardEvent, MouseEvent, createRef, useEffect, useRef, useState } from "react";
import { GraphQLQuery } from '@aws-amplify/api';
import { API, Auth, graphqlOperation } from 'aws-amplify';

import { PATH } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GetUserInfoByEmailAddressQuery, UserInfo } from "../../API";
import { getUserInfoByEmailAddress } from "../../graphql/queries";
import { useAuth, useLanguage, useLocalStorage, useProcessTry } from "../../hooks";
import i18n from "../../i18n";

interface ConfirmEmailProps {
    data: { password: string, email: string } | undefined;
}

export const ConfirmEmail = (props: ConfirmEmailProps) => {
    const { data: formData } = props;
    const { loginUser, setToken } = useAuth();
    const { handleCreateError } = useProcessTry();
    const [path] = useLocalStorage(PATH);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { selectedLanguage } = useLanguage({ i18n });

    const [confirmationCode, setConfirmationCode] = useState([
        '',
        '',
        '',
        '',
        '',
        '',
    ]);
    const [confirmationError, setConfirmationError] = useState(false);

    const inputRefs = useRef([...Array(6)].map(() => createRef<HTMLInputElement>()))

    const handleInputChange = (index: number, event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;

        setConfirmationCode((prevCode) => {
            const newCode = [...prevCode]
            newCode[index] = value

            if (value !== '' && index < inputRefs.current.length - 1) {
                inputRefs.current[index + 1].current?.focus()
            }

            return newCode;
        })
    }

    const handlePaste = (event: ClipboardEvent<HTMLFormElement>) => {
        event.preventDefault()
        const pastedData = event.clipboardData.getData('text')

        if (/^\d{6}$/.test(pastedData)) {
            setConfirmationCode(pastedData.split(''))
        }
    }

    const getToken = async (userData?: UserInfo | null) => {
        if (!userData?.email) {
            setToken('');

            return '';
        }

        try {
            const fullname = `${userData.firstName} ${userData.lastName}`;
            const decodedName = encodeURI(fullname);
            const email = encodeURI(userData.email)

            const response = await fetch(
                `https://edc1touev2.execute-api.eu-central-1.amazonaws.com/?method=TwilioToken&email=${email}&identity=${userData.id}&friendlyName=${decodedName}&locale=${selectedLanguage}`
            );
            const data = await response.json();

            return data.token;
        } catch (error) {
            console.log("ERROR", error);
        }
    }

    const handleSubmit = async (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
        e.preventDefault();
        setConfirmationError(false);

        try {
            await Auth.confirmSignUp(formData?.email || '', confirmationCode.join(''));
            
            const user = await Auth.signIn({ username: formData?.email || '', password: formData?.password || ''});

            const { data } = await API.graphql<GraphQLQuery<GetUserInfoByEmailAddressQuery>>(graphqlOperation(getUserInfoByEmailAddress, {
                email: user?.attributes.email
            }));

            const userData = data?.getUserInfoByEmailAddress?.items[0];
            
            if (userData) {
                loginUser(userData);
            }

            const token = await getToken(userData);
            setToken(token);

            if (path) {
                navigate(path);
            }
        } catch (error: any) {
            console.error('Error confirming signup', error);
            setConfirmationError(true);

            const errorData = {
                userInfoSub: '',
                requestBody: JSON.stringify(formData),
                errorMsg: error.message as string,
                process: 'Confirming sign up',
                DateTimeUnix: Math.floor(new Date().getTime() / 1000)
            }

            handleCreateError(errorData);
        }
    }

    useEffect(() => {
        inputRefs.current[0].current?.focus()
    }, [])

    return (
        <div className="px-[25px] py-[20px] md:px-[60px] md:py-[100px] lg:px-[100px] text-center">
            <p className="text-center text-[20px] font-bold md:text-[30px] mb-[30px]">
                {t('confirmation.code')}
                <br />
                {t('confirmation.please_provide')}
            </p>

            <form 
                onPaste={handlePaste}
                className="flex flex-col items-center mb-[30px]"
            >
                <div className="flex md:gap-[20px] gap-[10px] justify-center mb-[30px]">
                    {confirmationCode.map((digit, index) => (
                        <input
                            key={index}
                            type="text"
                            maxLength={1}
                            value={digit}
                            onChange={(event) => handleInputChange(index, event)}
                            className="md:w-[60px] md:h-[80px] w-[45px] h-[65px] border-[1px] border-black 
                                outline-0 md:px-[17px] px-[10px] rounded-[12px] focus:border-primary focus:border-[3px] text-[30px]"
                            ref={inputRefs.current[index]}
                        />
                    ))}
                </div>

                <button
                    className="bg-primary text-white w-[228px] md:w-[225px] py-[10px] rounded-[6px]"
                    onClick={(e) => handleSubmit(e)}
                >
                    {t('confirmation.verify')}
                </button>
            </form>

            {confirmationError && (
                <p className="text-red-300">
                    {t('confirmation.invalid')}
                </p>
            )}
        </div>
    )
}
