import { GraphQLQuery } from "@aws-amplify/api";
import { API, Auth, Hub, graphqlOperation } from "aws-amplify";
import { CreateUserInfoMutation, GetUserInfoByEmailAddressQuery } from "../API";
import { getUserInfoByEmailAddress } from "../graphql/queries";
import { useAuth } from "./useAuth";
import { useLocalStorage } from "./useLocaleStorage";
import { PATH } from "../utils/constants";
import { Links } from "../settings";
import { createUserInfo } from "../graphql/mutations";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export const useSocialLogin = () => {
    const { loginUser } = useAuth();
    const navigate = useNavigate();
    const [path, setPath] = useLocalStorage(PATH, '');
    const [isEmailError, setIsEmailError] = useState(false);

    let userCreatedCalled = false;

    const getUserByEmail = async (email: string) => {
        try {
            const { data } = await API.graphql<GraphQLQuery<GetUserInfoByEmailAddressQuery>>(
                graphqlOperation(getUserInfoByEmailAddress, {
                    email
            }));

            const userData = data?.getUserInfoByEmailAddress?.items[0];

            if (userData) {
                loginUser(userData);
            }

            if (path) {
                navigate(path)
                setPath('')
            } else {
                navigate(Links.index)
            }
        } catch (error) {
            console.log("ERROR", error)
        }
    }

    const handleSocialLogin = () => {
        Hub.listen("auth", async ({ payload }) => {
            switch (payload.event) {
                case 'signIn':
                case 'cognitoHostedUI':
                    const user = await Auth.currentAuthenticatedUser();

                    try {
                        const email = user?.signInUserSession.idToken.payload.email;

                        if (!email) {
                            setIsEmailError(true);
                        }

                        if (!userCreatedCalled) {

                            userCreatedCalled = true;

                            const resp = await API.graphql<GraphQLQuery<GetUserInfoByEmailAddressQuery>>(
                                graphqlOperation(getUserInfoByEmailAddress, {
                                    email: user?.signInUserSession.idToken.payload.email
                                })
                            );

                            if (resp.data?.getUserInfoByEmailAddress?.items.length === 0) {

                                const userInfo = {
                                    email: user?.signInUserSession.idToken.payload.email,
                                    sub: user?.signInUserSession.idToken.payload.sub,
                                    firstName: user?.signInUserSession.idToken.payload.given_name,
                                    lastName: user?.signInUserSession.idToken.payload.family_name,
                                    phone: '',
                                    streetAndHouseNumber: '',
                                    postalCode: '',
                                    city: '',
                                    country: '',
                                    companyName: '',
                                    qualifiedInvestor: false,
                                    companyWebsite: '',
                                    interestedIn: '',
                                    personalStatement: '',
                                    nutzungsbedingungenAnwenderAccepted: false,
                                    nutzungsbedingungenAnwenderTimestamp: new Date().toISOString()
                                };

                                try {
                                    await API.graphql<GraphQLQuery<CreateUserInfoMutation>>(
                                        graphqlOperation(createUserInfo, { input: userInfo })
                                    );

                                    const UserByEmail = await getUserByEmail(user?.signInUserSession.idToken.payload.email);
                                    // console.log("User retrieval success", UserByEmail);
                                    userCreatedCalled = false;

                                } catch (createUserErr) {
                                    console.log("Error creating user", createUserErr);
                                    userCreatedCalled = false;
                                }
                            } else {
                                const UserByEmail = await getUserByEmail(user?.signInUserSession.idToken.payload.email);
                                // console.log("User retrieval success", UserByEmail);
                                userCreatedCalled = false;
                            }
                        }

                    } catch (queryErr) {
                        console.log("Error", queryErr);
                        userCreatedCalled = false;

                    }

                    break;
                case "signInWithRedirect_failure":
                    console.log("An error has ocurred during the OAuth flow.");
                    break;
                case "customOAuthState":
                    console.log("Custom", payload);
                    break;

                default:
                    console.log("PAYLOAAAD", payload)
            }
        });
    }

    return {
        isEmailError,
        setIsEmailError,
        handleSocialLogin
    }
}