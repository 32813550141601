import { Flaeche } from "../API";
import { SizeValues } from "../pages/SearchPage/hooks/useSearch";
import { filterBySize } from "./filterBySize";

export const filterByType = (
    areas: Array<Flaeche | null>, 
    filterType: string, 
    filterMunicipality: string,
    submittedValues?: SizeValues | null
): Array<Flaeche | null> => {
        const filteredByType = areas.filter((area: Flaeche | null): area is Flaeche | null => {
        const type = area?.areaType;

        switch(filterType) {
            case 'building (e.g. roof)':
            case 'gebäude (z.b. dach)':
                return type === 'addArea.roof';

            case 'building':
            case 'gebäude':
                return type === 'addArea.building';

            case 'noise barrier':
            case 'lärmschutzwand':
                return type === 'addArea.noise_barrier';

            case 'field':
            case 'acker':
                return type === 'addArea.field';

            case 'grassland':
            case 'grünland':
                return type === 'addArea.grassland';

            case 'lake':
            case 'see':
                return type === 'addArea.lake';

            case 'others':
            case 'sonstiges':
                return type === 'addArea.others';

            default:
                return area !== null;
        }
    });

    const filteredByAddress = filteredByType.filter(area => {
        const state = area?.address_state;
                
        switch (filterMunicipality) {
            case 'baden-wuerttemberg':
            case 'baden-württemberg': 
                return state === 'addArea.states.Baden-Wuerttemberg';

            case 'bavaria':
            case 'bayern': 
                return state === 'addArea.states.Bavaria';

            case 'berlin':
                return state === 'addArea.states.Berlin';

            case 'brandenburg':
                return state === 'addArea.states.Brandenburg';

            case 'bremen': 
                return state === 'addArea.states.Bremen';

            case 'hamburg':
                return state === 'addArea.states.Hamburg';

            case 'mecklenburg-western pomerania':
            case 'mecklenburg-vorpommern': 
                return state === 'addArea.states.Mecklenburg-Western Pomerania';

            case 'hesse':
            case 'hessen': 
                return state === 'addArea.states.Hesse';

            case 'niedersachsen':
            case 'lower saxony': 
                return state === 'addArea.states.Lower Saxony';

            case 'north rhine-westphalia':
            case 'nordrhein-westfalen': 
                return state === 'addArea.states.North Rhine-Westphalia';

            case 'rhineland-palatinate':
            case 'rheinland-pfalz': 
                return state === 'addArea.states.Rhineland-Palatinate';

            case 'saarland':
                return state === 'addArea.states.Saarland';

            case 'sachsen':
            case 'saxony': 
                return state === 'addArea.states.Saxony';

            case 'sachsen-anhalt':
            case 'saxony-anhalt': 
                return state === 'addArea.states.Saxony-Anhalt';

            case 'schleswig-holstein':
                return state === 'addArea.states.Schleswig-Holstein'

            case 'thuringia':
            case 'thüringen': 
                return state === 'addArea.states.Thuringia';

            default:
                return area !== null;
        }
    });

    return filterBySize(filteredByAddress, submittedValues)
};
