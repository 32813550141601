import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import languageEN from "./en/translate.json";
import languageDE from "./de/translate.json";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";

const resources = {
  'en': languageEN,
  'de': languageDE
}

const lang = navigator.language;

if (localStorage.getItem('i18nextLng') === null) {
  if (lang.includes('de')) {
    localStorage.setItem('i18nextLng', 'de');
  } else {
    localStorage.setItem('i18nextLng', 'en');
  }
}

i18n
.use(I18nextBrowserLanguageDetector)
.use(initReactI18next)
.init({
  debug: false,
  fallbackLng: 'de',
  interpolation: {
    escapeValue: false,
  },
  resources
});

export default i18n;