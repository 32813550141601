import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

interface TabProps {
    selectedTab: number;
    tab: number;
    title: string;
    setTab: Dispatch<SetStateAction<number>>;
}

export const Tab = ({
    selectedTab,
    setTab,
    tab,
    title
}: TabProps) => {
  const { t } = useTranslation();

  return (
    <div className={`cursor-pointer py-[15px] px-[25px] rounded-t-[8px] ${selectedTab === tab
        ? 'bg-primary' 
        : 'border-t-[1px] border-l-[1px] border-r-[1px]'}`
        }
        onClick={() => setTab(tab)}
    >
        {t(title)}
    </div>
  )
}
