export const formatFileName = (url: string, areaId: string) => {
    const pathname = new URL(url).pathname;

    const fileNameWithoutEncodedChars = pathname.split('/').pop();

    const decodedFileName = fileNameWithoutEncodedChars 
    ? decodeURIComponent(fileNameWithoutEncodedChars)
    : fileNameWithoutEncodedChars;

    const modifiedFileName = decodedFileName?.replace(`${areaId}_`, '');

    const displayedFileName = modifiedFileName && modifiedFileName?.length <= 14
    ? modifiedFileName
    : `${modifiedFileName?.slice(0, 11)}...${modifiedFileName?.slice(-4)}`;

    return displayedFileName;
}