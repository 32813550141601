import { createContext, ReactNode, useState } from 'react';

interface MessagesContextType {
    unreadCount: number;
    setUnreadCount: (val: number) => void;
}

const initialMessagesContext = {
    unreadCount: 0,
    setUnreadCount: () => {}
};

const MessagesContext = createContext<MessagesContextType>(initialMessagesContext);

interface MessagesProviderProps {
    children: ReactNode;
}

const MessagesProvider = ({ children }: MessagesProviderProps) => {
    const [unreadCount, setUnreadCount] = useState(0);

    return (
        <MessagesContext.Provider
            value={{ unreadCount, setUnreadCount }}
        >
            {children}
        </MessagesContext.Provider>
    );
};

export { MessagesContext, MessagesProvider };