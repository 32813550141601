import { FormControlLabel, TableCell, TableRow } from '@mui/material';
import { UserInfo } from '../../../API';
import { useTranslation } from 'react-i18next';
import { UseOnboardingItem, useOrganizationItem, useUser } from '../hooks';
import { IOSSwitch } from '../styled';
import { AreaTypesModal } from './AreaTypesModal';
import { MembersBlock } from './MembersBlock';

interface OnboardingItemProps {
    user: UserInfo | null;
}

export const OnboardingItem = ({
    user
}: OnboardingItemProps) => {
    const { t } = useTranslation();
    const { userImage } = useUser({ user });
    const {
        organization,
        qualifiedInvestor,
        handleStatusChange
    } = UseOnboardingItem({ user });
    const {
        members,
        isEditMode,
        exclusiveAccess,
        handleAccessChange,
        handleCheck,
        selectedOptions,
        setIsEditMode
    } = useOrganizationItem({ organization });

    return (
        <>
            <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                className="cursor-pointer"
            >
                <TableCell>
                    <div className="h-[50px] w-[50px] overflow-hidden rounded-[50%] border-[1px]">
                        <img
                            src={userImage 
                                ? userImage 
                                : '/assets/icons/profile-icon.png'
                            }
                            alt=""
                            className="h-[50px] w-[50px] rounded-[50%]"
                        />
                    </div>
                </TableCell>

                <TableCell>
                    {user?.qualifiedInvestor && (
                        <div className="rounded-[5px] bg-primary px-[2px] text-center text-white">
                            {t('admin_dashboard.investor')}
                        </div>
                    )}

                    {(user?.adminCustomerService || user?.adminFull) && (
                        <div className="rounded-[5px] bg-red-500 px-[2px] text-center text-white">
                            {t('admin_dashboard.admin')}
                        </div>
                    )}
                </TableCell>

                <TableCell>
                    {user?.firstName} {user?.lastName}
                </TableCell>

                <TableCell>{user?.email}</TableCell>

                <TableCell>
                    <div className='w-full flex justify-center'>
                        {organization 
                            ? organization?.CompanyName 
                            : <img 
                                src="/assets/icons/closed.webp" 
                                className='h-[20px]' 
                            />
                        }
                    </div>
                </TableCell>

                <MembersBlock
                    organization={organization} 
                    members={members}
                />

                <TableCell>
                    <div className="flex w-full justify-center">
                        <FormControlLabel
                            control={
                                <IOSSwitch
                                    sx={{ m: 1 }}
                                    checked={qualifiedInvestor}
                                    onChange={handleStatusChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label=""
                            className="mb-[20px]"
                        />
                    </div>
                </TableCell>

                <TableCell>
                    <div className="flex w-full justify-center">
                        {organization ? (
                            <FormControlLabel
                                control={
                                    <IOSSwitch
                                        sx={{ m: 1 }}
                                        checked={exclusiveAccess}
                                        onChange={handleAccessChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                label=""
                                className="mb-[20px]"
                            />
                        ) : (
                            <img 
                                src="/assets/icons/closed.webp" 
                                className='h-[20px]' 
                            />
                        )}
                    </div>
                </TableCell>

                <TableCell>
                    {organization ? (
                        <div className="flex w-full flex-col justify-center">
                            {selectedOptions?.map((type) => (
                                <p className="mb-[6px] text-[12px]">{t(type)}</p>
                            ))}

                            <div className="flex justify-center">
                                <div 
                                    className="bg-secondary text-white px-[5px] rounded-[8px] w-fit"
                                    onClick={() => setIsEditMode(true)}
                                >
                                    {t('admin_dashboard.edit')}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="flex w-full justify-center">
                            <img 
                                src="/assets/icons/closed.webp" 
                                className='h-[20px] w-[20px]' 
                            />
                        </div>
                    )}
                </TableCell>
            </TableRow>

            {isEditMode && organization && (
                <AreaTypesModal 
                    organization={organization}
                    onClose={setIsEditMode}
                    onCheck={handleCheck}
                    selected={selectedOptions}
                />
            )}
        </>
    )
}
