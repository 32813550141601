import { handleGetMessageTime } from './../../../helpers/getMessageTime';
import { useCallback, useEffect, useState } from 'react';
import { Client, Conversation, Message } from '@twilio/conversations';

interface Options {
    client?: Client | null;
}

export const useConversations = ({ client }: Options) => {
    const [conversations, setConversations] = useState<Conversation[]>([]);
    const [selectedConversation, setSelectedConversation] = useState<Conversation | null>(null);
    const [messages, setMessages] = useState<Message[]>([]);
    const [isSending, setIsSending] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [files, setFiles] = useState<File[]>([]);

    const handleOpenChat = useCallback(async (conv: Conversation) => {
        setSelectedConversation(conv);
    }, [])

    const handleCloseChat = useCallback(() => {
        setSelectedConversation(null);
    }, [])

    const fetchConversations = async () => {
        try {
            setIsLoading(true);
            const chats = await client?.getSubscribedConversations();

            setIsLoading(false);

            if (chats?.items.length) {
                setConversations(chats.items);
            }
        } catch (error) {
            console.log("Error loading conversations:", error);
            setIsLoading(false);
        }
    }

    const fetchMessages = async () => {
        setMessages([]);
        const messages = await selectedConversation?.getMessages();

        setMessages(messages?.items || []);
    }

    const handleMessageAdded = async (message: Message) => {
        setMessages(prev => [...prev, message]);
        setIsSending(false);
    };

    const updateUnreadMessages = async () => {
        if (messages.length) {
            await selectedConversation?.updateLastReadMessageIndex(messages[messages.length - 1]?.index);
        }
    }

    const handleSendMessage = async (message: string) => {
        try {
            if (!message && files.length === 0) {
                return;
            }

            if (selectedConversation) {
                setIsSending(true);
                const newMessageBuilder = selectedConversation.prepareMessage().setBody(message);

                for (const file of files) {
                    const fileData = new FormData();
                    fileData.set(file.name, file, file.name);

                    newMessageBuilder.addMedia(fileData);
                }

                await newMessageBuilder.build().send();
                setFiles([]);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleCloseConversation = useCallback(() => {
        setSelectedConversation(null);
    }, [selectedConversation])

    const handleDeleteMessage = async (message: Message) => {
        try {
            await message.remove();

            const filteredMessages = messages.filter(el => el.sid !== message.sid);
            setMessages(filteredMessages);
        } catch (error) {
            console.log("Error deleting message:", error)
        }
    }

    useEffect(() => {
        fetchConversations();
    }, [client])

    useEffect(() => {
        if (!selectedConversation) {
            return;
        }

        selectedConversation?.on('messageAdded', handleMessageAdded);

        return () => {
            selectedConversation?.removeListener("messageAdded", handleMessageAdded);
        };
    } , [selectedConversation])

    useEffect(() => {
        updateUnreadMessages();
    }, [selectedConversation, messages])

    return {
        conversations,
        messages,
        files,
        setFiles,
        selectedConversation,
        setMessages,
        fetchMessages,
        isSending,
        isLoading,
        handleCloseChat,
        handleOpenChat,
        handleCloseConversation,
        handleDeleteMessage,
        handleSendMessage,
        handleGetMessageTime,
        setSelectedConversation
    }
}
