import { RefObject } from "react";

interface SliderArrowsProps {
    sliderRef:  RefObject<HTMLDivElement>;
    sx?: string;
    value?: number;
}

export const SliderArrows = ({ sliderRef, sx, value }: SliderArrowsProps) => {
    const scrollLeft = () => {
        if (sliderRef.current) {
            sliderRef.current.scrollLeft -= value ? value : 600;
        }
    };

    const scrollRight = () => {
        if (sliderRef.current) {
            sliderRef.current.scrollLeft += value ? value : 600;
        }
    };

    return (
        <div className={`absolute flex gap-[15px] ${sx ? sx : 'right-[170px] top-[50px]'}`}>
            <div
                className={`flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full ${sx ? 'bg-white' : 'bg-[#F2F2F2]'}`}
                onClick={scrollLeft}
            >
                <img 
                    src="/assets/icons/arrowLeft.svg" 
                    alt="arrow left" 
                    className="h-[20px] w-[20px]"
                />
            </div>

            <div
                className={`flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full ${sx ? 'bg-white' : 'bg-[#F2F2F2]'}`}
                onClick={scrollRight}
            >
                <img 
                    src="/assets/icons/arrowRight.svg" 
                    alt="arrow right" 
                    className="h-[20px] w-[20px]"
                />
            </div>
        </div>
    )
}
