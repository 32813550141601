import { useTranslation } from 'react-i18next';
import { Flaeche } from '../../../API';
import { handleIconColor } from "../../../helpers/iconColor";
import {ReactComponent as ClockIcon} from '../../../assets/icons/plan.svg';
import {ReactComponent as QualityIcon} from '../../../assets/icons/Iconbuilding.svg';
import {ReactComponent as PowerIcon} from '../../../assets/icons/power-generation.svg';
import {ReactComponent as ContractIcon} from '../../../assets/icons/contract.svg';
import {ReactComponent as ThumbsUpIcon} from '../../../assets/icons/thumbs-up.svg';
import {ReactComponent as SolarIcon} from '../../../assets/icons/solar-panel.svg';
import {ReactComponent as PlanIcon} from '../../../assets/icons/Unionconstruction.svg';
import { ProgressDesc } from "../../ProjectDetails/components";

interface ProgressOrgProps {
    area: Flaeche;
}

export const ProgressOrg = ({ area }: ProgressOrgProps) => {
    const { t } = useTranslation();

    return (
        <div className="w-full flex justify-center h-[50px]">
            <div className="flex flex-wrap gap-[5px] text-white">
                {area?.progressZustimmung &&
                area?.progressZustimmung !== 'false' ? (
                    <div
                        className={`${handleIconColor(
                            area?.progressZustimmung
                        )} group relative cursor-pointer`}
                    >
                        <ThumbsUpIcon className="h-[23px] w-[30px]" />

                        <ProgressDesc
                            description={t(
                                'addArea.planning_process_items.agreement.desc'
                            )}
                            status={t(area?.progressZustimmung)}
                            sx
                        />
                    </div>
                ) : null}

                {area?.progressPachtvertragAbgeschlossen &&
                area?.progressPachtvertragAbgeschlossen !== 'false' ? (
                    <div
                        className={`${handleIconColor(
                            area?.progressPachtvertragAbgeschlossen
                        )} group relative cursor-pointer`}
                    >
                        <ContractIcon className="h-[23px] w-[30px]" />

                        <ProgressDesc
                            description={t(
                                'addArea.planning_process_items.contract.desc'
                            )}
                            status={t(area?.progressPachtvertragAbgeschlossen)}
                            sx
                        />
                    </div>
                ) : null}

                {area?.progressEinspeisezusage &&
                area?.progressEinspeisezusage !== 'false' ? (
                    <div
                        className={`${handleIconColor(
                            area?.progressEinspeisezusage
                        )} group relative cursor-pointer`}
                    >
                        <PowerIcon className="h-[23px] w-[30px]" />

                        <ProgressDesc
                            description={t(
                                'addArea.planning_process_items.grid_connection.desc'
                            )}
                            status={t(area?.progressEinspeisezusage)}
                            sx
                        />
                    </div>
                ) : null}

                {area?.progressAufstellungsbeschluss &&
                area?.progressAufstellungsbeschluss !== 'false' ? (
                    <div
                        className={`${handleIconColor(
                            area?.progressAufstellungsbeschluss
                        )} group relative cursor-pointer`}
                    >
                        <ClockIcon className="h-[23px] w-[30px]" />

                        <ProgressDesc
                            description={t(
                                'addArea.planning_process_items.approval_request.desc'
                            )}
                            status={t(area?.progressAufstellungsbeschluss)}
                            sx
                        />
                    </div>
                ) : null}

                {area?.progressBebauungsplan &&
                area?.progressBebauungsplan !== 'false' ? (
                    <div
                        className={`${handleIconColor(
                            area?.progressBebauungsplan
                        )} group relative cursor-pointer`}
                    >
                        <QualityIcon className="h-[23px] w-[30px]" />

                        <ProgressDesc
                            description={t(
                                'addArea.planning_process_items.approval_grant.desc'
                            )}
                            status={t(area?.progressBebauungsplan)}
                            sx
                        />
                    </div>
                ) : null}

                {area?.progressBauvertrag &&
                area?.progressBauvertrag !== 'false' ? (
                    <div
                        className={`${handleIconColor(
                            area?.progressBauvertrag
                        )} group relative cursor-pointer`}
                    >
                        <PlanIcon className="h-[23px] w-[30px]" />

                        <ProgressDesc
                            description={t(
                                'addArea.planning_process_items.plan.desc'
                            )}
                            status={t(area?.progressBauvertrag)}
                            sx
                        />
                    </div>
                ) : null}

                {area?.progressAnlageGebaut &&
                area?.progressAnlageGebaut !== 'false' ? (
                    <div
                        className={`${handleIconColor(
                            area?.progressAnlageGebaut
                        )} group relative cursor-pointer`}
                    >
                        <SolarIcon className="h-[23px] w-[30px]" />

                        <ProgressDesc
                            description={t(
                                'addArea.planning_process_items.building.desc'
                            )}
                            status={t(area?.progressAnlageGebaut)}
                            sx
                        />
                    </div>
                ) : null}
            </div>
        </div>
    )
}
