import { useLocation } from "react-router-dom";
import { useAuth, useLanguage } from ".";
import { useCallback, useEffect, useState } from "react";
import { Client } from "@twilio/conversations";
import i18n from "../i18n";

export const useChatClient = () => {
    const { pathname } = useLocation();
    const { loggedIn, token, setToken } = useAuth();
    const { selectedLanguage } = useLanguage({ i18n });

    const [client, setClient] = useState<Client | null>(null);

    const getToken = useCallback(async () => {
        if (!loggedIn?.email) {
            setToken('');

            return;
        }

        try {
            const fullname = `${loggedIn.firstName} ${loggedIn.lastName}`;
            const decodedName = encodeURI(fullname);
            const email = encodeURI(loggedIn.email)

            const response = await fetch(
                `https://edc1touev2.execute-api.eu-central-1.amazonaws.com/?method=TwilioToken&email=${email}&identity=${loggedIn.id}&friendlyName=${decodedName}&locale=${selectedLanguage}`
            );
            
            const data = await response.json();

            setToken(data.token);

            return data.token;
        } catch (error) {
            console.log("ERROR", error);
        }
    }, [loggedIn])

    useEffect(() => {
        const initializeClient = async () => {
            try {
                if (!token) {
                    setClient(null);
                    return;
                }

                const newClient = new Client(token);
                setClient(newClient);
            } catch (error) {
                console.error("Error initializing client:", error);
            }
        };

        initializeClient();
    }, [pathname]);

    return {
        client,
        getToken
    }
}