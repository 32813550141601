import { Close } from '@mui/icons-material';
import { Conversation } from '@twilio/conversations';
import { useEffect, useMemo } from 'react';
import { useAuth } from '../../../hooks';
import { useTranslation } from 'react-i18next';
import { useAttributes } from '../hooks/useAttributes';
import { useChatItem } from '../hooks/useChatItem';

interface ChatItemProps {
    conversation: Conversation;
    onOpen: (conver: Conversation) => void;
    selected: Conversation | null;
    query: string;
    onRemove: (conver: Conversation) => void;
}

export const ChatItem = ({
    conversation,
    onOpen,
    query,
    onRemove,
    selected,
}: ChatItemProps) => {
    const { loggedIn } = useAuth();
    const { t } = useTranslation();
    const {
        user,
        userImage,
        areaDetails,
        imagesArea,
        attributes,
        getAreaDetails,
        getUserInfo,
    } = useAttributes({ conversation });

    const {
        messages,
        lastMessage,
        unreadCount
    } = useChatItem({ conversation, selected });

    const filterCondition = useMemo(() => {
        const lowerQuery = query.toLowerCase();
        
        return (t(areaDetails?.address_state ? areaDetails?.address_state : '')).toLowerCase().includes(lowerQuery) 
            || (t(areaDetails?.address_country ? areaDetails.address_country : '')).toLowerCase().includes(lowerQuery) 
            || `${user?.firstName} ${user?.lastName}`.toLowerCase().includes(lowerQuery);
    }, [areaDetails, user, query])

    useEffect(() => {
        if (attributes.userEmail === loggedIn.email) {
            getAreaDetails();

            return;
        }

        getUserInfo();
    }, [])

    if (!areaDetails && attributes.userEmail === loggedIn.email) {
        return null;
    }

    return (
        <>
        {filterCondition ? (
            <>
                <div
                    className={`group flex cursor-pointer gap-[30px] px-[5px] py-[10px] relative transition-all xl:px-[25px]
                        ${
                            selected?.uniqueName === conversation.uniqueName
                                ? 'bg-[#c5e1a5]'
                                : 'hover:bg-gray-200'
                        }
                    `}
                    onClick={() => onOpen(conversation)}
                >
                    <img
                        src={areaDetails 
                            ? imagesArea.find(el => el.includes('Screenshot')) 
                            : userImage || '/assets/icons/profile-icon.png'
                        }
                        alt="profile"
                        className="h-[60px] w-[60px] rounded-full object-center [@media(min-width:1024px)]:mt-[15px] [@media(min-width:1200px)]:mt-0"
                    />

                    <div className="flex w-full justify-between">
                        {areaDetails?.address_country && (
                            <div className='[@media(min-width:1024px)]:mt-[15px] [@media(min-width:1200px)]:mt-0'>
                                <p className="text-[18px] font-medium">
                                    {t(areaDetails.address_state) || t(areaDetails.address_country)}
                                </p>

                                <div className='flex gap-[5px]'>
                                    {lastMessage?.author === loggedIn.id && (
                                        <p className='text-[13px]'>{t('profile.you')}</p>
                                    )}

                                    {lastMessage && (
                                        <>
                                            {lastMessage?.body ? (
                                                <p className="text-[14px] text-[#9e9e9e]">
                                                    {messages[messages.length - 1]?.body}
                                                </p>
                                            ) : (
                                                <div className='flex gap-[5px] items-center text-[12px]'>
                                                    <img src='/assets/icons/file_copy.svg' className='h-[20px]'/>
                                                    <p>{t('profile.document')}</p>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                        </div>
                        )}

                        {user && (
                            <div className='[@media(min-width:1024px)]:mt-[15px] [@media(min-width:1200px)]:mt-0'>
                                <p className="text-[18px] font-medium">
                                    {`${user?.firstName} ${user?.lastName}`}
                                </p>

                                <div className='flex gap-[5px]'>
                                    {lastMessage?.author === loggedIn.id && (
                                        <p className='text-[13px]'>{t('profile.you')} </p>
                                    )}

                                    {lastMessage && (
                                        <>
                                            {lastMessage?.body ? (
                                                <p className="text-[14px] text-[#9e9e9e]">
                                                    {messages[messages.length - 1]?.body}
                                                </p>
                                            ) : (
                                                <div className='flex gap-[5px] items-center text-[12px]'>
                                                    <img src='/assets/icons/file_copy.svg' className='h-[20px]'/>
                                                    <p>{t('profile.document')}</p>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        )}

                        <div className="flex flex-col items-end gap-[3px] absolute md:right-[20px] right-[10px]">
                            {/* <div onClick={() => onRemove(conversation)}>
                                <div className="opacity-0 transition-all group-hover:opacity-100">
                                    <Close
                                        sx={{ height: '15px' }}
                                        className="mr-[-5px] xl:mr-[-20px]"
                                    />
                                </div>
                            </div> */}

                            {unreadCount > 0 && (
                                <div className="flex h-[18px] w-[18px] items-center justify-center rounded-full bg-secondary text-[14px] text-white">
                                    {unreadCount}
                                </div>
                            )}

                            <p className="text-[10px] text-[#9e9e9e]">
                                {conversation.lastMessage?.dateCreated?.toLocaleDateString()}
                            </p>
                        </div>
                    </div>
                </div>

                <hr />
            </>
        ) : null}
        </>
    )
}
