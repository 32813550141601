import { useEffect, useState } from 'react'
import { useLanguage, useProcessTry } from '../../../hooks';
import {
    CreateOrganizationMutation,
    ListOrganizationsQuery,
    Organization,
    UpdateOrganizationMutation,
    UpdateUserInfoMutation,
    UserInfo,
} from '../../../API';
import i18n from '../../../i18n';
import { API, Storage, graphqlOperation } from 'aws-amplify';
import { GraphQLQuery } from '@aws-amplify/api';
import { listOrganizations } from '../../../graphql/queries';
import { createOrganization, updateOrganization, updateUserInfo } from '../../../graphql/mutations';
import { FieldValues } from 'react-hook-form';

interface Options {
    user: UserInfo;
    onLoading: (val: boolean) => void;
    imageToUpload: File | null;
    onUser: () => void;
}

export const useEditInformation = ({ 
    user, 
    imageToUpload, 
    onLoading, 
    onUser 
}: Options) => {
    const { handleCreateError } = useProcessTry();
    const { selectedLanguage } = useLanguage({ i18n });

    const [organization, setOrganization] = useState<Organization | null | undefined>(null);
    const [invitedAsOwner, setInvitedAsOwner] = useState<(Organization | null)[] | undefined>();
    const [error, setError] = useState('');

    const getOrganization = async () => {
        if (!user) return;

        try {
            const { data } = await API.graphql<GraphQLQuery<ListOrganizationsQuery>>(
                graphqlOperation(listOrganizations, { 
                    filter: {
                        OwnerOfOrganization: { eq: user.sub },
                }})
            );

            const org = data?.listOrganizations?.items.find(el => !el?.disabledOrganization);
    
            setOrganization(org);
        } catch (error) {
            console.log(error);
        }
    }

    const getInvitedAsOwner = async () => {
        if (!user) return;

        try {
            const { data } = await API.graphql<GraphQLQuery<ListOrganizationsQuery>>(
                graphqlOperation(listOrganizations, { 
                    filter: {
                        TransferOwnershipToEMail: { contains: user.email },
                }})
            )
    
            setInvitedAsOwner(data?.listOrganizations?.items);
        } catch (error) {
            console.log(error);
        }
    }

    const onSubmit = async (data: FieldValues) => {
        const formattedPhone = data.phone.replace(/[^0-9+]/g, '');
        if (user) {
            const editingUser = {
                sub: user.sub,
                firstName: data.name,
                lastName: data.surname,
                phone: formattedPhone || '',
                streetAndHouseNumber: data.street,
                profileImageFilename: imageToUpload 
                        ? `${user?.sub}_${imageToUpload?.name}` 
                        : user?.profileImageFilename,
                postalCode: data.postalCode,
                city: data.city,
                country: data.country,
                interestedIn: data.interest,
                personalStatement: data.biography,
                locale: selectedLanguage,
                id: user.id
            }

            const newOrganization = {
                OwnerOfOrganization: user.sub,
                CompanyName: data.company,
                websiteDomain: data.website || '',
                addressStreetAddress: data.companyStreet || '',
                addressPostalCode: data.companyPostal || '',
                addressCity: data.companyCity || '',
                addressCountry: data.companyCountry || '',
                vatID: data.VATID || '',
                disabledOrganization: false,
            };

            onLoading(true);
            setError('');

            try {
                const res = await API.graphql<GraphQLQuery<UpdateUserInfoMutation>>(
                    graphqlOperation(updateUserInfo, { input: editingUser })
                );

                if (imageToUpload) {
                    if (res && res.data && res.data.updateUserInfo) {
                        await Storage.put(
                            `${res.data.updateUserInfo.sub}_${imageToUpload.name}`,
                            imageToUpload
                        )
                    }
                }

                if (!organization && data.company) {
                    await API.graphql<GraphQLQuery<CreateOrganizationMutation>>(
                        graphqlOperation(createOrganization, { input: newOrganization })
                    );
                } else {
                    if (organization && data.company) {
                        const editingOrg = {
                            id: organization.id,
                            ...newOrganization
                        }
    
                        await API.graphql<GraphQLQuery<UpdateOrganizationMutation>>(
                            graphqlOperation(updateOrganization, { input: editingOrg })
                        )
                    }
                }

                onUser();
                onLoading(false);
            } catch (error: any) {
                console.log(error);
                setError(error.message);
                onLoading(false);

                const errorData = {
                    userInfoSub: user.sub,
                    requestBody: JSON.stringify(editingUser),
                    response: error,
                    errorMsg: error.message as string,
                    process: 'Edit profile info',
                    DateTimeUnix: Math.floor(new Date().getTime() / 1000)
                };
            
                handleCreateError(errorData);
            }
        }
    }

    useEffect(() => {
        getOrganization();
        getInvitedAsOwner();
    }, [user])

    return {
        organization,
        onSubmit,
        setOrganization,
        invitedAsOwner,
        setInvitedAsOwner,
        error,
        getOrganization
    }
}
