import { useEffect, useMemo, useState } from "react";
import { GetUserInfoByEmailAddressQuery, UserInfo } from "../../../API";
import { useDetails } from "../../ProjectDetails/hooks/useDetails";
import { API, Storage, graphqlOperation } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { Conversation } from '@twilio/conversations';
import { getUserInfoByEmailAddress } from "../../../graphql/queries";

interface Options {
    conversation: Conversation | null;
}

export const useAttributes = ({ conversation}: Options) => {
    const [userImage, setUserImage] = useState('');
    const [user, setUser] = useState<UserInfo | null | undefined>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [imagesArea, setImagesArea] = useState<string[]>([]);
    const attributes = conversation?.attributes as { areaId?: string, userEmail?: string };

    const { 
        getAreaDetails, 
        areaDetails, 
        areaImages,
        setAreaDetails
    } = useDetails({ areaId: attributes?.areaId });

    const getUserInfo = async () => {
        setAreaDetails(null);
        setIsLoading(true);

        try {
            const res = await API.graphql<
                GraphQLQuery<GetUserInfoByEmailAddressQuery>
            >(
                graphqlOperation(getUserInfoByEmailAddress, {
                    email: attributes?.userEmail,
                })
            )

            const userId = res.data?.getUserInfoByEmailAddress?.items[0]?.sub;

            const storageResults = await Storage.list(userId ? userId : "");

            for (const file of storageResults.results) {
                if (file?.key && userId && file?.key.includes(userId)) {
                    const fileName =
                        res.data?.getUserInfoByEmailAddress?.items[0]
                            ?.profileImageFilename || '';

                    try {
                        const expirationTime = 7 * 24 * 60 * 60;
                        
                        const fileRes = await Storage.get(fileName, { expires: expirationTime });
                        setUserImage(fileRes);
                    } catch (err) {
                        console.log('File error:', err);
                    }
                }
            }

            setIsLoading(false);
            setUser(res?.data?.getUserInfoByEmailAddress?.items[0])
        } catch (error: any) {
            console.log('ERROR', error);
            setIsLoading(false);
        }
    }

    const chatImage = useMemo(() => {
        return areaDetails
            ? areaImages.find((el) => el.includes('Screenshot'))
            : userImage || '/assets/icons/profile-icon.png'
    }, [areaDetails, areaImages, userImage])

    const clearCacheImages = () => {
        setUserImage('');
        setImagesArea([]);
    }

    useEffect(() => {
        setImagesArea(areaImages);
    }, [areaImages, conversation])

    return {
        user,
        userImage,
        areaDetails,
        imagesArea,
        attributes,
        getAreaDetails,
        getUserInfo,
        chatImage,
        setUser,
        clearCacheImages
    }
}
