export const types = [
    {
        image: '/assets/images/farmLand.png',
        title: 'addArea.field'
    },
    {
        image: '/assets/images/roof.png',
        title: 'addArea.roof'
    },
    {
        image: '/assets/images/building.png',
        title: 'addArea.building'
    },
    {
        image: '/assets/images/lake.png',
        title: 'addArea.lake'
    },
    {
        image: '/assets/images/noise-barrier.jpeg',
        title: 'addArea.noise_barrier'
    },
    {
        image: '/assets/images/grass-land.jpeg',
        title: 'addArea.grassland'
    },
]