import { useTranslation } from "react-i18next";

interface AreaButtonsProps {
    title: string;
    onDelete: () => void;
    onEdit: () => void;
    onStatus: () => Promise<void>;
}

export const AreaButtons = ({ 
    title, 
    onDelete, 
    onEdit, 
    onStatus 
}: AreaButtonsProps) => {
    const { t } = useTranslation();

    return (
        <div className="flex gap-[10px]">
            <button
                className="h-[30px] rounded-[8px] bg-primary px-[15px] text-[12px]"
                onClick={onEdit}
                type="button"
            >
                {t('admin_dashboard.edit')}
            </button>

            <button
                className="h-[30px] rounded-[8px] bg-secondary px-[15px] text-white text-[12px]"
                onClick={onStatus}
                type="button"
            >
                {t(title)}
            </button>

            <button
                className="h-[30px] rounded-[8px] bg-red-500 px-[15px] text-white text-[12px]"
                onClick={onDelete}
                type="button"
            >
                {t('admin_dashboard.delete')}
            </button>
        </div>
    )
}
