/** @format */

import * as React from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import { Links } from '../../types';

interface NavProps {
    links: Links[]
    onClose?: () => void
}

export const Nav = memo(({ links, onClose }: NavProps): JSX.Element => {
    const { t } = useTranslation()

    return (
        <ul className="flex flex-col pl-[48px] lg:gap-[30px] pt-[76px] text-2xl lg:ml-[70px] md:ml-[20px] md:flex-row md:pl-0 md:pt-0 md:text-base">
            {React.Children.toArray(
                links.map((link: Links) => (
                    <li className="mb-[30px] font-bold sm:mr-5 md:mb-0 md:mr-[25px] md:font-medium lg:mr-[50px]">
                        <Link to={`/#${link.link}`} onClick={onClose}>
                            {t(link.name)}
                        </Link>
                    </li>
                ))
            )}
        </ul>
    )
})
