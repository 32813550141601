import { Client } from "@twilio/conversations";
import { AuthRouteWrapper, PrivateRoute } from "../components";
import {
    AboutUs,
    AdminDashboard,
    ArticleDetails,
    BlogPage,
    BrokerTerms,
    CancellationPolicy,
    ChatPage,
    Cookies,
    Feedback,
    ForgotPassword,
    GetStartedInvestors,
    GetStartedOwners,
    Home,
    Imprint,
    InvestorPricing,
    InvestorTerms,
    LegalPolicy,
    Login,
    NotFoundPage,
    OfferMetaAds,
    OfferPage,
    OurVision,
    OwnersFAQ,
    PressContact,
    Pricing,
    PricingEnterprise,
    Privacy,
    ProfilePage,
    ProjectDetails,
    SearchPage,
    ServiceTerms,
    Terms,
    ThankYou,
    UserAreas,
} from '../pages';
import { Paths } from "../settings";
import { GetQuote } from "../pages/Home/components";
import { Compensation } from "../pages/Compensation";
import { JobOffer } from "../pages/JobOffer";
import { PowerGrid } from "../pages/PowerGrid";

export const routes = (client: Client | null, isMobile: boolean) =>  [
    {
        path: Paths.index,
        element: <Home />,
    },
    {
        path: Paths.login.index,
        children: [
            {
                path: '',
                element: <AuthRouteWrapper />,
                children: [{ path: '', element: <Login /> }],
            },
        ],
    },
    {
        path: Paths.signup.index,
        children: [
            {
                path: '',
                element: <AuthRouteWrapper />,
                children: [{ path: '', element: <Login /> }],
            },
        ],
    },
    {
        path: Paths.resetPassword.index,
        children: [
            {
                path: '',
                element: <AuthRouteWrapper />,
                children: [{ path: '', element: <ForgotPassword /> }],
            },
        ],
    },
    {
        path: Paths.profile.index,
        children: [
            {
                path: '',
                element: <PrivateRoute element={<ProfilePage />} />,
            },
        ],
    },
    {
        path: Paths.chat.index,
        children: [
            {
                path: '',
                element: <PrivateRoute element={<ChatPage client={client} />} />,
            },
        ],
    },
    {
        path: Paths.investorPricing.index,
        children: [
            {
                path: '',
                element: <Pricing />,
            },
        ],
    },
    {
        path: Paths.pricingEnterprise.index,
        children: [
            {
                path: '',
                element: <PricingEnterprise />,
            },
        ],
    },
    {
        path: Paths.search.index,
        children: [
            {
                path: '',
                element: <PrivateRoute element={<SearchPage />} />,
            },
            {
                path: ':areaId',
                element: <PrivateRoute element={<ProjectDetails twilioClient={client} />} />,
            },
        ],
    },
    {
        path: Paths.offer.index,
        children: [
            {
                path: '',
                element: <PrivateRoute element={isMobile ? <OfferMetaAds /> : <OfferPage />} />,
            },
            {
                path: ':areaId',
                element: <PrivateRoute element={<OfferPage />} />,
            },
        ],
    },
    {
        path: Paths.offerMetaAds.index,
        children: [
            {
                path: '',
                element: <PrivateRoute element={<OfferMetaAds />} />,
            },
        ],
    },
    {
        path: Paths.thank_you.index,
        children: [
            {
                path: '',
                element: <PrivateRoute element={<ThankYou />} />,
            },
        ],
    },
    {
        path: Paths.adminDashboard.index,
        children: [
            {
                path: '',
                element: <PrivateRoute element={<AdminDashboard />} />,
            },
        ],
    },
    {
        path: Paths.about.index,
        children: [
            {
                path: '',
                element: <AboutUs />,
            },
        ],
    },
    {
        path: Paths.vision.index,
        children: [
            {
                path: '',
                element: <OurVision />,
            },
        ],
    },
    {
        path: Paths.imprint.index,
        children: [
            {
                path: '',
                element: <Imprint />,
            },
        ],
    },
    {
        path: Paths.contact.index,
        children: [
            {
                path: '',
                element: <GetQuote />
            }
        ]
    },
    {
        path: Paths.terms.index,
        children: [
            {
                path: '',
                element: <Terms />,
            },
        ],
    },
    {
        path: Paths.privacy.index,
        children: [
            {
                path: '',
                element: <Privacy />,
            },
        ],
    },
    {
        path: Paths.cookies.index,
        children: [
            {
                path: '',
                element: <Cookies />,
            },
        ],
    },
    {
        path: Paths.legal.index,
        children: [
            {
                path: '',
                element: <LegalPolicy />,
            },
        ],
    },
    {
        path: Paths.serviceTerms.index,
        children: [
            {
                path: '',
                element: <ServiceTerms />,
            },
        ],
    },
    {
        path: Paths.investorTerms.index,
        children: [
            {
                path: '',
                element: <PrivateRoute element={<InvestorTerms />} />,
            },
        ],
    },
    {
        path: Paths.broker.index,
        children: [
            {
                path: '',
                element: <PrivateRoute element={<BrokerTerms />} />,
            },
        ],
    },
    {
        path: Paths.cancellation.index,
        children: [
            {
                path: '',
                element: <CancellationPolicy />,
            },
        ],
    },
    {
        path: Paths.investorGetStarted.index,
        children: [
            {
                path: '',
                element: <GetStartedInvestors />,
            },
        ],
    },
    {
        path: Paths.investorPricing.index,
        children: [
            {
                path: '',
                element: <InvestorPricing />,
            },
        ],
    },
    {
        path: Paths.feedback.index,
        children: [
            {
                path: '',
                element: <Feedback />,
            },
        ],
    },
    {
        path: Paths.pressContact.index,
        children: [
            {
                path: '',
                element: <PressContact />,
            },
        ],
    },
    {
        path: Paths.compensation.index,
        children: [
            {
                path: '',
                element: <Compensation />,
            },
        ],
    },
    {
        path: Paths.ownersFAQ.index,
        children: [
            {
                path: '',
                element: <OwnersFAQ />,
            },
        ],
    },
    {
        path: Paths.ownerGetStarted.index,
        children: [
            {
                path: '',
                element: <GetStartedOwners />,
            },
        ],
    },
    {
        path: Paths.areas.index,
        children: [
            {
                path: '',
                element: <PrivateRoute element={<UserAreas />} />,
            },
        ],
    },
    {
        path: Paths.blog.index,
        children: [
            {
                path: '',
                element: <BlogPage />,
            },
            {
                path: ':articleId',
                element: <ArticleDetails />,
            },
        ],
    },
    {
        path: Paths.jobOffer.index,
        children: [
            {
                path: '',
                element: <JobOffer/>,
            },
        ],
    },
    {
        path: Paths.powerGrid.index,
        children: [
            {
                path: '',
                element: <PowerGrid/>,
            },
        ],
    },
    { path: '*', element: <NotFoundPage /> },
]