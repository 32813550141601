import { useTranslation } from 'react-i18next'

export const Disabled = () => {
    const { t } = useTranslation();

    return (
        <div className="flex w-full justify-center pb-[60px] pt-[80px]">
            <div className="mb-[15px] rounded-[10px] border-[1px] border-secondary bg-white p-[10px]">
                <p className="text-[13px] font-medium text-secondary md:text-[25px]">
                    {t('area.disabled')}
                </p>
            </div>
        </div>
    )
}
