import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import { useLanguage } from '../../../hooks';

interface MapModalProps {
    polygonArea: number;
}

export const MapModal = ({ polygonArea }: MapModalProps) => {
  const { t } = useTranslation();
  const { selectedLanguage } = useLanguage({ i18n });

  return (
    <div
        className={`absolute
            ${selectedLanguage === 'de' ? "top-[65%]" : "top-[69%]"} 
            z-[10] rounded-[10px] bg-white p-[12px] md:left-[10px] md:top-[10px] left-[5px]`
        }
        id="calculated-area"
    >
        <p className="mb-[10px] text-[15px] font-medium md:text-[20px]">
            {t('addArea.draw_your_area')}
            <br></br>
            {t('addArea.on_the_map')}
        </p>

        <ul className="mb-[10px]">
            <li>{t('addArea.map_instructions.to_start_draw')}</li>
            <li>{t('addArea.map_instructions.to_end_draw')}</li>
            <li>
                {t('addArea.map_instructions.click')}{' '}
                <img
                    src="/assets/images/polygon.png"
                    alt="polygon"
                    className="mb-[2px] inline h-[18px]"
                />{' '}
                {t('addArea.map_instructions.to_add_another')}
            </li>
        </ul>

        <p className="text-[15px] font-medium">
            {t('addArea.area')}
        </p>
        <p>{Math.round(polygonArea)}</p>
    </div>
  )
}
