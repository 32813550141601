import { PowerElement } from '../hooks/usePowerElements';
import { FeatureCollection, GeoJsonProperties, Point } from 'geojson';
import mapboxgl from 'mapbox-gl';

export const addImageLayer = (
    map: mapboxgl.Map | null,
    id: string,
    data: PowerElement[],
    imageUrl: string,
    minZoom: number
) => {
    if (!map) return;

    const geojson: FeatureCollection<Point, GeoJsonProperties> = {
        type: 'FeatureCollection',
        features: data.map((element) => ({
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [element.lon, element.lat],
            },
            properties: {
                id: element.id,
                tags: JSON.stringify(element.tags),
            },
        })),
    };

    const updateOrAddLayer = () => {
        if (map.getSource(id)) {
            (map.getSource(id) as mapboxgl.GeoJSONSource).setData(geojson);
        } else {
            map.addSource(id, { type: 'geojson', data: geojson });
        }

        if (!map.getLayer(id)) {
            map.addLayer({
                id,
                type: 'symbol',
                source: id,
                layout: {
                    'icon-image': id,
                    'icon-size': 0.1,
                },
                minzoom: minZoom,
            });

            map.on('click', id, handleClick);
            map.on('mouseenter', id, () => {
                map.getCanvas().style.cursor = 'pointer';
            });
            map.on('mouseleave', id, () => {
                map.getCanvas().style.cursor = '';
            });
        }
    };

    const handleClick = (e: mapboxgl.MapMouseEvent & { features?: mapboxgl.MapboxGeoJSONFeature[] }) => {
        const feature = e.features && e.features[0];
        if (!feature) return;

        const coordinates = (feature.geometry as Point).coordinates.slice();
        const { id, tags } = feature.properties || {};
        const parsedTags = JSON.parse(tags || '{}');

        new mapboxgl.Popup({ closeButton: false })
            .setLngLat(coordinates as [number, number])
            .setHTML(
                `<h3><strong>ID:</strong> ${id}</h3>` +
                Object.entries(parsedTags)
                    .map(([key, value]) => `<h3><strong>${key}:</strong> ${value}</h3>`)
                    .join('')
            )
            .addTo(map);
    };

    if (map.hasImage(id)) {
        updateOrAddLayer();
    } else {
        map.loadImage(imageUrl, (error, image) => {
            if (error) {
                console.error(`Error loading image:`, error);
                return;
            }
            if (image) {
                map.addImage(id, image);
                updateOrAddLayer();
            }
        });
    }
};