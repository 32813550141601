import { useTranslation } from "react-i18next";
import { ArticleImage } from "./Image";
import { CallToAction } from "../../../components";
import { useNavigate } from "react-router-dom";
import { Links } from "../../../settings";
import { useCallback } from "react";

export const EightArticle = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleNavigate = useCallback(() => {
        navigate(Links.offer.index);
    }, [navigate])

    return (
        <div className="mb-[20px] flex flex-col gap-[25px] text-[16px] text-[#333] md:text-[18px]">
            <span className="uppercase text-primary">{t('articles.info')}</span>

            <h1 className="text-[25px] font-medium md:text-[35px]">
                {t('article_eight.title')}
            </h1>

            <div className="h-[2px] w-[150px] bg-primary" />

            <span className="text-[13px] uppercase text-gray-700 md:text-[16px]">
                {t('articles.article_eight.date')}
            </span>

            <p>{t('article_eight.p_1')}</p>

            <ArticleImage
                source="/assets/images/eightArticle.jpg"
                link="https://unsplash.com/de/fotos/weisse-und-violette-blumen-auf-grunem-grasfeld-tagsuber-qRVV4qZO1Mo"
            />

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_eight.subtitle_1')}
            </h2>

            <p>{t('article_eight.p_2')}</p>


            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_eight.subtitle_2')}
            </h2>

            <p>{t('article_eight.p_3')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_eight.subtitle_3')}
            </h3>

            <p>{t('article_eight.p_4')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_eight.subtitle_4')}  
            </h3>

            <p>{t('article_eight.p_5')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_eight.subtitle_5')}
            </h3>

            <p>{t('article_eight.p_6')}</p>

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_eight.subtitle_6')}
            </h2>

            <p>{t('article_eight.p_7')}</p>

            <p>
                {t('article_eight.p_8')}
            </p>

            <div className="mx-[25px] text-[16px] md:mt-[50px]">
                <CallToAction
                    title={t('home.call_to_action_money')}
                    btnTitle={t('buttons.offer_your_space_now')}
                    bgColor="bg-secondary text-white"
                    onClick={handleNavigate}
                    tag="p"
                />
            </div>
        </div>
    )
}
