/** @format */
import { Dropdown } from '../Dropdown';
import { TUserInfoSchema } from '../../helpers/validation';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    FieldValues,
    Path,
    SetFieldValue,
    UseControllerProps,
    UseFormRegister,
} from 'react-hook-form';

type CustomInputProps<T extends FieldValues> = {
    placeholder: string;
    defaultValue?: string;
    sx?: string;
    register: UseFormRegister<T>;
    name: Path<T>;
    star?: boolean;
    width?: string;
    starStyle?: string;
    icon?: boolean;
    iconSx?: string;
    type?: 'password' | 'textarea' | 'dropdown';
    options?: Record<string, string>[] | undefined;
    setValue?: SetFieldValue<T>;
    trigger?: (name: keyof TUserInfoSchema) => void;
} & UseControllerProps<T>;

/**
 * Customized Input field to display form
 * @param props - The properties for the Inputs
 * @returns Then rendered Input Component
 */
export const CustomInput = <T extends FieldValues>({
    placeholder,
    sx,
    register,
    name,
    type,
    options,
    setValue,
    trigger,
    defaultValue,
    starStyle,
    star,
    width,
    icon,
    iconSx,
}: CustomInputProps<T>) => {
    const { t } = useTranslation();

    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);

        if (setValue) {
            setValue(name, event.target.value);
        }
    };

    let renderInput = register ? (
        <div className="relative">
            <input
                type={type || 'text'}
                className={`${
                    width ? width : 'w-full'
                } rounded-xl bg-[#F3F3F3] px-6 py-[21px] text-base font-normal focus:outline-secondary ${sx}`}
                placeholder={placeholder}
                {...register(name)}
                onChange={handleInputChange}
            />

            {star && !inputValue && (
                <div
                    className={`absolute top-[20px] text-[24px] text-[#FF0F00] ${starStyle} req_placeholder`}
                >
                    <span className="req_placeholder">*</span>
                </div>
            )}

            {icon && inputValue && (
                <div
                    className={`absolute right-0 top-0 px-3 py-[21px] text-[12px] 
                    ${iconSx}`}
                >
                    {t('pricing.kwt')}
                </div>
            )}
        </div>
    ) : null;

    if (type === 'dropdown')
        renderInput = (
            <Dropdown
                options={options}
                defaultValue={defaultValue}
                sx={sx}
                star={star}
                starStyle={starStyle}
                placeholder={placeholder}
                register={register}
                name={name}
                setValue={setValue}
                trigger={trigger}
            />
        );

    if (type === 'textarea')
        renderInput = (
            <textarea
                {...register(name)}
                placeholder={placeholder}
                className={`w-full rounded-xl bg-[#F3F3F3] px-6 py-[21px] text-base 
                    font-normal focus:outline-secondary ${sx} h-[120px] resize-none`}
            ></textarea>
        );

    return <div className={`w-full rounded-xl`}>{renderInput}</div>;
};
