import { GraphQLQuery, GraphQLResult } from '@aws-amplify/api';
import { getCookie } from "../../../helpers/getCookie";
import { CreateFlaecheMutation } from '../../../API';

export const sendFacebookValue = (
    purchaseValue: number, 
    createRes: GraphQLResult<GraphQLQuery<CreateFlaecheMutation>>
) => {
    if (typeof window !== 'undefined' && window.fbq != null) {
        window.fbq('track', 'Purchase', {
            value: purchaseValue,
            currency: 'EUR',
        })
    }

    const myCookieValue = getCookie('GA-not tracking');

    if (!myCookieValue) {
        const data = createRes.data?.createFlaeche;

        window.dataLayer.push({ ecommerce: null })
        window.dataLayer.push({
            event: 'purchase',
            ecommerce: {
                transaction_id: data?.id,
                value: purchaseValue,
                currency: 'EUR',
                items: [
                    {
                        item_name: data?.areaType,
                        item_id: data?.id,
                        price: 0.01,
                        quantity: data?.areaSize,
                        item_category: data?.areaType,
                        item_category2: data?.address_country,
                        item_category3: data?.address_state,
                        item_category4: data?.address_city,
                    },
                ],
            },
        })
    }
}