import { createContext, useCallback, useState } from 'react';


type ModalContextType = {
    signInModalOpen: boolean;
    signOutModalOpen: boolean;
    removeConfirmationModalOpen: boolean;
    openSignInModal: () => void;
    openSignOutModal: () => void;
    openRemoveConfirmationModal: () => void;
    closeSignInModal: () => void;
    closeSignOutModal: () => void;
    closeRemoveConfirmationModal: () => void;
};

const ModalContext =
    createContext<ModalContextType>({
        signInModalOpen: false,
        signOutModalOpen: false,
        removeConfirmationModalOpen: false,
        openSignInModal: () => null,
        openSignOutModal: () => null,
        openRemoveConfirmationModal: () => null,
        closeSignInModal: () => null,
        closeSignOutModal: () => null,
        closeRemoveConfirmationModal: () => null,
    });

type ModalContextProviderProps = {
    children?: React.ReactElement | React.ReactElement[];
};

const ModalContextProvider: React.FC<
    ModalContextProviderProps
> = ({ children }) => {

    const [signInModalOpen, setSignInModalOpen] = useState(() => false);
    const [signOutModalOpen, setSignOutModalOpen] = useState(() => false);
    const [removeConfirmationModalOpen, setRemoveConfirmationModalOpen] = useState(() => false);

    const openSignInModal = useCallback(() => {
        setSignInModalOpen(true);
    }, []);

    const openSignOutModal = useCallback(() => {
        setSignOutModalOpen(true);
    }, []);

    const openRemoveConfirmationModal = useCallback(() => {
        setRemoveConfirmationModalOpen(true);
    }, []);

    const closeSignInModal = useCallback(() => {
        setSignInModalOpen(false);
    }, []);

    const closeSignOutModal = useCallback(() => {
        setSignOutModalOpen(false);
    }, []);

    const closeRemoveConfirmationModal = useCallback(() => {
        setRemoveConfirmationModalOpen(false);
    }, []);
    

    return (
        <ModalContext.Provider
            value={{
                signInModalOpen,
                signOutModalOpen,
                removeConfirmationModalOpen,
                openSignInModal,
                openSignOutModal,
                openRemoveConfirmationModal,
                closeSignInModal,
                closeSignOutModal,
                closeRemoveConfirmationModal,
            }}
        >
            {children}
        </ModalContext.Provider>
    );
};

export {
    ModalContextProvider,
    ModalContext,
};
