/** @format */

import { useTranslation } from 'react-i18next'
import { LandsSlider } from './LandsSlider'
import { useNavigate } from 'react-router-dom'
import { Links } from '../../../settings'
import { Flaeche } from '../../../API'
import { Card, Heading } from '../../../components'
import { useAuth } from '../../../hooks'
import { landsForLeaseMock } from '../../../utils/sliderForLease'

interface LandsForLeaseProps {
    lands: Flaeche[]
    images: string[]
    fetchImages: (id?: string | undefined) => Promise<void>
}

export const LandsForLease = (props: LandsForLeaseProps) => {
    const { lands, images, fetchImages } = props
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { loggedIn } = useAuth()

    const handleNavigate = () => {
        navigate(Links.search.index)
    }


    return (
        <div
            className="section mx-[25px] mt-[60px] box-border lg:mt-[160px]"
            id="areas"
        >
            <div className="flex w-full justify-center md:justify-between">
                <Heading
                    title={t('home.land_offered')}
                    size="text-[38px] mb-[40px] md:text-left text-center"
                    weight="font-medium"
                />

                <button
                    className={`hidden h-[45px]
                        rounded-[10px] px-[15px] py-[10px] text-[#11470E] md:block md:px-[44px]`}
                    onClick={handleNavigate}
                >
                    {t('search.show_more')}
                </button>
            </div>

            <div className="flex flex-col items-center">
                <div className="mb-[30px] flex flex-col items-center gap-[40px] md:hidden">
                    {lands.map((land, i) => (
                        <Card
                            key={`roof-${i}`}
                            land={land}
                            image={
                                images.find(
                                    (image) =>
                                        image.includes(land.id) &&
                                        (image.includes(
                                            'Screenshot-300x320.webp'
                                        ) ||
                                            image.includes('Screenshot'))
                                ) ?? ''
                            }
                            mobile={true}
                            fetchImages={fetchImages}
                        />
                    ))}
                </div>

                <LandsSlider
                    lands={loggedIn ? lands : landsForLeaseMock}
                    images={images}
                    fetchImages={fetchImages}
                />

                <button
                    className={`block h-[45px]
                        rounded-[10px] px-[15px] py-[10px] text-[20px] text-[#11470E] md:hidden md:px-[44px]`}
                    onClick={handleNavigate}
                >
                    {t('search.show_more')}
                </button>
            </div>
        </div>
    )
}
