import { useTranslation } from "react-i18next"
import { LegalData } from "../../types";
import { GradientBlock, LandOwnerBlock } from "../../components";
import { usePageDescription } from "../../hooks";
import { Helmet } from "react-helmet";

const data: LegalData = {
  subtitle: 'landOwner_get_started.subtitle',
  title: 'landOwner_get_started.title',
  description: 'landOwner_get_started.desc',
  block: 'landOwner_get_started.offer',
  image: "/assets/images/landOwnerImage.png"
};

export const GetStartedOwners = () => {
  const { t } = useTranslation();

  usePageDescription({ 
    title: t('title.owners_started'),
    description: t('description.owners_started')
  });

  return (
    <>
        <Helmet>
            <meta name='title' content={t('title.owners_started')} />
            <meta name='description' content={t('description.owners_started')} />
        </Helmet>
        <div className="flex flex-col lg:pt-[110px]">
        <LandOwnerBlock data={data} />

        <div className="md:px-[100px] px-[25px] flex flex-col items-center">
          <p className="uppercase text-[#464547] md:text-[30px] font-bold md:mb-[80px] mb-[40px]">
            {t('landOwner_get_started.subtitle')}
          </p>

          <div className="mb-[60px] md:mb-[100px] flex lg:justify-start w-full">
              <div className="flex gap-[30px] lg:flex-row flex-col">
                <div className="flex gap-[20px] items-center mb-[30px]">
                  <img src="/assets/icons/thumbs-up.svg" alt="agreement" className="lg:w-[147px] w-[80px]"/>
                </div>

                <div className="max-w-[690px]">
                  <p className="text-[20px] font-bold">
                    {t('landOwner_get_started.agreement.title')}
                  </p>

                  <p className="text-[15px] mb-[10px]">
                    {t('landOwner_get_started.agreement.desc')}
                  </p>

                  <p className="font-medium text-[#11470E] text-[15px]">{t('landOwner_get_started.role')} </p>

                  <p className="text-[15px]">
                      {t('landOwner_get_started.agreement.role')}
                  </p>
                </div>
              </div>
          </div>

          <div className="mb-[60px] md:mb-[100px] flex lg:justify-end w-full">
              <div className="flex gap-[30px] lg:flex-row flex-col">
                <div className="flex gap-[20px] items-center mb-[30px]">
                  <img src="/assets/icons/contract.svg" alt="agreement" className="lg:w-[147px] w-[80px]"/>
                </div>

                <div className="max-w-[690px]">
                  <p className="text-[20px] font-bold">
                    {t('landOwner_get_started.contract.title')}
                  </p>

                  <p className="text-[15px] mb-[10px]">
                    {t('landOwner_get_started.contract.desc')}
                  </p>

                  <p className="font-medium text-[#11470E] text-[15px]">{t('landOwner_get_started.role')} </p>

                  <p className="text-[15px]">
                      {t('landOwner_get_started.contract.role')}
                  </p>
                </div>
              </div>
          </div>

          <div className="mb-[60px] md:mb-[100px] flex lg:justify-start w-full">
              <div className="flex gap-[30px] lg:flex-row flex-col">
                <div className="flex gap-[20px] items-center mb-[30px]">
                  <img src="/assets/icons/power-generation.svg" alt="agreement" className="lg:w-[147px] w-[80px]"/>
                </div>

                <div className="max-w-[690px]">
                  <p className="text-[20px] font-bold">
                    {t('landOwner_get_started.grid.title')}
                  </p>

                  <p className="text-[15px] mb-[10px]">
                    {t('landOwner_get_started.grid.desc')}
                  </p>

                  <p className="font-medium text-[#11470E] text-[15px]">{t('landOwner_get_started.role')} </p>

                  <p className="text-[15px]">
                      {t('landOwner_get_started.grid.role')}
                  </p>
                </div>
              </div>
          </div>

          <div className="mb-[60px] md:mb-[100px] flex lg:justify-end w-full">
              <div className="flex gap-[30px] lg:flex-row flex-col">
                <div className="flex gap-[20px] items-center mb-[30px]">
                  <img src="/assets/icons/plan.svg" alt="agreement" className="lg:w-[147px] w-[80px]"/>
                </div>

                <div className="max-w-[690px]">
                  <p className="text-[20px] font-bold">
                    {t('landOwner_get_started.plan.title')}
                  </p>

                  <p className="text-[15px] mb-[10px]">
                    {t('landOwner_get_started.plan.desc')}
                  </p>

                  <p className="font-medium text-[#11470E] text-[15px]">{t('landOwner_get_started.role')} </p>

                  <p className="text-[15px]">
                      {t('landOwner_get_started.plan.role')}
                  </p>
                </div>
              </div>
          </div>

          <div className="mb-[60px] md:mb-[100px] flex lg:justify-start w-full">
              <div className="flex gap-[30px] lg:flex-row flex-col">
                <div className="flex gap-[20px] items-center mb-[30px]">
                  <img src="/assets/icons/Iconbuilding.svg" alt="agreement" className="lg:w-[147px] w-[80px]"/>
                </div>

                <div className="max-w-[690px]">
                  <p className="text-[20px] font-bold">
                    {t('landOwner_get_started.building.title')}
                  </p>

                  <p className="text-[15px] mb-[10px]">
                    {t('landOwner_get_started.building.desc')}
                  </p>
                </div>
              </div>
          </div>

          <div className="mb-[60px] md:mb-[100px] flex lg:justify-end w-full">
              <div className="flex gap-[30px] lg:flex-row flex-col">
                <div className="flex gap-[20px] items-center mb-[30px]">
                  <img src="/assets/icons/Unionconstruction.svg" alt="agreement" className="lg:w-[147px] w-[80px]"/>
                </div>

                <div className="max-w-[690px]">
                  <p className="text-[20px] font-bold">
                    {t('landOwner_get_started.construction.title')}
                  </p>

                  <p className="text-[15px] mb-[10px]">
                    {t('landOwner_get_started.construction.desc')}
                  </p>
                </div>
              </div>
          </div>

          <div className="mb-[60px] md:mb-[100px] flex lg:justify-start w-full">
              <div className="flex gap-[30px] lg:flex-row flex-col">
                <div className="flex gap-[20px] items-center mb-[30px]">
                  <img src="/assets/icons/solar-panel.svg" alt="agreement" className="lg:w-[147px] w-[80px]"/>
                </div>

                <div className="max-w-[690px]">
                  <p className="text-[20px] font-bold">
                    {t('landOwner_get_started.project.title')}
                  </p>

                  <p className="text-[15px] mb-[10px]">
                    {t('landOwner_get_started.project.desc')}
                  </p>

                  <p className="text-[15px] mb-[10px]">
                    {t('landOwner_get_started.project.desc2')}
                  </p>

                  <p className="font-medium text-[#11470E] text-[15px]">{t('landOwner_get_started.role')} </p>

                  <p className="text-[15px]">
                      {t('landOwner_get_started.project.role')}
                  </p>
                </div>
              </div>
          </div>

          <div className="bg-[#F6F7F3] py-[65px] md:px-[70px] px-[25px] relative md:mb-[95px] mb-[40px]">
            <p className="md:text-[27px] text-[20px] mb-[20px] font-medium">
              {t('landOwner_get_started.information')}
            </p>

            <p className="md:text-[20px] mb-[20px] leading-[50px]">
              {t('landOwner_get_started.information_desc')}
            </p>

            <img 
              src="/assets/icons/Groupdesign.svg" 
              alt="design" 
              className="absolute bottom-0 right-0 h-[250px]"
            />
          </div>
        </div>

        <GradientBlock
            title={t('home.call_to_action_urgency')}
            buttonText={t('buttons.offer_your_space_now')}
            image="/assets/images/gradient-house.png"
        />
    </div>
    </>

  )
}
