import { Flaeche } from "../../../API";
import { AreaButtons } from "./AreaButtons";
import { AreaInfo } from "./AreaInfo";
import { DeleteModal } from "./DeleteModal";
import { AreaIdModal } from "./AreaIdModal";
import { useEffect, useState } from "react";
import { useAreaHandling } from "../hooks";
import { fetchImage } from "../../../utils";

interface AreaCardProps {
    area: Flaeche | null | undefined;
    image: string;
    onDelete: (id: string) => Promise<void>;
    fetchImages: (id?: string | undefined) => Promise<void>;
}

export const AreaCard = ({ area, image, onDelete, fetchImages }: AreaCardProps) => {
    const [imageUrl, setImageUrl] = useState('');

    const {
        selArea,
        owner,
        idModalOpen,
        passedId,
        setPassedId,
        deleteError,
        approveDeleteArea,
        handleCancelDelete,
        isOpenDelete,
        changeAreaStatus,
        handleCloseDelete,
        handleEditArea,
        handleNavigate,
        handleOpenDelete,
        setDeleteError
    } = useAreaHandling({ area });

    const handleDeleteArea = async () => {
        if (!area?.id || passedId !== area?.id) {
            setDeleteError(true)

            return;
        }

        await onDelete(area.id);
    }

    useEffect(() => {
        async function loadImage() {
            try {
                const fetchedImageUrl = await fetchImage(image);
                if (fetchedImageUrl) {
                    setImageUrl(fetchedImageUrl);
                }
            } catch (error) {
                console.error('Error loading image:', error);
            }
        }
        loadImage();
    }, [image]);

    useEffect(() => {
        fetchImages(area?.id);
    }, [area])

    return (
        <>
            <div 
                className="flex flex-col gap-[20px] md:flex-row rounded-[12px] border-[1px] 
                border-[#111A2126] hover:shadow-md p-[5px] cursor-pointer items-center justify-center"
                onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                    if ((event.target as HTMLElement).tagName !== 'BUTTON') {
                        handleNavigate();
                    }
                }}
            >
                <div className="h-[250px] md:w-[300px] w-[320px] overflow-hidden border-[1px] rounded-[12px]">
                    <img src={imageUrl} alt="" className="h-full object-cover" />
                </div>

                <div className="w-[320px]">
                    <AreaInfo 
                        area={area}
                        owner={owner}
                    />

                    <AreaButtons
                        onDelete={handleOpenDelete}
                        onEdit={handleEditArea}
                        onStatus={changeAreaStatus}
                        title={
                            selArea?.disabled 
                                ? 'admin_dashboard.activate' 
                                : 'admin_dashboard.deactivate'
                        }
                    />
                </div>
            </div>

            {isOpenDelete && (
                <DeleteModal
                    text='admin_dashboard.sure'
                    onApproveDelete={approveDeleteArea}
                    onCancelDelete={handleCloseDelete}
                />
            )}

            {idModalOpen && (
                <AreaIdModal 
                    passedId={passedId}
                    error={deleteError}
                    onApprove={handleDeleteArea}
                    onCancel={handleCancelDelete}
                    onChange={setPassedId}
                />
            )}
        </>
    )
}
