import { useEffect, useState } from 'react';
import { NotificationData } from '../../App';

interface NotificationModalProps {
    data: NotificationData | null;
    onClose: () => void;
    isScrolled: boolean;
}

export const NotificationModal = ({
    data,
    onClose,
    isScrolled
}: NotificationModalProps) => {
    return (
        <div
            className={`fixed right-[10px] top-[60px] z-10 flex w-[200px] cursor-pointer 
            items-center gap-[10px] rounded-[8px] bg-white px-[15px] py-[5px] shadow-md 
            ${isScrolled ? 'md:top-[70px]' : 'md:top-[100px]'}`}
            onClick={onClose}
        >
            <img
                src="/assets/icons/mail-letter.svg"
                style={{ height: '20px' }}
            />
            <div style={{ flexGrow: '1' }}>
                <p style={{ fontWeight: 'bold', margin: '0' }}>
                    {data?.full_name}
                </p>

                <p style={{ margin: '0', color: '#333333' }}>
                    {data?.message && data.message.length > 15
                        ? data?.message.slice(0, 15) + '...'
                        : data?.message}
                </p>
            </div>
        </div>
    )
}
