import { useTranslation } from 'react-i18next';
import { PricingUserInfoEnterpriseSchema, TPricingUserInfoEnterpriseSchema } from '../../../helpers';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CustomInput } from '../../../components';
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLQuery } from '@aws-amplify/api';
import { CreatePricingTestMutation } from '../../../API';
import { createPricingTest } from '../../../graphql/mutations';
import { Links } from '../../../settings';
import { SelectedPlan } from '../../../types';
import { useAuth, useProcessTry } from '../../../hooks';
import { useNavigate } from 'react-router-dom';

interface EnterpriseFormProps {
    selected: SelectedPlan | null;
}

export const EnterpriseForm = ({ selected }: EnterpriseFormProps) => {
    const { t } = useTranslation();
    const { loggedIn } = useAuth();
    const { handleCreateError } = useProcessTry();
    const navigate = useNavigate();

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm<TPricingUserInfoEnterpriseSchema>({
        mode: 'all',
        resolver: yupResolver(PricingUserInfoEnterpriseSchema),
    })

    const onSubmit = async (data: FieldValues) => {
        if (selected) {
            const pricingTest = {
                Package: `${t(selected.name)} ${t(selected.price)} 2000 m²`,
                Date: Math.floor(new Date().getTime() / 1000),
                email: data.email,
                Name: data.fullName,
                installedkWPpa: data.solar ? data.solar : null,
                Company: data.company ? data.company : null,
                Phone: data.phone,
                DateUnix: Math.floor(new Date().getTime() / 1000)
            };
    
            try {
                await API.graphql<GraphQLQuery<CreatePricingTestMutation>>(
                    graphqlOperation(createPricingTest, { input: pricingTest })
                );

                if (typeof window !== 'undefined' && window.fbq != null) {
                    window.fbq('track', 'Subscribe');
                }

                navigate(Links.pricingEnterprise.index);

            } catch (error: any) {
                console.error(error);

                const errorData = {
                    userInfoSub: loggedIn?.sub,
                    requestBody: JSON.stringify(pricingTest),
                    errorMsg: error.message as string,
                    process: 'Submit pricing form - plan',
                    DateTimeUnix: Math.floor(new Date().getTime() / 1000)
                };
            
                handleCreateError(errorData);
            }
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-[35px] flex flex-col gap-[25px]">
                <p className="text-[16px] font-bold uppercase tracking-wider">
                    {t('pricing.address_enterprise')}
                </p>

                <CustomInput
                    name="company"
                    placeholder={t('placeholders.company_name')}
                    sx={`lg:w-[500px] h-[60px] w-full bg-white border-grey-50 border-solid border-[1px] ${
                        errors['company'] &&
                        'border-red-400 outline-red-400'
                    }`}
                    register={register}
                />

                <CustomInput
                    name="fullName"
                    placeholder={t('profile.labels.full_name')}
                    sx={`lg:w-[500px] h-[60px] w-full bg-white border-grey-50 border-solid border-[1px] ${
                        errors['fullName'] && 'border-red-400 outline-red-400'
                    }`}
                    register={register}
                />

                <CustomInput
                    name="email"
                    placeholder={t('pricing.address')}
                    sx={`lg:w-[500px] h-[60px] w-full bg-white border-grey-50 border-solid border-[1px] ${
                        errors['email'] && 'border-red-400 outline-red-400'
                    }`}
                    register={register}
                />

            
                <CustomInput
                    name="phone"
                    placeholder={t('placeholders.phone_number')}
                    sx={`lg:w-[500px] h-[60px] w-full bg-white border-grey-50 border-solid border-[1px] ${
                        errors['phone'] &&
                        'border-red-400 outline-red-400'
                    }`}
                    register={register}
                />

                <p className="text-[16px]">{t('pricing.how_much')}</p>

                <CustomInput
                    name="solar"
                    placeholder={t('pricing.solar_power')}
                    sx={`lg:w-[500px] h-[60px] w-full bg-white border-grey-50 border-solid border-[1px] ${
                        errors['solar'] &&
                        'border-red-400 outline-red-400'
                    }`}
                    register={register}
                    icon={true}
                    iconSx={`${errors['solar'] && 'border-red-400'}`}
                />
            </div>

            <button
                className="w-full rounded-[10px] bg-[#11470E] py-[17px] 
                    text-[20px] font-medium text-white"
            >
                {t('pricing.send')}
            </button>
        </form>
    )
}
