import { ChangeEvent, MouseEventHandler, useCallback, useEffect, useRef, useState } from "react";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { useLocation, useNavigate } from "react-router-dom";
import { Links } from "../../../settings";
import { GraphQLQuery } from '@aws-amplify/api';
import { Flaeche, ListFlaechesQuery } from '../../../API';
import { listFlaeches } from "../../../graphql/queries";
import { useAuth } from "../../../hooks";
import { filterByType } from "../../../helpers";
import { filterAdminAreas } from "../../AdminDashboard/utils";
import { filterOptions } from "../../AdminDashboard/utils/options";

export interface SizeValues {
  min: string;
  max: string;
}

interface Options {
  isAdminPage?: boolean;
}

export const useSearch = ({ isAdminPage }: Options) => {
    const [loading, setLoading] = useState(() => true);
    const [areas, setAreas] = useState<Array<Flaeche | null>>(() => []);
    const [notDisabledAreas, setNotDisabledAreas] = useState<Array<Flaeche | null>>(() => []);
    const [filteredAreas, setFilteredAreas] = useState<Array<Flaeche | null>>(() => []);
    const [areaImages, setAreaImages] = useState<string[]>(() => []);
    const [itemsToShow, setItemsToShow] = useState(12);
    const [filterType, setFilterType] = useState('');
    const [filterMunicipality, setFilterMunicipality] = useState('');
    const [error, setError] = useState(false);
    const [navigatedToPricing, setNavigatedToPricing] = useState(() => {
      const storedFlag = sessionStorage.getItem('navigatedToPricing');
      return storedFlag === 'true' ? true : false;
    });
    const [isOpenSizeFilter, setIsOpenSizeFilter] = useState(false);
    const [minValue, setMinValue] = useState('');
    const [maxValue, setMaxValue] = useState('');
    const [areasToShow, setAreasToShow] = useState(0);
    const [submittedValues, setSubmittedValues] = useState<SizeValues | null>(null);
    const [filterBy, setFilterBy] = useState(filterOptions[1].value);

    const navigate = useNavigate();
    const location = useLocation();
    const { loggedIn } = useAuth();
    const sizeRef = useRef<HTMLDivElement | null>(null);

    const handleNavigate = useCallback(() => {
        navigate(Links.offer.index);
    }, [navigate])
  
    const handleLoadMore: MouseEventHandler<HTMLButtonElement> = (event) => {
      event.preventDefault();

      if (
          filteredAreas.length - itemsToShow < 13 
          && !navigatedToPricing 
          && location.pathname === '/search'
          && !loggedIn?.qualifiedInvestor
        ) {
        navigate(Links.investorPricing.index);
        sessionStorage.setItem('navigatedToPricing', 'true');
        setNavigatedToPricing(true);

        return;
      }

      setItemsToShow(prev => prev + 12);
      sessionStorage.setItem('areaCount', (itemsToShow + 12).toString());
    };
  
    const getAreas = useCallback((filterCondition?: any) => {
      setLoading(true);
    
      let nextToken: string | null | undefined = null;
      let allAreas: (Flaeche | null)[] = [];
    
      const fetchAreasBatch = async () => {
        return API.graphql<GraphQLQuery<ListFlaechesQuery>>(
          graphqlOperation(listFlaeches, { 
            limit: 100, 
            nextToken,
            filter: filterCondition ? filterCondition : null
          })
        )
          .then((response) => {
            const { items, nextToken: newNextToken } = response.data?.listFlaeches || {};
            
            allAreas = [...allAreas, ...(items || [])];
            nextToken = newNextToken;
          });
      };
    
      const handleFetchAreas: any = async () => {
        return fetchAreasBatch().then(() => {
          if (nextToken) {
            return handleFetchAreas();
          }
        });
      };
    
      handleFetchAreas()
        .then(() => {
          setAreas(allAreas);
          setLoading(false);
        })
        .catch((error: any) => {
          console.error(error);
          setLoading(false);
          setError(true);
        });
    }, []);

    const fetchImages = async (id?: string) => {
      Storage.list(id ? id : "")
      .then(({ results }) => {
        results.forEach((image) => {
          if (image.key) {
            const expirationTime = 7 * 24 * 60 * 60;

            Storage.get(image.key, { expires: expirationTime })
            .then((imageRes) => {
              setAreaImages((areaImages) => [...areaImages, imageRes])
            })
            .catch((err) => {
              if (err.status === 400) {
                window.location.reload();
              }
              
              console.log("Error getting images:", err)
            })
          }
        })
      })
    }

    const toggleSizeFilter = () => {
      setIsOpenSizeFilter(prev => !prev);
    }

    const handleFilterSize = (e: ChangeEvent<HTMLInputElement>, value: string) => {
        if (value === 'max') {
            setMaxValue(e.target.value);
            return;
        }

        setMinValue(e.target.value);
    }

    const submitSizeFiltering = () => {
      setSubmittedValues({
        min: minValue,
        max: maxValue
      })

      setIsOpenSizeFilter(false);
    }

    useEffect(() => {
      setNotDisabledAreas(areas.filter((area) => area?.disabled !== true));
  }, [areas, setNotDisabledAreas]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (sizeRef.current && !sizeRef.current.contains(event.target as Node)) {
                setIsOpenSizeFilter(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (!maxValue && !minValue) {
            setSubmittedValues(null);
        }

        if (!isAdminPage) {
          const areasToShow = filterByType(
            notDisabledAreas, 
            filterType, 
            filterMunicipality,  
            { min: minValue, max: maxValue }
          );
  
          setAreasToShow(areasToShow.length);

          return;
        }

        const areasToShow = filterAdminAreas(
          areas, 
          filterBy,
          filterType,  
          { min: minValue, max: maxValue }
        );

        setAreasToShow(areasToShow.length);
    }, [minValue, maxValue, filteredAreas])

    useEffect(() => {
      const items = sessionStorage.getItem('areaCount');

      if (items) {
        const currentPage = parseInt(items) || 12;
        setItemsToShow(currentPage);
      }
    }, []);

    return {
        loading,
        areas,
        error,
        notDisabledAreas,
        filteredAreas,
        areaImages,
        filterType,
        filterMunicipality,
        itemsToShow,
        getAreas,
        handleLoadMore,
        handleNavigate,
        setNotDisabledAreas,
        setFilterType,
        setFilterMunicipality,
        setFilteredAreas,
        setError,
        setItemsToShow,
        fetchImages,
        isOpenSizeFilter,
        minValue,
        maxValue,
        areasToShow,
        sizeRef,
        toggleSizeFilter,
        handleFilterSize,
        submitSizeFiltering,
        submittedValues,
        filterBy,
        setFilterBy
    }
}