import { useTranslation } from "react-i18next";

export const Prohibited = () => {
    const { t } = useTranslation();

    return (
        <div className="mb-[20px] w-full">
            <p className="mb-[10px] text-[20px] font-bold uppercase md:text-[30px]">
                {t('terms.prohibited.title')}
            </p>

            <p className="mb-[10px] ml-[20px] md:text-[18px]">
                {t('terms.prohibited.you_may')}
            </p>

            <p className="mb-[10px] ml-[40px] md:text-[18px]">
                {t('terms.prohibited.in_any')}
            </p>

            <p className="mb-[10px] ml-[40px] md:text-[18px]">
                {t('terms.prohibited.in_any2')}
            </p>

            <p className="mb-[10px] ml-[40px] md:text-[18px]">
                {t('terms.prohibited.send')}
            </p>

            <p className="mb-[10px] ml-[40px] md:text-[18px]">
                {t('terms.prohibited.knowingly')}
            </p>

            <p className="mb-[10px] ml-[20px] md:text-[18px]">
                {t('terms.prohibited.you_also')}
            </p>

            <p className="mb-[10px] ml-[40px] md:text-[18px]">
                {t('terms.prohibited.reproduce')}
            </p>

            <p className="mb-[10px] ml-[40px] md:text-[18px]">
                {t('terms.prohibited.access')}
            </p>

            <p className="mb-[10px] ml-[40px] md:text-[18px]">
                {t('terms.prohibited.part')}
            </p>

            <p className="mb-[10px] ml-[40px] md:text-[18px]">
                {t('terms.prohibited.equipment')}
            </p>

            <p className="mb-[10px] ml-[40px] md:text-[18px]">
                {t('terms.prohibited.software')}
            </p>

            <p className="mb-[10px] ml-[40px] md:text-[18px]">
                {t('terms.prohibited.network')}
            </p>
        </div>
    )
}
