import { useTranslation } from 'react-i18next';
import { AreaCard } from './components/AreaCard';
import { CallToAction } from '../../components/CallToAction';
import { useEffect, useState } from 'react';
import { Loader } from '../../components/Loader';
import { SearchDropdowns } from './components/SearchDropdowns';
import { useProfileInfo } from '../ProfilePage/hooks/useProfileInfo';
import { useAuth, usePageDescription } from '../../hooks';
import { useSearch } from './hooks';
import { useHandleArea } from './hooks/useHandleArea';
import { LoadMoreButton } from '../../components';
import { SearchSchema, SizeFilter } from './components';
import { filterByType } from '../../helpers';
import { API } from 'aws-amplify';
import { GraphQLQuery, graphqlOperation } from '@aws-amplify/api';
import { GetOrganizationQuery, GetUserInfoBySubQuery } from '../../API';
import { getOrganization, getUserInfoBySub } from '../../graphql/queries';
import { Helmet } from 'react-helmet';

export const SearchPage = () => {
    const { loggedIn, isAdmin, isInvestor } = useAuth();

    //Check if owner of organization is the qualified investor
    const [isOwnerInvestor, setIsOwnerInvestor] = useState(false);

    const {
        error,
        loading,
        areaImages,
        itemsToShow,
        filteredAreas,
        getAreas,
        setFilterType,
        setFilterMunicipality,
        handleLoadMore,
        handleNavigate,
        fetchImages,
        sizeRef,
        isOpenSizeFilter,
        areasToShow,
        handleFilterSize,
        toggleSizeFilter,
        submitSizeFiltering,
        notDisabledAreas,
        filterType,
        filterMunicipality,
        setFilteredAreas,
        submittedValues,
        maxValue,
        minValue
    } = useSearch({});

    const {
        user,
        getUserInfo
    } = useProfileInfo({ loggedIn });

    const {
        markAsFavorite,
        deactivateArea,
        removeFavorite
    } = useHandleArea({ user, getUserInfo});

    const { t } = useTranslation();

    const handleSaveId = (id: string) => {
        sessionStorage.setItem('prevPosition', id);
    }

    const getUserOrganization = async () => {
        try {
            if (!user || !user.MemberofOrganizationID) return;

            const { data } = await API.graphql<GraphQLQuery<GetOrganizationQuery>>(
                graphqlOperation(getOrganization, {id: user.MemberofOrganizationID})
            );

            const ownerSub = data?.getOrganization?.OwnerOfOrganization;

            if (!ownerSub) return;
            const { data: ownerData } = await API.graphql<GraphQLQuery<GetUserInfoBySubQuery>>(
                graphqlOperation(getUserInfoBySub, { sub: ownerSub})
            );

            if (ownerData) {
                const investor = ownerData.getUserInfoBySub?.items[0]?.qualifiedInvestor;

                setIsOwnerInvestor(investor ? true : false);
            }
        } catch (error) {
            console.log(error);
        }
    }

    usePageDescription({ 
        title: t('title.search'),
        description: t('description.search')
    });

    useEffect(() => {
        getAreas();
        getUserInfo();
    }, []);

    useEffect(() => {
        if (filteredAreas.length > 0) {
            const id = sessionStorage.getItem('prevPosition');
        
            if (id) {
                const area = document.getElementById(id);
                area && area?.scrollIntoView();
            }

            return;
        }
    }, [filteredAreas]);

    useEffect(() => {
        const sortedAreas = [...notDisabledAreas].sort((a, b) => {
            if (!a?.createdAt || !b?.createdAt) {
                return 0;
            }
  
            const dateA = new Date(a?.createdAt).getTime();
            const dateB = new Date(b?.createdAt).getTime();
  
            return dateB - dateA;
        });
  
        const filteredByType = filterByType(sortedAreas, filterType, filterMunicipality, submittedValues);
        setFilteredAreas(filteredByType)
    }, [filterType, notDisabledAreas, setFilteredAreas, filterMunicipality, submittedValues])

    useEffect(() => {
        getUserOrganization();
    }, [user])

    return (
        <>
            <Helmet>
                <meta name='title' content={t('title.search')} />
                <meta name='description' content={t('description.search')} />
            </Helmet>
            <div className="px-[25px] md:px-[60px] mb-[-100px] md:mb-[-300px] relative z-[10]">
                <SearchSchema />
                {loading ? <Loader /> : null}

                <div className="flex w-full justify-center py-[25px] md:py-[50px]">
                    <h1 className="text-center text-[24px]	font-semibold md:text-[40px]" id="title">
                        {t('search.title')}<br></br>{t('search.available')}
                    </h1>
                </div>

                <div className="search-flex mb-[25px] flex items-center justify-between">
                    <p className="mb-[20px] text-[12px] font-bold uppercase tracking-wider md:text-[15px] lg:mb-0">
                        {t('search.explore')}
                    </p>

                    <div className='flex gap-[15px] md:flex-row flex-col w-full md:w-fit'>
                        <SearchDropdowns 
                            onFilterType={setFilterType}
                            onFilterMunicipality={setFilterMunicipality}
                        />

                        {(isAdmin || isInvestor) && (
                            <SizeFilter
                                sizeRef={sizeRef}
                                minValue={minValue}
                                maxValue={maxValue}
                                areasToShow={areasToShow}
                                toggleSizeFilter={toggleSizeFilter}
                                onFilterSize={handleFilterSize}
                                isOpenSizeFilter={isOpenSizeFilter}
                                submitted={submittedValues}
                                submitFiltering={submitSizeFiltering}
                            />
                        )}
                    </div>
                </div>

                {filteredAreas.length > 0 ? (
                    <>
                        <div className='w-full flex justify-center'>
                            <div className="mb-[60px] flex flex-wrap flex-col md:flex-row justify-start 
                                gap-[20px] md:mb-[90px] favorite-projects"
                            >
                                {filteredAreas.slice(0, itemsToShow).map((area) => (
                                    area ? (
                                        <div 
                                            id={`area-${area.id}`}
                                            key={area?.id}
                                            onClick={() => handleSaveId(`area-${area.id}`)}
                                        >
                                            <AreaCard 
                                                key={area?.id}
                                                item={area}
                                                image={areaImages.find((image) => image.includes(area.id) 
                                                    && (image.includes('Screenshot-300x350.webp') || image.includes('Screenshot'))) 
                                                    ?? ""}
                                                onDeactivate={deactivateArea}
                                                onFavorite={markAsFavorite}
                                                onRemoveFavorite={removeFavorite}
                                                fetchImages={fetchImages}
                                                selected={user?.favoriteFlaeches?.includes(area?.id)}
                                                isOwnerInvestor={isOwnerInvestor}
                                            />
                                        </div>
                                    ) : null
                                ))}
                            </div>
                        </div>

                        {!loading && filteredAreas && filteredAreas.length > itemsToShow  && (
                            <LoadMoreButton onShowMore={handleLoadMore} />
                        )}
                    </>
                ) : (
                    !loading && (
                        <div className='w-full flex flex-col items-center justify-center my-[40px]'>
                            <p className='text-[30px] text-secondary font-medium'>
                                {t('search.no_areas')}
                            </p>
                        </div>
                    )
                )}

                {error && (
                    <div className='w-full flex justify-center'>
                        <div className="mb-[15px] rounded-[10px] border-[1px] border-red-400 bg-white p-[10px]">
                            <p className="font-medium text-red-400">
                                {t('addArea.please_try_later')}
                            </p>
                        </div>
                    </div>
                )}

                <div className="mx-[25px]">
                    <CallToAction
                        title={t('home.call_to_action_urgency')}
                        btnTitle={t('buttons.offer_your_space_now')}
                        onClick={handleNavigate}
                    />
                </div>
            </div>

            <div className="relative ml-[calc(50%-50vw)] h-full w-screen bg-[lightgray]">
                <div
                    className="z-5 absolute h-[200px] w-full"
                    style={{
                        background:
                            'linear-gradient(180deg, #FFFF 0%, rgba(243, 243, 243, 0.76) 40.12%, rgba(243, 243, 243, 0.00) 89.67%)',
                    }}
                ></div>
                <div className="z-[1] h-[451px] w-full bg-solarWoman bg-cover bg-center  bg-no-repeat md:h-[857px]"></div>
            </div>
        </>
    )
}
