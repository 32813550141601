// @ts-nocheck
import mapboxgl from "mapbox-gl";
import { useEffect } from "react";
import toGeoJSON from 'togeojson';
import bbox from '@turf/bbox';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import i18n from "../../../i18n";
import { useLanguage } from "../../../hooks";

const TOKEN = 'pk.eyJ1IjoiZW5sYXBhIiwiYSI6ImNsbzc2dndjbTAybmMydnBjNXE3OTVzbWkifQ.eoRkVu1mm9KFcTTq7plkew';

interface Options {
    mapContainer: MutableRefObject<HTMLDivElement | null>;
    areaFiles: string[];
    zoom?: number | null;
    isOpenContacts: boolean;
}

export const useAreaMap = ({ mapContainer, areaFiles, zoom, isOpenContacts }: Options) => {
    const { selectedLanguage } = useLanguage({ i18n });
    
    useEffect(() => {
        if (mapContainer?.current && areaFiles.length > 0) {
            mapboxgl.accessToken = TOKEN;

            const language = new MapboxLanguage({ defaultLanguage: 'en' });
    
            const map = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mapbox/satellite-streets-v11',
                center: [10.4515, 51.1657],
                zoom: 5,
                preserveDrawingBuffer: true
            });

            map.addControl(language);
    
            map.on('style.load', async () => {     
                map.setStyle(language.setLanguage(map.getStyle(), selectedLanguage));
                
                const kml = areaFiles.find(el => el.includes('.kml'));
    
                if (kml) {
                    try {
                        const response = await fetch(kml);
                        const kmlText = await response.text();
    
                        const parser = new DOMParser();
                        const kmlDocument = parser.parseFromString(
                            kmlText,
                            'application/xml'
                        );
                        const geoJSON = await toGeoJSON.kml(kmlDocument);
    
                        if (geoJSON && geoJSON.features && geoJSON.features.length > 0) {
                            const coordinates = geoJSON.features[0].geometry.coordinates;
    
                            if (coordinates) {
                                map.addSource('polygon', {
                                    type: 'geojson',
                                    data: geoJSON
                                });
    
                                map.addLayer({
                                    id: 'polygon',
                                    type: 'line',
                                    source: 'polygon',
                                    layout: {},
                                    paint: {
                                        'line-color': 'yellow',
                                        'line-width': 2
                                    }
                                });
    
                                if (zoom) {
                                    map.fitBounds(bbox(geoJSON), { padding: 100, zoom, });
                                } else {
                                    map.fitBounds(bbox(geoJSON), { padding: 100 });
                                }
                            }
                        }
                    } catch (error) {
                        console.error('Error fetching or parsing KML file:', error);
                    }
                }
            });
    
            return () => {
                map.remove();
            };
        }
    }, [areaFiles, isOpenContacts, selectedLanguage]);
}
