import { useTranslation } from "react-i18next";

import { general, limitation, otherInfo, privacy } from "../../utils/formLists";
import {
    AboutUs,
    Account,
    Links,
    Prohibited,
    TermsItem,
    Uploading,
    UserRights,
} from './components';
import { usePageDescription } from "../../hooks";
import { Helmet } from "react-helmet";

export const Terms = () => {
  const { t } = useTranslation();

  usePageDescription({ 
    title: t('title.terms'),
    description: t('description.terms')
  });

  return (
    <>
         <Helmet>
            <meta name='title' content={t('title.terms')} />
            <meta name='description' content={t('description.terms')} />
        </Helmet>
        <div className="flex flex-col py-[50px] md:px-[100px] px-[25px]">
        <h1 className="md:text-[38px] text-[25px] font-bold mb-[10px] text-secondary text-center md:text-left">
            {t('terms.title')}
        </h1>

        <p className="md:text-[20px] text-[15px] text-center md:text-left font-medium mb-[20px]">
            {t('terms.thank_you')} <br/>{t('terms.legal')}
        </p>

        <AboutUs />

        <TermsItem
          title={t('terms.general.title')} 
          number={2} 
          items={general} 
        />

        <Account />

        <UserRights />

        <Links />

        <TermsItem 
          title={t('terms.privacy.title')} 
          number={6} 
          items={privacy} 
        />

        <Uploading />

        <Prohibited />

        <TermsItem 
          title={t('terms.limitation.title')} 
          number={9} 
          items={limitation} 
        />

        <TermsItem 
          title={t('terms.other.title')} 
          number={10} 
          items={otherInfo} 
        />
    </div>
    </>

  )
}
