import { useState, useCallback, useContext } from 'react';
import { LinearProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Auth } from 'aws-amplify';

import { ModalContext } from '../../contexts/ModalContext';
import { useLocalStorage } from '../../hooks/useLocaleStorage';
import { CHAT_TOKEN, PATH } from '../../utils/constants';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    textAlign: 'center',
    bgcolor: 'background.paper',
    border: '2px solid #f0f0f0',
    boxShadow: 24,
    padding: 0,
    p: 4,
}

type SignOutModalProps = {}

export const SignOutModal: React.FC<SignOutModalProps> = () => {
    const { signOutModalOpen, closeSignOutModal } = useContext(ModalContext);
    const { t } = useTranslation();
    const { logout } = useAuth();
    const [path, setPath] = useLocalStorage(PATH);

    const [loading, setLoading] = useState(() => false);
    const [logoutFailure, setLogoutFailure] = useState(() => false);
    const [token, setToken] = useLocalStorage(CHAT_TOKEN, '');

    const signOutAWS = useCallback(async () => {
        setLoading(true);

        try {
            logout();

            Auth.signOut()
                .then((res) => {
                    logout()
                    closeSignOutModal()
                    setPath('')
                    setLoading(false)
                    setToken('')
                })
                .catch((err) => {
                    setLogoutFailure(true)
                    setLoading(false)
                })
        } catch (error) {
            setLogoutFailure(true)
            setLoading(false)
            console.log('error signOut', error)
        }
    }, [closeSignOutModal, logout])

    return (
        <Modal
            id={'signOut-modal'}
            open={signOutModalOpen}
            disableAutoFocus={true}
            onClose={closeSignOutModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                {loading ? (
                    <Box
                        style={{
                            position: 'absolute',
                            left: '50%',
                            bottom: 0,
                            width: '100%',
                            height: 4,
                            transform: 'translate(-50%, 0)',
                        }}
                    >
                        <LinearProgress />
                    </Box>
                ) : null}
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {t('header.log_out_from')}
                    <br />
                    <br />
                </Typography>
                {logoutFailure ? (
                    <Typography color={'error'}>
                        <br />
                            {t('header.logOut_failure')}
                        <br />
                            {t('header.please_try_again')}
                        <br />
                    </Typography>
                ) : null}
                <Button size={'large'} onClick={signOutAWS} sx={{ color: '#11470E'}}>
                    {t('header.log_out')}
                </Button>
                <Button
                    color={'info'}
                    size={'large'}
                    onClick={closeSignOutModal}
                    sx={{ color: '#11470E'}}
                >
                    {t('header.cancel')}
                </Button>
            </Box>
        </Modal>
    )
}
