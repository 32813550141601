import { useContext, useEffect, useMemo, useState } from "react";
import { useAuth, useUnreadMessages } from "../../../hooks";
import { Client, Conversation, Message } from '@twilio/conversations';
import { MessagesContext } from "../../../contexts";

interface Options {
    conversation: Conversation;
    selected: Conversation | null;
    client?: Client | null;
}

export const useChatItem = ({ conversation, selected }: Options) => {
    const { loggedIn } = useAuth();
    const { unreadCount: unread,  setUnreadCount: setUnread } = useContext(MessagesContext);

    const [messages, setMessages] = useState<Message[]>([]);
    const [unreadCount, setUnreadCount] = useState(0);

    const fetchMessages = async () => {
        const messages = await conversation.getMessages();

        setMessages(messages?.items || []);
    }

    const handleMessageAdded = async (message: Message) => {
        if (selected?.uniqueName === conversation.uniqueName) {
            setUnreadCount(0);
        }

        if (message.author !== loggedIn.email) {
            handleUnreadMessages();
        }

        setMessages(prev => [...prev, message]);
    };

    const lastMessage = useMemo(() => {
        return messages[messages.length - 1];
    }, [messages])

    useEffect(() => {
        fetchMessages();

        conversation?.on('messageAdded', handleMessageAdded);

        return () => {
            conversation?.removeListener("messageAdded", handleMessageAdded);
        };
    }, [])

    const handleUnreadMessages = async () => {
        const unreadMessages = await conversation.getUnreadMessagesCount();

        if (unreadMessages !== null && selected?.uniqueName !== conversation.uniqueName) {
            setUnreadCount(unreadMessages);
        }
    }

    useEffect(() => {
        handleUnreadMessages();
    }, [])

    useEffect(() => {
        if (selected?.uniqueName === conversation.uniqueName) {
            setUnreadCount(0);
            setUnread(unread - unreadCount);
        }
    }, [selected, messages, conversation, unreadCount])

    return {
        lastMessage,
        unreadCount,
        messages,
        setUnreadCount
    }
}
