import { Storage } from "aws-amplify";
import { useEffect, useMemo, useState } from "react";
import { useSearch } from "../../SearchPage/hooks";
import { UserInfo } from "../../../API";

interface Options {
    user: UserInfo | null;
}

export const useUser = ({ user }: Options) => {
    const [userImage, setUserImage] = useState('');

    const {
        areas,
        getAreas
    } = useSearch({});

    const getImage = async () => {
        const storageResults = await Storage.list(user ? user.sub : "");
  
      for (const file of storageResults.results) {
        if (file?.key && user?.sub && file?.key.includes(user?.sub)) {
          const fileName = user?.profileImageFilename || '';
  
          try {
            const expirationTime = 7 * 24 * 60 * 60;

            const fileRes = await Storage.get(fileName, { expires: expirationTime });
            setUserImage(fileRes);
          } catch (err) {
            console.log('File error:', err);
          }
        }
      }
    }

    const activeAreas = useMemo(() => {
        return areas.filter(el => !el?.disabled && el?.ownerSub === user?.sub)
    }, [areas])

    const disabledAreas = useMemo(() => {
        return areas.filter(el => el?.disabled && el?.ownerSub === user?.sub)
    }, [areas])

    const openedAreas = useMemo(() => {
        if (!user) return;

        return areas.filter(el => !el?.disabled && el?.InvestorsWithApprovedAccess?.includes(user?.sub))
    }, [areas])

    useEffect(() => {
        getImage();
    }, [user])

    useEffect(() => {
        getAreas({ or: [
            { ownerSub: { eq: user?.sub } },
            { InvestorsWithApprovedAccess: { contains: user?.sub } }
          ]})
    }, [user])

    return {
        userImage,
        activeAreas,
        disabledAreas,
        openedAreas
    }
}