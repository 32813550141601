import { useTranslation } from "react-i18next";
import { Dropdown } from "../../../components";
import { federalStates, types } from "../../../utils/formLists";
import { Dispatch, SetStateAction } from "react";

interface SearchDropdownsProps {
    onFilterType: Dispatch<SetStateAction<string>>;
    onFilterMunicipality: Dispatch<SetStateAction<string>>;
}

export const SearchDropdowns = (props: SearchDropdownsProps) => {
    const { onFilterType, onFilterMunicipality } = props;
    const { t } = useTranslation();

    const filterTypes = [
        {
            "value": "addArea.all",
            "label": "addArea.all"
        },
        ...types
    ];

    const municipalities = [
        {
            "value": "addArea.all",
            "label": "addArea.all"
        },
        ...federalStates
    ];

    return (
        <div className="flex w-full flex-col gap-[20px] md:w-fit md:flex-row">
            <Dropdown
                placeholder={t('search.type')}
                options={filterTypes}
                filterStyle="md:w-[180px] w-full py-[11px] rounded-[6px]"
                height="h-[250px]"
                setFilter={onFilterType}
            />

            <Dropdown
                placeholder={t('search.municipality')}
                options={municipalities}
                height="h-[250px]"
                filterStyle="md:w-[180px] w-full py-[11px] rounded-[6px]"
                setFilter={onFilterMunicipality}
            />
        </div>
    )
}
