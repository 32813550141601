import { UserInfo } from "../../../API";
import { TOfferAdsAreaSchema } from "../../../helpers";

interface MapValues {
    latitude: number;
    longitude: number;
    mapElevation: number;
}

export const createNewArea = (
    loggedIn: UserInfo,
    formData: TOfferAdsAreaSchema, 
    areaSize: number, 
    mapValues: MapValues,
    zoom: number,
    energyData: any,
    data: any,
    monthly: any,
    language: string
) => {
    const newArea = {
        ownerSub: loggedIn.sub,
        areaType: formData.type,
        areaSize: formData.size,
        areaName: formData.type,
        areSizeMapbox: Math.round(areaSize),

        address_state: formData.state || '',
        address_street: formData.street || '',
        address_postcode: formData.postalCode,
        address_city: formData.city,
        address_country: formData.country,
        address_other: '',

        compensationType: '',
        locale: language,

        additional_information: '',

        GeoCoordinatesLatitude: mapValues.latitude,
        GeoCoordinatesLongitude: mapValues.longitude,
        GeoCoordinatesElevation: mapValues.mapElevation,

        updatedTimeUnix: Math.floor(new Date().getTime() / 1000),

        disabled: false,
    }

    const areaToCreate = {
        ...newArea,
        MapboxZoomfactor: Math.round(zoom),
        PvGISInputFixedSlope:
            energyData?.inputs?.mounting_system?.fixed?.slope.value,
        PvGISInputFixedAzimuth:
            energyData?.inputs?.mounting_system?.fixed?.azimuth?.value,
        PvGISInputSystemLoss:
            energyData?.inputs?.pv_module?.system_loss,
        PvGISCalcOutputTotalE_y:
            energyData?.outputs?.totals?.fixed?.E_y,
        PvGISCalcOutputperMonthE_y: monthly,
        PvGISDateOfCalc: new Date().toISOString(),
        GeoCircumferenceMapbox: data.features.map(
            (el: any) => el.geometry.coordinates[0]
        ),
    }

    return areaToCreate;
}