import { FieldErrors, SetFieldValue, UseFormRegister } from "react-hook-form";
import { Organization } from '../../../API';
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction } from "react";
import { Loader } from "../../../components";
import { InviteModal } from "./InviteModal";
import { TEditingUserInfoSchema } from "../../../helpers";
import { CompanyMember } from "./CompanyMember";
import { CompanyInfo } from "./CompanyInfo";
import { useMembers } from "../hooks";

interface CompanyFormProps {
    register: UseFormRegister<TEditingUserInfoSchema>;
    organization?: Organization | null;
    memberOf?: Organization | null;
    setValue: SetFieldValue<TEditingUserInfoSchema>;
    openInvite: boolean;
    onInvite:  Dispatch<SetStateAction<any>>;
    onOpenInvite: Dispatch<SetStateAction<boolean>>;
    errors: FieldErrors<TEditingUserInfoSchema>;
    invited: (Organization | null)[] | undefined;
    onNotRegistered: Dispatch<SetStateAction<string[]>>;
}

export const CompanyForm = ({
    register,
    openInvite,
    onOpenInvite,
    organization,
    setValue,
    errors,
    onInvite,
    memberOf,
    invited,
    onNotRegistered
}: CompanyFormProps) => {
    const { t } = useTranslation();

    const {
        owner,
        email,
        error,
        isLoading,
        setEmail,
        handleApprove
    } = useMembers({
        memberOf,
        organization,
        onInvite,
        onOpenInvite,
        onNotRegistered
    })

    return (
        <div className={`${!invited?.length && !memberOf && 'mb-[50px]'}`}>
            {isLoading && <Loader />}
            <div className="flex flex-col gap-[20px]">
                <div className="flex justify-between">
                    <h1 className="text-[24px] font-medium">
                        {t('organization.company_info')}
                    </h1>

                    {!memberOf && !invited?.length && (
                        <button 
                            className="whitespace-nowrap rounded-[12px] bg-[#B0CC55] 
                                px-[15px] py-[5px] text-[12px] text-white md:block hidden"
                        >
                            {t('profile.buttons.edit_profile')}
                        </button>
                    )}
                </div>

                {!memberOf ? (
                        !invited?.length && (
                            <CompanyInfo
                                register={register} 
                                setValue={setValue}
                                organization={organization}
                                errors={errors}
                            />
                        )
                ) : (
                    <CompanyMember
                        memberOf={memberOf} 
                        owner={owner}
                    />
                )}

                {openInvite && (
                    <InviteModal 
                        email={email}
                        error={t(error)}
                        setEmail={setEmail}
                        onOpen={onOpenInvite}
                        onApprove={handleApprove}
                    />
                )}
            </div>
        </div>
    )
}
