import { Flaeche } from "../API";
import { SliderPropsMock } from "../types";
import { days, hours, sizeOfCoalPlant } from "../utils/constants";

export interface Data {
    area: number;
    type: string;
}

export const calculateResult = (data: Data | null) => {
    if (data) {
        if (data?.type === 'addArea.roof') {
            const num = data.area / 5 / sizeOfCoalPlant * days * hours;

            return num > 1 ? Math.round(num) : num.toFixed(2);
        } else {
            const num = data.area / 8 / sizeOfCoalPlant * days;
            
            return num > 1 ? Math.round(num) : num.toFixed(2);
        }
    }
}

export const convertToHectares = (val?: number | null) => {
    if (!val) return;
    return Number((val *  0.0001).toFixed(2));
}

export const checkType = (type: string | undefined) => {
    return type?.toLowerCase() === 'field' 
    || type?.toLowerCase() === 'grassland' 
    || type?.toLowerCase() === 'lake' 
    || type === 'addArea.field'
    || type === 'addArea.grassland'
    || type === 'addArea.lake'
    || type?.toLowerCase() === 'acker' 
    || type?.toLowerCase() === 'gruenland' 
    || type?.toLowerCase() === 'see' 
}

export const checkTypeRoof = (area: Flaeche | SliderPropsMock | null) => {
    return area?.areaType.toLowerCase() === 'roof' 
        || area?.areaType.toLowerCase() === 'dach'
        || area?.areaType === 'addArea.roof';
}