import { Flaeche } from '../../../API'
import { AreaTableRow } from './AreaTableRow'
import { DataTable } from '../../../components'
import { areaColumns } from '../utils/columns';

interface AreasTableProps {
    areas: (Flaeche | null)[];
    areaImages: string[];
    onDelete: (id: string) => Promise<void>;
    fetchImages: (id?: string) => Promise<void>;
}

export const AreasTable = ({ 
    areas, 
    areaImages, 
    onDelete, 
    fetchImages 
}: AreasTableProps) => {
    return (
        <DataTable 
            data={areas} 
            columns={areaColumns} 
            renderBodyCell={(area) => (
                <AreaTableRow
                    key={area?.id}
                    area={area}
                    onDelete={onDelete}
                    image={
                        areaImages.find(
                            (image) =>
                                image.includes(area!.id) &&
                                image.includes('Screenshot.png')
                        ) ?? ''
                    }
                    fetchImages={fetchImages}
                />
            )} 
        />
    )
}
