import { useTranslation } from "react-i18next";
import { Conversation } from '@twilio/conversations';
import { useEffect, useState } from "react";
import { ChatItem } from "./ChatItem";
import { Search } from "@mui/icons-material";

interface ChatListProps {
    onOpenChat: (conv: Conversation) => void;
    conversations: Conversation[];
    selected: Conversation | null;
    onSelect: (conv: Conversation | null) => void;
}

export const ChatList = (props: ChatListProps) => {
    const { 
        onOpenChat, 
        conversations, 
        selected, 
        onSelect, 
    } = props;
    const { t } = useTranslation();

    const [userConversations, setUserConversations] = useState<Conversation[]>([]);
    const [query, setQuery] = useState('');

    const handleRemoveConversation = async (conversation: Conversation) => {
        try {
            await conversation.delete();

            const filteredConversations = userConversations
                .filter(conv => conv.uniqueName !== conversation.uniqueName);
            setUserConversations(filteredConversations);
            
            if (conversation.uniqueName === selected?.uniqueName) {
                onSelect(null);
            }
        } catch (error) {
            console.log("Error deleting tha conversation:", error)
        }
    }

    useEffect(() => {
        const sortedConversations = conversations.sort((a, b) => {
            const dateA = a.lastMessage?.dateCreated ? new Date(a.lastMessage?.dateCreated) : new Date(0);
            const dateB = b.lastMessage?.dateCreated  ? new Date(b.lastMessage?.dateCreated) : new Date(0);
            return dateB.getTime() - dateA.getTime();
        });

        setUserConversations(sortedConversations);
    }, [conversations])

    const handleReadMessages = async () => {
        await selected?.setAllMessagesRead();
    }

    useEffect(() => {
        handleReadMessages();
    }, [selected])

    return (
        <div className="h-full lg:border-r-[0.5px] border-[#9e9e9e]">
            <div className="relative p-[25px]">
                <input
                    value={query}
                    placeholder={t('profile.search_chat')}
                    className="mb-[6px] w-full rounded-[12px] border-[0.5px] border-[#9e9e9e] px-[27px] py-[15px] outline-0"
                    onChange={(e) => setQuery(e.target.value)}
                />

                <Search className="h-[30px]!important absolute right-[50px] top-[40px] text-[#9e9e9e]" />
            </div>

            <hr />

            <div className="flex flex-col overflow-y-scroll lg:h-[590px] md:h-[400px] h-[450px] w-full">
                {userConversations.map((conv, i) => (
                        <ChatItem 
                            key={`${conv.uniqueName}_${i}`}
                            query={query}
                            conversation={conv}
                            selected={selected}
                            onOpen={onOpenChat}
                            onRemove={handleRemoveConversation}
                        />
                ))}
            </div>
        </div>
    )
}
