/** @format */

import { useEffect, useMemo, useState } from 'react';
import { motion, AnimatePresence, PanInfo } from 'framer-motion';
import { wrap } from '@popmotion/popcorn';
import { Heading } from '../Title';
import { Flaeche } from '../../API';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { checkTypeRoof, convertToHectares } from '../../helpers/calculateResult';
import { Links } from '../../settings';
import { fetchImage } from '../../utils';
import { useLanguage } from '../../hooks';

interface CarouselProps {
    images: string[];
    lands: (Flaeche | null)[];
    fetchImages: (id?: string | undefined) => Promise<void>;
}

const sliderVariants = {
    incoming: (direction: number) => ({
        x: direction > 0 ? '100%' : '-100%',
        scale: 1.2,
        opacity: 0,
    }),
    active: { x: 0, scale: 1, opacity: 1 },
    exit: (direction: number) => ({
        x: direction > 0 ? '-100%' : '100%',
        scale: 1,
        opacity: 0.2,
    }),
};

const sliderTransition = {
    duration: 0.6,
    ease: [0.56, 0.03, 0.12, 1.04],
};

export const Carousel = ({ images, lands, fetchImages }: CarouselProps) => {
    const [[imageCount, direction], setImageCount] = useState([0, 0]);
    const [imageUrl, setImageUrl] = useState<string>("");

    const activeImageIndex = wrap(0, lands.length, imageCount);
    const { i18n, t } = useTranslation();
    const { selectedLanguage } = useLanguage({ i18n });
    const navigate = useNavigate();

    const selectedImage = useMemo(() => {
        if (lands[activeImageIndex]?.id) {
            return images.find((image) => image.includes(lands[activeImageIndex]?.id || '') && image.includes('Screenshot')) ?? "";
        }
        return ""; 
    }, [lands, activeImageIndex, images]);

    const title = lands[activeImageIndex]?.address_state || lands[activeImageIndex]?.address_country || '';

    const swipeToImage = (swipeDirection: number) => {
        setImageCount([imageCount + swipeDirection, swipeDirection])
    };

    const dragEndHandler = (dragInfo: PanInfo) => {
        const draggedDistance = dragInfo.offset.x
        const swipeThreshold = 50
        if (draggedDistance > swipeThreshold) {
            swipeToImage(-1)
        } else if (draggedDistance < -swipeThreshold) {
            swipeToImage(1)
        }
    };

    const skipToImage = (imageId: number) => {
        let changeDirection = 0;
        if (imageId > activeImageIndex) {
            changeDirection = 1
        } else if (imageId < activeImageIndex) {
            changeDirection = -1
        }
        setImageCount([imageId, changeDirection])
    };

    const dotsVariants = {
        initial: {
            y: 0,
        },
        animate: {
            y: -10,
            scale: 1.2,
            transition: { type: 'spring', stiffness: 1000, damping: '10' },
        },
        hover: {
            scale: 1.1,
            transition: { duration: 0.2 },
        },
    };

    const handleDotClick = (id: string) => {
        const currentIndex = lands.findIndex((land) => land?.id === id)
        skipToImage(currentIndex)
    };

    const handleNavigate = () => {
        navigate(`${Links.search.index}/${lands[activeImageIndex]?.id}`);
    }

    useEffect(() => {
        async function loadImage() {
            try {
                const fetchedImageUrl = await fetchImage(selectedImage);
                if (fetchedImageUrl) {
                    setImageUrl(fetchedImageUrl);
                }
            } catch (error) {
                console.error('Error loading image:', error);
            }
        }
        loadImage();
    }, [selectedImage]);

    useEffect(() => {
        fetchImages(lands[activeImageIndex]?.id);
    }, [activeImageIndex])

    return (
        <div className="w-[100%] block md:hidden">
            {lands && (
                <div className="mt-[25px] mb-[45px] md:mb-[25px] flex flex-col items-center">
                    <div 
                        className="relative h-[320px] w-[100%] max-w-[350px] overflow-hidden rounded-[12px]" 
                        onClick={handleNavigate}
                    >
                        <AnimatePresence initial={false} custom={direction}>
                            <motion.div
                                key={lands[activeImageIndex]?.id}
                                style={{
                                    backgroundImage: `url(${imageUrl})`,
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    opacity: 1,
                                    position: 'absolute',
                                    height: '100%',
                                    width: '100%',
                                    borderRadius: '12px',
                                    willChange: 'transform',
                                    cursor: 'grab',
                                }}
                                custom={direction}
                                variants={sliderVariants}
                                initial="incoming"
                                animate="active"
                                exit="exit"
                                transition={sliderTransition}
                                drag="x"
                                dragConstraints={{ left: 0, right: 0 }}
                                dragElastic={1}
                                onDragEnd={(_, dragInfo) =>
                                    dragEndHandler(dragInfo)
                                }
                                className="gb-cover opacity absolute h-full w-full rounded-[12px] bg-center bg-no-repeat will-change-transform hover:cursor-grab active:cursor-grabbing"
                            />
                        </AnimatePresence>
                    </div>
                    <div 
                        className="mt-[20px] text-center md:text-left cursor-pointer" 
                        onClick={handleNavigate}
                    >
                        <Heading title={t(title)} />
                        
                        <p className="text-lg opacity-50">
                            Size: {checkTypeRoof(lands[activeImageIndex]) 
                                ? lands[activeImageIndex]?.areaSize?.toLocaleString(selectedLanguage === 'de' ? 'de-DE' : 'en-US') 
                                : convertToHectares(lands[activeImageIndex]?.areaSize)?.toLocaleString(selectedLanguage === 'de' ? 'de-DE' : 'en-US')} {" "}
                            {checkTypeRoof(lands[activeImageIndex])  ? 'm²' : 'ha'}
                        </p>
                    </div>

                    <div className="mt-[30px] flex items-center justify-center gap-x-5">
                        {lands.map((i) => (
                            <motion.div
                                key={i?.id}
                                className={`h-2.5 w-2.5 cursor-pointer rounded-full bg-primary ${
                                    lands[activeImageIndex]?.id === i?.id
                                        ? 'active'
                                        : ''
                                }`}
                                onClick={() => handleDotClick(i ? i?.id : '')}
                                initial="initial"
                                animate={
                                    lands[activeImageIndex]?.id === i?.id
                                        ? 'animate'
                                        : ''
                                }
                                whileHover="hover"
                                variants={dotsVariants}
                            ></motion.div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}
