import { useRef } from 'react';
import { ProgressArea } from '../../../types';
import { useTranslation } from 'react-i18next';
import { ConfirmProgressModal } from './ConfirmProgressModal';
import { SliderArrows } from '../../../components';

interface ProgressSliderProps {
    blocks: ProgressArea[];
    selected: ProgressArea | null;
    onSelect: (val: ProgressArea)  => void;
    isActive: (val: ProgressArea) => ProgressArea | undefined;
    isCompleted: (val: ProgressArea) => ProgressArea | undefined;
    onClose: () => void;
    onStart: () => void;
    onComplete: () => void;
    onCancel: () => void;
}

export const ProgressSlider = (props: ProgressSliderProps) => {
    const { t } = useTranslation();
    const { 
        blocks, 
        selected, 
        onSelect, 
        isCompleted, 
        isActive,
        onClose,
        onCancel,
        onComplete,
        onStart
    } = props;

    const sliderRef = useRef<HTMLDivElement>(null);

    return (
        <div className="hidden lg:block overflow-x-auto slider smooth-scroll mb-[30px] md:mb-[60px]" ref={sliderRef}>
            <div className='flex xl:pl-[80px] lg:pl-[65px]'>
                {blocks.map((el, i) => (
                    <div className='relative' key={el.id}>
                        <div
                            className={`border-box flex h-[263px] min-w-[300px] flex-col rounded-[10px] border-[1px] border-[#111A2126] px-[25px]
                                py-[15px] text-start md:py-[30px] mx-[10px]
                                ${
                                    selected?.id === el.id
                                        ? 'border-[2px] border-[#8EAD44]'
                                        : 'border-[1px] border-[#111A2126]'
                                }
                            `}
                            key={el.id}
                            onClick={() => onSelect(el)}
                        >
                            <div className="flex w-full justify-between">
                                <img
                                    src={el.image}
                                    alt="block"
                                    className="mb-[20px] h-[38px]"
                                />
                                <div className="h-[24px] w-[24px] rounded-[50%] bg-grey">
                                    {!isActive(el) && !isCompleted(el) && (
                                        <div className="bg-grey h-[24px] w-[24px] rounded-[50%]">
                                            <img src='/assets/icons/done.svg' alt="progress status" />
                                        </div>
                                    )}

                                    {isCompleted(el) && (
                                        <div className="bg-[#8EAD44] h-[24px] w-[24px] rounded-[50%]">
                                            <img src='/assets/icons/done.svg' alt="progress status" />
                                        </div>
                                    )}

                                    {isActive(el) && (
                                        <div className="h-[24px] w-[24px] rounded-[50%]">
                                            <img src='/assets/icons/completed.svg' alt="progress status" />
                                        </div>
                                    )}
                                </div>
                            </div>

                            <p className="mb-[15px] whitespace-nowrap text-start text-[20px] font-medium md:text-[20px]">
                                {t(el.title)}
                            </p>

                            <p className="text-[16px] font-normal">
                                {t(el.description)}
                            </p>
                        </div>

                        {selected && selected.id === el.id && (
                            <ConfirmProgressModal
                                onClose={onClose}
                                onStart={onStart}
                                onComplete={onComplete}
                                started={isActive(selected)}
                                finished={isCompleted(selected)}
                                onCancel={onCancel}
                            />
                        )}
                    </div>
                ))}
            </div>

            <SliderArrows sliderRef={sliderRef} />
        </div>
    )
}
