import { useTranslation } from "react-i18next";
import { Organization, UserInfo } from "../../../API"

interface CompanyMemberProps {
    memberOf?: Organization | null;
    owner?: UserInfo | null;
}

export const CompanyMember = ({
    memberOf,
    owner
}: CompanyMemberProps) => {
    const { t } = useTranslation();

    return (
        <div>
            <p className="mb-[20px]">
                {t('organization.member')}
            </p>

            <div className="flex flex-col justify-between md:flex-row">
                <div className="flex flex-col gap-[10px]">
                    <p className="text-[14px] text-[#9e9e9e]">
                        {t('organization.company_name')}
                    </p>
                    <p>{memberOf?.CompanyName}</p>
                </div>

                <div className="flex flex-col gap-[10px]">
                    <p className="text-[14px] text-[#9e9e9e]">
                        {t('organization.website')}
                    </p>
                    <p>{memberOf?.websiteDomain}</p>
                </div>

                <div className="flex flex-col gap-[10px]">
                    <p className="text-[14px] text-[#9e9e9e]">
                        {t('organization.admin')}
                    </p>

                    <p className="text-[14px] text-secondary">
                        {owner?.firstName} {owner?.lastName}, {owner?.email}
                    </p>
                </div>
            </div>
        </div>
    )
}
