import { useParams } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from "react-i18next";
import { articles } from "../../utils";



export const ArticleDetails = () => {
    const { articleId } = useParams();
    const { t } = useTranslation();

    const article = useMemo(() =>  {
        return articles.find((article) => article.id === articleId)
    }, [articleId]);

    const articleJsonLd = useMemo(() => {
        return {
            "@context": "https://schema.org",
            "@type": "Article",
            "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": `https://www.enlapa.de/${article?.id}`
            },
            "headline": t(article!.title),
            "description": t(article!.description),
            "author": {
                "@type": "Organization",
                "name": "Enlapa"
            },
            "publisher": {
                "@type": "Organization",
                "name": "Enlapa",
                "logo": {
                "@type": "ImageObject",
                "url": "https://www.enlapa.de/Enlapa logo.png",
                "width": "150",
                "height": "25"
                }
            },
            "datePublished": ""
        };
    }, [article, t]);

    useEffect(() => {
        document.title = t(article!.title);
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
            metaDescription.setAttribute('content', t(article!.description));
        }

        return () => {
            document.title = 'ENLAPA - Das Portal f&uuml;r Energie-Land-Pacht';
            if (metaDescription) {
                metaDescription.setAttribute('content', 'Flächen zur Energieerzeugung anbieten oder pachten');
            }
        };
    }, [article, t]);

    return (
        <HelmetProvider>
            <Helmet>
                <title>{t(article!.title)}</title>
                <meta name="description" content={t(article!.description)} />
                <script type="application/ld+json">
                    {JSON.stringify(articleJsonLd)}
                </script>
            </Helmet>

            <div className="px-[25px] py-[40px] md:px-[100px] md:py-[100px] lg:py-[60px]">
                {article?.component}
            </div>
        </HelmetProvider>
    )
}
