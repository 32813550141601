import { Splide, SplideSlide } from '@splidejs/react-splide';
import { useEffect, useState } from 'react';
import { LazyLoadImage } from '../../../components';

interface ProjectSliderProps {
    images: string[] | null;
}

export const ProjectSlider = (props: ProjectSliderProps) => {
    const { images } = props;
    const [mainPhoto, setMainPhoto] = useState(images 
        ? images.find(el => el.includes('Screenshot')) 
        : ''
    );

    const checkImages = () => {
        if (!images) return;

        if (images?.length < 3) {
            return 2;
        }

        if (images.length === 3) {
            return 3;
        }

            return 4;
    }

    useEffect(() => {
        setMainPhoto(images ? images.find(el => el.includes('Screenshot')) : '');
    }, [images])

    const handleNext = () => {
        const currentIndex = images?.findIndex(image => image === mainPhoto);

        if (images?.length && currentIndex !== undefined) {
            if (currentIndex <= images.length - 2) {
                setMainPhoto(images[currentIndex + 1]);
            } else {
                setMainPhoto(images[0]);
            }
        }
    }

    const handlePrev = () => {
        const currentIndex = images?.findIndex(image => image === mainPhoto);

        if (images?.length && currentIndex !== undefined) {
            if (currentIndex > 0) {
                setMainPhoto(images[currentIndex - 1]);
            } else {
                setMainPhoto(images[images.length - 1]);
            }
        }
    }

    const arrowNext = document.querySelector('.arrow-next-project');
    const arrowPrev = document.querySelector('.arrow-prev-project');

    useEffect(() => {
        arrowNext?.addEventListener('click', handleNext);
        arrowPrev?.addEventListener('click', handlePrev);

        return () => {
            arrowNext?.removeEventListener('click', handleNext);
            arrowPrev?.removeEventListener('click', handlePrev);
        }
    }, [arrowNext, arrowPrev, mainPhoto])

    if (images?.length === 1) {
        return (
        <div className='flex flex-col items-center'>
            <div className="mb-[25px] rounded-[8px] border-[10px] border-[#F2F2F2] flex justify-center 
                overflow-hidden md:max-h-[360px] max-h-[200px] md:w-[530px] w-[310px]"
            >
                <LazyLoadImage
                    src={mainPhoto}
                    alt=""
                    className="object-contain"
                />
            </div>
        </div>
        );
    }

    return (
        <div className='flex flex-col items-center'>
            <div className="mb-[25px] rounded-[8px] border-[10px] border-[#F2F2F2] flex justify-center 
                items-center overflow-hidden md:h-[360px] h-[200px] md:w-[530px] w-[310px]"
            >
                <LazyLoadImage
                    src={mainPhoto}
                    alt=""
                    className="object-contain"
                />
            </div>

                <div className="max-w-[330px] md:max-w-[450px]">
                    <Splide
                        options={{
                            classes: {
                                arrows: 'arrows-project',
                                next: 'arrow-next-project',
                                prev: 'arrow-prev-project',
                            },
                            type: 'loop',
                            perPage: checkImages(),
                            perMove: 1,
                            gap: 15,
                            pagination: false,
                            arrows: images && images.length > 1 ? true : false,
                        }}
                    >
                        {images?.map((el, i) => (
                            <SplideSlide key={el} onClick={() => setMainPhoto(el)}>
                                <div className={
                                    `${mainPhoto === el 
                                        ? 'border-primary' 
                                        : 'border-[#F2F2F2]'
                                    } max-h-[70px] w-[90px] rounded-[4px] border-[4px] overflow-hidden flex justify-center items-center`
                                }>
                                    <LazyLoadImage
                                        src={el}
                                        alt=""
                                        className="object-contain cursor-pointer"
                                    />
                                </div>
                            </SplideSlide>
                        ))}
                    </Splide>
                </div>
        </div>
    )
}
