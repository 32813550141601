import { handleIconColor } from "../../../helpers/iconColor";

interface ProgressDescProps {
    description: string;
    status?: string;
    sx?: boolean;
}

export const ProgressDesc = (props: ProgressDescProps) => {
  const { description, status, sx } = props;

  return (
    <div className="group-hover:block hidden z-10 absolute md:w-[200px] bg-white md:right-[-100px] mt-[10px] border-[1px] p-[10px] rounded-[12px]">
        <p className={`mb-[10px] ${sx ? 'text-[12px]' : 'text-[14px]'}`}>
          {description}
        </p>

        {status && (
          <p className={`text-[${handleIconColor(status)}] ${sx ? 'text-[12px]' : 'text-[14px]'}`}>
            {status}
          </p>
        )}
    </div>
  )
}
