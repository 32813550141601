import { GraphQLQuery } from "@aws-amplify/api";
import { API, graphqlOperation } from "aws-amplify";
import {
    CreateIncompleteAreasFromOfferFormMutation,
    DeleteIncompleteAreasFromOfferFormMutation,
    GetIncompleteAreasByOwnerSubQuery,
    UpdateIncompleteAreasFromOfferFormMutation,
} from '../../../API';
import { getIncompleteAreasByOwnerSub } from "../../../graphql/queries";
import { useAuth } from "../../../hooks";
import { Dispatch, useEffect, useState } from "react";
import {
    createIncompleteAreasFromOfferForm,
    deleteIncompleteAreasFromOfferForm,
    updateIncompleteAreasFromOfferForm,
} from '../../../graphql/mutations';
import { Geolocation } from "../../../types";
import { FieldValues } from "react-hook-form";

interface Options {
    setFieldsData: Dispatch<any>;
    fieldsData: FieldValues | null;
}

export const useIncompleteData = ({
    fieldsData,
    setFieldsData
}: Options) => {
    const { loggedIn } = useAuth();

    const [incompleteId, setIncompleteId] = useState('');
    const [geolocation, setGeolocation] = useState<Geolocation | null>(null);

    const getIncompleteData = async () => {
        try {
            const { data: incomplete } = await API.graphql<GraphQLQuery<GetIncompleteAreasByOwnerSubQuery>> (
                graphqlOperation(getIncompleteAreasByOwnerSub, { ownerSub: loggedIn.sub })
            );

            const response = incomplete?.getIncompleteAreasByOwnerSub?.items;

            return response;
        } catch (error) {
            console.log("Error:", error)
        }
    }

    const handleVisitPage = async () => {
        try {
            if (incompleteId) return;

            const response = await getIncompleteData();

            if (response && response.length > 0) {
                const olderData = {
                    type: response.find(el => el?.areaType)?.areaType,
                    size: response.find(el => el?.areaSize)?.areaSize
                }

                setFieldsData({
                    ...fieldsData,
                    ...olderData
                })

                setIncompleteId(response[response.length - 1]?.id ?? '')

                return;
            }

            const incomplete = {
                ownerSub: loggedIn.sub,
                areaType: ''
            };

            const { data } = await API.graphql<GraphQLQuery<CreateIncompleteAreasFromOfferFormMutation>>(
                graphqlOperation(createIncompleteAreasFromOfferForm, { input: incomplete })
            );

            if (!data?.createIncompleteAreasFromOfferForm?.id) return;
            setIncompleteId(data?.createIncompleteAreasFromOfferForm?.id);
        } catch (error) {
            console.log("Error:", error);
        }
    }

    const handleGeolocation = async () => {
        try {
            const incomplete = {
                id: incompleteId,
                GeoCoordinatesLatitude: geolocation?.latitude,
                GeoCoordinatesLongitude: geolocation?.longitude
            };

            await API.graphql<GraphQLQuery<UpdateIncompleteAreasFromOfferFormMutation>>(
                graphqlOperation(updateIncompleteAreasFromOfferForm, { input: incomplete })
            );
        } catch (error) {
            console.log("ERROR:", error)
        }
    }

    const removeIncompleteData = async () => {
        try {
            if (!incompleteId) return;

            const itemToDelete = {
                id: incompleteId
            }

            await API.graphql<GraphQLQuery<DeleteIncompleteAreasFromOfferFormMutation>>(
                graphqlOperation(deleteIncompleteAreasFromOfferForm, {input: itemToDelete})
            );
        } catch (error) {
            console.log("Error:", error);
        }
    }

    useEffect(() => {
        if (!incompleteId) {
            handleVisitPage();
        }
    }, [])

    useEffect(() => {
        if (geolocation) {
            handleGeolocation();
        }
    }, [geolocation])

    return {
        geolocation,
        incompleteId,
        setGeolocation,
        handleGeolocation,
        removeIncompleteData
    }
}