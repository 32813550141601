import { useTranslation } from "react-i18next";
import { Card } from "../../../types";
import { MouseEventHandler } from "react";

interface CreditCardProps {
    card: Card;
    selectedCard: Card | null;
    onSelect: (card: Card) => void;
    onRemove: (card: Card) => void;
}

export const CreditCard = ({
    card,
    selectedCard,
    onSelect,
    onRemove
}: CreditCardProps) => {
    const { t } = useTranslation();

    return (
        <div
            key={card.number}
            className="mb-[10px] flex w-full flex-wrap items-center justify-between 
                rounded-[12px] bg-[#F2F2F2] px-[26px] py-[29px] hover:bg-primary"
                onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
                    const targetElement = event.target as HTMLDivElement;
                    if (targetElement.tagName !== 'IMG') {
                        onSelect(card);
                    }
                }}
        >
            <div className="flex items-center gap-[20px] md:w-[300px]">
                <img src="/assets/icons/Visa.svg" alt="visa" />

                <p>{'*'.repeat(6) + card.number.slice(12)}</p>

                {selectedCard?.number === card.number && (
                    <button
                        type="button"
                        className="hidden h-[30px] rounded-[60px] bg-[#8EAD44] px-[12px]
                            text-[11px] text-white lg:block"
                    >
                        {t('profile.default')}
                    </button>
                )}
            </div>

            <p className="hidden text-[14px] lg:block">
                {t('profile.expires')} {card.expires}
            </p>

            <img
                src="/assets/icons/clear.svg"
                alt="close"
                className="h-[20px] cursor-pointer"
                onClick={() => onRemove(card)}
            />

            <div className="flex w-full justify-between gap-[20px]">
                <p className="mt-[20px] block text-[14px] lg:hidden">
                    {t('profile.expires')} {card.expires}
                </p>

                {selectedCard?.number === card.number && (
                    <button
                        className="mt-[20px] block h-[30px] rounded-[60px] bg-[#8EAD44] 
                            px-[12px] text-[11px] text-white lg:hidden"
                        type="button"
                    >
                        {t('profile.default')}
                    </button>
                )}
            </div>
        </div>
    )
}
