import { LabelUpInput } from "../../../components";
import { TOfferAdsAreaSchema } from "../../../helpers";
import { types } from "../../../utils/formLists";
import { FormHeader, TypeModal } from "../../Offer/components";
import i18n from "../../../i18n";
import { useState } from "react";
import { FieldErrors, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../../hooks";

interface FirstStepFormProps {
    data?: any;
    register: UseFormRegister<TOfferAdsAreaSchema>;
    setValue: UseFormSetValue<TOfferAdsAreaSchema>;
    errors: FieldErrors<TOfferAdsAreaSchema>;
}

export const FirstStepForm = ({
    register,
    setValue,
    errors,
    data,
}: FirstStepFormProps) => {
  const [visibleType, setVisibleType] = useState(false);

  const { t } = useTranslation();
  const { selectedLanguage } = useLanguage({ i18n });

  const handleModalType = () => {
    setVisibleType(prev => !prev)
  };

  return (
    <>
        <div 
            className="flex gap-[20px] md:flex-row flex-col items-end 
            px-[25px] md:px-[80px] xl:px-[180px] mb-[40px]"
        >
            <div className="relative w-[100%]">
                <FormHeader
                    title={t('addArea.type_and_size')}
                    icon={
                        <img
                            src="/assets/icons/error_outline.svg"
                            alt="info"
                            onClick={handleModalType}
                            className="cursor-pointer"
                        />
                    }
                    sx="flex gap-[10px]"
                />

                <LabelUpInput
                    type="dropdown"
                    name="type"
                    value={data?.type}
                    star={true}
                    options={types}
                    starStyle={
                        selectedLanguage === 'en'
                            ? `left-[110px]`
                            : 'left-[130px]'
                    }
                    register={register}
                    error={errors['type' as keyof TOfferAdsAreaSchema]}
                    placeholder={t('addArea.placeholders.type')}
                    setValue={setValue}
                />

                {visibleType && <TypeModal />}
            </div>

            <div className="w-full">
                <LabelUpInput
                    name="size"
                    value={data?.size}
                    setValue={setValue}
                    star={true}
                    starStyle={
                        selectedLanguage === 'en'
                            ? `left-[180px] md:hidden lg:left-[180px] lg:block`
                            : `md:hidden left-[200px] lg:block`
                    }
                    register={register}
                    error={errors['size' as keyof TOfferAdsAreaSchema]}
                    placeholder={t('addArea.placeholders.size')}
                />
            </div>
        </div>
    </>
  )
}
