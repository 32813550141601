export const fetchImage = async (url: string) => {
    try {
      const response = await fetch(url);
      
      if (response.ok) {
        const blob = await response.blob();
        return URL.createObjectURL(blob);
      } else if (response.status === 400) {
        window.location.reload();
      } else {
        console.error(`Error fetching image: ${response.status}`);
        return null;
      }
    } catch (error) {
      console.error('Error fetching image:', error);
      return null;
    }
  }