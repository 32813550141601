export const powerElementsDefaultValues = {
    pole: [],
    tower: [],
    substation: [],
    transformer: [],
    switch: [],
    converter: [],
    compensator: [],
    terminal: [],
    plant: [],
};

export const voltageDefaultValues = {
    '765kV (e.g. USA)': [],
    '750kV (e.g. Russia)': [],
    '420kV - 650kV': [],
    '400kV (e.g. France)': [],
    '380kV (e.g. Germany)': [],
    '225kV - 350kV': [],
    '220kV (Westeurope)': [],
    '115kV - 200kV': [],
    '110kV (Distribution Grid Europe)': [],
    '50kV - 100kV': [],
    '30kV - 38kV': [],
    '20kV - 25kV': [],
    '6kV - 15kV': [],
};

export const powerLayersDefaultValues = {
    pole: false,
    tower: false,
    substation: true,
    transformer: false,
    switch: false,
    terminal: false,
    converter: false,
    compensator: false,
    plant: false,
};

export const voltageLayersDefaultValues = {
    '765kV (e.g. USA)': false,
    '750kV (e.g. Russia)': false,
    '420kV - 650kV': false,
    '400kV (e.g. France)': false,
    '380kV (e.g. Germany)': false,
    '225kV - 350kV': false,
    '220kV (Westeurope)': false,
    '115kV - 200kV': false,
    '110kV (Distribution Grid Europe)': false,
    '50kV - 100kV': false,
    '20kV - 25kV': false,
    '6kV - 15kV': false,
};

export const railwayVoltageDefaultValues = {
    '15kV (e.g. Germany)': [],
    '1.5kV': [],

};

export const railwayLayersDefaultValues = {
    '15kV (e.g. Germany)': false,
    '1.5kV': false,

};

export const railwayVoltageQueries: { [key: string]: number } = {
    '15kV (e.g. Germany)': 15000,
    '1.5kV': 1500

};

export const voltageQueries: { [key: string]: number[] } = {
    '765kV (e.g. USA)': [765000, 765000],
    '750kV (e.g. Russia)': [750000, 750000],
    '420kV - 650kV': [420000, 650000],
    '400kV (e.g. France)': [400000, 765000],
    '380kV (e.g. Germany)': [380000, 380000],
    '225kV - 350kV': [225000, 350000],
    '220kV (Westeurope)': [220000, 220000],
    '115kV - 200kV': [115000, 200000],
    '110kV (Distribution Grid Europe)': [110000, 110000],
    '50kV - 100kV': [50000, 100000],
    '30kV - 38kV': [30000, 38000],
    '20kV - 25kV': [20000, 25000],
    '6kV - 15kV': [6000, 15000],
    '1kV - 5kV': [1000, 5000],
    '500V - 950V': [500, 950],
    '220V - 499V': [220, 499],
};

export interface MinLayersZoom {
    pole: number;
    substation: number;
    tower: number;
    generator: number;
    transformer: number;
    switch: number;
    converter: number;
    compensator: number;
    terminal: number;
    plant: number;
}

export const minLayersZoom: MinLayersZoom = {
    pole: 13,
    tower: 13,
    substation: 12,
    generator: 12,
    transformer: 12,
    switch: 10,
    converter: 10,
    compensator: 10,
    terminal: 10,
    plant: 10,
};

export const voltageColor: { [key: string]: string } = {
    '765kV (e.g. USA)': '#ED2939',
    '750kV (e.g. Russia)': '#FF2400',
    '420kV - 650kV': '#CD5C5C',
    '400kV (e.g. France)': '#7C0A02',
    '380kV (e.g. Germany)': '#FF1A1A',
    '225kV - 350kV': '#C21807',
    '220kV (Westeurope)': '#800000',
    '115kV - 200kV': '#B22222',
    '110kV (Distribution Grid Europe)': '#B80F0A',
    '50kV - 100kV': '#960018',
    '30kV - 38kV': '#EA3C53',
    '20kV - 25kV': '#7E191B',
    '6kV - 15kV': '#D21F3C',
    '25kV': "#17B169",
    '15kV (e.g. Germany)': '#FFD300',
    '1.5kV': "#1CAC78",
};
