import { useTranslation } from 'react-i18next';
import { CallToAction } from '../../../components';
import { ArticleImage } from './Image';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { Links } from '../../../settings';

export const SecondArticle = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const handleNavigate = useCallback(() => {
        navigate(Links.offer.index)
    }, [navigate])

    return (
        <div className="mb-[20px] flex flex-col gap-[25px] text-[16px] text-[#333] md:text-[18px]">
            <span className="uppercase text-primary">{t('articles.info')}</span>

            <h1 className="text-[25px] font-medium md:text-[35px]">
                {t('article_two.title')}
            </h1>

            <div className="h-[2px] w-[150px] bg-primary" />

            <span className="text-[13px] uppercase text-gray-700 md:text-[16px]">
                {t('articles.article_two.date')}
            </span>

            <p>{t('article_two.p_1')}</p>

            <p>{t('article_two.p_3')}</p>

            <p>{t('article_two.p_4')}</p>

            <ArticleImage
                source="/assets/images/Tractor.jpg"
                link="https://unsplash.com/de/fotos/gruner-traktor-tagsuber-auf-braunem-rasenfeld-unter-blauem-himmel-dDc0vuVH_LU"
            />

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_two.subtitle_1')}
            </h2>

            <p>{t('article_two.p_5')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_two.subtitle_2')}
            </h3>

            <p>{t('article_two.p_6')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_two.subtitle_3')}
            </h3>

            <p>{t('article_two.p_7')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_two.subtitle_4')}
            </h3>

            <p>{t('article_two.p_8')}</p>

            <p>{t('article_two.list_title')}</p>

            <ul className="flex flex-col gap-[10px] list-disc">
                <li>
                    <b>{t('article_two.points.point_one.bold')}</b>{' '}
                    {t('article_two.points.point_one.text')}
                </li>
                <li>
                    <b>{t('article_two.points.point_two.bold')}</b>{' '}
                    {t('article_two.points.point_two.text')}
                </li>
                <li>
                    <b>{t('article_two.points.point_three.bold')}</b>{' '}
                    {t('article_two.points.point_three.text')}
                </li>
                <li>
                    <b>{t('article_two.points.point_four.bold')}</b>{' '}
                    {t('article_two.points.point_four.text')}
                </li>
                <li>
                    <b>{t('article_two.points.point_five.bold')}</b>{' '}
                    {t('article_two.points.point_five.text')}
                </li>
                <li>
                    <b>{t('article_two.points.point_six.bold')}</b>{' '}
                    {t('article_two.points.point_six.text')}
                </li>
                <li>
                    <b>{t('article_two.points.point_seven.bold')}</b>{' '}
                    {t('article_two.points.point_seven.text')}
                </li>
                <li>
                    <b>{t('article_two.points.point_eight.bold')}</b>{' '}
                    {t('article_two.points.point_eight.text')}
                </li>
                <li>
                    <b>{t('article_two.points.point_nine.bold')}</b>{' '}
                    {t('article_two.points.point_nine.text')}
                </li>
            </ul>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_two.subtitle_5')}
            </h3>

            <p>{t('article_two.p_9')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_two.subtitle_6')}
            </h3>

            <p>{t('article_two.p_10')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_two.subtitle_7')}
            </h3>

            <p>{t('article_two.p_11')}</p>

            <ArticleImage
                source="/assets/images/Acker.jpg"
                link="https://unsplash.com/de/fotos/gruner-rasenplatz-unter-weissen-wolken-tagsuber-PvwdlXqo85k"
            />

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_two.subtitle_8')}
            </h2>

            <p>{t('article_two.p_12')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_two.subtitle_9')}
            </h3>

            <p>{t('article_two.p_13')}</p>

            <p>{t('article_two.p_14')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_two.subtitle_10')}
            </h3>

            <p>{t('article_two.p_15')}</p>

            <p>{t('article_two.p_16')}</p>

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_two.subtitle_11')}
            </h2>

            <p>{t('article_two.p_17')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_two.subtitle_12')}
            </h3>

            <p>{t('article_two.p_18')}</p>

            <p>{t('article_two.p_19')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_two.subtitle_13')}
            </h3>

            <p>{t('article_two.p_20')}</p>

            <p>{t('article_two.p_21')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_two.subtitle_14')}
            </h3>

            <p>{t('article_two.p_22')}</p>

            <div className="mx-[25px] text-[16px] md:mt-[50px]">
                <CallToAction
                    title={t('home.call_to_action_money')}
                    btnTitle={t('buttons.offer_your_space_now')}
                    bgColor="bg-secondary text-white"
                    onClick={handleNavigate}
                    tag="p"
                />
            </div>
        </div>
    )
}
