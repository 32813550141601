import { useTranslation } from 'react-i18next';
import { Button, Heading } from '../../../components';
import { SelectedPlan } from '../../../types';
import i18n from '../../../i18n';
import { useLanguage } from '../../../hooks';

interface SalesPlanProps {
    onSelect: (plan: SelectedPlan) => void;
}

export const SalesPlan = ({ onSelect }: SalesPlanProps) => {
  const { t } = useTranslation();
  const { selectedLanguage } = useLanguage({ i18n });

  const formattedArea = 2000?.toLocaleString(selectedLanguage === 'de' ? 'de-DE' : 'en-US');

  const handleSelectPlan = () => {
    const plan = {
        name: 'pricing.enterprise', 
        price: 'pricing.for', 
    };

    onSelect(plan);

    const form = document.getElementById('form');

    form?.scrollIntoView({
        behavior: "smooth", block: "start"
    });
  }

  return (
    <div className='border-primary-80 xl:w-[1070px] w-full rounded-[20px] border px-[28px] py-[25px] mb-[40px]'>
        <Heading
            title={t("pricing.enterprise")}
            size="text-[24px]"
            color="text-secondary"
        />

        <span className="my-[6px] block font-bold">
            {t('pricing.for')} {formattedArea} m²
        </span>

        <p className='mb-[20px]'>
            {t("pricing.enterprise_desc")} {formattedArea} m² {t("pricing.enterprise_desc2")}
        </p>

        <div className='flex gap-[15px] flex-col md:flex-row items-center'>
            <Button
                color="Primary"
                textColor='text-white'
                type="button"
                width="md:w-fit w-full"
                sx="px-[10px]"
                onClick={handleSelectPlan}
            >
                {t("pricing.call")}
            </Button>
        </div>
    </div>
  )
}
