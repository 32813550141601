import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flaeche, UserInfo } from '../../../API';
import { AreaSize, ChatDetails } from '../../../components';
import { Link } from 'react-router-dom';
import { Links } from '../../../settings';
import { useDetails } from '../hooks/useDetails';

interface ChatHeaderProps {
    area?: Flaeche | null;
    areaImage?: string;
    areaName?: string | null; 
    user?: UserInfo | null;
    onClick?: () => void;
    interestedIn?: string;
}

export const ChatHeader = ({ 
    area, 
    areaName, 
    areaImage,
    user,
    onClick,
    interestedIn
}: ChatHeaderProps) => {
    const [openDetails, setOpenDetails] = useState(false);

    const { t } = useTranslation();
    const {
        areaDetails,
        getAreaDetails
    } = useDetails({ areaId: interestedIn });

    useEffect(() => {
        if (!interestedIn) return;

        getAreaDetails();
    }, [interestedIn])

    return (
        <div className="flex gap-[20px]">
            <img
                src={areaImage}
                alt="profile"
                className={`h-[60px] w-[60px] rounded-full ${user ? '' : 'cursor-pointer'}`}
                onClick={onClick}
            />

            <div className="relative">
                <p onClick={onClick} className={`${user ? '' : 'cursor-pointer'} mb-[10px]`}>
                    {t(areaName ? areaName : '')}
                </p>

                {interestedIn && areaDetails && (
                    <p className='text-[13px]'>
                        {t('profile.interested')} {t('search.area')}:  {" "}

                        <Link
                            to={`${Links.search.index}/${interestedIn}`}
                            className='text-primary underline'
                        >
                            {areaDetails?.areaName ? t(areaDetails?.areaName) : ''} {" "}
                            (<AreaSize areaDetails={areaDetails} />)
                        </Link>
                    </p>
                )}

                {area && (
                    <p
                        className="group cursor-pointer text-[14px] text-[#9e9e9e] 
                underline transition-all hover:text-primary"
                        onClick={() => setOpenDetails(true)}
                    >
                        {t('area.see_details')}
                    </p>
                )}

                {openDetails && area && (
                    <div className="absolute z-10 flex flex-col rounded-[8px] border-[1px] border-primary bg-white p-[10px]">
                        <div
                            className="flex cursor-pointer justify-end"
                            onClick={() => setOpenDetails(false)}
                        >
                            <img
                                src="/assets/icons/clear.svg"
                                className="h-[15px]"
                            />
                        </div>

                        <ChatDetails area={area} />
                    </div>
                )}
            </div>
        </div>
    )
}
