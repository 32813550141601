import { FormControlLabel, TableCell, TableRow } from "@mui/material";
import { AreaButtons } from "./AreaButtons";
import { Flaeche } from "../../../API";
import { useTranslation } from "react-i18next";
import { DeleteModal } from "./DeleteModal";
import { AreaIdModal } from "./AreaIdModal";
import { useEffect, useState } from "react";
import { AreaSize } from "../../../components";
import { useAreaHandling, useInvestorsList } from "../hooks";
import { fetchImage } from "../../../utils";
import { IOSSwitch } from "../styled";
import { AreaAccessBlock } from "./AreaAccessBlock";
interface AreaTableRowProps {
    area?: Flaeche | null;
    image: string;
    onDelete: (id: string) => Promise<void>;
    fetchImages: (id?: string | undefined) => Promise<void>;
}

export const AreaTableRow = ({ area, image, onDelete, fetchImages }: AreaTableRowProps) => {
    const [imageUrl, setImageUrl] = useState('');

    const { t } = useTranslation();
    const {
        selArea,
        owner,
        idModalOpen,
        passedId,
        setPassedId,
        deleteError,
        approveDeleteArea,
        handleCancelDelete,
        isOpenDelete,
        changeAreaStatus,
        handleCloseDelete,
        handleEditArea,
        handleNavigate,
        handleOpenDelete,
        formattedDate,
        handleStatusChange,
        handleDeleteArea,
        isMaximumNumber
    } = useAreaHandling({ area, onDelete });
    const {
        showInvestors,
        showWaitList,
        setShowInvestors,
        setShowWaitList,
        openDeleteModal,
        approveRevoking,
        revokeInvestorAccess,
        cancelRevoking,
        investors,
        waitList
    } = useInvestorsList({ area })

   

    useEffect(() => {
        async function loadImage() {
            try {
                const fetchedImageUrl = await fetchImage(image);
                if (fetchedImageUrl) {
                    setImageUrl(fetchedImageUrl);
                }
            } catch (error) {
                console.error('Error loading image:', error);
            }
        }
        loadImage();
    }, [image]);

    useEffect(() => {
        fetchImages(area?.id);
    }, [area])

    return (
        <>
            <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                onClick={(event: React.MouseEvent<HTMLTableRowElement>) => {
                    const targetTagName = (event.target as HTMLElement).tagName.toUpperCase();
                    if (targetTagName !== 'BUTTON' && targetTagName !== 'INPUT') {
                        console.log(targetTagName);
                        handleNavigate();
                    }
                }}
                className="cursor-pointer"
            >
                <TableCell>
                    <div className="h-[50px] w-[70px] overflow-hidden rounded-[12px] border-[1px]">
                        <img
                            src={imageUrl}
                            alt=""
                            className="h-full object-cover"
                        />
                    </div>
                </TableCell>

                <TableCell>{area?.areaName ? t(area?.areaName) : ''}</TableCell>

                <TableCell>{t(area!.areaType)}</TableCell>

                <TableCell>
                    <AreaSize areaDetails={area} />
                </TableCell>

                <TableCell>
                    {t(area!.address_country ? area!.address_country : '')}
                </TableCell>

                <TableCell>{owner?.email}</TableCell>

                <TableCell>
                    <AreaSize areaDetails={area} mapbox />
                </TableCell>

                <TableCell>{area?.address_city}</TableCell>

                <TableCell>{formattedDate}</TableCell>

                <TableCell>
                    <div className="flex w-full justify-center">
                        <FormControlLabel
                            control={
                                <IOSSwitch
                                    sx={{ m: 1 }}
                                    checked={isMaximumNumber}
                                    onChange={handleStatusChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label=""
                            className="mb-[20px]"
                        />
                    </div>
                </TableCell>

                <AreaAccessBlock 
                    area={area}
                    investors={investors}
                    waitList={waitList}
                    showInvestors={showInvestors}
                    showWaitList={showWaitList}
                    setShowInvestors={setShowInvestors}
                    setShowWaitList={setShowWaitList}
                    revokeInvestorAccess={revokeInvestorAccess}
                />

                <TableCell>
                    <AreaButtons
                        onDelete={handleOpenDelete}
                        onEdit={handleEditArea}
                        onStatus={changeAreaStatus}
                        title={
                            selArea?.disabled 
                                ? 'admin_dashboard.activate' 
                                : 'admin_dashboard.deactivate'
                        }
                    />
                </TableCell>
            </TableRow>

            {/* remove area modal */}
            {isOpenDelete && (
                <DeleteModal
                    text='admin_dashboard.sure'
                    onApproveDelete={approveDeleteArea}
                    onCancelDelete={handleCloseDelete}
                />
            )}

            {/* revoke investor access modal */}
            {openDeleteModal && (
                <DeleteModal
                    text='organization.sure'
                    email={openDeleteModal.email}
                    onApproveDelete={approveRevoking}
                    onCancelDelete={cancelRevoking}
                />
            )}

            {idModalOpen && (
                <AreaIdModal
                    passedId={passedId}
                    error={deleteError}
                    onApprove={handleDeleteArea}
                    onCancel={handleCancelDelete}
                    onChange={setPassedId}
                />
            )}
        </>
    )
}
