import { FeatureCollection, GeoJsonProperties, LineString } from 'geojson';
import mapboxgl from 'mapbox-gl';
import { voltageColor } from '../constants';

const getVoltageColor = (voltage: string) => {
  return voltageColor[voltage] || '#FFD300';
};

export const addLineLayer = (
    map: mapboxgl.Map | null,
    id: string,
    data: any[],
    voltage: string,
    minZoom: number,
    ) => {
    if (!map) return;

    const geojson: FeatureCollection<LineString, GeoJsonProperties> = {
        type: 'FeatureCollection',
        features: data
            .filter((element) => element.type === 'way')
            .map((element) => ({
                type: 'Feature',
                geometry: {
                    type: 'LineString',
                    coordinates: element.geometry.map((node: any) => [
                        node.lon,
                        node.lat,
                    ]),
                },
                properties: {
                    id: element.id,
                    tags: JSON.stringify(element.tags),
                },
            })),
    };

    const updateOrAddLayer = () => {
        if (map.getSource(id)) {
            (map.getSource(id) as mapboxgl.GeoJSONSource).setData(geojson);
        } else {
            map.addSource(id, { type: 'geojson', data: geojson });
        }

        if (!map.getLayer(id)) {
            map.addLayer({
                id,
                type: 'line',
                source: id,
                layout: {
                    'line-join': 'round',
                    'line-cap': 'round',
                },
                paint: {
                    'line-color': getVoltageColor(voltage),
                    'line-width': 2,
                },
                minzoom: minZoom,
            });

            map.on('click', id, handleClick);
            map.on('mouseenter', id, () => {
                map.getCanvas().style.cursor = 'pointer';
            });
            map.on('mouseleave', id, () => {
                map.getCanvas().style.cursor = '';
            });
        } else {
            map.setPaintProperty(id, 'line-color', getVoltageColor(voltage));
        }
    };

    const handleClick = (e: mapboxgl.MapMouseEvent & { features?: mapboxgl.MapboxGeoJSONFeature[] }) => {
        const feature = e.features && e.features[0];
        if (!feature) return;

        const coordinates = e.lngLat;
        const { id, tags } = feature.properties || {};
        const parsedTags = JSON.parse(tags || '{}');

        new mapboxgl.Popup({ closeButton: false })
            .setLngLat(coordinates)
            .setHTML(
                `<h3><strong>ID:</strong> ${id}</h3>` +
                Object.entries(parsedTags)
                    .map(([key, value]) => `<h3><strong>${key}:</strong> ${value}</h3>`)
                    .join('')
            )
            .addTo(map);
    };

    updateOrAddLayer();
};