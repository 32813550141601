export const getElevation = async (coordinates: number[], token: string) => {
    try {
        const response = await fetch(
            `https://api.mapbox.com/v4/mapbox.mapbox-terrain-v2/tilequery/${coordinates.join(',')}.json?layers=contour&limit=50&access_token=${token}`
        );

        const elevationData = await response.json();

        const elevationFeatures = elevationData.features.filter((feature: any) => {
            return (
                feature.geometry.type === 'Point' &&
                feature.properties &&
                feature.properties.ele !== undefined
            );
        });

        const highestElevation = elevationFeatures.reduce(
            (maxElevation: number, feature: any) =>
                Math.max(maxElevation, feature.properties.ele),
            Number.NEGATIVE_INFINITY
        );

        return highestElevation;
    } catch (error) {
        console.error('Error fetching elevation:', error);
    }
};