import { useCallback, useEffect, useState } from 'react';

interface PhotoBlockProps {
    name?: string | null;
    surname?: string | null;
    onImage: (file: File) => void;
    uploadedImage: string | File | null;
}

export const PhotoBlock = (props: PhotoBlockProps) => {
    const { name, surname, onImage, uploadedImage } = props;
    const [image, setImage] = useState<any>('/assets/icons/profile-icon.png');

    useEffect(() => {
        if (uploadedImage) {
            setImage(uploadedImage);
        }
    }, [uploadedImage]);

    const handleFileChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const file = event.target.files?.[0];
            if (file) {
                const fileAsURL = URL.createObjectURL(file);
                onImage(file);
                setImage(fileAsURL);
            }
        },
        [onImage]
    );

    return (
        <div className="flex w-full flex-col items-center md:w-fit">
            <div className="mb-[25px] flex w-full flex-col items-center rounded-[12px] bg-[#F2F2F2] py-[45px] md:w-[300px]">
                <div className="relative mb-[20px]">
                    <img
                        src={image}
                        alt="profile"
                        className="h-[150px] w-[150px] rounded-[50%]"
                    />

                    <label className="camera-icon" htmlFor="file-upload">
                        <div className="absolute bottom-[10px] right-0 cursor-pointer rounded-full bg-[#B0CC55] p-[18px]">
                            <img
                                src="/assets/icons/photo_camera.svg"
                                alt="camera"
                                className="h-[15px]"
                            />
                        </div>
                    </label>

                    <input
                        id="file-upload"
                        type="file"
                        className="hidden"
                        onChange={handleFileChange}
                    />
                </div>

                <div className="flex flex-col items-center justify-center">
                    <p className="mb-[10px] text-[30px] font-semibold">
                        {name} {surname}
                    </p>
                </div>
            </div>
        </div>
    );
};
