import { useCallback, useEffect, useState } from 'react';
import { FormHeader } from './FormHeader';
import { useDropzone } from 'react-dropzone';
import { FilesDropZone } from './FilesDropZone';
import { Delete } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { getFileNameFromUrl } from '../../../helpers/getFileName';

interface DropZonesProps {
    setFiles : (files: any) => void;
    images?: any[];
    files?: any[];
    filesToUpload?: any[];
    onDelete: (file: any) => void;
}

export const DropZones = (props: DropZonesProps) => {
    const { 
        setFiles: onsetFiles, 
        images, 
        files: editFiles,
        onDelete
    } = props;
 
    const { t } = useTranslation();
    const [paths, setPaths] = useState<any[]>([]);
    const [files, setFiles] = useState<any[]>([]);
    const [isOpen, setIsOpen] = useState(false);

    const onDropImages = useCallback((acceptedFiles: any) => {
        setPaths(prev => [...prev, ...acceptedFiles])
        onsetFiles((prev: any) => [...prev, ...acceptedFiles]);
    }, [setPaths, onsetFiles]);

    const onDropFiles = useCallback((acceptedFiles: any) => {
        setFiles(prev => [...prev, ...acceptedFiles])
        onsetFiles((prev: any) => [...prev, ...acceptedFiles]);
    }, [setFiles, onsetFiles]);

    const {
        getRootProps,
        getInputProps,
        isDragReject
    } = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': []
        },
        onDrop: onDropImages
    });

    const handleDelete = (path: any, type: string) => {
        if (type === 'paths') {
            const filteredPaths = paths.filter(el => el !== path || el.path !== path.path);
            setPaths(filteredPaths);
            
            if (images?.includes(path)) {
                const fileName = getFileNameFromUrl(path);

                onDelete((prev: any) => [...prev, fileName]);
            }

            return;
        }

        if (type === 'files') {
            const filteredFiles = files.filter(el => el !== path || el.path !== path.path);
            setFiles(filteredFiles);
            
            const fileName = getFileNameFromUrl(path);

            onDelete((prev: any) => [...prev, fileName]);

            return;
        }
    };

    const handleOpen = () => {
        setIsOpen(prev => !prev);
    }

    useEffect(() => {
        onsetFiles([...paths, ...files]);
    }, [paths, files])

    useEffect(() => {
        if (images) {
            setPaths(images.filter(el => !el.includes('Screenshot')));
            onsetFiles(images);
        }

        if (editFiles) {
            setFiles(editFiles.filter(el => !el.includes('AreaKMLFile') && !el.includes('undefined') && !el.includes('area_details')));
            onsetFiles((prev: any) => [...prev, ...editFiles]);
        }
    }, [images, editFiles])

    return (
        <div className="w-full overflow-hidden px-[25px] md:px-[80px] xl:px-[180px]">
            <div 
                className="mb-[15px] flex justify-between cursor-pointer items-start" 
                onClick={handleOpen}
            >
                <FormHeader title={t('addArea.upload_files')} />
                <img
                    src="/assets/icons/arrowDown.svg"
                    alt="arrow"
                    className={`cursor-pointer ${isOpen ? 'rotate-180' : ""}`}
                />
            </div>

            {isOpen && (
                <>
                    <div className="flex w-full flex-col justify-start">
                        <p className="mb-[20px] max-w-[545px] text-[16px] font-normal text-[#9e9e9e] md:mb-[25px]">
                            {t('addArea.upload_files_description')}
                        </p>
                    </div>

                    <div className="mb-[30px] flex flex-col gap-[30px] md:mb-[53px] md:flex-row">
                        <div className='md:w-[50%] w-full'>
                            <div
                                {...getRootProps()}
                                className="relative flex h-[160px] w-full flex-col items-center 
                                justify-end gap-[33px] rounded-md border-[1px] border-dashed border-[#111A2140] bg-[#F2F2F2] px-[22px] py-[15px] md:gap-[2px]"
                            >
                                <input {...getInputProps()} />
                                
                                <div className="flex items-center gap-[20px]">
                                    <img src='/assets/icons/add_photo_alternate.svg' alt="add" />
                                    <span className="text-[12px] font-bold uppercase text-[#9e9e9e] md:text-[16px]">
                                        {t('addArea.placeholders.drop_photos')}
                                    </span>
                                </div>

                                <div className="flex w-full justify-center md:justify-end">
                                    <button
                                        type="button"
                                        className="rounded-[10px] bg-[#B0CC55] px-[31px] py-[14px]"
                                    >
                                        {t('addArea.upload')}
                                    </button>
                                </div>
                                {isDragReject && "File type not accepted, sorry!"}
                            </div>

                            <div className='w-full flex gap-1 mt-[20px]'>
                                {paths.map(path => (
                                    <div className='relative drop__image' key={path}>
                                        <img 
                                            src={path.path ? URL.createObjectURL(path) : path} 
                                            className='h-[60px]'
                                            alt="dropped"
                                        />

                                        <div 
                                            className='bg-[#eeeeee] px-[5px] rounded-3xl h-[30px] absolute top-[-10px] right-[-5px] dropped__delete'
                                            onClick={() => handleDelete(path, 'paths')}
                                        >
                                            <Delete sx={{ color: 'gray' }} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        
                        <FilesDropZone
                            files={files}
                            onDrop={onDropFiles}
                            onDelete={handleDelete}
                        />
                    </div>
                </>
            )}
        </div>
    )
}
