import { Box, LinearProgress } from '@mui/material';

export const Loader = () => {
  return (
    <Box id={"signUp-loader-wrapper"} sx={{ 
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        textAlign: "center",
        background: "rgba(255,255,255,0.8)",
        pointerEvents: "all",
        zIndex: 100,
    }}>
        <Box style={{width: "70%", margin: "0 auto"}}>
        <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><LinearProgress/>
        </Box>
    </Box>
  )
}
