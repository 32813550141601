import { TFunction } from "i18next";
import { UserInfo } from "../../../API";

export const getEmailData = (
    t: TFunction<"translation", undefined>, 
    loggedIn: UserInfo | null, 
    areaId?: string
) => {
    const emailData = {
        subject: t('email.subject'),
        title: t('email.title'),
        subtitle: t('email.subtitle'),
        button: t('email.button'),
        AreaURL: `https://www.enlapa.de/search/${areaId}`,
        unlocked: t('email.unlocked'),
        email: loggedIn?.email,
        visit: t('email.visit'),
        areas: t('email.areas'),
        wish: t('email.wish'),
        thanks: t('email.thanks'),
        enlapa: t('email.enlapa')
      };

      return emailData;
}