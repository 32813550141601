import { useEffect, } from "react";
import { Chart, registerables, ChartItem } from 'chart.js';
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { useLanguage } from "../../../hooks";

Chart.register(...registerables);

interface ChartBarProps {
    data: number[];
}

export const ChartBar = ({ data }: ChartBarProps) => {
    const { t } = useTranslation();
    const { selectedLanguage } = useLanguage({ i18n });

    useEffect(() => {
        const ctx = document.getElementById('myChart') as ChartItem

        let myChart: Chart;

        if (ctx) {
            myChart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: [
                        t("area.months.Jan"), 
                        t("area.months.Feb"),
                        t("area.months.Mar"),
                        t("area.months.Apr"),
                        t("area.months.May"),
                        t("area.months.June"),
                        t("area.months.Jul"),
                        t("area.months.Aug"),
                        t("area.months.Sept"),
                        t("area.months.Oct"),
                        t("area.months.Nov"),
                        t("area.months.Dec")
                    ],
                    datasets: [
                        {
                            label: t('area.monthly'),
                            data,
                            borderWidth: 0,
                            backgroundColor: '#B0CC55'
                        },
                    ],
                },
                options: {
                    scales: {
                        y: {
                            beginAtZero: true
                        },
                        x: {
                            grid: {
                                display: false
                            },
                        },
                    },
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label: function(context) {
                                    return context.label + ':' + ' ' + context.formattedValue;
                                }
                            }
                        }
                    }
                },
            })
        }

        return () => {
            myChart.destroy()
        }
    }, [data, selectedLanguage])

    return (
        <div className="md:w-[500px] w-full">
            <canvas id="myChart"></canvas>
        </div>
    )
}