import { FieldValues } from "react-hook-form";
import { CreateFlaecheInput } from "../API";
import { AreaProperty } from "../types";
import i18n from "../i18n";
import { TFunction } from "i18next";

interface Position {
    longitude: number;
    latitude: number;
    mapElevation: number;
}

export const getAreaData = (
    formData: FieldValues, 
    sub: string, 
    areaSize: number, 
    selectedArea: AreaProperty | null, 
    position: Position,
    checkProgress: (name: string) => string,
    t: TFunction<"translation", undefined>
) => {
    const selectedLanguage = i18n.language || window.localStorage.i18n;

    const newArea: CreateFlaecheInput = {
        ownerSub: sub,
        areaType: formData.type,
        areaSize: formData.size,
        areaName: formData.areaName,
        areSizeMapbox: Math.round(areaSize),
    
        address_state: formData.state || '',
        address_street: formData.street || '',
        address_postcode: formData.postalCode,
        address_city: formData.city,
        address_country: formData.country,
        address_other: formData.municipality || '',
        address_noStreet: formData.checked === 'addArea.no_street',

        compensationType: '',
        locale: selectedLanguage,

        additional_information: formData.moreInfo,
        additionalInformationLookingFor: formData.forMySpace,
        identifyAsOtherDescription: formData.moreRoleInfo,

        identifyAsOwner:
            formData.role ===
            'Commissioned by the owner of the space, e.g. intermediary, broker, project developer'
            || formData.role === "Im Auftrag des Eigentümers, z.B. Vermittler, Makler, Projektentwickler"
                ? 'true'
                : 'false',
        identifyAsLandlord:
            formData.role ===
            'Landowners or representatives of the community of owners'
            || formData.role === "Grundstückseigentümer oder Vertreter der Eigentümergemeinschaft"
                ? 'true'
                : 'false',
        identifyAsDeveloper:
            formData.role === 'Project developer' || formData.role === "Projektentwickler" ? 'true' : 'false',
        identifyAsInvestor: formData.role === 'Investor' ? 'true' : 'false',
        identifyAsOther:
            formData.role === 'Other (Please explain)' || formData.role === "Andere (Bitte erklären)" ? 'true' : 'false',

        progressZustimmung: checkProgress('addArea.planning_process_items.agreement.title'),
        progressPachtvertragAbgeschlossen: checkProgress('addArea.planning_process_items.contract.title'),
        progressEinspeisezusage: checkProgress('addArea.planning_process_items.grid_connection.title'),
        progressAufstellungsbeschluss: checkProgress('addArea.planning_process_items.approval_request.title'),
        progressBebauungsplan: checkProgress('addArea.planning_process_items.approval_grant.title'),
        progressBauvertrag: checkProgress('addArea.planning_process_items.plan.title'),
        progressAnlageGebaut: checkProgress('addArea.planning_process_items.building.title'),

        criteriaMetMargins: selectedArea && t(selectedArea?.title) === t('addArea.description_items.railways') ? 'true' : 'false',
        criteriaMetHandicapped: selectedArea && t(selectedArea?.title) === t('addArea.description_items.fields') ? 'true' : 'false',
        criteriaMetConversionArea: selectedArea && t(selectedArea?.title) === t('addArea.description_items.conversion_areas') ? 'true' : 'false',
        criteriaMetIndustryArea: selectedArea && t(selectedArea?.title) === t('addArea.description_items.industry') ? 'true' : 'false',
        criteriaMetPlant: selectedArea && t(selectedArea?.title) === t('addArea.description_items.buildings') ? 'true' : 'false',
        criteriaMetOther: selectedArea && t(selectedArea?.title) === t('addArea.description_items.nothing_applicable') ? 'true' : 'false',

        GeoCoordinatesLatitude: position.latitude,
        GeoCoordinatesLongitude: position.longitude,
        GeoCoordinatesElevation: position.mapElevation,

        updatedTimeUnix: Math.floor(new Date().getTime() / 1000),

        disabled: false,
    }

    return newArea;
}