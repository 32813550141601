import { createContext, ReactNode, useState } from 'react';
import { Data } from '../helpers/calculateResult';

interface ThanksPageContextType {
    data: Data | null;
    setData: (data: Data) => void;
}

const initialThanksPageContext = {
    data: null,
    setData: () => {}
};

const ThanksPageContext = createContext<ThanksPageContextType>(initialThanksPageContext);

interface AuthProviderProps {
    children: ReactNode;
}

const ThanksPageProvider = ({ children }: AuthProviderProps) => {
    const [data, setData] = useState<Data | null>(null);

    return (
        <ThanksPageContext.Provider
            value={{ data, setData }}
        >
            {children}
        </ThanksPageContext.Provider>
    );
};

export { ThanksPageContext, ThanksPageProvider };