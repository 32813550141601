import { useState } from "react";
import { Flaeche } from "../../../API";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../hooks";

interface LocationInfoProps {
    area: Flaeche | null | undefined;
    owner: boolean;
    isOpenContacts: boolean;
  }

export const LocationInfo = ({ area, owner, isOpenContacts }: LocationInfoProps) => {
    const [isOpen, setIsOpen] = useState(true);
    const { t } = useTranslation();
    const { isAdmin, isInvestor } = useAuth();

    const handleOpen = () => {
        setIsOpen(prev => !prev);
    }

    return (
        <div>
            <div className="mb-[15px] flex justify-between font-semibold cursor-pointer" onClick={handleOpen}>
                <p>{t('area.location')}</p>
                <img
                    src="/assets/icons/arrowDown.svg"
                    alt="arrow"
                    className={`cursor-pointer ${isOpen ? 'rotate-180' : ""}`}
                />
            </div>

            {isOpen && (
                <>
                    <div className="mb-[30px] flex md:gap-[60px] gap-[20px] flex-wrap">
                        <div>
                            <p className="mb-[5px] text-[16px] text-[#9e9e9e]">
                                {t('placeholders.country')}
                            </p>
                            <p className="text-[18px] font-medium">{area?.address_country && t(area.address_country)}</p>
                        </div>

                        <div>
                            <p className="mb-[5px] text-[16px] text-[#9e9e9e]">
                                {t('addArea.placeholders.federal_state')}
                            </p>
                            <p className="text-[18px] font-medium">{area?.address_state && t(area.address_state)}</p>
                        </div>

                        <div>
                            <p className="mb-[5px] text-[16px] text-[#9e9e9e]">
                                {t('area.post_code')}
                            </p>
                            <p className={`text-[18px] font-medium ${(!owner && !isOpenContacts && !isAdmin) && "text-transparent [text-shadow:_0_0_15px_rgb(0_0_0_)] unselectable"}`}>
                                {area?.address_postcode}
                            </p>
                        </div>

                        <div>
                            <p className="mb-[5px] text-[16px] text-[#9e9e9e]">{t('profile.labels.city')}</p>
                            <p className={`text-[18px] font-medium ${(!owner && !isOpenContacts && !isAdmin) 
                                && "text-transparent [text-shadow:_0_0_15px_rgb(0_0_0_)] unselectable"}`}
                            >
                                {area?.address_city}
                            </p>
                        </div>
                    </div>

                    <div className="flex gap-[100px]">
                        <div>
                            <p className="mb-[5px] text-[16px] text-[#9e9e9e]">
                                {t('area.street')}
                            </p>
                            <p className={`text-[18px] font-medium ${(!owner && !isOpenContacts && !isAdmin) 
                                && "text-transparent [text-shadow:_0_0_15px_rgb(0_0_0_)] unselectable"}`}
                            >
                                {area?.address_street}
                            </p>
                        </div>

                        <div>
                            <p className="mb-[5px] text-[16px] text-[#9e9e9e]">
                                {t('area.municipality')}
                            </p>
                            <p className={`text-[18px] font-medium ${(!owner && !isOpenContacts && !isAdmin) 
                                && "text-transparent [text-shadow:_0_0_15px_rgb(0_0_0_)] unselectable"}`}
                            >
                                {area?.address_other}
                            </p>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}
