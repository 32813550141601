import { ReactNode, useCallback, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { Links } from '../../settings';
import { useLocalStorage } from '../../hooks/useLocaleStorage';
import { PATH } from '../../utils/constants';
import { InvestorModal } from '../InvestorModal';
import { useAuth } from '../../hooks';

interface PrivateRouteProps {
    element: ReactNode;
}

export const PrivateRoute = ({ element }: PrivateRouteProps) => {
    const { loggedIn, registeredUser } = useAuth();
    const { pathname } = useLocation();
    const [path, setPath] = useLocalStorage(PATH, '');
    const [isOpen, setIsOpen] = useState(false);

    const handleClose = useCallback(() => {
        setIsOpen(false);
    }, [])

    useEffect(() => {
        if (!loggedIn) {
            setPath(pathname);
        }
    }, [loggedIn, pathname, setPath]);

    useEffect(() => {
        if (loggedIn 
            && loggedIn.qualifiedInvestor 
            && !loggedIn.nutzungsbedingungenInvestorAccepted
        ) {
            setIsOpen(true);
        }
    }, [loggedIn, pathname])


    if (!element) {
        return null;
    }

    if (!loggedIn) {
        return registeredUser 
            ? <Navigate to={Links.login.index} replace /> 
            : <Navigate to={Links.signup.index} replace />
        ;
    }
    
    return (
        <div>
            {element}

            {isOpen && pathname !== '/terms-investor' && (
                <InvestorModal
                    user={loggedIn}
                    onClose={handleClose}
                />
            )}
        </div>
    )
};
