import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { usePagination } from '../../pages/AdminDashboard/hooks';
import { ReactNode } from 'react';

interface DataTableProps<T> {
    data: T[];
    columns: string[];
    renderBodyCell: (data: T) => ReactNode;
}

export const DataTable = <T,>({ data, columns, renderBodyCell }: DataTableProps<T>) => {
    const { t } = useTranslation();
    const {
        page,
        rowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage
    } = usePagination();

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden', paddingX: '25px' }}>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column, i) => (
                                <TableCell key={column + i}>
                                    {t(column)}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data
                            ?.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            )
                            .map(renderBodyCell)}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={data ? data?.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    )
}
