import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

interface AreaIdModalProps {
    passedId: string;
    onChange: (value: string) => void;
    error: boolean;
    onApprove: () => void;
    onCancel: () => void;
}

export const AreaIdModal = (props: AreaIdModalProps) => {
    const {
        passedId,
        error,
        onApprove,
        onCancel,
        onChange
    } = props;
    const { t } = useTranslation();

    return (
        <Box className="fixed right-0 top-0 z-10 flex h-full w-full items-center justify-center px-[10px]">
            <div className="z-10 rounded-[8px] bg-[#dcedc8] px-[20px] py-[15px] text-center text-secondary">
                <p className="mb-[20px] max-w-[400px] text-[17px]">
                    {t('admin_dashboard.enter')}
                </p>

                <input
                    value={passedId}
                    onChange={(e) => onChange(e.target.value)}
                    className="mb-[10px] w-full px-[10px] outline-0"
                />

                {error && (
                    <p className="mb-[10px] text-red-500">
                        {t('admin_dashboard.error')}
                    </p>
                )}

                <div className="flex justify-center gap-[15px]">
                    <button
                        className="rounded-[8px] bg-secondary px-[10px] py-[5px] text-white"
                        onClick={onApprove}
                    >
                        {t('admin_dashboard.delete')}
                    </button>

                    <button
                        onClick={onCancel}
                        className="rounded-[8px] bg-red-500 px-[10px] py-[5px] text-white"
                    >
                        {t('admin_dashboard.cancel')}
                    </button>
                </div>
            </div>
        </Box>
    )
}
