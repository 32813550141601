/** @format */

// Types
// @TODO: Needs to create global.d.ts file for types
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Links } from '../../../settings'
import { LandsSlider } from './LandsSlider'
import { Flaeche } from '../../../API'
import { Carousel, Heading } from '../../../components'
import { useAuth } from '../../../hooks'
import { roofsForLeaseMock } from '../../../utils/sliderForLease'

interface RoofsForLeaseProps {
    images: string[]
    roofs: Flaeche[]
    fetchImages: (id?: string | undefined) => Promise<void>
}

export const RoofsForLease = (props: RoofsForLeaseProps) => {
    const { images, roofs, fetchImages } = props
    const { t } = useTranslation()

    const navigate = useNavigate()
    const { loggedIn } = useAuth()


    const handleNavigate = () => {
        navigate(Links.search.index)
    }

    return (
        <div className="mx-[25px] mt-[60px] md:mt-[80px]">
            <div className="mb-[40px] flex w-full justify-center md:justify-between">
                <Heading
                    title={t('home.roofs_offered')}
                    size="text-[32px] text-center md:text-left md:text-[38px] md:max-w-[60%] lg:max-w-[100%]"
                    weight="font-medium"
                />

                <button
                    className={`hidden h-[45px]
                        rounded-[10px] px-[15px] py-[10px] text-[#11470E] md:block md:px-[44px]`}
                    onClick={handleNavigate}
                >
                    {t('search.show_more')}
                </button>
            </div>

            <div className="mb-[40px] flex flex-col items-center">
                {roofs && (
                    <Carousel
                        images={images}
                        lands={roofs}
                        fetchImages={fetchImages}
                    />
                )}

                <LandsSlider
                    lands={loggedIn ? roofs : roofsForLeaseMock}
                    images={images}
                    fetchImages={fetchImages}
                />

                <button
                    className={`block h-[45px]
                        rounded-[10px] px-[15px] py-[10px] text-[20px] text-[#11470E] md:hidden md:px-[44px]`}
                    onClick={handleNavigate}
                >
                    {t('search.show_more')}
                </button>
            </div>
        </div>
    )
}
