import { useState } from "react";
import { useTranslation } from "react-i18next";
import { checkType, convertToHectares } from "../../../helpers/calculateResult";
import { useLanguage } from "../../../hooks";

interface TypeProps {
    type: string;
    size: number;
}

export const TypeInfo = (props: TypeProps) => {
    const { type, size } = props;
    const [isOpen, setIsOpen] = useState(true);
    const { t, i18n } = useTranslation();
    const { selectedLanguage } = useLanguage({ i18n });

    const handleOpen = () => {
        setIsOpen(prev => !prev);
    }

    return (
        <div>
            <div className="mb-[15px] flex justify-between font-semibold cursor-pointer" onClick={handleOpen}>
                <p>{t('area.type')}</p>
                <img
                    src="/assets/icons/arrowDown.svg"
                    alt="arrow"
                    className={`cursor-pointer ${isOpen ? 'rotate-180' : ""}`}
                />
            </div>

            {isOpen && (
                <div className="flex gap-[60px]">
                    <div>
                        <p className="mb-[5px] text-[16px] text-[#9e9e9e]">
                            {t('search.type')}
                        </p>
                        <p className="text-[18px] font-medium">
                            {type.slice(0, 1).toUpperCase() + type.slice(1)}
                        </p>
                    </div>

                    <div>
                        <p className="mb-[5px] text-[16px] text-[#9e9e9e]">
                            {t('area.size')} {" "}
                            {checkType(type) ? 'ha' : 'm²'}
                        </p>
                        <p className="text-[18px] font-medium">
                            {checkType(type) 
                                ? convertToHectares(size) + " " + t('search.ha') 
                                : size.toLocaleString(selectedLanguage === 'de' ? 'de-DE' : 'en-US') + " " + 'm²'
                            }
                        </p>
                    </div>
                </div>
            )}
        </div>
    )
}
