import { useTranslation } from "react-i18next";
import { Button } from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { Links } from "../../../settings";

interface ButtonsProps {
    ownArea: boolean;
    areaId: string | undefined;
    onDeactivate?: (id: string | undefined) => void;
}

export const Buttons = ({ ownArea, areaId, onDeactivate }: ButtonsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleEdit = useCallback(() => {
    navigate(`${Links.offer.index}/${areaId}`)
  }, [navigate, areaId])

  const handleDetails = useCallback(() => {
    navigate(`${Links.search.index}/${areaId}`)
  }, [navigate, areaId])

  const handleDeactivate = useCallback(() => {
    if (onDeactivate && areaId) {
        onDeactivate(areaId);
    }
  }, [areaId, onDeactivate])

  return (
    <div className="absolute left-1/2 w-[90%] 
        -translate-x-1/2 transform transition-transform 
        duration-500 ease-in-out group-hover:-translate-y-20 group-hover:z-10 z-0"
    >
        {!ownArea ? (
            <Button 
                color="Secondary" 
                width="w-full"
                onClick={handleDetails}
            >
                {t('home.see_details')}
            </Button>
        ) : (
            <div className="flex gap-[15px]">
                <Button 
                    width="w-full"
                    sx="border-[1px] border-[#111A2126]"
                    onClick={handleEdit}
                >
                    {t('profile.edit')}
                </Button>

                <Button 
                    color="Secondary" 
                    width="w-full" 
                    textColor="text-white"
                    onClick={handleDeactivate}
                >
                    {t('profile.deactivate')}
                </Button>
            </div>
        )}
    </div>
  )
}
