import { useNavigate } from "react-router-dom";
import { CallToAction } from "../../../components";
import { useCallback } from "react";
import { Links } from "../../../settings";
import { ArticleImage } from "./Image";
import { useTranslation } from "react-i18next";

export const NineArticle = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleNavigate = useCallback(() => {
        navigate(Links.offer.index);
    }, [navigate])

    return (
        <div className="mb-[20px] flex flex-col gap-[25px] text-[16px] text-[#333] md:text-[18px]">
            <span className="uppercase text-primary">{t('articles.info')}</span>

            <h1 className="text-[25px] font-medium md:text-[35px]">
                {t('article_nine.title')}
            </h1>

            <div className="h-[2px] w-[150px] bg-primary" />

            <span className="text-[13px] uppercase text-gray-700 md:text-[16px]">
                {t('articles.article_nine.date')}
            </span>

            <p>
                {t('article_nine.p_1')}
                {" "}
                <a 
                    href="https://marktoffensive-ee.de/newsroom/publikationsdetailansicht/pub/mustervertrag-standardvertrag-ppa-lieferband/" 
                    className="text-primary"
                >
                    {t('article_nine.link_1')}
                </a> {" "}
                 {t('article_nine.p_2')}
                {" "}
                <a 
                    href="https://marktoffensive-ee.de/newsroom/publikationsdetailansicht/pub/guidance-notes-ppa-lieferband"
                    className="text-primary"
                >
                    {t('article_nine.link_2')}
                </a> {" "}
                {t('article_nine.p_3')}
            </p>

            <ArticleImage
                source="/assets/images/nineArticle.jpg"
                link="https://unsplash.com/de/fotos/weisse-und-schwarze-sonnenkollektoren-unter-weissen-wolken-und-blauem-himmel-tagsuber-itv-MC5S6cU"
            />

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_nine.subtitle_1')}
            </h2>

            <p>{t('article_nine.p_4')}</p>
            <p>{t('article_nine.p_5')}</p>
            <p>{t('article_nine.p_6')}</p>

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_nine.subtitle_2')}
            </h2>

            <p>{t('article_nine.p_7')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_nine.p_8')}
            </h3>
            <p>{t('article_nine.p_9')}</p>


            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_nine.p_10')}
            </h3>
            <p>{t('article_nine.p_11')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_nine.p_12')}
            </h3>
            <p>{t('article_nine.p_13')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_nine.p_14')}
            </h3>
            <p>{t('article_nine.p_15')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_nine.subtitle_3')}
            </h3>

            <p>{t('article_nine.p_16')}</p>
            <p>{t('article_nine.p_17')}</p>
            <p>{t('article_nine.p_18')}</p>
            <p>{t('article_nine.p_19')}</p>
            <p>{t('article_nine.p_20')}</p>

            <div className="flex gap-[5px] items-end">
                <img src="/assets/icons/files.svg" className="h-[30px]"/>
                <a 
                    href="https://marktoffensive-ee.de/newsroom/publikationsdetailansicht/pub/mustervertrag-standardvertrag-ppa-lieferband/" 
                    className="text-[#8EAD44]"
                >
                    {t('article_nine.link_1')}
                </a>
            </div>

            <div className="flex gap-[5px] items-end">
                <img src="/assets/icons/files.svg" className="h-[30px]"/>
                <a 
                    href="https://marktoffensive-ee.de/newsroom/publikationsdetailansicht/pub/mustervertrag-standardvertrag-ppa-lieferband/" 
                    className="text-[#8EAD44]"
                >
                    {t('article_nine.link_3')}
                </a>
            </div>

            <div className="mx-[25px] text-[16px] md:mt-[50px]">
                <CallToAction
                    title={t('home.call_to_action_environment')}
                    btnTitle={t('buttons.offer_your_space_now')}
                    bgColor="bg-secondary text-white"
                    onClick={handleNavigate}
                    tag="p"
                />
            </div>
        </div>
    )
}
