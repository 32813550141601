import { useLocation, useNavigate } from "react-router-dom";
import { useAuth, useLanguage, useLocalStorage, useProcessTry } from "../../../hooks";
import { useEffect, useState } from "react";
import { TUserLoginSchema } from "../../../helpers";
import { GraphQLQuery } from '@aws-amplify/api';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import {
    CreateOrganizationMutation,
    CreateUserInfoMutation,
    GetUserInfoByEmailAddressQuery,
    UpdateUserInfoMutation,
    UserInfo,
} from '../../../API';
import { getUserInfoByEmailAddress } from "../../../graphql/queries";
import { Links } from "../../../settings";
import { createOrganization, createUserInfo, updateUserInfo } from "../../../graphql/mutations";
import i18n from "../../../i18n";
import { PATH } from "../../../utils/constants";

enum STEPS {
    REGISTER,
    INFO,
}

export const useLogin = () => {
    const { loginUser, setToken } = useAuth();
    const { handleCreateError } = useProcessTry();
    const navigate = useNavigate();
    const [path, setPath] = useLocalStorage(PATH, '');
    const { selectedLanguage } = useLanguage({ i18n });

    const [successSignUp, setSuccessSignUp] = useState(false);
    const [step, setStep] = useState<STEPS>(STEPS.REGISTER);
    const [userData, setUserData] = useState<
        TUserLoginSchema
    >();
    const [resendData, setResendData] = useState({ email: '', password: ''});
    const [resendCode, setResendCode] = useState(false);
    const [error, setError] = useState<any>('');
    const [loginError, setLoginError] = useState<any>('');
    const [inProcess, setInProcess] = useState(false);

    const location = useLocation();
    const isLoginPage = location.pathname === '/login';
    const title =
        step === STEPS.REGISTER ? 'login.username_password' : 'sign_up.personal_info';

    const getUser = async (email: string) => {
        try {
            const { data } = await API.graphql<GraphQLQuery<GetUserInfoByEmailAddressQuery>>(
                graphqlOperation(getUserInfoByEmailAddress, {
                email: email
            }));
    
            const userData = data?.getUserInfoByEmailAddress?.items[0];

            const updateUser = {
                id: userData?.id,
                sub: userData?.sub,
                lastloginDateTime: new Date()
            }

            await API.graphql<GraphQLQuery<UpdateUserInfoMutation>>(
                graphqlOperation(updateUserInfo, {
                input: updateUser 
            }));
            
            if (userData) {
                loginUser(userData);
                const token = await getToken(userData);
                setToken(token);
            }
    
            if (path) {
                navigate(path)
                setPath('')
            } else {
                navigate(Links.index)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getToken = async (userData: UserInfo) => {
        if (!userData?.email) {
            setToken('');
            return;
        }

        try {
            const fullname = `${userData.firstName} ${userData.lastName}`;
            const decodedName = encodeURI(fullname);
            const email = encodeURI(userData.email);

            const response = await fetch(
                `https://edc1touev2.execute-api.eu-central-1.amazonaws.com/?method=TwilioToken&email=${email}&identity=${userData.id}&friendlyName=${decodedName}&locale=${selectedLanguage}`
            );
            const data = await response.json();

            return data.token;
        } catch (error) {
            console.log("ERROR", error);
        }
    }

    const login = async (formData: TUserLoginSchema) => {
        if (step === STEPS.REGISTER && !isLoginPage) {
            setUserData(formData)
            setStep(STEPS.INFO)
            return
        }

        window.dataLayer.push({
            event: 'login',
        });

        try {
            setInProcess(true);

            const user = await Auth.signIn({
                username: formData.email,
                password: formData.password,
            })

            await getUser(user.attributes.email);
            setInProcess(false);
            window.location.reload();
        } catch (error: any) {
            if (error.code === 'UserNotConfirmedException') {
                await Auth.resendSignUp(formData.email);
                setResendData(formData);
                setResendCode(true);
            }
            setInProcess(false);
            setLoginError(error);
        }
    }

    const signUp = async (data: any) => {
        const sanitizedNumber = '+' + data.phone.replace(/[^0-9+]/g, '');

        window.dataLayer.push({
            event: 'signing_up',
        });

        try {
            setInProcess(true);
            const user = await Auth.signUp({
                username: userData?.email || '',
                password: userData?.password || '',
                attributes: {
                    email: userData?.email,
                    given_name: data.firstName,
                    family_name: data.lastName,
                    phone_number: sanitizedNumber || '',
                    address: `${data.postalAddress}, ${data.postal} ${data.city}`,
                    locale: selectedLanguage,
                },
                autoSignIn: {
                    enabled: true,
                },
            })

            setSuccessSignUp(true);
            window.scrollTo({ top: 0 });

            const userInfo = {
                email: userData?.email,
                sub: user.userSub,
                firstName: data.firstName,
                lastName: data.lastName,
                phone: sanitizedNumber || '',
                streetAndHouseNumber: data.postalAddress,
                postalCode: data.postal,
                city: data.city,
                country: data.country,
                qualifiedInvestor: false,
                locale: selectedLanguage,
                interestedIn: data.interest || '',
                personalStatement: data.profile || '',
                nutzungsbedingungenAnwenderAccepted: data.accept,
                nutzungsbedingungenAnwenderTimestamp: new Date().toISOString()
            };

            await API.graphql<GraphQLQuery<CreateUserInfoMutation>>(
                graphqlOperation(createUserInfo, { input: userInfo })
            );

            if (data.companyName) {
                const organization = {
                    OwnerOfOrganization: user.userSub,
                    CompanyName: data.companyName,
                    websiteDomain: data.website || '',
                    addressStreetAddress: data.companyAddress || '',
                    addressPostalCode: data.companyPostal || '',
                    addressCity: data.companyCity || '',
                    addressCountry: data.companyCountry || '',
                    vatID: data.VATID || '',
                    disabledOrganization: false
                }

                await API.graphql<GraphQLQuery<CreateOrganizationMutation>>(
                    graphqlOperation(createOrganization, { input: organization })
                );
            }

            setInProcess(false);

            if (typeof window !== 'undefined' && window.fbq != null) {
                window.fbq('track', 'CompleteRegistration');
                window.fbq('track', 'Lead');
            }
        } catch (error: any) {
            console.log('error signing in', error);
            setError(error);
            setInProcess(false);

            const errorData = {
                userInfoSub: userData?.email,
                requestBody: JSON.stringify(data),
                errorMsg: error.message as string,
                process: 'Sign up',
                DateTimeUnix: Math.floor(new Date().getTime() / 1000)
            }

            handleCreateError(errorData);
        }
    }

    const socialSingIn = async (provider: CognitoHostedUIIdentityProvider) => {
        try {
            await Auth.federatedSignIn({ provider, customState: 'shopping-cart' });
        } catch (error) {
            console.error(`Error signing in with ${provider}:`, error);
        }
    }

    useEffect(() => {
        setStep(STEPS.REGISTER)
    }, [location.pathname])

    return {
        successSignUp,
        resendCode,
        resendData,
        error,
        loginError,
        socialSingIn,
        isLoginPage,
        userData,
        step,
        signUp,
        login,
        title,
        getToken,
        inProcess
    }
}
