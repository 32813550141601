import { FormHeader } from './FormHeader';
import { TOfferAreaSchema } from '../../../helpers';
import { options } from '../../../utils/formLists';
import { useTranslation } from 'react-i18next';
import { Flaeche } from '../../../API';
import { LabelUpInput } from '../../../components';
import { useLanguage } from '../../../hooks';
import { FieldErrors, SetFieldValue, UseFormRegister } from 'react-hook-form';

interface AreaForProps {
    area?: Flaeche | undefined | null;
    data?: any;
    register: UseFormRegister<TOfferAreaSchema>;
    errors: FieldErrors<TOfferAreaSchema>;
    setValue: SetFieldValue<TOfferAreaSchema>;
}
export const AreaFor = (props: AreaForProps) => {
    const { t, i18n } = useTranslation();
    const { selectedLanguage } = useLanguage({ i18n });

    const { 
        area,
        data,
        register, 
        errors, 
        setValue, 
    } = props;

    return (
        <div className="mb-[30px] flex w-full flex-col items-end gap-[30px] px-[25px] md:px-[80px] xl:px-[180px]">
            <div className="w-full">
                <FormHeader title={t('addArea.other_info')} />

                <LabelUpInput
                    name='forMySpace'
                    value={area?.additionalInformationLookingFor ?? data?.forMySpace}
                    type="dropdown"
                    options={options}
                    placeholder={t('addArea.placeholders.for_my_space')}
                    error={errors['forMySpace' as keyof TOfferAreaSchema]}
                    register={register}
                    star={true}
                    setValue={setValue}
                    starStyle={
                        selectedLanguage === 'en' 
                            ? `left-[235px]`
                            : 'left-[220px]'
                    }
                />
            </div>

            <div className="w-full">
                <LabelUpInput
                    value={area?.additional_information ?? data?.moreInfo}
                    name='moreInfo'
                    type="textarea"
                    placeholder={t('addArea.placeholders.add_more_info')}
                    error={errors['moreInfo' as keyof TOfferAreaSchema]}
                    register={register}
                    setValue={setValue}
                />
            </div>
        </div>
    )
}
