import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';


import Amplify from '@aws-amplify/core';
import { Auth } from 'aws-amplify';
import awsExports from './aws-exports';

import './index.css';
import { Authenticator } from '@aws-amplify/ui-react';
import { ModalContextProvider } from './contexts/ModalContext';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';
import { ThanksPageProvider } from './contexts/ThanksPageContext';
import { MatomoProvider, createInstance } from '@m4tt72/matomo-tracker-react'
import { MessagesProvider } from './contexts/MessagesContext';

// @ts-ignore
//window.LOG_LEVEL="DEBUG"

Amplify.configure(awsExports);
Auth.configure(awsExports);

const instance = createInstance({
    urlBase: 'https://enlapa.matomo.cloud/',
    siteId: 1,
    disabled: false, 
    heartBeat: {
      active: true,
      seconds: 10
    },
    linkTracking: false,
    configurations: {
      disableCookies: true,
      setSecureCookie: true,
      setRequestMethod: 'POST'
    }
  })

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <BrowserRouter>
    
        <Authenticator.Provider>
            <AuthProvider>
                <MessagesProvider>
                    <ThanksPageProvider>
                        <ModalContextProvider>
                            <React.StrictMode>
                                <I18nextProvider i18n={i18n}>
                                    <MatomoProvider value={instance}>
                                        <App />
                                    </MatomoProvider>
                                </I18nextProvider>
                            </React.StrictMode>
                        </ModalContextProvider>
                    </ThanksPageProvider>
                </MessagesProvider>
            </AuthProvider>
        </Authenticator.Provider>
    </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
