import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { qAs, requirements, symbiosis, use } from "../../utils/formLists";
import { GradientBlock } from "../../components";
import { usePageDescription } from "../../hooks";
import { Helmet } from "react-helmet";
import { helmetJsonLdProp } from "react-schemaorg";
import { FAQPage } from "schema-dts";

export const OwnersFAQ = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<number[]>([]);

  usePageDescription({ 
    title: t('title.questions'),
    description: t('description.questions')
  });

  const handleOpen = (id: number) => {
    if (!isOpen.includes(id)) {
        setIsOpen(prev => [...prev, id]);
        return;
    }

    const filteredQuestions = isOpen.filter(question => question !== id);
    setIsOpen(filteredQuestions);
  }

  const handleTrigger = (question: string) => {
    window.dataLayer!.push({
        event: 'question',
        question
    })

    window.dataLayer!.push({
        'question': t(question)
    })
  }

  return (
    <div className="flex flex-col">
        <Helmet
            script={[
                helmetJsonLdProp<FAQPage>({
                    "@context": "https://schema.org",
                    "@type": "FAQPage",
                    "mainEntity": [{
                      "@type": "Question",
                      "name": t("fAq_owners.lease.first_q"),
                      "acceptedAnswer": {
                        "@type": "Answer",
                        "text": t("fAq_owners.lease.first_a")
                      }
                    },{
                      "@type": "Question",
                      "name": t("fAq_owners.lease.second_q"),
                      "acceptedAnswer": {
                        "@type": "Answer",
                        "text": t("fAq_owners.lease.second_a")
                      }
                    },{
                      "@type": "Question",
                      "name": t("fAq_owners.lease.third_q"),
                      "acceptedAnswer": {
                        "@type": "Answer",
                        "text": t("fAq_owners.lease.third_a")
                      }
                    }]
                }),
            ]}
        />
         <Helmet>
            <meta name='title' content={t('title.oquestions')} />
            <meta name='description' content={t('description.questions')} />
        </Helmet>
        <div className="md:px-[100px] px-[25px] bg-[#F6F7F3] flex flex-col items-center md:py-[100px] py-[30px] md:mb-[100px] mb-[25px] relative">
            <h1 className="md:text-[45px] text-[25px] font-bold mb-[10px]"> {t('fAq_owners.frequently')}</h1>

            <p className="text-primary md:text-[35px] text-[20px]"> {t('fAq_owners.for')}</p>

            <img 
                src="/assets/icons/Groupdesign.svg" 
                alt="design" 
                className="absolute bottom-0 right-0 h-[250px]"
            />
        </div>

        <div className="w-full mb-[50px] md:px-[100px] px-[25px]">
            <p className="md:text-[27px] text-[20px] font-bold mb-[30px] text-center uppercase">
                {t('fAq_owners.lease.title')}
            </p>

            <div className="border-[2px] border-[#E8E8E8] rounded-[12px]">
                {qAs.map(el => (
                    <Fragment key={el.id}>
                        <div 
                            className="flex justify-between gap-[10px] cursor-pointer items-start px-[15px] py-[30px]" 
                            onClick={() => {
                                handleOpen(el.id)
                                handleTrigger(el.question)
                            }}
                        >
                            <p className="font-medium md:text-[18px] text-[16px]">{t(el.question)}</p>
                            <img
                                src={`${isOpen.includes(el.id) ? "/assets/icons/minus.svg" : "/assets/icons/plus.svg"}`}
                                alt="arrow"
                                className="cursor-pointer"
                            />
                        </div>

                        {isOpen.includes(el.id) && (
                            <p className="text-[15px] mb-[20px] px-[15px]">{t(el.answer)}</p>
                        )}

                        {el.id !== qAs[qAs.length - 1].id && (
                            <hr className="h-[2px] w-full bg-gray-50" />
                        )}
                    </Fragment>
                ))}
            </div>
        </div>

        <div className="w-full mb-[50px] md:px-[100px] px-[25px]">
            <p className="md:text-[27px] text-[20px] font-bold mb-[30px] text-center uppercase">
                {t('fAq_owners.process.title')}
            </p>

            <div className="border-[2px] border-[#E8E8E8] rounded-[12px]">
                {requirements.map(el => (
                    <Fragment key={el.id}>
                        <div 
                            className="flex justify-between gap-[10px] cursor-pointer items-start px-[15px] py-[30px]" 
                            onClick={() => {
                                handleOpen(el.id)
                                handleTrigger(el.question)
                            }}
                        >
                            <p className="font-medium md:md:text-[18px] text-[16px]">{t(el.question)}</p>
                            <img
                                src={`${isOpen.includes(el.id) ? "/assets/icons/minus.svg" : "/assets/icons/plus.svg"}`}
                                alt="arrow"
                                className="cursor-pointer"
                            />
                        </div>

                        {isOpen.includes(el.id) && (
                            <p className="text-[15px] mb-[20px] px-[15px]">{t(el.answer)}</p>
                        )}

                        {el.id !== requirements[requirements.length - 1].id && (
                            <hr className="h-[2px] w-full bg-gray-50" />
                        )}
                    </Fragment>
                ))}
            </div>
        </div>

        <div className="w-full mb-[50px] md:px-[100px] px-[25px]">
            <p className="md:text-[27px] text-[20px] font-bold mb-[30px] text-center uppercase">
                {t('fAq_owners.symbiosis.title')}
            </p>

            <div className="border-[2px] border-[#E8E8E8] rounded-[12px]">
                {symbiosis.map(el => (
                    <Fragment key={el.id}>
                        <div 
                            className="flex justify-between gap-[10px] cursor-pointer items-start px-[15px] py-[30px]" 
                            onClick={() => {
                                handleOpen(el.id)
                                handleTrigger(el.question)
                            }}
                        >
                            <p className="font-medium md:md:text-[18px] text-[16px]">{t(el.question)}</p>
                            <img
                                src={`${isOpen.includes(el.id) ? "/assets/icons/minus.svg" : "/assets/icons/plus.svg"}`}
                                alt="arrow"
                                className="cursor-pointer"
                            />
                        </div>

                        {isOpen.includes(el.id) && (
                            <p className="text-[15px] mb-[20px] px-[15px]">{t(el.answer)}</p>
                        )}

                        {el.id !== symbiosis[symbiosis.length - 1].id && (
                            <hr className="h-[2px] w-full bg-gray-50" />
                        )}
                    </Fragment>
                ))}
            </div>
        </div>

        <div className="w-full mb-[50px] md:px-[100px] px-[25px]">
            <p className="md:text-[27px] text-[20px] font-bold mb-[30px] text-center uppercase">
                {t('fAq_owners.use.title')}
            </p>

            <div className="border-[2px] border-[#E8E8E8] rounded-[12px]">
                {use.map(el => (
                    <Fragment key={el.id}>
                        <div 
                            className="flex justify-between gap-[10px] cursor-pointer items-start px-[15px] py-[30px]" 
                            onClick={() => {
                                handleOpen(el.id)
                                handleTrigger(el.question)
                            }}
                        >
                            <p className="font-medium md:md:text-[18px] text-[16px]">{t(el.question)}</p>
                            <img
                                src={`${isOpen.includes(el.id) ? "/assets/icons/minus.svg" : "/assets/icons/plus.svg"}`}
                                alt="arrow"
                                className="cursor-pointer"
                            />
                        </div>

                        {isOpen.includes(el.id) && (
                            <p className="text-[15px] mb-[20px] px-[15px]">{t(el.answer)}</p>
                        )}

                        {el.id !== use[use.length - 1].id && (
                            <hr className="h-[2px] w-full bg-gray-50" />
                        )}
                    </Fragment>
                ))}
            </div>
        </div>

        <GradientBlock
            title={t('home.call_to_action_environment')}
            buttonText={t('buttons.offer_your_space_now')}
            image="/assets/images/squirel.png"
        />
    </div>
  )
}
