export const fetchEnergyData = async (latitude: number, longitude: number) => {
    try {
        if (latitude && longitude) {
            const response = await fetch(
                `https://edc1touev2.execute-api.eu-central-1.amazonaws.com?method=PVcalc&lat=${latitude}&lon=${longitude}&peakpower=1&loss=14`
            );
    
            const energyData = await response.json();
        
            return energyData;
        }
    } catch (error) {
        console.log("Error fetching energy data:", error);
    }
}