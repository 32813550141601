import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

interface LoadMoreButtonProps {
    onShowMore: MouseEventHandler<HTMLButtonElement>;
}

export const LoadMoreButton = ({ onShowMore }: LoadMoreButtonProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex w-full justify-center mb-[60px]">
        <button 
            className="rounded-[10px] bg-[#11470E] px-[79px] py-[14px] text-white md:px-[44px] md:py-[17px]"
            onClick={onShowMore}
        >
            {t('search.load_more')}
        </button>
    </div>
  )
}
