import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ProgressDescProps {
    title: string;
    progressImage: string;
    desc: string;
    status: string;
}

export const ProgressDesc = (props: ProgressDescProps) => {
  const { title, progressImage, desc, status } = props;
  const { t } = useTranslation();

  const [isOpenExplanation, setIsOpenExplanation] = useState(false);

  const handleOpen = () => {
    setIsOpenExplanation(true);
  }

  const handleClose = () => {
    setIsOpenExplanation(false);
  }

  return (
    <div className="relative flex items-center justify-between px-[15px] py-[12px] z-10">
        <div className="flex flex-col gap-[5px]">
            <p className="text-[15px] font-medium">{t(title)}</p>

            <p 
                className="text-[14px] text-[#bdbdbd] underline cursor-pointer"
                onClick={handleOpen}
            >
                {t('search.learn_more')}
            </p>
        </div>

        <img src={progressImage} alt="option" className='max-h-[38px]'/>

        {isOpenExplanation && (
            <div className="absolute rounded-[12px] p-[15px] bg-white border-[1px] border-[#111A2126] bottom-[-60px] max-w-[250px]">
                <div className="w-full flex justify-end">
                    <img 
                        src={'/assets/icons/clear.svg'} 
                        className="h-[15px] cursor-pointer"
                        alt="close"
                        onClick={handleClose}
                    />
                </div>

                <p className='mb-[10px] text-[14px]'>{t(desc)}</p>
                <p className={`${status === 'finished' ? 'text-secondary' : 'text-primary'}`}>
                    {t(status)}
                </p>
            </div>
        )}
    </div>
  )
}
