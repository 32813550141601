export const PATH = 'path';
export const LOGGED_IN = 'loggedIn';
export const CACHED = 'cachedIndexHtml';
export const REGISTERED_BEFORE = 'registeredBefore';
export const IMAGE = 'profileImage';
export const CHAT_TOKEN = 'chatTOKEN';
export const CHAT_CLIENT = 'client';

export const sizeOfCoalPlant = 120000;
export const days = 395;
export const hours = 24;

export const AREA_SIZE = 1500000;
export const INVESTORS_NUMBER = 3;