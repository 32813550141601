/** @format */

import { useCallback, useState, useRef, useEffect } from 'react';
import { VirtualItem, useVirtualizer } from '@tanstack/react-virtual';
import { FieldValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';

import {  TUserInfoSchema } from '../../helpers/validation';
import { Icons } from '../Icons';
import { useTranslation } from 'react-i18next';

/** Types */
interface IProps {
    options: Record<string, string>[] | undefined;
    sx?: string | undefined;
    star?: boolean;
    starStyle?: string;
    placeholder: string;
    defaultValue?: string;
    name?: string;
    register?: UseFormRegister<any>;
    height?: string;
    setValue?: UseFormSetValue<FieldValues>;
    trigger?: (name: keyof TUserInfoSchema) => void;
    filterStyle?: string;
    setFilter?: (val: string) => void;
}

/**
 * Customized dropdown to display HTML Select
 * @param props - The properties for dropdown
 * @returns The rendered Custom Dropdown
 */
export const Dropdown = ({
    options,
    sx,
    star,
    starStyle,
    height,
    placeholder,
    register,
    defaultValue,
    name,
    setValue,
    trigger,
    filterStyle,
    setFilter
}: IProps): JSX.Element => {
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const parentRef = useRef<HTMLDivElement | null>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<string>(defaultValue || '');
    const { t } = useTranslation();

    const selectVirtualizer = useVirtualizer({
        count: options?.length || 0,
        getScrollElement: () => parentRef.current,
        estimateSize: useCallback(() => 35, []),
        overscan: 15,
    });

    useEffect(() => {
        if (register && name) {
            register(name)
        }
    }, [name, register])

    useEffect(() => {
        if ( setValue ) {
            setValue(name as string, selectedOption)
        }
    }, [selectedOption, setValue, name])

    const toggler = useCallback((): void => {
        setIsOpen((prev) => !prev)
    }, []);

    const selectValueFn = (index: number): void => {
        const label = options![index].label
        
        setIsOpen(false)
        setSelectedOption(label)
        setValue?.(name as string, label, { shouldValidate: true })
        trigger?.(name as keyof TUserInfoSchema)
        setFilter?.(t(label).toLowerCase())
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div style={{ all: 'unset' }}>
            <div className="relative inline-block w-full cursor-pointer text-left" ref={dropdownRef}>
                <div>
                    <button
                        type="button"
                        className={`${selectedOption ? 'text-black' : 'text-[#9e9e9e]'} 
                        flex w-full justify-between bg-[#F3F3F3] px-6 text-left text-base font-normal 
                        ${filterStyle 
                            ? filterStyle 
                            : "rounded-xl py-[21px]"
                        } ${sx}`}
                        onClick={toggler}
                    >
                        {selectedOption ? t(selectedOption) : placeholder}
                        <Icons name="down" />
                    </button>

                    {star && !selectedOption && (
                        <div className={`text-[#FF0F00] text-[24px] absolute top-[20px] ${starStyle} req_placeholder`}>
                            <span className="req_placeholder">*</span>
                        </div>
                    )}
                </div>

                <div
                    className={`${
                        isOpen ? 'inline' : 'hidden'
                    } absolute right-0 z-[999] ml-5 mt-2 ${height ? height : 'h-[9rem]'} w-full origin-top-right overflow-auto  rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5  focus:outline-none`}
                    ref={parentRef}
                >
                    <div
                        className={`relative py-1 text-left h-[${selectVirtualizer.getTotalSize()}px] `}
                    >
                        {selectVirtualizer
                            .getVirtualItems()
                            .map((item: VirtualItem) => (
                                <button
                                    type="button"
                                    className={`${
                                        selectedOption ===
                                        options![item.index].label
                                            ? 'bg-grey-50'
                                            : ''
                                    } absolute left-0 top-0 block w-full cursor-pointer  px-[20px] py-2 text-left text-sm text-gray-700 hover:bg-gray-50`}
                                    style={{
                                        height: `${item.size}px`,
                                        transform: `translateY(${item.start}px)`,
                                    }}
                                    onClick={selectValueFn.bind(
                                        null,
                                        item.index
                                    )}
                                    key={item.index}
                                >
                                    {t(options![item.index].label)}
                                </button>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
