import { category } from '../../../utils/formLists';
import { TOfferAreaSchema } from '../../../helpers';
import { Checkbox } from '../../../components/Checkbox';
import { useTranslation } from 'react-i18next';
import { LabelUpInput } from '../../../components/LabelUpInput';
import { Flaeche } from '../../../API';
import { getRole } from '../../../helpers/getRole';
import { FieldErrors, SetFieldValue, UseFormRegister } from 'react-hook-form';

interface RoleProps {
    area?: Flaeche | undefined | null;
    register: UseFormRegister<TOfferAreaSchema>;
    errors: FieldErrors<TOfferAreaSchema>;
    role: string;
    setValue: SetFieldValue<TOfferAreaSchema>;
}

export const Role = (props: RoleProps) => {
    const { t } = useTranslation();
    
    const {
        area,
        register, 
        errors, 
        role,
        setValue
    } = props;

    return (
        <div className="mb-[30px] flex w-full flex-col justify-start px-[25px] md:px-[80px] xl:px-[180px]">
            <p className="mb-[20px] flex items-center gap-[9px] md:text-[20px] text-[16px]">
                {t('addArea.indicate_role')}
                <span className="text-[24px] text-[#FF0F00]">
                    *
                </span>
            </p>

            <div className="flex flex-col items-start justify-between font-normal text-[#9e9e9e] lg:ml-[-10px]">
                {category.map((label) => (
                    <Checkbox
                        value={getRole(area)}
                        name="role"
                        label={t(label)}
                        key={label}
                        register={register}
                        role={role}
                        setValue={setValue}
                    />
                ))}
            </div>

            {errors['role' as keyof TOfferAreaSchema] && (
                <p className='text-[#e53935] mt-[20px]'>
                    {t('addArea.choose_a_role')}
                </p>
            )}

            {role === t('addArea.role_items.other') && (
                <div className="w-full mt-[30px]">
                    <LabelUpInput
                        value={area?.identifyAsOtherDescription}
                        name='moreRoleInfo'
                        type="textarea"
                        star={true}
                        starStyle='left-[520px] sm:block hidden'
                        placeholder={t('addArea.placeholders.describe_role')}
                        error={errors['moreRoleInfo' as keyof TOfferAreaSchema]}
                        register={register}
                        setValue={setValue}
                    />
                </div>
            )}
        </div>
    )
}
