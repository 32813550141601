import { API, graphqlOperation } from "aws-amplify";
import { useAuth } from "../../../hooks";
import { GraphQLQuery } from "@aws-amplify/api";
import {
    CreateAccountingLogMutation,
    CreateFlaecheInterestedMutation,
    Flaeche,
    GetOrganizationQuery,
    GetOrganizationbyOwnerQuery,
    GetUserForCompanyByCompanyIDQuery,
    Organization,
    UpdateFlaecheMutation,
    UpdateOrganizationMutation,
    UserInfo,
} from '../../../API';
import { 
  getOrganization, 
  getOrganizationbyOwner, 
  getUserForCompanyByCompanyID 
} from "../../../graphql/queries";
import {
    createAccountingLog,
    createFlaecheInterested,
    updateFlaeche,
    updateOrganization,
} from '../../../graphql/mutations';
import { useEffect, useMemo, useState } from "react";
import { getEmailData } from "../utils";
import { useTranslation } from "react-i18next";

interface Options {
    areaDetails?: Flaeche | null;
    user?: UserInfo | null;
}

export const useContactsAccess = ({ areaDetails, user }: Options) => {
    const { loggedIn } = useAuth();
    const { t } = useTranslation();
    const [isListFull, setIsListFull] = useState(false);
    const [isJoined, setIsJoined] = useState(false);
    const [isOpenContacts, setIsOpenContacts] = useState(false);
    const [hasAccess, setHasAccess] = useState(false);
    const [ownOrganization, setOwnOrganization] = useState<Organization | null>();
    const [memberOf, setMemberOf] = useState<Organization | null>();
    const [notTypeError, setNotTypeError] = useState(false);

    const getUserOrganization = async () => {
        if (!user) return;

        try {
            const { data } = await API.graphql<
                GraphQLQuery<GetOrganizationbyOwnerQuery>
            >(
                graphqlOperation(getOrganizationbyOwner, {
                    OwnerOfOrganization: user?.sub,
                })
            );

            if (data?.getOrganizationbyOwner?.items) {
                const organization = data?.getOrganizationbyOwner?.items.find(el => !el?.disabledOrganization);

                setOwnOrganization(organization);
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getOrganizationMemberIn = async () => {
      if (!user || !user.MemberofOrganizationID) return;

      try {
        const { data } = await API.graphql<
                GraphQLQuery<GetOrganizationQuery>
            >(
                graphqlOperation(getOrganization, {
                    id: user?.MemberofOrganizationID,
                })
            );

        setMemberOf(data?.getOrganization);
      } catch (error) {
        console.log(error);
      }
    }

    const handleCloseNotTypeError = () => {
      setNotTypeError(false);
    }

    const sendEmail = async (member?: UserInfo | null) => {
      try {
        const emailData = getEmailData(t, loggedIn, areaDetails?.id)
  
        const params = {
          templateId: "d-8b14c20a54354d80808c25fd32eae477",
          toEmail: member?.email,
          toName: member?.firstName,
          dynamicTemplateData: emailData
        }

        const endpoint = 'https://edc1touev2.execute-api.eu-central-1.amazonaws.com?method=SendgridMessage';

        const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(params)
        };
        
        await fetch(endpoint, options)
      } catch (error) {
        console.log("Error:", error)
      }
    }

    const handleInterest = async (isFullList: boolean) => {
        const userOrganization = ownOrganization ?? memberOf;

        if (userOrganization 
            && areaDetails 
            && userOrganization?.AllowedAreaTypes 
            && userOrganization?.AllowedAreaTypes.length > 0
            && !userOrganization?.AllowedAreaTypes?.includes(areaDetails?.areaType)
          ) {
          setNotTypeError(true);
          return;
        }

        const data = {
            flaecheId: areaDetails?.id,
            userInfoSub: loggedIn?.sub,
            DateTimeUnix: Math.floor(new Date().getTime() / 1000),
            TransactionTypeAreaUnlockRegular: true,
            TransactionTypeAreaUnlockEarlyAccess: false,
            TransactionTypeAreaAlertNotificationMail: false,
            OrganizationID: user?.MemberofOrganizationID
        };
    
        const interested = {
            flaecheId: areaDetails?.id,
            userInfoSub: loggedIn?.sub,
            interested: true
        };
    
        const interestedUsers = isFullList
          ? areaDetails && (areaDetails.InvestorsWaitlistForAccess
            ? [...areaDetails.InvestorsWaitlistForAccess, loggedIn.sub]
            : [loggedIn.sub])
          : areaDetails && (areaDetails.InvestorsWithApprovedAccess
            ? [...areaDetails.InvestorsWithApprovedAccess, loggedIn.sub]
            : [loggedIn.sub]);
    
        try {
          const accessId = user?.MemberofOrganizationID 
            ? user?.MemberofOrganizationID 
            : (ownOrganization 
              ? ownOrganization.id 
              : ''
            );
    
          const updateArea = {
            id: areaDetails?.id,
            ...(isFullList
              ? { InvestorsWaitlistForAccess: interestedUsers }
              : { InvestorsWithApprovedAccess: interestedUsers }),
            OrganizationsWithApprovedAccess: areaDetails?.OrganizationsWithApprovedAccess
              ? [...areaDetails?.OrganizationsWithApprovedAccess, accessId]
              : [accessId],
            MaximumNumberOfContactsAllowed: userOrganization?.ExclusiveAccessFirst24h
          };
    
          if (userOrganization && !isFullList) {
            const access = {
                flaecheId: areaDetails?.id ||'',
                userInfoSub: user?.sub || '',
                DateTime: new Date().toString()
            };
        
            const accessAreas = userOrganization.AccessToAreaID 
                ? [...userOrganization.AccessToAreaID, access] 
                : [access];
                
            const updatedOrg = {
                id: userOrganization.id,
                AccessToAreaID: accessAreas.map(area => ({
                    flaecheId: area.flaecheId,
                    OrganizationId: userOrganization.id,
                    userInfoSub: area.userInfoSub,
                    DateTime: area.DateTime
                }))
            };
        
            await API.graphql<GraphQLQuery<UpdateOrganizationMutation>>(
                graphqlOperation(updateOrganization, { input: updatedOrg })
            );

            if (userOrganization.SendBroadcastForUnlockToAllOrganizationMembers) {
              if (userOrganization.OwnerOfOrganization === loggedIn.sub) {
                await sendEmail(loggedIn);
              }

              const { data } = await API.graphql<
                GraphQLQuery<GetUserForCompanyByCompanyIDQuery>
              >(
                graphqlOperation(getUserForCompanyByCompanyID, {
                    MemberofOrganizationID: userOrganization?.id,
                })
              );

              const members = data?.getUserForCompanyByCompanyID?.items;

              if (members && members.length > 0) {
                await Promise.all(members.map(async member => {
                  return sendEmail(member);
                }));
              }

            }
        }
        
          await API.graphql<
              GraphQLQuery<UpdateFlaecheMutation>
          >(graphqlOperation(updateFlaeche, { input: updateArea }));
    
          await API.graphql<
              GraphQLQuery<CreateFlaecheInterestedMutation>
          >(graphqlOperation(createFlaecheInterested, { input: interested }));
          
          await API.graphql<
              GraphQLQuery<CreateAccountingLogMutation>
          >(graphqlOperation(createAccountingLog, { input: data }));

          if (!isFullList) {
            setIsOpenContacts(true);
          }

          if (isFullList) {
            setIsJoined(true);
          }
        } catch (error) {
          console.log(error);
        }
      }
    
      const handleOpenDetails = async () => {
        try {
            if (areaDetails?.InvestorsWithApprovedAccess?.length 
                === Number(process.env.REACT_APP_CONFIG_MAX_NUM_OF_CONTACS_PER_AREA)
                || areaDetails?.MaximumNumberOfContactsAllowed
              ) {
              setIsListFull(true);
              return;
            }
    
            await handleInterest(false);
        } catch (error) {
            console.log("Error", error)
        }
      }
    
      const handleJoinWaitList = async () => {
        try {
          if (areaDetails?.InvestorsWaitlistForAccess?.includes(loggedIn.sub)) {
            setIsJoined(true);
            setIsListFull(false);
    
            return;
          }
    
          await handleInterest(true);
  
          setIsListFull(false);
        } catch (error) {
          console.log("Error:", error)
        }
      }

      useEffect(() => {
        if (areaDetails?.InvestorsWithApprovedAccess?.includes(loggedIn.sub)) {
          setIsOpenContacts(true);
        }
      }, [areaDetails, loggedIn])
    
      useEffect(() => {
        if (isJoined) {
          setTimeout(() => {
            setIsJoined(false);
          }, 6000)
        }
      }, [isJoined])

      useEffect(() => {
        const userHasAccess = user?.MemberofOrganizationID &&
            areaDetails?.OrganizationsWithApprovedAccess?.includes(user.MemberofOrganizationID);

        const ownerHasAccess = memberOf && areaDetails?.InvestorsWithApprovedAccess?.includes(memberOf.OwnerOfOrganization);
    
        if (userHasAccess || ownerHasAccess) {
            setHasAccess(true);
        } else {
            setHasAccess(false);
        }
    }, [user, areaDetails, memberOf]);
    

    useEffect(() => {
        getUserOrganization();
        getOrganizationMemberIn();
    }, [user])

    const isVisible = useMemo(() => {
        return isOpenContacts || hasAccess;
    }, [isOpenContacts, hasAccess])

    const isInList = useMemo(() => (
        areaDetails?.InvestorsWaitlistForAccess?.includes(loggedIn.sub)
      ), [areaDetails, loggedIn])

      return {
        isJoined,
        isListFull,
        notTypeError,
        handleCloseNotTypeError,
        isVisible,
        isOpenContacts,
        handleInterest,
        isInList,
        setIsListFull,
        handleJoinWaitList,
        handleOpenDetails
      }
}
