import { useEffect, useState } from 'react';
import {
    GetOrganizationQuery,
    GetUserForCompanyByCompanyIDQuery,
    GetUserInfoByEmailAddressQuery,
    ListOrganizationsQuery,
    Organization,
    UserInfo,
} from '../../../API';
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLQuery } from '@aws-amplify/api';
import {
    getOrganization,
    getUserForCompanyByCompanyID,
    getUserInfoByEmailAddress,
    listOrganizations,
} from '../../../graphql/queries';

interface Options {
    organization?: Organization | null;
    user: UserInfo;
}

export const useOrganizations = ({ organization, user }: Options) => {
    const [invitedIn, setInvitedIn] = useState<(Organization | null)[]>();
    const [members, setMembers] = useState<(UserInfo | null)[]>();
    const [invitedUsers, setInvitedUsers] = useState<
        (UserInfo | null | undefined)[]
    >([]);
    const [memberOf, setMemberOf] = useState<Organization | null>();
    const [users, setUsers] = useState<any>([]);
    const [existingUsers, setExistingUsers] = useState<any>([]);
    const [notRegisteredUsers, setNotRegisteredUsers] = useState<string[]>([]);

    const getUsersInfo = async () => {
        if (
            !organization ||
            !organization?.InviteCompanyMembersEMail ||
            organization?.InviteCompanyMembersEMail?.length === 0
        )
            return

        try {
            const userQueries = organization.InviteCompanyMembersEMail.map(
                (user) => {
                    return API.graphql<
                        GraphQLQuery<GetUserInfoByEmailAddressQuery>
                    >(
                        graphqlOperation(getUserInfoByEmailAddress, {
                            email: user,
                        })
                    )
                }
            )

            const userData = await Promise.all(userQueries)

            const userInfoArray = userData.map(
                (response) => response.data?.getUserInfoByEmailAddress?.items[0]
            )

            setInvitedUsers(userInfoArray)
        } catch (error) {
            console.error('Error fetching user info:', error)
        }
    }

    const getUserOrganizationIn = async () => {
        try {
            if (!user || !user.MemberofOrganizationID) return

            const { data } = await API.graphql<
                GraphQLQuery<GetOrganizationQuery>
            >(
                graphqlOperation(getOrganization, {
                    id: user.MemberofOrganizationID,
                })
            )

            setMemberOf(data?.getOrganization)
        } catch (error) {
            console.log('Error:', error)
        }
    }

    const getMembers = async () => {
        if (!organization || organization?.OwnerOfOrganization !== user.sub)
            return

        try {
            const { data } = await API.graphql<
                GraphQLQuery<GetUserForCompanyByCompanyIDQuery>
            >(
                graphqlOperation(getUserForCompanyByCompanyID, {
                    MemberofOrganizationID: organization?.id,
                })
            )

            setMembers(data?.getUserForCompanyByCompanyID?.items)
        } catch (error) {
            console.log('Error getting company members:', error)
        }
    }

    const getInvitedInOrganizations = async () => {
        try {
            const { data } = await API.graphql<
                GraphQLQuery<ListOrganizationsQuery>
            >(
                graphqlOperation(listOrganizations, {
                    filter: {
                        InviteCompanyMembersEMail: { contains: user.email },
                    },
                })
            )

            setInvitedIn(data?.listOrganizations?.items)
        } catch (error) {
            console.log('Error getting invited in organizations:', error)
        }
    }

    const handleAcceptInvitation = (org: Organization) => {
        const filteredOrganizations = invitedIn?.filter(el => el?.id !== org.id);
        setInvitedIn(filteredOrganizations);
        setMemberOf(org);
    }

    const handleDeclineInvitation = (org: Organization) => {
        const filteredOrganizations = invitedIn?.filter(el => el?.id !== org.id);
        setInvitedIn(filteredOrganizations);
    }

    useEffect(() => {
        const users = [];

        if (members) {
            users.push(...members);
        }

        if (invitedUsers) {
            users.push(...invitedUsers);
        }

        setUsers(users.filter((el) => el));
        setExistingUsers(users.filter((el) => el));
    }, [members, invitedUsers])

    useEffect(() => {
        const otherUsers = (organization?.InviteCompanyMembersEMail ?? []).filter(email => {
            return !existingUsers.find((user: UserInfo) => user?.email === email);
        });

        setNotRegisteredUsers(otherUsers);
    }, [existingUsers, organization])

    useEffect(() => {
        getInvitedInOrganizations();
        getMembers();
        getUsersInfo();
    }, [organization])

    useEffect(() => {
        getUserOrganizationIn();
    }, [user])

    return {
        invitedIn,
        setInvitedIn,
        memberOf,
        setMemberOf,
        members,
        users,
        setUsers,
        notRegisteredUsers,
        setNotRegisteredUsers,
        getInvitedInOrganizations,
        handleAcceptInvitation,
        handleDeclineInvitation,
        getMembers,
        getUsersInfo,
        getUserOrganizationIn
    }
}
