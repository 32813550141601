import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Flaeche } from "../../../API";
import { useArea } from "../hooks/useArea";
import { ProgressDesc } from "./ProgressDesc";
import { Buttons } from "./Buttons";
import { DisabledButtons } from "./DisabledButtons";
import { AreaDescription } from "./AreaDescription";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../hooks";
import { LazyLoadImage } from "../../../components";
import { Links } from "../../../settings";
import { fetchImage } from "../../../utils";
import { Lock } from "@mui/icons-material";
interface AreaCardProps {
    item?: Flaeche;
    image: string;
    width?: string;
    selected?: boolean;
    onDeactivate?: (id: string | undefined) => void;
    onFavorite?: (id: string | undefined) => void;
    onRemoveFavorite?: (id: string | undefined) => void;
    fetchImages: (id?: string | undefined) => Promise<void>;
    isOwnerInvestor?: boolean; 
}

export const AreaCard = (props: AreaCardProps) => {
    const { 
        item, 
        selected, 
        width="w-[315px]", 
        image, 
        onDeactivate, 
        onFavorite,
        onRemoveFavorite,
        fetchImages,
        isOwnerInvestor
    } = props;
    const { loggedIn, isAdmin, isInvestor } = useAuth();
    const { t } = useTranslation();

    const {
        desc,
        title,
        status,
        progressImage
    } = useArea({ item });

    const [isSelected, setIsSelected] = useState(selected);
    const [imageUrl, setImageUrl] = useState<string>("");

    const handleSelect = () => {
        setIsSelected(prev => !prev);
        
        if (onFavorite) {
            onFavorite(item?.id);
        }
    }

    const handleRemove = () => {
        setIsSelected(prev => !prev);
        
        if (onRemoveFavorite) {
            onRemoveFavorite(item?.id);
        }
    }

    const ownArea = useMemo(() => (
        loggedIn && loggedIn.sub === item?.ownerSub
    ), [loggedIn, item]);

    const isFullList = useMemo(() => (
        item?.InvestorsWithApprovedAccess?.length 
            === Number(process.env.REACT_APP_CONFIG_MAX_NUM_OF_CONTACS_PER_AREA)
            || item?.MaximumNumberOfContactsAllowed
    ), [item])

    useEffect(() => {
        setIsSelected(selected);
    }, [selected])

    useEffect(() => {
        fetchImages(item?.id)
    }, [item])

    useEffect(() => {
        async function loadImage() {
            try {
                const fetchedImageUrl = await fetchImage(image);

                if (fetchedImageUrl) {
                    setImageUrl(fetchedImageUrl);
                }
            } catch (error) {
                console.error('Error loading image:', error);
            }
        }
        
        loadImage();
    }, [image]);

    return (
        <div
            className={`relative border-box h-[455px] ${width} rounded-[12px] border-[1px] border-[#111A2126] hover:shadow-md`}
        >
            {item?.disabled && (
                <div className="group cursor-pointer">
                    <div
                        className="absolute top-0 left-0 w-full h-full bg-gray-200 opacity-70 rounded-[12px] cursor-pointer"
                        style={{ zIndex: 1 }}
                    >
                    </div>

                    <DisabledButtons id={item.id} />
                </div>
            )}

            <div className="flex items-center justify-between px-[15px] py-[15px]">
                <Link to={`/search/${item?.id}`}>
                    {!ownArea ? (
                        <p className="text-[24px] font-medium">
                            {item?.areaType &&
                                t(item.areaType).slice(0, 1).toUpperCase() +
                                    t(item.areaType).slice(1)}
                        </p>
                    ) : (
                        <p className="text-[24px] font-medium">
                            {item?.areaName ? t(item.areaName) : ''}
                        </p>
                    )}
                </Link>

                <div className="flex gap-[10px] items-center">
                    {!ownArea && (
                        <img
                            src={`${
                                isSelected
                                    ? '/assets/icons/fullStar.svg'
                                    : '/assets/icons/emptyStar.svg'
                            }`}
                            alt="star"
                            className="cursor-pointer"
                            onClick={isSelected ? handleRemove : handleSelect}
                        />
                    )}
                </div>
            </div>

            <div className="group relative overflow-hidden">
                <Link to={`${Links.search.index}/${item?.id}`}>
                    <div className="h-[230px] relative">
                        <LazyLoadImage
                            src={imageUrl}
                            alt=""
                            className="h-[230px] w-full transition-all duration-500 group-hover:scale-105"
                        />

                        {isFullList && (isInvestor || isAdmin || isOwnerInvestor)  && (
                            <div 
                                className="absolute top-0 left-0 px-[18px] py-[5px] flex gap-[5px] items-center" 
                                style={{ backgroundColor: 'rgba(176, 204, 85, 0.85)' }}
                            >
                                <Lock className="text-white h-[10px]" style={{ fontSize: 15 }} />
                                <p className="text-white text-[14px] mt-[2px]">
                                    {t("area.locked")}
                                </p>
                            </div>
                        )}
                    </div>
                </Link>

                <Buttons 
                    ownArea={ownArea} 
                    areaId={item?.id} 
                    onDeactivate={onDeactivate} 
                />
            </div>

            {item && (
                <AreaDescription area={item} />
            )}

            {image && title && status && (
                <ProgressDesc
                    title={title}
                    desc={desc}
                    status={status}
                    progressImage={progressImage}
                />
            )}
        </div>
    )
}
