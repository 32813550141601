import { useNavigate } from "react-router-dom";
import { Links } from "../../../settings";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../hooks";
import { Lock } from "@mui/icons-material";

interface ButtonsProps {
    isOwner: boolean;
    onOpenContacts: () => Promise<void>;
    isOpen: boolean;
    isJoined: boolean;
    isVisible: boolean;
}

export const Buttons = (props: ButtonsProps) => {
  const { 
    isOwner, 
    onOpenContacts, 
    isOpen,
    isJoined,
    isVisible
  } = props;

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isInvestor } = useAuth();

  return (
    <div className={`flex md:flex-row flex-col gap-[21px] ${!isOpen && !isVisible ? 'pb-[35px]' : ''} md:px-0 px-[30px]`}>
        {
            !isOwner && isInvestor && !isOpen && !isJoined && !isVisible && (
                <button 
                    className='flex justify-center rounded-[10px] text-white bg-[#11470E] 
                    text-[18px] font-medium flex gap-[18px] p-[15px] items-center'
                    onClick={onOpenContacts}
                >
                    <Lock className="text-white" />
                    <p className="text-[13px] md:text-[16px]">{t('area.unlock_contacts')}</p>
                </button>
            )
        }

        {!isOwner && !isInvestor && !isVisible && (
            <button 
                className='flex justify-center rounded-[10px] text-white bg-[#11470E] 
                text-[18px] font-medium flex gap-[18px] p-[15px]'
                onClick={() => navigate(Links.investorPricing.index)}
            >
                <p>{t('area.get_full_access')}</p>
            </button>
        )}
    </div>
  )
}
