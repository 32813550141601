import { GraphQLQuery } from '@aws-amplify/api';
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
    GetOrganizationQuery,
    GetUserInfoByEmailAddressQuery,
    GetUserInfoBySubQuery,
    Organization,
    UpdateOrganizationMutation,
    UserInfo,
} from '../../../API';
import { validateEmail } from "../../../helpers";
import { getOrganization, getUserInfoByEmailAddress, getUserInfoBySub } from '../../../graphql/queries';
import { API, graphqlOperation } from "aws-amplify";
import { updateOrganization } from '../../../graphql/mutations';
import { useAuth } from '../../../hooks';

interface Options {
    organization?: Organization | null;
    memberOf?: Organization | null;
    onInvite:  Dispatch<SetStateAction<any>>;
    onOpenInvite: Dispatch<SetStateAction<boolean>>;
    onNotRegistered: Dispatch<SetStateAction<string[]>>;
}

export const useMembers = ({
    organization,
    memberOf,
    onInvite,
    onNotRegistered,
    onOpenInvite
}: Options) => {
    const [owner, setOwner] = useState<UserInfo | null>();
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const { loggedIn } = useAuth();

    const sendEmail = async (user?: UserInfo | null) => {
        try {
            const language = user
                && user.locale === 'de';

            const endpoint = 'https://edc1touev2.execute-api.eu-central-1.amazonaws.com?method=SendgridMessage';

            const parameters = {
                subject: language ? 'Enlapa-Einladung zur neuen Organisation' : "Enlapa-Invitation to the new organization",
                congrats: language ? 'Herzlichen Glückwunsch!' : "Congratulations!",
                invited: language 
                    ? `${loggedIn.firstName} ${loggedIn.lastName} (${loggedIn.email}) hat Sie eingeladen der Organisation (${organization?.CompanyName})  auf ENLAPA beizutreten. Um diese Einladung anzunehmen oder abzulehnen, melden Sie sich an und gehen Sie auf Ihre Profilseite`
                    : `${loggedIn.firstName} ${loggedIn.lastName} (${loggedIn.email}) has invited you to join their organization (${organization?.CompanyName}) on ENLAPA. If you wish to accept or decline this invite, please sign-up or sign-in and visit your profile page:`,
                button: language ? 'Profilseite' : 'Profile page',
                thanks: language 
                    ? 'Vielen Dank, dass Sie ENLAPA gewählt haben, um die Energiewende voranzutreiben.' 
                    : 'Thanks for choosing ENLAPA to drive the energy transition.',
                enlapa: language ? 'Ihr ENLAPA Team' : 'Your ENLAPA team'
            };

            const emailData = {
                templateId: "d-5d11d48905414cb1a4fc32022e684c60",
                toEmail: email,
                toName: user 
                    ? user.firstName 
                    : '',
                dynamicTemplateData: parameters
            }

            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(emailData)
            };

            await fetch(endpoint, options);
        } catch (error) {
            console.log("Error sending email:", error)
        }
    }

    const handleApprove = async () => {
        const isValid = validateEmail(email);

        if (!isValid) {
            setError('organization.correct');

            return;
        }

        if (organization?.InviteCompanyMembersEMail?.some(el => el === email)) {
            setError('organization.invited');

            return;
        }

        try {
            setIsLoading(true);

            const { data: updated } = await API.graphql<
                GraphQLQuery<GetOrganizationQuery>
            >(
                graphqlOperation(getOrganization, {
                    id: organization?.id,
                })
            );

            const org = updated?.getOrganization;

            const invitedUsers = org?.InviteCompanyMembersEMail 
            ? [...org.InviteCompanyMembersEMail, email] 
            : [email];
        
            const editingOrg = {
                id: organization?.id,
                InviteCompanyMembersEMail: invitedUsers
            }

            await API.graphql<GraphQLQuery<UpdateOrganizationMutation>>(
                graphqlOperation(updateOrganization, { input: editingOrg })
            )

            const { data } = await API.graphql<GraphQLQuery<GetUserInfoByEmailAddressQuery>>(
                graphqlOperation(getUserInfoByEmailAddress, { email })
            );

            if (data?.getUserInfoByEmailAddress?.items[0]) {
                onInvite((prev: UserInfo[]) => prev 
                    ? [...prev, data.getUserInfoByEmailAddress?.items[0]] 
                    : [data.getUserInfoByEmailAddress?.items[0]]
                )
            } else {
                onNotRegistered((prev: string[]) => prev 
                    ? [...prev, email] 
                    : [email]
                )
            }

            await sendEmail(data?.getUserInfoByEmailAddress?.items[0]);

            onOpenInvite(false);
            setEmail('');
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            setError('organization.wrong');
        }
    }

    const getOrganizationOwner = async () => {
        if (!memberOf) return;

        try {
            const { data } = await API.graphql<
                GraphQLQuery<GetUserInfoBySubQuery>
            >(
                graphqlOperation(getUserInfoBySub, {
                    sub: memberOf?.OwnerOfOrganization,
                })
            )

            if (data?.getUserInfoBySub?.items[0]) {
                setOwner(data?.getUserInfoBySub?.items[0])
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getOrganizationOwner();
    }, [memberOf, organization])

    return {
        owner,
        email,
        error,
        setEmail,
        setError,
        handleApprove,
        isLoading
    }
}