import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { Links } from './settings';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSocialLogin } from './hooks/useSocialLogin';
import { useAuth, useChatClient, useLastLogin, useResponsive, useUnreadMessages } from './hooks';
import { Client, Message, PushNotification } from '@twilio/conversations';
import { initFcmServiceWorker, subscribeFcmNotifications } from './firebase';
import { routes } from './utils/routes';
import { SocialError } from './components/SocialError';
import { NotificationModal } from './components';
import { Auth } from 'aws-amplify';

export interface NotificationData {
    full_name: string;
    message: string;
}
const App = () => {
    const [notificationData, setNotificationData] = useState<NotificationData | null>(null);
    const [isShownNotification, setShowNotification] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    const { pathname, state } = useLocation();
    const { token } = useAuth();
    const navigate = useNavigate();
    const {
        handleSocialLogin,
        isEmailError,
        setIsEmailError
    } = useSocialLogin();
    const { client, getToken } = useChatClient();
    const { getUnreadMessages } = useUnreadMessages();
    const { loggedIn, logout } = useAuth();
    const { updateUserLastLogin } = useLastLogin();
    const { isMobile } = useResponsive();

    const handleUnreadMessages = async (message: Message) => {
        if (message.author !== loggedIn.id) {
            getUnreadMessages();
        }
    }

    const handleCloseNotification = () => {
        navigate(Links.chat.index);
        setShowNotification(false);
        setNotificationData(null);
    }

    const handleNotification = (event: PushNotification) => {
        if (event.type !== "twilio.conversations.new_message") {
            return;
        }
        
        if (Notification.permission === "granted") {
            const body = (event.body?.split(":")) ? event.body?.split(":") : [];
            const full_name = body[1];
            const message = body[2];
            const conversationSid = "" + event.data.conversationSid;

            setNotificationData({ full_name, message });
            setShowNotification(true);
        } else {
            console.log("Push notification is skipped", Notification.permission);
        }
    }

    const handleTokenRefresh = async () => {
        console.log("Refresh twilio token");
        
        try {
            const newToken = await getToken();
            client?.updateToken(newToken);
        } catch (error) {
            console.log('Error while refreshing the token:', error)
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setIsScrolled(true)
            } else {
                setIsScrolled(false)
            }
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    useEffect(() => {
        initFcmServiceWorker().catch(() => {
            console.error(
                "FCM initialization failed: no push notifications will be available"
            );
        });
    }, []);

    useEffect(() => {
        if (!token) {
            return;
        }

        const client = new Client(token);

        const fcmInit = async () => {
            await subscribeFcmNotifications(client);
        };

        fcmInit()
            .catch(() => {
                console.error(
                    "FCM initialization failed: no push notifications will be available"
                );
            });

        client?.on("pushNotification", handleNotification);

        return () => {
            client?.removeListener("pushNotification", handleNotification);
        };
    }, [client]);

    useEffect(() => {
        updateUserLastLogin();
        
        if (typeof window !== 'undefined' && window.fbq != null) {
            window.fbq('track', 'ViewContent', { content_name: pathname });
        }

        if (pathname !== '/search' && !pathname.includes('/search/')) {
            sessionStorage.removeItem('prevPosition');
            sessionStorage.removeItem('areaCount');
        }
    }, [pathname])

    useEffect(() => {
        handleSocialLogin();
    }, [])

    useEffect(() => {
        getUnreadMessages();
        
        client?.on("messageAdded", handleUnreadMessages);
        client?.on("tokenAboutToExpire", handleTokenRefresh);
        client?.on("tokenExpired", handleTokenRefresh);
        
        return () => {
            client?.removeListener("messageAdded", handleUnreadMessages);
        }
    }, [client])

    useEffect(() => {
        if (isShownNotification) {
            setTimeout(() => {
                setShowNotification(false);
            }, 6000)
        }
    }, [isShownNotification])

    useEffect(() => {
        const checkCurrentUser = async () => {
            try {
                await Auth.currentAuthenticatedUser();
            } catch (error) {
                console.log(error);
                await Auth.signOut()
                logout();
            }
        }

        checkCurrentUser();
    }, [])

    const element = useRoutes(routes(client, isMobile));

    return (
        <div id="app-wrapper">
            <Header isScrolled={isScrolled} />
            {element}
            {pathname !== '/offer-meta-ads' 
                && state?.path !== '/offer-meta-ads' 
            && (
                <Footer />
            )}

            {isEmailError && (
                <SocialError
                    onClose={() => setIsEmailError(false)}
                />
            )}

            {isShownNotification && notificationData && (
                <NotificationModal 
                    data={notificationData}
                    isScrolled={isScrolled}
                    onClose={handleCloseNotification}
                />
            )}
        </div>
    )
}
export default App
