import { Dispatch, SetStateAction } from "react";
import { Flaeche } from "../API";
import { AreaProperty, ProgressArea } from "../types";
import { blocks, descBlocks } from "../utils/formLists";

export const findAreaDescription = (area: Flaeche, func: (property: AreaProperty) => void) => {
    if (area.criteriaMetConversionArea === 'true') {
        const property = descBlocks.find(el => el.id === 3);

        if (property) {
            func(property);
        }

        return;
    }

    if (area.criteriaMetHandicapped === 'true') {
        const property = descBlocks.find(el => el.id === 2);

        if (property) {
            func(property);
        }

        return;
    }

    if (area.criteriaMetIndustryArea === 'true') {
        const property = descBlocks.find(el => el.id === 4);

        if (property) {
            func(property);
        }

        return;
    }

    if (area.criteriaMetMargins === 'true') {
        const property = descBlocks.find(el => el.id === 1);

        if (property) {
            func(property);
        }

        return;
    }

    if (area.criteriaMetOther === 'true') {
        const property = descBlocks.find(el => el.id === 6);

        if (property) {
            func(property);
        }

        return;
    }

    if (area.criteriaMetPlant === 'true') {
        const property = descBlocks.find(el => el.id === 5);

        if (property) {
            func(property);
        }

        return;
    }
}

export const getAreaProgress = (
    area: Flaeche, 
    onActive:  Dispatch<SetStateAction<ProgressArea[]>>,
    onCompleted:  Dispatch<SetStateAction<ProgressArea[]>>,
) => {
    if (area.progressZustimmung !== 'not started') {
        const property = blocks.find(el => el.id === 1);

        if (property && area.progressZustimmung === 'started') {
            onActive((prev: ProgressArea[]) => [...prev, property]);
        }

        if (property && area.progressZustimmung === 'finished') {
            onCompleted((prev: ProgressArea[]) => [...prev, property]);
        }
    }

    if (area.progressPachtvertragAbgeschlossen !== 'not started') {
        const property = blocks.find(el => el.id === 2);

        if (property && area.progressPachtvertragAbgeschlossen === 'started') {
            onActive((prev: ProgressArea[]) => [...prev, property]);
        }

        if (property && area.progressPachtvertragAbgeschlossen === 'finished') {
            onCompleted((prev: ProgressArea[]) => [...prev, property]);
        }
    }

    if (area.progressEinspeisezusage !== 'not started') {
        const property = blocks.find(el => el.id === 3);

        if (property && area.progressEinspeisezusage === 'started') {
            onActive((prev: ProgressArea[]) => [...prev, property]);
        }

        if (property && area.progressEinspeisezusage === 'finished') {
            onCompleted((prev: ProgressArea[]) => [...prev, property]);
        }
    }

    if (area.progressAufstellungsbeschluss !== 'not started') {
        const property = blocks.find(el => el.id === 4);

        if (property && area.progressAufstellungsbeschluss === 'started') {
            onActive((prev: ProgressArea[]) => [...prev, property]);
        }

        if (property && area.progressAufstellungsbeschluss === 'finished') {
            onCompleted((prev: ProgressArea[]) => [...prev, property]);
        }
    }

    if (area.progressBebauungsplan !== 'not started') {
        const property = blocks.find(el => el.id === 5);

        if (property && area.progressBebauungsplan === 'started') {
            onActive((prev: ProgressArea[]) => [...prev, property]);
        }

        if (property && area.progressBebauungsplan === 'finished') {
            onCompleted((prev: ProgressArea[]) => [...prev, property]);
        }
    }

    if (area.progressBauvertrag !== 'not started') {
        const property = blocks.find(el => el.id === 6);

        if (property && area.progressBauvertrag === 'started') {
            onActive((prev: ProgressArea[]) => [...prev, property]);
        }

        if (property && area.progressBauvertrag === 'finished') {
            onCompleted((prev: ProgressArea[]) => [...prev, property]);
        }
    }

    if (area.progressAnlageGebaut !== 'not started') {
        const property = blocks.find(el => el.id === 7);

        if (property && area.progressAnlageGebaut === 'started') {
            onActive((prev: ProgressArea[]) => [...prev, property]);
        }

        if (property && area.progressAnlageGebaut === 'finished') {
            onCompleted((prev: ProgressArea[]) => [...prev, property]);
        }
    }
}