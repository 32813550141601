import { useEffect, useMemo, useState } from "react";
import { ListOrganizationsQuery, Organization } from "../../../API";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { listOrganizations } from "../../../graphql/queries";
import { useTranslation } from "react-i18next";
import { filterOptions } from "../utils/options";

export const useOrganizationsData = () => {
  const { t } = useTranslation();
  const [filterBy, setFilterBy] = useState(t(filterOptions[1].label).toLowerCase());
  const [isLoading, setIsLoading] = useState(false);
  const [organizations, setOrganizations] = useState<(Organization | null)[]>([]);

  const getOrganizations = async () => {    
    let nextToken: string | null | undefined = null;
    let organizations: (Organization | null)[] = [];

    setIsLoading(true);
  
    const fetchAreasBatch = async () => {
      return API.graphql<GraphQLQuery<ListOrganizationsQuery>>(
        graphqlOperation(listOrganizations, { 
          limit: 100, 
          nextToken,
        })
      )
        .then((response) => {
          const { items, nextToken: newNextToken } = response.data?.listOrganizations || {};
          
          organizations = [...organizations, ...(items || [])];
          nextToken = newNextToken;
        });
      }

      const handleFetchAreas: any = async () => {
          return fetchAreasBatch().then(() => {
            if (nextToken) {
              return handleFetchAreas();
            }
          });
      };
      
      handleFetchAreas()
      .then(() => {
          setOrganizations(organizations);
          setIsLoading(false);
      })
      .catch((error: any) => {
          console.error(error);
          setIsLoading(false);
      });
  }

  const filteredOrganizations = useMemo(() => {
    return organizations.filter(org => {
        switch (filterBy) {
            case 'aktiv':
            case 'active': 
                return !org?.disabledOrganization;
            case 'deaktiviert':
            case 'deactivated': 
                return org?.disabledOrganization;
            default:
                return org;
        }
    })
  }, [organizations, filterBy])

  useEffect(() => {
    getOrganizations();
  }, [])

  return {
    filteredOrganizations,
    setFilterBy,
    isLoading
  }
}