/** @format */

import { useTranslation } from "react-i18next";

interface ExplainerProps {
    steps: Record<string, string>[]
    styles?: string
}

export const Explainer = ({ steps, styles }: ExplainerProps) => {
    const { t } = useTranslation();

    return (
        <>
            {steps.map((step: Record<string, string>, i) => (
                <div className={styles} key={i}>
                    <img
                        src={`/assets/images/illustrations/${step.icon}`}
                        alt={`Enlapa ${t(step.title)}`}
                        className="mb-[30px] h-[52px] w-[52px]"
                    />

                    <h3 className="mb-[20px] text-[28px] font-semibold">
                        {t(step.title)}
                    </h3>
                    <p className="opacity-60">{t(step.description)}</p>
                </div>
            ))}
        </>
    )
}
