import { useTranslation } from "react-i18next";
import { usePageDescription } from "../../hooks";
import { Helmet } from "react-helmet";

export const CancellationPolicy = () => {
  const { t } = useTranslation();

  usePageDescription({ 
    title: t('title.cancellation'),
    description: t('description.cancellation')
  });

  return (
    <>
        <Helmet>
            <meta name='title' content={t('title.cancellation')} />
            <meta name='description' content={t('description.cancellation')} />
        </Helmet>
        <div className="flex flex-col py-[50px] md:px-[100px] px-[25px]">
        <p className="md:text-[37px] text-[20px] font-bold text-secondary mb-[10px]">
            {t('home.footer.cancellation')}
        </p>

        <div className="md:text-[20px] flex flex-col gap-[25px]">
            <p>
                {t('cancellation.welcome')}
            </p>

            <div>
                <p className="font-bold">{t('cancellation.right')}</p>

                <p>
                    {t('cancellation.right_option')}
                </p>
            </div>

            <div>
                <p className="font-bold">{t('cancellation.policy')}</p>

                <p className="mb-[20px]">
                    {t('cancellation.policy_option_one')}
                </p>

                <p className="mb-[20px]">
                    {t('cancellation.policy_option_two')}
                </p>

                <p className="mb-[20px]">
                    {t('cancellation.policy_option_three')}
                </p>
            </div>

            <div>
                <p className="font-bold">{t('cancellation.withdrawl')}</p>

                <p className="mb-[20px]">
                    {t('cancellation.withdrawl_option_one')}
                </p>

                <p className="mb-[20px]">
                    {t('cancellation.withdrawl_option_two')}
                </p>

                <p className="mb-[20px]">
                    {t('cancellation.withdrawl_option_three')}
                </p>
            </div>

            <div className="border-[1px] p-[20px] border-black">
                <div className="flex flex-col items-center mb-[15px]">
                    <p className="font-bold">{t('cancellation.form')}</p>
                    <p>{t('cancellation.form_desc')}</p>
                </div>

                <div className="mb-[15px]">
                    <p className="font-bold">{t('cancellation.to')} </p>
                    <p>ENLAPA GmbH, </p>
                    <p>Radlkoferstr. 2, </p>
                    <p>81373 , {t('cancellation.Munich')}</p>
                    <p>{t('cancellation.Germany')}</p>
                </div>

                <p className="mb-[15px]">
                    <span className="font-bold">{t('placeholders.email')}:</span> {" "}
                    impressum@enlapa.de
                </p>

                <div>
                    <p>{t('cancellation.revoke')}</p>
                    <p>{t('cancellation.name')}</p>
                    <p>{t('cancellation.address')}</p>
                    <p>{t('cancellation.signature')}</p>
                    <p>{t('cancellation.date')}</p>
                </div>

                <p>-------------</p>

                <p>{t('cancellation.delete')}</p>
            </div>

            <div>
                <p className="font-bold">{t('cancellation.questions')}</p>
                <p>
                    {t('cancellation.questions_desc')}
                </p>
            </div>

            <p>{t('cancellation.updated')}</p>
        </div>
    </div>
    </>

  )
}
