import { useTranslation } from 'react-i18next';

interface MapButtonsProps {
    openMap: boolean;
    onBack: () => void;
    onOpen: () => void;
}

export const MapButtons = ({
    openMap,
    onBack,
    onOpen
}: MapButtonsProps) => {
  const { t } = useTranslation();

  return (
      <>
          {openMap ? (
              <button
                  className="rounded-[8px] bg-primary px-[15px] py-[5px] transition-all hover:shadow-md"
                  onClick={onBack}
              >
                  {t('admin_dashboard.back')}
              </button>
          ) : (
              <button
                  className="rounded-[8px] bg-primary px-[15px] py-[5px] transition-all hover:shadow-md"
                  onClick={onOpen}
              >
                  {t('admin_dashboard.map')}
              </button>
          )}
      </>
  )
}
