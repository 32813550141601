/** @format */

interface HeadingProps {
    title: string;
    size?: string;
    align?: string;
    weight?: string;
    leading?: string;
    m?: string;
    color?: string;
    tracking?: string;
    sx?: string;
    tag?: string;
}

export const Heading = ({
    title,
    size,
    align,
    weight,
    leading,
    m,
    color,
    tracking,
    sx,
    tag = 'h2'
}: HeadingProps) => {


    switch (tag) {
        case 'p': 
            return (
                <p
                    className={`${size ? size : 'text-[24px] md:text-[48px]'} ${
                        weight ? weight : ' font-medium md:font-semibold '
                    } ${
                        (leading && leading) || ' md:leading-[50px] '
                    } ${align} ${m} ${color} ${tracking} ${sx}`}
                >
                    {title}
                </p>
            );
        
        case 'h3':
            return (
                <h3
                    className={`${size ? size : 'text-[24px] md:text-[48px]'} ${
                        weight ? weight : ' font-medium md:font-semibold '
                    } ${
                        (leading && leading) || ' md:leading-[50px] '
                    } ${align} ${m} ${color} ${tracking} ${sx}`}
                >
                    {title}
                </h3>
            );

        case 'h1':
            return (
                <h1
                    className={`${size ? size : 'text-[24px] md:text-[48px]'} ${
                        weight ? weight : ' font-medium md:font-semibold '
                    } ${
                        (leading && leading) || ' md:leading-[50px] '
                    } ${align} ${m} ${color} ${tracking} ${sx}`}
                >
                    {title}
                </h1>
            );
        default: 
            return (
                <h2
                    className={`${size ? size : 'text-[24px] md:text-[48px]'} ${
                        weight ? weight : ' font-medium md:font-semibold '
                    } ${
                        (leading && leading) || ' md:leading-[50px] '
                    } ${align} ${m} ${color} ${tracking} ${sx}`}
                >
                    {title}
                </h2>
            );
    }
}
