/** @format */

interface ListIconProps {
    icon: string;
    title: string;
    styles?: string;
}

export const ListIcon = ({ icon, title, styles }: ListIconProps) => {
    return (
        <div
            className={
                'flex w-full flex-row items-center justify-center gap-x-[30px] ' +
                styles
            }
        >
            <img className="w-[56px] h-[56px]" src={icon} alt="Enalapa Icon" />
            <p className="break-words text-lg">{title}</p>
        </div>
    )
}
