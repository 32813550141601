import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues, useForm } from 'react-hook-form';
import { CardInfoSchema, TCardInfoSchema } from '../../../helpers';
import { Button, CustomInput } from '../../../components';
import { Card } from '../../../types';
import { useTranslation } from 'react-i18next';

interface CardFormProps  {
    onCards: (card: Card) => void;
}

export const CardForm = ({ onCards }: CardFormProps) => {
    const { t } = useTranslation();

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm<TCardInfoSchema>({
        mode: 'all',
        resolver: yupResolver(CardInfoSchema),
    })

    const onSubmit = (data: FieldValues) => {
        const card = {
            number: data.number,
            expires: data.expires
        };

        onCards(card);
    }

    return (
        <div className='mb-[25px] flex flex-col items-center md:items-start'>
            <div className='flex flex-col md:flex-row gap-[15px] mb-[20px] w-full'>
                <CustomInput
                    name="number"
                    placeholder={t("pricing.card")}
                    sx={`lg:w-[300px] h-[60px] w-full bg-white border-grey-50 border-solid border-[1px] ${
                        errors['number'] && 'border-red-400 outline-red-400'
                    }`}
                    register={register}
                />

                <CustomInput
                    name="expires"
                    placeholder={t("pricing.expiration")}
                    sx={`lg:w-[180px] h-[60px] w-full bg-white border-grey-50 border-solid border-[1px] ${
                        errors['number'] && 'border-red-400 outline-red-400'
                    }`}
                    register={register}
                />
            </div>

            <Button
                color="Primary"
                textColor='text-white'
                type="button"
                onClick={handleSubmit(onSubmit)}
            >
                {t('pricing.add')}
            </Button>
        </div>
    )
}
