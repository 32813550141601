interface SocialButtonProps {
    name: string;
    title: string;
    icon: string;
    onClick: () => void;
}

export const SocialButton = ({ name, onClick, title, icon}: SocialButtonProps) => {
  return (
    <button
        key={name}
        type="button"
        onClick={onClick}
        className="bg-secondary flex gap-[10px] px-[40px] py-[21px] rounded-[12px] text-white items-center justify-center md:w-full w-[300px] whitespace-nowrap"
    >
        <img
            src={icon}
            alt={`Enlapa ${name}`}
            className="h-[15px]"
        />
        <p>{title}</p>
    </button>
  )
}
