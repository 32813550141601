import { useTranslation } from "react-i18next";

export const Uploading = () => {
    const { t } = useTranslation();

    return (
        <div className="mb-[20px] w-full">
            <p className="mb-[10px] text-[20px] font-bold uppercase md:text-[30px]">
                {t('terms.uploading.title')}
            </p>

            <p className="mb-[10px] ml-[20px] md:text-[18px]">
                {t('terms.uploading.irrevocably')}
            </p>

            <p className="mb-[10px] ml-[20px] md:text-[18px]">
                {t('terms.uploading.responsible')}
            </p>

            <p className="mb-[10px] ml-[40px] md:text-[18px]">
                {t('terms.uploading.content')}
            </p>

            <p className="mb-[10px] ml-[40px] md:text-[18px]">
                {t('terms.uploading.loss')}
            </p>

            <p className="mb-[10px] ml-[20px] md:text-[18px]">
                {t('terms.uploading.we_still')}
            </p>

            <p className="mb-[10px] ml-[20px] md:text-[18px]">
                {t('terms.uploading.we_may')}
            </p>

            <p className="mb-[10px] ml-[20px] md:text-[18px]">
                {t('terms.uploading.we_have')}
            </p>
        </div>
    )
}
