import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface NewOwnerModalProps {
    email: string;
    setEmail: (val: string) => void;
    onApprove: () => Promise<void>;
    newOwnerError: string;
    onError: (val: boolean) => void;
}

export const NewOwnerModal = ({
    email,
    setEmail,
    onApprove,
    newOwnerError,
    onError
}: NewOwnerModalProps) => {
    const { t } = useTranslation();

    return (
        <Box className="fixed right-0 top-0 z-10 flex h-full w-full items-center justify-center px-[10px]">
            <div className="z-10 rounded-[8px] bg-[#dcedc8] px-[20px] py-[15px] text-center text-secondary">
                <p className="mb-[20px] max-w-[400px] text-[17px]">
                    {t('organization.sorry')}
                </p>

                <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="mb-[10px] w-full px-[10px] outline-0"
                />

                {newOwnerError && (
                    <p className="mb-[10px] text-red-500">{newOwnerError}</p>
                )}

                <div className="flex w-full justify-center gap-[10px]">
                    <button
                        className="rounded-[8px] bg-secondary px-[10px] py-[5px] text-white"
                        onClick={onApprove}
                        type="button"
                    >
                        {t('organization.invite')}
                    </button>

                    <button
                        className="rounded-[8px] bg-white px-[10px] py-[5px] text-secondary"
                        onClick={() => onError(false)}
                        type="button"
                    >
                        {t('organization.cancel')}
                    </button>
                </div>
            </div>
        </Box>
    )
}
