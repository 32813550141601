// This folder stores types that are used in all project modules

import { IconType } from "../components/Icons";

export type RelativePaths = {
    index: string;
    [key: string]: RelativePaths | string;
};

export type MapRelativePathsToAbsolute<
    Obj extends RelativePaths,
    Prefix extends string = '',
> = {
    [Property in keyof Obj]: Obj[Property] extends string
        ? Obj[Property] extends '/'
            ? '/'
            : Property extends 'index'
            ? Prefix
            : `${Prefix}/${Obj[Property]}`
        : Obj[Property] extends RelativePaths
        ? MapRelativePathsToAbsolute<
              Obj[Property],
              `${Prefix}/${Obj[Property]['index']}`
          >
        : never;
};

export interface Links {
    name: string;
    link: string;
}

export interface AreaProperty {
    id: number;
    title: string;
    image: string;
}

export interface ProgressArea {
    id: number;
    title: string;
    image: string;
    description: string;
}

export interface LegalData {
    subtitle: string;
    title: string;
    description: string;
    block: string;
    image: string;
}

export enum Plan {
    Monthly,
    Yearly,
}

export interface Features {
    text: string;
    size?: number;
    access: boolean;
}

export interface IPlan {
    icon: IconType;
    name: string;
    price: string;
    yearly: number;
    oldPrice?: string;
    description: string;
    features: Features[];
    buttonTitle?: string;
}

export interface SelectedPlan {
    name: string;
    price: string;
    icon?: IconType;
}

export interface Card {
    number: string;
    expires: string;
}

export interface TableColumn {
    id: number;
    name: string;
    width?: string;
    opacity?: number;
}

export interface AreaFeatures {
    country?: string | null;
    state?: string | null;
    city?: string | null;
    street?: string | null;
    postCode?: string | null;
}

export interface IncompletedData {
    type: string | undefined;
    size: number | null | undefined;
}

export interface Geolocation {
    latitude: string;
    longitude: string;
}

export interface DropDownOptions {
    label: string;
    value: string;
  }

  export interface SliderPropsMock {
    id: string
    address_state: string
    areaSize: number
    img: string
    address_country: string
    areaType: string

}

