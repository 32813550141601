import { useNavigate } from "react-router-dom";
import { Button } from "../Button";
import { useCallback } from "react";
import { Links } from "../../settings";

interface GradientBlockProps {
    title: string;
    buttonText: string;
    image: string;
}

export const GradientBlock = (props: GradientBlockProps) => {
    const { title, buttonText, image } = props;

    const navigate = useNavigate();

    const handleNavigate = useCallback(() => {
        navigate(Links.offer.index);
    }, [navigate])
    return (
        <div className="relative flex w-full flex-col justify-between bg-[#8EAD44] md:px-[100px] px-[25px] py-[70px] xl:flex-row">
            <p className="relative z-10 mb-[15px] max-w-[800px] text-white md:mb-0 md:text-[60px] text-[20px] text-center md:text-left md:font-bold max-w-[750px]">
                {title}
            </p>

            <div className="flex md:w-fit w-full items-center justify-center md:justify-end">
                <Button
                    textColor="text-white"
                    width="w-fit"
                    sx="px-[15px] relative z-10"
                    color="Primary"
                    onClick={handleNavigate}
                >
                    {buttonText}
                </Button>

                <img
                    src={image}
                    alt="house"
                    className="absolute bottom-0 right-0 h-full md:top-0 md:max-h-[450px]"
                />
                <img
                    src="/assets/images/gradient.png"
                    alt="house"
                    className="absolute bottom-0 right-0 top-0 h-full md:max-h-[450px]"
                />
            </div>
        </div>
    )
}
