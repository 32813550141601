import { useCallback, useEffect, useState } from "react";
import { Card, SelectedPlan } from "../../../types";
import { FieldValues } from "react-hook-form";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { CreatePricingTestMutation } from "../../../API";
import { createPricingTest } from "../../../graphql/mutations";
import { Links } from "../../../settings";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth, useProcessTry } from "../../../hooks";

interface Options {
    selected: SelectedPlan | null;
}

export const useCardForm = ({ selected }: Options) => {
    const [cards, setCards] = useState<Card[]>([]);
    const [selectedCard, setSelectedCard] = useState<Card | null>(null);
    const [isAddMode, setIsAddMode] = useState(false);
    const [cardError, setCardError] = useState(false);
    const [isSubmitError, setIsSubmitError] = useState(false);

    const navigate = useNavigate();
    const { loggedIn } = useAuth();
    const { t } = useTranslation();
    const { handleCreateError } = useProcessTry();

    const handleAddCard = useCallback((card: Card) => {
        setCards((prev) => [...prev, card]);
        setIsAddMode(false);
    }, [])

    const handleRemoveCard = useCallback(
        (cardToDelete: Card) => {
            const filteredCards = cards.filter(
                (card) => card.number !== cardToDelete.number
            );

            setCards(filteredCards);

            if (cardToDelete.number === selectedCard?.number) {
                setSelectedCard(filteredCards[0]);
            }
        },
        [cards, selectedCard]
    )

    const onSubmit = async (data: FieldValues) => {
        setCardError(false);

        const price = selected?.name === 'pricing.enterprise' 
            ? `${t(selected.price)} 2000 m²` 
            : `${selected?.price} month`
        ;

        if (selected) {
            const pricingTest = {
                Package: `${t(selected.name)} ${price}`,
                Date: Math.floor(new Date().getTime() / 1000),
                email: data.email,
                Name: data.fullName,
                installedkWPpa: data.solar ? data.solar : null,
                Company: data.company ? data.company : null,
                Phone: data.phone,
                DateUnix: Math.floor(new Date().getTime() / 1000)
            };
        
            if (selected?.name !== 'pricing.enterprise' && !selectedCard) {
                setCardError(true);
    
                return;
            }
    
            try {
                await API.graphql<GraphQLQuery<CreatePricingTestMutation>>(
                    graphqlOperation(createPricingTest, { input: pricingTest })
                );

                if (typeof window !== 'undefined' && window.fbq != null) {
                    window.fbq('track', 'Subscribe');
                }

                if (selected?.name === 'pricing.enterprise') {
                    navigate(Links.pricingEnterprise.index);

                    return;
                }

                setIsSubmitError(true);
            } catch (error: any) {
                console.error(error);

                const errorData = {
                    userInfoSub: loggedIn?.sub,
                    requestBody: JSON.stringify(pricingTest),
                    errorMsg: error.message as string,
                    process: 'Submit pricing form - plan',
                    DateTimeUnix: Math.floor(new Date().getTime() / 1000)
                };
            
                handleCreateError(errorData);
            }
        }
    }

    useEffect(() => {
        if (!selectedCard && cards.length > 0) {
            setSelectedCard(cards[0])
        }
    }, [cards, selectedCard])

    return {
        cards, 
        onSubmit,
        cardError,
        isAddMode,
        selectedCard,
        setCardError,
        setSelectedCard,
        setIsAddMode,
        handleAddCard,
        isSubmitError,
        setIsSubmitError,
        handleRemoveCard
    }
}
