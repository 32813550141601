import { useTranslation } from "react-i18next";
import { ProgressArea } from "../../../types";
import { ProgressSlider } from "./ProgressSlider";
import { blocks } from "../../../utils/formLists";
import { ConfirmProgressModal } from "./ConfirmProgressModal";
import { useState } from "react";
import { Wrapper } from "./Wrapper";
import { FormHeader } from "./FormHeader";

interface ProgressBlockProps {
    onSelect: (val: ProgressArea) => void;
    selected: ProgressArea | null;
    isActive: (val: ProgressArea) => ProgressArea | undefined;
    isCompleted: (val: ProgressArea) => ProgressArea | undefined;
    onClose: () => void;
    onStart: () => void;
    onComplete: () => void;
    onCancel: () => void;
}

export const ProgressBlock = (props: ProgressBlockProps) => {
    const { t } = useTranslation();

    const { 
        onSelect, 
        selected, 
        isActive, 
        isCompleted,
        onCancel,
        onClose,
        onComplete,
        onStart
    } = props;

    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
        setIsOpen(prev => !prev);
    }

    return (
        <div className="flex w-full flex-col justify-start relative">
            <div 
                className="mb-[15px] flex justify-between cursor-pointer px-[25px] md:px-[80px] xl:px-[180px] items-start" 
                onClick={handleOpen}
            >
                <FormHeader title={t('addArea.planning_process')} />

                <img
                    src="/assets/icons/arrowDown.svg"
                    alt="arrow"
                    className={`cursor-pointer ${isOpen ? 'rotate-180' : ""}`}
                />
            </div>

            {!isOpen && (
                <Wrapper />
            )}
            
            {isOpen && (
                <>
                    <div className="px-[25px] md:px-[80px] xl:px-[180px]">
                        <p className="mb-[20px] max-w-[545px] text-[16px] font-normal text-[#9e9e9e] md:mb-[25px]">
                            {t('addArea.planning_process_description')}
                        </p>
                    </div>

                    <div className="flex flex-col justify-center gap-[9px] md:gap-[20px] lg:hidden block px-[25px] md:px-[80px] xl:px-[180px]">
                        {blocks.map((el) => (
                            <div
                                className={`border-box flex h-max flex-col rounded-[10px] px-[29px] py-[15px] text-start md:py-[30px]
                                border-[1px] border-[#111A2126] relative
                                ${
                                    selected?.id === el.id
                                        ? 'border-[2px] border-[#8EAD44]'
                                        : 'border-[1px] border-[#111A2126]'
                                }
                            `}
                                key={el.id}
                                onClick={() => onSelect(el)}
                            >
                                <div className="w-full flex justify-between">
                                    <img src={el.image} alt="block" className="mb-[20px] h-[40px]" />
                                    {!isActive(el) && !isCompleted(el) && (
                                        <div className="bg-grey h-[24px] w-[24px] rounded-[50%]">
                                            <img src='/assets/icons/done.svg' alt="progress status" />
                                        </div>
                                    )}

                                    {isCompleted(el) && (
                                        <div className="bg-[#8EAD44] h-[24px] w-[24px] rounded-[50%]">
                                            <img src='/assets/icons/done.svg' alt="progress status" />
                                        </div>
                                    )}

                                    {isActive(el) && (
                                        <div className="h-[24px] w-[24px] rounded-[50%]">
                                            <img src='/assets/icons/completed.svg' alt="progress status" />
                                        </div>
                                    )}
                                </div>

                                <p className="text-start text-[20px] font-medium md:text-[20px] mb-[15px] whitespace-nowrap">
                                    {t(el.title)}
                                </p>

                                <p className="text-[15px] font-normal">
                                    {t(el.description)}
                                </p>

                                {selected && selected.id === el.id && (
                                    <ConfirmProgressModal
                                        onClose={onClose}
                                        onStart={onStart}
                                        onComplete={onComplete}
                                        started={isActive(selected)}
                                        finished={isCompleted(selected)}
                                        onCancel={onCancel}
                                    />
                                )}
                            </div>
                        ))}
                    </div>

                    <ProgressSlider 
                        blocks={blocks}
                        onSelect={onSelect}
                        selected={selected}
                        isActive={isActive}
                        isCompleted={isCompleted}
                        onClose={onClose}
                        onStart={onStart}
                        onComplete={onComplete}
                        onCancel={onCancel}
                    />
                </>
            )}
        </div>
    )
}
