import { useTranslation } from 'react-i18next';
import { Icons } from '../../../components';
import { Features } from '../../../types';
import i18n from '../../../i18n';
import { useLanguage } from '../../../hooks';

interface PlanFeaturesProps {
    features: Features[];
}

export const PlanFeatures = ({ features }: PlanFeaturesProps) => {
  const { t } = useTranslation();
  const { selectedLanguage } = useLanguage({ i18n });

  return (
    <div>
        {features.map((feature) => {
            const isAreaFeature = feature.text.includes('pricing.area');

            return (
                <div className="mt-5 flex gap-x-3" key={feature.text}>
                    {feature.access ? (
                            <div className="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-primary">
                                <Icons name="tick" sx="text-white" />
                            </div>
                        ) : (
                            <div className="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-[#ff7043]">
                                <p className='text-white text-[20px] rotate-45'>+</p>
                            </div>
                        )}

                    <span className={`${isAreaFeature ? 'font-bold' : ''} text-[15px] text-left`}>
                        {isAreaFeature ? (
                            `${t(feature.text)} ${feature.size?.toLocaleString(selectedLanguage === 'de' ? 'de-DE' : 'en-US')} m²`
                        ) : (
                            t(feature.text)
                        )}
                    </span>
                </div>
            )
        })}
    </div>
  )
}
