import { useState } from "react";
import { useTranslation } from "react-i18next";
import { formatFileName } from "../../../helpers";
import { useAuth } from "../../../hooks";

interface UploadedFilesProps {
    files: string[];
    owner: boolean;
    areaId: string;
    isVisible: boolean;
  }

export const UploadedFiles = (props: UploadedFilesProps) => {
    const { files, owner, areaId, isVisible } = props;
    const [isOpen, setIsOpen] = useState(true);
    const { t } = useTranslation();
    const { isAdmin, isInvestor } = useAuth();

    const handleOpen = () => {
        setIsOpen(prev => !prev);
    }

    const checkFileName = (url: string) => {
        if (url.includes('kml')) {
            return `${t('area.areaOutline')}.kml`;
        }

        if (url.includes('area_details')) {
            return t('area.expose')
        }

        const file = formatFileName(url, areaId);

        return file;
    }

    return (
        <div>
            <div className="mb-[15px] flex justify-between font-semibold cursor-pointer" onClick={handleOpen}>
                <p>{t('area.files')}</p>
                <img
                    src="/assets/icons/arrowDown.svg"
                    alt="arrow"
                    className={`cursor-pointer ${isOpen ? 'rotate-180' : ""}`}
                />
            </div>

            {isOpen && (
                <div className="flex flex-wrap flex md:flex-row flex-col gap-[21px]">
                    {files.map((url, index) => (
                        (!owner && !isInvestor && !isAdmin && !isVisible ? (
                            <div 
                                className="flex gap-[18px] rounded-[8px] border-[1px] border-[#111A2126] p-[15px] blur-[5px] unselectable" 
                                key={url}
                            >
                                <img src="/assets/icons/files.svg" alt="file" />
                                <p>{checkFileName(url)}</p>
                            </div>
                        ) : (
                            <a href={url} download={`ProposalDocument${index + 1}.doc`} key={url}>
                                <div className={`flex gap-[18px] rounded-[8px] border-[1px] border-[#111A2126] p-[15px]`}
                                >
                                    <img src="/assets/icons/files.svg" alt="file" />
                                    <p>{checkFileName(url)}</p>
                                </div>
                            </a>
                        ))
                    ))}
                </div>
            )}
        </div>
    )
}
