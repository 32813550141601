import { useNavigate } from "react-router-dom";
import { Links } from "../../settings";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../components";
import { usePageDescription } from "../../hooks";
import { Helmet } from "react-helmet";

export const AboutUs = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  usePageDescription({ 
    title: t('title.about_us'),
    description: t('description.about_us')
  });

  const handleNavigate = useCallback(() => {
    navigate(Links.signup.index)
  }, [navigate])

  return (
    <>
       <Helmet>
            <meta name='title' content={t('title.about_us')} />
            <meta name='description' content={t('description.about_us')} />
        </Helmet>
        <div className="py-[50px] md:px-[100px] px-[25px]">
            <div className="flex flex-col gap-[15px] mb-[30px]">
                <h1 className="text-[38px] font-medium text-[#11470E]"> 
                    {t('about.title')}
                </h1>

                <p className="font-medium md:text-[27px]">
                    {t('about.offer')}
                </p>

                <p className="font-medium md:text-[27px] max-w-[900px]">
                    {t('about.welcome')}
                </p>
            </div>

            <div className="mb-[50px]">
                <p className="text-[30px] font-medium text-[#B0CC55] mb-[15px]">
                    {t('about.our_mission')}
                </p>

                <p className="mb-[30px]">
                    {t('about.mission')}
                </p>

                <p className="text-[30px] font-medium text-[#B0CC55] mb-[15px]">
                    {t('about.our_founder')}
                </p>

                <p className="mb-[30px]">
                    {t('about.founder')}            
                </p>


                <p className="text-[30px] font-medium text-[#B0CC55] mb-[15px]">
                    {t('about.our_approach')}
                </p>

                <p className="mb-[30px]">
                    {t('about.approach')}
                </p>


                <p className="text-[30px] font-medium text-[#B0CC55] mb-[15px]">
                    {t('about.our_community')}
                </p>

                <p>
                    {t('about.community')}
                </p>
            </div>

            <div className="mb-[30px] flex justify-center md:justify-start">
                <Button 
                    color="Primary" 
                    textColor="text-white"
                    m="md:min-w-[200px]"
                    onClick={handleNavigate}
                >
                    {t('about.join_us')}
                </Button>
            </div>

            <p className="md:text-[25px] text-[15px] font-medium text-[#11470E]">
                {t('about.sign_up')}
            </p>

            <p className="md:text-[25px] text-[15px] font-medium text-[#11470E] mb-[50px]">
                {t('about.together')}
            </p>

            <div className="flex justify-center items-center flex-col gap-[10px] text-[15px] md:text-[25px] font-medium text-[#B0CC55]">
                <p>{t('about.your_land')}</p>
                <p>{t('about.Enlapa')}</p>
            </div>
        </div>
    </>
  )
}
