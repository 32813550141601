import { useTranslation } from 'react-i18next';

export const FinishAreaModal = () => {
  const { t } = useTranslation();
  
  return (
      <div className="fixed bottom-[10px] flex w-full justify-center">
          <div className="z-10 max-w-[200px] rounded-[8px] bg-[#464547] p-[10px] text-center text-white">
              {t('finish')}
          </div>
      </div>
  )
}
