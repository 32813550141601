import { useTranslation } from "react-i18next";

export const InvestorPricing = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col py-[50px] md:px-[100px] px-[25px]">
        <h1 className="md:text-[38px] text-[25px] font-bold mb-[10px] text-secondary">
            {t('investors_pricing.title')}
        </h1>

        <p className="md:text-[27px] text-[20px] mb-[20px] font-bold">
            {t('investors_pricing.understanding')}
        </p>

        <p className="text-[17px] mb-[20px]">
            {t('investors_pricing.desc')}
        </p>

        <div className="w-full">
            <p className="text-[25px] font-bold text-secondary mb-[20px]">
                {t('investors_pricing.free')}
            </p>

            <p className="md:text-[20px] mb-[30px]">
                {t('investors_pricing.we_are_commited')}
            </p>

            <p className="text-[25px] font-bold text-secondary mb-[20px]">
                {t('investors_pricing.fees')}
            </p>

            <p className="md:text-[20px] font-bold mb-[20px]">
                {t('investors_pricing.fees_desc')}
            </p>

            <p className="md:text-[20px] mb-[20px]">
                <span className="text-secondary font-bold">{t('investors_pricing.success_fees')}</span>
                {t('investors_pricing.success_desc')}
            </p>

            <p className="md:text-[20px] mb-[30px]">
                <span className="text-secondary font-bold">{t('investors_pricing.agreements')}</span>
                {t('investors_pricing.agreements_desc')}
            </p>

            <p className="text-[25px] font-bold text-secondary mb-[20px]">
                {t('investors_pricing.solutions')}
            </p>

            <p className="md:text-[20px] mb-[30px]">
                {t('investors_pricing.solutions_desc')}
                <a href="mailto:info@enlapa.de" className="text-primary">info@enlapa.de</a> .
            </p>

            <p className="text-[25px] font-bold text-secondary mb-[20px]">
                {t('investors_pricing.commitment')}
            </p>

            <p className="md:text-[20px] mb-[15px]">
                {t('investors_pricing.commitment_desc')}
            </p>
        </div>
    </div>
  )
}
