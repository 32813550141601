import { Delete } from "@mui/icons-material";
import { UserInfo } from "../../../API"
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { useMemo } from "react";
import { useLanguage } from "../../../hooks";

interface MemberInfoProps {
    user: UserInfo;
    isAccepted?: boolean;
    onUserSet: (val: UserInfo | null) => void;
}

export const MemberInfo = ({
    user,
    isAccepted,
    onUserSet
}: MemberInfoProps) => {
    const { t } = useTranslation();
    const { selectedLanguage } = useLanguage({ i18n });

    const lastLogin = useMemo(() => {
        const currentDate = user?.lastloginDateTime && new Date(user?.lastloginDateTime).toDateString();
        const options: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        return currentDate && new Date(currentDate).toLocaleDateString(selectedLanguage === 'en' ? undefined : 'de-DE', options);
    }, [user, selectedLanguage])
    
    return (
        <div className="flex border-[1px]">
            <div className="flex w-[200px] [@media(max-width:580px)]:w-[80px] items-center justify-center border-r-[1px] py-[5px] text-[14px]">
                <div className="flex flex-col flex-wrap items-center">
                    <p className="md:text-[12px] text-[10px] text-center text-secondary">
                        {user.firstName} {user.lastName}
                    </p>
                    <p className="md:text-[12px] text-[10px] [@media(max-width:580px)]:max-w-[78px] break-words text-center">
                        {user.email}
                    </p>
                </div>
            </div>

            <div className="flex md:w-[170px] w-[110px] [@media(max-width:580px)]:w-[80px] 
                items-center justify-center border-r-[1px] py-[5px] text-center md:text-[12px] text-[10px]"
            >
                {isAccepted
                    ? t('organization.accepted')
                    : t('organization.waiting')
                }
            </div>

            <div className="flex md:w-[200px] w-[110px] [@media(max-width:580px)]:w-[80px] 
                items-center justify-center border-r-[1px] py-[5px] md:text-[12px] text-[10px] text-center"
            >
                {isAccepted && lastLogin ? lastLogin : ''}
            </div>

            <div
                className="flex w-[110px] [@media(max-width:580px)]:w-[80px] cursor-pointer items-center justify-center 
                    border-r-[1px] py-[5px] text-[14px] text-[#8EAD44]"
                onClick={() => onUserSet(user)}
            >
                <Delete />
            </div>
        </div>
    )
}
