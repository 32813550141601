import { useTranslation } from "react-i18next";
import { ProgressArea } from "../../../types";

interface ConfirmProgressModalProps {
    onClose: () => void;
    onStart: () => void;
    onComplete: () => void;
    onCancel: () => void;
    started: ProgressArea | null | undefined;
    finished: ProgressArea | null | undefined;
}

export const ConfirmProgressModal = (props: ConfirmProgressModalProps) => {
  const { t } = useTranslation();
  const { onClose, onStart, onComplete, started, finished, onCancel } = props;

  return (
    <div className='border-box top-0 lg:right-[10px] lg:left-[10px] right-0 left-0 bottom-0
        absolute z-10 bg-white lg:p-[25px] border-[1px] border-[#111A2126] 
        rounded-[10px] flex flex-col items-center justify-center px-[10px]'
    >
        <div className='w-full flex justify-end'>
            <button type="button" onClick={onClose}>
                <img src='/assets/icons/clear.svg' alt='close' />
            </button>
        </div>

        <div className='lg:px-[20px] md:py-[11.5px] py-[6px] mt-[-20px] lg:mt-0'>
            <div className='flex flex-col gap-[10px] w-full text-white text-[15px]'>
                <button 
                    className={`${!started && !finished 
                        && 'bg-[#8EAD44] text-black shadow-lg'} py-[10px] px-[15px] w-full border-[#8EAD44] text-[#8EAD44] border-[1px] rounded-[10px] shadow-md`}
                    type="button"
                    onClick={onCancel}
                >
                    {t('addArea.process_not_started')}
                </button>

                <button 
                    className={`${started
                        && 'bg-[#8EAD44] text-black shadow-lg'} py-[10px] px-[15px] w-full border-[#8EAD44] text-[#8EAD44] border-[1px] rounded-[10px] shadow-md`} 
                    type="button"
                    onClick={onStart}
                >
                    {t('addArea.process_started')}
                </button>

                <button 
                    className={`${finished
                        && 'bg-[#8EAD44] text-black shadow-lg'} py-[10px] px-[15px] w-full border-[#8EAD44] text-[#8EAD44] border-[1px] rounded-[10px] shadow-md`} 
                    type="button"
                    onClick={onComplete}
                >
                    {t('addArea.process_finished')}
                </button>
            </div>
        </div>
    </div>
  )
}
