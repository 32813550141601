/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://rhtab6wqarcjxbniocdokw5l5u.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-7bcrufdhm5hbdgwoqffnl4cbcq",
    "aws_cognito_identity_pool_id": "eu-central-1:d8b0e2f0-6f3a-4e62-a3b3-b7b8460d3bc7",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_HGRti6j6x",
    "aws_user_pools_web_client_id": "59ha8hd705rbffet396ccloh8t",
    "oauth": {
        "domain": "enlapanew.auth.eu-central-1.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "profile"
        ],
        "redirectSignIn": "https://www.enlapa.de/",
        "redirectSignOut": "https://www.enlapa.de/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "energieportal253ef4108781ef4fe3bb3c012726cb44f9192759-dev",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
