import { FormControlLabel, TableCell, TableRow } from "@mui/material";
import { Organization } from "../../../API";
import { useTranslation } from "react-i18next";
import { useOrganizationItem } from "../hooks";
import { AreaTypesModal } from "./AreaTypesModal";
import { IOSSwitch } from "../styled";
import { useSearch } from "../../SearchPage/hooks";
import { useEffect, useMemo } from "react";

interface OrganizationRowProps {
    organization: Organization | null;
}

export const OrganizationRow = ({
    organization
}: OrganizationRowProps) => {
    const { t } = useTranslation();
    const {
        owner,
        areas: investorAreas,
        members,
        isEditMode,
        qualifiedInvestor,
        exclusiveAccess,
        emailNotif,
        handleAccessChange,
        handleCheck,
        handleNotifChange,
        handleStatusChange,
        selectedOptions,
        setIsEditMode
    } = useOrganizationItem({ organization });

    const {
        areas,
        getAreas
    } = useSearch({});

    const openedAreas = useMemo(() => {
        const mergedArray = [...areas, ...investorAreas];

        const uniqueIds = new Map();

        mergedArray.forEach(obj => {
            uniqueIds.set(obj?.id, obj);
        });

        const uniqueArray = Array.from(uniqueIds.values());

        return uniqueArray.filter(el => !el.disabled);
      }, [areas])

    useEffect(() => {
        getAreas({
            OrganizationsWithApprovedAccess: {contains: organization?.id}
        })
    }, [organization])
    
    return (
        <>
            <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                className="cursor-pointer"
            >
                <TableCell sx={{ color: '#11470E', fontWeight: 500 }}>
                    {organization?.CompanyName}
                </TableCell>

                <TableCell>{organization?.websiteDomain}</TableCell>

                <TableCell>
                    <div className="flex w-full flex-col justify-center">
                        {owner?.email}
                    </div>
                </TableCell>

                <TableCell>
                    <div className="flex flex-col w-full flex-col justify-center">
                        {members.map(member => (
                            <p>{member?.email}</p>
                        ))}
                    </div>
                </TableCell>

                <TableCell>
                    <div className="flex w-full justify-center">
                        {openedAreas.length || 0}
                    </div>
                </TableCell>

                <TableCell>
                    <div className="flex w-full justify-center">
                        <FormControlLabel
                            control={
                                <IOSSwitch
                                    sx={{ m: 1 }}
                                    checked={qualifiedInvestor}
                                    onChange={handleStatusChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label=""
                            className="mb-[20px]"
                        />
                    </div>
                </TableCell>

                <TableCell>
                    <div className="flex w-full flex-col justify-center">
                        {selectedOptions?.map((type) => (
                            <p className="text-primary mb-[5px]">{t(type)}</p>
                        ))}

                        <div className="flex justify-center">
                            <div 
                                className="bg-secondary text-white px-[5px] rounded-[8px] w-fit"
                                onClick={() => setIsEditMode(true)}
                            >
                                {t('admin_dashboard.edit')}
                            </div>
                        </div>
                    </div>
                </TableCell>

                <TableCell>
                    <div className="flex w-full justify-center">
                        <FormControlLabel
                            control={
                                <IOSSwitch
                                    sx={{ m: 1 }}
                                    checked={exclusiveAccess}
                                    onChange={handleAccessChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label=""
                            className="mb-[20px]"
                        />
                    </div>
                </TableCell>

                <TableCell>
                    <div className="flex w-full justify-center">
                        <FormControlLabel
                            control={
                                <IOSSwitch
                                    sx={{ m: 1 }}
                                    checked={emailNotif}
                                    onChange={handleNotifChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label=""
                            className="mb-[20px]"
                        />
                    </div>
                </TableCell>
            </TableRow>

            {isEditMode && (
                <AreaTypesModal 
                    organization={organization}
                    onClose={setIsEditMode}
                    onCheck={handleCheck}
                    selected={selectedOptions}
                />
            )}
        </>
    )
}
