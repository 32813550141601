import { useTranslation } from "react-i18next";

export const OfferIntro = () => {
    const { t } = useTranslation();
    return (
        <div
            className="ml-[calc(50%-50vw)] flex w-screen justify-center 
                bg-primary px-[25px] md:px-[50px] py-[30px] text-center md:mb-[60px] md:py-[57px]"
        >
            <h1 className="max-w-[1050px] text-[24px] font-semibold tracking-[-4%] text-white md:text-[48px]">
                {t("addArea.title")}
            </h1>
        </div>
    )
}
