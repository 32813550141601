import { useTranslation } from "react-i18next";

interface TermsItemProps {
    title: string;
    number: number;
    items: string[];
}

export const TermsItem = (props: TermsItemProps) => {
  const {
    title,
    number,
    items
  } = props;

  const { t } = useTranslation();

  return (
    <div className="mb-[20px]">
        <p className="md:text-[30px] text-[20px] font-bold mb-[10px] uppercase">
            {`${number}. ${title}`}
        </p>

        {items.map((el, index) => (
            <p className="md:text-[18px] mb-[10px] ml-[20px]" key={el}>
              {`${number}.${index + 1}. ${t(el)}`}
            </p>
        ))}
    </div>
  )
}
