import { useTranslation } from "react-i18next";
import { useLanguage } from "../../../hooks";

export const LanguageSwitcher = () => {
    const { t, i18n } = useTranslation();
    const { selectedLanguage } = useLanguage({ i18n });

    const changeLanguageHandler = (lang: string) => {
        i18n.changeLanguage(lang);
    };

    return (
        <div className="flex gap-[10px] font-medium mr-[20px]">
            <p
                className={`${
                    selectedLanguage === 'en' &&
                    'border-b-[3px] border-secondary'
                } cursor-pointer md:text-[13px] text-[17px]`}
                onClick={() => changeLanguageHandler('en')}
            >
                {t('header.en')}
            </p>

            <p
                className={`${
                    selectedLanguage === 'de' &&
                    'border-b-[3px] border-secondary'
                } cursor-pointer md:text-[13px] text-[17px]`}
                onClick={() => changeLanguageHandler('de')}
            >
                {t('header.de')}
            </p>
        </div>
    )
}
