import { ChangeEvent } from "react";

interface SizeInputProps {
    value: string | number;
    val: 'min' | 'max';
    onChange: (e: ChangeEvent<HTMLInputElement>, val: string) => void;
    placeholder: string;
}

export const SizeInput = (props: SizeInputProps) => {
  const {
    value,
    val,
    onChange,
    placeholder
  } = props;

  return (
    <div className="flex items-end gap-[3px]">
        <input
            value={value}
            type="text"
            className="h-[40px] w-[80px] rounded-[6px] border-[1px] px-[5px] outline-0 md:w-[60px]"
            placeholder={placeholder}
            onChange={(e) => onChange(e, val)}
        />
        <p className="text-[10px] text-[#9e9e9e]">m²</p>
    </div>
  )
}
