import { useTranslation } from 'react-i18next';

export const JobOffer = () => {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col gap-[15px] px-[25px] py-[50px] md:px-[100px]">
            <h1 className="mb-5 text-xl font-bold">{t('job_offer.title')}</h1>
            <h2 className="font-bold">
                <span>{t('job_offer.location_article.title_bold')}</span>
                <span className="font-normal">
                    {t('job_offer.location_article.title_rest')}
                </span>
            </h2>
            <p className="font-bold">
                <span>{t('job_offer.location_article.paragraph_bold')}</span>
                <span className="font-normal">
                    {t('job_offer.location_article.paragraph_rest')}
                </span>
            </p>
            <h2 className="font-bold">{t('job_offer.role_article.title')}</h2>
            <p>{t('job_offer.role_article.paragraph')}</p>
            <h2 className="font-bold">{t('job_offer.tasks_article.title')}</h2>
            <ul className="ml-10 list-disc">
                <li>{t('job_offer.tasks_article.line1')}</li>
                <li>{t('job_offer.tasks_article.line2')}</li>
                <li>{t('job_offer.tasks_article.line3')}</li>
                <li>{t('job_offer.tasks_article.line4')}</li>
                <li>{t('job_offer.tasks_article.line5')}</li>
                <li>{t('job_offer.tasks_article.line6')}</li>
                <li>{t('job_offer.tasks_article.line7')}</li>
                <li>{t('job_offer.tasks_article.line8')}</li>
            </ul>
            <h2 className="font-bold">
                {t('job_offer.profile_article.title')}
            </h2>
            <ul className="ml-10 list-disc">
                <li>{t('job_offer.profile_article.line1')}</li>
                <li>{t('job_offer.profile_article.line2')}</li>
                <li>{t('job_offer.profile_article.line3')}</li>
                <li>{t('job_offer.profile_article.line4')}</li>
                <li>{t('job_offer.profile_article.line5')}</li>
            </ul>
            <h2 className="font-bold">{t('job_offer.offer_article.title')}</h2>
            <ul className="ml-10 list-disc">
                <li>{t('job_offer.offer_article.line1')}</li>
                <li>{t('job_offer.offer_article.line2')}</li>
                <li>{t('job_offer.offer_article.line3')}</li>
                <li>{t('job_offer.offer_article.line4')}</li>
                <li>{t('job_offer.offer_article.line5')}</li>
            </ul>
            <p>{t('job_offer.last_paragraph')}</p>
            <p>{t('job_offer.send_documents')}</p>
            <ul>
                <li className="font-bold">{t('job_offer.sign.line1')}</li>
                <li>{t('job_offer.sign.line2')}</li>
                <li>{t('job_offer.sign.line3')}</li>
            </ul>
            <a href="mailto:https://www.enlapa.de">https://www.enlapa.de</a>
        </div>
    );
};
