import { useTranslation } from "react-i18next"
import { Chat } from "./Chat"
import { useAuth } from "../../../hooks";
import { Client } from "@twilio/conversations";
import { Flaeche, UserInfo } from "../../../API";
import { Modal } from "../../../components";
import { Dispatch, SetStateAction } from "react";

interface ProjectModalsProps {
    openDialog: boolean;
    twilioClient: Client | null;
    area?: Flaeche | null;
    onClose: () => void;
    owner: UserInfo | null;
    image?: string;
    isListFull: boolean;
    isJoined: boolean;
    isInList?: boolean;
    onJoin: () => Promise<void>;
    onModalClose: () => void;
    onListFull: Dispatch<SetStateAction<boolean>>;
    isTypeError: boolean;
}

export const ProjectModals = (props: ProjectModalsProps) => {
  const { 
    openDialog,
    twilioClient,
    area,
    onClose,
    onJoin,
    owner,
    image,
    isListFull,
    isJoined,
    isInList,
    isTypeError,
    onModalClose,
    onListFull
} = props;

  const { t } = useTranslation();
  const { loggedIn } = useAuth();

  return (
    <>
        <div className="relative ml-[calc(50%-50vw)] h-full w-screen bg-[lightgray]">
                <div
                    className="z-5 absolute h-[200px] w-full"
                    style={{
                        background:
                            'linear-gradient(180deg, #FFFF 0%, rgba(243, 243, 243, 0.76) 40.12%, rgba(243, 243, 243, 0.00) 89.67%)',
                    }}
                ></div>
                <div className="z-[1] h-[451px] w-full bg-solarCell bg-cover bg-center  bg-no-repeat md:h-[857px]"></div>
            </div>

            {openDialog && (
                <Chat 
                    onClose={onClose} 
                    twilioClient={twilioClient} 
                    areaId={area?.id} 
                    ownerEmail={owner?.id}
                    areaImage={image}
                    areaName={area?.address_state || area?.address_country}
                    uniqueName={`${loggedIn.sub}_${area?.id}`}
                    area={area}
                />
            )}

            {isListFull && (
                <Modal 
                    isCancel={true}
                    buttonText="area.join_waitlist"
                    text="area.maximum_number"
                    onClick={onJoin} 
                    onCancel={onListFull}
                />
            )}

            {isTypeError && (
                <Modal 
                    text="area.types"
                    buttonText="Ok"
                    onClick={onModalClose} 
                />
            )}

            {isJoined && (
                <div className='fixed bottom-[10px] right-[10px] bg-primary 
                    rounded-[4px] px-[20px] py-[10px] max-w-[200px] text-center'
                >
                    {!isInList ? t("area.thanks") : t("area.in_list")}
                </div>
            )}
    </>
  )
}
