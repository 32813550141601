import { FormHeader } from '../../Offer/components';
import { LabelUpInput } from '../../../components';
import { TOfferAdsAreaSchema } from '../../../helpers';
import { FieldErrors, FieldValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { countriesAddArea, federalStates } from '../../../utils/formLists';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import { useEffect, useMemo } from 'react';
import { AreaFeatures } from '../../../types';
import { useLanguage } from '../../../hooks';

interface ThirdStepForm {
    country?: string;
    features?: AreaFeatures | null;
    register: UseFormRegister<TOfferAdsAreaSchema>;
    setValue: UseFormSetValue<TOfferAdsAreaSchema>;
    errors: FieldErrors<TOfferAdsAreaSchema>;
    fieldsData: FieldValues | null;
}

export const ThirdStepForm = ({
    country,
    features,
    register,
    setValue,
    errors
}: ThirdStepForm) => {
    const { t } = useTranslation();
    const { selectedLanguage } = useLanguage({ i18n });

    const countryName = useMemo(() => {
        const isCountry = countriesAddArea.some(el => el.value === `addArea.countries.${features?.country}`);

        return isCountry ? `addArea.countries.${features?.country}` : (
            features?.country?.includes('addArea') ? features.country : ''
        );
    }, [features])

    const stateName = useMemo(() => {
        const isState = federalStates.some(el => el.value === `addArea.states.${features?.state}`);

        return isState ? `addArea.states.${features?.state}` : (
            features?.state?.includes('addArea') ? features.state : ''
        );;
    }, [features])

    useEffect(() => {
        setValue('state', stateName);
        setValue('country', countryName);
    }, [features])

    return (
        <>
            <div className="flex w-full flex-col flex-wrap items-start px-[25px] md:flex-row md:px-[80px] xl:px-[180px]">
                <FormHeader title={t('addArea.location')} />

                <div
                    className={`flex ${
                        country === 'addArea.countries.Germany'
                            ? 'w-full'
                            : 'w-full md:w-[39%]'
                    } mb-[30px] flex-col items-center gap-[30px] md:flex-row`}
                >
                    <div
                        className={`w-full ${
                            country === 'addArea.countries.Germany'
                                ? 'w-full md:mr-0 md:w-[50%]'
                                : 'md:mr-[30px]'
                        }`}
                        key="country"
                    >
                        <LabelUpInput
                            name="country"
                            star={true}
                            value={countryName}
                            starStyle={
                                selectedLanguage === 'en'
                                    ? 'left-[120px]'
                                    : 'left-[60px]'
                            }
                            type="dropdown"
                            options={countriesAddArea}
                            setValue={setValue}
                            register={register}
                            error={!country}
                            placeholder={t('addArea.placeholders.country')}
                        />
                    </div>

                    {(country === 'addArea.countries.Germany') && (
                        <div className="w-full md:w-[50%]" key="state">
                            <LabelUpInput
                                name="state"
                                star={true}
                                value={stateName}
                                starStyle={'left-[115px]'}
                                type="dropdown"
                                options={federalStates}
                                setValue={setValue}
                                register={register}
                                error={
                                    errors['state' as keyof TOfferAdsAreaSchema]
                                }
                                placeholder={t(
                                    'addArea.placeholders.federal_state'
                                )}
                            />
                        </div>
                    )}
                </div>

                <div className='flex gap-[15px] w-full'>
                    <div
                        className="mb-[30px] mb-[30px] w-full md:mb-0 md:mr-[30px] md:w-[16%]"
                        key="postal"
                    >
                        <LabelUpInput
                            name="postalCode"
                            star={true}
                            value={features?.postCode}
                            starStyle={'left-[105px] md:hidden lg:block'}
                            setValue={setValue}
                            register={register}
                            error={
                                errors['postalCode' as keyof TOfferAdsAreaSchema]
                            }
                            placeholder={t('addArea.placeholders.postal_code')}
                        />
                    </div>

                    <div className="mb-[30px] w-full md:w-[40%]" key="city">
                        <LabelUpInput
                            name="city"
                            value={features?.city}
                            star={true}
                            starStyle={
                                selectedLanguage === 'en'
                                    ? 'left-[95px]'
                                    : 'left-[60px]'
                            }
                            placeholder={t('addArea.placeholders.city')}
                            register={register}
                            setValue={setValue}
                            error={errors['city' as keyof TOfferAdsAreaSchema]}
                        />
                    </div>
                </div>
            </div>

            <div className="mb-[30px] flex w-full flex-col items-start gap-[30px] 
                px-[25px] md:flex-row md:px-[80px] xl:px-[180px]"
            >
                <div className="flex w-full flex-col gap-[20px] md:w-[50%]">
                    <div key="street">
                        <LabelUpInput
                            name="street"
                            value={features?.street}
                            star={features?.street ? false : true}
                            starStyle={
                                selectedLanguage === 'en'
                                    ? 'left-[295px]'
                                    : 'left-[325px] md:hidden lg:block block'
                            }
                            placeholder={t('addArea.placeholders.street')}
                            register={register}
                            setValue={setValue}
                            error={
                                !features?.street &&
                                errors['street' as keyof TOfferAdsAreaSchema]
                            }
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
