import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import { useLanguage } from '../../../hooks';

interface SwitcherProps {
    isYearly: boolean;
    setIsYearly: (val: boolean) => void;
}

export const Switcher = ({ isYearly, setIsYearly}: SwitcherProps) => {
  const { t } = useTranslation();
  const { selectedLanguage } = useLanguage({ i18n });

  return (
    <div
        style={{
            justifyContent: isYearly ? 'flex-end' : 'flex-start' 
        }}
        className="mt-[41px] flex h-[65px] w-[308px] items-center rounded-full bg-grey relative cursor-pointer"
        onClick={() => setIsYearly(!isYearly)}
    >
        <motion.div layout className='mx-[10px] h-[51px] w-[140px] rounded-full bg-secondary' />

        <div className='absolute flex justify-between w-full px-[40px] text-[20px]'>
            <p className={`${!isYearly ?'text-white' : 'text-secondary'} transition-all`}>
                {t("pricing.monthly")}
            </p>
            
            <p className={`${selectedLanguage === 'en' ? 'mr-[15px]' : '' } 
                ${isYearly ?'text-white' : 'text-secondary'} transition-all`}
            >
                {t("pricing.yearly")}
            </p>
        </div>
    </div>
  )
}
