import { useCallback, useContext, useMemo } from "react";
import { Button } from "../../components/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { Links } from "../../settings";
import { ThanksPageContext } from "../../contexts/ThanksPageContext";
import { calculateResult } from "../../helpers/calculateResult";
import { useTranslation } from "react-i18next";

export const ThankYou = () => {
  const navigate = useNavigate();
  const { data } = useContext(ThanksPageContext);
  const { t } = useTranslation();

  const location = useLocation();
  
  const handleNavigate = useCallback(() => {
    navigate(Links.offer.index);
  }, [navigate])

  const result = useMemo(() => calculateResult(data), [data]);

  return (
    <>
        <div
            className="mb-[35px] ml-[calc(50%-50vw)] flex w-screen justify-center 
                bg-primary px-[25px] md:px-[50px] py-[30px] text-center md:mb-[60px] md:py-[57px]"
        >
            <h1 className="max-w-[1050px] text-[24px] font-semibold tracking-[-4%] text-white md:text-[48px]">
                {t('facebook_offer.thanks')}
            </h1>
        </div>
        <div className="pb-[50px] lg:px-[160px] px-[25px] w-full flex flex-col justify-center items-center text-center">
            <p className="md:text-[30px] text-[18px] font-semibold text-[#B0CC55]">
                {t('thank_you.your_area')}
            </p>

            {data && (
                <>
                    <p className="text-[100px] font-semibold text-[#11470E] mb-[0px]">
                        {result}
                    </p>

                    <p className="text-[30px] font-semibold text-[#11470E] mb-[10px]">
                        {data.type.toLowerCase() === "roof" || data.type.toLowerCase() === "dach" ? (
                            t('thank_you.hours')
                        ) : (
                            t('thank_you.days')
                        )} {t('thank_you.every_year')}
                    </p>
                </>
            )}

            {location.state?.path === '/offer-meta-ads' ? (
                <Button
                    color={'Secondary'}
                    m="mt-[25px] md:mt-[60px] font-medium"
                    textColor={'text-black'}
                    width="w-[228px] md:w-[225px]"
                    onClick={() => navigate('/')}
                >
                    {t('facebook_offer.home')}
                </Button>
            ) : (
                <Button
                    color={'Secondary'}
                    m="mt-[25px] md:mt-[60px] font-medium"
                    textColor={'text-black'}
                    width="w-[228px] md:w-[225px]"
                    onClick={handleNavigate}
                >
                    {t('thank_you.add_more')}
                </Button>
            )}
        </div>
    </>
  )
}
