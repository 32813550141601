/** @format */

import { Button } from '../Button';
import { Heading } from '../Title';

interface CallToActionProps {
    title: string;
    btnTitle: string;
    bgColor?: string;
    styles?: string;
    onClick?: () => void;
    tag?: string;
}

export const CallToAction = (props: CallToActionProps) => {
    const { 
        title, 
        btnTitle, 
        bgColor, 
        styles, 
        onClick, 
        tag="h2" 
    } = props;

    return (
        <div className={`rounded-3xl ${bgColor ? bgColor : ' bg-primary '} ${styles}`}>
            <div className="px-[25px] py-[25px] pb-[35px] text-center md:m-auto md:max-w-[805px] md:py-[70px]">
                <Heading
                    title={title}
                    size="text-[32px] md:text-[65px] break-words"
                    weight="font-bold"
                    leading="leading-[36px] md:leading-[75px] -tracking-[5%]"
                    tag={tag}
                />

                <Button
                    color={bgColor ? 'Secondary' : 'Primary'}
                    m="mt-[25px] md:mt-[60px]"
                    textColor={`${bgColor ? 'text-black' : 'text-white'}`}
                    width="w-[228px] md:w-[225px]"
                    onClick={onClick}
                >
                    {btnTitle}
                </Button>
            </div>
        </div>
    )
}
