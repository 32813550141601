import { Conversation, Message } from '@twilio/conversations';
import { useEffect, useRef } from 'react';
import { useAuth, useLanguage } from '../../hooks';
import { MessageItem } from '../../pages/Chat/components/Message';
import i18n from '../../i18n';
interface ChatMessagingProps {
    sx: string;
    messages?: Message[];
    image?: string;
    owner?: string;
    selected?: Conversation | null;
    onDelete: (message: Message) => void;
}

export const ChatMessaging = (props: ChatMessagingProps) => {
    const { sx, messages, image, owner, onDelete, selected } = props;
    const scrollDiv = useRef<HTMLDivElement | null>(null);
    const { loggedIn } = useAuth();
    const { selectedLanguage } = useLanguage({ i18n });

    const scrollToBottom = () => {
        if (scrollDiv.current) {
            scrollDiv.current.scrollTop = scrollDiv.current.scrollHeight;
        }
    };

    const readAll = async () => {
        await selected?.setAllMessagesRead();
    }

    const formatDate = (date: Date) => {
        const options: Intl.DateTimeFormatOptions = { 
            weekday: 'long', 
            year: 'numeric', 
            month: 'long', 
            day: 'numeric' 
        };
        
        return new Date(date).toLocaleDateString(
            selectedLanguage === 'en' 
            ? undefined 
            : 'de-DE', 
        options);
    };

    useEffect(() => {
        scrollToBottom();
        readAll();
    }, [messages]);

    return (
        <div className={`overflow-y-auto px-[29px] py-[18px] ${sx}`} ref={scrollDiv}>
            {messages?.map((el, i) => {
                const author = el.author !== owner && loggedIn.id === el.author;
                const currentDate = el.dateCreated && new Date(el.dateCreated).toDateString();
                const previousMessage = i > 0 && messages[i - 1]?.dateCreated
                    ? new Date(messages[i - 1].dateCreated as Date).toDateString()
                    : '';

                return (
                    <div key={el.index}>
                        {currentDate !== previousMessage && (
                            <div className="text-center mb-2 text-gray-500 text-[14px]">
                                {el.dateCreated && formatDate(el.dateCreated)}
                            </div>
                        )}

                        <MessageItem 
                            author={author}
                            message={el}
                            image={image}
                            key={`${el.body}_${i}`}
                            onDelete={onDelete}
                        />
                    </div>
                )
            })}
        </div>
    )
}
