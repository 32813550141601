import { useState } from "react";
import { ChartBar } from "./ChartBar";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { useLanguage } from "../../../hooks";

interface EnergyProductionProps {
    data: number[];
    total?: number | null;
}

export const EnergyProduction = ({ data, total }: EnergyProductionProps) => {
    const { t } = useTranslation();
    const { selectedLanguage } = useLanguage({ i18n });

    const [isOpen, setIsOpen] = useState(true);

    const handleOpen = () => {
        setIsOpen((prev) => !prev);
    };

    return (
        <div>
            <div
                className="mb-[15px] flex cursor-pointer justify-between font-semibold"
                onClick={handleOpen}
            >
                <p>{t('area.energy')}</p>
                <img
                    src="/assets/icons/arrowDown.svg"
                    alt="arrow"
                    className={`cursor-pointer ${isOpen ? 'rotate-180' : ''}`}
                />
            </div>

            {isOpen && (
                <div className="flex flex-col justify-between">
                    <div className="flex gap-[5px] items-center mb-[20px]">
                        <p className="font-semibold text-secondary text-[14px]">{t('area.annual')}</p>
                        <span className="font-semibold text-[#9e9e9e] text-[15px]">{t("area.up_to")} {total} {t('area.year')}</span>
                    </div>

                    <ChartBar data={data} />

                    <div className="mt-[20px] text-[12px] text-gray-400 hover:text-primary transition-all">
                        <a href={`${selectedLanguage === 'en' 
                            ? 'https://re.jrc.ec.europa.eu/pvg_tools/en/' 
                            : 'https://re.jrc.ec.europa.eu/pvg_tools/de/'}`
                        }>
                            {t('area.source')}
                        </a>
                    </div>
                </div>
            )}
        </div>
    )
}