import { useTranslation } from "react-i18next";

interface ArticleImageProps {
    link?: string;
    source: string;
}

export const ArticleImage = ({ 
    link, 
    source 
}: ArticleImageProps) => {
  const { t } = useTranslation();

  return (
    <div>
        <img
            src={source}
            alt="Article"
            className="mb-[15px] max-h-[650px] w-full"
        />

        {link && (
            <span className="text-[13px] font-medium text-gray-500">
                <a href={link}>
                    {t('articles.source')}
                </a>
            </span>
        )}
    </div>
  )
}
