import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../hooks";
import { UserInfo } from "../../../API";

interface ContactProps {
    owner: boolean;
    contacts: UserInfo | null;
    isVisible: boolean;
    isOpenContacts: boolean;
    onOpen: () => void;
}

export const Contact = (props: ContactProps) => {
    const { 
        owner, 
        contacts, 
        isVisible, 
        onOpen, 
        isOpenContacts
    } = props;

    const { t } = useTranslation();
    const { isAdmin, isInvestor } = useAuth();

    const [isOpen, setIsOpen] = useState(true);

    const handleOpen = () => {
        setIsOpen((prev) => !prev);
    }

    return (
        <div>
            <div
                className="mb-[15px] flex cursor-pointer justify-between font-semibold"
                onClick={handleOpen}
            >
                <p>{t('area.contact')}</p>
                <img
                    src="/assets/icons/arrowDown.svg"
                    alt="arrow"
                    className={`cursor-pointer ${isOpen ? 'rotate-180' : ''}`}
                />
            </div>

            {isOpen && (
                <div className="flex flex-col gap-[10px]">
                    {!owner && !isAdmin && !isVisible ? (
                        <div className="flex flex-col gap-[10px] font-medium blur-[10px] unselectable"
                        >
                            <p>
                                {t('placeholders.full_name')}: {" "} 
                                <span className="text-[#9e9e9e]">
                                    ########### ##############
                                </span>
                            </p>
        
                            <p>
                                {t('placeholders.email')}: {" "}
                                <span className="text-[#9e9e9e]">
                                    ###############
                                </span>
                            </p>
        
                            <p className="mb-[20px]">
                                {t('placeholders.phone_number')}: {" "}
                                <span className="text-[#9e9e9e]">
                                    {contacts?.phone && (
                                        '0000000000000000'
                                    )}
                                </span>
                            </p>
                        </div>
                    ) : (
                        <div className="flex flex-col gap-[10px] font-medium">
                            <p>
                                {t('placeholders.full_name')}: {" "} 
                                <span className="text-[#9e9e9e]">
                                    {contacts?.firstName} {contacts?.lastName} 
                                </span>
                            </p>

                            <p>
                                {t('placeholders.email')}: {" "}
                                <span className="text-[#9e9e9e]">
                                    <a href={`mailto:${contacts?.email}`}>{contacts?.email}</a>
                                </span>
                            </p>

                            <p className="mb-[20px]">
                                {t('placeholders.phone_number')}: {" "}
                                <span className="text-[#9e9e9e]">
                                    {contacts?.phone && (
                                        <a href={`tel:${contacts.phone}`}>{contacts.phone}</a>
                                    )}
                                </span>
                            </p>
                        </div>
                    )}

                    {process.env.REACT_APP_Deactivate_Chat === '0' && (
                        !owner && isInvestor && (
                            <button 
                                className={`flex justify-center rounded-[10px] text-black
                                    text-[18px] font-medium flex gap-[18px] p-[15px] w-fit 
                                    ${isOpenContacts ? 'bg-[#B0CC55]' : 'bg-[#bdbdbd]'}`
                                }
                                onClick={onOpen}
                                disabled={!isOpenContacts}
                            >
                                <p>{t('area.write')}</p>
                            </button>
                        )
                    )}
                </div>
            )}
        </div>
    )
}
