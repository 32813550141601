import { GraphQLQuery } from '@aws-amplify/api';
import { API, graphqlOperation } from 'aws-amplify';
import { useCallback } from 'react';
import { UpdateFlaecheMutation, UpdateUserInfoMutation, UserInfo } from '../../../API';
import { updateFlaeche, updateUserInfo } from '../../../graphql/mutations';
import { useProcessTry } from '../../../hooks';

interface Options {
  user?: UserInfo | null;
  getUserInfo: () => Promise<void>;
}

export const useHandleArea = ({ user, getUserInfo }: Options) => {
    const { handleCreateError } = useProcessTry();

    const deactivateArea = useCallback(async (id: string | undefined) => {
        const disabledArea = {
            disabled: true,
            disabledDate: new Date(),
            id: id || '',
        }

        try {
          await API.graphql<GraphQLQuery<UpdateFlaecheMutation>>(
            graphqlOperation(updateFlaeche, { input: disabledArea })
          );

          window.location.reload();
                
          setTimeout(() => {
          window.scrollTo({ top: 0 });
          }, 500)
        } catch (error: any) {
          console.log(error);

          const errorData = {
              userInfoSub: user?.sub,
              requestBody: JSON.stringify(disabledArea),
              errorMsg: error.message as string,
              process: 'Deactivate area',
              DateTimeUnix: Math.floor(new Date().getTime() / 1000),
          };

          handleCreateError(errorData);
        }
    }, [])

    const markAsFavorite = async (id: string | undefined) => {
        if (user) {
            const areas = user?.favoriteFlaeches
                ? [...user?.favoriteFlaeches, id]
                : [id]

            const editingUser = {
                sub: user.sub,
                favoriteFlaeches: areas,
                id: user.id,
            }

            try {
              await API.graphql<GraphQLQuery<UpdateUserInfoMutation>>(
                  graphqlOperation(updateUserInfo, { input: editingUser })
              )

              getUserInfo();
            } catch (error: any) {
              console.log("error:", error);

              const errorData = {
                userInfoSub: user?.sub,
                requestBody: JSON.stringify(editingUser),
                errorMsg: error.message as string,
                process: 'Mark areas as favorite',
                DateTimeUnix: Math.floor(new Date().getTime() / 1000),
              };
    
              handleCreateError(errorData);
            }
        }
    }

    const removeFavorite = async (id: string | undefined) => {
        if (user) {
            const areas =
                user?.favoriteFlaeches &&
                user?.favoriteFlaeches?.filter((el: string) => el !== id)

            const editingUser = {
                sub: user.sub,
                favoriteFlaeches: areas,
                id: user.id,
            }

            try {
              await API.graphql<GraphQLQuery<UpdateUserInfoMutation>>(
                  graphqlOperation(updateUserInfo, { input: editingUser })
              )

              getUserInfo();
            } catch (error: any) {
              console.log("error:", error);

              const errorData = {
                userInfoSub: user?.sub,
                requestBody: JSON.stringify(editingUser),
                errorMsg: error.message as string,
                process: 'Delete area from favorite',
                DateTimeUnix: Math.floor(new Date().getTime() / 1000),
              };
    
              handleCreateError(errorData);
            }
        }
    }

    return {
      markAsFavorite,
      deactivateArea,
      removeFavorite
    }
}
