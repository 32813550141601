import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { SocialButton } from "./SocialButton";
import { useCallback } from "react";
import facebook from '../../../assets/icons/facebook.svg';
import mail from '../../../assets/icons/mail.svg';
import { useTranslation } from "react-i18next";

interface SocialLoginButtonsProps {
    onSocialLogin: (provider: CognitoHostedUIIdentityProvider) => void;
}

export const SocialLoginButtons = ({ onSocialLogin }: SocialLoginButtonsProps) => {
    const { t } = useTranslation();

    const loginWithFacebook = useCallback(() => {
        onSocialLogin(CognitoHostedUIIdentityProvider.Facebook);
    }, [])

    const loginWithGoogle = useCallback(() => {
        onSocialLogin(CognitoHostedUIIdentityProvider.Google);
    }, [])

    return (
        <div className="flex flex-col items-center gap-[18px] w-full md:w-fit">
            <SocialButton 
                onClick={loginWithFacebook} 
                name="facebook" 
                icon={facebook}
                title={t('facebook_login')}
            />

            <SocialButton 
                onClick={loginWithGoogle} 
                name="mail" 
                icon={mail}
                title={t('google_login')}
            />
        </div>
    )
}
