import { Flaeche } from "../API";

export const getRole = ( area: Flaeche | undefined | null ) => {
    if (area?.identifyAsDeveloper === 'true') {
        return 'addArea.role_items.project_developer';
    }

    if (area?.identifyAsInvestor === 'true') {
        return 'addArea.role_items.investor';
    }

    if (area?.identifyAsLandlord === 'true') {
        return 'addArea.role_items.landowners';
    }

    if (area?.identifyAsOwner === 'true') {
        return 'addArea.role_items.commisioned';
    }

    if (area?.identifyAsOther === 'true') {
        return 'addArea.role_items.other';
    }
}