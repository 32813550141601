import { useEffect, useMemo, useRef, useState } from "react";
import { Heading, SliderArrows } from "../../../components";
import { recentArticles } from "../../../utils";
import { BlogCard } from "../../Blog/components/BlogCard";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Links } from "../../../settings";

export const BlogSection = () => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showLeftOverlay, setShowLeftOverlay] = useState(false);
  const [showRightOverlay, setShowRightOverlay] = useState(false);

    const handleNavigate = () => {
        navigate(Links.blog.index);
    }

  const articlesToShow = useMemo(() => {
    return recentArticles.slice(0, 6);
  }, [recentArticles])

  const lastArticle = useMemo(() => {
    return articlesToShow[articlesToShow.length - 1];
  }, [articlesToShow]);

  useEffect(() => {
    const handleScroll = () => {
      if (sliderRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = sliderRef.current;
        setShowLeftOverlay(scrollLeft > 0);
        setShowRightOverlay(scrollLeft < scrollWidth - clientWidth);
      }
    };

    if (sliderRef.current) {
      sliderRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (sliderRef.current) {
        sliderRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <div>
     <div className="w-full flex justify-center md:pt-[50px] pt-[20px]">
      <div className="flex md:justify-between justify-center w-full px-[65px] max-w-[1450px]">
            <Heading
                title={t('home.footer.for_owners.blog')}
                size="text-[32px] text-center md:text-left md:text-[38px] md:max-w-[60%] lg:max-w-[100%]"
                weight="font-medium"
            />
        </div>
     </div>

      <div className="flex justify-center relative">
        <div>
          <SliderArrows 
            sliderRef={sliderRef} 
            sx="md:right-[70px] [@media(min-width:1350px)]:right-[110px] right-[20px] top-[-40px]" 
            value={340} 
          />
        </div>

        <div 
          ref={sliderRef}
          className="relative mt-[50px] overflow-x-auto smooth-scroll slider items-center blog-section"
          style={{ overflowX: 'auto' }}
        >
          <div className="flex md:px-[55px] px-[25px]">
            {articlesToShow.map(article => (
              <BlogCard 
                article={article} 
                key={article.id} 
                isLast={lastArticle.id === article.id}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

