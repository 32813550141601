export const filterOptions = [
    {
        "value": "all",
        "label": "admin_dashboard.all"
    },
    {
        "value": "active",
        "label": "admin_dashboard.active"
    },
    {
        "value": "deactivated",
        "label": "admin_dashboard.deactivated"
    }
];

export const adminUsersOptions = [
    {
        label: 'admin_dashboard.all',
        value: 'all'
    },
    {
        label: 'admin_dashboard.regular',
        value: 'regular user'
    },
    {
        label: 'admin_dashboard.regular_active',
        value: 'regular user with active area'
    },
    {
        label: 'admin_dashboard.investor',
        value: 'investor'
    },
    {
        label: 'admin_dashboard.admin',
        value: 'admin'
    },
    {
        label: 'admin_dashboard.user_organization',
        value: 'user with organization'
    },
]