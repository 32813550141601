import { useEffect, useState } from 'react'
import { Flaeche, GetFlaecheQuery, GetOrganizationQuery, GetOrganizationbyOwnerQuery, ListFlaechesQuery, Organization, UserInfo } from '../../../API';
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLQuery } from '@aws-amplify/api';
import { getOrganizationbyOwner, getFlaeche, getOrganization, listFlaeches } from '../../../graphql/queries';
import { useSearch } from '../../SearchPage/hooks';

interface Options {
    user?: UserInfo | null;
}

export const useOrganizationAreas = ({ user }: Options) => {
    const [organization, setOrganization] = useState<Organization | null>();
    const [organizationAreas, setOrganizationAreas] = useState<(Flaeche | null | undefined)[]>([]);

    const {
      areas,
      getAreas
    } = useSearch({});

    const getUserOrganization = async () => {
        if (!user) return;
    
        const { data } = await API.graphql<GraphQLQuery<GetOrganizationbyOwnerQuery>>(
          graphqlOperation(getOrganizationbyOwner, { OwnerOfOrganization: user.sub })
        );
    
        const ownerOf = data?.getOrganizationbyOwner?.items 
          && data?.getOrganizationbyOwner?.items.find(el => !el?.disabledOrganization);
    
        let member;
    
        if (user.MemberofOrganizationID) {
          const { data: memberOf } = await API.graphql<GraphQLQuery<GetOrganizationQuery>>(
            graphqlOperation(getOrganization, { id: user.MemberofOrganizationID })
          );
    
          member = memberOf?.getOrganization;
        }
    
        setOrganization(ownerOf ?? member);
      }
    
      const getFleaches = async () => {
        if (!organization) return;
    
        const areasIds = organization.AccessToAreaID?.map(el => el.flaecheId);
    
        if (!areasIds) return;
    
        const areasQuery = areasIds?.map(
          (id) => {
              return API.graphql<
                  GraphQLQuery<GetFlaecheQuery>
              >(
                  graphqlOperation(getFlaeche, {
                      id,
                  })
              )
          }
        )
    
        const areasData = await Promise.all(areasQuery)
    
        const areasInfoArray = areasData.map(
          (response) => response.data?.getFlaeche
        ).filter(area => !area?.disabled)
    
        setOrganizationAreas(areasInfoArray)
      }
    
      useEffect(() => {
        getUserOrganization();
      }, [user])
    
      useEffect(() => {
        getFleaches();
        getAreas({
          disabled: { eq: false },
          InvestorsWithApprovedAccess: { contains: organization?.OwnerOfOrganization || user?.sub},
        });
      }, [organization])

      useEffect(() => {
        const mergedArray = [...areas, ...organizationAreas];

        const uniqueIds = new Map();

        mergedArray.forEach(obj => {
            uniqueIds.set(obj?.id, obj);
        });

        const uniqueArray = Array.from(uniqueIds.values());

        setOrganizationAreas(uniqueArray)
      }, [areas])

      return {
        organizationAreas,
        organization
      }
}
