import { Link } from 'react-router-dom';
import { Links } from '../../../settings';
import { Button } from '../../../components';
import { Article } from './BlogCard';
import { useTranslation } from 'react-i18next';

interface MainArticleProps {
    mainPost: Article | null;
}

export const MainArticle = ({ mainPost}: MainArticleProps) => {
    const { t } = useTranslation();

    return (
        <>
            {mainPost && (
               <div className="flex-1 cursor-pointer rounded-[12px] shadow-md transition-all hover:shadow-2xl h-fit">
                    <Link to={`${Links.blog.index}/${mainPost.id}`}>
                        {mainPost.id === 'ENLAPA_gibt_Mitgliedschaft_beim_Bundesverband_Solarwirtschaft_e.V._bekannt' ? (
                            <div className="mb-[25px] max-h-[350px] w-full rounded-t-[12px] flex justify-center">
                                <img
                                    src={mainPost.bigImage}
                                    alt="Article"
                                    className="md:max-h-[350px] max-h-[300px] rounded-t-[12px]"
                                />
                            </div>
                        ) : (
                            <img
                                src={mainPost.bigImage}
                                alt="Article"
                                className="mb-[25px] max-h-[350px] w-full rounded-t-[12px]"
                            />
                        )}
            
                        <div className="flex flex-col gap-[20px] md:px-[25px] px-[15px]">
                            <p className="text-[14px] uppercase text-gray-600">
                                {t(mainPost.published)}
                            </p>
            
                            <hr className="w-full" />
            
                            <h2 className='text-[20px] font-medium'>{t(mainPost.title)}</h2>
            
                            <p className='md:text-[17px] text-[14px]'>{t(mainPost.description)}</p>
            
                            <div className='flex justify-center md:justify-start'>
                                <Button
                                    color="Secondary"
                                    sx="mb-[25px] hover:shadow-xl transition-all"
                                >
                                    {t('articles.read')}
                                </Button>
                            </div>
                        </div>
                    </Link>
               </div>
            )}
        </>
    )
}
