import { ChangeEvent, MutableRefObject } from "react";
import { Icons } from "../../../components";
import { SizeValues } from "../hooks/useSearch";
import { useTranslation } from "react-i18next";
import { SizeInput } from "./SizeInput";
import i18n from "../../../i18n";
import { useLanguage } from "../../../hooks";
interface SizeFilterProps {
    sizeRef: MutableRefObject<HTMLDivElement | null>;
    toggleSizeFilter: () => void;
    isOpenSizeFilter: boolean;
    minValue: string;
    maxValue: string;
    areasToShow: number;
    submitted: SizeValues | null;
    onFilterSize: (e: ChangeEvent<HTMLInputElement>, value: string) => void;
    submitFiltering: () => void;
    sx?: string
}

export const SizeFilter = ({
    sizeRef,
    sx,
    toggleSizeFilter,
    isOpenSizeFilter,
    maxValue,
    minValue,
    areasToShow,
    onFilterSize,
    submitted,
    submitFiltering
}: SizeFilterProps) => {
    const { t } = useTranslation();
    const { selectedLanguage } = useLanguage({ i18n });

    return (
        <div className={`flex justify-center ${sx ? sx : 'w-full'}`}>
            <div className="relative mb-[20px] flex w-full"
            >
                <div 
                    ref={sizeRef} 
                    className="w-full md:w-fit"
                >
                    <button
                        type="button"
                        className="flex w-full justify-between rounded-[6px] bg-[#F3F3F3] 
                                px-6 py-[11px] text-left text-base font-normal text-[#9e9e9e] md:w-[200px]"
                        onClick={toggleSizeFilter}
                    >
                        {submitted 
                            ? `${submitted.min 
                                ? submitted.min 
                                : 0
                            } ${submitted.max && '-' + ' ' + submitted.max}` 
                            : t('search.size')
                        }
                        <Icons name="down" />
                    </button>

                    {isOpenSizeFilter && (
                        <div
                            className="absolute bottom-[-120px] z-10 flex 
                                    w-full flex-col items-center justify-center rounded-[6px] border-[1px] 
                                    bg-white p-[10px] shadow-xl md:w-[200px]"
                        >
                            <div className="mb-[15px] flex items-center gap-[10px]">
                                <SizeInput 
                                    value={minValue}
                                    val="min"
                                    placeholder={t('search.min')}
                                    onChange={onFilterSize}
                                />

                                <p className="text-[#9e9e9e]">-</p>

                                <SizeInput 
                                    val="max"
                                    value={maxValue}
                                    placeholder={t('search.max')}
                                    onChange={onFilterSize}
                                />
                            </div>

                            <button
                                className="bg-primary rounded-[6px] px-[10px] py-[5px] text-[15px]"
                                type="button"
                                onClick={submitFiltering}
                            >
                                {selectedLanguage === 'en' 
                                    ? t('search.show') + " " + areasToShow + " " + t('search.results')
                                    : areasToShow + " " + 'Ergebnisse anzeigen'
                                }
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
