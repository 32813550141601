import { useNavigate } from "react-router-dom";
import { Button } from "../../components";
import { useCallback } from "react";
import { Links } from "../../settings";
import { useTranslation } from "react-i18next";

export const PricingEnterprise = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigate = useCallback(() => {
    navigate(Links.investorPricing.index);
  }, [navigate])

  return (
    <div className="mx-[20px] md:my-[120px] my-[80px] flex flex-col items-center justify-center">
      <div className='text-secondary flex flex-col items-center max-w-[600px] text-center mb-[30px] py-[40px]'>
          <p className='md:text-[38px] text-[25px] font-medium'>{t('pricing.thanks')}</p>
          <p className='md:text-[38px] text-[25px] font-medium'>
            {t('pricing.reach_out')}
          </p>
      </div>

      <Button
            color="Primary"
            textColor='text-white'
            type="button"
            onClick={handleNavigate}
        >
            {t("pricing.back")}
        </Button>
    </div>
  )
}
