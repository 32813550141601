import { Organization, UserInfo } from '../../../API';
import { Dispatch, SetStateAction } from 'react';
import { columns } from '../../../utils/columns';
import { useTranslation } from 'react-i18next';
import { DeleteModal } from './DeleteModal';
import { MemberInfo } from './MemberInfo';
import { Button, Loader } from '../../../components';
import { NotRegisteredTable } from './NotRegisteredTable';
import { useMember } from '../hooks';

interface MembersTableProps {
    members: (UserInfo | null)[] | undefined;
    users: (UserInfo | null | undefined)[];
    organization?: Organization | null; 
    notRegistered: string[];
    getMembers: () => Promise<void>;
    onNotRegistered: Dispatch<SetStateAction<string[]>>;
    onOpenInvite: Dispatch<SetStateAction<boolean>>;
    onUsers: Dispatch<SetStateAction<(UserInfo | null | undefined)[]>>;
}

export const MembersTable = ({ 
    members, 
    users,
    onUsers,
    onOpenInvite,
    organization,
    notRegistered,
    onNotRegistered,
    getMembers
}: MembersTableProps) => {
    const { t } = useTranslation()

    const {
        isLoading,
        userToDelete,
        setUserToDelete,
        approveDeleting
    } = useMember({
        users,
        onUsers,
        organization,
        onNotRegistered,
        notRegistered,
        getMembers
    });
    return (
        <>
            {isLoading && <Loader />}
            <div>
                <div className='flex md:flex-row md:justify-between flex-col gap-[15px] mb-[10px]'>
                    {organization && (
                        <h1 className="md:mb-[20px] text-[24px] font-medium">
                            {t('organization.members')}
                        </h1>
                    )}

                    {organization && (
                        <Button 
                            color="Secondary" 
                            width="w-fit" 
                            sx="px-[10px]" 
                            textColor="text-white"
                            type="button"
                            onClick={() => onOpenInvite(true)}
                        >
                            {t('organization.add')}
                        </Button>
                )}
                </div>

                {((users && users.length > 0) || notRegistered.length > 0) && (
                    <div>
                        <div className='w-fit xl:w-full'>
                            <div className="flex border-[1px]">
                                {columns.map(column => (
                                    <div
                                        className={`flex ${column.width 
                                                ? column.width 
                                                : '[@media(max-width:580px)]:w-[80px] w-[200px]'
                                        } 
                                            items-center justify-center 
                                            border-r-[1px] py-[5px] md:text-[14px] text-[12px] text-center`}
                                        style={{ backgroundColor: `rgba(176, 204, 85, ${column.opacity 
                                            ? column.opacity 
                                            : '0.7'
                                        }` }}
                                        key={column.id}
                                    >
                                        {t(column.name)}
                                    </div>
                                ))}
                            </div>

                            <div className="w-full overflow-scroll">
                                {users?.map((user) => (
                                    user && (
                                        <MemberInfo 
                                            key={user.id}
                                            user={user} 
                                            onUserSet={setUserToDelete} 
                                            isAccepted={members?.some(el => el?.id === user.id)}
                                        />
                                    )
                                ))}

                                {notRegistered.map(email => (
                                    <NotRegisteredTable
                                        key={email}
                                        email={email}
                                        onUser={setUserToDelete} 
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {userToDelete && (
                <DeleteModal 
                    selectedUser={userToDelete}
                    onDelete={approveDeleting}
                    onUserSet={setUserToDelete}
                />
            )}
        </>
    )
}
