export const recentArticles = [
    {
        id: 'Erweiterung_des_Solarpakets_I_Solar_Freifläche_Photovoltaik',
        title: 'articles.article_13.title',
        description: 'articles.article_13.desc',
        image: '/assets/images/ThirteenArticle.jpg',
        bigImage: '/assets/images/ThirteenArticle.jpg',
        published: 'articles.article_13.date',
        link: 'https://enlapa-46044507.hubspotpagebuilder.com/blog/solarpaket-i-st%C3%A4rkung-der-solarenergie-die-gesetz%C3%A4nderung-kurz-erkl%C3%A4rt-teil-1-dachanlagen'
    },
    {
        id: 'Photovoltaik_und_Solaranlage_zur_Stärkung',
        title: 'article_12.title',
        description: 'articles.article_12.desc',
        image: '/assets/images/TwelveArticle-big.webp',
        bigImage: '/assets/images/TwelveArticle-big.webp',
        published: 'articles.article_12.date',
        link: 'https://enlapa-46044507.hubspotpagebuilder.com/blog/solarpaket-i-st%C3%A4rkung-der-solarenergie-die-gesetz%C3%A4nderung-kurz-erkl%C3%A4rt-teil-1-dachanlagen'
    },
    {
        id: 'Enlapas_Leitfaden_zur_industriellen_Dachsanierung',
        title: 'article_11.title',
        description: 'articles.article_11.desc',
        image: '/assets/images/ElevenArticle.webp',
        bigImage: '/assets/images/ElevenArticle-big.webp',
        published: 'articles.article_11.date',
        link: 'https://enlapa-46044507.hubspotpagebuilder.com/blog/dachsanierung-anforderung-f%C3%BCr-den-bau-von-photovoltaikanlagen'
    },
    {
        id: 'Dachfläche_vermieten_und_Photovoltaik nutzen',
        title: 'article_ten.title',
        description: 'articles.article_ten.desc',
        image: '/assets/images/TenArticle.webp',
        bigImage: '/assets/images/tenArticle-620.png',
        published: 'articles.article_ten.date',
        link: 'https://enlapa-46044507.hubspotpagebuilder.com/blog/dach-vermieten-photovoltaik-f%C3%BCr-sich-nutzen-und-gelegenheit-beim-schopfe-packen'
    },
    {
        id: 'Power_Purchase_Agreement',
        title: 'article_nine.title',
        description: 'articles.article_nine.desc',
        image: '/assets/images/nineArticle.webp',
        bigImage: '/assets/images/nineArticle.jpg',
        published: 'articles.article_nine.date',
        link: 'https://enlapa-46044507.hubspotpagebuilder.com/blog/power-purchase-agreeement-ppa-mustervertrag-ein-schritt-zur-vereinfachung-des-zugangs-zum-ppa-markt'
    },
    {
        id: 'Wie_Solarparks_die_Artenvielfalt_fördern_können',
        title: 'article_eight.title',
        description: 'articles.article_eight.desc',
        image: '/assets/images/eightArticle.webp',
        bigImage: '/assets/images/eightArticle.jpg',
        published: 'articles.article_eight.date',
        link: 'https://enlapa-46044507.hubspotpagebuilder.com/blog/solarparks-als-biologische-oasen-eine-studie-%C3%BCber-vogelgemeinschaften-und-artenreichtum'
    },
    {
        id: 'ENLAPA_gibt_Mitgliedschaft_beim_Bundesverband_Solarwirtschaft_e.V._bekannt',
        title: 'article_seven.title',
        description: 'articles.article_seven.desc',
        image: '/assets/images/logo-new.jpg',
        bigImage: '/assets/images/logo-new.jpg',
        published: 'articles.article_seven.date',
        link: 'https://enlapa-46044507.hubspotpagebuilder.com/blog/enlapa-gibt-mitgliedschaft-beim-bundesverband-solarwirtschaft-e.v.-bekannt'
    },
    {
        id: 'Photovoltaik Lebensdauer_Wie_lange_hält_eine_Solaranlage',
        title: 'article_six.title',
        description: 'articles.article_six.desc',
        image: '/assets/images/Installation.webp',
        bigImage: '/assets/images/Installation.jpg',
        published: 'articles.article_six.date',
        link: 'https://enlapa-46044507.hubspotpagebuilder.com/blog/photovoltaik-lebensdauer-wie-lange-h%C3%A4lt-eine-solaranlage'
    },
    {
        id: 'Solarpark_bauen_Alles Wissenswerte_zu_Solarparks',
        title: 'article_five.title',
        description: 'articles.article_five.desc',
        image: '/assets/images/Solarpark.webp',
        bigImage: '/assets/images/Solarpark.jpg',
        published: 'articles.article_five.date',
        link: 'https://enlapa-46044507.hubspotpagebuilder.com/blog/solarpark-bauen-rendite-genehmigungen-f%C3%BCr-den-bau-eines-solarparks'
    },
    {
        id: 'Einspeisevergütung_2024_für_PV-Anlagen',
        title: 'article_four.title',
        description: 'articles.article_four.desc',
        image: '/assets/images/FourthArticle.webp',
        bigImage: '/assets/images/FourthArticle.jpg',
        published: 'articles.article_four.date',
        link: 'https://enlapa-46044507.hubspotpagebuilder.com/blog/photovoltaik-einspeiseverg%C3%BCtung-2024-aktuelle-s%C3%A4tze-und-%C3%A4nderungen-im-%C3%BCberblick'
    },
    {
        id: 'Pachtpreise_Ackerland_–_Einfluss_von_Solarprojekten_auf_die_Preisentwicklung',
        title: 'article_three.title',
        description: 'articles.article_three.desc',
        image: '/assets/images/Ackerland.webp',
        bigImage: '/assets/images/Ackerland.jpg',
        published: 'articles.article_three.date',
        link: 'https://enlapa-46044507.hubspotpagebuilder.com/blog/pachtpreise-ackerland-einfluss-von-solarprojekten-auf-die-preisentwicklung'
    },
    {
        id: 'Unterschied_Pachtvertrag_Landwirtschaft_vs_Pachtvertrag_Solarnutzung',
        title: 'article_two.title',
        description: 'articles.article_two.desc',
        image: '/assets/images/Tractor.webp',
        bigImage: '/assets/images/Tractor.jpg',
        published: 'articles.article_two.date',
        link: 'https://enlapa-46044507.hubspotpagebuilder.com/blog/unterschied-pachtvertrag-landwirtschaft-vs.-pachtvertrag-solarnutzung-ein-vergleich-der-schl%C3%BCsselelemente'
    },
    {
        id: 'Photovoltaik_Pacht_pro_Hektar_Ackerland_und_Grundstück_verpachten',
        title: 'article_one.title',
        description: 'articles.article_one.desc',
        image: '/assets/images/Solaranlage.webp',
        bigImage: '/assets/images/Solaranlage.jpg',
        published: 'articles.article_one.date',
        link: 'https://enlapa-46044507.hubspotpagebuilder.com/blog/photovoltaik-pacht-pro-hektar-ackerland-grundst%C3%BCck-verpachten'
    }
]