import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

interface DeleteModalProps {
    text: string;
    email?: string;
    onApproveDelete:  () => void;
    onCancelDelete: () => void;
}

export const DeleteModal = ({ 
    text,
    email,
    onApproveDelete, 
    onCancelDelete
}: DeleteModalProps) => {
  const { t } = useTranslation();

  return (
    <Box className="fixed top-0 right-0 h-full w-full flex justify-center items-center z-10 px-[10px]">
        <div className="bg-[#dcedc8] text-secondary px-[20px] py-[15px] rounded-[8px] text-center z-10">
            <div className="mb-[20px]">
                <p className='text-[17px] max-w-[400px]'>
                    {t(text)}
                </p>

                {email && (
                    <p className='text-[17px] text-primary max-w-[400px]'>
                        {t(email)}
                    </p>
                )}
            </div>

            <div className="flex gap-[15px] justify-center">
                <button 
                    className="bg-secondary text-white px-[10px] py-[5px] rounded-[8px]"
                    onClick={onApproveDelete}>
                    {t('admin_dashboard.yes')}
                </button>

                <button 
                    onClick={onCancelDelete}
                    className="bg-red-500 text-white px-[10px] py-[5px] rounded-[8px]"
                >
                    {t('admin_dashboard.no')}
                </button>
            </div>
        </div>
    </Box>
  )
}
