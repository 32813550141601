import { TableCell } from '@mui/material';
import { Organization, UserInfo } from '../../../API';

interface MembersBlockProps {
    organization?: Organization | null;
    members: (UserInfo | null)[];
}
 
export const MembersBlock = ({ 
    organization, 
    members 
}: MembersBlockProps) => {
    return (
        <>
            <TableCell>
                <div className="group relative flex w-full justify-center">
                    {organization ? (
                        members.length || 0
                    ) : (
                        <img
                            src="/assets/icons/closed.webp"
                            className="h-[20px]"
                        />
                    )}

                    {members.length > 0 && (
                        <div className="absolute bottom-[-20px] hidden group-hover:block">
                            <div className="flex flex-col gap-[3px] rounded-[4px] border-[1px] bg-white p-[3px] text-[10px]">
                                {members?.map((el) => <p key={el?.email}>{el?.email}</p>)}
                            </div>
                        </div>
                    )}
                </div>
            </TableCell>

            <TableCell>
                <div className="group relative flex w-full justify-center">
                    {organization ? (
                        organization.InviteCompanyMembersEMail?.length || 0
                    ) : (
                        <img
                            src="/assets/icons/closed.webp"
                            className="h-[20px]"
                        />
                    )}

                    {organization &&
                        organization.InviteCompanyMembersEMail &&
                        organization.InviteCompanyMembersEMail?.length > 0 && (
                            <div className="absolute bottom-[-20px] hidden group-hover:block">
                                <div className="flex flex-col gap-[3px] rounded-[4px] border-[1px] bg-white p-[3px] text-[10px]">
                                    {organization.InviteCompanyMembersEMail?.map(
                                        (el) => <p key={el}>{el}</p>
                                    )}
                                </div>
                            </div>
                        )}
                </div>
            </TableCell>
        </>
    )
}
