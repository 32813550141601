/** @format */

import { Link } from 'react-router-dom';
import { Button } from '../Button';
import { Flaeche } from '../../API';
import { useTranslation } from 'react-i18next';
import { checkTypeRoof, convertToHectares } from '../../helpers/calculateResult';
import { LazyLoadImage } from '../LazyLoadImage';
import { useEffect, useState } from 'react';
import { fetchImage } from '../../utils';
import { useLanguage } from '../../hooks';
import { SliderPropsMock } from '../../types';

interface CardProps {
    land: Flaeche | SliderPropsMock;
    image: string;
    mobile?: boolean;
    fetchImages: (id?: string | undefined) => Promise<void>;
}

export const Card = ({ land, image, mobile, fetchImages }: CardProps) => {
    const { t, i18n } = useTranslation();
    const { selectedLanguage } = useLanguage({ i18n });

    const [imageUrl, setImageUrl] = useState<string>("");

    useEffect(() => {
        async function loadImage() {
            try {
                const fetchedImageUrl = await fetchImage(image);
                if (fetchedImageUrl) {
                    setImageUrl(fetchedImageUrl);
                }
            } catch (error) {
                console.error('Error loading image:', error);
            }
        }
        loadImage();
    }, [image]);

    useEffect(() => {
        fetchImages(land.id)
    }, [land])

    return (
        <Link to={`/search/${land.id}`}>
            <div className="text-center md:text-left">
                <div className="group relative overflow-hidden rounded-2xl">
                    <LazyLoadImage
                        className={`
                            ${mobile && 'h-[320px]'}
                            object-cover object-center xl:h-[350px] md:h-[300px] 
                            w-[300px] cursor-pointer rounded-2xl group-hover:scale-110 transition-all duration-500
                        `}
                        src={imageUrl}
                        alt=""
                    />
                    <div 
                        className="absolute left-1/2 w-[90%] -translate-x-1/2 transform transition-transform 
                        duration-500 ease-in-out group-hover:-translate-y-20"
                    >
                        <Button color="Secondary" width="w-full">
                            {t('home.see_details')}
                        </Button>
                    </div>
                </div>
                <h4 className="mt-[30px] text-xl font-medium md:text-[20px] pr-[5px]">
                    {t(land.address_state) || (land.address_country && t(land.address_country))}
                </h4>
                <h5 className="md:mt-none mt-[10px] text-sm font-medium opacity-50 md:text-lg">
                    {t('home.size')} {checkTypeRoof(land) 
                        ? land.areaSize?.toLocaleString(selectedLanguage === 'de' ? 'de-DE' : 'en-US') 
                        : convertToHectares(land.areaSize)?.toLocaleString(selectedLanguage === 'de' ? 'de-DE' : 'en-US')} {" "}
                    {checkTypeRoof(land)  ? 'm²' : 'ha'}
                </h5>
            </div>
        </Link>
    )
}
