import { Link } from "react-router-dom";
import { Links } from "../../../settings";
import { UseFormRegister } from "react-hook-form";
import { TUserInfoSchema } from "../../../helpers";
import { useTranslation } from "react-i18next";
import { Checkbox } from "../../../components";

interface AcceptanceProps {
    register: UseFormRegister<TUserInfoSchema>;
}

export const Acceptance = ({ register }: AcceptanceProps) => {
    const { t } = useTranslation();

    return (
        <div className="flex justify-center items-center mb-[20px]">
            <Checkbox name="accept" register={register} type="checkbox" />

            <p className="max-w-[600px] text-[13px]">
                {t('sign_up.by_submitting')}{' '}
                <Link
                    to={Links.privacy.index}
                    className="text-primary underline"
                >
                    {t('sign_up.the_privacy_policy')}
                </Link>{' '}
                ,{' '}
                <Link to={Links.terms.index} className="text-primary underline">
                    {t('sign_up.the_terms')}
                </Link>{' '}
                    {t('sign_up.and')} {' '}
                <Link
                    to={Links.serviceTerms.index}
                    className="text-primary underline"
                >
                    {t('sign_up.terms_of_use')}
                </Link>{' '}
            </p>
        </div>
    )
}
