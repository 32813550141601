import { GraphQLQuery } from "@aws-amplify/api";
import { API, Storage, graphqlOperation } from "aws-amplify";
import { ChangeEvent, Dispatch, SetStateAction, useCallback, useState } from "react";
import { DeleteFlaecheMutation, Flaeche } from "../../../API";
import { deleteFlaeche } from "../../../graphql/mutations";

interface Options {
    filteredAreas: (Flaeche | null)[];
    setFilteredAreas: Dispatch<SetStateAction<(Flaeche | null)[]>>;
}

export const useArea = ({
    filteredAreas,
    setFilteredAreas
}: Options) => {
    const [checked, setChecked] = useState(true);
    const [openMap, setOpenMap] = useState(false);

    const deleteArea = async (id: string) => {
        try {
            await API.graphql<GraphQLQuery<DeleteFlaecheMutation>>(
                graphqlOperation(deleteFlaeche, { input: { id } })
            );

            const filtered = filteredAreas.filter(el => el?.id !== id);
            setFilteredAreas(filtered);

            const { results } = await Storage.list(id ? id : "");

            const areaImages = results.filter(
                (file) => id && file.key && file?.key.includes(id)
            )

            if (areaImages.length > 0) {
                await Promise.all(areaImages.map(file => {
                    if (file.key) {
                        Storage.remove(file.key);
                    }
                }));
            }
        } catch (error: any) {
            console.log('ERROR', error)
        }
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const goBack = useCallback(() => {
        setOpenMap(false);
    }, [])

    const showMap = useCallback(() => {
        setOpenMap(true);
    }, [])

    return {
        deleteArea,
        checked,
        openMap,
        handleChange,
        goBack,
        showMap
    }
}