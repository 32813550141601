export const BackgroundBlock = () => {
  return (
    <div className="relative ml-[calc(50%-50vw)] h-full w-screen bg-[lightgray]">
        <div
            className="z-5 absolute h-[200px] w-full"
            style={{
                background:
                    'linear-gradient(180deg, #FFFF 0%, rgba(243, 243, 243, 0.76) 40.12%, rgba(243, 243, 243, 0.00) 89.67%)',
            }}
        ></div>
        <div className="z-[1] h-[451px] w-full bg-solarCell bg-cover bg-center  bg-no-repeat md:h-[857px]"></div>
    </div>
  )
}
