import mapboxgl from 'mapbox-gl';
import { useEffect, useRef, useState, useCallback } from 'react';
import { useLanguage } from '../../../hooks';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import i18n from '../../../i18n';
import { minLayersZoom, MinLayersZoom } from '../constants';
import { usePowerElements } from './usePowerElements';
import { useVoltageElements } from './useVoltageElements';
import { useRailwayElements } from './useRailwayElements';
import { addImageLayer, addLineLayer } from '../helpers';

const TOKEN =
    'pk.eyJ1IjoiZW5sYXBhIiwiYSI6ImNsbzc2dndjbTAybmMydnBjNXE3OTVzbWkifQ.eoRkVu1mm9KFcTTq7plkew';
interface FilterStates {
    energyInfrastructure: boolean;
    powerGrid: boolean;
    railwayPowerGrid: boolean;
}

export const usePowerGrid = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [filterStates, setFilterStates] = useState<FilterStates>({
        energyInfrastructure: true,
        powerGrid: false,
        railwayPowerGrid: false,
    });
    const mapContainer = useRef<HTMLDivElement | null>(null);
    const { selectedLanguage } = useLanguage({ i18n });
    const [map, setMap] = useState<mapboxgl.Map | null>(null);

    const { powerElements, powerLayers, fetchPower, setPowerLayers } =
        usePowerElements();

    const { voltageElements, voltageLayers, fetchVoltage, setVoltageLayers, fetchGermanyVoltage } =
    useVoltageElements();

    const {
        railwayLayers,
        railwayElements,
        fetchRailwayVoltage,
        setRailwayLayers,
    } = useRailwayElements();

    const toggleFilter = useCallback((filter: keyof FilterStates) => {
        setFilterStates((prev) => ({ ...prev, [filter]: !prev[filter] }));
    }, []);

    const handleCheckboxes = (checkboxes: { [key: string]: boolean }) =>
        Object.keys(checkboxes).map((layer) => (
            <div className="flex" key={layer}>
                <input
                    id={`filter-mobile-size-${layer}`}
                    name="size[]"
                    value={layer}
                    checked={checkboxes[layer]}
                    type="checkbox"
                    onChange={() => {
                        if (checkboxes === powerLayers) {
                            setPowerLayers((prevLayers) => ({
                                ...prevLayers,
                                [layer]: !prevLayers[layer],
                            }));
                        } else if (checkboxes === voltageLayers) {
                            setVoltageLayers((prevLayers) => ({
                                ...prevLayers,
                                [layer]: !prevLayers[layer],
                            }));
                        } else if (checkboxes === railwayLayers) {
                            setRailwayLayers((prevLayers) => ({
                                ...prevLayers,
                                [layer]: !prevLayers[layer],
                            }));
                        }
                    }}
                    style={{ cursor: "pointer", accentColor: 'rgb(22 163 74)' }}
                    className="h-4 w-4 rounded"
                />
                <label className="ml-3 min-w-0 flex-1 text-gray-500">
                    {checkboxes === powerLayers ? `Power ${layer}` : `${layer}`}
                </label>
            </div>
        ));

    const removeLayer = useCallback(
        (id: string) => {
            if (map?.getLayer(id)) {
                map.removeLayer(id);
            }
            if (map?.getSource(id)) {
                map.removeSource(id);
            }
            if (map?.hasImage(id)) {
                map.removeImage(id);
            }
        },
        [map, powerLayers, voltageLayers,railwayLayers]
    );

    useEffect(() => {
        mapboxgl.accessToken = TOKEN;

        const language = new MapboxLanguage({ defaultLanguage: 'en' });

        const map = new mapboxgl.Map({
            container: mapContainer.current!,
            style: 'mapbox://styles/mapbox/satellite-streets-v11',
            center: [10.4584, 51.2141],
            zoom: 12,
            preserveDrawingBuffer: true,
        });

        map.addControl(language);

        map.on('load', async () => {
            setMap(map);
            const bounds = map.getBounds();

            fetchPower('substation', bounds);
            fetchGermanyVoltage('380kV (e.g. Germany)');
            fetchRailwayVoltage('15kV (e.g. Germany)');
            fetchRailwayVoltage('1.5kV');
        });

        return () => {
            map.remove();
        };
    }, [selectedLanguage]);



    const debounce = (func: Function, delay: number) => {
        let timeoutId: NodeJS.Timeout;
        return function(this: any, ...args: any[]) {
          const context = this;
          clearTimeout(timeoutId);
          timeoutId = setTimeout(() => func.apply(context, args), delay);
        }
      }
      
      const handleMapEvents = useCallback(() => {
        if (!map) return;
        const bounds = map.getBounds();
        const currentZoom = map.getZoom();
      
        Object.keys(powerLayers).forEach((key: string) => {
          const minZoom: number = minLayersZoom[key as keyof MinLayersZoom];
          if (powerLayers[key] && currentZoom >= minZoom) {
            fetchPower(key, bounds);
          }
        });
      
        Object.keys(voltageLayers).forEach((key) => {
          const minZoom = 8;
          if (voltageLayers[key] && currentZoom >= minZoom && currentZoom < 14) {
            fetchVoltage(key, bounds);
          }
        });
      
      }, [map, powerLayers, voltageLayers, railwayLayers, fetchPower, fetchVoltage, fetchRailwayVoltage]);
      
      const debouncedHandleMapEvents = useCallback(
        debounce(handleMapEvents, 2500),
        [handleMapEvents]
      );
      
      useEffect(() => {
        if (map) {
          map.on('moveend', debouncedHandleMapEvents);
          map.on('zoomend', debouncedHandleMapEvents);
      
          return () => {
            map.off('moveend', debouncedHandleMapEvents);
            map.off('zoomend', debouncedHandleMapEvents);
          };
        }
      }, [map, debouncedHandleMapEvents]);

    useEffect(() => {
        if (!map) return;

        Object.keys(powerLayers).forEach((layer) => {
            if (powerLayers[layer]) {
                const minZoom: number =
                    minLayersZoom[layer as keyof MinLayersZoom];
                addImageLayer(
                    map,
                    layer,
                    powerElements[layer],
                    `/power ${layer}.png`,
                    minZoom
                );
            } else {
                removeLayer(layer);
            }
        });

        Object.keys(voltageLayers).forEach((layer) => {
            if (voltageLayers[layer]) {
                const minZoom = 9;
                addLineLayer(map, layer, voltageElements[layer], layer, minZoom);
            } else {
                removeLayer(layer);
            }
        });

        Object.keys(railwayLayers).forEach((layer) => {
            if (railwayLayers[layer]) {
                const minZoom = 9;
                console.log("test")
                addLineLayer(map, layer, railwayElements[layer], layer, minZoom);
            } else {
                removeLayer(layer);
            }
        });
    }, [
        map,
        powerElements,
        voltageElements,
        railwayElements,
        addImageLayer,
        addLineLayer,
        removeLayer,
    ]);

    return {
        mapContainer,
        isOpen,
        toggleFilter,
        powerLayers,
        voltageLayers,
        railwayLayers,
        filterStates,
        setIsOpen,
        handleCheckboxes,
    };
};
