import React, { ReactElement } from "react";
import LazyLoad from "react-lazyload";

export const LazyLoadImage = (
  props: React.ImgHTMLAttributes<HTMLImageElement>
): ReactElement => {
  return (
    <LazyLoad offset={100}>
      <img {...props} />
    </LazyLoad>
  );
};