import { FormControlLabel } from "@mui/material"
import { IOSSwitch } from "../../AdminDashboard/styled"
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { Organization, UpdateOrganizationMutation } from "../../../API";
import { updateOrganization } from "../../../graphql/mutations";
import { useTranslation } from "react-i18next";

interface CompanySwitcherProps {
    organization?: Organization | null;
}

export const CompanySwitcher = ({ organization }: CompanySwitcherProps) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const { t } = useTranslation();

    const [showInfo, setShowInfo] = useState(false);
    const [checked, setChecked] = useState(false);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const updateNotifications = async () => {
        if (!organization) return;

        try {
            const editingOrganization = {
                id: organization?.id,
                SendBroadcastForUnlockToAllOrganizationMembers: checked
            };

            await API.graphql<GraphQLQuery<UpdateOrganizationMutation>>(
                graphqlOperation(updateOrganization, { input: editingOrganization })
            )
        } catch (error) {
            console.log("Error:", error);
        }
    }

    const toggleInfo = () => {
        setShowInfo(prev => !prev)
    }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                setShowInfo(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        updateNotifications();
    }, [checked])

    useEffect(() => {
        const isChecked = organization?.SendBroadcastForUnlockToAllOrganizationMembers;

        setChecked(isChecked ? isChecked : false)
    }, [organization])

    return (
        <div className="flex">
            <FormControlLabel
                control={
                    <IOSSwitch
                        sx={{ m: 1 }}
                        checked={checked}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                }
                label={t('profile.switcher_label')}
                className="mb-[20px]"
            />

            <div className="items center relative flex">
                <img
                    src="/assets/icons/error_outline.svg"
                    alt="info"
                    onClick={toggleInfo}
                    className="mt-[-18px] cursor-pointer"
                />

                {showInfo && (
                    <div
                        className="absolute right-[20px] top-[40px] w-[200px] 
                                rounded-[5px] border-[1px] bg-white p-[5px] text-[12px] md:left-0"
                        ref={containerRef}
                    >
                        {t('profile.switcher')}
                    </div>
                )}
            </div>
        </div>
    )
}
