import { Links } from "../types";
import CountryList from './countryLists.json';

export type InputType = {
    title?: string;
    name: string;
    placeholder: string;
    defaultValue?: string;
    star?: boolean;
    width?: string;
    starStyle?: string;
    dropdown?: boolean;
    type?: 'password' | 'textarea' | 'dropdown';
    options?: Record<string, string>[] | undefined;
    value?: string
}

export const category_login: {value: string, label: string }[] = [
    {
        value: 'sign_up.area_for_lease',
        label: 'sign_up.area_for_lease'
    },
    {
        value: 'sign_up.solar_energy',
        label: 'sign_up.solar_energy'
    },
    {
        value: 'sign_up.develop_solar_plant',
        label: 'sign_up.develop_solar_plant'
    },
]

export const category: string[] = [
    'addArea.role_items.landowners',
    'addArea.role_items.commisioned',
    'addArea.role_items.project_developer',
    'addArea.role_items.investor',
    'addArea.role_items.other'
]

export const countriesAddArea = [
    {
        value: 'addArea.countries.Germany',
        label: 'addArea.countries.Germany',
    },
    {
        value: 'addArea.countries.Austria',
        label: 'addArea.countries.Austria',
    },
    {
        value: 'addArea.countries.Belgium',
        label: 'addArea.countries.Belgium',
    },
    {
        value: 'addArea.countries.Bulgaria',
        label: 'addArea.countries.Bulgaria',
    },
    {
        value: 'addArea.countries.Croatia',
        label: 'addArea.countries.Croatia',
    },
    {
        value: 'addArea.countries.Cyprus',
        label: 'addArea.countries.Cyprus',
    },
    {
        value: 'addArea.countries.Czech Republic',
        label: 'addArea.countries.Czech Republic',
    },
    {
        value: 'addArea.countries.Denmark',
        label: 'addArea.countries.Denmark',
    },
    {
        value: 'addArea.countries.Estonia',
        label: 'addArea.countries.Estonia',
    },
    {
        value: 'addArea.countries.Finland',
        label: 'addArea.countries.Finland',
    },
    {
        value: 'addArea.countries.France',
        label: 'addArea.countries.France',
    },
    {
        value: 'addArea.countries.Greece',
        label: 'addArea.countries.Greece',
    },
    {
        value: 'addArea.countries.Hungary',
        label: 'addArea.countries.Hungary',
    },
    {
        value: 'addArea.countries.Ireland',
        label: 'addArea.countries.Ireland',
    },
    {
        value: 'addArea.countries.Italy',
        label: 'addArea.countries.Italy',
    },
    {
        value: 'addArea.countries.Latvia',
        label: 'addArea.countries.Latvia',
    },
    {
        value: 'addArea.countries.Lithuania',
        label: 'addArea.countries.Lithuania',
    },
    {
        value: 'addArea.countries.Luxembourg',
        label: 'addArea.countries.Luxembourg',
    },
    {
        value: 'addArea.countries.Malta',
        label: 'addArea.countries.Malta',
    },
    {
        value: 'addArea.countries.Netherlands',
        label: 'addArea.countries.Netherlands',
    },
    {
        value: 'addArea.countries.Poland',
        label: 'addArea.countries.Poland',
    },
    {
        value: 'addArea.countries.Portugal',
        label: 'addArea.countries.Portugal',
    },
    {
        value: 'addArea.countries.Romania',
        label: 'addArea.countries.Romania',
    },
    {
        value: 'addArea.countries.Slovakia',
        label: 'addArea.countries.Slovakia',
    },
    {
        value: 'addArea.countries.Slovenia',
        label: 'addArea.countries.Slovenia',
    },
    {
        value: 'addArea.countries.Spain',
        label: 'addArea.countries.Spain',
    },
    {
        value: 'addArea.countries.Sweden',
        label: 'addArea.countries.Sweden',
    },
]

export const federalStates = [
    {
        "value": "addArea.states.Baden-Wuerttemberg",
        "label": "addArea.states.Baden-Wuerttemberg"
    },
    {
        "value": "addArea.states.Bavaria",
        "label": "addArea.states.Bavaria"
    },
    {
        "value": "addArea.states.Berlin",
        "label": "addArea.states.Berlin"
    },
    {
        "value": "addArea.states.Brandenburg",
        "label": "addArea.states.Brandenburg"
    },
    {
        "value": "addArea.states.Bremen",
        "label": "addArea.states.Bremen"
    },
    {
        "value": "addArea.states.Hamburg",
        "label": "addArea.states.Hamburg"
    },
    {
        "value": "addArea.states.Hesse",
        "label": "addArea.states.Hesse"
    },
    {
        "value": "addArea.states.Mecklenburg-Western Pomerania",
        "label": "addArea.states.Mecklenburg-Western Pomerania"
    },
    {
        "value": "addArea.states.Lower Saxony",
        "label": "addArea.states.Lower Saxony"
    },
    {
        "value": "addArea.states.North Rhine-Westphalia",
        "label": "addArea.states.North Rhine-Westphalia"
    },
    {
        "value": "addArea.states.Rhineland-Palatinate",
        "label": "addArea.states.Rhineland-Palatinate"
    },
    {
        "value": "addArea.states.Saarland",
        "label": "addArea.states.Saarland"
    },
    {
        "value": "addArea.states.Saxony",
        "label": "addArea.states.Saxony"
    },
    {
        "value": "addArea.states.Saxony-Anhalt",
        "label": "addArea.states.Saxony-Anhalt"
    },
    {
        "value": "addArea.states.Schleswig-Holstein",
        "label": "addArea.states.Schleswig-Holstein"
    },
    {
        "value": "addArea.states.Thuringia",
        "label": "addArea.states.Thuringia"
    },
]

export const location: InputType[] = [
    {
        star: true,
        starStyle: 'left-[120px]',
        title: 'addArea.location',
        name: 'country',
        placeholder: 'addArea.placeholders.country',
        type: 'dropdown',
        options: countriesAddArea,
    },
    {
        star: true,
        starStyle: 'left-[115px]',
        name: 'state',
        type: 'dropdown',
        options: federalStates,
        placeholder: 'addArea.placeholders.federal_state',
    },
    {
        star: true,
        starStyle: 'left-[95px]',
        name: 'city',
        placeholder: 'addArea.placeholders.city',
    },
]

export const types = [
    {
        "value": "addArea.field",
        "label": "addArea.field"
    },
    {
        "value": "addArea.roof",
        "label": "addArea.roof"
    },
    {
        "value": "addArea.lake",
        "label": "addArea.lake"
    },
    {
        "value": "addArea.noise_barrier",
        "label": "addArea.noise_barrier"
    },
    {
        "value": "addArea.grassland",
        "label": "addArea.grassland"
    },
    {
        "value": "addArea.others",
        "label": "addArea.others"
    }
]

export const options = [
    {
        "value": "addArea.offer_for.lease_offer",
        "label": "addArea.offer_for.lease_offer"
    },
    {
        "value": "addArea.offer_for.purchase_offer",
        "label": "addArea.offer_for.purchase_offer"
    },
    {
        "value": "addArea.offer_for.project_development",
        "label": "addArea.offer_for.project_development"
    },
    {
        "value": "addArea.offer_for.project_construction",
        "label": "addArea.offer_for.project_construction"
    },
    {
        "value": "addArea.offer_for.electricity",
        "label": "addArea.offer_for.electricity"
    },
    {
        "value": "addArea.offer_for.operation",
        "label": "addArea.offer_for.operation"
    },
    {
        "value": "addArea.offer_for.others",
        "label": "addArea.offer_for.others"
    },
]

export const blocks = [
    {
        id: 1,
        image: '/assets/icons/thumbs-up.svg',
        title: 'addArea.planning_process_items.agreement.title',
        description: 'addArea.planning_process_items.agreement.desc'
    },
    {
        id: 2,
        image: '/assets/icons/contract.svg',
        title: 'addArea.planning_process_items.contract.title',
        description: 'addArea.planning_process_items.contract.desc'
    },
    {
        id: 3,
        image: '/assets/icons/power-generation.svg',
        title: 'addArea.planning_process_items.grid_connection.title',
        description: 'addArea.planning_process_items.grid_connection.desc'
    },
    {
        id: 4,
        image: '/assets/icons/plan.svg',
        title: 'addArea.planning_process_items.approval_request.title',
        description: 'addArea.planning_process_items.approval_request.desc'
    },
    {
        id: 5,
        image: '/assets/icons/Iconbuilding.svg',
        title: 'addArea.planning_process_items.approval_grant.title',
        description: 'addArea.planning_process_items.approval_grant.desc'
    },
    {
        id: 6,
        image: '/assets/icons/Unionconstruction.svg',
        title: 'addArea.planning_process_items.plan.title',
        description: 'addArea.planning_process_items.plan.desc'
    },
    {
        id: 7,
        image: '/assets/icons/solar-panel.svg',
        title: 'addArea.planning_process_items.building.title',
        description: 'addArea.planning_process_items.building.desc'
    },
];

export const descBlocks = [
    {
        id: 1,
        image: '/assets/icons/road.svg',
        title: 'addArea.description_items.railways',
    },
    {
        id: 2,
        image: '/assets/icons/field.svg',
        title: 'addArea.description_items.fields',
    },
    {
        id: 3,
        image: '/assets/icons/biomimetic.svg',
        title: 'addArea.description_items.conversion_areas',
    },
    {
        id: 4,
        image: '/assets/icons/factory.svg',
        title: 'addArea.description_items.industry',
    },
    {
        id: 5,
        image: '/assets/icons/skyline.svg',
        title: 'addArea.description_items.buildings',
    },
    {
        id: 6,
        image: '/assets/icons/not-applicable.svg',
        title: 'addArea.description_items.nothing_applicable',
    },
];

export const slides: string[] = [
    'home.energy_revolution.benefit_one',
    'home.energy_revolution.benefit_two',
    'home.energy_revolution.benefit_three',
    'home.energy_revolution.benefit_four',
    'home.energy_revolution.benefit_five',
    'home.energy_revolution.benefit_six',
    'home.energy_revolution.benefit_seven'
]

export const cookies = [
    {
        name: 'cookie_banner.preferences',
        desc: 'cookie_banner.preferences_desc',
        category: 'preferences'
    },
    {
        name: 'cookie_banner.statistics',
        desc: 'cookie_banner.statistics_desc',
        category: 'statistics'
    },
    {
        name: 'cookie_banner.marketing',
        desc: 'cookie_banner.marketing_desc',
        category: 'marketing'
    },
]

export const qAs = [
    {
        id: 1,
        question: "fAq_owners.lease.first_q",
        answer: "fAq_owners.lease.first_a"
    },
    {
        id: 2,
        question: "fAq_owners.lease.second_q",
        answer: "fAq_owners.lease.second_a"
    },
    {
        id: 3,
        question: "fAq_owners.lease.third_q",
        answer: "fAq_owners.lease.third_a"
    },
    {
        id: 4,
        question: "fAq_owners.lease.fourth_q",
        answer: "fAq_owners.lease.fourth_a"
    },
    {
        id: 5,
        question: "fAq_owners.lease.fifth_q",
        answer: "fAq_owners.lease.fifth_a"
    }
];

export const requirements = [
    {
        id: 6,
        question: "fAq_owners.process.first_q",
        answer: "fAq_owners.process.first_a"
    },
    {
        id: 7,
        question: "fAq_owners.process.second_q",
        answer: "fAq_owners.process.second_a"
    },
    {
        id: 8,
        question: "fAq_owners.process.third_q",
        answer: "fAq_owners.process.third_a"
    },
    {
        id: 9,
        question: "fAq_owners.process.fourth_q",
        answer: "fAq_owners.process.fourth_a"
    }
];

export const symbiosis = [
    {
        id: 10,
        question: "fAq_owners.symbiosis.first_q",
        answer: "fAq_owners.symbiosis.first_a"
    },
    {
        id: 11,
        question: "fAq_owners.symbiosis.second_q",
        answer: "fAq_owners.symbiosis.second_a"
    },
    {
        id: 12,
        question: "fAq_owners.symbiosis.third_q",
        answer: "fAq_owners.symbiosis.third_a"
    },
    {
        id: 13,
        question: "fAq_owners.symbiosis.fourth_q",
        answer: "fAq_owners.symbiosis.fourth_a"
    }

];

export const use = [
    {
        id: 14,
        question: "fAq_owners.use.first_q",
        answer: "fAq_owners.use.first_a"
    },
    {
        id: 15,
        question: "fAq_owners.use.second_q",
        answer: "fAq_owners.use.second_a"
    }
]

export const footer: Record<string, Links[] | string>[] = [
    {
        title: 'home.footer.company.title',
        links: [
            { name: 'home.footer.company.about_us', link: '/about-us'},
            { name: 'home.footer.company.our_vision', link: '/vision' },
            { name: 'home.footer.company.imprint', link: '/imprint' }
        ],
    },
    {
        title: 'home.footer.contact.title',
        links: [
            { name: 'home.footer.contact.press_contact', link: '/press-contact' },
            { name: 'home.footer.contact.feedback', link: '/feedback' }
        ],
    },
    {
        title: 'home.footer.for_owners.title',
        links: [
            { name: 'home.footer.for_owners.get_started', link: '/owners-get-started' },
            { name: 'home.footer.for_owners.compensation', link: '/compensation' },
            { name: 'home.footer.for_owners.faqs', link: '/owners-faq' },
            { name: 'home.footer.for_owners.blog', link: '/blog' },
        ],
    },
    {
        title: 'home.footer.for_investors.title',
        links: [
            { name: 'home.footer.for_investors.get_started', link: '/investors-get-started' },
            { name: 'home.footer.for_investors.pricing', link: '/investors-pricing' },
            // { name: 'home.footer.for_investors.faqs', link: '' },
        ],
    },
]

export const ArticleQuestions = [
    {
        id: 1,
        question: 'article_ten.subtitle_11',
        answer: 'article_ten.p_26'
    },
    {
        id: 2,
        question: 'article_ten.subtitle_12',
        answer: 'article_ten.p_27'
    },
    {
        id: 3,
        question: 'article_ten.subtitle_13',
        answer: 'article_ten.p_28'
    },
    {
        id: 4,
        question: 'article_ten.subtitle_14',
        answer: 'article_ten.p_29'
    },
    {
        id: 5,
        question: 'article_ten.subtitle_15',
        answer: 'article_ten.p_30'
    }
]

export const socialMedias = [
    {
        name: 'linkedin',
        link: 'https://www.linkedin.com/company/enlapa'
    },
    {
        name: 'facebook',
        link: 'https://www.facebook.com/enlapa.de'
    },
    {
        name: 'instagram',
        link: 'https://www.instagram.com/enlapa.de/'
    },
    {
        name: 'mail',
        link: 'mailto:info@enlapa.de'
    }
]

type LoginInputType = {
    name: string;
    placeholder: string;
    defaultValue?: string;
    width: string;
    dropdown?: boolean;
    type?: 'password' | 'textarea' | 'dropdown';
    options?: Record<string, string>[] | undefined;
}

export const userInfo: LoginInputType[] = [
    {
        name: 'firstName',
        placeholder: 'placeholders.first_name',
        width: 'w-full md:w-[calc(50%-10px)]',
    },
    {
        name: 'lastName',
        placeholder: 'placeholders.last_name',
        width: 'w-full md:w-[calc(50%-10px)]',
    },
    {
        name: 'postalAddress',
        placeholder: 'placeholders.postal_address',
        width: 'w-full md:w-[calc(50%-10px)]'
    },
    {
        name: 'country',
        placeholder: 'placeholders.country',
        type: 'dropdown',
        width: 'w-full md:w-[calc(50%-10px)]',
        options: CountryList,
        defaultValue: 'Germany',
    },
    {
        name: 'postal',
        placeholder: 'placeholders.postal_code',
        width: 'w-full md:w-[calc(50%-10px)]',
    },
    {
        name: 'city',
        placeholder: 'placeholders.city',
        width: 'w-full md:w-[calc(50%-10px)]',
    }
]

export const company: LoginInputType[] = [
    {
        name: 'companyName',
        placeholder: 'placeholders.company_name',
        width: 'w-full md:w-[calc(50%-10px)]',
    },
    {
        name: 'website',
        placeholder: 'placeholders.website',
        width: 'w-full md:w-[calc(50%-10px)]',
    },
    {
        name: 'companyCountry',
        placeholder: 'placeholders.country',
        type: 'dropdown',
        width: 'w-full md:w-[calc(50%-10px)]',
        options: CountryList,
    },
    {
        name: 'companyPostal',
        placeholder: 'placeholders.postal_code',
        width: 'w-full md:w-[calc(50%-10px)]',
    },
    {
        name: 'companyCity',
        placeholder: 'placeholders.city',
        width: 'w-full md:w-[calc(50%-10px)]',
    },
    {
        name: 'companyAddress',
        placeholder: 'placeholders.company_address',
        width: 'w-full md:w-[calc(50%-10px)]'
    },
    {
        name: 'VATID',
        placeholder: 'VATID',
        width: 'w-full md:w-[calc(50%-10px)]',
    },
    {
        name: 'profile',
        placeholder: 'placeholders.enter_investor_profile',
        width: 'w-full',
        type: 'textarea',
    }
]

export const general = ['terms.general.by_using', 'terms.general.as_you_browse', 'terms.general.Enlapa'];

export const privacy = ['terms.privacy.these_terms', 'terms.privacy.our_cookie'];

export const limitation = ['terms.limitation.we_are', 'terms.limitation.you_agree', 'terms.limitation.nothing', 'terms.limitation.these_terms'];

export const otherInfo = [ 'terms.other.we_may', 'terms.other.you_may', 'terms.other.no_join', 'terms.other.these_terms', 'terms.other.if_we', 'terms.other.each', 'terms.other.subject'];
