export const organizationColumns = [
    'admin_dashboard.name',
    'admin_dashboard.website',
    'admin_dashboard.owner',
    'admin_dashboard.members',
    'admin_dashboard.watched',
    'admin_dashboard.qualified_investor',
    'admin_dashboard.types',
    'admin_dashboard.exclusive',
    'admin_dashboard.email_notif'
];


export const usersColumns = [
    '',
    '',
    'admin_dashboard.full_name',
    'admin_dashboard.email',
    'admin_dashboard.phone',
    'admin_dashboard.registered',
    'admin_dashboard.login',
    'sign_up.interested_in',
    'admin_dashboard.unlocked',
    'admin_dashboard.active_areas',
    'admin_dashboard.disabled_areas',
    'admin_dashboard.search_criteria',
];

export const onboardingColumns = [
    '',
    '',
    'admin_dashboard.full_name',
    'admin_dashboard.email',
    'admin_dashboard.organization',
    'admin_dashboard.members',
    'admin_dashboard.invited',
    'admin_dashboard.qualified_investor',
    'admin_dashboard.exclusive',
    'admin_dashboard.types'
];

export const areaColumns: string[] = [
    'admin_dashboard.area',
    'admin_dashboard.name',
    'admin_dashboard.type',
    'admin_dashboard.size',
    'admin_dashboard.country',
    'admin_dashboard.owner',
    'admin_dashboard.mapBox',
    'placeholders.city',
    'admin_dashboard.date',
    'admin_dashboard.max',
    'admin_dashboard.access',
    'admin_dashboard.waitlist',
    ''
]