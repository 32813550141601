import { useTranslation } from "react-i18next";

export const UserRights = () => {
    const { t } = useTranslation();
    return (
        <div className="mb-[20px] w-full">
            <p className="mb-[10px] text-[20px] font-bold uppercase md:text-[30px]">
                {t('terms.user_rights.title')}
            </p>

            <p className="mb-[10px] ml-[20px] md:text-[18px]">
                {t('terms.user_rights.this_website')}
            </p>

            <p className="mb-[10px] ml-[20px] md:text-[18px]">
                {t('terms.user_rights.you_are_provided')}
            </p>

            <p className="mb-[10px] ml-[40px] md:text-[18px]">
                {t('terms.user_rights.adapt')}
            </p>

            <p className="mb-[10px] ml-[40px] md:text-[18px]">
                {t('terms.user_rights.commercialise')}
            </p>

            <p className="mb-[10px] ml-[40px] md:text-[18px]">
                {t('terms.user_rights.without')}
            </p>

            <p className="mb-[10px] ml-[20px] md:text-[18px]">
                {t('terms.user_rights.all_rights')}
            </p>

            <p className="mb-[10px] ml-[20px] md:text-[18px]">
                {t('terms.user_rights.all_trade')}
            </p>

            <p className="mb-[10px] ml-[20px] md:text-[18px]">
                {t('terms.user_rights.nothing')}
            </p>

            <p className="mb-[10px] ml-[20px] md:text-[18px]">
                {t('terms.user_rights.you_may')}
            </p>
        </div>
    )
}
