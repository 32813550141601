
import { convertToKML } from "./convertToKML";

const TOKEN = 'pk.eyJ1IjoiZW5sYXBhIiwiYSI6ImNsbzc2dndjbTAybmMydnBjNXE3OTVzbWkifQ.eoRkVu1mm9KFcTTq7plkew';

export const takeScreenshot = async (data, onError, zoom, onLoading) => {
    if (data) {
        const features = data.features.map(el => {
            return {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": el.geometry.coordinates,
                },
                "properties": {
                    "stroke-width": 3,
                    "fill-opacity": 0.5,
                    "stroke":'yellow'
                },
            }
        })
        
        const geoJson = {
            "type": "FeatureCollection",
            "features": features
        }

        const coordinates = geoJson.features[0].geometry.coordinates[0];
        const centerX = coordinates.reduce((sum, point) => sum + point[0], 0) / coordinates.length;
        const centerY = coordinates.reduce((sum, point) => sum + point[1], 0) / coordinates.length;

        try {
            const response = await fetch(
                `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/static/geojson(${encodeURIComponent(JSON.stringify(geoJson))})/${centerX},${centerY},${zoom}/1000x600@2x?access_token=${TOKEN}&zoom=0`, 
            {
                method: 'GET',
                responseType: 'arraybuffer',
            });

            if (response.ok) {
                const blob = new Blob([await response.arrayBuffer()], { type: 'image/png' });
                
                const file = new File([blob], 'Screenshot.png', { type: 'image/png' });
                return file;
            } else {
                onLoading(false);
                console.error('Error fetching map image: Response not OK');
            }
        } catch (error) {
            onError(true);
            const mapSection = document.getElementById('map');
            if (mapSection) {
                mapSection.scrollIntoView({ behavior: 'smooth' });
            }
            setTimeout(() => {
                onError(false)
            }, 5000)
        }
    }
};

export const downloadKML = async (data, onError) => {
    if (data) {
        const coordinates = data.features.map(el => el.geometry.coordinates[0]);
        
        const kmlContent = convertToKML(coordinates);
        const blob = new Blob([kmlContent], { type: 'application/vnd.google-earth.kml+xml' });

        const kmlFile = new File([blob], 'AreaKMLFile.kml', { type: 'application/vnd.google-earth.kml+xml' });

        return kmlFile;
    } else {
        onError(true);
        const mapSection = document.getElementById('map');
        if (mapSection) {
            mapSection.scrollIntoView({ behavior: 'smooth' });
        }
        setTimeout(() => {
            onError(false)
        }, 5000)
    }
};

export const getAreaMapData = async (longitude, latitude) => {
    const response = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=pk.eyJ1IjoiZW5sYXBhIiwiYSI6ImNsbzc2dndjbTAybmMydnBjNXE3OTVzbWkifQ.eoRkVu1mm9KFcTTq7plkew`);
    const data = await response.json();

    return data.features;
}
