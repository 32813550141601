/** @format */

import { useTranslation } from 'react-i18next';
import { EnergySlider } from './EnergySlider';
import { ListIcon } from '../../../components';

export const EnergyRevolution = (): JSX.Element => {
    const { t } = useTranslation()

    return (
        <div className="mx-[25px] mt-[35px] md:mt-[60px] section" >
            <div className="flex flex-col items-center lg:flex-row md:justify-between">
                <h2 className="md:[text-left] max-w-[351px] text-center text-[24px] font-medium md:text-[38px] md:leading-[50px]">
                    {t('home.energy_revolution.become_part')}
                </h2>

                <ListIcon
                    icon={'/assets/images/illustrations/property-owner.svg'}
                    title={t('home.energy_revolution.point_one')}
                    styles="hidden lg:flex md:w-[420px]"
                />
                <ListIcon
                    icon={'/assets/images/illustrations/invester.svg'}
                    title={t('home.energy_revolution.point_two')}
                    styles="hidden lg:flex md:md:w-[420px]"
                />
            </div>
            <div className="ml-[calc(50%-50vw)] mt-[35px] w-screen md:ml-auto md:w-full">
                <div className="h-[309px] bg-man-solar bg-cover bg-center bg-no-repeat md:h-[500px] xl:h-[625px] xl:bg-contain"></div>
            </div>

            <div className="mt-[46px] flex flex-col items-center justify-center gap-y-10 lg:hidden">
                <ListIcon
                    icon={'/assets/images/illustrations/property-owner.svg'}
                    title={t('home.energy_revolution.point_one')}
                />
                <ListIcon
                    icon={'/assets/images/illustrations/invester.svg'}
                    title={t('home.energy_revolution.point_two')}
                />
            </div>

            <EnergySlider />
        </div>
    )
}
