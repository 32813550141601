import { useEffect } from "react"
import { PhotoBlock } from "./PhotoBlock";
import { ProfileForm } from "./ProfileForm";
import { Loader } from "../../../components/Loader";
import { useAuth } from "../../../hooks";
import { useProfileInfo } from "../hooks/useProfileInfo";

export const ProfileInfo = () => {
  const { loggedIn } = useAuth();

  const {
    getUserInfo,
    isLoading,
    user,
    imageToUpload,
    setImageToUpload,
    userImage,
    setLoading
  } = useProfileInfo({ loggedIn })

  useEffect(() => {
    getUserInfo();
  }, [])

  if (isLoading) {
    return <Loader />
  }

  return (
    <div className="flex xl:flex-row flex-col gap-[50px] mb-[135px] items-center xl:items-start">
        <PhotoBlock 
            name={user?.firstName} 
            surname={user?.lastName} 
            onImage={setImageToUpload}
            uploadedImage={userImage}
        />

        {user && (
          <ProfileForm 
            user={user} 
            image={userImage} 
            onLoading={setLoading} 
            imageToUpload={imageToUpload}
            onUser={getUserInfo}
          />
        )}

        {/* <BillingInfo /> */}
    </div>
  )
}
