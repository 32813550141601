import { useTranslation } from "react-i18next";
import { OfferedSpaces } from "./components/OfferedSpaces";
import { useSearch } from "../SearchPage/hooks/useSearch";
import { useEffect, useState } from "react";
import { Flaeche } from '../../API';

import { useProfileInfo } from "../ProfilePage/hooks/useProfileInfo";
import { useAuth } from "../../hooks";
import { useHandleArea } from "../SearchPage/hooks/useHandleArea";
import { useOrganizationAreas } from "./hooks/useOrganizationAreas";

export const UserAreas = () => {
  const { t } = useTranslation();
  const { loggedIn, isInvestor } = useAuth();
  const [userAreas, setUserAreas] = useState<(Flaeche | null)[]>([]);
  const [favoriteFlaeches, setFavoriteFlaeches] = useState<(Flaeche | null)[]>([]);

  const {
    getAreas,
    areas,
    areaImages,
    loading,
    fetchImages
  } = useSearch({});

  useEffect(() => {
    getAreas();
    getUserInfo();
  }, []);

  const {
    user,
    getUserInfo
  } = useProfileInfo({ loggedIn });

  const {
    removeFavorite,
    deactivateArea
  } = useHandleArea({ user, getUserInfo })

  const {
    organizationAreas,
    organization
  } = useOrganizationAreas({ user });

  useEffect(() => {
    const userAreas = areas.filter(area => area?.ownerSub === loggedIn?.sub);
    const selectedAreas = user?.favoriteFlaeches 
      ? areas.filter(area => area && user?.favoriteFlaeches?.includes(area?.id) && !area?.disabled) : [];

    setUserAreas(userAreas);
    setFavoriteFlaeches(selectedAreas);
  }, [areas, loggedIn, user])
  
  return (
    <div className='lg:pt-[86px] md:pt-[100px] lg:px-[60px] px-[25px] pt-[30px]'>
        {isInvestor && !userAreas.length ? (
            null
          ) : (
            <div className="md:mb-[95px] mb-[70px]">
              <OfferedSpaces 
                title={t('profile.offered')} 
                areas={userAreas}
                images={areaImages} 
                loading={loading}
                onDeactivate={deactivateArea}
                fetchImages={fetchImages}
                withOwner={false}
              />
            </div>
          )}

          {(isInvestor || organization) && (
            <div className="md:mb-[95px] mb-[70px]">
              <OfferedSpaces 
                title={t('profile.areas')}
                areas={organizationAreas}
                images={areaImages} 
                loading={loading}
                fetchImages={fetchImages}
                withOwner={true}
              />
            </div>
          )}

          {isInvestor && !favoriteFlaeches.length ? (
            null
          ) : (
            <div className="md:mb-[160px] mb-[70px]">
              <OfferedSpaces 
                title={t('profile.favorite')} 
                areas={favoriteFlaeches}
                images={areaImages} 
                loading={loading}
                onRemoveFavorite={removeFavorite}
                selected
                fetchImages={fetchImages}
                withOwner={false}
              />
            </div>
          )}
    </div>
  )
}
