import { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';
import { useAuth, useLanguage } from '../../../hooks';
import { useNavigate } from 'react-router-dom';
import { ThanksPageContext } from '../../../contexts';
import { Control, FieldValues, SubmitHandler, useWatch } from 'react-hook-form';
import {
    TOfferAdsAreaSchema,
    createPdf,
    downloadKML,
    fetchEnergyData,
    getAreaMapData,
    takeScreenshot,
} from '../../../helpers';
import { useTranslation } from 'react-i18next';
import { API, Storage, graphqlOperation } from 'aws-amplify';
import { GraphQLQuery } from '@aws-amplify/api';
import {
    CreateFlaecheMutation,
    UpdateIncompleteAreasFromOfferFormMutation,
    UpdateUserInfoMutation,
} from '../../../API';
import {
    createFlaeche,
    updateIncompleteAreasFromOfferForm,
    updateUserInfo,
} from '../../../graphql/mutations';
import { Links } from '../../../settings';
import { useIncompleteData } from './useIncompleteData';
import { createNewArea, sendFacebookValue } from '../helpers';
import { AreaFeatures } from '../../../types';
import i18n from '../../../i18n';

interface Options {
    control: Control<TOfferAdsAreaSchema>;
}

export const useAreaCreation = ({ control }: Options) => {
    const { loggedIn, loginUser } = useAuth();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { selectedLanguage } = useLanguage({ i18n });
    const { setData: setThanksData } = useContext(ThanksPageContext);

    const [phone, setPhone] = useState(loggedIn.phone);
    const [step, setStep] = useState(1);
    const [areaSize, setAreaSize] = useState(0);
    const [mapElevation, setMapElevation] = useState(0);
    const [zoom, setZoom] = useState(12);
    const [data, setData] = useState<any>(null);
    const [mapError, setMapError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [screenShot, setScreenShot] = useState<File>();
    const [error, setError] = useState(false);
    const [kml, setKml] = useState<File>();
    const [fieldsData, setFieldsData] = useState<FieldValues | null>(null);
    const [areaFeatures, setAreaFeatures] = useState<AreaFeatures | null>(null);
    const [clickCount, setClickCount] = useState(0);
    const [doubleClick, setDoubleClick] = useState(false);
    const [showFinishModal, setShowFinishModal] = useState(false);
    const [firstName, setFirstName] = useState(loggedIn?.firstName);
    const [lastName, setLastName] = useState(loggedIn?.lastName);
    const [submittedError, setSubmittedError] = useState(false);

    const watchedCountry = useWatch({
        control,
        name: 'country',
        defaultValue: '',
    })

    const {
        setGeolocation,
        incompleteId,
        removeIncompleteData
    } = useIncompleteData({ setFieldsData, fieldsData });

    const onSubmit: SubmitHandler<TOfferAdsAreaSchema> = async (formData) => {
        if (step === 1) {
            setFieldsData(formData);

            const incomplete = {
                id: incompleteId,
                areaType: formData.type,
                areaSize: formData.size
            };

            await API.graphql<GraphQLQuery<UpdateIncompleteAreasFromOfferFormMutation>>(
                graphqlOperation(updateIncompleteAreasFromOfferForm, { input: incomplete })
            );

            setStep(step + 1);

            return;
        }

        if (step === 2) {
            if (!firstName || !lastName || !phone) {
                setSubmittedError(true);
                return;
            }

            const editingUser = {
                sub: loggedIn.sub,
                id: loggedIn.id,
                firstName: firstName,
                lastName: lastName,
                phone: phone ? phone : loggedIn.phone,
            };

            const res = await API.graphql<
                GraphQLQuery<UpdateUserInfoMutation>
            >(graphqlOperation(updateUserInfo, { input: editingUser }));

            if (res?.data?.updateUserInfo) {
                loginUser(res?.data?.updateUserInfo);
            }

            setStep(step + 1);
            setSubmittedError(false);
        }

        if (step === 3) {
            if (!data) {
                setMapError(true);
                return;
            }

            try {
                setIsLoading(true);
                const screen = await takeScreenshot(
                    data,
                    setError,
                    zoom,
                    setIsLoading
                );
                const kml = await downloadKML(data, setError);

                const [longitude, latitude] =
                    data?.features[0]?.geometry?.coordinates[0][0];

                setGeolocation({
                    longitude,
                    latitude
                });

                const areaData = await getAreaMapData(longitude, latitude);

                setScreenShot(screen);
                setKml(kml);

                const features = {
                    country: areaData.find((el: any) => el.id.includes('country'))?.text ?? '',
                    state: areaData.find((el: any) => el.id.includes('region'))?.text ?? '',
                    city: areaData.find((el: any) => el.id.includes('place'))?.text ?? '',
                    street:
                        areaData[0]?.text +
                        ' ' +
                        (areaData[0].adress ? areaData[0].address : ''),
                    postCode: areaData.find((el: any) => el.id.includes('postcode'))?.text ?? '',
                };

                setAreaFeatures(features);
                setStep(step + 1);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                setError(true);
                console.log('Error:', error);
            }
        }

        if (step === 4) {
            if (!watchedCountry) {
                return;
            }

            setStep(step + 1);

            const features = {
                country: formData.country,
                state: formData.state,
                city: formData.city,
                street: formData.street,
                postCode: formData.postalCode,
            }

            setFieldsData(formData);
            setAreaFeatures(features);
            setIsLoading(true);

            const pdf = await createPdf(
                loggedIn.sub,
                formData,
                t,
                screenShot ? screenShot : null
            )

            if (!screenShot || !kml || !pdf) {
                throw new Error('Failed to create necessary files.');
            }

            const thanksData = {
                area: formData.size,
                type: formData.type,
            };

            setThanksData(thanksData);

            const [longitude, latitude] =
                data?.features[0]?.geometry?.coordinates[0][0];

            const energyData = await fetchEnergyData(latitude, longitude);

            const monthly = energyData?.outputs?.monthly?.fixed.map(
                (month: any) => month.E_m
            );

            const areaToCreate = createNewArea(
                loggedIn,
                formData, 
                areaSize, 
                { latitude, longitude, mapElevation },
                zoom,
                energyData,
                data,
                monthly,
                selectedLanguage
            );

            const files = [kml, screenShot, pdf];
            let areaId: string | undefined;

            try {
                const createRes = await API.graphql<
                    GraphQLQuery<CreateFlaecheMutation>
                >(graphqlOperation(createFlaeche, { input: areaToCreate }));

                const purchaseValue = 0.01 * formData.size;

                sendFacebookValue(purchaseValue, createRes);

                areaId = createRes?.data?.createFlaeche?.id;

                if (files.length > 0 && areaId) {
                    await Promise.all(
                        files.map((file) =>
                            Storage.put(`${areaId}_${file?.name}`, file)
                        )
                    )
                }

                await removeIncompleteData();

                setIsLoading(false);
                navigate(Links.thank_you.index, { state: { path: '/offer-meta-ads' }});

                if (typeof window !== 'undefined' && window.fbq != null) {
                    window.fbq('track', 'Lead');
                }
            } catch (error) {
                console.log(error);
                setIsLoading(false);
            }
        }
    }

    const handleChangeName = useCallback(
        (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setFirstName(e.target.value)
        },
        []
    )

    const handleChangeSur = useCallback(
        (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setLastName(e.target.value)
        },
        []
    )

    useEffect(() => {
        let timeoutId1: NodeJS.Timeout

        if (clickCount === 2 && !data && !doubleClick) {
            setShowFinishModal(true);

            setDoubleClick(true);

            timeoutId1 = setTimeout(() => {
                setShowFinishModal(false);
            }, 5000)

            return;
        }

        return () => {
            clearTimeout(timeoutId1)
        }
    }, [clickCount, data])

    useEffect(() => {
        let timeoutId1: NodeJS.Timeout;

        if (error) {
            timeoutId1 = setTimeout(() => {
                setError(false);
            }, 3000)

            return;
        }

        return () => {
            clearTimeout(timeoutId1)
        }
    }, [error])

    return {
        phone,
        step,
        zoom,
        data,
        error,
        setMapError,
        mapError,
        isLoading,
        mapElevation,
        areaSize,
        areaFeatures,
        fieldsData,
        onSubmit,
        clickCount,
        setClickCount,
        showFinishModal,
        setAreaSize,
        setAreaFeatures,
        setData,
        setError,
        setZoom,
        setPhone,
        setStep,
        submittedError,
        firstName,
        setFirstName,
        handleChangeName,
        handleChangeSur,
        lastName,
        setLastName,
        setMapElevation,
        setGeolocation,
        watchedCountry
    }
}
