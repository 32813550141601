import { useTranslation } from "react-i18next";
import { LegalData } from "../../types";
import { GradientBlock, LandOwnerBlock } from "../../components";
import { CompensationItem } from "./components";
import { usePageDescription } from "../../hooks";
import { Helmet } from "react-helmet";

const data: LegalData = {
    subtitle: 'compensation.tailoring',
    title: 'compensation.title',
    description: 'compensation.desc',
    block: 'compensation.crafting',
    image: "/assets/images/landOwnerImage2.png"
};

export const Compensation = () => {
  const { t } = useTranslation();

  usePageDescription({ 
    title: t('title.compensation'),
    description: t('description.compensation')
  });

  return (
    <>
        <Helmet>
            <meta name='title' content={t('title.compensation')} />
            <meta name='description' content={t('description.compensation')} />
        </Helmet>
        <div className="flex flex-col lg:pt-[110px]">
        <LandOwnerBlock data={data} />

        <div className="w-full flex flex-col items-center md:px-[100px] px-[25px]">
            <p className="uppercase text-[#464547] md:text-[30px] font-bold md:mb-[80px] mb-[40px]">
                {t('compensation.possible')}
            </p>

            <div className="flex flex-wrap xl:flex-row justify-center w-full">
                <CompensationItem
                    type={t('compensation.one_time')}
                    desc={t('compensation.one_time_desc')}
                    benefit={t('compensation.one_time_benefit')}
                    image="/assets/icons/Iconpayment.svg"
                />

                <CompensationItem
                    type={t('compensation.monthly')}
                    desc={t('compensation.monthly_desc')}
                    benefit={t('compensation.monthly_benefit')}
                    image="/assets/icons/Iconmonthly.svg"
                />

                <CompensationItem
                    type={t('compensation.electricity')}
                    desc={t('compensation.electricity_desc')}
                    benefit={t('compensation.electricity_benefit')}
                    image="/assets/icons/Iconelectricity.svg"
                />

                <CompensationItem
                    type={t('compensation.share')}
                    desc={t('compensation.share_desc')}
                    benefit={t('compensation.share_benefit')}
                    image="/assets/icons/Iconshare.svg"
                />

                <CompensationItem
                    type={t('compensation.perfomance')}
                    desc={t('compensation.perfomance_desc')}
                    benefit={t('compensation.perfomance_benefit')}
                    image="/assets/icons/Iconperformance.svg"
                />

                <CompensationItem
                    type={t('compensation.hybrid')}
                    desc={t('compensation.hybrid_desc')}
                    benefit={t('compensation.hybrid_benefit')}
                    image="/assets/icons/Iconhybrid.svg"
                />
            </div>

            <div className="flex flex-wrap justify-center mb-[30px] md:mb-[100px]">
                <div className="border-[1px] p-[20px] xl:h-[400px] max-w-[414px] md:shrink-0 mb-[50px] min-[1026px]:mr-[412px] min-[1440px]:mr-0">
                    <img src="/assets/icons/Iconcommunity.svg" alt="payment" className="h-[70px] mb-[10px]"/>
                    <p className="md:text-[25px] text-[20px] font-bold mb-[20px]">
                        {t('compensation.community')}
                    </p>

                    <p className="text-[15px] mb-[20px]">
                        {t('compensation.community_desc')}
                    </p>

                    <p className="text-[15px] text-[#11470E] font-medium">Benefit</p>

                    <p className="text-[15px] mb-[40px] flex gap-[15px] items-center mb-[60px]">
                        {t('compensation.community_benefit')}
                    </p>
                </div>

                <div className="w-full bg-[#E8E8E8] flex flex-col justify-center lg:w-[825px] xl:h-[400px] py-[20px]">
                    <p className="md:text-[26px] text-[20px] font-bold mb-[27px] md:px-[100px] px-[25px]">
                        {t('compensation.negotiating')}
                    </p>

                    <p className="md:text-[17px] mb-[60px] md:px-[100px] px-[25px]">
                        {t('compensation.negotiating_desc')}
                    </p>
                </div>
            </div>

            <div className="bg-[#F6F7F3] py-[65px] md:px-[70px] px-[25px] relative md:mb-[95px] mb-[40px]">
                <p className="md:text-[27px] text-[20px] mb-[20px] font-medium">
                    {t('compensation.partnership')}
                </p>

                <p className="md:text-[20px] mb-[20px] leading-[50px]">
                    {t('compensation.partnership_desc')}
                </p>

                <img 
                    src="/assets/icons/Groupdesign.svg" 
                    alt="design" 
                    className="absolute bottom-0 right-0 h-[250px]"
                />
            </div>
        </div>

        <GradientBlock
            title={t('home.call_to_action_money')}
            buttonText={t('buttons.offer_your_space_now')}
            image="/assets/images/gradiend-money.png"
        />
    </div>
    </>

  )
}
