import { checkType, convertToHectares } from '../../helpers';
import { Flaeche } from '../../API';
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../../hooks';

interface AreaSizeProps {
    areaDetails?: Flaeche | null;
    mapbox?: boolean;
}

export const AreaSize = ({ areaDetails, mapbox }: AreaSizeProps) => {
    const { t } = useTranslation();
    const { selectedLanguage } = useLanguage({ i18n });

    if (mapbox) {
        return (
            <>
                {checkType(areaDetails?.areaType)
                    ? convertToHectares(areaDetails?.areSizeMapbox) +
                      ' ' +
                      t('search.ha')
                    : areaDetails?.areSizeMapbox &&
                      areaDetails?.areSizeMapbox.toLocaleString(
                          selectedLanguage === 'de' ? 'de-DE' : 'en-US'
                      ) +
                          ' ' +
                          'm²'}
            </>
        )
    }

    return (
        <>
            {checkType(areaDetails?.areaType)
                ? convertToHectares(areaDetails?.areaSize) +
                  ' ' +
                  t('search.ha')
                : areaDetails?.areaSize &&
                  areaDetails?.areaSize.toLocaleString(
                      selectedLanguage === 'de' ? 'de-DE' : 'en-US'
                  ) +
                      ' ' +
                      'm²'}
        </>
    )
}
