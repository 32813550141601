import { t } from "i18next";
import { LegalData } from "../../types";

interface LandOwnerBlockProps {
    data: LegalData;
}

export const LandOwnerBlock = (props: LandOwnerBlockProps) => {
  const { data } = props;
  const { subtitle, title, description, block, image } = data;

  return (
    <div 
        className="bg-[#F6F7F3] lg:pt-[50px] md:pt-[100px] pt-[50px] xl:pb-[100px] 
        lg:pb-[140px] pb-[20px] xl:px-[100px] px-[25px] md:mb-[100px] mb-[50px] relative flex flex-col lg:flex-row justify-between"
    >
        <div>
            <p className="md:text-[27px] text-[20px] text-primary mb-[30px] font-medium">
                {t(subtitle)}
            </p>

            <h1 className="md:text-[45px] text-[25px] font-bold mb-[10px] lg:max-w-[450px]">
                {t(title)}
            </h1>

            <p className="text-[16px] mb-[30px] lg:max-w-[450px]">
                {t(description)}
            </p>
            </div>

            <div 
            className="bg-primary p-[15px] xl:max-w-[280px] max-w-[300px] 
            font-bold absolute xl:left-[47%] left-[50%] lg:bottom-[160px] bottom-[100px] z-10"
            >
            <p className="text-[#464547] lg:text-[25px] text-[18px]">
                {t(block)}
            </p>
        </div>

        <img 
            src={image} 
            alt="Enlapa design"
            className="lg:mt-[-100px] lg:max-h-[600px] max-w-[500px] lg:max-w-none"
        />
    </div>
  )
}
