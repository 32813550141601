/** @format */

import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useTranslation } from 'react-i18next';
import { TUserLoginSchema, formatSignUpError, userLoginSchema } from '../../../helpers';
import { Button, CustomInput, Heading } from '../../../components';
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import { SocialLoginButtons } from './SocialLoginButtons';
import { CircularProgress } from '@mui/material';
import { useLocalStorage } from '../../../hooks';
import { PATH } from '../../../utils';

/** TYPES */
interface UserLoginProps {
    nextStep: (userData: TUserLoginSchema) => void;
    isLoginPage: boolean;
    loginFailure?: boolean;
    title: string;
    inProcess: boolean;
    onSocialLogin: (provider: CognitoHostedUIIdentityProvider) => void;
}

export const UserLogin = (props: UserLoginProps): JSX.Element => {
    const { 
        nextStep, 
        title,
        isLoginPage, 
        loginFailure,
        onSocialLogin,
        inProcess
    } = props;

    const navigate = useNavigate();
    const { t } = useTranslation();

    const [path, setPath] = useLocalStorage(PATH, '');

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm<TUserLoginSchema>({
        resolver: yupResolver(userLoginSchema),
        mode: 'all'
    });

    const onSubmitFn: SubmitHandler<TUserLoginSchema> = (data): void => {
        nextStep(data);
    }

    const switchPages = useCallback((): void => {
        if (isLoginPage) navigate('/signup');
        else navigate('/login');
    }, [isLoginPage, navigate])

    const handleForgotPassword = useCallback(() => {
        navigate('/reset-password');
    }, [navigate])

    return (
        <form onSubmit={handleSubmit(onSubmitFn)} className="w-full flex md:flex-row flex-col justify-center md:gap-[60px]">
            <div className={`order-1 flex flex-col items-center 
                ${path !== '/offer-meta-ads' && 'md:items-start'} 
                mb-[30px]`
            }>
                <Heading
                    title={t('social_login')}
                    size="text-xs lg:text-base md:text-[13px]"
                    tracking="lg:tracking-[3px] tracking-[1px]"
                    sx="uppercase mb-[20px]"
                />
                <SocialLoginButtons onSocialLogin={onSocialLogin} />
            </div>

            
            {path !== '/offer-meta-ads' && (
                <>
                    <div className='flex md:flex-col items-center order-2 mb-[25px]'>
                        <hr className="w-[1px] h-[150px] bg-[#9e9e9e] md:block hidden" />
                        <hr className="h-[1px] w-[45%] bg-[#9e9e9e] md:hidden block" />

                        <div className='h-[50px] w-[50px] text-[#9e9e9e] flex-nowrap border-[1px] border-[#9e9e9e] rounded-full flex justify-center items-center'>
                            {t('or')}
                        </div>

                        <hr className="w-[1px] h-[150px] bg-[#9e9e9e] md:block hidden" />
                        <hr className="h-[1px] w-[45%] bg-[#9e9e9e] md:hidden block" />
                    </div>

                    <div className="flex flex-col items-center md:items-start flex-wrap gap-y-5 md:gap-x-5 order-3">
                        <Heading
                            title={t(title)}
                            size="text-xs lg:text-base md:text-[13px]"
                            tracking="lg:tracking-[3px] tracking-[1px]"
                            sx="uppercase"
                        />

                        <div className='w-[300px]' key='email'>
                            <div>
                                <CustomInput
                                    placeholder={t('placeholders.email')}
                                    sx={`bg-white border-grey-50 border-solid border-[1px] ${
                                        errors[
                                            'email' as keyof TUserLoginSchema
                                        ] && 'border-red-400 outline-red-400'
                                    }`}
                                    register={register}
                                    name={'email'}
                                    width="w-[300px]"
                                    setValue={setValue}
                                />
                                {errors['email' as keyof TUserLoginSchema] && (
                                    <span className="mt-1 block text-red-400">
                                        {t('valid_email')}
                                    </span>
                                )}

                            </div>
                        </div>

                        <div className='w-[300px]' key='password'>
                            <div>
                                <CustomInput
                                    type='password'
                                    placeholder={isLoginPage ? t('placeholders.password') : t('placeholders.set_password')}
                                    sx={`bg-white border-grey-50 border-solid border-[1px] ${
                                        errors[
                                            'password' as keyof TUserLoginSchema
                                        ] && 'border-red-400 outline-red-400'
                                    }`}
                                    register={register}
                                    width="w-[300px]"
                                    name='password'
                                    setValue={setValue}
                                />
                                {errors['password' as keyof TUserLoginSchema] ? (
                                    <span className="mt-1 block text-red-400">
                                        {
                                            t(errors[
                                                'password' as keyof TUserLoginSchema
                                            ]?.message || '')
                                        }
                                    </span>
                                ) : (
                                    (loginFailure && isLoginPage) && (
                                        <span className="mt-1 block text-red-400">
                                            {t(formatSignUpError(String(loginFailure)))}
                                        </span>
                                    )
                                )}
                            </div>
                        </div>

                        <div className={`flex flex-col  justify-center 
                            ${isLoginPage ?  "md:items-start items-center" : "items-center"}  
                            gap-y-4 md:gap-x-6 mb-[30px]`}
                        >
                            <Button
                                color="Secondary"
                                width="w-[300px] h-[65px]"
                                disabled={inProcess}
                            >
                                {inProcess 
                                    ? <CircularProgress color="success" size={25}/> 
                                    : isLoginPage 
                                        ? `${t('buttons.login')}` 
                                        : `${t('buttons.create')}`
                                }
                            </Button>

                            <div className='flex md:gap-[15px] gap-0 justify-center md:items-start items-center flex-col md:flex-row'>
                                <Button
                                    type="button"
                                    width="w-fit"
                                    sx="underline"
                                    onClick={switchPages}
                                >
                                    {isLoginPage
                                        ? `${t('login.create_new_account')}`
                                        : `${t('login.already_have_account')}`
                                    }
                                </Button>

                                {isLoginPage && (
                                    <Button
                                        type="button"
                                        width="w-fit"
                                        sx="underline"
                                        onClick={handleForgotPassword}
                                    >
                                        {t('login.forgot_password')}
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </form>
    )
}
