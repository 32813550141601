import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLQuery } from '@aws-amplify/api';
import { UpdateFlaecheMutation } from '../../../API';
import { updateFlaeche } from '../../../graphql/mutations';

interface DisabledButtonsProps {
    id: string | undefined;
}

export const DisabledButtons = ({ id }: DisabledButtonsProps) => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const handleNavigate = useCallback(() => {
        navigate(`/search/${id}`)
    }, [navigate, id])

    const activateArea = useCallback(() => {
        const activeArea = {
            disabled: false,
            id: id || '',
        }

        API.graphql<GraphQLQuery<UpdateFlaecheMutation>>(
            graphqlOperation(updateFlaeche, { input: activeArea })
        )
            .then((res) => {
                window.location.reload()
            })
            .catch((err) => {
                console.log(err)
            })
    }, [id])

    return (
        <div
            className="absolute z-10 flex h-full w-full flex-col
        justify-center gap-[20px] px-[20px] opacity-0 transition-all duration-500 ease-in-out group-hover:opacity-100"
        >
            <Button
                width="w-full"
                sx="border-[1px] border-[#111A2126]"
                onClick={handleNavigate}
            >
                {t('home.see_details')}
            </Button>

            <Button
                color="Secondary"
                width="w-full"
                textColor="text-white"
                onClick={activateArea}
            >
                {t('profile.activate')}
            </Button>
        </div>
    )
}
