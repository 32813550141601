import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom";
import { Links } from "../../settings";
import { CallToAction } from "../../components";
import { usePageDescription } from "../../hooks";
import { Helmet } from "react-helmet";

export const OurVision = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  usePageDescription({ 
    title: t('title.our_vision'),
    description: t('description.our_vision')
  });

  const handleNavigate = () => {
    navigate(Links.offer.index);
  }

  return (
    <>
        <Helmet>
            <meta name='title' content={t('title.our_vision')} />
            <meta name='description' content={t('description.our_vision')} />
        </Helmet>
        <div className="flex flex-col items-center py-[50px] md:px-[100px] px-[25px]">
            <div>
                <h1 className="text-[40px] font-medium text-[#11470E]">
                    {t('our_vision.title')}
                </h1>

                <p className="font-medium md:text-[27px] text-[20px] mb-[20px]">
                    {t('our_vision.future')}
                </p>

                <p className="md:text-[20px] font-medium mb-[40px]">
                    {t('our_vision.vision_description')}
                </p>
            </div>

            <div className="mb-[50px]">
                <p className="text-[30px] font-medium text-[#B0CC55] mb-[15px]">
                    {t('our_vision.modernization')}
                </p>

                <div className="mb-[30px]">
                    <p className="mb-[20px]">
                        {t('our_vision.modernization_topic')}
                    </p>

                    <p>
                        {t('our_vision.modernization_topic2')}
                    </p>
                </div>

                <p className="text-[30px] font-medium text-[#B0CC55] mb-[15px]">
                    {t('our_vision.solar')}
                </p>

                <p className="mb-[30px]">
                    {t('our_vision.solar_topic')}
                </p>


                <p className="text-[30px] font-medium text-[#B0CC55] mb-[15px]">
                    {t('our_vision.home_owner')}
                </p>

                <p className="mb-[30px]">
                    {t('our_vision.home_owner_topic')}
                </p>


                <p className="text-[30px] font-medium text-[#B0CC55] mb-[15px]">
                    {t('our_vision.driving')}
                </p>

                <p className="mb-[30px]">
                    {t('our_vision.driving_topic')}                
                </p>

                <p className="text-[30px] font-medium text-[#B0CC55] mb-[15px]">
                    {t('our_vision.commitment')}
                </p>

                <p className="mb-[30px]">
                    {t('our_vision.commitment_topic')}
                </p>
            </div>

            <p className="text-[30px] font-medium text-[#B0CC55] mb-[15px]">
                {t('about.Enlapa')}
            </p>

            <div className="m-auto mb-[-180px] relative z-10 mt-[60px] max-w-[1178px]">
                <CallToAction
                    title={t('home.call_to_action_environment')}
                    btnTitle={t('buttons.offer_your_space_now')}
                    bgColor="bg-secondary text-white"
                    onClick={handleNavigate}
                    tag="p"
                />
            </div>
        </div>

        <div className="ml-[calc(50%-50vw)] relative h-full w-screen bg-[lightgray]">
            <div
                className="absolute z-5 h-[200px] w-full"
                style={{
                    background:
                        'linear-gradient(180deg, #FFFF 0%, rgba(243, 243, 243, 0.76) 40.12%, rgba(243, 243, 243, 0.00) 89.67%)',
                }}
            ></div>
            <div className="z-[1] h-[451px] w-full bg-solarCell bg-cover bg-center  bg-no-repeat md:h-[857px]"></div>
        </div>
    </>
  )
}
