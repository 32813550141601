import { useTranslation } from "react-i18next";

export const Links = () => {
    const { t } = useTranslation();

    return (
        <div className="mb-[20px] w-full">
            <p className="mb-[10px] text-[20px] font-bold uppercase md:text-[30px]">
                {t('terms.links.title')}
            </p>

            <p className="mb-[10px] ml-[20px] md:text-[18px]">
                {t('terms.links.this_website')}
            </p>

            <p className="mb-[10px] ml-[20px] md:text-[18px]">
                {t('terms.links.you_may')}
            </p>

            <p className="mb-[10px] ml-[20px] md:text-[18px]">
                {t('terms.links.your_use')}
            </p>

            <p className="mb-[10px] ml-[20px] md:text-[18px]">
                {t('terms.links.unless')}
            </p>

            <p className="mb-[10px] ml-[40px] md:text-[18px]">
                {t('terms.links.no_relationship')}
            </p>

            <p className="mb-[10px] ml-[40px] md:text-[18px]">
                {t('terms.links.no_control')}
            </p>
        </div>
    )
}
