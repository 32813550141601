import { ChangeEvent, SetStateAction, useState } from "react";
import { useStyles } from "../../../styles/jss/useStyles";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

interface UserInfoInputProps {
    value: string;
    error: boolean;
    placeholder: string;
    onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

export const UserInfoInput = ({
    value,
    error,
    placeholder,
    onChange
}: UserInfoInputProps) => {
    const [focused, setIsFocused] = useState(false);
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className="TextField-with-border-radius relative w-full">
            <TextField
                className={classes.root}
                value={value}
                label={placeholder}
                error={!value && error}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                style={{
                    width: '100%',
                }}
                inputProps={{
                    style: {
                        padding: '18px 20px',
                    },
                }}
                onChange={(e) =>
                    onChange(e)
                }
            />

            {!value && !focused && (
                <div
                    className={`req_placeholder absolute left-[100px] top-[13px] text-[24px] text-[#FF0F00]`}
                >
                    <span className="req_placeholder">*</span>
                </div>
            )}
        </div>
    )
}
