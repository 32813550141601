import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { LazyLoadImage } from '../../../components'
import { Buttons } from '../../SearchPage/components'
import { Links } from '../../../settings'
import { Flaeche, GetUserInfoBySubQuery, UserInfo } from '../../../API'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../../hooks'
import { fetchImage } from '../../../utils'
import { ProgressOrg } from './ProgressOrg'
import { AreaDescOrganization } from './AreaDescOrganization'
import { API, graphqlOperation } from 'aws-amplify'
import { GraphQLQuery } from '@aws-amplify/api'
import { getUserInfoBySub } from '../../../graphql/queries'

interface AreaCardProps {
    item?: Flaeche;
    image: string;
    width?: string;
    selected?: boolean;
    withOwner?: boolean;
    onDeactivate?: (id: string | undefined) => void;
    onFavorite?: (id: string | undefined) => void;
    onRemoveFavorite?: (id: string | undefined) => void;
    fetchImages: (id?: string | undefined) => Promise<void>;
}

export const OrganizationCard = (props: AreaCardProps) => {
    const { 
        item, 
        width="w-[315px]", 
        image, 
        onDeactivate, 
        fetchImages,
    } = props;
    const { t } = useTranslation();
    const { loggedIn } = useAuth();

    const [imageUrl, setImageUrl] = useState<string>("");
    const [owner, setOwner] = useState<UserInfo | null>(null);

    const ownArea = useMemo(() => (
        loggedIn && loggedIn.sub === item?.ownerSub
    ), [loggedIn, item]);

    const getOwner = async () => {
        try {
            if (item) {
                const { data } = await API.graphql<
                  GraphQLQuery<GetUserInfoBySubQuery
                >>(graphqlOperation(getUserInfoBySub, {
                    sub: item.ownerSub
                }))
        
                if (data?.getUserInfoBySub?.items[0]) {
                    setOwner(data?.getUserInfoBySub?.items[0]);
                }
            } 
        } catch (error: any) {
            console.log("ERROR", error);
        }
    }

    useEffect(() => {
        fetchImages(item?.id);
        getOwner();
    }, [item])

    useEffect(() => {
        async function loadImage() {
            try {
                const fetchedImageUrl = await fetchImage(image);
                if (fetchedImageUrl) {
                    setImageUrl(fetchedImageUrl);
                }
            } catch (error) {
                console.error('Error loading image:', error);
            }
        }
        loadImage();
    }, [image]);

    return (
        <div
            className={`border-box relative ${width} rounded-[12px] border-[1px] border-[#111A2126] hover:shadow-md`}
        >
            <div className="flex items-center justify-between px-[15px] py-[15px]">
                <Link to={`/search/${item?.id}`}>
                    <p className="text-[24px] font-medium">
                        {item?.areaType &&
                            t(item.areaType).slice(0, 1).toUpperCase() +
                                t(item.areaType).slice(1)}
                    </p>
                </Link>

                <div className="flex items-center gap-[10px]">
                        {owner?.phone && (
                            <div className="group relative">
                                <img
                                    src="/assets/icons/phone-icon.png"
                                    className="h-[25px] cursor-pointer"
                                />

                                <div
                                    className="absolute bottom-[-40px] left-[-30px] z-10 
                                    hidden rounded-[5px] border-[1px] bg-white p-[10px] text-[12px] font-normal group-hover:block"
                                >
                                    {owner.phone}
                                </div>
                            </div>
                        )}

                        <div className="group relative">
                            <img
                                src="/assets/icons/email-icon.png"
                                className="h-[25px] cursor-pointer"
                            />

                            <div
                                className="absolute bottom-[-40px] left-[-40px] z-10 
                                hidden rounded-[5px] border-[1px] bg-white p-[10px] text-[12px] font-normal group-hover:block"
                            >
                                {owner?.email}
                            </div>
                        </div>
                </div>
            </div>

            <div className="group relative overflow-hidden">
                <Link to={`${Links.search.index}/${item?.id}`}>
                    <div className="relative h-[230px]">
                        <LazyLoadImage
                            src={imageUrl}
                            alt=""
                            className="h-[230px] w-full transition-all duration-500 group-hover:scale-105"
                        />
                    </div>
                </Link>

                <Buttons
                    ownArea={ownArea}
                    areaId={item?.id}
                    onDeactivate={onDeactivate}
                />
            </div>

            {item && <AreaDescOrganization area={item} />}

            <div className='flex flex-col justify-center'>
                <p className='px-[15px] py-[5px] font-medium text-[15px]'>{t('profile.progress')}</p>

                {item && <ProgressOrg area={item} />}
            </div>
        </div>
    )
}
