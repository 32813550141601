import { useTranslation } from "react-i18next"
import { usePageDescription } from "../../hooks";
import { Helmet } from "react-helmet";

export const Feedback = () => {
  const { t } = useTranslation();

  usePageDescription({ 
    title: t('title.feedback'),
    description: t('description.feedback')
  });

  return (
    <>
        <Helmet>
            <meta name='title' content={t('title.feedback')} />
            <meta name='description' content={t('description.feedback')} />
        </Helmet>
        <div className="flex flex-col py-[50px] md:px-[100px] px-[25px]">
        <h1 className="md:text-[38px] text-[25px] text-secondary font-bold mb-[10px]">
            {t('feedback.title')}
        </h1>

        <p className="md:text-[25px] text-[15px] text-primary font-bold mb-[10px]">
            {t('feedback.we_value')}
        </p>

        <p className="md:text-[20px] mb-[20px]">
            {t('feedback.desc')}
        </p>

        <p className="md:text-[25px] text-[15px] text-primary font-bold mb-[10px]">
            {t('feedback.share')}
        </p>

        <p className="md:text-[20px] mb-[40px]">
            {t('feedback.share_option_one')}{" "}
            <a className="text-primary" href="mailto:support@enlapa.com">support@enlapa.com</a> .
        </p>

        <p className="md:text-[20px] mb-[40px]">
            {t('feedback.share_option_two')}
        </p>

        <p className="md:text-[25px] text-[15px] text-primary font-bold mb-[10px]">
            {t('feedback.thank_you')}
        </p>

        <p className="md:text-[20px] mb-[40px]">
            {t('feedback.thank_you_desc')}
        </p>
    </div>
    </>

  )
}
