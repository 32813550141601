import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
    root: {
      "& label.Mui-focused": {
        color: "#11470E"
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#11470E"
      },
      "& .MuiFilledInput-underline:after": {
        borderBottomColor: "#11470E"
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "#11470E"
        }
      }
    },
    formControl: {
      "& label.Mui-focused": {
        color: "#11470E"
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#11470E"
      },
      "& .MuiFilledInput-underline:after": {
        borderBottomColor: "#11470E"
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "#11470E"
        }
      },
      maxWidth: 600,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    }
  });