import { useCallback, useState } from 'react';
import {
    railwayLayersDefaultValues,
    railwayVoltageDefaultValues,
    railwayVoltageQueries,
} from '../constants';
import { VoltageElement } from './useVoltageElements';

export const useRailwayElements = () => {
    const [railwayElements, setRailwayElements] = useState<{
        [key: string]: VoltageElement[];
    }>(railwayVoltageDefaultValues);
    const [railwayLayers, setRailwayLayers] = useState<{
        [key: string]: boolean;
    }>(railwayLayersDefaultValues);

    const fetchRailwayVoltage = useCallback(async (voltageRange: string) => {
        const voltage = railwayVoltageQueries[voltageRange];
        const query = `
                [out:json];
                area["ISO3166-1"="DE"][admin_level=2]->.germany;

                (
                node["railway"="rail"]["electrified"="contact_line"]
                ["voltage"="${voltage}"];
                way["railway"="rail"]["electrified"="contact_line"]
                ["voltage"="${voltage}"];
                relation["railway"="rail"]["electrified"="contact_line"]
                ["voltage"="${voltage}"];
                );
                out geom;
                `;
        try {
            const response = await fetch(
                'https://overpass-api.de/api/interpreter',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: new URLSearchParams({ data: query }),
                }
            );

            const data = await response.json();
            if (data.elements && data.elements.length > 0) {
                setRailwayElements((prev) => ({
                    ...prev,
                    [voltageRange]: [
                        ...(prev[voltageRange] || []),
                        ...data.elements,
                    ],
                }));
            }
        } catch (error) {
            console.error(
                `Error fetching voltage range ${voltageRange}:`,
                error
            );
        }
    }, []);

    return {
        railwayElements,
        railwayLayers,
        setRailwayLayers,
        fetchRailwayVoltage,
    };
};
