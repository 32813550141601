import { CircularProgress } from "@mui/material";
import { 
  ChangeEvent, 
  Dispatch, 
  FormEvent, 
  SetStateAction, 
  useCallback, 
  useState 
} from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

interface ChatInputProps {
    sx: string;
    onSend?: (val: string) => void;
    onChange: Dispatch<SetStateAction<File[]>>;
    count: number;
    isSending: boolean;
}
export const ChatInput = (props: ChatInputProps) => {
  const [message, setMessage] = useState('');

  const { sx, onSend, onChange, count, isSending } = props;
  const { t } = useTranslation();

  const onDropFiles = useCallback((acceptedFiles: File[]) => {
    onChange(prev => [...prev, ...acceptedFiles])
}, []);

const {
    getRootProps,
    getInputProps,
} = useDropzone({
    onDrop: onDropFiles
});

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value);
  }

  const handleSendMessage = (e: FormEvent) => {
    e.preventDefault();

    onSend?.(message);
    setMessage('');
  }

  return (
    <form 
      className={`flex items-center justify-between px-[29px] ${sx}`}
      onSubmit={handleSendMessage}
    >
        <input
            value={message}
            placeholder={t('profile.type_message')}
            className="w-full border-none outline-none"
            onChange={(e) => handleChange(e)}
        />

        <div className="flex gap-[15px] items-center">
            <div
                {...getRootProps()}
            >
                  <input {...getInputProps()} />
                  <div className="relative cursor-pointer">
                    <img
                        src="/assets/images/pin.png"
                        alt="pin"
                        className="h-[23px]"
                    />

                    {count > 0 && (
                      <div 
                        className="absolute bg-primary text-[12px] h-[13px] w-[13px] 
                          flex justify-center items-center rounded-full top-[-5px] right-[-10px]"
                        >
                        {count}
                      </div>
                    )}
                  </div>
          </div>

          <button type="submit">
              {isSending ? (
                <CircularProgress color="success" size={20} />
              ) : (
                <img src="/assets/icons/send.svg" alt="send" />
              )}
          </button>
        </div>
    </form>
  )
}
