import { Checkbox } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Organization, UpdateOrganizationMutation } from '../../../API';
import { API, graphqlOperation } from 'aws-amplify';
import { updateOrganization } from '../../../graphql/mutations';
import { GraphQLQuery } from '@aws-amplify/api';

const options = [
    "addArea.roof",
    "addArea.noise_barrier",
    "addArea.field",
    "addArea.grassland",
    "addArea.lake",
    "addArea.others"
];

interface AreaTypesModalProps {
    organization: Organization | null;
    onClose: (val: boolean) => void;
    onCheck: (val: string) => void;
    selected: string[] | null;
}

export const AreaTypesModal = ({
    organization,
    onClose,
    onCheck,
    selected
}: AreaTypesModalProps) => {
    const { t } = useTranslation();

    const handleSaveTypes = async () => {
        try {
            const editingOrganization = {
                id: organization?.id,
                AllowedAreaTypes: selected
            };

            await API.graphql<GraphQLQuery<UpdateOrganizationMutation>>(
                graphqlOperation(updateOrganization, { input: editingOrganization })
            );
            onClose(false);
        } catch (error) {
            console.log("Error:", error)
        }
    }

    return (
        <div className="fixed left-0 top-0 z-10 flex h-full w-full items-center justify-center">
            <div className="flex flex-col rounded-[8px] border-[1px] bg-white p-[20px] md:w-[300px]">
                <p className="mb-[10px] text-secondary">
                    {t('admin_dashboard.select')}
                </p>
                {options.map((option, index) => (
                    <div 
                        className="flex items-center gap-[5px]"
                        key={option}
                    >
                        <Checkbox
                            key={index}
                            color="success"
                            placeholder={t(option)}
                            checked={selected?.includes(option)}
                            onChange={() => onCheck(option)}
                        />

                        <p>{t(option)}</p>
                    </div>
                ))}

                <div className="flex w-full justify-end gap-[10px]">
                    <div
                        className="w-fit cursor-pointer rounded-[8px] bg-secondary px-[5px] text-white"
                        onClick={handleSaveTypes}
                    >
                        {t('profile.buttons.edit_profile')}
                    </div>

                    <div
                        className="w-fit cursor-pointer rounded-[8px] border-[2px] border-red-600 px-[5px] text-red-600"
                        onClick={() => onClose(false)}
                    >
                        {t('header.cancel')}
                    </div>
                </div>
            </div>
        </div>
    )
}
