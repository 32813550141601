import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { ContactFormSchema, TContactFormSchema } from '../../../helpers';
import { Button, LabelUpInput } from '../../../components';

export const ContactForm = () => {
    const { t } = useTranslation();

    const {
        handleSubmit,
        formState: { errors },
        register
    } = useForm<TContactFormSchema>({
        resolver: yupResolver(ContactFormSchema),
        mode: 'all'
    })

    const onSubmit = (data: FieldValues) => {
        console.log('Form submitted:', data)
    }

    return (
        <form
            className="mt-[25px] box-border flex flex-col gap-y-[20px] md:mt-[30px]"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div>
                <LabelUpInput
                    placeholder={t('placeholders.full_name')}
                    register={register}
                    name='fullName'
                    error={errors['fullName' as keyof TContactFormSchema]}
                />
            </div>

            <div>
                <LabelUpInput
                    placeholder={t('placeholders.email')}
                    register={register}
                    name='email'
                    error={errors['email' as keyof TContactFormSchema]}
                />
            </div>

            <div>
                <LabelUpInput
                    placeholder={t('placeholders.phone_number')}
                    register={register}
                    name='phone'
                    error={errors['phone' as keyof TContactFormSchema]}
                />
            </div>

            <div className="flex flex-col items-center md:mt-[39px]  md:flex-row md:gap-x-6">
                <Button
                    color="Primary"
                    textColor="text-white"
                    width="w-[272px] md:w-[107px]"
                >
                    {t('contact-form.submit')}
                </Button>
                <span className="pt-3 text-center font-normal opacity-50 md:pt-3 md:text-left">
                    {t('contact-form.will_contact')}
                </span>
            </div>
        </form>
    )
}
