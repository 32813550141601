/** @format */

import * as yup from 'yup';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const userLoginSchema = yup.object({
    email: yup.string().email().trim().required(),
    password: yup.string().min(8).required(),
})

export const UserInfoSchema = yup.object({
    firstName: yup.string().min(3).required(),
    lastName: yup.string().min(3).required(),
    phone: yup.string(),
    postalAddress: yup.string().required(),
    country: yup.string().required(),
    postal: yup.string().required(),
    city: yup.string().required(),
    accept: yup.boolean().oneOf([true]).required(),
    website: yup.string(),
    companyAddress: yup.string(),
    companyPostal: yup.string(),
    companyCountry: yup.string(),
    companyCity: yup.string(),
    VATID: yup.string(),
    companyName: yup.string().when([
        "website", "companyAddress", "companyPostal", "companyCountry", "companyCity", "VATID"
    ], ([
        website,
        companyAddress,
        companyCity,
        companyCountry,
        companyPostal,
        VATID
    ], schema) =>
    website || companyAddress || companyCity || companyCountry || companyPostal || VATID 
        ? schema.required() 
        : schema.nullable().optional()
    )
})

export const EditingUserInfoSchema = yup.object({
    name: yup.string().min(3).required(),
    surname: yup.string().min(3).required(),
    phone: yup.string(),
    street: yup.string().required(),
    country: yup.string().required(),
    postalCode: yup.string().required(),
    city: yup.string().required(),
    website: yup.string(),
    interest: yup.string(),
    companyStreet: yup.string(),
    companyPostal: yup.string(),
    companyCountry: yup.string(),
    companyCity: yup.string(),
    VATID: yup.string(),
    company: yup.string().when([
        "website", "companyStreet", "companyPostal", "companyCountry", "companyCity", "VATID"
    ], ([
        website,
        companyAddress,
        companyCity,
        companyCountry,
        companyPostal,
        VATID
    ], schema) =>
    website || companyAddress || companyCity || companyCountry || companyPostal || VATID 
        ? schema.required() 
        : schema.nullable().optional()
    )
})

export const ContactFormSchema = yup.object({
    email: yup.string().email().trim().required(),
    fullName: yup.string().min(3).required(),
    phone: yup
        .string()
        .required()
        .test('Phone number', 'Invalid Phone number', (value) => {
            return !!parsePhoneNumberFromString(value, 'DE')?.isValid()
        }),
})

export const ResetPasswordSchema = yup.object({
    username: yup.string(),
    email: yup.string().email().trim().required(),
    password: yup.string().min(6).required(),
    passwordConfirm: yup
        .string()
        .min(6)
        .oneOf([yup.ref('password')], 'Passwords must match')
        .required(),
})

export const OfferAreaSchema = yup.object({
    areaName: yup.string().required(),
    type: yup.string().required(),
    size: yup.number().required(),
    country: yup.string().required(),
    city: yup.string().required(),
    postalCode: yup.string().required(),
    forMySpace: yup.string().required(),
    role: yup.string().required(),
    moreInfo: yup.string(),
    moreRoleInfo: yup.string().when("role", ([role], schema) =>
    role === "Other (Please explain)" || role === "Andere (Bitte erklären)" ? schema.required() : schema.nullable().optional()),
    state: yup.string().when("country", ([country], schema) =>
    country === "addArea.countries.Germany" ? schema.required() : schema.optional()),
    checked: yup.string(),
    municipality: yup.string().when("checked", ([checked], schema) =>
    checked === 'addArea.no_street' ? schema.required() : schema.optional()),
    street: yup.string().when("checked", ([checked], schema) =>
    checked !== 'addArea.no_street' ? schema.required() : schema.optional()),
})

export const OfferAdsAreaSchema = yup.object({
    type: yup.string().required(),
    size: yup.number().required(),
    firstName: yup.string(),
    lastName: yup.string(),
    phone: yup.string(),
    country: yup.string(),
    street: yup.string(),
    postalCode: yup.string(),
    city: yup.string().when("country", ([country], schema) =>
    country ? schema.required() : schema.optional()),
    state: yup.string().when("country", ([country], schema) =>
    country === "addArea.countries.Germany" ? schema.required() : schema.optional()),
})

export const PricingUserInfoSchema = yup.object({
    fullName: yup.string().required(),
    email: yup.string().required(),
})

export const PricingUserInfoEnterpriseSchema = yup.object({
    fullName: yup.string().required(),
    email: yup.string().required(),
    company: yup.string().required(),
    phone: yup.string().required(),
    solar: yup.string().required(),
})

export const CardInfoSchema = yup.object({
    number: yup.string().required().max(16).min(16),
    expires: yup.string().required(),
})

export type TUserLoginSchema = yup.InferType<typeof userLoginSchema>
/* {
    email: string;
    password: string
} */

export type TUserInfoSchema = yup.InferType<typeof UserInfoSchema>
/* {
    firstName: string;
    lastName: string;
    phone:string;
    postalAddress: string;
    country: string;
    postal: string;
    city: string;
    address: string;
} */

export  const validateEmail = (email: string) => {
    return String(email.trim())
        .toLowerCase()
        .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export type TContactFormSchema = yup.InferType<typeof ContactFormSchema>

export type TResetPasswordSchema = yup.InferType<typeof ResetPasswordSchema>

export type TOfferAreaSchema = yup.InferType<typeof OfferAreaSchema>

export type TOfferAdsAreaSchema = yup.InferType<typeof OfferAdsAreaSchema>

export type TEditingUserInfoSchema = yup.InferType<typeof EditingUserInfoSchema>

export type TPricingUserInfoSchema = yup.InferType<typeof PricingUserInfoSchema>

export type TPricingUserInfoEnterpriseSchema = yup.InferType<typeof PricingUserInfoEnterpriseSchema>

export type TCardInfoSchema = yup.InferType<typeof CardInfoSchema>
