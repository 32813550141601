import { ChangeEvent, useEffect, useState } from "react";
import {
    GetOrganizationbyOwnerQuery,
    Organization,
    UpdateUserInfoMutation,
    UserInfo,
} from '../../../API';
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { getOrganizationbyOwner } from "../../../graphql/queries";
import { updateUserInfo } from "../../../graphql/mutations";

interface Options {
    user?: UserInfo | null;
}

export const UseOnboardingItem = ({ user }: Options) => {
    const [organization, setOrganization] = useState<Organization | null | undefined>(null);
    const [qualifiedInvestor, setQualifiedInvestor] = useState(false);

    const getOrganization = async () => {
        if (!user) return;

        try {
            const { data } = await API.graphql<GraphQLQuery<GetOrganizationbyOwnerQuery>>(
                graphqlOperation(getOrganizationbyOwner, { OwnerOfOrganization: user.sub})
            );

            if (!data?.getOrganizationbyOwner?.items) return;

            setOrganization(data?.getOrganizationbyOwner?.items?.find(el => !el?.disabledOrganization))
        } catch (error) {
            console.log("Error:", error)
        }
    }

    const handleStatusChange = async (event: ChangeEvent<HTMLInputElement>) => {
        setQualifiedInvestor(event.target.checked);

        if (!user) return;

        try {
            const editingUser = {
                id: user.id,
                sub: user.sub,
                qualifiedInvestor: event.target.checked,
            };

            const { data } = await API.graphql<GraphQLQuery<UpdateUserInfoMutation>>(
                graphqlOperation(updateUserInfo, {
                    input: editingUser
                })
            );

            console.log("DATA", data)
        } catch (error) {

        }
    };

    useEffect(() => {
        getOrganization();

        if (user?.qualifiedInvestor) {
            setQualifiedInvestor(true);
        }
    }, [user])

    return {
        qualifiedInvestor,
        organization,
        handleStatusChange
    }
}