import { SliderPropsMock } from "../types";

export const landsForLeaseMock: SliderPropsMock[] = [
  {
      id: '817185d0-c746-4f60-8c55-43e896059ed3',
      address_state: 'Mecklenburg-Western Pomerania',
      areaSize: 200313,
      img: '/landsForLeaseMock/Mecklenburg.png',
      address_country: "",
      areaType: "addArea.field"
  },
  {
      id: '080a5b36-1be4-4687-9089-9136fcc62ef0',
      address_state: 'Hesse',
      areaSize: 160000,
      img: '/landsForLeaseMock/Hesse.png',
      address_country: "",            
      areaType: "addArea.field"
  },
  {
      id: '351085b7-a662-49c0-9155-6e799728550d',
      address_state: 'Thuringia',
      areaSize: 50000,
      img: '/landsForLeaseMock/Thuringia.png',
      address_country: "",
      areaType: "addArea.field"
  },
  {
      id: '09d8ead6-1123-478f-9dfc-4eab36914ebc',
      address_state: 'Hungary',
      areaSize: 50000,
      img: '/landsForLeaseMock/Hungary.png',
      address_country: "",
      areaType: "addArea.field"
  },
]

export const roofsForLeaseMock: SliderPropsMock[] = [
        {
            id: "6c891ffc-d173-4797-b4a5-9120e8e71d36",
            address_state: 'Brandenburg',
            areaSize: 43900,
            img: '/roofsForLeaseMock/Brandenburg.png',
            address_country: '',
            areaType: 'addArea.roof',
        },
        {
            id: 'c8a8923e-ef01-48e6-8d54-e4bba239fd8b',
            address_state: 'Mecklenburg-Western Pomerania',
            areaSize: 2500,
            img: '/roofsForLeaseMock/Mecklenburg.png',
            address_country: '',
            areaType: 'addArea.roof',
        },
        {
            id: 'e3af2361-be59-4ffc-a64a-f99525fcd39f',
            address_state: 'Thuringia',
            areaSize: 1700,
            img: '/roofsForLeaseMock/Thuringia1.png',
            address_country: '',
            areaType: 'addArea.roof',
        },
        {
            id: '0e0d5d7f-24af-4d19-bb03-697c59974ad0',
            address_state: 'Thuringia',
            areaSize: 1600,
            img: '/roofsForLeaseMock/Thuringia2.png',
            address_country: '',
            areaType: 'addArea.roof',
        },
    ]