import { useTranslation } from 'react-i18next';
import { Flaeche } from '../../../API';
import { Description } from './Description';
import { LocationInfo } from './LocationInfo';
import { MoreInfo } from './MoreInfo';
import { TypeInfo } from './TypeInfo';
import { UploadedFiles } from './UploadedFiles';
import { Button } from '../../../components/Button';
import { useNavigate } from 'react-router-dom';
import { AreaSize } from '../../../components';
import { useAuth } from '../../../hooks';

interface AboutAreaProps {
  area: Flaeche | null | undefined;
  files: string[];
  isOwner: boolean;
  isOpenContacts: boolean;
}

export const AboutArea = (props: AboutAreaProps) => {
  const { area, files, isOwner, isOpenContacts } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isAdmin } = useAuth();

  const handleNavigate = () => {
    navigate(`/offer/${area?.id}`);
  };

  return (
    <div>
        {area && (
          <>
              <div className='lg:flex justify-between items-center hidden'>
                  <div>
                    <h1 className='font-semibold text-[45px] mb-[15px]'>
                        {!isOwner ?
                          t(area?.areaType)?.slice(0, 1)?.toUpperCase() + t(area?.areaType).slice(1)
                          : area?.areaName ? t(area?.areaName) : ''
                        }
                    </h1>

                    <p className='mb-[30px]'>
                      {t('search.area')}: {" "}
                        <AreaSize areaDetails={area} />
                      </p>
                  </div>

                  {(isOwner || isAdmin) && (
                    <Button
                      color='Primary'
                      textColor='text-white'
                      onClick={handleNavigate}
                    >
                      {t('profile.edit')}
                    </Button>
                  )}
              </div>

              <Description 
                description={area?.additional_information || ''} 
                owner={isOwner}
                isVisible={isOpenContacts}
              />

              <hr className="mb-[30px] h-[2px] w-full bg-gray-50 my-[25px]" />

              <TypeInfo 
                type={t(area?.areaType) || ''} 
                size={area?.areaSize || 0}
              />

              <hr className="mb-[30px] h-[2px] w-full bg-gray-50 my-[25px]" />

              <LocationInfo 
                area={area} 
                owner={isOwner}
                isOpenContacts={isOpenContacts}
              />

              <hr className="mb-[30px] h-[2px] w-full bg-gray-50 my-[25px]" />

              <MoreInfo area={area} />

              <hr className="mb-[30px] h-[2px] w-full bg-gray-50 my-[25px]" />

              <UploadedFiles 
                files={files.filter(el => !el.includes('undefined'))} 
                owner={isOwner}
                areaId={area.id}
                isVisible={isOpenContacts}
              />

              <hr className="mb-[30px] h-[2px] w-full bg-gray-50 my-[25px]" />
          </>
        )}
    </div>
  )
}
