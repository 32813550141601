import { useTranslation } from "react-i18next"
import { CallToAction } from "../../../components"
import { ArticleImage } from "./Image";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Links } from "../../../settings";

export const FifthArticle = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const handleNavigate = useCallback(() => {
        navigate(Links.offer.index);
    }, [navigate]);

    return (
        <div className="mb-[20px] flex flex-col gap-[25px] text-[16px] text-[#333] md:text-[18px]">
            <span className="uppercase text-primary">{t('articles.info')}</span>

            <h1 className="text-[25px] font-medium md:text-[35px]">
                {t('article_five.title')}
            </h1>

            <div className="h-[2px] w-[150px] bg-primary" />

            <span className="text-[13px] uppercase text-gray-700 md:text-[16px]">
                {t('articles.article_five.date')}
            </span>

            <p>{t('article_five.p_1')}</p>

            <p>{t('article_five.p_2')}</p>

            <p>{t('article_five.p_3')}</p>

            <ArticleImage
                source="/assets/images/FifthArticle.jpg"
                link="https://unsplash.com/de/fotos/weisse-und-blaue-sonnenkollektoren-hxUcl0nUsIY"
            />

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_five.subtitle_1')}
            </h2>

            <p>{t('article_five.p_4')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_five.subtitle_2')}
            </h3>

            <p>{t('article_five.p_5')}</p>

            <p>{t('article_five.p_6')}</p>
           
            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_five.subtitle_3')}
            </h2>

            <p>{t('article_five.p_7')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_five.subtitle_4')}
            </h3>

            <p>{t('article_five.p_8')}</p>

            <p className="font-medium">{t('article_five.p_9')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_five.subtitle_5')}
            </h3>

            <p>{t('article_five.p_10')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_five.subtitle_6')}
            </h3>

            <p>{t('article_five.p_11')}</p>

            <p>{t('article_five.p_12')}</p>

            <ArticleImage
                source="/assets/images/Solarpark.jpg"
                link="https://unsplash.com/de/fotos/installation-von-sonnenkollektoren-nahaufnahme-eines-arbeitswerkzeugs-drahte-und-ein-mann-in-schutzkleidung-der-auf-einem-dach-mit-photovoltaik-kraftwerk-steht-rMilgVMrMlQ"
            />

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_five.subtitle_7')}
            </h2>

            <p>{t('article_five.p_13')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_five.subtitle_8')}
            </h3>

            <p>{t('article_five.p_14')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_five.subtitle_9')}
            </h3>

            <p>{t('article_five.p_15')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_five.subtitle_10')}
            </h3>

            <p>{t('article_five.p_16')}</p>

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_five.subtitle_11')}
            </h2>

            <p>{t('article_five.p_17')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_five.subtitle_12')}
            </h3>

            <p>{t('article_five.p_18')}</p>

            <ul className="flex list-disc flex-col gap-[10px] pl-[30px]">
                <li>
                    <b>{t('article_five.list_1.bold')}</b>{' '}
                    <ul className="flex list-disc flex-col gap-[10px] pl-[30px]">
                        <li>{t('article_five.list_1.option_1')}</li>
                        <li>{t('article_five.list_1.option_2')}</li>
                        <li>{t('article_five.list_1.option_3')}</li>
                    </ul>
                </li>
            </ul>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_five.subtitle_13')}
            </h3>

            <p>{t('article_five.p_19')}</p>

            <ul className="flex list-disc flex-col gap-[10px] pl-[30px]">
                <li>
                    <b>{t('article_five.list_2.bold')}</b>{' '}
                    <ul className="flex list-disc flex-col gap-[10px] pl-[30px]">
                        <li>{t('article_five.list_2.option_1')}</li>
                        <li>{t('article_five.list_2.option_2')}</li>
                        <li>{t('article_five.list_2.option_3')}</li>
                    </ul>
                </li>
            </ul>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_five.subtitle_14')}
            </h3>

            <p>{t('article_five.p_20')}</p>

            <ul className="flex list-disc flex-col gap-[10px] pl-[30px]">
                <li>
                    <b>{t('article_five.list_3.bold')}</b>{' '}
                    <ul className="flex list-disc flex-col gap-[10px] pl-[30px]">
                        <li>{t('article_five.list_3.option_1')}</li>
                        <li>{t('article_five.list_3.option_2')}</li>
                        <li>{t('article_five.list_3.option_3')}</li>
                    </ul>
                </li>
            </ul>

            <div className="mx-[25px] text-[16px] md:mt-[50px]">
                <CallToAction
                    title={t('home.call_to_action_environment')}
                    btnTitle={t('buttons.offer_your_space_now')}
                    bgColor="bg-secondary text-white"
                    onClick={handleNavigate}
                    tag="p"
                />
            </div>
        </div>
    )
}
