import { Dispatch, SetStateAction, useState } from 'react';
import {
    GetOrganizationQuery,
    GetOrganizationbyOwnerQuery,
    Organization,
    UpdateOrganizationMutation,
    UpdateUserInfoMutation,
    UserInfo,
} from '../../../API';
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLQuery } from '@aws-amplify/api';
import { getOrganization, getOrganizationbyOwner } from '../../../graphql/queries';
import { updateOrganization, updateUserInfo } from '../../../graphql/mutations';

interface Options {
    users: (UserInfo | null | undefined)[];
    organization?: Organization | null; 
    notRegistered: string[];
    onNotRegistered: Dispatch<SetStateAction<string[]>>;
    onUsers: Dispatch<SetStateAction<(UserInfo | null | undefined)[]>>;
    getMembers: () => Promise<void>;
}

export const useMember = ({
    organization,
    users,
    onNotRegistered,
    notRegistered,
    onUsers,
    getMembers
}: Options) => {
    const [userToDelete, setUserToDelete] = useState<UserInfo | null | string>()
    const [isLoading, setIsLoading] = useState(false)

    const updateMemberOrganization = async () => {
        const { data } = await API.graphql<
            GraphQLQuery<GetOrganizationbyOwnerQuery>
        >(
            graphqlOperation(getOrganizationbyOwner, {
                OwnerOfOrganization: userToDelete?.sub,
            })
        )

        if (data?.getOrganizationbyOwner?.items[0]) {
            const editingOrg = {
                id: data?.getOrganizationbyOwner?.items[0].id,
                disabledOrganization: false,
            }

            await API.graphql<GraphQLQuery<UpdateOrganizationMutation>>(
                graphqlOperation(updateOrganization, { input: editingOrg })
            )
        }
    }

    const approveDeleting = async () => {
        try {
            setIsLoading(true);
            const { data } = await API.graphql<
                GraphQLQuery<GetOrganizationQuery>
            >(
                graphqlOperation(getOrganization, {
                    id: organization?.id,
                })
            );

            const org = data?.getOrganization;

            if (typeof userToDelete === 'string') {
                const editingOrg = {
                    id: organization?.id,
                    InviteCompanyMembersEMail:
                        org?.InviteCompanyMembersEMail
                            ? org?.InviteCompanyMembersEMail.filter(
                                  (member) => member !== userToDelete
                              )
                            : [],
                }

                await API.graphql<GraphQLQuery<UpdateOrganizationMutation>>(
                    graphqlOperation(updateOrganization, { input: editingOrg })
                )

                const filteredUsers = notRegistered.filter(
                    (user) => user !== userToDelete
                )
                onNotRegistered(filteredUsers)
                setUserToDelete(null);
                setIsLoading(false);

                return
            }

            if (userToDelete?.MemberofOrganizationID) {
                const editingUser = {
                    id: userToDelete.id,
                    sub: userToDelete.sub,
                    MemberofOrganizationID: null,
                }

                await API.graphql<GraphQLQuery<UpdateUserInfoMutation>>(
                    graphqlOperation(updateUserInfo, { input: editingUser })
                )

                const filteredUsers = users.filter(
                    (user) => user?.id !== userToDelete.id
                )

                await updateMemberOrganization();
                await getMembers();

                onUsers(filteredUsers);
                setUserToDelete(null);
                setIsLoading(false);

                return
            }

            const editingOrg = {
                id: organization?.id,
                InviteCompanyMembersEMail:
                    org?.InviteCompanyMembersEMail
                        ? org?.InviteCompanyMembersEMail.filter(
                              (member) => member !== userToDelete?.email
                          )
                        : [],
            }

            await API.graphql<GraphQLQuery<UpdateOrganizationMutation>>(
                graphqlOperation(updateOrganization, { input: editingOrg })
            );

            const filteredUsers = users.filter(
                (user) => user?.id !== userToDelete?.id
            );
            onUsers(filteredUsers);
            setUserToDelete(null);
            setIsLoading(false);
        } catch (error) {
            console.log('Error removing user:', error)
            setIsLoading(false)
        }
    }

    return {
        userToDelete,
        setUserToDelete,
        approveDeleting,
        isLoading
    }
}

