import { Flaeche } from "../API";

export const getValue = (area: Flaeche | undefined | null, name: string) => {
    switch (name) {
        case 'country': 
            return area?.address_country;

        case 'state': 
            return area?.address_state;

        case 'city': 
            return area?.address_city;

        case 'street': 
            return area?.address_street;

        case 'postalCode': 
            return area?.address_postcode;

        case 'municipality':
            return area?.address_other;

        default:
            return '';
    }
}