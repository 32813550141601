import { useTranslation } from 'react-i18next';
import { FormHeader } from '../../Offer/components';
import { UserInfoInput } from './UserInfoInput';
import PhoneInput from 'react-phone-input-2';
import { ChangeEvent } from 'react';

interface FourthStepFormProps {
    firstName: string;
    lastName: string;
    phone: string;
    onSetPhone: (e: string) => void;
    error: boolean;
    onChangeName: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onChangeSur: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const FourthStepForm = ({
    firstName,
    lastName,
    phone,
    error,
    onSetPhone,
    onChangeName,
    onChangeSur
}: FourthStepFormProps) => {
  const { t } = useTranslation();

  return (
    <div className="px-[25px] md:px-[80px] xl:px-[180px] ">
        <FormHeader
            title="Personal Info"
            sx="flex gap-[10px]"
        />

        <div 
            className="flex gap-[20px] md:flex-row flex-col items-end mb-[40px]"
        >
            <UserInfoInput
                value={firstName}
                placeholder={t('placeholders.first_name')}
                error={error}
                onChange={onChangeName}
            />

            <UserInfoInput
                value={lastName}
                placeholder={t('placeholders.last_name')}
                error={error}
                onChange={onChangeSur}
            />

            <div className='relative w-full'>
                <p className='mb-[10px]' style={{ color: 'rgba(0, 0, 0, 0.6)'}}>
                    {t('placeholders.phone_number')}
                </p>

                <PhoneInput
                    country={'de'}
                    placeholder={t('placeholders.phone_number')}
                    value={phone}
                    containerStyle={{
                        border: `${!phone && error ? '1px solid red' : ''}`,
                        borderRadius: '0px 8px 8px 0px',
                    }}
                    onChange={e => onSetPhone(e)}
                />

                {!phone && (
                    <div
                        className={`req_placeholder absolute left-[115px] top-[-5px] text-[24px] text-[#FF0F00]`}
                    >
                        <span className="req_placeholder">*</span>
                    </div>
                )}
            </div>
        </div>
    </div>
  )
}
