import { TextFieldProfile } from './TextFieldProfile';
import { TEditingUserInfoSchema } from '../../../helpers';
import { useTranslation } from 'react-i18next';
import { FieldErrors, SetFieldValue, UseFormRegister } from 'react-hook-form';
import { UserInfo } from '../../../API';
import { useMemo } from 'react';
import CountryList from '../../../utils/countryLists.json';
import { category_login } from '../../../utils/formLists';

type CompanyFormProps= {
    user?: UserInfo | null;
    register: UseFormRegister<TEditingUserInfoSchema>;
    setValue?: SetFieldValue<TEditingUserInfoSchema>;
    errors: FieldErrors<TEditingUserInfoSchema>;
};

export const PersonalForm = ({
    register,
    user,
    errors,
    setValue
}: CompanyFormProps) => {
    const { t } = useTranslation();

    const countries: Record<string, string>[] = useMemo(() => CountryList, []);

    return (
        <div className='mb-[70px]'>
            <div className="mb-[20px] flex w-full flex-col justify-between gap-[20px] md:flex-row">
                <div className="flex w-full flex-col gap-[20px]">
                    <TextFieldProfile
                        name="name"
                        register={register}
                        setValue={setValue}
                        value={user?.firstName}
                        label={t('profile.labels.name')}
                        error={errors['name' as keyof TEditingUserInfoSchema]}
                    />

                    <label className="flex w-full flex-col gap-[15px] text-[14px] text-[#9e9e9e]">
                        {t('profile.labels.email')}

                        <p className="pb-[10px] text-[17px] text-black">
                            {user?.email}
                        </p>
                    </label>
                </div>

                <div className="flex w-full flex-col gap-[20px]">
                    <TextFieldProfile
                        name="surname"
                        register={register}
                        setValue={setValue}
                        value={user?.lastName}
                        label={t('profile.labels.surname')}
                        error={
                            errors['surname' as keyof TEditingUserInfoSchema]
                        }
                    />

                    <TextFieldProfile
                        name="phone"
                        register={register}
                        value={user?.phone}
                        label={t('profile.labels.phone')}
                        setValue={setValue}
                    />
                </div>
            </div>

            <div className="mb-[20px]">
                <TextFieldProfile
                    name="street"
                    register={register}
                    value={user?.streetAndHouseNumber}
                    label={t('profile.labels.street')}
                    error={errors['street' as keyof TEditingUserInfoSchema]}
                    setValue={setValue}
                />
            </div>

            <div className="mb-[20px] flex w-full flex-col justify-between gap-[20px] md:flex-row">
                <div className="flex w-full flex-col gap-[20px]">
                    <TextFieldProfile
                        name="postalCode"
                        setValue={setValue}
                        register={register}
                        value={user?.postalCode}
                        label={t('profile.labels.postal_code')}
                        error={
                            errors['postalCode' as keyof TEditingUserInfoSchema]
                        }
                    />
                </div>

                <div className="flex w-full flex-col gap-[20px]">
                    <TextFieldProfile
                        name="city"
                        register={register}
                        value={user?.city}
                        label={t('profile.labels.city')}
                        setValue={setValue}
                        error={errors['city' as keyof TEditingUserInfoSchema]}
                    />
                </div>
            </div>

            <div className="mb-[20px] flex w-full flex-col justify-between gap-[20px] md:flex-row">
                <div className="flex w-full flex-col gap-[20px]">
                    <TextFieldProfile
                        name="country"
                        type="dropdown"
                        setValue={setValue}
                        options={countries}
                        register={register}
                        value={user?.country}
                        label={t('profile.labels.country')}
                        error={
                            errors['country' as keyof TEditingUserInfoSchema]
                        }
                    />
                </div>

                <div className="flex w-full flex-col gap-[20px]">
                    <TextFieldProfile
                        name="interest"
                        type="dropdown"
                        setValue={setValue}
                        options={category_login}
                        register={register}
                        value={user?.interestedIn}
                        label={t('profile.labels.interested_in')}
                    />
                </div>
            </div>

            {/* <div className="mb-[20px]">
                <TextFieldProfile
                    name="biography"
                    register={register}
                    setValue={setValue}
                    value={user?.personalStatement}
                    label={t('profile.labels.personal')}
                    multiline
                />
            </div> */}
        </div>
    )
}
