export const formatError = (text: string | undefined) => {
    if (text) {
        return text.split('')[0].toUpperCase() + text.slice(1)
    } else {
        return ''
    }
}

export const formatSignUpError = (text: string) => {
    const indexOfColon = text.indexOf(':');
    if (indexOfColon !== -1) {
        const specificErrorMessage = text.substring(indexOfColon + 1).trim();
        return specificErrorMessage;
    } else {
        return text;
    }
}