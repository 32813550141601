import { Checkbox } from "@mui/material";
import { Button } from "../Button";
import { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { UpdateUserInfoMutation, UserInfo } from "../../API";
import { updateUserInfo } from "../../graphql/mutations";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Links } from "../../settings";
import { useAuth } from "../../hooks";

interface InvestorModalProps {
    onClose: () => void;
    user: UserInfo | null;
}

export const InvestorModal = (props: InvestorModalProps) => {
  const { onClose, user } = props;
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState(false);
  const { loginUser } = useAuth();

  const handleCheck = () => {
    setChecked(prev => !prev);
  }

  const handleAccess = async () => {
    if (!checked) {
        setError(true);

        return;
    }
    
    const editingUser = {
        sub: user?.sub,
        nutzungsbedingungenInvestorAccepted: checked,
        nutzungsbedingungenInvestorTimestamp: new Date().toISOString(),
        id: user?.id
    };

    try {
        const res = await API.graphql<GraphQLQuery<UpdateUserInfoMutation>>(
            graphqlOperation(updateUserInfo, { input: editingUser })
        );
    
        setError(false);
        onClose();
        loginUser(res?.data?.updateUserInfo);
    } catch (error) {
        console.log('Error', error)
    }
  }

  useEffect(() => {
    if (checked) {
        setError(false);
    }
  }, [checked])

  return (
    <div 
        className='fixed top-0 h-full w-full z-10 flex justify-center items-center px-[10px]' 
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}
    >
        <div className='opacity-100'>
            <div className='px-[30px] py-[20px] bg-white rounded-[12px] flex flex-col items-center border-[1px] border-black mt-[100px]'>
                <p className='mb-[15px] text-[25px] font-bold text-secondary'>
                    {t('investor_modal.congrats')}
                </p>

                <p className='md:text-[20px] text-[15px] text-secondary font-bold mb-[20px]'>
                    {t('investor_modal.upgrated')}
                </p>

                <div className='flex flex-col gap-[15px] w-full items-start md:text-[20px] text-[15px] text-secondary font-bold mb-[30px] pl-[15px]'>
                    <li>{t('investor_modal.get_in_contact')}</li>
                    <li>{t('investor_modal.see_full')}</li>
                    <li>{t('investor_modal.kml')}</li>
                </div>

                <p className='text-primary mb-[15px] md:text-[25px] text-[15px] font-bold max-w-[500px] text-center'>
                    {t('investor_modal.thank_you')}
                </p>

                <p className="mb-[10px]">{t('investor_modal.by_continuing')}</p>

                <div className='flex gap-[10px] items-center mb-[20px]'>
                    <Checkbox 
                        color="success"
                        checked={checked}
                        onChange={handleCheck}
                    />

                    <p> {t('investor_modal.have_read')} {" "}
                        {/* <Link to={Links.broker.index} className="underline text-secondary">
                            {t('investor_modal.broker')}
                        </Link>
                        {" "} {t('investor_modal.and')}
                        {" "} */}
                        <Link to={Links.investorTerms.index} className="underline text-secondary">
                            {t('investor_modal.investors')}
                        </Link>
                    .”</p>
                </div>

                <Button
                    color='Secondary'
                    onClick={handleAccess}
                    sx="mb-[30px]"
                >
                    {t('investor_modal.accept')}
                </Button>

                {error && (
                    <p className="text-red-500">{t('investor_modal.please')}</p>
                )}
            </div>
        </div>
    </div>
  )
}
