/** @format */

import { useNavigate } from 'react-router-dom';
import { Links } from '../../../settings';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { Button } from '../../../components';
import { useAuth } from '../../../hooks';

export const Intro = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { loggedIn } = useAuth();

    const handleNavigateToOfferPage = useCallback(() => {
        if (loggedIn) {
            navigate(Links.offer.index);

            return;
        }

        const introSection = document.getElementById('how%20it%20works');
        if (introSection) {
            introSection.scrollIntoView({ behavior: 'smooth' });
        }
    }, [navigate, loggedIn]);

    const handleNavigateToSearchPage = useCallback(() => {
        if (loggedIn) {
            navigate(Links.search.index);

            return;
        }

        const introSection = document.getElementById('areas');
        if (introSection) {
            introSection.scrollIntoView({ behavior: 'smooth' });
        }
    }, [navigate, loggedIn]);

    return (
        // overwrite the parent width 100vw
        <div className="ml-[calc(50%-50vw)] w-screen bg-primary section" id="start">
            <div className="m-auto pb-[44px] h-max max-w-[390px] pt-[44px] md:pt-[80px] text-center text-white lg:h-[406px] md:max-w-[1040px]">
                <p className="tex-center m-auto mb-[20px] max-w-[205px]  text-[14px] md:max-w-full md:text-[22px]">
                    {t('home.intro.welcome')}
                </p>
                <h1 className="text-[38px] font-bold leading-10 md:text-[75px] md:leading-[70px]">
                    {t('home.intro.title')}
                </h1>

                <div className="mt-[44px] flex flex-col items-center gap-y-[15px] md:flex-row md:justify-center md:gap-x-[25px]">
                    <Button
                        color="Primary" 
                        textColor="text-white" 
                        onClick={handleNavigateToOfferPage}
                    >
                        {t('buttons.offer_spaces')}
                    </Button>
                    <Button 
                        color="white" 
                        onClick={handleNavigateToSearchPage}
                    >
                        {t('buttons.search')}
                    </Button>
                </div>
            </div>
        </div>
    )
}
