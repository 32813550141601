// @ts-nocheck
import * as React from 'react';
import { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Burger } from './components/BurgerIcon';
import { Nav } from '../Nav';
import { Drawer } from '../Drawer';
import { UserProfile } from './components/UserProfile';
import { LoginBlock } from './components/LoginBlock';
import { SignOutModal } from '../SignOutModal';
import { ModalContext } from '../../contexts/ModalContext';
import { Links } from '../../types';
import { useTranslation } from 'react-i18next';
import { LanguageSwitcher } from './components/LanguageSwitcher';
import { useAuth } from '../../hooks';
import { Auth, Storage } from 'aws-amplify';

interface HeaderProps {
    isScrolled: boolean;
}

export const Header: React.FC<HeaderProps> = ({ isScrolled }: HeaderProps) => {
    const { t } = useTranslation();
    const { loggedIn, setImage } = useAuth();
    const location = useLocation();

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [profileMenuOpen, setProfileMenuOpen] = useState(false);
    const { openSignOutModal, signOutModalOpen } = useContext(ModalContext);
    const profileRef = useRef<HTMLDivElement | null>(null);
    const [imageURL, setImageURL] = useState("");

    const handleGetImage = async () => {
        await Auth.currentSession();
        
        const storageResults = await Storage.list(loggedIn.sub ? loggedIn.sub : "");

        for (const file of storageResults.results) {
            if (file?.key && loggedIn.sub && file?.key.includes(loggedIn.sub)) {
                const fileName = loggedIn.profileImageFilename || '';

                try {
                    const expirationTime = 7 * 24 * 60 * 60;
                    
                    const fileRes = await Storage.get(fileName, { expires: expirationTime });
                    const response = await fetch(fileRes);
                    
                    if (response.ok) {
                        const blob = await response.blob();
                        const img = URL.createObjectURL(blob);
                        setImage(fileRes);
                        setImageURL(img);
                    } else if (response.status === 400) {
                        window.location.reload();
                    } else {
                        console.error(`Error fetching image: ${response.status}`);
                        return null;
                    }
                } catch (err) {
                    console.log('File error:', err);
                }
            }
        }
    }

    useEffect(() => {
        if (loggedIn) {
            handleGetImage();
        }
    }, [loggedIn])

    const headerClass = isScrolled
        ? 'md:h-[65px] transition-all'
        : 'md:h-[98px]';
    const menuClass = isScrolled ? 'py-0 top-[60px]' : 'py-[5px] md:top-[79px] top-[65px]';
    const iconClasses = isScrolled ? 'w-[49px] h-[50px]' : 'md:w-[56px] w-[50px] md:h-[55px] h-[50px]';

    React.useLayoutEffect(() => {
        if (location.hash) {
            const elem = document.getElementById(location.hash.slice(1))
            if (elem) {
                elem.scrollIntoView({ block: 'start' })
            }
        } else {
            window.scrollTo({ top: 0, left: 0 })
        }
    }, [location])

    // menu
    const navLinks: Links[] = [
        { name: 'header.start', link: 'start' },
        { name: 'header.how_it_works', link: 'how it works' },
        { name: 'header.areas', link: 'areas' },
    ]

    const toggleProfileMenu = () => {
        setProfileMenuOpen((prev) => !prev)
    };

    /**
     * Toggles Side Drawer
     */
    const drawerOpenFn = useCallback((): void => {
        setIsOpen((prev) => !prev)
    }, []);

    /**
     * Close Side Drawer
     */
    const drawerCloseFn = useCallback((): void => {
        setIsOpen(false)
    }, []);

    const mobileDrawerClose = useCallback(() => {
        setTimeout(() => {
            setIsOpen(false)
        }, 500)
    }, []);

    const handleLogOut = useCallback(() => {
        openSignOutModal();
        setProfileMenuOpen(false);
    }, [openSignOutModal]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (profileRef.current && !profileRef.current.contains(event.target as Node)) {
                setProfileMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="fixed left-0 right-0 top-0 z-[1000] bg-white shadow-md lg:px-[15px]">
            <div className="max-w-[1448px] px-[30px] md:m-auto">
                <div
                    className={`flex h-[54px] items-center justify-between text-[#111A21] lg:px-[10px] 2xl:px-[25px] ${headerClass}`}
                >
                    <div>
                        <NavLink to="/">
                            <img
                                src="/Enlapa logo.png"
                                alt="Enlapa Logo"
                                className="h-[25px] w-[143px]"
                            />
                        </NavLink>
                    </div>

                    <div className="hidden md:block">
                        <Nav links={navLinks} />
                    </div>

                    <div className="hidden md:block">
                        <LanguageSwitcher />
                    </div>

                    {!loggedIn && (
                        <LoginBlock />
                    )}

                    {isOpen ? (
                        <svg
                            onClick={drawerCloseFn}
                            className="h-6 w-6 text-primary"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    ) : (
                            <div className={`${!loggedIn && 'md:hidden'} flex gap-[20px] items-center`}>
                                {loggedIn &&  (
                                    <div ref={profileRef}>
                                        <UserProfile
                                            onClick={toggleProfileMenu}
                                            isOpen={profileMenuOpen}
                                            onLogOut={handleLogOut}
                                            scrollClasses={menuClass}
                                            iconClasses={iconClasses}
                                            image={imageURL}
                                        />
                                    </div>
                                )}

                                <Burger onClick={drawerOpenFn} />
                            </div>
                    )}

                    {signOutModalOpen && <SignOutModal />}
                </div>

                <div className="md:hidden">
                    <Drawer isOpen={isOpen} onClose={drawerCloseFn}>
                        <Nav links={navLinks} onClose={mobileDrawerClose} />
                        {!loggedIn ? (
                            <Link
                                to="/login"
                                className="mb-[30px] pl-[48px] text-2xl font-bold sm:mr-5"
                                onClick={mobileDrawerClose}
                            >
                                {t('header.login')}
                            </Link>
                        ) : (
                            <p 
                                className="mb-[30px] pl-[48px] text-2xl font-bold sm:mr-5"
                                onClick={handleLogOut}
                            >
                                {t('header.log_out')}
                            </p>
                        )}
                        <div className="pl-[48px] pt-[40px]">
                            <LanguageSwitcher />
                        </div>
                    </Drawer>
                </div>
            </div>
        </div>
    )
}
