import { useTranslation } from "react-i18next"
import { useResponsive } from "../../../hooks";
import { Carousel } from "../../../components";
import { LandsSlider } from "../../Home/components";
import { Flaeche } from "../../../API";

interface RecommendedAreasProps {
    fetchImages: (id?: string | undefined) => Promise<void>;
    images: string[];
    recommendedAreas: (Flaeche | null)[];
}

export const RecommendedAreas = (props: RecommendedAreasProps) => {
    const { images, fetchImages, recommendedAreas } = props;
    const { t } = useTranslation();
    const { isMobile } = useResponsive();

    return (
        <div className="flex flex-col items-center">
            <p className="mb-[30px] text-[30px] font-semibold md:mb-[45px] md:text-[38px]">
                {t('area.you_may')}
            </p>

            {isMobile ? (
                recommendedAreas && (
                    <Carousel
                        images={images}
                        lands={recommendedAreas}
                        fetchImages={fetchImages}
                    />
                )
            ) : (
                <LandsSlider
                    images={images}
                    lands={recommendedAreas}
                    fetchImages={fetchImages}
                />
            )}
        </div>
    )
}
