import { CallToAction } from '../../components/CallToAction';
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { Loader } from '../../components/Loader';
import { useTranslation } from 'react-i18next';
import { useSearch } from '../SearchPage/hooks/useSearch';
import { Flaeche } from '../../API';
import { useProfileInfo } from '../ProfilePage/hooks/useProfileInfo';
import { useAuth, usePageDescription } from '../../hooks';
import { Client } from "@twilio/conversations";
import { AreaDetails, AreaError, Disabled, ProjectModals, RecommendedAreas } from './components';
import { useAreaMap, useContactsAccess, useDetails } from './hooks';
import { Helmet } from 'react-helmet';
interface ProjectDetailsProps {
    twilioClient: Client | null;
}

export const ProjectDetails = ({ twilioClient }: ProjectDetailsProps) => {
    const [recommendedAreas, setRecommendedAreas] = useState<(Flaeche | null)[]>([]);

    const mapContainer = useRef<HTMLDivElement | null>(null);
    const { areaId } = useParams();
    const { t } = useTranslation();
    const { loggedIn, isAdmin } = useAuth();
    const { getUserInfo, user } = useProfileInfo({ loggedIn });
    const {
        loading,
        areaDetails,
        openDialog,
        handleOpenChat,
        handleCloseChat,
        handleNavigate,
        getAreaDetails,
        handleRateArea,
        handleNavigateToEdit,
        areaImages,
        areaFiles,
        getRatedArea,
        getOwner,
        ratedArea,
        owner,
        isOwner,
        error
    } = useDetails({ areaId, user });

    const {
        handleOpenDetails,
        handleJoinWaitList,
        isOpenContacts,
        isListFull,
        setIsListFull,
        isJoined,
        isVisible,
        notTypeError,
        handleCloseNotTypeError,
        isInList
    } = useContactsAccess({ areaDetails, user })

    const { 
        areas, 
        areaImages: images, 
        getAreas, 
        fetchImages 
    } = useSearch({});

    usePageDescription({ 
        title: t('title.area'),
        description: t('description.area')
    });

    useAreaMap({ 
        mapContainer,
        areaFiles, 
        zoom: areaDetails?.MapboxZoomfactor, 
        isOpenContacts: isVisible
    });

    useEffect(() => {
        getAreaDetails();
        getAreas();
    }, [getAreaDetails, getAreas])

    useEffect(() => {
        const recAreas = areas.filter((area) => !area?.disabled).slice(1, 5)
        setRecommendedAreas(recAreas)
    }, [areas])

    useEffect(() => {
        getUserInfo();
    }, [])

    useEffect(() => {
        getOwner();
    }, [areaDetails])

    useEffect(() => {
        getRatedArea();
    }, [user, areaId])

    if (error) {
        return (
            <AreaError />
        );
    }

    if (areaDetails?.disabled && !isOwner && !isAdmin) {
        return (
            <Disabled />
        );
    }

    return (
        <>
             <Helmet>
                <meta name='title' content={t('title.area')} />
                <meta name='description' content={t('description.area')} />
            </Helmet>
            <div className=" px-[25px] pt-[20px] md:px-[60px] md:pt-[60px]">
                {loading ? <Loader /> : null}

                {(isOwner || isAdmin) && areaDetails?.disabled && (
                    <p className="mb-[25px] text-center xl:text-left font-medium text-secondary">
                        {t('area.not_seen')}
                    </p>
                )}

                <AreaDetails
                    areaDetails={areaDetails}
                    areaFiles={areaFiles}
                    areaImages={areaImages}
                    ratedArea={ratedArea}
                    owner={owner} 
                    isOwner={isOwner}
                    isJoined={isJoined}
                    isVisible={isVisible}
                    isOpenContacts={isOpenContacts}
                    handleNavigateToEdit={handleNavigateToEdit}
                    handleOpenChat={handleOpenChat}
                    handleOpenDetails={handleOpenDetails}
                    handleRateArea={handleRateArea}
                />

                {(isVisible || isAdmin) && (
                    <>
                        <p className="mb-[30px] text-[30px] font-semibold md:mb-[45px] md:text-[38px] text-center">
                            {t("area.discover")}
                        </p>

                        <div className="flex justify-center mb-[50px]">
                            <div ref={mapContainer} className="mapBox_project" id="map-admin" />
                        </div>
                    </>
                )}

                <RecommendedAreas 
                    images={images} 
                    fetchImages={fetchImages} 
                    recommendedAreas={recommendedAreas} 
                />

                <div className="m-auto mt-[60px] max-w-[1178px] md:mt-[152px]">
                    <CallToAction
                        title={t('home.energy_revolution.become_part')}
                        btnTitle={t('area.offer')}
                        onClick={handleNavigate}
                        bgColor="bg-secondary text-white"
                    />
                </div>
            </div>

            <ProjectModals
                openDialog={openDialog}
                twilioClient={twilioClient}
                area={areaDetails}
                image={areaImages.find(el => el.includes('Screenshot'))}
                onClose={handleCloseChat}
                owner={owner}
                isListFull={isListFull}
                isInList={isInList}
                isJoined={isJoined}
                isTypeError={notTypeError}
                onJoin={handleJoinWaitList}
                onListFull={setIsListFull}
                onModalClose={handleCloseNotTypeError}
            />
        </>
    )
}
