/** @format */

import { ReactNode, memo } from 'react';

type Colors = 'Primary' | 'Secondary' | 'white';
type ButtonType = 'button' | 'submit';

interface ButtonProps {
    children: ReactNode;
    color?: Colors;
    width?: string;
    textColor?: string;
    m?: string;
    sx?: string;
    type?: ButtonType;
    onClick?: () => void;
    disabled?: boolean;
}

export const Button = memo((props: ButtonProps) => {
    const { 
        children, 
        color, 
        width, 
        textColor, 
        m, 
        sx, 
        type, 
        onClick,
        disabled
    } = props;
    
    return (
        <button
            type={type || 'submit'}
            className={`${
                color === 'Primary'
                    ? 'bg-secondary '
                    : color === 'Secondary'
                    ? 'bg-primary'
                    : 'bg-white '
            } ${width ? width : ' w-[272px] md:w-[107px] px-[10px]'} ${
                textColor ? textColor : ' text-black '
            } h-[46px] rounded-[10px] ${m} ${sx}`}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </button>
    )
})
