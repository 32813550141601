import { GraphQLQuery } from '@aws-amplify/api';
import { API, Storage, graphqlOperation } from 'aws-amplify';
import { useCallback, useState } from 'react'
import { GetUserInfoByEmailAddressQuery, UserInfo } from '../../../API';
import { getUserInfoByEmailAddress } from '../../../graphql/queries';
import { useAuth, useProcessTry } from '../../../hooks';

interface Options {
  loggedIn: any;
}

export const useProfileInfo = ({ loggedIn }: Options) => {
  const { loginUser } = useAuth();
  const [userImage, setUserImage] = useState<string | File | null>(null);
  const [imageToUpload, setImageToUpload] = useState<File | null>(null);
  const [user, setUser] = useState<UserInfo | null>(null);
  const [isLoading, setLoading] = useState(false);

  const { handleCreateError } = useProcessTry();

  const getUserInfo = useCallback(async () => {
    try {
      const res = await API.graphql<GraphQLQuery<GetUserInfoByEmailAddressQuery>>(
        graphqlOperation(getUserInfoByEmailAddress, {
          email: loggedIn.email
        })
      );
  
      const userId = res.data?.getUserInfoByEmailAddress?.items[0]?.sub;
  
      const storageResults = await Storage.list(userId ? userId : "");
  
      for (const file of storageResults.results) {
        if (file?.key && userId && file?.key.includes(userId)) {
          const fileName = res.data?.getUserInfoByEmailAddress?.items[0]?.profileImageFilename || '';
  
          try {
            const expirationTime = 7 * 24 * 60 * 60;

            const fileRes = await Storage.get(fileName, { expires: expirationTime });
            setUserImage(fileRes);
            loginUser(res.data?.getUserInfoByEmailAddress?.items[0])
          } catch (err) {
            console.log('File error:', err);
          }
        }
      }

      if (res.data?.getUserInfoByEmailAddress?.items[0]) {
        setUser(res.data?.getUserInfoByEmailAddress?.items[0]);
      }
  
      setLoading(false);
    } catch (error: any) {
      console.log("ERROR", error);
      setLoading(false);
  
      const errorData = {
        userInfoSub: loggedIn?.sub,
        requestBody: JSON.stringify(loggedIn.email),
        errorMsg: error.message as string,
        process: 'Get user info by email - profile page',
        DateTimeUnix: Math.floor(new Date().getTime() / 1000)
      };
  
      handleCreateError(errorData);
    }
  }, [loggedIn]);

  return {
    user,
    userImage,
    getUserInfo,
    isLoading,
    imageToUpload,
    setLoading,
    setImageToUpload,
    setUser
  }
}
