import { Link, useNavigate } from 'react-router-dom';
import { Button, LazyLoadImage } from '../../../components';
import { Links } from '../../../settings';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import { useLanguage } from '../../../hooks';

export interface Article { 
    id: string, 
    title: string, 
    description: string, 
    image: string,
    bigImage: string,
    published: string,
    link: string;
};

interface BlogCardProps {
    article: Article;
    isLast: boolean;
}

export const BlogCard = ({ article, isLast }: BlogCardProps ) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { selectedLanguage } = useLanguage({ i18n });

    const handleNavigate = useCallback(() => {
        navigate(`${Links.blog.index}/${article.id}`);
    }, [navigate, article]);

    const handleShowMore = useCallback(() => {
        navigate(Links.blog.index);
    }, [])

    return (
        <Link 
            to={!isLast 
                ? `${Links.blog.index}/${article.id}` 
                : `${Links.blog.index}`
            }
        >
            <div 
                className="relative mb-[20px] md:w-[400px] w-[330px] cursor-pointer rounded-[12px] 
                bg-white transition-all hover:shadow-md md:mr-[20px] mr-[10px] h-full"
            >
                {isLast && (
                    <>
                        <div
                            className="absolute top-0 left-0 w-full h-full bg-gray-200 opacity-90 rounded-[12px] cursor-pointer"
                            style={{ zIndex: 1 }}
                        >
                        </div>

                        <div className="flex justify-center md:justify-start absolute z-10 top-[50%] md:left-[30%] left-[10%]">
                            <Button
                                color="Secondary"
                                onClick={handleShowMore}
                                sx="hover:shadow-xl transition-all md:w-[180px]"
                            >
                                {t('search.show_more')}
                            </Button>
                        </div>
                    </>
                )}

                {article.id === 'ENLAPA_gibt_Mitgliedschaft_beim_Bundesverband_Solarwirtschaft_e.V._bekannt' ? (
                    <div className="mb-[20px] md:h-[250px] h-[200px] w-full rounded-t-[12px] flex justify-center items-center">
                        <LazyLoadImage
                            src={article.image}
                            alt=""
                            className="mb-[20px] md:h-[200px] h-[150px]"
                        />
                    </div>
                ) : (
                    <LazyLoadImage
                        src={article.image}
                        alt=""
                        className="mb-[20px] md:h-[250px] h-[200px] w-full rounded-t-[12px]"
                    />
                )}

                <div className="px-[20px] py-[20px]">
                    <h2
                        className={`mb-[10px] md:text-[20px] text-[18px] font-medium`}
                    >
                        {t(article.title)}
                    </h2>

                    <p className="mb-[15px] text-[15px] font-medium text-gray-400">
                        {t(article.published)}
                    </p>

                    <p className={`mb-[15px] h-[120px] ${selectedLanguage === 'en' ? 'md:h-[110px]' : 'md:h-[120px]'}`}>
                        {t(article.description).slice(0, 145) + '...'}
                    </p>

                    <div className="flex justify-center md:justify-start">
                        <Button
                            color="Secondary"
                            onClick={handleNavigate}
                            sx="hover:shadow-xl transition-all"
                        >
                            {t('articles.read')}
                        </Button>
                    </div>
                </div>
            </div>
        </Link>
    )
}
