import { ChangeEvent, useEffect, useState } from "react";
import {
    GetUserForCompanyByCompanyIDQuery,
    GetUserInfoBySubQuery,
    Organization,
    UpdateOrganizationMutation,
    UpdateUserInfoMutation,
    UserInfo,
} from '../../../API';
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import {
    getUserForCompanyByCompanyID,
    getUserInfoBySub,
} from '../../../graphql/queries';
import { updateOrganization, updateUserInfo } from "../../../graphql/mutations";
import { useSearch } from "../../SearchPage/hooks";

interface Options {
    organization?: Organization | null;
}

export const useOrganizationItem = ({ organization }: Options) => {
    const [owner, setOwner] = useState<UserInfo | null>(null);
    const [members, setMembers] = useState<(UserInfo | null)[]>([]);
    const [isEditMode, setIsEditMode] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const [qualifiedInvestor, setQualifiedInvestor] = useState(false);
    const [exclusiveAccess, setExclusiveAccess] = useState(false);
    const [emailNotif, setEmailNotif] = useState(false);

    const {
        areas,
        getAreas
    } = useSearch({});

    const handleCheck = (option: string) => {
        if (selectedOptions.includes(option)) {
        setSelectedOptions(prevOptions => prevOptions.filter(item => item !== option));
        } else {
        setSelectedOptions(prevOptions => [...prevOptions, option]);
        }
    };

    const handleAccessChange = async (event: ChangeEvent<HTMLInputElement>) => {
        setExclusiveAccess(event.target.checked);

        try {
            if (!organization) return;

            const editingOrganization = {
                id: organization?.id,
                ExclusiveAccessFirst24h: event.target.checked
            };

            const { data } =  await API.graphql<GraphQLQuery<UpdateOrganizationMutation>>(
                graphqlOperation(updateOrganization, { input: editingOrganization })
            );

            console.log(data)
        } catch (error) {
            console.log("Error:", error)
        }
    };

    const handleStatusChange = async (event: ChangeEvent<HTMLInputElement>) => {
        setQualifiedInvestor(event.target.checked);

        if (!owner) return;

        try {
            const editingUser = {
                id: owner.id,
                sub: owner.sub,
                qualifiedInvestor: event.target.checked,
            };

            await API.graphql<GraphQLQuery<UpdateUserInfoMutation>>(
                graphqlOperation(updateUserInfo, {
                    input: editingUser
                })
            );
        } catch (error) {
            console.log("Error:", error)
        }
    };

    const handleNotifChange = async (event: ChangeEvent<HTMLInputElement>) => {
        setEmailNotif(event.target.checked);

        try {
            if (!organization) return;

            const editingOrganization = {
                id: organization?.id,
                SendBroadcastForUnlockToAllOrganizationMembers: event.target.checked
            };

            const { data } =  await API.graphql<GraphQLQuery<UpdateOrganizationMutation>>(
                graphqlOperation(updateOrganization, { input: editingOrganization })
            );

            console.log(data)
        } catch (error) {
            console.log("Error:", error)
        }
    }

    const getOwner = async () => {
        if (!organization) return;

        try {
            const { data } = await API.graphql<GraphQLQuery<GetUserInfoBySubQuery>>(
                graphqlOperation(getUserInfoBySub, { sub: organization?.OwnerOfOrganization})
            );

            if (data?.getUserInfoBySub?.items[0]) {
                setOwner(data.getUserInfoBySub.items[0])
            }
        } catch (error) {
            console.log("Error:", error)
        }
    }

    const getMembers = async () => {
        if (!organization) return;

        try {
            const { data } = await API.graphql<
                GraphQLQuery<GetUserForCompanyByCompanyIDQuery>
            >(
                graphqlOperation(getUserForCompanyByCompanyID, {
                    MemberofOrganizationID: organization?.id,
                })
            )

            if (data?.getUserForCompanyByCompanyID?.items) {
                setMembers(data?.getUserForCompanyByCompanyID?.items);
            }
        } catch (error) {
            console.log("Error:", error)
        }
    }

    useEffect(() => {
        if (!organization) return;

        if (organization.AllowedAreaTypes) {
            setSelectedOptions(organization.AllowedAreaTypes);
        }

        if (owner?.qualifiedInvestor) {
            setQualifiedInvestor(true);
        }

        if (organization.ExclusiveAccessFirst24h) {
            setExclusiveAccess(true);
        }

        if (organization.SendBroadcastForUnlockToAllOrganizationMembers) {
            setEmailNotif(true);
        }
    }, [organization, owner])

    useEffect(() => {
        getOwner();
        getMembers();
    }, [organization])

    useEffect(() => {
        getAreas({
            InvestorsWithApprovedAccess: { contains: organization?.OwnerOfOrganization},
          });
    }, [owner])

    return {
        owner,
        areas,
        members,
        isEditMode,
        qualifiedInvestor,
        exclusiveAccess,
        emailNotif,
        handleAccessChange,
        handleCheck,
        handleNotifChange,
        handleStatusChange,
        selectedOptions,
        setIsEditMode
    }
}