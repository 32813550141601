import toGeoJSON from 'togeojson';

import mapboxgl from "mapbox-gl";
import * as turf from '@turf/turf';
import { Flaeche } from "../../../API";
import { TFunction } from 'i18next';
import { NavigateFunction } from 'react-router-dom';
import { checkType, convertToHectares, filterBySize } from '../../../helpers';
import { SizeValues } from '../../SearchPage/hooks/useSearch';


export const formatDate = (dateString?: string | null) => {
    if (!dateString) return;

    const date = new Date(dateString);

    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;

    return formattedDate;
}

export const filterAdminAreas = (
    areas: (Flaeche | null)[], 
    filterType: string, 
    typeFilter: string,
    values?: SizeValues | null
) => {
    const filteredAreas =  areas?.filter(area => {
        switch (filterType) {
            case 'active':
            case 'aktiv':
                return !area?.disabled;

            case 'deactivated':
            case 'deaktiviert':
                return area?.disabled;

            default:
                return area;
        }
    });

    const typedAreas =  filteredAreas.filter(area => {
        const type = area?.areaType;

        switch(typeFilter) {
            case 'gebäude (z.b. dach)':
            case 'building (e.g. roof)':
                return type === 'addArea.roof';

            case 'building':
            case 'gebäude':
                return type === 'addArea.building';

            case 'noise barrier':
            case 'lärmschutzwand':
                return type === 'addArea.noise_barrier';

            case 'field':
            case 'acker':
                return type === 'addArea.field';

            case 'grassland':
            case 'grünland':
                return type === 'addArea.grassland';

            case 'lake':
            case 'see':
                return type === 'addArea.lake';

            case 'others':
            case 'sonstiges':
                return type === 'addArea.others';

            default:
                return area !== null;
        }
    });

    return filterBySize(typedAreas, values);
};

export const handleAddMap = (
    map: mapboxgl.Map, 
    areas: (Flaeche | null)[], 
    areaImages: string[],
    t: TFunction<"translation", undefined>,
    navigate: NavigateFunction,
    language: string
) => {
    map.on('style.load', async () => {
        try {
            areas.forEach(async (area, i) => {
                const kml = areaImages.find(el => el.includes('kml') && el.includes(area!.id));
        
                if (kml) {
                    const response = await fetch(kml);
                    const kmlText = await response.text();
        
                    const parser = new DOMParser();
                    const kmlDocument = parser.parseFromString(kmlText, 'application/xml');
                    const geoJSON = toGeoJSON.kml(kmlDocument);
        
                    if (geoJSON && geoJSON.features && geoJSON.features.length > 0) {
                        const centroid = turf.centerOfMass(geoJSON).geometry.coordinates;
        
                        try {
                            map.loadImage(
                                'https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png',
                                (error, image: any) => {
                                    if (error) console.log(error);
    
                                    const imageName = `custom-marker-${area!.id}`;
    
                                    if (!map.hasImage(imageName)) {
                                        map.addImage(imageName, image);
                                    }

                                    geoJSON.features.forEach((feature: any) => {
                                        feature.properties.areaId = area!.id;
                                    });
        
                                    map.addLayer({
                                        id: `centroid-${area!.id}`,
                                        type: 'symbol',
                                        source: {
                                            type: 'geojson',
                                            data: {
                                                type: 'Feature',
                                                geometry: {
                                                    type: 'Point',
                                                    coordinates: centroid
                                                },
                                                properties: {
                                                    // title: t(area!.areaType),
                                                    id: area?.id
                                                }
                                            }
                                        },
                                        layout: {
                                            'icon-image': imageName,
                                            'icon-size': 0.5,
                                            'icon-allow-overlap': true,
                                            'text-field': '{title}',
                                            'text-size': 12,
                                            'text-font': ['Open Sans Regular'],
                                            'text-offset': [0, 1.2],
                                            'text-anchor': 'top'
                                        },
                                        paint: {
                                            'text-color': 'red'
                                        }
                                    });

                                    map.on('mouseenter', `centroid-${area!.id}`, (e) => {
                                        if (!e.features) return;
                                    
                                        const coordinates = (e.features[0].geometry as any).coordinates.slice(); // Cast to Point type
                                        const size = checkType(area?.areaType)
                                            ? convertToHectares(area?.areaSize) + ' ' + t('search.ha')
                                            : area?.areaSize?.toLocaleString(language === 'de' 
                                                ? 'de-DE' 
                                                : 'en-US'
                                            ) + ' m²';
                                    
                                        const popup = new mapboxgl.Popup()
                                            .setLngLat(coordinates)
                                            .setHTML(
                                                `<h3>${t('admin_dashboard.name')}: ${area?.areaName ? t(area?.areaName) : ''}}</h3>
                                                <h2>${t('admin_dashboard.size')}: ${size}</h2>
                                                <h2>${t('admin_dashboard.type')}: ${t(area!.areaType)}</h2>
                                                <h2>${t('admin_dashboard.country')}: ${t(area!.address_country ? area!.address_country : '')}</h2>
                                                <h2>${t('admin_dashboard.code')}: ${t(area!.address_postcode)}</h2>
                                                `
                                            )
                                            .addTo(map);

                                        map.on('mouseleave', `centroid-${area!.id}`, () => {
                                            map.getCanvas().style.cursor = '';
                                            popup.remove();
                                        });
                                    });
                                    
                                    map.on('click', `centroid-${area!.id}`, (e) => {
                                        const areaId = e.features && e.features[0].properties?.id;
                                        navigate(`/search/${areaId}`);
                                    });
                                    
                                }
                            );
                        } catch (err) {
                            console.log("Error while loading the image", err)
                        }
                    }
                }
            });
        } catch (error) {
            console.error('Error fetching or parsing KML file:', error);
        }
    });
}
