/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getFlaeche = /* GraphQL */ `query GetFlaeche($id: ID!) {
  getFlaeche(id: $id) {
    ownerSub
    disabled
    disabledDate
    areaType
    areaSize
    areaName
    kml_filename
    map_screenshot_filename
    areSizeMapbox
    MapboxZoomfactor
    MapboxGPSCoordsCircumference
    GeoCircumferenceMapbox
    locale
    updatedTimeUnix
    address_state
    address_street
    address_postcode
    address_other
    address_city
    address_country
    address_noStreet
    compensationType
    additional_information
    isRoofRehabilitationDesired
    ElectriySelfConsumptionPerYear
    additionalInformationLookingFor
    additionalInformationDescription
    identifyAsOwner
    identifyAsLandlord
    identifyAsDeveloper
    identifyAsInvestor
    identifyAsOther
    identifyAsOtherDescription
    progressZustimmung
    progressPachtvertragAbgeschlossen
    progressNetzanschlussbegehren
    progressEinspeisezusage
    progressAufstellungsbeschluss
    progressBebauungsplan
    progressFinanzierung
    progressAusschreibung
    progressBauvertrag
    progressAnlageGebaut
    progressAnlageAngeschlossen
    criteriaMetMargins
    criteriaMetHandicapped
    criteriaMetConversionArea
    criteriaMetParking
    criteriaMetIndustryArea
    criteriaMetPlant
    criteriaMetOther
    GeoCoordinatesLatitude
    GeoCoordinatesLongitude
    GeoCoordinatesElevation
    PvGISDateOfCalc
    PvGISInputFixedSlope
    PvGISInputFixedAzimuth
    PvGISInputSystemLoss
    PvGISCalcOutputTotalE_y
    PvGISCalcOutputperMonthE_y
    ManagementWrongMapboxArea
    ManagementWrongMapboxAreaDate
    ManagementAreaSmallButExtensible
    ManagementAreaSmallButExtensibleDate
    InvestorsWithApprovedAccess
    OrganizationsWithApprovedAccess
    InvestorsWaitlistForAccess
    MaximumNumberOfContactsAllowed
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFlaecheQueryVariables,
  APITypes.GetFlaecheQuery
>;
export const listFlaeches = /* GraphQL */ `query ListFlaeches(
  $filter: ModelFlaecheFilterInput
  $limit: Int
  $nextToken: String
) {
  listFlaeches(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      ownerSub
      disabled
      disabledDate
      areaType
      areaSize
      areaName
      kml_filename
      map_screenshot_filename
      areSizeMapbox
      MapboxZoomfactor
      MapboxGPSCoordsCircumference
      GeoCircumferenceMapbox
      locale
      updatedTimeUnix
      address_state
      address_street
      address_postcode
      address_other
      address_city
      address_country
      address_noStreet
      compensationType
      additional_information
      isRoofRehabilitationDesired
      ElectriySelfConsumptionPerYear
      additionalInformationLookingFor
      additionalInformationDescription
      identifyAsOwner
      identifyAsLandlord
      identifyAsDeveloper
      identifyAsInvestor
      identifyAsOther
      identifyAsOtherDescription
      progressZustimmung
      progressPachtvertragAbgeschlossen
      progressNetzanschlussbegehren
      progressEinspeisezusage
      progressAufstellungsbeschluss
      progressBebauungsplan
      progressFinanzierung
      progressAusschreibung
      progressBauvertrag
      progressAnlageGebaut
      progressAnlageAngeschlossen
      criteriaMetMargins
      criteriaMetHandicapped
      criteriaMetConversionArea
      criteriaMetParking
      criteriaMetIndustryArea
      criteriaMetPlant
      criteriaMetOther
      GeoCoordinatesLatitude
      GeoCoordinatesLongitude
      GeoCoordinatesElevation
      PvGISDateOfCalc
      PvGISInputFixedSlope
      PvGISInputFixedAzimuth
      PvGISInputSystemLoss
      PvGISCalcOutputTotalE_y
      PvGISCalcOutputperMonthE_y
      ManagementWrongMapboxArea
      ManagementWrongMapboxAreaDate
      ManagementAreaSmallButExtensible
      ManagementAreaSmallButExtensibleDate
      InvestorsWithApprovedAccess
      OrganizationsWithApprovedAccess
      InvestorsWaitlistForAccess
      MaximumNumberOfContactsAllowed
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFlaechesQueryVariables,
  APITypes.ListFlaechesQuery
>;
export const getFlaecheRating = /* GraphQL */ `query GetFlaecheRating($id: ID!) {
  getFlaecheRating(id: $id) {
    flaecheId
    userInfoSub
    rating
    ratingSizeLocation
    ratingGrid
    ratingProbability
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFlaecheRatingQueryVariables,
  APITypes.GetFlaecheRatingQuery
>;
export const listFlaecheRatings = /* GraphQL */ `query ListFlaecheRatings(
  $filter: ModelFlaecheRatingFilterInput
  $limit: Int
  $nextToken: String
) {
  listFlaecheRatings(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      flaecheId
      userInfoSub
      rating
      ratingSizeLocation
      ratingGrid
      ratingProbability
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFlaecheRatingsQueryVariables,
  APITypes.ListFlaecheRatingsQuery
>;
export const getFlaecheInterested = /* GraphQL */ `query GetFlaecheInterested($id: ID!) {
  getFlaecheInterested(id: $id) {
    flaecheId
    userInfoSub
    interested
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFlaecheInterestedQueryVariables,
  APITypes.GetFlaecheInterestedQuery
>;
export const listFlaecheInteresteds = /* GraphQL */ `query ListFlaecheInteresteds(
  $filter: ModelFlaecheInterestedFilterInput
  $limit: Int
  $nextToken: String
) {
  listFlaecheInteresteds(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      flaecheId
      userInfoSub
      interested
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFlaecheInterestedsQueryVariables,
  APITypes.ListFlaecheInterestedsQuery
>;
export const getUserInfo = /* GraphQL */ `query GetUserInfo($id: ID!) {
  getUserInfo(id: $id) {
    email
    sub
    firstName
    lastName
    tmpTestField
    profileImageFilename
    locale
    phone
    streetAndHouseNumber
    postalCode
    city
    country
    MemberofOrganizationID
    companyName
    companyWebsite
    interestedIn
    personalStatement
    qualifiedInvestor
    adminFull
    adminCustomerService
    favoriteFlaeches
    nutzungsbedingungenAnwenderAccepted
    nutzungsbedingungenAnwenderTimestamp
    nutzungsbedingungenInvestorAccepted
    nutzungsbedingungenInvestorTimestamp
    NewAreaAlertInstant
    NewAreaAlertDaily
    NewAreaAlertWeekly
    NewAreaAlertLastSent
    EnableExclusivityForAreas
    lastloginDateTime
    SearchCriteriaTypes {
      AreaTypeString
      sizeRange {
        min
        max
        __typename
      }
      __typename
    }
    SearchCriteriaCountries
    SearchCriteriaFederalStates
    SearchCriteriaOfferLookingFor
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserInfoQueryVariables,
  APITypes.GetUserInfoQuery
>;
export const listUserInfos = /* GraphQL */ `query ListUserInfos(
  $filter: ModelUserInfoFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      email
      sub
      firstName
      lastName
      tmpTestField
      profileImageFilename
      locale
      phone
      streetAndHouseNumber
      postalCode
      city
      country
      MemberofOrganizationID
      companyName
      companyWebsite
      interestedIn
      personalStatement
      qualifiedInvestor
      adminFull
      adminCustomerService
      favoriteFlaeches
      nutzungsbedingungenAnwenderAccepted
      nutzungsbedingungenAnwenderTimestamp
      nutzungsbedingungenInvestorAccepted
      nutzungsbedingungenInvestorTimestamp
      NewAreaAlertInstant
      NewAreaAlertDaily
      NewAreaAlertWeekly
      NewAreaAlertLastSent
      EnableExclusivityForAreas
      lastloginDateTime
      SearchCriteriaTypes {
        AreaTypeString
        sizeRange {
          min
          max
          __typename
        }
        __typename
      }
      SearchCriteriaCountries
      SearchCriteriaFederalStates
      SearchCriteriaOfferLookingFor
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserInfosQueryVariables,
  APITypes.ListUserInfosQuery
>;
export const getPricingTest = /* GraphQL */ `query GetPricingTest($id: ID!) {
  getPricingTest(id: $id) {
    Package
    Date
    DateUnix
    email
    Name
    Company
    installedkWPpa
    Phone
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPricingTestQueryVariables,
  APITypes.GetPricingTestQuery
>;
export const listPricingTests = /* GraphQL */ `query ListPricingTests(
  $filter: ModelPricingTestFilterInput
  $limit: Int
  $nextToken: String
) {
  listPricingTests(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      Package
      Date
      DateUnix
      email
      Name
      Company
      installedkWPpa
      Phone
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPricingTestsQueryVariables,
  APITypes.ListPricingTestsQuery
>;
export const getProcessTry = /* GraphQL */ `query GetProcessTry($id: ID!) {
  getProcessTry(id: $id) {
    userInfoSub
    requestBody
    response
    errorMsg
    process
    DateTimeUnix
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProcessTryQueryVariables,
  APITypes.GetProcessTryQuery
>;
export const listProcessTries = /* GraphQL */ `query ListProcessTries(
  $filter: ModelProcessTryFilterInput
  $limit: Int
  $nextToken: String
) {
  listProcessTries(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      userInfoSub
      requestBody
      response
      errorMsg
      process
      DateTimeUnix
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProcessTriesQueryVariables,
  APITypes.ListProcessTriesQuery
>;
export const getAccountingLog = /* GraphQL */ `query GetAccountingLog($id: ID!) {
  getAccountingLog(id: $id) {
    flaecheId
    userInfoSub
    OrganizationID
    DateTimeUnix
    Description
    ActionPerformedbyUserSub
    TransactionTypeAreaUnlockRegular
    TransactionTypeAreaUnlockEarlyAccess
    TransactionTypeAreaAlertNotificationMail
    TransactionTypeChangeOfOrganizationOwnership
    TransactionTypeAccessToContactRemoved
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAccountingLogQueryVariables,
  APITypes.GetAccountingLogQuery
>;
export const listAccountingLogs = /* GraphQL */ `query ListAccountingLogs(
  $filter: ModelAccountingLogFilterInput
  $limit: Int
  $nextToken: String
) {
  listAccountingLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      flaecheId
      userInfoSub
      OrganizationID
      DateTimeUnix
      Description
      ActionPerformedbyUserSub
      TransactionTypeAreaUnlockRegular
      TransactionTypeAreaUnlockEarlyAccess
      TransactionTypeAreaAlertNotificationMail
      TransactionTypeChangeOfOrganizationOwnership
      TransactionTypeAccessToContactRemoved
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAccountingLogsQueryVariables,
  APITypes.ListAccountingLogsQuery
>;
export const getOrganization = /* GraphQL */ `query GetOrganization($id: ID!) {
  getOrganization(id: $id) {
    OwnerOfOrganization
    CompanyName
    websiteDomain
    addressStreetAddress
    addressPostalCode
    addressCity
    addressCountry
    vatID
    disabledOrganization
    InviteCompanyMembersEMail
    TransferOwnershipToEMail
    AccessToAreaID {
      flaecheId
      OrganizationId
      userInfoSub
      DateTime
      __typename
    }
    AllowedAreaTypes
    ExclusiveAccessFirst24h
    SendBroadcastForUnlockToAllOrganizationMembers
    CostPerSeatPerMonth
    CostPerLeadLarger2000
    CostPerLeadLarger1000
    CostPerLeadSmaller1000
    CostSuccessFeePerkWp
    CostPercentageSplitSuccessFee
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrganizationQueryVariables,
  APITypes.GetOrganizationQuery
>;
export const listOrganizations = /* GraphQL */ `query ListOrganizations(
  $filter: ModelOrganizationFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      OwnerOfOrganization
      CompanyName
      websiteDomain
      addressStreetAddress
      addressPostalCode
      addressCity
      addressCountry
      vatID
      disabledOrganization
      InviteCompanyMembersEMail
      TransferOwnershipToEMail
      AccessToAreaID {
        flaecheId
        OrganizationId
        userInfoSub
        DateTime
        __typename
      }
      AllowedAreaTypes
      ExclusiveAccessFirst24h
      SendBroadcastForUnlockToAllOrganizationMembers
      CostPerSeatPerMonth
      CostPerLeadLarger2000
      CostPerLeadLarger1000
      CostPerLeadSmaller1000
      CostSuccessFeePerkWp
      CostPercentageSplitSuccessFee
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrganizationsQueryVariables,
  APITypes.ListOrganizationsQuery
>;
export const getIncompleteAreasFromOfferForm = /* GraphQL */ `query GetIncompleteAreasFromOfferForm($id: ID!) {
  getIncompleteAreasFromOfferForm(id: $id) {
    ownerSub
    areaType
    areaSize
    GeoCoordinatesLatitude
    GeoCoordinatesLongitude
    DateTimeUnixLastUpdate
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetIncompleteAreasFromOfferFormQueryVariables,
  APITypes.GetIncompleteAreasFromOfferFormQuery
>;
export const listIncompleteAreasFromOfferForms = /* GraphQL */ `query ListIncompleteAreasFromOfferForms(
  $filter: ModelIncompleteAreasFromOfferFormFilterInput
  $limit: Int
  $nextToken: String
) {
  listIncompleteAreasFromOfferForms(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      ownerSub
      areaType
      areaSize
      GeoCoordinatesLatitude
      GeoCoordinatesLongitude
      DateTimeUnixLastUpdate
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListIncompleteAreasFromOfferFormsQueryVariables,
  APITypes.ListIncompleteAreasFromOfferFormsQuery
>;
export const getUserInfoByEmailAddress = /* GraphQL */ `query GetUserInfoByEmailAddress(
  $email: String!
  $sortDirection: ModelSortDirection
  $filter: ModelUserInfoFilterInput
  $limit: Int
  $nextToken: String
) {
  getUserInfoByEmailAddress(
    email: $email
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      email
      sub
      firstName
      lastName
      tmpTestField
      profileImageFilename
      locale
      phone
      streetAndHouseNumber
      postalCode
      city
      country
      MemberofOrganizationID
      companyName
      companyWebsite
      interestedIn
      personalStatement
      qualifiedInvestor
      adminFull
      adminCustomerService
      favoriteFlaeches
      nutzungsbedingungenAnwenderAccepted
      nutzungsbedingungenAnwenderTimestamp
      nutzungsbedingungenInvestorAccepted
      nutzungsbedingungenInvestorTimestamp
      NewAreaAlertInstant
      NewAreaAlertDaily
      NewAreaAlertWeekly
      NewAreaAlertLastSent
      EnableExclusivityForAreas
      lastloginDateTime
      SearchCriteriaTypes {
        AreaTypeString
        sizeRange {
          min
          max
          __typename
        }
        __typename
      }
      SearchCriteriaCountries
      SearchCriteriaFederalStates
      SearchCriteriaOfferLookingFor
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserInfoByEmailAddressQueryVariables,
  APITypes.GetUserInfoByEmailAddressQuery
>;
export const getUserInfoBySub = /* GraphQL */ `query GetUserInfoBySub(
  $sub: String!
  $sortDirection: ModelSortDirection
  $filter: ModelUserInfoFilterInput
  $limit: Int
  $nextToken: String
) {
  getUserInfoBySub(
    sub: $sub
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      email
      sub
      firstName
      lastName
      tmpTestField
      profileImageFilename
      locale
      phone
      streetAndHouseNumber
      postalCode
      city
      country
      MemberofOrganizationID
      companyName
      companyWebsite
      interestedIn
      personalStatement
      qualifiedInvestor
      adminFull
      adminCustomerService
      favoriteFlaeches
      nutzungsbedingungenAnwenderAccepted
      nutzungsbedingungenAnwenderTimestamp
      nutzungsbedingungenInvestorAccepted
      nutzungsbedingungenInvestorTimestamp
      NewAreaAlertInstant
      NewAreaAlertDaily
      NewAreaAlertWeekly
      NewAreaAlertLastSent
      EnableExclusivityForAreas
      lastloginDateTime
      SearchCriteriaTypes {
        AreaTypeString
        sizeRange {
          min
          max
          __typename
        }
        __typename
      }
      SearchCriteriaCountries
      SearchCriteriaFederalStates
      SearchCriteriaOfferLookingFor
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserInfoBySubQueryVariables,
  APITypes.GetUserInfoBySubQuery
>;
export const getUserForCompanyByCompanyID = /* GraphQL */ `query GetUserForCompanyByCompanyID(
  $MemberofOrganizationID: String!
  $sortDirection: ModelSortDirection
  $filter: ModelUserInfoFilterInput
  $limit: Int
  $nextToken: String
) {
  getUserForCompanyByCompanyID(
    MemberofOrganizationID: $MemberofOrganizationID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      email
      sub
      firstName
      lastName
      tmpTestField
      profileImageFilename
      locale
      phone
      streetAndHouseNumber
      postalCode
      city
      country
      MemberofOrganizationID
      companyName
      companyWebsite
      interestedIn
      personalStatement
      qualifiedInvestor
      adminFull
      adminCustomerService
      favoriteFlaeches
      nutzungsbedingungenAnwenderAccepted
      nutzungsbedingungenAnwenderTimestamp
      nutzungsbedingungenInvestorAccepted
      nutzungsbedingungenInvestorTimestamp
      NewAreaAlertInstant
      NewAreaAlertDaily
      NewAreaAlertWeekly
      NewAreaAlertLastSent
      EnableExclusivityForAreas
      lastloginDateTime
      SearchCriteriaTypes {
        AreaTypeString
        sizeRange {
          min
          max
          __typename
        }
        __typename
      }
      SearchCriteriaCountries
      SearchCriteriaFederalStates
      SearchCriteriaOfferLookingFor
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserForCompanyByCompanyIDQueryVariables,
  APITypes.GetUserForCompanyByCompanyIDQuery
>;
export const getOrganizationbyOwner = /* GraphQL */ `query GetOrganizationbyOwner(
  $OwnerOfOrganization: String!
  $sortDirection: ModelSortDirection
  $filter: ModelOrganizationFilterInput
  $limit: Int
  $nextToken: String
) {
  getOrganizationbyOwner(
    OwnerOfOrganization: $OwnerOfOrganization
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      OwnerOfOrganization
      CompanyName
      websiteDomain
      addressStreetAddress
      addressPostalCode
      addressCity
      addressCountry
      vatID
      disabledOrganization
      InviteCompanyMembersEMail
      TransferOwnershipToEMail
      AccessToAreaID {
        flaecheId
        OrganizationId
        userInfoSub
        DateTime
        __typename
      }
      AllowedAreaTypes
      ExclusiveAccessFirst24h
      SendBroadcastForUnlockToAllOrganizationMembers
      CostPerSeatPerMonth
      CostPerLeadLarger2000
      CostPerLeadLarger1000
      CostPerLeadSmaller1000
      CostSuccessFeePerkWp
      CostPercentageSplitSuccessFee
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrganizationbyOwnerQueryVariables,
  APITypes.GetOrganizationbyOwnerQuery
>;
export const getIncompleteAreasByOwnerSub = /* GraphQL */ `query GetIncompleteAreasByOwnerSub(
  $ownerSub: String!
  $sortDirection: ModelSortDirection
  $filter: ModelIncompleteAreasFromOfferFormFilterInput
  $limit: Int
  $nextToken: String
) {
  getIncompleteAreasByOwnerSub(
    ownerSub: $ownerSub
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      ownerSub
      areaType
      areaSize
      GeoCoordinatesLatitude
      GeoCoordinatesLongitude
      DateTimeUnixLastUpdate
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetIncompleteAreasByOwnerSubQueryVariables,
  APITypes.GetIncompleteAreasByOwnerSubQuery
>;
