import { Box } from "@mui/material";
import { t } from "i18next";
import { Dispatch, SetStateAction } from "react";

interface InviteModalProps {
    email: string;
    setEmail: Dispatch<SetStateAction<string>>;
    error: string;
    onApprove: () => void;
    onOpen: (val: boolean) => void;
}

export const InviteModal = ({
    email,
    setEmail,
    error,
    onApprove,
    onOpen
}: InviteModalProps) => {
  return (
    <Box className="fixed right-0 top-0 z-10 flex h-full w-full items-center justify-center px-[10px]">
        <div className="z-10 rounded-[8px] bg-[#dcedc8] px-[20px] py-[15px] text-center text-secondary">
            <p className="mb-[20px] max-w-[400px] text-[17px]">
                {t('organization.enter')}
            </p>

            <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="mb-[10px] w-full px-[10px] outline-0"
            />

            {error && (
                <p className="mb-[10px] text-red-500">
                    {error}
                </p>
            )}

            <div className="flex gap-[10px] w-full justify-center">
                <button
                    className="rounded-[8px] bg-secondary px-[10px] py-[5px] text-white"
                    onClick={onApprove}
                    type="button"
                >
                    {t('organization.invite')}
                </button>

                <button
                    className="rounded-[8px] bg-white px-[10px] py-[5px] text-secondary"
                    onClick={() => onOpen(false)}
                    type="button"
                >
                    {t('organization.cancel')}
                </button>
            </div>
        </div>
    </Box>
  )
}
