import { East } from "@mui/icons-material"
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom"

export const LoginBlock = () => {
    const { t } = useTranslation();

    return (
        <div className="hidden items-center gap-[42px] md:flex">
            <Link to="/login">
                <div className="flex gap-[10px]">
                    <img src="/assets/icons/person.svg" alt="profile" />
                    <p className="text-[16px] font-medium text-[#11470E]">
                        {t('header.login')}
                    </p>
                </div>
            </Link>

            <Link to="/signup">
                <div className="flex items-center gap-[15px]">
                    <p className="text-[16px] font-medium text-[#8EAD44]">
                        {t('header.register_now')}
                    </p>
                    
                    <div className="text-[#8EAD44] w-[36px] h-[36px] 
                        rounded-3xl border-[1px] border-[#8EAD44] border-solid flex items-center justify-center"
                    >
                        <East />
                    </div>
                </div>
            </Link>
        </div>
    )
}
