import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Heading } from "../../../components";
import i18n from "../../../i18n";
import { useLanguage, useObserver } from "../../../hooks";

interface PriceBlockProps {
    name: string;
    oldPrice?: string;
    price: string;
    yearly: number;
}

export const PriceBlock = ({ 
    name, 
    oldPrice, 
    price, 
    yearly, 
}: PriceBlockProps) => {
    const discountPriceRef = useRef<any>();
    const [animationActive, setAnimationActive] = useState(false);

    const { t } = useTranslation();
    useObserver({ ref: discountPriceRef, onObserve: setAnimationActive });
    const { selectedLanguage } = useLanguage({ i18n });

    return (
        <div className="mt-[10px] text-center" ref={name === 'pricing.expert' ? discountPriceRef : null}>
            <div
                className={`${
                    name === 'pricing.expert'
                        ? 'price-animation-container'
                        : 'flex justify-center'
                }`}
            >
                <div
                    className={`old-price ${animationActive ? 'animate' : ''}`}
                >
                    {oldPrice && (
                        <div className="relative">
                            <span className="font-medium">{oldPrice}€ </span>
                            <div 
                                className={`cross-line ${animationActive ? 'animate' : ''} 
                                absolute`} 
                            />
                        </div>
                    )}
                </div>

                <div
                    className={`${
                        name === 'pricing.expert' ? 'new-price' : ''
                    } ${animationActive ? 'animate' : ''}`}
                >
                    <div className="flex items-end">
                        <Heading
                            title={t(price)}
                            sx="text-[60px] mb-[-15px] md:mb-0 "
                        />

                        {t(price) !== 'Custom' && (
                            <span className="text-xl font-medium">
                                {t('pricing.month')}
                            </span>
                        )}
                    </div>
                </div>
            </div>

            <span className="mt-[6px] block font-medium">
                {`${yearly.toLocaleString(selectedLanguage === 'de' 
                    ? 'de-DE' 
                    : 'en-US')}€ / ${t('pricing.year')}`}
            </span>
        </div>
    )
}
