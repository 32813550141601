import { useNavigate } from "react-router-dom";
import { Links } from "../../../settings";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../hooks";
import { useContext } from "react";
import { MessagesContext } from "../../../contexts";

interface UserProfileProps {
    onClick: () => void;
    isOpen: boolean;
    onLogOut: () => void;
    scrollClasses: string;
    iconClasses: string;
    image: string;
}

export const UserProfile = (props: UserProfileProps) => {
    const { 
        onClick, 
        isOpen, 
        onLogOut, 
        scrollClasses, 
        iconClasses,
        image
    } = props;

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isAdmin } = useAuth();
    const { unreadCount } = useContext(MessagesContext);

    const handleNavigate = (link: string) => {
        navigate(link);
        onClick();
    };

    return (
        <div className="relative flex">
            <div className="relative">
                <img
                    src={image || '/assets/icons/profile-icon.png'}
                    alt="Enlapa Profile Icon"
                    className={`cursor-pointer rounded-full md:mr-[15px] ${iconClasses}`}
                    onClick={onClick}
                />

                {unreadCount > 0 && (
                    <div 
                        className="absolute text-[12px] bg-primary h-[20px] w-[20px] rounded-full 
                            flex justify-center items-center top-0 md:right-[10px] right-0"
                    >
                        {unreadCount}
                    </div>
                )}
            </div>

            {isOpen && (
                <div 
                    className={`absolute md:right-[-30px] lg:right-[-50px] right-[-60px] top-[20px] 
                        2xl:right-[-20px] md:top-[60px] flex w-[185px] flex-col items-start 
                        overflow-hidden rounded-xl bg-white shadow-md text-[15px] ${scrollClasses}`}
                >
                    {process.env.REACT_APP_Deactivate_Chat === '0' && (
                        <button 
                            className="w-full cursor-pointer px-[30px] py-[5px] hover:bg-[#f5f5f5]"
                            onClick={() => handleNavigate(Links.chat.index)}
                        >
                            {t('header.messages')}

                            {unreadCount > 0 && (
                                <div 
                                    className="absolute text-[12px] bg-primary h-[20px] w-[20px] rounded-full 
                                        flex justify-center items-center top-[10px] right-[10px]"
                                >
                                    {unreadCount}
                                </div>
                            )}
                        </button>
                    )}

                    <button 
                        className="w-full cursor-pointer px-[20px] py-[5px] hover:bg-[#f5f5f5]"
                        onClick={() => handleNavigate(Links.areas.index)}
                    >
                        {t('header.your_areas')}
                    </button>

                    <button 
                        className="w-full cursor-pointer px-[2px] py-[5px] hover:bg-[#f5f5f5]"
                        onClick={() => handleNavigate(Links.profile.index)}
                    >
                        {t('header.personal_info')}
                    </button>

                    {isAdmin && (
                        <button 
                            className="w-full cursor-pointer px-[20px] py-[5px] hover:bg-[#f5f5f5]"
                            onClick={() => handleNavigate(Links.adminDashboard.index)}
                        >
                            {t('admin_dashboard.maintenance')}
                        </button>
                    )}

                    <button
                        className="w-full cursor-pointer px-[30px] py-[5px] hover:bg-[#f5f5f5]"
                        onClick={onLogOut}
                    >
                        {t('header.sign_out')}
                    </button>
                </div>
            )}
        </div>
    )
}
