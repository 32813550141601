import { TableCell } from '@mui/material';
import { Flaeche, UserInfo } from '../../../API';
import { useTranslation } from 'react-i18next';

interface AreaAccessBlockProps {
    investors: UserInfo[];
    area?: Flaeche | null;
    waitList: UserInfo[];
    showInvestors: boolean;
    showWaitList: boolean;
    setShowInvestors: (val: boolean) => void;
    setShowWaitList: (val: boolean) => void;
    revokeInvestorAccess: (user: UserInfo) => void
}

export const AreaAccessBlock = ({
    investors,
    waitList,
    area,
    showInvestors,
    showWaitList,
    setShowInvestors,
    setShowWaitList,
    revokeInvestorAccess
}: AreaAccessBlockProps) => {
    const { t } = useTranslation();

    return (
        <>
            <TableCell
                className="relative"
                onMouseEnter={() => setShowInvestors(true)}
                onMouseLeave={() => setShowInvestors(false)}
            >
                <div className="flex w-full justify-center">
                    {investors.length || 0}
                </div>
                {showInvestors && investors.length > 0 && (
                    <div className="absolute z-10 rounded-[8px] bg-white p-[5px] shadow-md">
                        {investors?.map((user) => (
                            <div
                                className="mb-[10px] flex gap-[10px]"
                                key={user.sub}
                            >
                                <p>{user?.email}</p>

                                <button
                                    className="rounded-[5px] bg-red-600 px-[4px] 
                                            text-[10px] text-white"
                                    onClick={() => revokeInvestorAccess(user)}
                                >
                                    {t('admin_dashboard.revoke')}
                                </button>
                            </div>
                        ))}
                    </div>
                )}
            </TableCell>

            <TableCell
                className="relative"
                onMouseEnter={() => setShowWaitList(true)}
                onMouseLeave={() => setShowWaitList(false)}
            >
                <div className="flex w-full justify-center">
                    {area?.InvestorsWaitlistForAccess?.length || 0}
                </div>
                {showWaitList && waitList.length > 0 && (
                    <div className="absolute z-10 rounded-[8px] bg-white p-[5px] shadow-md">
                        {waitList.map((user) => (
                            <p key={user.sub}>{user?.email}</p>
                        ))}
                    </div>
                )}
            </TableCell>
        </>
    )
}
