import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../../hooks';
import i18n from "../../../i18n";
import { useSearch } from '../../SearchPage/hooks';
import { useArea } from '../hooks';
import { filterAdminAreas, handleAddMap } from '../utils';
import mapboxgl from 'mapbox-gl';
import { Dropdown, LoadMoreButton, Loader } from '../../../components';
import { SizeFilter } from '../../SearchPage/components';
import { FormControlLabel } from '@mui/material';
import { IOSSwitch } from '../styled';
import { MapButtons } from './MapButtons';
import { AreasTable } from './AreasTable';
import { AreaCard } from './AreaCard';
import { types } from '../../../utils/formLists';
import { filterOptions } from '../utils/options';

const filterTypes = [
    {
        "value": "addArea.all",
        "label": "addArea.all"
    },
    ...types
];

const TOKEN = 'pk.eyJ1IjoiZW5sYXBhIiwiYSI6ImNsbzc2dndjbTAybmMydnBjNXE3OTVzbWkifQ.eoRkVu1mm9KFcTTq7plkew';

export const AreasDashboard = () => {
    const mapContainer = useRef<any>();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { selectedLanguage } = useLanguage({ i18n });

    const { 
        getAreas, 
        areaImages, 
        areas, 
        handleLoadMore, 
        itemsToShow,
        loading,
        fetchImages,
        sizeRef,
        isOpenSizeFilter,
        areasToShow,
        handleFilterSize,
        toggleSizeFilter,
        submitSizeFiltering,
        submittedValues,
        maxValue,
        minValue,
        filterBy,
        setFilterBy,
        filteredAreas,
        setFilteredAreas,
        filterType,
        setFilterType
    } = useSearch({ isAdminPage: true });

    const {
        checked,
        openMap,
        goBack,
        showMap,
        deleteArea,
        handleChange
    } = useArea({ filteredAreas, setFilteredAreas })

    useEffect(() => {
        const sortedAreas = [...areas].sort((a, b) => {
            if (!a?.createdAt || !b?.createdAt) {
                return 0;
            }

            const dateA = new Date(a?.createdAt).getTime();
            const dateB = new Date(b?.createdAt).getTime();

            return dateB - dateA;
        })

        const filtered = filterAdminAreas(sortedAreas, filterBy, filterType, submittedValues);
        setFilteredAreas(filtered);
    }, [areas, filterBy, checked, filterType, submittedValues])

    useEffect(() => {
        getAreas();
    }, [getAreas])

    useEffect(() => {
        if (openMap) {
            mapboxgl.accessToken = TOKEN;
    
            const map = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mapbox/streets-v12',
                center: [10.4515, 51.1657],
                zoom: 5,
                preserveDrawingBuffer: true
            });
        
            handleAddMap(
                map,
                filteredAreas,
                areaImages,
                t,
                navigate,
                selectedLanguage
            )
            
            return () => {
                map.remove();
            };
        }
    }, [
        mapContainer, 
        filterBy, 
        filteredAreas, 
        areaImages, 
        selectedLanguage, 
        openMap
    ]);   

    return (
        <div>
            <div
                className="mb-[30px] flex w-[275px] w-full flex-col flex-wrap 
                justify-center gap-[10px] md:flex-row md:justify-start"
            >
                <Dropdown
                    placeholder={t('admin_dashboard.status')}
                    options={filterOptions}
                    height="h-[105px]"
                    filterStyle="md:w-[275px] w-full py-[11px] rounded-[6px]"
                    setFilter={setFilterBy}
                    defaultValue={filterOptions[1].label}
                />

                <Dropdown
                    placeholder={t('admin_dashboard.type')}
                    options={filterTypes}
                    height="h-[105px]"
                    filterStyle="md:w-[275px] w-full py-[11px] rounded-[6px]"
                    setFilter={setFilterType}
                />

                <SizeFilter
                    sizeRef={sizeRef}
                    minValue={minValue}
                    maxValue={maxValue}
                    areasToShow={areasToShow}
                    toggleSizeFilter={toggleSizeFilter}
                    onFilterSize={handleFilterSize}
                    isOpenSizeFilter={isOpenSizeFilter}
                    submitted={submittedValues}
                    submitFiltering={submitSizeFiltering}
                    sx="md:w-fit w-full"
                />
            </div>

            <div className="flex items-start gap-[20px]">
                {!openMap && (
                    <FormControlLabel
                        control={
                            <IOSSwitch
                                sx={{ m: 1 }}
                                checked={checked}
                                onChange={handleChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label={t('admin_dashboard.table')}
                        className="mb-[20px]"
                    />
                )}

                <MapButtons
                    openMap={openMap}
                    onBack={goBack}
                    onOpen={showMap}
                />
            </div>

            {!openMap ? (
                <div>
                    {loading ? (
                        <Loader />
                    ) : checked ? (
                        <AreasTable
                            areas={filteredAreas}
                            areaImages={areaImages}
                            onDelete={deleteArea}
                            fetchImages={fetchImages}
                        />
                    ) : (
                        <>
                            <div className="mb-[40px] flex flex-wrap justify-center gap-[30px]">
                                {filteredAreas
                                    .slice(0, itemsToShow)
                                    .map((area) => (
                                        <AreaCard
                                            key={area?.id}
                                            area={area}
                                            onDelete={deleteArea}
                                            image={
                                                areaImages.find(
                                                    (image) =>
                                                        image.includes(
                                                            area!.id
                                                        ) &&
                                                        image.includes(
                                                            'Screenshot.png'
                                                        )
                                                ) ?? ''
                                            }
                                            fetchImages={fetchImages}
                                        />
                                    ))}
                            </div>

                            {filteredAreas.length > itemsToShow && (
                                <LoadMoreButton onShowMore={handleLoadMore} />
                            )}
                        </>
                    )}
                </div>
            ) : (
                <div className="mt-[50px] flex justify-center">
                    <div ref={mapContainer} className="mapBox" id="map-admin" />
                </div>
            )}
        </div>
    )
}
