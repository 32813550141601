import { useTranslation } from 'react-i18next';
import { CallToAction } from '../../../components';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { Links } from '../../../settings';
import { ArticleImage } from './Image';

export const SixArticle = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleNavigate = useCallback(() => {
        navigate(Links.offer.index);
    }, [navigate]);

    return (
        <div className="mb-[20px] flex flex-col gap-[25px] text-[16px] text-[#333] md:text-[18px]">
            <span className="uppercase text-primary">{t('articles.info')}</span>

            <h1 className="text-[25px] font-medium md:text-[35px]">
                {t('article_six.title')}
            </h1>

            <div className="h-[2px] w-[150px] bg-primary" />

            <span className="text-[13px] uppercase text-gray-700 md:text-[16px]">
                {t('articles.article_six.date')}
            </span>

            <p>{t('article_six.p_1')}</p>

            <p>{t('article_six.p_2')}</p>

            <p>{t('article_six.p_3')}</p>

            <ArticleImage
                source="/assets/images/SixArticle.jpg"
                link="https://unsplash.com/de/fotos/solarpanel-unter-blauem-himmel-XGAZzyLzn18"
            />

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_six.subtitle_1')}
            </h2>

            <p>{t('article_six.p_4')}</p>

            <p>{t('article_six.p_5')}</p>

            <div className="flex">
                <div>
                    <div className="w-[220px] border-[1px] border-dashed py-[5px] text-center">
                        <p>{t('article_six.table.title_1')}</p>
                    </div>

                    <div className="w-[220px] border-[1px] border-dashed py-[5px] pl-[10px]">
                        <p>{t('article_six.table.point_1')}</p>
                    </div>

                    <div className="w-[220px] border-[1px] border-dashed py-[5px] pl-[10px]">
                        <p>{t('article_six.table.point_2')}</p>
                    </div>
                </div>

                <div>
                    <div className="w-[220px] border-[1px] border-dashed py-[5px] text-center">
                        <p>{t('article_six.table.title_2')}</p>
                    </div>

                    <div className="w-[220px] border-[1px] border-dashed py-[5px] pl-[10px]">
                        <p>{t('article_six.table.point_3')}</p>
                    </div>

                    <div className="w-[220px] border-[1px] border-dashed py-[5px] pl-[10px]">
                        <p>{t('article_six.table.point_4')}</p>
                    </div>
                </div>
            </div>

            <p>{t('article_six.p_6')}</p>

            <p>{t('article_six.p_7')}</p>

            <ArticleImage
                source="/assets/images/Installation.jpg"
                link="https://unsplash.com/de/fotos/zwei-manner-die-ein-blech-tragen-MagdWoazARo"
            />

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_six.subtitle_2')}
            </h2>

            <p>{t('article_six.p_8')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_six.subtitle_3')}
            </h3>

            <p>{t('article_six.p_9')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_six.subtitle_4')}
            </h3>

            <p>{t('article_six.p_10')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_six.subtitle_5')}
            </h3>

            <p>{t('article_six.p_11')}</p>

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_six.subtitle_6')}
            </h2>

            <p>{t('article_six.p_12')}</p>

            <p>
                <b>{t('article_six.p_13.bold')} </b>{' '}
                {t('article_six.p_13.text')}
            </p>
            <p>
                <b>{t('article_six.p_14.bold')} </b>{' '}
                {t('article_six.p_14.text')}
            </p>
            <p>
                <b>{t('article_six.p_15.bold')} </b>{' '}
                {t('article_six.p_15.text')}
            </p>
            <p>
                <b>{t('article_six.p_16.bold')} </b>{' '}
                {t('article_six.p_16.text')}
            </p>
            <p>
                <b>{t('article_six.p_17.bold')} </b>{' '}
                {t('article_six.p_17.text')}
            </p>
            <p>
                <b>{t('article_six.p_18.bold')} </b>{' '}
                {t('article_six.p_18.text')}
            </p>

            <p>{t('article_six.p_19')}</p>

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_six.subtitle_7')}
            </h2>

            <p>{t('article_six.p_20')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_six.subtitle_8')}
            </h3>

            <p>{t('article_six.p_21')}</p>

            <ul className="flex list-disc flex-col gap-[10px] pl-[30px]">
                <li>
                    <b>{t('article_six.list_1.option_1.bold')}</b>{' '}
                    {t('article_six.list_1.option_1.text')}
                </li>
                <li>
                    <b>{t('article_six.list_1.option_2.bold')} </b>{' '}
                    <ul className="flex list-disc flex-col gap-[10px] pl-[30px]">
                        <li>{t('article_six.list_1.option_2.text_1')}</li>
                        <li>{t('article_six.list_1.option_2.text_2')}</li>
                        <li>{t('article_six.list_1.option_2.text_3')}</li>
                        <li>{t('article_six.list_1.option_2.text_4')}</li>
                    </ul>
                </li>
            </ul>

            <p>{t('article_six.p_22')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_six.subtitle_9')}
            </h3>

            <p>{t('article_six.p_23')}</p>

            <ul className="flex list-disc flex-col gap-[10px] pl-[30px]">
                <li>
                    <b>{t('article_six.list_2.option_1.bold')}</b>{' '}
                    {t('article_six.list_2.option_1.text')}
                    <ul className="flex list-disc flex-col gap-[10px] pl-[30px]">
                        <li>
                            <b>{t('article_six.list_2.option_1.bold_text')}</b>
                            {t('article_six.list_2.option_1.text_2')}
                        </li>
                    </ul>
                </li>
                <li>
                    <b>{t('article_six.list_2.option_2.bold')}</b>{' '}
                    {t('article_six.list_2.option_2.text')}
                    <ul className="flex list-disc flex-col gap-[10px] pl-[30px]">
                        <li>
                            <b>{t('article_six.list_2.option_2.bold_text')}</b>
                            {t('article_six.list_2.option_2.text_2')}
                        </li>
                    </ul>
                </li>
            </ul>

            <p>{t('article_six.p_24')}</p>

            <div className="mx-[25px] text-[16px] md:mt-[50px]">
                <CallToAction
                    title={t('home.call_to_action_money')}
                    btnTitle={t('buttons.offer_your_space_now')}
                    bgColor="bg-secondary text-white"
                    onClick={handleNavigate}
                    tag="p"
                />
            </div>
        </div>
    )
}
