import { useEffect } from "react";
import { useTranslation } from "react-i18next";


interface Options {
    title: string;
    description: string;
}

export const usePageDescription = ({
    title,
    description
}: Options) => {
    const { t } = useTranslation();

    useEffect(() => {
        document.title = title;
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
            metaDescription.setAttribute('content', description);
        }

        return () => {
            document.title = 'ENLAPA - Das Portal f&uuml;r Energie-Land-Pacht';

            if (metaDescription) {
                metaDescription.setAttribute('content', 'Flächen zur Energieerzeugung anbieten oder pachten');
            }
        };
    }, [t]);
}
