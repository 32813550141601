import React from 'react'
import { useTranslation } from 'react-i18next'
import { types } from '../../../utils/dataList';

export const TypeModal = () => {
  const { t } = useTranslation();

  return (
    <div className='bg-white z-10 absolute 2xl:top-0 top-[28px] lg:right-[-100px] flex flex-wrap gap-[15px] w-[309px] px-[16px] py-[19px] border-[#111A2126] rounded-xl border-[1px]'>
        {types.map(type => (
            <div key={type.image}>
                <img src={type.image} className='w-[130px] h-[73px] mb-[15px]' alt="area type" />
                <p className='text-[14px] font-normal'>{t(type.title)}</p>
            </div>
        ))}
    </div>
  )
}
