/** @format */

import { Heading } from '../../components/';
import { Card } from './components/PlanCard';
import { useTranslation } from 'react-i18next';
import { Checkout } from './components/Checkout';
import { SalesPlan } from './components/SalesPlan';
import { usePricing } from './hooks/usePricing';
import { Switcher } from './components/Switcher';
import { usePageDescription } from '../../hooks';
import { Helmet } from 'react-helmet';

export const Pricing = () => {
    const { t } = useTranslation();

    const {
        plans,
        isYearly,
        selectedPackage,
        setIsYearly,
        setSelectedPackage,
    } = usePricing();

    usePageDescription({ 
        title: t('title.pricing'),
        description: t('description.pricing')
    });

    return (
        <>
        <Helmet>
            <meta name='title' content={t('title.pricing')} />
            <meta name='description' content={t('description.pricing')} />
        </Helmet>
        <div className="mx-[20px] md:my-[120px] my-[80px] flex flex-col items-center justify-center">
            <div className="flex flex-col items-center">
                <Heading
                    title={t('pricing.title')}
                    sx="md:max-w-[716px] text-center"
                    leading="md:leading-[58px]"
                    tracking="md:tracking-[-4%]"
                />

                <p className='my-[20px] text-[13px] max-w-[600px] text-center'>{t('pricing.landOwners_free')}</p>

                <Switcher 
                    isYearly={isYearly} 
                    setIsYearly={setIsYearly} 
                />
            </div>

            <div className='xl:max-w-[1075px] md:max-w-[710px] max-w-[343px]'>
                <div className="mt-[110px] flex flex-wrap justify-center gap-y-16 md:gap-x-5 mb-[25px]">
                    {plans.map((plan) => (
                        <Card 
                            key={plan.name} 
                            {...plan} 
                            onSelect={setSelectedPackage} 
                            selected={selectedPackage} 
                        />
                    ))}
                </div>

                <SalesPlan onSelect={setSelectedPackage} />
            </div>

            <p className='md:mb-[120px] mb-[40px]'>{t('pricing.guarantee')}</p>

            <div id="form" className='w-full'>
                {selectedPackage && (
                    <Checkout 
                        selectedPlan={selectedPackage} 
                        onSelect={setSelectedPackage}
                    />
                )}
            </div>
        </div>
        </>

    )
}
