import { Chip, FormControl, Input, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../../../styles/jss/useStyles';

interface Options {
    value: string;
    label: string;
}

interface MutipleSelectProps {
    options: Options[];
    values: string[];
    label: string;
    placeholder: string;
    onValuesChange: (e: SelectChangeEvent<string[]>) => void;
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

export const MultipleSelect = ({
    label,
    placeholder,
    options,
    values,
    onValuesChange
}: MutipleSelectProps) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <div>
            <p className="mb-[20px] text-[14px] text-gray-500">{label}</p>

            <FormControl className={classes.formControl} sx={{ minWidth: 250 }}>
                <InputLabel id="demo-mutiple-chip-label">
                    {placeholder}
                </InputLabel>
                <Select
                    sx={{ minHeight: 45 }}
                    labelId="demo-mutiple-chip-label"
                    id="demo-mutiple-chip"
                    multiple
                    value={values}
                    onChange={onValuesChange}
                    input={<Input id="select-multiple-chip" />}
                    renderValue={(selected) => (
                        <div className={classes.chips}>
                            {selected.map((value) => (
                                <Chip
                                    key={value}
                                    label={t(value)}
                                    className={classes.chip}
                                />
                            ))}
                        </div>
                    )}
                    MenuProps={MenuProps}
                >
                    {options.map((el) => (
                        <MenuItem key={el.value} value={el.value}>
                            {t(el.label)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    )
}
