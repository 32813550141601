/** @format */

import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Acceptance } from './Acceptance';
import { TUserInfoSchema, UserInfoSchema, formatSignUpError } from '../../../helpers';
import { Button, Checkbox, CustomInput, Heading } from '../../../components';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { category_login, company, userInfo } from '../../../utils/formLists';

/** TYPES */
interface IProps {
    nextStep: (data: TUserInfoSchema) => void;
    isLoginPage?: boolean;
    signInFailure: boolean;
}

export const UserInfo = ({ nextStep, signInFailure }: IProps): JSX.Element => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [phone, setPhone] = useState<string>('');

    const {
        register,
        setValue,
        handleSubmit,
        trigger,
        formState: { errors },
    } = useForm<TUserInfoSchema>({
        resolver: yupResolver(UserInfoSchema),
        mode: 'all'
    });

    const onSubmitFn: SubmitHandler<TUserInfoSchema> = (data): void => {
        const userInfo = { ...data, phone };
        nextStep(userInfo);
    };

    const switchPages = useCallback((): void => {
        navigate('/login', {
            replace: true,
        });
    }, [navigate]);

    return (
        <form onSubmit={handleSubmit(onSubmitFn)} className="w-full">
            <div className="flex flex-col flex-wrap items-center 
                justify-center gap-y-5 md:flex-row md:items-start md:gap-x-5 mb-[20px]"
            >
                {userInfo.map((field) => (
                    <div className={field.width} key={field.name}>
                        <div>
                            <CustomInput
                                type={field?.type}
                                placeholder={t(field.placeholder)}
                                options={field?.options}
                                setValue={setValue}
                                trigger={trigger}
                                defaultValue={field?.defaultValue}
                                sx={`bg-white border-grey-50 border-solid border-[1px] ${
                                    errors[
                                        field.name as keyof TUserInfoSchema
                                    ] && 'border-red-400 outline-red-400'
                                }`}
                                register={register}
                                name={field.name as keyof TUserInfoSchema}
                            />
                        </div>
                    </div>
                ))}
            </div>

            <PhoneInput
                country={'de'}
                placeholder={t('placeholders.phone_number')}
                value={phone}
                onChange={e => setPhone(e)}
            />

            <hr className="my-10 h-[2px] w-full bg-gray-50" />

            <div className="w-full">
                <Heading
                    title="Company information (if applicable)"
                    size="text-xs md:text-base"
                    tracking="tracking-[3px]"
                    sx="uppercase block mb-[20px]"
                />
                <div className="flex flex-col flex-wrap items-center justify-center md:justify-start 
                    gap-y-5 md:flex-row md:items-start md:gap-x-5"
                >
                    {company.map((field) => (
                        <div className={field.width} key={field.name}>
                            <div>
                                <CustomInput
                                    type={field?.type}
                                    placeholder={t(field.placeholder)}
                                    options={field?.options}
                                    setValue={setValue}
                                    trigger={trigger}
                                    defaultValue={field?.defaultValue}
                                    sx={`bg-white border-grey-50 border-solid border-[1px] ${
                                        errors[
                                            field.name as keyof TUserInfoSchema
                                        ] && 'border-red-400 outline-red-400'
                                    }`}
                                    register={register}
                                    name={field.name as keyof TUserInfoSchema}
                                />
                            </div>
                        </div>
                    ))}
                </div>

                <div className='w-full flex flex-col items-center justify-center'>
                    <div className="mt-5 flex flex-col items-start justify-between lg:flex-row lg:items-center">
                        <p className="md:mr-5">{t('sign_up.interested_in')}</p>
                        {category_login.map((value) => (
                            <Checkbox label={t(value.label)} key={value.label} name="interest" register={register} />
                        ))}
                    </div>
                </div>
            </div>

            <hr className="my-10 h-[2px] w-full bg-gray-50" />

            <Acceptance register={register} />

            <div className="mb-[20px] flex w-full flex-col items-center justify-center gap-y-4 md:flex-row md:gap-x-6">
                <div>
                    <Button
                        color="Secondary"
                        width="w-[100%] md:w-[162px] md:h-[65px]"
                        sx="px-[20px]"
                    >
                        {t('header.register_now')}
                    </Button>
                </div>
                <Button
                    type="button"
                    width="w-[198px]"
                    sx="underline"
                    onClick={switchPages}
                >
                    {t('login.already_have_account')}
                </Button>
            </div>

            {errors['accept' as keyof TUserInfoSchema] && (
                <div className='flex justify-center'>
                    <p className='mt-1 block text-red-400 mt-[10px]'>
                        {t('sign_up.please')}
                    </p>
                </div>
            )}

            {signInFailure &&  (
                <div className='flex justify-center'>
                    <span className="mt-1 block text-red-400 mt-[10px]">
                        {t(formatSignUpError(String(signInFailure)))}
                    </span>
                </div>
            )}   
        </form>
    )
}
