import React from 'react';

interface CheckboxesSectionProps {
    title: string;
    layers: { [key: string]: boolean };
    isOpen: boolean;
    toggleOpen: () => void;
    handleCheckboxes: (checkboxes: { [key: string]: boolean; }) => JSX.Element[];
}

export const CheckboxesSection = React.memo(
    ({ title, layers, isOpen, toggleOpen, handleCheckboxes }: CheckboxesSectionProps) => {
        
        return (
            <div className="mb-2 rounded-[8px] p-[10px]">
                <h3 className="-mx-2 -my-3 flow-root">
                    <button
                        type="button"
                        className="flex w-full items-center justify-between bg-white text-gray-400 hover:text-gray-500"
                        onClick={toggleOpen}
                        aria-expanded={isOpen}
                    >
                        <span className="font-medium text-gray-900 mb-2">
                            {title}
                        </span>
                        <span className="ml-6 flex items-center">
                            {isOpen ? (
                                <svg
                                    className="h-5 w-5"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    className="h-5 w-5"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
                                </svg>
                            )}
                        </span>
                    </button>
                </h3>
                {isOpen && (
                    <div className="mt-4 overflow-y-scroll">
                        <div className="space-y-1">
                            {handleCheckboxes(layers)}
                        </div>
                    </div>
                )}
            </div>
        );
    }
);
