import { Search } from '@mui/icons-material';
import { ChangeEvent, useEffect, useState } from 'react'
import { DataTable, Loader } from '../../../components';
import { useTranslation } from 'react-i18next';
import { useUsersData } from '../hooks';
import { OnboardingItem } from './OnboardingItem';
import { onboardingColumns } from '../utils/columns';

export const OnboardingDashboard = () => {
    const [query, setQuery] = useState('');
    const [approvedQuery, setApprovedQuery] = useState('');

    const { t } = useTranslation();
    const {
        sortedUsers: users,
        isLoading,
        getUsers
    } = useUsersData();

    const handleApproveQuery = () => {
        setApprovedQuery(query);
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value)
    };

    useEffect(() => {
        if (!approvedQuery) return;
        getUsers({email: { eq: approvedQuery }});
    }, [approvedQuery])

    return (
        <div>
            <div className='flex gap-[15px]'>
                <div className='relative mb-[20px]'>
                    <input
                        type="text"
                        placeholder={t('admin_dashboard.search')}
                        className="flex 
                        md:w-[250px] w-full justify-between rounded-[5px] bg-[#F3F3F3] 
                        px-7 py-[10px] text-left text-base 
                        font-normal text-black outline-0"
                        onChange={(e) => handleChange(e)}
                    />
                    <Search 
                        className='absolute top-[13px] left-[5px]' 
                        style={{ height: '17px'}}
                    />
                </div>

                <button 
                    className={`bg-primary text-white px-[5px] rounded-[5px] mb-[20px] ${
                        !query && 'bg-gray-500'
                    }`}
                    disabled={!query}
                    onClick={handleApproveQuery}
                >
                    {t('buttons.search')}
                </button>
            </div>

            {isLoading ? (
                <Loader />
            ) : (
                users && users.length > 0 && (
                    <DataTable 
                        data={users} 
                        columns={onboardingColumns} 
                        renderBodyCell={(user) => <OnboardingItem user={user} />} 
                    />
                )
            )}
        </div>
    )
}