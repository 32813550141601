import {
    EightArticle,
    ElevenArticle,
    FifthArticle,
    FirstArticle,
    FourthArticle,
    NineArticle,
    SecondArticle,
    SevenArticle,
    SixArticle,
    TenArticle,
    ThirdArticle,
    ThirteenArticle,
    TwelveArticle,
} from '../pages/ArticleDetails/components';

export const articles = [
    {
        id: 'Erweiterung_des_Solarpakets_I_Solar_Freifläche_Photovoltaik',
        component: <ThirteenArticle />,
        title: 'articles.article_13.pageTitle',
        description: 'articles.article_13.pageDesc',
    },
    {
        id: 'Photovoltaik_und_Solaranlage_zur_Stärkung',
        component: <TwelveArticle />,
        title: 'articles.article_12.pageTitle',
        description: 'articles.article_12.pageDesc',
    },
    {
        id: 'Enlapas_Leitfaden_zur_industriellen_Dachsanierung',
        component: <ElevenArticle />,
        title: 'articles.article_11.pageTitle',
        description: 'articles.article_11.pageDesc',
    },
    {
        id: 'Dachfläche_vermieten_und_Photovoltaik nutzen',
        component: <TenArticle />,
        title: 'articles.article_ten.pageTitle',
        description: 'articles.article_ten.pageDesc',
    },
    {
        id: 'Power_Purchase_Agreement',
        component: <NineArticle />,
        title: 'articles.article_nine.pageTitle',
        description: 'articles.article_nine.pageDesc',
    },
    {
        id: 'Wie_Solarparks_die_Artenvielfalt_fördern_können',
        component: <EightArticle />,
        title: 'articles.article_eight.pageTitle',
        description: 'articles.article_eight.pageDesc',
    },
    {
        id: 'ENLAPA_gibt_Mitgliedschaft_beim_Bundesverband_Solarwirtschaft_e.V._bekannt',
        component: <SevenArticle />,
        title: 'articles.article_seven.pageTitle',
        description: 'articles.article_seven.pageDesc',
    },
    {
        id: 'Photovoltaik Lebensdauer_Wie_lange_hält_eine_Solaranlage',
        component: <SixArticle />,
        title: 'articles.article_six.pageTitle',
        description: 'articles.article_six.pageDesc',
    },
    {
        id: 'Solarpark_bauen_Alles Wissenswerte_zu_Solarparks',
        component: <FifthArticle />,
        title: 'articles.article_five.pageTitle',
        description: 'articles.article_five.pageDesc',
    },
    {
        id: 'Einspeisevergütung_2024_für_PV-Anlagen',
        component: <FourthArticle />,
        title: 'articles.article_four.pageTitle',
        description: 'articles.article_four.pageDesc',
    },
    {
        id: 'Pachtpreise_Ackerland_–_Einfluss_von_Solarprojekten_auf_die_Preisentwicklung',
        component: <ThirdArticle />,
        title: 'articles.article_three.pageTitle',
        description: 'articles.article_three.pageDesc',
    },
    {
        id: 'Unterschied_Pachtvertrag_Landwirtschaft_vs_Pachtvertrag_Solarnutzung',
        component: <SecondArticle />,
        title: 'articles.article_two.pageTitle',
        description: 'articles.article_two.pageDesc',
    },
    {
        id: 'Photovoltaik_Pacht_pro_Hektar_Ackerland_und_Grundstück_verpachten',
        component: <FirstArticle />,
        title: 'articles.article_one.pageTitle',
        description: 'articles.article_one.pageDesc',
    },
]
