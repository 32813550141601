/** @format */
import { useTranslation } from 'react-i18next';
import { Icons } from '../Icons'
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FieldValues, UseControllerProps } from 'react-hook-form';

/**Types */
type IProps<T extends FieldValues> = {
    value?: string;
    label?: string;
    name?: string;
    register?: any;
    role?: any;
    setValue?: any;
    type?: string;
    checkedValue?: boolean | null | undefined;
} & UseControllerProps<T>

/**
 * The Customized checkbox to display
 * @param props     - The properties for the checkbox
 * @returns The rendered checkbox Component
 */
export function Checkbox<T extends FieldValues>(props: IProps<T>){
    const { 
        label, 
        register, 
        name, 
        value, 
        role, 
        setValue, 
        type,
        checkedValue,
    } = props;

    const { t } = useTranslation();
    const { pathname } = useLocation();

    const width = useMemo(() => pathname === '/offer' ? 'w-8' : 'lg:w-[35px] w-8', [pathname]);
    const labelWidth = useMemo(() => pathname.includes('/offer') ? '' : "lg:max-w-[180px]", [pathname]);

    const [checked, setChecked] = useState('');

    useEffect(() => {
        if (value === 'addArea.no_street') {
            setValue(name, value);
            return;
        }

        if (value) {
            setChecked(t(value));
            setValue(name, t(value));
        }
    }, [value, t])

    useEffect(() => {
        if (role) {
            setChecked(role);
        }
    }, [role])
    
    return (
        <div className="inline-flex items-center">
            <label className="relative flex gap-[15px] cursor-pointer items-center rounded-full py-3 pr-3 md:p-3">
                <input
                    name={name}
                    type={type ? type : 'radio'}
                    className={`${width} before:content[''] peer relative h-8  cursor-pointer appearance-none rounded-md 
                        border border-grey-50 transition-all before:absolute before:left-2/4 before:top-2/4 before:block 
                        before:h-12 before:w-12 before:-translate-x-2/4 before:-translate-y-2/4 before:rounded-full before:bg-grey-50 
                        before:opacity-0 before:transition-opacity checked:bg-grey-50 hover:before:opacity-10`}
                    id="checkbox"
                    value={label}
                    {...register(name)}
                    checked={(value && checked === label) || checkedValue}
                />
                <div className="pointer-events-none absolute md:left-[29px] left-[15px] top-2/4 -translate-x-2/4 -translate-y-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
                    <Icons name="tick" sx="text-primary w-7 h-7"/>
                </div>

                <span className={`max-w-[250px] sm:max-w-none ${labelWidth} ${label === 'addArea.no_street' ? 'text-[13px]' : ''}`}>
                    {label && t(label)}
                </span>
            </label>
        </div>
    )
}
