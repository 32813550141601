import { useCallback, useState, useLayoutEffect } from 'react'
import {
    Grid,
    Container,
    Typography,
    Button,
    Box,
    TextField,
    LinearProgress,
} from '@mui/material'
import { Auth } from 'aws-amplify'

import * as d3 from 'd3'
import { CustomInput } from '../../components/CustomInput'
import { useForm } from 'react-hook-form'
import { ResetPasswordSchema, TResetPasswordSchema } from '../../helpers'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import { useStyles } from '../../styles/jss/useStyles'
import { useProcessTry } from '../../hooks'

export const ForgotPassword = () => {
    const { t } = useTranslation();
    const { handleCreateError } = useProcessTry();
    const [loading, setLoading] = useState(() => false);
    const [forgotPasswordFailure, setForgotPasswordFailure] = useState<
        null | string
    >(() => null);
    const [forgotPasswordSubmitFailure, setForgotPasswordSubmitFailure] =
        useState<null | string>(() => null);

    const [passwordValid, setPasswordValid] = useState(() => true);
    const [passwordConfirmValid, setPasswordConfirmValid] = useState(() => true);
    const [passwordsMatch, setPasswordsMatch] = useState(() => true);

    const [fieldsEmptyButRequired, setFieldsEmptyButRequired] = useState<
        string[]
    >(() => []);

    const classes = useStyles();

    const forgotPasswordAWS = useCallback(async (event: React.FormEvent) => {
        setLoading(true)
        event.preventDefault()
        event.stopPropagation()

        const username = (
            d3
                .select("#forgot-password-form input[name='username']")
                .node() as HTMLInputElement
        ).value

        try {
            Auth.forgotPassword(username)
                .then((res) => {
                    setLoading(false)
                    d3.selectAll('#forgot-password-container .step-1').style(
                        'display',
                        'none'
                    )
                    d3.selectAll('#forgot-password-container .step-2').style(
                        'display',
                        null
                    )
                    d3.selectAll('#forgot-password-container .step-3').style(
                        'display',
                        'none'
                    )
                })
                .catch((err) => {
                    setLoading(false)
                    if ('name' in err && err['name'] === 'AuthError')
                        setForgotPasswordFailure(
                            'Please enter the email address of your Enlapa account.'
                        )
                    else if (
                        'name' in err &&
                        err['name'] === 'UserNotFoundException'
                    )
                        setForgotPasswordFailure(
                            'Unfortunately there is no Enlapa account with this email address.'
                        )
                    else if (
                        'name' in err &&
                        err['name'] === 'LimitExceededException'
                    )
                        setForgotPasswordFailure(
                            'Maximum number of attempts reached. Please try again a little later or contact hilfe@enlapa.de if you need help.'
                        )
                    else
                        setForgotPasswordFailure(
                            "Unfortunately that didn't work. Please try again."
                        )
                })
        } catch (error: any) {
            setForgotPasswordFailure(
                "Unfortunately that didn't work. Please try again."
            )
            setLoading(false)

            const errorData = {
                userInfoSub: '',
                requestBody: JSON.stringify(username),
                errorMsg: error.message as string,
                process: 'Forgot password',
                DateTimeUnix: Math.floor(new Date().getTime() / 1000)
            }

            handleCreateError(errorData);
        }
    }, [])

    const forgotPasswordSubmitAWS = useCallback(
        async (event: React.FormEvent) => {
            setLoading(true)
            event.preventDefault()
            event.stopPropagation()

            const requiredFields = [
                'username',
                'verificationCode',
                'password',
                'passwordConfirm',
            ]

            const userAttr = {
                username: (
                    d3
                        .select("#forgot-password-form input[name='username']")
                        .node() as HTMLInputElement
                ).value,
                verificationCode: (
                    d3
                        .select(
                            "#forgot-password-submit-form input[name='verificationCode']"
                        )
                        .node() as HTMLInputElement
                ).value,
                password: (
                    d3
                        .select(
                            "#forgot-password-submit-form input[name='password']"
                        )
                        .node() as HTMLInputElement
                ).value,
                passwordConfirm: (
                    d3
                        .select(
                            "#forgot-password-submit-form input[name='passwordConfirm']"
                        )
                        .node() as HTMLInputElement
                ).value,
            }

            let entriesValid = true

            // @ts-ignore
            const fieldsEmptyButRequired = requiredFields.filter((field) => !userAttr[field] || userAttr[field] === '')

            if (fieldsEmptyButRequired.length > 0) {
                entriesValid = false
                setFieldsEmptyButRequired(fieldsEmptyButRequired)
            }

            if (
                !fieldsEmptyButRequired.includes('password') &&
                userAttr.password.length < 8
            ) {
                entriesValid = false
                setPasswordValid(false)
            }
            if (
                !fieldsEmptyButRequired.includes('passwordConfirm') &&
                userAttr.passwordConfirm.length < 8
            ) {
                entriesValid = false
                setPasswordConfirmValid(false)
            } else if (userAttr.passwordConfirm !== userAttr.password) {
                entriesValid = false
                setPasswordsMatch(false)
            }

            if (entriesValid) {
                try {
                    Auth.forgotPasswordSubmit(
                        userAttr.username,
                        userAttr.verificationCode,
                        userAttr.password
                    )
                        .then((res) => {
                            setLoading(false)
                            d3.selectAll(
                                '#forgot-password-container .step-1'
                            ).style('display', 'none')
                            d3.selectAll(
                                '#forgot-password-container .step-2'
                            ).style('display', 'none')
                            d3.selectAll(
                                '#forgot-password-container .step-3'
                            ).style('display', null)
                        })
                        .catch((err) => {
                            setForgotPasswordSubmitFailure(
                                'Das hat leider nicht funktioniert (ist der Verifizierungscode korrekt?). Bitte versuchen Sie es erneut.'
                            )
                            setLoading(false)
                        })
                } catch (error) {
                    setForgotPasswordSubmitFailure(
                        'Das hat leider nicht funktioniert (ist der Verifizierungscode korrekt?). Bitte versuchen Sie es erneut.'
                    )
                    setLoading(false)
                }
            } else setLoading(false)
        },
        []
    )

    useLayoutEffect(() => {
        d3.selectAll('#forgot-password-container .step-1').style(
            'display',
            null
        )
        d3.selectAll('#forgot-password-container .step-2').style(
            'display',
            'none'
        )
        d3.selectAll('#forgot-password-container .step-3').style(
            'display',
            'none'
        )
    }, [])

    const {
        formState: { errors },
        register,
    } = useForm<TResetPasswordSchema>({
        resolver: yupResolver(ResetPasswordSchema),
    })

    return (
        <Container maxWidth={false} id={'forgot-password-container'}>
            {loading ? (
                <Box
                    id={'signIn-loader-wrapper'}
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        textAlign: 'center',
                        background: 'rgba(255,255,255,0.8)',
                        pointerEvents: 'all',
                        zIndex: 10,
                    }}
                >
                    <Box style={{ width: '70%', margin: '0 auto' }}>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <LinearProgress />
                    </Box>
                </Box>
            ) : null}
            <Grid
                container
                alignItems="center"
                direction="column"
                style={{ height: '100vh', marginTop: '110px', zIndex: 5 }}
            >
                <Box
                    id={'signIn-form-wrapper'}
                    sx={{ maxWidth: '780px', textAlign: 'center' }}
                >
                    <br />
                    <Typography
                        variant="h6"
                        fontWeight={700}
                        className={'step-1'}
                    >
                        {t('reset_password.have_you_forgotten')}
                    </Typography>
                    <Typography
                        variant="h6"
                        fontWeight={700}
                        className={'step-2'}
                    >
                       {t('reset_password.we_have_sent')}
                    </Typography>
                    <Typography
                        variant="h6"
                        fontWeight={700}
                        className={'step-3'}
                    >
                        {t('reset_password.successful_reset')}
                    </Typography>

                    <br />

                    {forgotPasswordFailure !== null ? (
                        <Typography
                            color={'error'}
                            variant="h6"
                            fontWeight={700}
                            style={{ marginBottom: '20px' }}
                        >
                            {forgotPasswordFailure}
                            <br />
                        </Typography>
                    ) : null}
                    {forgotPasswordSubmitFailure !== null ? (
                        <Typography
                            color={'error'}
                            variant="h6"
                            fontWeight={700}
                        >
                            {forgotPasswordSubmitFailure}
                            <br />
                        </Typography>
                    ) : null}

                    <form
                        noValidate
                        autoComplete="on"
                        onSubmit={forgotPasswordAWS}
                        onKeyPress={(event) =>
                            event.key === 'Enter' && forgotPasswordAWS(event)
                        }
                        id="forgot-password-form"
                    >
                        <div className={'step-1'}>
                            <CustomInput
                                placeholder={t('placeholders.email')}
                                sx={`focus:outline-none`}
                                register={register}
                                name="username"
                            />
                            {errors['email' as keyof TResetPasswordSchema] && (
                                <span className="mt-1 block text-red-400">
                                    Email is required
                                </span>
                            )}
                        </div>

                        <Button
                            className={'step-1'}
                            fullWidth={true}
                            size={'large'}
                            onClick={forgotPasswordAWS}
                            sx={{ marginTop: '40px', color: '#11470E' }}
                        >
                            {t('buttons.reset_password')}
                        </Button>
                    </form>

                    <form
                        noValidate
                        autoComplete="on"
                        onSubmit={forgotPasswordSubmitAWS}
                        onKeyPress={(event) =>
                            event.key === 'Enter' && forgotPasswordAWS(event)
                        }
                        className='max-w-[600px] sm:max-w-[100%]'
                        id="forgot-password-submit-form"
                    >
                        <TextField
                            className={'step-2'}
                            name={'verificationCode'}
                            autoFocus={false}
                            placeholder={t('placeholders.verification_code')}
                            type={'number'}
                            fullWidth={true}
                            style={{
                                margin: '20px auto 0',
                                appearance: 'textfield',
                            }}
                            error={fieldsEmptyButRequired.includes(
                                'verificationCode'
                            )}
                            helperText={
                                `${t('reset_password.please_fillOut')}`
                            }
                            InputProps={{
                                sx: {
                                    '&:focus-within fieldset, &:focus-visible fieldset':
                                        {
                                            border: '2px solid #11470E!important',
                                        },
                                },
                            }}
                        />

                        <div className='md:flex md:gap-[20px] md:justify-center'>
                            <TextField
                                className={`step-2 ${classes.root}`}
                                name={'password'}
                                label={'Password'}
                                placeholder={t('placeholders.password')}
                                error={
                                    fieldsEmptyButRequired.includes('password') ||
                                    !passwordValid
                                }
                                helperText={
                                    fieldsEmptyButRequired.includes('password')
                                        ? 'Bitte füllen Sie dieses Pflichtfeld aus.'
                                        : !passwordValid
                                        ? 'Das Passwort muss mindestens 8 Zeichen lang sein.'
                                        : null
                                }
                                type={'password'}
                                onChange={(event: React.ChangeEvent) => {
                                    if (fieldsEmptyButRequired.includes('password'))
                                        setFieldsEmptyButRequired((fields) =>
                                            fields.filter(
                                                (field) => field !== 'password'
                                            )
                                        )
                                    if (
                                        (event.target as HTMLInputElement).value
                                            .length > 7
                                    )
                                        setPasswordValid(true)
                                }}
                                style={{
                                    width: '340px',
                                    margin: '30px 0 0',
                                }}
                                InputProps={{
                                    sx: {
                                        '&:focus-within fieldset, &:focus-visible fieldset':
                                            {
                                                border: '2px solid #11470E!important',
                                            },
                                    },
                                }}
                            />

                            <TextField
                                className={`step-2 ${classes.root}`}
                                name={'passwordConfirm'}
                                label={'Confirm password'}
                                placeholder={t('placeholders.confirm_password')}
                                error={
                                    fieldsEmptyButRequired.includes(
                                        'passwordConfirm'
                                    ) ||
                                    !passwordConfirmValid ||
                                    !passwordsMatch
                                }
                                helperText={
                                    fieldsEmptyButRequired.includes(
                                        'passwordConfirm'
                                    )
                                        ? 'Bitte füllen Sie dieses Pflichtfeld aus.'
                                        : !passwordConfirmValid
                                        ? 'Das Passwort muss mindestens 8 Zeichen lang sein.'
                                        : !passwordsMatch
                                        ? 'Die Passwörter stimmen nicht überein.'
                                        : null
                                }
                                type={'password'}
                                onChange={(event: React.ChangeEvent) => {
                                    if (
                                        fieldsEmptyButRequired.includes(
                                            'passwordConfirm'
                                        )
                                    )
                                        setFieldsEmptyButRequired((fields) =>
                                            fields.filter(
                                                (field) =>
                                                    field !== 'passwordConfirm'
                                            )
                                        )
                                    if (
                                        (event.target as HTMLInputElement).value
                                            .length > 7
                                    )
                                        setPasswordConfirmValid(true)
                                    if (
                                        (event.target as HTMLInputElement).value ===
                                        (
                                            d3
                                                .select(
                                                    "#forgot-password-submit-form input[name='password']"
                                                )
                                                .node() as HTMLInputElement
                                        ).value
                                    )
                                        setPasswordsMatch(true)
                                }}
                                style={{
                                    width: '340px',
                                    margin: '30px 0 0',
                                }}
                                InputProps={{
                                    sx: {
                                        '&:focus-within fieldset, &:focus-visible fieldset':
                                            {
                                                border: '2px solid #11470E!important',
                                            },
                                    },
                                }}
                            />
                        </div>

                        <Button
                            className={'step-2'}
                            fullWidth={true}
                            size={'large'}
                            onClick={forgotPasswordSubmitAWS}
                            sx={{ marginTop: '40px', color: '#11470E' }}
                        >
                            Set new password
                        </Button>
                    </form>

                    <Button
                        className={'step-3'}
                        fullWidth={true}
                        size={'large'}
                        onClick={() => (window.location.href = '/login')}
                        sx={{ marginTop: '40px', color: '#11470E' }}
                    >
                        {t('buttons.to_login')}
                    </Button>

                    <br />
                    <br />
                    <br />
                </Box>
            </Grid>

            <div className="ml-[calc(50%-50vw)] relative h-full w-screen bg-[lightgray]">
                <div
                    className="absolute z-5 h-[200px] w-full"
                    style={{
                        background:
                            'linear-gradient(180deg, #FFFF 0%, rgba(243, 243, 243, 0.76) 40.12%, rgba(243, 243, 243, 0.00) 89.67%)',
                    }}
                ></div>
                <div className="z-[1] h-[451px] w-full bg-solarCell bg-cover bg-center  bg-no-repeat md:h-[857px]"></div>
            </div>
        </Container>
    )
}
