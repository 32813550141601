import { AreaTypesInput, UpdateUserInfoMutation, UserInfo } from './../../../API';
import { GraphQLQuery, graphqlOperation } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { FormEvent, useEffect, useState } from "react";
import { updateUserInfo } from '../../../graphql/mutations';
import { useAuth } from '../../../hooks';

export const useSearchCriteria = ({ user }: { user: UserInfo }) => {
    const { loggedIn } = useAuth();
    const [areaTypes, setAreaTypes] = useState<string[]>([]);
    const [sizeRanges, setSizeRanges] = useState<
        { min: string; max: string }[]
    >([]);
    const [countries, setCountries] = useState<string[]>([]);
    const [germanyStates, setGermanyStates] = useState<string[]>([]);
    const [offerPreferences, setOfferPreferences] = useState<string[]>([]);
    const [compensationTypes, setCompensationTypes] = useState<string[]>([]);
    const [typeError, setTypeError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSaved, setIsSaved] = useState(false);

    const handleFormSubmit = async (event: FormEvent) => {
        event.preventDefault()
        if (sizeRanges.length > areaTypes.filter((el) => el).length) {
            setTypeError('profile.select_type')
            return
        };

        setIsLoading(true);

        const types = areaTypes
            .filter((el) => el)
            .map((type, index) => ({
                AreaTypeString: type,
                sizeRange: {
                    min: sizeRanges[index]?.min || '',
                    max: sizeRanges[index]?.max || '',
                },
            }));

        try {
            const editingUser = {
                id: loggedIn.id,
                sub: loggedIn.sub,
                SearchCriteriaTypes: types,
                SearchCriteriaCountries: countries,
                SearchCriteriaFederalStates: germanyStates,
                SearchCriteriaOfferLookingFor: offerPreferences
            }
            
            const {data} =  await API.graphql<GraphQLQuery<UpdateUserInfoMutation>>(
                graphqlOperation(updateUserInfo, { input: editingUser })
            );

            setIsLoading(false);
            setIsSaved(true);
        } catch (error) {
            console.log("Error:", error)
            setIsLoading(false);
        }
    }

    const handleAreaTypeChange = (index: number, value: string) => {
        const newAreaTypes = [...areaTypes]
        newAreaTypes[index] = value
        setAreaTypes(newAreaTypes)
    }

    const handleMinSizeChange = (index: number, value: string) => {
        const newSizeRanges = [...sizeRanges]
        newSizeRanges[index] = { ...newSizeRanges[index], min: value }
        setSizeRanges(newSizeRanges)
    }

    const handleMaxSizeChange = (index: number, value: string) => {
        const newSizeRanges = [...sizeRanges]
        newSizeRanges[index] = { ...newSizeRanges[index], max: value }
        setSizeRanges(newSizeRanges)
    }

    const addAreaType = () => {
        setTypeError('')

        if (areaTypes.length > 0 && areaTypes[areaTypes.length - 1] === '') {
            setTypeError('profile.is_empty')
            return
        }

        setAreaTypes([...areaTypes, ''])
        setSizeRanges([...sizeRanges, { min: '', max: '' }])
    }

    const removeAreaType = (index: number) => {
        setTypeError('')

        const newAreaTypes = [...areaTypes]
        const newSizeRanges = [...sizeRanges]

        newAreaTypes.splice(index, 1)
        newSizeRanges.splice(index, 1)

        setAreaTypes(newAreaTypes)
        setSizeRanges(newSizeRanges)
    }

    const handleCountriesChange = (event: any) => {
        if (!event.target.value.includes('addArea.countries.Germany')) {
            setGermanyStates([])
        }
        setCountries(event.target.value)
    }

    const handleStatesChange = (event: any) => {
        setGermanyStates(event.target.value)
    }

    const handleOfferChange = (event: any) => {
        setOfferPreferences(event.target.value)
    }

    useEffect(() => {
        const filterNonNullValues = (arr: (string | null)[] | undefined): string[] => {
            return arr ? arr.filter(el => el !== null) as string[] : [];
        };
    
        if (user.SearchCriteriaCountries) {
            setCountries(filterNonNullValues(user.SearchCriteriaCountries));
        }
        
        if (user.SearchCriteriaFederalStates) {
            setGermanyStates(filterNonNullValues(user.SearchCriteriaFederalStates));
        }  
    
        if (user.SearchCriteriaOfferLookingFor) {
            setOfferPreferences(filterNonNullValues(user.SearchCriteriaOfferLookingFor));
        }  
    
        if (user.SearchCriteriaTypes) {
            const filteredTypes = user.SearchCriteriaTypes.map(el => el?.AreaTypeString) as string[];
            const filteredSizes = user.SearchCriteriaTypes.map(el => el?.sizeRange) as { min: string; max: string }[];
    
            setAreaTypes(filteredTypes);
            setSizeRanges(filteredSizes);
        }  
    }, [user]);

    useEffect(() => {
        let timeoutId1: NodeJS.Timeout;
    
        if (isSaved) {    
            timeoutId1 = setTimeout(() => {
                setIsSaved(false);
            }, 5000);

            return;
        }
    
        return () => {
            clearTimeout(timeoutId1);
        };
    }, [isSaved]);
    

    return {
        areaTypes,
        sizeRanges,
        offerPreferences,
        germanyStates,
        countries,
        compensationTypes,
        typeError,
        handleAreaTypeChange,
        handleCountriesChange,
        handleFormSubmit,
        handleMaxSizeChange,
        handleMinSizeChange,
        handleOfferChange,
        handleStatesChange,
        addAreaType,
        removeAreaType,
        isLoading,
        isSaved
    }
}