/** @format */

import { Icons } from '../../../components';
import { Button } from '../../../components/Button';
import { Heading } from '../../../components/Title';

/** Types */
import { useTranslation } from 'react-i18next';
import { IconType } from '../../../components/Icons';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { Features, SelectedPlan } from '../../../types';
import { PlanFeatures } from './PlanFeatures';
import { PriceBlock } from './PriceBlock';

interface CardProps {
    icon: IconType,
    name: string,
    yearly: number,
    price: string,
    oldPrice?: string,
    features: Features[],
    buttonTitle?: string,
    onSelect: Dispatch<SetStateAction<SelectedPlan | null>>
    selected: SelectedPlan | null;
}

export const Card = ({
    icon,
    name,
    yearly,
    price,
    oldPrice,
    features,
    buttonTitle,
    onSelect,
    selected,
}: CardProps): JSX.Element => {
    const { t } = useTranslation();

    const handleSelect = useCallback((data: SelectedPlan) => {
        if (selected?.name === data.name && selected.price === data.price) {
            onSelect(null);

            return;
        }

        onSelect(data);
        const form = document.getElementById('form');

        form?.scrollIntoView({
            behavior: "smooth", block: "start"
        });
    }, [selected])

    return (
        <div className="border-primary-80 relative w-[345px] rounded-[20px] border text-center pb-[20px]">
            <div 
                className="border-primary-80 absolute left-1/3 top-[-50px] z-10 flex h-[100px] w-[100px] 
                    translate-x-1 items-center justify-center rounded-full border bg-white"
            >
                <Icons name={icon} />
            </div>

            {name === 'pricing.expert' && (
                <>
                    <div 
                        className='absolute bg-primary px-[20px] py-[5px] rounded-[5px] right-[-15px] 
                            top-[5px] rotate-[30deg] uppercase font-medium'
                    >
                        {t('pricing.popular')}
                    </div>
                </>
            )}

            <div className="mt-[80px]">
                <Heading
                    title={t(name)}
                    size="text-[24px]"
                    color="text-secondary"
                />

                <PriceBlock 
                    name={name} 
                    oldPrice={oldPrice} 
                    price={price} 
                    yearly={yearly} 
                />

                <div className="m-auto mt-[35px] w-10/12">
                    <hr />
                </div>

                <div className="m-auto mt-[35px] w-10/12">
                    <PlanFeatures features={features} />

                    <Button
                        width="w-full"
                        color="Primary"
                        textColor="text-white"
                        sx="mt-[30px] text-lg"
                        onClick={() => handleSelect({ name, price: `${price}`, icon})}
                    >
                        {buttonTitle 
                            ? t(buttonTitle) 
                            : (selected?.name === name 
                                && selected.price === `${price}` 
                                    ? t('pricing.selected') 
                                    : t('pricing.select'))
                        }
                    </Button>
                </div>
            </div>
        </div>
    )
}
