import { GraphQLQuery } from '@aws-amplify/api';
import { useState } from 'react';
import {
    CreateAccountingLogMutation,
    GetUserInfoByEmailAddressQuery,
    GetUserInfoBySubQuery,
    Organization,
    UpdateOrganizationMutation,
    UpdateUserInfoMutation,
    UserInfo,
} from '../../../API';
import { getUserInfoByEmailAddress, getUserInfoBySub } from '../../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import { createAccountingLog, updateOrganization, updateUserInfo } from '../../../graphql/mutations';
import { validateEmail } from '../../../helpers';
import { useAuth } from '../../../hooks';
import { useTranslation } from 'react-i18next';

interface Options {
    organization?: Organization | null
    userCompany?: Organization | null
    user?: UserInfo | null
    members?: (UserInfo | null)[]
    isTransferOwnerRequest: boolean
    onAccept: (organization: Organization) => void
    onDecline: (organization: Organization) => void
    onAcceptOwner: (organization: Organization) => void
    onDeclineOwner: (organization: Organization) => void
}

export const useInvitation = ({
    user,
    organization,
    userCompany,
    members,
    onAccept,
    onDecline,
    onAcceptOwner,
    onDeclineOwner,
    isTransferOwnerRequest,
}: Options) => {
    const [owner, setOwner] = useState<UserInfo | null>()
    const [openErrorModal, setIsOpenErrorModal] = useState('')
    const [error, setError] = useState(false)
    const [email, setEmail] = useState('')
    const [newOwnerError, setNewOwnerError] = useState('')

    const { loggedIn } = useAuth();
    const { t } = useTranslation();

    const getOrganizationOwner = async () => {
        try {
            const { data } = await API.graphql<
                GraphQLQuery<GetUserInfoBySubQuery>
            >(
                graphqlOperation(getUserInfoBySub, {
                    sub: organization?.OwnerOfOrganization,
                })
            )

            if (data?.getUserInfoBySub?.items[0]) {
                setOwner(data?.getUserInfoBySub?.items[0])
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleAccept = async () => {
        if (!organization) return

        try {
            if (isTransferOwnerRequest) {
                const updatedOrganization = {
                    id: organization.id,
                    OwnerOfOrganization: user?.sub,
                    TransferOwnershipToEMail: '',
                    disabledOrganization: false,
                }

                await API.graphql<GraphQLQuery<UpdateOrganizationMutation>>(
                    graphqlOperation(updateOrganization, {
                        input: updatedOrganization,
                    })
                )

                const accountLogDescription = {
                    flaecheId: '',
                    userInfoSub: user?.sub,
                    DateTimeUnix: Math.floor(new Date().getTime() / 1000),
                    Description: user?.email + 
                        ' ' + 'accepted to become the owner of' + ' ' + organization.id + '' + 
                        'instead of' + organization.OwnerOfOrganization,
                    TransactionTypeChangeOfOrganizationOwnership: true,
                    OrganizationID: organization.id
                }

                await API.graphql<GraphQLQuery<CreateAccountingLogMutation>>(
                    graphqlOperation(createAccountingLog, {
                        input: accountLogDescription,
                    })
                );

                const editingUser = {
                    id: user?.id,
                    sub: user?.sub,
                    MemberofOrganizationID: null,
                }
    
                await API.graphql<GraphQLQuery<UpdateUserInfoMutation>>(
                    graphqlOperation(updateUserInfo, { input: editingUser })
                )

                if (userCompany) {
                    const editingOrg = {
                        id: userCompany.id,
                        disabledOrganization: true,
                    }

                    await API.graphql<GraphQLQuery<UpdateOrganizationMutation>>(
                        graphqlOperation(updateOrganization, {
                            input: editingOrg,
                        })
                    )
                }

                onAcceptOwner(organization);

                return;
            }

            const invitedUsers =
                organization?.InviteCompanyMembersEMail?.filter(
                    (el) => el !== user?.email
                )

            const editingUser = {
                id: user?.id,
                sub: user?.sub,
                MemberofOrganizationID: organization?.id,
            }

            const editingOrg = {
                id: organization?.id,
                InviteCompanyMembersEMail: invitedUsers,
            }

            await API.graphql<GraphQLQuery<UpdateUserInfoMutation>>(
                graphqlOperation(updateUserInfo, { input: editingUser })
            )

            await API.graphql<GraphQLQuery<UpdateOrganizationMutation>>(
                graphqlOperation(updateOrganization, { input: editingOrg })
            )

            if (userCompany) {
                const editingOrg = {
                    id: userCompany.id,
                    disabledOrganization: true,
                }

                await API.graphql<GraphQLQuery<UpdateOrganizationMutation>>(
                    graphqlOperation(updateOrganization, { input: editingOrg })
                )
            }

            onAccept(organization)
        } catch (error) {
            console.log('Error:', error)
        }
    }

    const handleAcceptInvitation = async () => {
        if (
            userCompany &&
            !userCompany.disabledOrganization &&
            members?.length === 0
        ) {
            setIsOpenErrorModal('organization.owner')

            return
        }

        if (userCompany && members?.length) {
            setError(true)

            return
        }

        if (user?.MemberofOrganizationID) {
            setIsOpenErrorModal('organization.already')

            return
        }

        try {
            await handleAccept()
        } catch (error) {
            console.log('Error accepting the invitation:', error)
        }
    }

    const sendEmail = async (user?: UserInfo | null) => {
        try {
            const endpoint = 'https://edc1touev2.execute-api.eu-central-1.amazonaws.com?method=SendgridMessage';

            const parameters = {
                subject: t('email.invitation.subject'),
                congrats: t('email.invitation.congrats'),
                invited: t('email.invitation.invited_1', { 
                    firstName: loggedIn.firstName, 
                    lastName: loggedIn.lastName, 
                    email: loggedIn.email, 
                    organization: organization?.CompanyName 
                }),
                button: t('email.invitation.button'),
                thanks: t('email.invitation.thanks'),
                enlapa: t('email.enlapa')
            };

            const emailData = {
                templateId: "d-5d11d48905414cb1a4fc32022e684c60",
                toEmail: email,
                toName: user 
                    ? user.firstName 
                    : '',
                dynamicTemplateData: parameters
            }

            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(emailData)
            };

            await fetch(endpoint, options);
        } catch (error) {
            console.log("Error sending email:", error)
        }
    }

    const onApproveNewOwner = async () => {
        const isValid = validateEmail(email)

        if (!isValid) {
            setNewOwnerError('organization.correct')

            return
        }

        try {
            const editingOrg = {
                id: userCompany?.id,
                TransferOwnershipToEMail: email,
                disabledOrganization: true,
            }

            await API.graphql<GraphQLQuery<UpdateOrganizationMutation>>(
                graphqlOperation(updateOrganization, { input: editingOrg })
            )

            const { data } = await API.graphql<GraphQLQuery<GetUserInfoByEmailAddressQuery>>(
                graphqlOperation(getUserInfoByEmailAddress, { email })
            );

            await sendEmail(data?.getUserInfoByEmailAddress?.items[0]);

            await handleAccept()
        } catch (error) {
            console.log(error)
        }
    }

    const handleDeclineInvitation = async () => {
        if (!organization) return

        try {
            if (isTransferOwnerRequest) {
                const editingOrg = {
                    id: organization?.id,
                    TransferOwnershipToEMail: ''
                }
    
                await API.graphql<GraphQLQuery<UpdateOrganizationMutation>>(
                    graphqlOperation(updateOrganization, { input: editingOrg })
                )

                onDeclineOwner(organization);

                return;
            }

            const invitedUsers =
                organization?.InviteCompanyMembersEMail?.filter(
                    (el) => el !== user?.email
                )

            const editingOrg = {
                id: organization?.id,
                InviteCompanyMembersEMail: invitedUsers,
            }

            await API.graphql<GraphQLQuery<UpdateOrganizationMutation>>(
                graphqlOperation(updateOrganization, { input: editingOrg })
            )

            onDecline(organization);
        } catch (error) {
            console.log(error)
        }
    }

    return {
        owner,
        email,
        setEmail,
        newOwnerError,
        openErrorModal,
        onApproveNewOwner,
        setIsOpenErrorModal,
        error,
        setError,
        getOrganizationOwner,
        handleAccept,
        handleAcceptInvitation,
        handleDeclineInvitation,
    }
}
