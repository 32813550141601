/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createFlaeche = /* GraphQL */ `mutation CreateFlaeche(
  $input: CreateFlaecheInput!
  $condition: ModelFlaecheConditionInput
) {
  createFlaeche(input: $input, condition: $condition) {
    ownerSub
    disabled
    disabledDate
    areaType
    areaSize
    areaName
    kml_filename
    map_screenshot_filename
    areSizeMapbox
    MapboxZoomfactor
    MapboxGPSCoordsCircumference
    GeoCircumferenceMapbox
    locale
    updatedTimeUnix
    address_state
    address_street
    address_postcode
    address_other
    address_city
    address_country
    address_noStreet
    compensationType
    additional_information
    isRoofRehabilitationDesired
    ElectriySelfConsumptionPerYear
    additionalInformationLookingFor
    additionalInformationDescription
    identifyAsOwner
    identifyAsLandlord
    identifyAsDeveloper
    identifyAsInvestor
    identifyAsOther
    identifyAsOtherDescription
    progressZustimmung
    progressPachtvertragAbgeschlossen
    progressNetzanschlussbegehren
    progressEinspeisezusage
    progressAufstellungsbeschluss
    progressBebauungsplan
    progressFinanzierung
    progressAusschreibung
    progressBauvertrag
    progressAnlageGebaut
    progressAnlageAngeschlossen
    criteriaMetMargins
    criteriaMetHandicapped
    criteriaMetConversionArea
    criteriaMetParking
    criteriaMetIndustryArea
    criteriaMetPlant
    criteriaMetOther
    GeoCoordinatesLatitude
    GeoCoordinatesLongitude
    GeoCoordinatesElevation
    PvGISDateOfCalc
    PvGISInputFixedSlope
    PvGISInputFixedAzimuth
    PvGISInputSystemLoss
    PvGISCalcOutputTotalE_y
    PvGISCalcOutputperMonthE_y
    ManagementWrongMapboxArea
    ManagementWrongMapboxAreaDate
    ManagementAreaSmallButExtensible
    ManagementAreaSmallButExtensibleDate
    InvestorsWithApprovedAccess
    OrganizationsWithApprovedAccess
    InvestorsWaitlistForAccess
    MaximumNumberOfContactsAllowed
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFlaecheMutationVariables,
  APITypes.CreateFlaecheMutation
>;
export const updateFlaeche = /* GraphQL */ `mutation UpdateFlaeche(
  $input: UpdateFlaecheInput!
  $condition: ModelFlaecheConditionInput
) {
  updateFlaeche(input: $input, condition: $condition) {
    ownerSub
    disabled
    disabledDate
    areaType
    areaSize
    areaName
    kml_filename
    map_screenshot_filename
    areSizeMapbox
    MapboxZoomfactor
    MapboxGPSCoordsCircumference
    GeoCircumferenceMapbox
    locale
    updatedTimeUnix
    address_state
    address_street
    address_postcode
    address_other
    address_city
    address_country
    address_noStreet
    compensationType
    additional_information
    isRoofRehabilitationDesired
    ElectriySelfConsumptionPerYear
    additionalInformationLookingFor
    additionalInformationDescription
    identifyAsOwner
    identifyAsLandlord
    identifyAsDeveloper
    identifyAsInvestor
    identifyAsOther
    identifyAsOtherDescription
    progressZustimmung
    progressPachtvertragAbgeschlossen
    progressNetzanschlussbegehren
    progressEinspeisezusage
    progressAufstellungsbeschluss
    progressBebauungsplan
    progressFinanzierung
    progressAusschreibung
    progressBauvertrag
    progressAnlageGebaut
    progressAnlageAngeschlossen
    criteriaMetMargins
    criteriaMetHandicapped
    criteriaMetConversionArea
    criteriaMetParking
    criteriaMetIndustryArea
    criteriaMetPlant
    criteriaMetOther
    GeoCoordinatesLatitude
    GeoCoordinatesLongitude
    GeoCoordinatesElevation
    PvGISDateOfCalc
    PvGISInputFixedSlope
    PvGISInputFixedAzimuth
    PvGISInputSystemLoss
    PvGISCalcOutputTotalE_y
    PvGISCalcOutputperMonthE_y
    ManagementWrongMapboxArea
    ManagementWrongMapboxAreaDate
    ManagementAreaSmallButExtensible
    ManagementAreaSmallButExtensibleDate
    InvestorsWithApprovedAccess
    OrganizationsWithApprovedAccess
    InvestorsWaitlistForAccess
    MaximumNumberOfContactsAllowed
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFlaecheMutationVariables,
  APITypes.UpdateFlaecheMutation
>;
export const deleteFlaeche = /* GraphQL */ `mutation DeleteFlaeche(
  $input: DeleteFlaecheInput!
  $condition: ModelFlaecheConditionInput
) {
  deleteFlaeche(input: $input, condition: $condition) {
    ownerSub
    disabled
    disabledDate
    areaType
    areaSize
    areaName
    kml_filename
    map_screenshot_filename
    areSizeMapbox
    MapboxZoomfactor
    MapboxGPSCoordsCircumference
    GeoCircumferenceMapbox
    locale
    updatedTimeUnix
    address_state
    address_street
    address_postcode
    address_other
    address_city
    address_country
    address_noStreet
    compensationType
    additional_information
    isRoofRehabilitationDesired
    ElectriySelfConsumptionPerYear
    additionalInformationLookingFor
    additionalInformationDescription
    identifyAsOwner
    identifyAsLandlord
    identifyAsDeveloper
    identifyAsInvestor
    identifyAsOther
    identifyAsOtherDescription
    progressZustimmung
    progressPachtvertragAbgeschlossen
    progressNetzanschlussbegehren
    progressEinspeisezusage
    progressAufstellungsbeschluss
    progressBebauungsplan
    progressFinanzierung
    progressAusschreibung
    progressBauvertrag
    progressAnlageGebaut
    progressAnlageAngeschlossen
    criteriaMetMargins
    criteriaMetHandicapped
    criteriaMetConversionArea
    criteriaMetParking
    criteriaMetIndustryArea
    criteriaMetPlant
    criteriaMetOther
    GeoCoordinatesLatitude
    GeoCoordinatesLongitude
    GeoCoordinatesElevation
    PvGISDateOfCalc
    PvGISInputFixedSlope
    PvGISInputFixedAzimuth
    PvGISInputSystemLoss
    PvGISCalcOutputTotalE_y
    PvGISCalcOutputperMonthE_y
    ManagementWrongMapboxArea
    ManagementWrongMapboxAreaDate
    ManagementAreaSmallButExtensible
    ManagementAreaSmallButExtensibleDate
    InvestorsWithApprovedAccess
    OrganizationsWithApprovedAccess
    InvestorsWaitlistForAccess
    MaximumNumberOfContactsAllowed
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFlaecheMutationVariables,
  APITypes.DeleteFlaecheMutation
>;
export const createFlaecheRating = /* GraphQL */ `mutation CreateFlaecheRating(
  $input: CreateFlaecheRatingInput!
  $condition: ModelFlaecheRatingConditionInput
) {
  createFlaecheRating(input: $input, condition: $condition) {
    flaecheId
    userInfoSub
    rating
    ratingSizeLocation
    ratingGrid
    ratingProbability
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFlaecheRatingMutationVariables,
  APITypes.CreateFlaecheRatingMutation
>;
export const updateFlaecheRating = /* GraphQL */ `mutation UpdateFlaecheRating(
  $input: UpdateFlaecheRatingInput!
  $condition: ModelFlaecheRatingConditionInput
) {
  updateFlaecheRating(input: $input, condition: $condition) {
    flaecheId
    userInfoSub
    rating
    ratingSizeLocation
    ratingGrid
    ratingProbability
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFlaecheRatingMutationVariables,
  APITypes.UpdateFlaecheRatingMutation
>;
export const deleteFlaecheRating = /* GraphQL */ `mutation DeleteFlaecheRating(
  $input: DeleteFlaecheRatingInput!
  $condition: ModelFlaecheRatingConditionInput
) {
  deleteFlaecheRating(input: $input, condition: $condition) {
    flaecheId
    userInfoSub
    rating
    ratingSizeLocation
    ratingGrid
    ratingProbability
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFlaecheRatingMutationVariables,
  APITypes.DeleteFlaecheRatingMutation
>;
export const createFlaecheInterested = /* GraphQL */ `mutation CreateFlaecheInterested(
  $input: CreateFlaecheInterestedInput!
  $condition: ModelFlaecheInterestedConditionInput
) {
  createFlaecheInterested(input: $input, condition: $condition) {
    flaecheId
    userInfoSub
    interested
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFlaecheInterestedMutationVariables,
  APITypes.CreateFlaecheInterestedMutation
>;
export const updateFlaecheInterested = /* GraphQL */ `mutation UpdateFlaecheInterested(
  $input: UpdateFlaecheInterestedInput!
  $condition: ModelFlaecheInterestedConditionInput
) {
  updateFlaecheInterested(input: $input, condition: $condition) {
    flaecheId
    userInfoSub
    interested
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFlaecheInterestedMutationVariables,
  APITypes.UpdateFlaecheInterestedMutation
>;
export const deleteFlaecheInterested = /* GraphQL */ `mutation DeleteFlaecheInterested(
  $input: DeleteFlaecheInterestedInput!
  $condition: ModelFlaecheInterestedConditionInput
) {
  deleteFlaecheInterested(input: $input, condition: $condition) {
    flaecheId
    userInfoSub
    interested
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFlaecheInterestedMutationVariables,
  APITypes.DeleteFlaecheInterestedMutation
>;
export const createUserInfo = /* GraphQL */ `mutation CreateUserInfo(
  $input: CreateUserInfoInput!
  $condition: ModelUserInfoConditionInput
) {
  createUserInfo(input: $input, condition: $condition) {
    email
    sub
    firstName
    lastName
    tmpTestField
    profileImageFilename
    locale
    phone
    streetAndHouseNumber
    postalCode
    city
    country
    MemberofOrganizationID
    companyName
    companyWebsite
    interestedIn
    personalStatement
    qualifiedInvestor
    adminFull
    adminCustomerService
    favoriteFlaeches
    nutzungsbedingungenAnwenderAccepted
    nutzungsbedingungenAnwenderTimestamp
    nutzungsbedingungenInvestorAccepted
    nutzungsbedingungenInvestorTimestamp
    NewAreaAlertInstant
    NewAreaAlertDaily
    NewAreaAlertWeekly
    NewAreaAlertLastSent
    EnableExclusivityForAreas
    lastloginDateTime
    SearchCriteriaTypes {
      AreaTypeString
      sizeRange {
        min
        max
        __typename
      }
      __typename
    }
    SearchCriteriaCountries
    SearchCriteriaFederalStates
    SearchCriteriaOfferLookingFor
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserInfoMutationVariables,
  APITypes.CreateUserInfoMutation
>;
export const updateUserInfo = /* GraphQL */ `mutation UpdateUserInfo(
  $input: UpdateUserInfoInput!
  $condition: ModelUserInfoConditionInput
) {
  updateUserInfo(input: $input, condition: $condition) {
    email
    sub
    firstName
    lastName
    tmpTestField
    profileImageFilename
    locale
    phone
    streetAndHouseNumber
    postalCode
    city
    country
    MemberofOrganizationID
    companyName
    companyWebsite
    interestedIn
    personalStatement
    qualifiedInvestor
    adminFull
    adminCustomerService
    favoriteFlaeches
    nutzungsbedingungenAnwenderAccepted
    nutzungsbedingungenAnwenderTimestamp
    nutzungsbedingungenInvestorAccepted
    nutzungsbedingungenInvestorTimestamp
    NewAreaAlertInstant
    NewAreaAlertDaily
    NewAreaAlertWeekly
    NewAreaAlertLastSent
    EnableExclusivityForAreas
    lastloginDateTime
    SearchCriteriaTypes {
      AreaTypeString
      sizeRange {
        min
        max
        __typename
      }
      __typename
    }
    SearchCriteriaCountries
    SearchCriteriaFederalStates
    SearchCriteriaOfferLookingFor
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserInfoMutationVariables,
  APITypes.UpdateUserInfoMutation
>;
export const deleteUserInfo = /* GraphQL */ `mutation DeleteUserInfo(
  $input: DeleteUserInfoInput!
  $condition: ModelUserInfoConditionInput
) {
  deleteUserInfo(input: $input, condition: $condition) {
    email
    sub
    firstName
    lastName
    tmpTestField
    profileImageFilename
    locale
    phone
    streetAndHouseNumber
    postalCode
    city
    country
    MemberofOrganizationID
    companyName
    companyWebsite
    interestedIn
    personalStatement
    qualifiedInvestor
    adminFull
    adminCustomerService
    favoriteFlaeches
    nutzungsbedingungenAnwenderAccepted
    nutzungsbedingungenAnwenderTimestamp
    nutzungsbedingungenInvestorAccepted
    nutzungsbedingungenInvestorTimestamp
    NewAreaAlertInstant
    NewAreaAlertDaily
    NewAreaAlertWeekly
    NewAreaAlertLastSent
    EnableExclusivityForAreas
    lastloginDateTime
    SearchCriteriaTypes {
      AreaTypeString
      sizeRange {
        min
        max
        __typename
      }
      __typename
    }
    SearchCriteriaCountries
    SearchCriteriaFederalStates
    SearchCriteriaOfferLookingFor
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserInfoMutationVariables,
  APITypes.DeleteUserInfoMutation
>;
export const createPricingTest = /* GraphQL */ `mutation CreatePricingTest(
  $input: CreatePricingTestInput!
  $condition: ModelPricingTestConditionInput
) {
  createPricingTest(input: $input, condition: $condition) {
    Package
    Date
    DateUnix
    email
    Name
    Company
    installedkWPpa
    Phone
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePricingTestMutationVariables,
  APITypes.CreatePricingTestMutation
>;
export const updatePricingTest = /* GraphQL */ `mutation UpdatePricingTest(
  $input: UpdatePricingTestInput!
  $condition: ModelPricingTestConditionInput
) {
  updatePricingTest(input: $input, condition: $condition) {
    Package
    Date
    DateUnix
    email
    Name
    Company
    installedkWPpa
    Phone
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePricingTestMutationVariables,
  APITypes.UpdatePricingTestMutation
>;
export const deletePricingTest = /* GraphQL */ `mutation DeletePricingTest(
  $input: DeletePricingTestInput!
  $condition: ModelPricingTestConditionInput
) {
  deletePricingTest(input: $input, condition: $condition) {
    Package
    Date
    DateUnix
    email
    Name
    Company
    installedkWPpa
    Phone
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePricingTestMutationVariables,
  APITypes.DeletePricingTestMutation
>;
export const createProcessTry = /* GraphQL */ `mutation CreateProcessTry(
  $input: CreateProcessTryInput!
  $condition: ModelProcessTryConditionInput
) {
  createProcessTry(input: $input, condition: $condition) {
    userInfoSub
    requestBody
    response
    errorMsg
    process
    DateTimeUnix
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProcessTryMutationVariables,
  APITypes.CreateProcessTryMutation
>;
export const updateProcessTry = /* GraphQL */ `mutation UpdateProcessTry(
  $input: UpdateProcessTryInput!
  $condition: ModelProcessTryConditionInput
) {
  updateProcessTry(input: $input, condition: $condition) {
    userInfoSub
    requestBody
    response
    errorMsg
    process
    DateTimeUnix
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProcessTryMutationVariables,
  APITypes.UpdateProcessTryMutation
>;
export const deleteProcessTry = /* GraphQL */ `mutation DeleteProcessTry(
  $input: DeleteProcessTryInput!
  $condition: ModelProcessTryConditionInput
) {
  deleteProcessTry(input: $input, condition: $condition) {
    userInfoSub
    requestBody
    response
    errorMsg
    process
    DateTimeUnix
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProcessTryMutationVariables,
  APITypes.DeleteProcessTryMutation
>;
export const createAccountingLog = /* GraphQL */ `mutation CreateAccountingLog(
  $input: CreateAccountingLogInput!
  $condition: ModelAccountingLogConditionInput
) {
  createAccountingLog(input: $input, condition: $condition) {
    flaecheId
    userInfoSub
    OrganizationID
    DateTimeUnix
    Description
    ActionPerformedbyUserSub
    TransactionTypeAreaUnlockRegular
    TransactionTypeAreaUnlockEarlyAccess
    TransactionTypeAreaAlertNotificationMail
    TransactionTypeChangeOfOrganizationOwnership
    TransactionTypeAccessToContactRemoved
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAccountingLogMutationVariables,
  APITypes.CreateAccountingLogMutation
>;
export const updateAccountingLog = /* GraphQL */ `mutation UpdateAccountingLog(
  $input: UpdateAccountingLogInput!
  $condition: ModelAccountingLogConditionInput
) {
  updateAccountingLog(input: $input, condition: $condition) {
    flaecheId
    userInfoSub
    OrganizationID
    DateTimeUnix
    Description
    ActionPerformedbyUserSub
    TransactionTypeAreaUnlockRegular
    TransactionTypeAreaUnlockEarlyAccess
    TransactionTypeAreaAlertNotificationMail
    TransactionTypeChangeOfOrganizationOwnership
    TransactionTypeAccessToContactRemoved
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAccountingLogMutationVariables,
  APITypes.UpdateAccountingLogMutation
>;
export const deleteAccountingLog = /* GraphQL */ `mutation DeleteAccountingLog(
  $input: DeleteAccountingLogInput!
  $condition: ModelAccountingLogConditionInput
) {
  deleteAccountingLog(input: $input, condition: $condition) {
    flaecheId
    userInfoSub
    OrganizationID
    DateTimeUnix
    Description
    ActionPerformedbyUserSub
    TransactionTypeAreaUnlockRegular
    TransactionTypeAreaUnlockEarlyAccess
    TransactionTypeAreaAlertNotificationMail
    TransactionTypeChangeOfOrganizationOwnership
    TransactionTypeAccessToContactRemoved
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAccountingLogMutationVariables,
  APITypes.DeleteAccountingLogMutation
>;
export const createOrganization = /* GraphQL */ `mutation CreateOrganization(
  $input: CreateOrganizationInput!
  $condition: ModelOrganizationConditionInput
) {
  createOrganization(input: $input, condition: $condition) {
    OwnerOfOrganization
    CompanyName
    websiteDomain
    addressStreetAddress
    addressPostalCode
    addressCity
    addressCountry
    vatID
    disabledOrganization
    InviteCompanyMembersEMail
    TransferOwnershipToEMail
    AccessToAreaID {
      flaecheId
      OrganizationId
      userInfoSub
      DateTime
      __typename
    }
    AllowedAreaTypes
    ExclusiveAccessFirst24h
    SendBroadcastForUnlockToAllOrganizationMembers
    CostPerSeatPerMonth
    CostPerLeadLarger2000
    CostPerLeadLarger1000
    CostPerLeadSmaller1000
    CostSuccessFeePerkWp
    CostPercentageSplitSuccessFee
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrganizationMutationVariables,
  APITypes.CreateOrganizationMutation
>;
export const updateOrganization = /* GraphQL */ `mutation UpdateOrganization(
  $input: UpdateOrganizationInput!
  $condition: ModelOrganizationConditionInput
) {
  updateOrganization(input: $input, condition: $condition) {
    OwnerOfOrganization
    CompanyName
    websiteDomain
    addressStreetAddress
    addressPostalCode
    addressCity
    addressCountry
    vatID
    disabledOrganization
    InviteCompanyMembersEMail
    TransferOwnershipToEMail
    AccessToAreaID {
      flaecheId
      OrganizationId
      userInfoSub
      DateTime
      __typename
    }
    AllowedAreaTypes
    ExclusiveAccessFirst24h
    SendBroadcastForUnlockToAllOrganizationMembers
    CostPerSeatPerMonth
    CostPerLeadLarger2000
    CostPerLeadLarger1000
    CostPerLeadSmaller1000
    CostSuccessFeePerkWp
    CostPercentageSplitSuccessFee
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrganizationMutationVariables,
  APITypes.UpdateOrganizationMutation
>;
export const deleteOrganization = /* GraphQL */ `mutation DeleteOrganization(
  $input: DeleteOrganizationInput!
  $condition: ModelOrganizationConditionInput
) {
  deleteOrganization(input: $input, condition: $condition) {
    OwnerOfOrganization
    CompanyName
    websiteDomain
    addressStreetAddress
    addressPostalCode
    addressCity
    addressCountry
    vatID
    disabledOrganization
    InviteCompanyMembersEMail
    TransferOwnershipToEMail
    AccessToAreaID {
      flaecheId
      OrganizationId
      userInfoSub
      DateTime
      __typename
    }
    AllowedAreaTypes
    ExclusiveAccessFirst24h
    SendBroadcastForUnlockToAllOrganizationMembers
    CostPerSeatPerMonth
    CostPerLeadLarger2000
    CostPerLeadLarger1000
    CostPerLeadSmaller1000
    CostSuccessFeePerkWp
    CostPercentageSplitSuccessFee
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrganizationMutationVariables,
  APITypes.DeleteOrganizationMutation
>;
export const createIncompleteAreasFromOfferForm = /* GraphQL */ `mutation CreateIncompleteAreasFromOfferForm(
  $input: CreateIncompleteAreasFromOfferFormInput!
  $condition: ModelIncompleteAreasFromOfferFormConditionInput
) {
  createIncompleteAreasFromOfferForm(input: $input, condition: $condition) {
    ownerSub
    areaType
    areaSize
    GeoCoordinatesLatitude
    GeoCoordinatesLongitude
    DateTimeUnixLastUpdate
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateIncompleteAreasFromOfferFormMutationVariables,
  APITypes.CreateIncompleteAreasFromOfferFormMutation
>;
export const updateIncompleteAreasFromOfferForm = /* GraphQL */ `mutation UpdateIncompleteAreasFromOfferForm(
  $input: UpdateIncompleteAreasFromOfferFormInput!
  $condition: ModelIncompleteAreasFromOfferFormConditionInput
) {
  updateIncompleteAreasFromOfferForm(input: $input, condition: $condition) {
    ownerSub
    areaType
    areaSize
    GeoCoordinatesLatitude
    GeoCoordinatesLongitude
    DateTimeUnixLastUpdate
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateIncompleteAreasFromOfferFormMutationVariables,
  APITypes.UpdateIncompleteAreasFromOfferFormMutation
>;
export const deleteIncompleteAreasFromOfferForm = /* GraphQL */ `mutation DeleteIncompleteAreasFromOfferForm(
  $input: DeleteIncompleteAreasFromOfferFormInput!
  $condition: ModelIncompleteAreasFromOfferFormConditionInput
) {
  deleteIncompleteAreasFromOfferForm(input: $input, condition: $condition) {
    ownerSub
    areaType
    areaSize
    GeoCoordinatesLatitude
    GeoCoordinatesLongitude
    DateTimeUnixLastUpdate
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteIncompleteAreasFromOfferFormMutationVariables,
  APITypes.DeleteIncompleteAreasFromOfferFormMutation
>;
