import { API, graphqlOperation } from "aws-amplify"
import { CreateProcessTryInput, CreateProcessTryMutation } from "../API"
import { GraphQLQuery } from "@aws-amplify/api";
import { createProcessTry } from "../graphql/mutations";

export const useProcessTry = () => {
    const handleCreateError = async (data: CreateProcessTryInput) => {
        await API.graphql<GraphQLQuery<CreateProcessTryMutation>>(
            graphqlOperation(createProcessTry, {
                input: data
            })
        );
    }

    return {
        handleCreateError
    }
}