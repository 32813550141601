import { useTranslation } from "react-i18next";
import { Flaeche } from "../../API";
import { AreaSize } from "../AreaSize";

interface ChatDetailsProps {
    area: Flaeche;
}

export const ChatDetails = ({ area }: ChatDetailsProps) => {
    const { t } = useTranslation();

    return (
        <>
            <p className="text-[11px]">
                {t('profile.type_of_area')} : {''}
                <span className="text-[11px] font-bold text-[#8EAD44]">
                    {t(area.areaType)}
                </span>
            </p>

            <p className="text-[11px]">
                {t('profile.size')} : {''}
                <span className="text-[11px] font-bold text-[#8EAD44]">
                    <AreaSize areaDetails={area} />
                </span>
            </p>

            <p className="text-[11px]">
                {t('profile.municipality')} : {''}
                {area.address_country && (
                    <span className="text-[11px] font-bold text-[#8EAD44]">
                        {t(
                            area.address_state
                                ? area.address_state
                                : area.address_country
                        )}
                    </span>
                )}
            </p>

            <p className="text-[11px]">
                {t('profile.post_code')} : {''}
                <span className="text-[11px] font-bold text-[#8EAD44]">
                    {area.address_postcode}
                </span>
            </p>

            <p className="text-[11px]">
                {t('profile.address')} : {''}
                <span className="text-[11px] font-bold text-[#8EAD44]">
                    {area.address_other}
                </span>
            </p>
        </>
    )
}
