import { Delete } from '@mui/icons-material';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

interface FilesDropZoneProps {
    onDelete: (path: string, type: string) => void;
    onDrop: (file: any) => void;
    files: any[];
}

export const FilesDropZone = (props: FilesDropZoneProps) => {
    const { t } = useTranslation();
    const { files, onDrop, onDelete } = props;

    const { getRootProps, getInputProps, isDragReject } = useDropzone({
        onDrop,
    });

    return (
        <div className="w-full md:w-[50%]">
            <div
                {...getRootProps()}
                className="flex h-[160px] w-full flex-col items-center 
                    justify-end gap-[33px] rounded-md border-[1px] border-dashed border-[#111A2140] bg-[#F2F2F2] px-[22px] py-[15px] md:gap-[2px]"
            >
                <input {...getInputProps()} />
                <div className="flex items-center gap-[20px]">
                    <img src="/assets/icons/file_copy.svg" alt="add file" />
                    <span className="text-[12px] font-bold uppercase text-[#9e9e9e] md:text-[16px]">
                        {t('addArea.placeholders.drop_docs')}
                    </span>
                </div>

                <div className="flex w-full justify-center md:justify-end">
                    <button
                        type="button"
                        className="rounded-[10px] bg-[#B0CC55] px-[31px] py-[14px]"
                    >
                        {t('addArea.upload')}
                    </button>
                    {isDragReject && (
                        <p className="text-black">
                            {' '}
                            "File type not accepted, sorry!"{' '}
                        </p>
                    )}
                </div>
            </div>

            <div className="mt-[20px] flex w-full gap-1">
                {files.map((file, i) => {
                    return (
                        <div className="drop__image relative" key={`${i}-n`}>
                            <img
                                src={'/assets/icons/file.png'}
                                alt="file"
                                className="h-[20px]"
                            />

                            <p className="text-[12px]">
                                {file.path && file.path.length > 5 ? `${file.path.slice(0, 5)}...` : `${file.slice(0, 5)}...`}
                            </p>

                            <div
                                className="dropped__delete absolute right-[-5px] top-[-10px] h-[30px] rounded-3xl bg-[#eeeeee] px-[5px]"
                                onClick={() => onDelete(file, 'files')}
                            >
                                <Delete sx={{ color: 'gray' }} />
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
