import { useTranslation } from "react-i18next";
import { Form } from "./Form";
import { SelectedPlan } from "../../../types";
import { Icons } from "../../../components";
import { Dispatch, SetStateAction, useMemo } from "react";
import i18n from "../../../i18n";
import { EnterpriseForm } from "./EnterpriseForm";
import { useLanguage } from "../../../hooks";

interface CheckoutProps {
    selectedPlan: SelectedPlan | null;
    onSelect: Dispatch<SetStateAction<SelectedPlan | null>>;
}

export const Checkout = ({ 
    selectedPlan, 
    onSelect,
}: CheckoutProps) => {
    const { t } = useTranslation();
    const { selectedLanguage } = useLanguage({ i18n });

    const handleRemovePlan = () => {
        onSelect(null);
    }

    const isEnterprise = useMemo(() => selectedPlan?.name === 'pricing.enterprise', [selectedPlan])

    return (
        <div className="flex flex-col items-center w-full">
            <p className="mb-[40px] text-[32px] font-medium lg:text-[38px]">
                {selectedPlan?.name === 'pricing.enterprise' 
                    ? t('pricing.lets_talk') 
                    : t('pricing.express')
                }
            </p>

            <div
                className="flex w-full flex-col gap-[45px] rounded-[12px] border-[0.5px] 
                    border-[#9e9e9e] px-[20px] py-[30px] md:w-fit md:p-[60px] lg:flex-row lg:gap-[20px] xl:gap-[50px] max-w-[1070px]"
            >
                <div className="md:w-[400px]">
                    <p className="mb-[25px] text-[16px] font-bold uppercase tracking-wider">
                        {t('pricing.selected')}
                    </p>

                    {selectedPlan && (
                        <div className="flex w-full gap-[20px] rounded-[12px] bg-[#F2F2F2] px-[26px] py-[29px] items-center relative">
                            <div className="h-[48px] w-[48px] rounded-[50%] bg-white flex items-center justify-center">
                                <Icons name={selectedPlan.icon} sx='h-[30px]' />
                            </div>

                            <div>
                                <p className="text-[16px] font-semibold text-[#11470E] md:text-[24px]">
                                    {t(selectedPlan.name)}
                                </p>
                                <p>{selectedPlan.icon 
                                    ? `${selectedPlan.price} ${t("pricing.month")}` 
                                    : `${t(selectedPlan.price)} ${2000?.toLocaleString(selectedLanguage === 'de' ? 'de-DE' : 'en-US')} m²`
                                }</p>
                            </div>

                            <img
                                src="/assets/icons/clear.svg"
                                alt="close"
                                className="h-[20px] absolute right-[10px] top-[10px] cursor-pointer"
                                onClick={handleRemovePlan}
                            />
                        </div>
                    )}
                </div>

                {isEnterprise ? (
                    <EnterpriseForm selected={selectedPlan} />
                ) : (
                    <Form selected={selectedPlan} />
                )}
            </div>
        </div>
    )
}
