import { Box } from "@mui/material";
import { UserInfo } from "../../../API";
import { useTranslation } from "react-i18next";

interface DeleteModalProps {
    selectedUser?: UserInfo | null | string;
    onDelete: () => void;
    onUserSet: (val: UserInfo | null) => void;
}

export const DeleteModal = ({
    selectedUser,
    onDelete,
    onUserSet
}: DeleteModalProps) => {
    const { t } = useTranslation();
    
    return (
        <Box className="fixed right-0 top-0 z-10 flex h-full w-full items-center justify-center px-[10px]">
            <div className="z-10 rounded-[8px] bg-[#dcedc8] px-[20px] py-[15px] text-center text-secondary">
                <p className="max-w-[400px] text-[17px]">
                    {t('organization.sure')} 
                </p>
                <p className="text-black mb-[20px]">
                    {typeof selectedUser === 'string' 
                        ? selectedUser 
                        : selectedUser?.email
                    }
                </p>

                <div className="flex w-full justify-center gap-[10px]">
                    <button
                        className="rounded-[8px] bg-secondary px-[10px] py-[5px] text-white"
                        onClick={onDelete}
                        type="button"
                    >
                        {t('organization.confirm')}
                    </button>

                    <button
                        className="rounded-[8px] bg-white px-[10px] py-[5px] text-secondary"
                        onClick={() => onUserSet(null)}
                        type="button"
                    >
                        {t('organization.cancel')}
                    </button>
                </div>
            </div>
        </Box>
    )
}
