/** @format */
import { useTranslation } from 'react-i18next';
import { ConfirmEmail } from '../ConfirmEmail';
import { Heading } from '../../components';
import { UserInfo } from './components/userInfo';
import { UserLogin } from './components/userLogin';
import { useLogin } from './hooks/useLogin';
import { usePageDescription } from '../../hooks';
import { Helmet } from 'react-helmet';

/** TYPES */

enum STEPS {
    REGISTER,
    INFO,
}

declare global {
    interface Window {
        fbq?: Function;
        dataLayer: any;
    }
}

export const Login = () => {
    const { t } = useTranslation();

    const {
        resendCode,
        socialSingIn,
        successSignUp,
        resendData,
        step,
        userData,
        isLoginPage,
        title,
        login,
        signUp,
        error,
        loginError,
        inProcess
    } = useLogin();

    usePageDescription({ 
        title: t('title.login'),
        description: t('description.login')
    });
    

    if (resendCode) {
        return (
            <ConfirmEmail data={resendData} />
        )
    }

    return (
        <>
        <Helmet>
            <meta name='title' content={t('title.login')} />
            <meta name='description' content={t('description.login')} />
        </Helmet>
        <div>
            {successSignUp && !isLoginPage ? (
                <ConfirmEmail data={userData} />
            ) : (
                <>
                    <div className="ml-[calc(50%-50vw)] w-screen bg-primary">
                        <div className="flex h-[93px] items-center justify-center md:h-[230px]">
                            <Heading
                                title={isLoginPage ? `${t('login.logIn')}` : `${t('sign_up.signUp')}`}
                                color="text-white"
                                size="text-2xl md:text-[48px]"
                                tag="h1"
                            />
                        </div>
                    </div>

                    <div className="mx-[25px] my-[50px] flex flex-col items-center justify-center md:my-[93px] ">
                        <div className="w-full md:max-w-[970px]">
                            <div className="mt-3 flex w-full flex-col items-center justify-center gap-y-10 md:mt-0">
                                {step === STEPS.REGISTER ? (
                                    <UserLogin
                                        nextStep={login}
                                        isLoginPage={isLoginPage}
                                        loginFailure={loginError}
                                        onSocialLogin={socialSingIn}
                                        title={title}
                                        inProcess={inProcess}
                                    />
                                ) : (
                                    <UserInfo
                                        nextStep={signUp}
                                        isLoginPage={isLoginPage}
                                        signInFailure={error}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}

            <div className="ml-[calc(50%-50vw)] relative h-full w-screen bg-[lightgray]">
                <div
                    className="absolute z-5 h-[200px] w-full"
                    style={{
                        background:
                            'linear-gradient(180deg, #FFFF 0%, rgba(243, 243, 243, 0.76) 40.12%, rgba(243, 243, 243, 0.00) 89.67%)',
                    }}
                ></div>
                <div className="z-[1] h-[451px] w-full bg-solarCell bg-cover bg-center  bg-no-repeat md:h-[857px]"></div>
            </div>
        </div>
        </>

    )
}
