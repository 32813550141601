import { useTranslation } from 'react-i18next';

interface ManageAreaButtonProps {
    areaId?: string;
}

export const ManageAreaButton = ({ areaId }: ManageAreaButtonProps) => {
  const { t } = useTranslation();

  return (
      <div className="w-full px-[25px] px-[30px] font-medium md:px-[150px] xl:px-[200px]">
          {areaId ? (
              <button className="w-full rounded-[10px] bg-[#B0CC55] py-[18px]">
                  {t('addArea.edit_area')}
              </button>
          ) : (
              <button className="w-full rounded-[10px] bg-[#B0CC55] py-[18px]">
                  {t('addArea.submit_area')}
              </button>
          )}
      </div>
  )
}
