import { useEffect, useState } from "react";
import { Flaeche } from "../../../API"

interface OptionsProps {
    item: Flaeche | undefined;
}

export const useArea = (options: OptionsProps) => {
    const { item } = options;
    const [progressImage, setImage] = useState('');
    const [title, setTitle] = useState('');
    const [desc, setDesc] = useState('');
    const [status, setStatus] = useState('');

    useEffect(() => {   
        if (item?.progressZustimmung && item?.progressZustimmung !== 'not started') {
            setImage('/assets/icons/thumbs-up.svg');
            setTitle('addArea.planning_process_items.agreement.title');
            setDesc('addArea.planning_process_items.agreement.desc');
            setStatus(item?.progressZustimmung);
        }

        if (item?.progressPachtvertragAbgeschlossen && item?.progressPachtvertragAbgeschlossen !== 'not started') {
            setImage('/assets/icons/contract.svg');
            setTitle('addArea.planning_process_items.contract.title');
            setDesc('addArea.planning_process_items.contract.desc');
            setStatus(item?.progressPachtvertragAbgeschlossen);
        }

        if (item?.progressEinspeisezusage && item?.progressEinspeisezusage !== 'not started') {
            setImage('/assets/icons/power-generation.svg');
            setTitle('addArea.planning_process_items.grid_connection.title');
            setDesc('addArea.planning_process_items.grid_connection.desc');
            setStatus(item?.progressEinspeisezusage);
        }

        if (item?.progressAufstellungsbeschluss && item?.progressAufstellungsbeschluss !== 'not started') {
            setImage('/assets/icons/plan.svg');
            setTitle('addArea.planning_process_items.approval_request.title');
            setDesc('addArea.planning_process_items.approval_request.desc');
            setStatus(item?.progressAufstellungsbeschluss);
        }

        if (item?.progressBebauungsplan && item?.progressBebauungsplan !== 'not started') {
            setImage('/assets/icons/Iconbuilding.svg');
            setTitle('addArea.planning_process_items.approval_grant.title');
            setDesc('addArea.planning_process_items.approval_grant.desc');
            setStatus(item?.progressBebauungsplan);
        }

        if (item?.progressBauvertrag && item?.progressBauvertrag !== 'not started') {
            setImage('/assets/icons/Unionconstruction.svg');
            setTitle('addArea.planning_process_items.plan.title');
            setDesc('addArea.planning_process_items.plan.desc');
            setStatus(item?.progressBauvertrag);
        }

        if (item?.progressAnlageGebaut && item?.progressAnlageGebaut !== 'not started') {
            setImage('/assets/icons/solar-panel.svg');
            setTitle('addArea.planning_process_items.building.title');
            setDesc('addArea.planning_process_items.building.desc');
            setStatus(item?.progressAnlageGebaut);
        }
    }, [item])

    return {
        title,
        progressImage,
        desc,
        status
    }
}