import { TableColumn } from "../types";

export const columns: TableColumn[] = [
    {
        id: 1,
        name: 'organization.name',
    },
    {
        id: 2,
        name: 'organization.status',
        width: 'w-[110px] md:w-[170px] [@media(max-width:580px)]:w-[80px]'
    },
    {
        id: 3,
        name: 'organization.last',
        width: 'w-[110px] md:w-[200px] [@media(max-width:580px)]:w-[80px]'
    },
    {
        id: 4,
        name: 'organization.manage',
        width: 'w-[110px] [@media(max-width:580px)]:w-[80px]',
    }
]