import { OfferIntro } from './components/OfferIntro';
import { OfferForm } from './components/OfferForm';
import { useTranslation } from 'react-i18next';
import { usePageDescription } from '../../hooks';
import { Helmet } from 'react-helmet';
import { helmetJsonLdProp } from 'react-schemaorg';
import { WebPage } from 'schema-dts';

export const OfferPage = () => {
    const { t } = useTranslation();

    usePageDescription({ 
        title: t('title.offer'),
        description: t('description.offer')
    });

    return (
        <div>
         <Helmet>
            <meta name='title' content={t('title.offer')} />
            <meta name='description' content={t('description.offer')} />
        </Helmet>
            <Helmet
                script={[
                    helmetJsonLdProp<WebPage>({
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": t('title.offer'),
                        "description": t('description.offer'),
                        "url": "https://www.enlapa.de/offer",
                    }),
                ]}
            />
            <OfferIntro />
            <OfferForm />
        </div>
    )
}
