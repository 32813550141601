/** @format */

import { memo } from 'react';

interface BurgerProps {
    onClick: () => void
}
export const Burger = memo(
    ({ onClick }: BurgerProps): JSX.Element => (
        <div
            onClick={onClick}
            className="flex cursor-pointer flex-col items-end gap-y-0.5 md:hidden"
        >
            <div className="h-1 w-[16px] bg-primary"></div>
            <div className="h-1 w-[20px] bg-primary"></div>
            <div className="h-1 w-[14px] bg-primary"></div>
        </div>
    )
)
