import { useTranslation } from "react-i18next"
import { usePageDescription } from "../../hooks";
import { Helmet } from "react-helmet";

export const PressContact = () => {
  const { t } = useTranslation();

  usePageDescription({ 
    title: t('title.press_contact'),
    description: t('description.press_contact')
  });

  return (
    <>
        <Helmet>
            <meta name='title' content={t('title.press_contact')} />
            <meta name='description' content={t('description.press_contact')} />
        </Helmet>
        <div className="flex flex-col py-[50px] md:px-[100px] px-[25px]">
        <h1 className="md:text-[38px] text-[25px] text-secondary  font-bold mb-[10px]">
            {t('press_contact.title')}
        </h1>

        <p className="md:text-[27px] text-[20px] mb-[20px] font-bold">
            {t('press_contact.welcome')}
        </p>

        <p className="text-[17px] mb-[20px]">
            {t('press_contact.desc')}
        </p>

        <p className="text-[25px] font-bold text-secondary mb-[20px]">
            {t('press_contact.media')}
        </p>

        <p className="text-[20px] font-semibold text-secondary mb-[20px]">
            {t('press_contact.media_desc')}
        </p>

        <div className="w-full">
            <p className="mb-[25px]">
                <span className="text-secondary font-semibold text-[18px]">{t('placeholders.email')}: </span>
                <a href="mailto:press@enlapa.de" className="text-primary text-[18px]">press@enlapa.de</a>
            </p>

            <p className="mb-[25px]">
                <span className="text-secondary font-semibold text-[18px]">{t('placeholders.phone_number')}: </span>
                <span className="text-primary text-[18px]">+49 89 20195 9990</span>
            </p>

            <p className="text-[17px] mb-[20px]">
                {t('press_contact.dedicated')}
            </p>
        </div>

        <p className="text-[25px] font-bold text-secondary mb-[20px]">
            {t('press_contact.kit')}
        </p>

        <p className="text-[17px] mb-[20px]">
            {t('press_contact.kit_desc')}
        </p>

        <p className="text-[25px] font-bold text-secondary mb-[20px]">
            {t('press_contact.updated')}
        </p>

        <p className="text-[17px] mb-[20px]">
            {t('press_contact.updated_desc')} {" "}
            <a href="mailto: press@enlapa.com" className="text-primary text-[18px]">press@enlapa.com</a> .
        </p>

        <p className="text-[25px] font-bold text-secondary mb-[20px]">
            {t('press_contact.follow')}
        </p>

        <div className="w-full mb-[40px] flex flex-col gap-[25px]">
            <p className="text-[20px] font-semibold text-secondary">
                {t('press_contact.follow_desc')}
            </p>

            <div className="flex flex-col gap-y-3">
                {[
                    {
                        name: 'linkedin',
                        link: 'https://www.linkedin.com/company/enlapa',
                        text: "Linkedin"
                    },
                    {
                        name: 'facebook',
                        link: 'https://www.facebook.com/enlapa.de',
                        text: "Facebook"
                    },
                    {
                        name: 'instagram',
                        link: 'https://www.instagram.com/enlapa.de/',
                        text: "Instagram"
                    }
                ].map((icon, i) => (
                    <a 
                        href={icon.link}
                        target='_blank'
                        rel="noreferrer"
                        className="flex gap-[15px] items-center"
                    >
                        <div
                            key={icon.name}
                            className="flex h-[34.91px] w-[34.91px] cursor-pointer items-center justify-center rounded-full bg-white transition duration-500 ease-in-out hover:bg-primary"
                        >
                            <img
                                src={`/assets/images/illustrations/${icon.name}.svg`}
                                alt={`Enlapa ${icon.name}`}
                            />
                        </div>

                        <p className="hover:text-primary">{icon.text}</p>
                    </a>
                ))}
            </div>
        </div>

        <div className="w-full">
            <p className="text-[20px] font-semibold text-secondary mb-[20px]">
                {t('press_contact.thank_you')}
            </p>

            <p className="text-[17px] mb-[20px]">
                {t('press_contact.thank_you_desc')}
            </p>
        </div>
    </div>
    </>

  )
}
