import { useCallback, useContext, useEffect, useState } from "react";
import { AreaProperty, ProgressArea } from "../../types";
import { useNavigate } from "react-router-dom";
import { 
    CreateFlaecheMutation, 
    Flaeche, 
    UpdateFlaecheInput, 
    UpdateFlaecheMutation 
} from '../../API';
import { API, Auth, graphqlOperation, Storage } from 'aws-amplify';
import { GraphQLQuery } from '@aws-amplify/api';
import { createFlaeche, updateFlaeche } from '../../graphql/mutations';
import { FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Links } from "../../settings";
import { ThanksPageContext } from "../../contexts/ThanksPageContext";
import { findAreaDescription, getAreaProgress } from "../../helpers/findAreaDesc";
import { downloadKML, takeScreenshot } from "../../helpers/handleDrawArea";
import { createPdf, fetchEnergyData, getAreaData } from "../../helpers";
import { useAuth, useProcessTry } from "../../hooks";
import { AREA_SIZE } from "../../utils/constants";
import { getCookie } from "../../helpers/getCookie";

interface Options {
    area?: Flaeche | null | undefined;
}

export const useOfferForm = ({ area: areaDetails }: Options ) => {
    const [visibleType, setVisibleType] = useState(false);
    const [selectedArea, setSelectedArea] = useState<AreaProperty | null>(null);
    const [selectedProgress, setSelectedProgress] = useState<ProgressArea | null>(null);
    const [activeProgress, setActiveProgress] = useState<ProgressArea[]>([]);
    const [completedProgress, setCompletedProgress] = useState<ProgressArea[]>([]);
    const [error, setError] = useState(false);
    const [data, setData] = useState<any>(null);
    const [zoom, setZoom] = useState(12);
    const [mapBoxError, setMapBoxError] = useState(false);
    const [areaSize, setAreaSize] = useState(0);
    const [isCorrectSize, setIsCorrectSize] = useState(true);
    const [size, setSize] = useState(0);
    const [isApproved, setIsApproved] = useState(false);
    const [mapElevation, setMapElevation] = useState(0);
    const [clickCount, setClickCount] = useState(0);
    const [showFinishModal, setShowFinishModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [filesToUpload, setFilesToUpload] = useState<File[]>(() => []);
    const [filesToDelete, setFilesToDelete] = useState<any[]>([]);
    const [doubleClick, setDoubleClick] = useState(false);
    
    const navigate = useNavigate();
    const { setData: setThanksData } = useContext(ThanksPageContext);
    const { loggedIn: user } = useAuth();
    const { handleCreateError } = useProcessTry();
    const { t } = useTranslation();

    const handleSelectProperty = useCallback((property: AreaProperty) => {
        if (selectedArea?.id === property.id) {
            setSelectedArea(null);
            return;
        }

        setSelectedArea(property);
    }, [selectedArea]);

    const handleSelectProgress = useCallback((property: ProgressArea) => {
        if (selectedProgress?.id === property.id) {
            setSelectedProgress(null);
            return;
        }

        setSelectedProgress(property)
    }, [selectedProgress]);

    const handleModalType = useCallback(() => {
        setVisibleType(prev => !prev)
    }, []);

    const handleCloseProgressModal = () => {
        setSelectedProgress(null);
    };

    const handleStartProcess = useCallback(() => {
        const foundItem = activeProgress.find(el => el.id === selectedProgress?.id);

        if (foundItem) {
            const filteredActive = activeProgress.filter(el => el.id !== selectedProgress?.id);
            setActiveProgress(filteredActive);
            setSelectedProgress(null);
            return;
        }

        if (selectedProgress) {
            const filteredCompleted = completedProgress.filter(el => el.id !== selectedProgress?.id);
            setCompletedProgress(filteredCompleted);
            setActiveProgress(prev => [...prev, selectedProgress]);
        }

        setSelectedProgress(null);
    }, [activeProgress, completedProgress, selectedProgress]);

    const handleCompleteProcess = useCallback(() => {
        const foundItem = completedProgress.find(el => el.id === selectedProgress?.id);

        if (foundItem) {
            const filteredCompleted = completedProgress.filter(el => el.id !== selectedProgress?.id);
            setCompletedProgress(filteredCompleted);
            setSelectedProgress(null);
            return;
        }
        
        if (selectedProgress) {
            const filteredActive = activeProgress.filter(el => el.id !== selectedProgress?.id);
            setActiveProgress(filteredActive);
            setCompletedProgress(prev => [...prev, selectedProgress]);
        }

        setSelectedProgress(null);
    }, [activeProgress, completedProgress, selectedProgress]);

    const handleNotStarted = useCallback(() => {
        const filteredCompleted = completedProgress.filter(el => el.id !== selectedProgress?.id);
        const filteredActive = activeProgress.filter(el => el.id !== selectedProgress?.id);

        setCompletedProgress(filteredCompleted);
        setActiveProgress(filteredActive);

        setSelectedProgress(null);
    }, [activeProgress, completedProgress, selectedProgress])

    const isActive = useCallback((item: ProgressArea) => {
        return activeProgress.find(el => el.id === item.id)
    }, [activeProgress]);

    const isCompleted = useCallback((item: ProgressArea) => {
        return completedProgress.find(el => el.id === item.id)
    }, [completedProgress]);

    const checkProgress = (name: string) => {
        if (activeProgress.some(el => t(el.title) === t(name))) {
            return 'started';
        }

        if (completedProgress.some(el => t(el.title) === t(name))) {
            return 'finished';
        }

        return 'not started';
    }

    const onSubmit = async (formData: FieldValues) => {
        setMapBoxError(false);
    
        try {
            if (formData.size > AREA_SIZE && !isApproved) {
                setIsCorrectSize(false);
                setSize(formData.size);
                return;
            }
    
            if (!data) {
                setError(true);
                return;
            }
    
            setLoading(true);

            await Auth.currentSession();
    
            const screen = await takeScreenshot(data, setError, zoom, setLoading);
            const kmlFile = await downloadKML(data, setError);
            const pdf = await createPdf(
                areaDetails ? areaDetails.ownerSub : user.sub ,
                formData, 
                t, 
                screen ? screen : null, 
                selectedArea?.title,
                activeProgress,
                completedProgress
            );
    
            if (!screen || !kmlFile || !pdf) {
                throw new Error("Failed to create necessary files.");
            }
    
            const thanksData = {
                area: formData.size,
                type: formData.type
            };
    
            setThanksData(thanksData);
    
            const [ longitude, latitude ] = data?.features[0]?.geometry?.coordinates[0][0];

            const energyData = await fetchEnergyData(latitude, longitude);

            const newArea = getAreaData(
                formData, 
                user.sub, 
                areaSize, 
                selectedArea, 
                { latitude, longitude, mapElevation }, 
                checkProgress, 
                t
            );

            const monthly = energyData?.outputs?.monthly?.fixed.map((month: any) => month.E_m);

            const areaToCreate = {
                ...newArea,
                MapboxZoomfactor: Math.round(zoom),
                PvGISInputFixedSlope: energyData?.inputs?.mounting_system?.fixed?.slope.value,
                PvGISInputFixedAzimuth: energyData?.inputs?.mounting_system?.fixed?.azimuth?.value,
                PvGISInputSystemLoss: energyData?.inputs?.pv_module?.system_loss,
                PvGISCalcOutputTotalE_y: energyData?.outputs?.totals?.fixed?.E_y,
                PvGISCalcOutputperMonthE_y: monthly,
                PvGISDateOfCalc: new Date().toISOString(),
                GeoCircumferenceMapbox: data.features.map((el: any) => el.geometry.coordinates[0])
            }
    
            window.dataLayer.push({
                event: 'submit_area',
                area: newArea
            });
    
            window.dataLayer.push({
                event: 'area_size',
                area: newArea.areaSize
            });
    
            const newAreaWithoutLocale = { ...areaToCreate };
            delete newAreaWithoutLocale.locale;

            if (
                user.sub !== areaDetails?.ownerSub &&
                (user.adminFull || user.adminCustomerService)
            ) {
                delete newAreaWithoutLocale.updatedTimeUnix;
            }
    
            const editingArea: UpdateFlaecheInput = {
                ...newAreaWithoutLocale,
                id: areaDetails?.id || '',
                ownerSub: areaDetails?.ownerSub || ''
            };

            const purchaseValue = 0.01 * formData.size;
    
            const files = [...filesToUpload, kmlFile, screen, pdf];
            let areaId: string | undefined;
    
            if (!areaDetails) {
                const createRes = await API.graphql<GraphQLQuery<CreateFlaecheMutation>>(
                    graphqlOperation(createFlaeche, { input: areaToCreate })
                );

                if (typeof window !== 'undefined' && window.fbq != null) {
                    window.fbq('track', 'Purchase', { value: purchaseValue, currency: 'EUR' });
                }

                const myCookieValue = getCookie('GA-not tracking');

                if (!myCookieValue) {
                    window.dataLayer.push({ ecommerce: null });
                    window.dataLayer.push({
                        event: "purchase",
                        ecommerce: {
                            transaction_id: createRes.data?.createFlaeche?.id,
                            value: purchaseValue,
                            currency: "EUR",
                            items: [{
                                item_name: createRes.data?.createFlaeche?.areaType,
                                item_id: createRes.data?.createFlaeche?.id,
                                price: 0.01,
                                quantity: createRes.data?.createFlaeche?.areaSize,
                                item_category: createRes.data?.createFlaeche?.areaType,
                                item_category2: createRes.data?.createFlaeche?.address_country,
                                item_category3: createRes.data?.createFlaeche?.address_state,
                                item_category4: createRes.data?.createFlaeche?.address_city
                            }]
                        }
                    });
                }
    
                areaId = createRes?.data?.createFlaeche?.id;
    
                if (files.length > 0 && areaId) {
                    await Promise.all(files.map(file =>
                        Storage.put(`${areaId}_${file?.name}`, file)
                    ));

                    await fetch(`https://w4v5y6i21m.execute-api.eu-central-1.amazonaws.com/?fileName=${areaId}_Screenshot.png
                    `)
                }
            } else {
                const updateRes = await API.graphql<GraphQLQuery<UpdateFlaecheMutation>>(
                    graphqlOperation(updateFlaeche, { input: editingArea })
                );
    
                areaId = updateRes?.data?.updateFlaeche?.id;
    
                if (filesToDelete.length > 0) {
                    await Promise.all(filesToDelete.map(file => Storage.remove(file)));
                }
    
                if (files.length > 0 && areaId) {
                    await Promise.all(files.map(file =>
                        Storage.put(`${areaId}_${file?.name}`, file)
                    ));

                    await fetch(`https://w4v5y6i21m.execute-api.eu-central-1.amazonaws.com/?fileName=${areaId}_Screenshot.png
                    `)
                }
            }
    
            setLoading(false);
            navigate(areaDetails ? `/search/${areaId}` : Links.thank_you.index);
    
            if (typeof window !== 'undefined' && window.fbq != null) {
                window.fbq('track', 'Lead');
            }
        } catch (error: any) {
            setMapBoxError(true);
            setLoading(false);

            const errorData = {
                userInfoSub: user.sub,
                requestBody: JSON.stringify(formData),
                errorMsg: error.message as string,
                response: JSON.stringify(error),
                process: 'Area creation',
                DateTimeUnix: Math.floor(new Date().getTime() / 1000)
            }

            handleCreateError(errorData);
            console.log("Error HERE", error);
        }
    };

    const handleApproveSize = useCallback(() => {
        setIsCorrectSize(true);
        setIsApproved(true);
    }, [])

    useEffect(() => {
        if (areaDetails) {
            findAreaDescription(areaDetails, setSelectedArea);

            getAreaProgress(
                areaDetails, 
                setActiveProgress, 
                setCompletedProgress, 
            );
        }
    }, [areaDetails])

    useEffect(() => {
        let timeoutId1: NodeJS.Timeout;
    
        if (clickCount === 2 && !data && !doubleClick) {    
            setShowFinishModal(true);

            setDoubleClick(true);

            timeoutId1 = setTimeout(() => {
                setShowFinishModal(false);
            }, 5000);

            return;
        }
    
        return () => {
            clearTimeout(timeoutId1);
        };
    }, [clickCount, data]);

    return {
        error,
        filesToUpload,
        visibleType,
        selectedArea,
        selectedProgress,
        activeProgress,
        completedProgress,
        handleCloseProgressModal,
        handleCompleteProcess,
        handleModalType,
        handleSelectProgress,
        handleSelectProperty,
        handleStartProcess,
        handleNotStarted,
        isActive,
        isCompleted,
        takeScreenshot,
        downloadKML,
        setData,
        loading,
        data,
        onSubmit,
        setFilesToUpload,
        setFilesToDelete,
        mapBoxError,
        setZoom,
        setAreaSize,
        isCorrectSize,
        size,
        setIsCorrectSize,
        setIsApproved,
        setMapElevation,
        handleApproveSize,
        setClickCount,
        showFinishModal,
        setShowFinishModal
    };
}