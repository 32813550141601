import { Dispatch, SetStateAction, useEffect } from "react";

interface Options {
    ref: any;
    onObserve: Dispatch<SetStateAction<boolean>>;
}

export const useObserver = ({ ref, onObserve }: Options) => {
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
            onObserve(entry.isIntersecting);
            },
            { root: null, rootMargin: '0px', threshold: 0.5 }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
            observer.unobserve(ref.current);
            }
        };
    }, [ref]);
}