import React from 'react'
import { useTranslation } from 'react-i18next'

export const AreaError = () => {
    const { t } = useTranslation();
    
    return (
        <div className="flex w-full justify-center pt-[40px]">
            <div className="mb-[15px] rounded-[10px] border-[1px] border-red-400 bg-white p-[10px]">
                <p className="font-medium text-red-400">
                    {t('addArea.please_try_later')}
                </p>
            </div>
        </div>
    )
}
