import { useTranslation } from 'react-i18next';
import { CallToAction } from '../../../components';
import { ArticleImage } from './Image';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { Links } from '../../../settings';

export const FourthArticle = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleNavigate = useCallback(() => {
        navigate(Links.offer.index);
    }, [navigate]);

    return (
        <div className="mb-[20px] flex flex-col gap-[25px] text-[16px] text-[#333] md:text-[18px]">
            <span className="uppercase text-primary">{t('articles.info')}</span>

            <h1 className="text-[25px] font-medium md:text-[35px]">
                {t('article_four.title')}
            </h1>

            <div className="h-[2px] w-[150px] bg-primary" />

            <span className="text-[13px] uppercase text-gray-700 md:text-[16px]">
                {t('articles.article_four.date')}
            </span>

            <p>{t('article_four.p_1')}</p>

            <p> {t('article_four.p_2')}</p>

            <p>{t('article_four.p_3')}</p>

            <ArticleImage
                source="/assets/images/FourthArticle.jpg"
                link="https://unsplash.com/de/fotos/eine-nahaufnahme-einer-uhr-mit-zahlen-darauf-Z09KqC2E3Ns"
            />

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_four.subtitle_1')}
            </h2>

            <p>{t('article_four.p_4')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_four.subtitle_2')}
            </h3>

            <p>{t('article_four.p_5')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_four.subtitle_3')}
            </h3>

            <p>{t('article_four.p_6')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_four.subtitle_4')}
            </h3>

            <p>{t('article_four.p_7')}</p>

            <ArticleImage
                source="/assets/images/Solarfeld.jpg"
                link="https://unsplash.com/de/fotos/blaue-solarpanel-platinen-V4ZYJZJ3W4M"
            />

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_four.subtitle_5')}
            </h2>

            <p>{t('article_four.p_8')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_four.subtitle_6')}
            </h3>

            <p>{t('article_four.p_9')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_four.subtitle_7')}
            </h3>

            <p>{t('article_four.p_10')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_four.subtitle_8')}
            </h3>

            <p>{t('article_four.p_11')}</p>

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_four.subtitle_9')}
            </h2>

            <p>{t('article_four.p_12')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_four.subtitle_10')}
            </h3>

            <p>{t('article_four.p_13')}</p>

            <ul className="flex list-disc flex-col gap-[10px] pl-[30px]">
                <li>{t('article_four.list_1.point_1')}</li>
                <li>{t('article_four.list_1.point_2')}</li>
            </ul>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_four.subtitle_11')}
            </h3>

            <p>{t('article_four.p_14')}</p>

            <ul className="flex list-disc flex-col gap-[10px] pl-[30px]">
                <li>
                    <b> {t('article_four.list_2.point_1.bold')}</b>{' '}
                    {t('article_four.list_2.point_1.text')}
                </li>
                <li>
                    <b>{t('article_four.list_2.point_2.bold')}</b>{' '}
                    {t('article_four.list_2.point_2.text')}
                </li>
            </ul>

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_four.subtitle_12')}
            </h2>

            <p>{t('article_four.p_15')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_four.subtitle_13')}
            </h3>

            <p>{t('article_four.p_16')}</p>

            <ul className="flex list-disc flex-col gap-[10px] pl-[30px]">
                <li>
                    <b>{t('article_four.list_3.point_1.bold')}</b>{' '}
                    {t('article_four.list_3.point_1.text')}
                </li>
                <li>
                    <b>{t('article_four.list_3.point_2.bold')}</b>{' '}
                    {t('article_four.list_3.point_2.text')}
                </li>
            </ul>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_four.subtitle_14')}
            </h3>

            <p>{t('article_four.p_17')}</p>

            <ul className="flex list-disc flex-col gap-[10px] pl-[30px]">
                <li>
                    <b>{t('article_four.list_4.point_1.bold')}</b>{' '}
                    {t('article_four.list_4.point_1.text')}
                </li>
                <li>
                    <b>{t('article_four.list_4.point_2.bold')}</b>{' '}
                    {t('article_four.list_4.point_2.text')}
                </li>
            </ul>

            <p>{t('article_four.p_18')}</p>

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_four.subtitle_15')}
            </h2>

            <p>{t('article_four.p_19')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_four.subtitle_16')}
            </h3>

            <p>
                {t('article_four.p_20')}{' '}
                <a
                    href="https://www.aceflex.de/magazin/pv-einspeiseverguetung-2024-das-aendert-sich-naechstes-jahr/"
                    target="_blank"
                    className="text-primary"
                >
                    Erneuerbare-Energie-Gesetz (EEG)
                </a>{' '}
                {t('article_four.p_21')}
            </p>

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_four.subtitle_17')}
            </h2>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_four.subtitle_18')}
            </h3>

            <p>{t('article_four.p_22')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_four.subtitle_19')}
            </h3>

            <p>{t('article_four.p_23')}</p>

            <p>{t('article_four.p_24')}</p>

            <p>{t('article_four.p_25')}</p>

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_four.subtitle_20')}
            </h2>

            <p>{t('article_four.p_26')}</p>

            <p>{t('article_four.p_27')}</p>

            <p>{t('article_four.p_28')}</p>

            <p>{t('article_four.p_29')}</p>

            <div className="mx-[25px] text-[16px] md:mt-[50px]">
                <CallToAction
                    title={t('home.call_to_action_urgency')}
                    btnTitle={t('buttons.offer_your_space_now')}
                    styles="mb-[40px]"
                    onClick={handleNavigate}
                    tag="p"
                />
            </div>
        </div>
    )
}
