import { Message } from '@twilio/conversations';
import { handleGetMessageTime } from '../../../helpers';
import { useEffect, useRef, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { Close, Delete, Download } from '@mui/icons-material';

interface MessageProps {
    author: boolean;
    message: Message;
    image?: string;
    onDelete?: (message: Message) => void;
}

export const MessageItem = ({ 
  author, 
  message, 
  image, 
  onDelete 
}: MessageProps) => {
    const [mediaUrls, setMediaUrls] = useState<string[][]>([]);
    const [mediaFiles, setMediaFiles] = useState<{ name: string; url: string }[][]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [openImage, setOpenImage] = useState('');

    const imageRef = useRef<HTMLImageElement | null>(null);

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
          if (imageRef.current && !imageRef.current.contains(event.target as Node)) {
              setOpenImage('');
          }
      };

      document.addEventListener('mousedown', handleClickOutside);

      return () => {
          document.removeEventListener('mousedown', handleClickOutside);
      };
  }, []);
  
    useEffect(() => {
        const fetchMediaUrls = async () => {
          setIsLoading(true);
          const urls: string[][] = [];
          const files: { name: string; url: string }[][] = [];
      
          if (message.attachedMedia && message.attachedMedia.length > 0) {
            const messageUrls: string[] = [];
            const messageFiles: { name: string; url: string }[] = [];
      
            for (const media of message.attachedMedia) {
              try {
                const url = await media.getContentTemporaryUrl();
                const fileName = media.filename || 'File';
      
                if (media.contentType.includes('image')) {
                  messageUrls.push(url || '');
                } else {
                  messageFiles.push({ name: fileName, url: url || '' });
                }
              } catch (error) {
                console.error('Error fetching media URL:', error);
                messageUrls.push('');
                messageFiles.push({ name: '', url: '' });
              }
            }
      
            urls.push(messageUrls);
            files.push(messageFiles);
          } else {
            urls.push([]);
            files.push([]);
          }
      
          setMediaUrls(urls);
          setMediaFiles(files);
          setIsLoading(false);
        };
      
        fetchMediaUrls();
      }, [message]);
  
    return (
      <div className={`mb-[40px]`}>
        <div
          className={`${
            author ? 'mb-[20px] flex justify-end' : 'flex gap-[10px]'
          }`}
          key={message.body}
        >
          {!author && (
            <img
              src={image}
              alt="profile"
              className="h-[40px] w-[40px] rounded-full"
            />
          )}
  
          <div
            className={`flex flex-col relative group ${
              author ? 'items-end' : 'items-start'
            } gap-[3px]`}
          >
            {message.body && (
              <div
                className={`${
                  author
                    ? 'rounded-tl-lg bg-[#B0CC55] text-white'
                    : 'rounded-tr-lg bg-[#F2F2F2]'
                } w-fit max-w-[400px] rounded-b-lg p-[10px]`}
              >
                {message.body}
              </div>
            )}
  
            {message.attachedMedia && message.attachedMedia.length > 0 && (
              <>
                {isLoading ? (
                    <CircularProgress color='success'  size={17} />
                ) : (
                    <div className="flex max-w-[200px] flex-wrap">
                        {mediaUrls.length > 0 &&
                        mediaUrls[0].map((mediaUrl, index) => (
                            <img
                                key={`${message.body}_media_${index}`}
                                src={mediaUrl}
                                alt="Media"
                                className="mb-2 mr-2 max-w-[200px] cursor-pointer"
                                onClick={() => setOpenImage(mediaUrl)}
                            />
                        ))}

                        {mediaFiles.length > 0 &&
                            mediaFiles[0].map((file, index) => (
                            <a 
                                href={file.url} 
                                download 
                                target='_blank'
                                key={`${file}_media_${index}`}
                            >
                                <div className='flex gap-[10px] rounded-[8px] border-[1px] border-primary px-[15px] py-[10px] items-center'>
                                    <img src="/assets/icons/file_copy.svg" className='h-[20px]'/>
                                    {file.name.slice(0, 15) + '...'}
                                </div>
                            </a>
                        ))}
                    </div>
                )}
              </>
            )}
  
            <p className="text-[12px] text-gray-400">
              {handleGetMessageTime(message.dateCreated)}
            </p>

           {author && (
             <div 
              className='absolute hidden group-hover:block transition-all top-[-10px] right-[-10px] cursor-pointer'
              onClick={() => onDelete?.(message)}
            >
              <Delete />
            </div>
           )}
          </div>
        </div>

        {openImage && (
          <div className='fixed z-10 h-full w-full flex justify-center items-center top-0 left-0 backdrop-filter backdrop-blur-sm'>
            <div className='relative group' ref={imageRef} >
              <img src={openImage} alt="" className='md:h-[400px] h-[200px]'/>

              <div 
                className='bg-gray-200 opacity-0 absolute top-0 bottom-0 left-0 
                  right-0 flex justify-center items-center group-hover:opacity-[0.9] transition-all'
              >
                <a href={openImage} download target="_blank">
                  <div className='bg-primary px-[5px] py-[3px] rounded-full cursor-pointer'>
                    <Download />
                  </div>
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };  
