import { useState } from "react";
import {
    AreasDashboard,
    OrganizationsDashboard,
    Tab,
    UsersDashboard,
} from './components';
import { useTranslation } from "react-i18next";
import { OnboardingDashboard } from "./components/OnbordingDashboard";
import { NotFoundPage } from "../NotFound";
import { useAuth } from "../../hooks";

export const AdminDashboard = () => {
    const { t } = useTranslation();
    const [tab, setTab] = useState(1);
    const { isAdmin } = useAuth();
    
    if (!isAdmin) {
        return <NotFoundPage />
    }

    return (
        <div className="px-[25px] py-[30px] md:px-[45px]">
            <div className="flex mb-[20px]">
                <Tab tab={1} selectedTab={tab} setTab={setTab} title="admin_dashboard.areas" />
                <Tab tab={2} selectedTab={tab} setTab={setTab} title="admin_dashboard.users" />
                <Tab tab={3} selectedTab={tab} setTab={setTab} title="admin_dashboard.organizations" />
                <Tab tab={4} selectedTab={tab} setTab={setTab} title="admin_dashboard.onboarding" />
            </div>

            <h1 className="mb-[20px] text-[30px] font-medium text-secondary">
                {t('admin_dashboard.title')}
            </h1>

            {tab === 1 && <AreasDashboard />}
            {tab === 2 && <UsersDashboard />}
            {tab === 3 && <OrganizationsDashboard />}
            {tab === 4 && <OnboardingDashboard />}
        </div>
    )
}
