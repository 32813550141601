import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { helmetJsonLdProp } from "react-schemaorg";
import { ItemList } from "schema-dts";

export const SearchSchema = () => {
    const { t } = useTranslation();

    return (
        <Helmet
            script={[
                helmetJsonLdProp<ItemList>({
                    '@context': 'https://schema.org',
                    '@type': 'ItemList',
                    name: t('title.search'),
                    description: t('description.search'),
                    url: 'https://www.enlapa.de/search',
                    numberOfItems: 40,
                    itemListElement: [
                        {
                            '@type': 'ListItem',
                            position: 1,
                            url: 'https://www.enlapa.de/search/817185d0-c746-4f60-8c55-43e896059ed3',
                            name: t('addArea.field'),
                            description: '20 ha',
                        },
                        {
                            '@type': 'ListItem',
                            position: 2,
                            url: 'https://www.enlapa.de/search/099bbb04-b2d4-4b16-86e7-80dec85c644c',
                            name: t('addArea.roof'),
                            description: '1300 m²',
                        },
                        {
                            '@type': 'ListItem',
                            position: 3,
                            url: 'https://www.enlapa.de/search/5613efc4-dcdd-41c8-b617-ab91e053ee36',
                            name: t('addArea.lake'),
                            description: '14 ha',
                        },
                    ],
                }),
            ]}
        />
    )
}
