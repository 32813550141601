import { useTranslation } from "react-i18next";
import { CallToAction } from "../../../components"
import { ArticleImage } from "./Image"
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { Links } from "../../../settings";

export const ThirdArticle = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleNavigate = useCallback(() => {
        navigate(Links.offer.index);
    }, [navigate])

    return (
        <div className="mb-[20px] flex flex-col gap-[25px] text-[16px] text-[#333] md:text-[18px]">
            <span className="uppercase text-primary">{t('articles.info')}</span>

            <h1 className="text-[25px] font-medium md:text-[35px]">
                {t('article_three.title')}
            </h1>

            <div className="h-[2px] w-[150px] bg-primary" />

            <span className="text-[13px] uppercase text-gray-700 md:text-[16px]">
                {t('articles.article_three.date')}
            </span>

            <p>{t('article_three.p_1')}</p>

            <p>{t('article_three.p_2')}</p>

            <p className="font-medium">{t('article_three.p_3')}</p>

            <p>{t('article_three.p_4')}</p>

            <p>{t('article_three.p_5')}</p>

            <ArticleImage
                source="/assets/images/Ackerland.jpg"
                link="https://unsplash.com/de/fotos/luftaufnahme-des-feldes-xDLEUTWCZdc"
            />

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_three.subtitle_1')}
            </h2>

            <p>{t('article_three.p_6')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_three.subtitle_2')}
            </h3>

            <p>{t('article_three.p_7')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_three.subtitle_3')}
            </h3>

            <p>{t('article_three.p_8')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_three.subtitle_4')}
            </h3>

            <ul className="flex flex-col gap-[10px] list-disc">
                <li>
                    <b>{t('article_three.options.one.bold')}</b>{' '}
                    {t('article_three.options.one.text')}
                </li>
                <li>
                    <b>{t('article_three.options.two.bold')}</b>{' '}
                    {t('article_three.options.two.text')}
                </li>
                <li>
                    <b>{t('article_three.options.three.bold')}</b>{' '}
                    {t('article_three.options.three.text')}
                </li>
                <li>
                    <b>{t('article_three.options.four.bold')}</b>{' '}
                    {t('article_three.options.four.text')}
                </li>
                <li>
                    <b>{t('article_three.options.five.bold')} </b>{' '}
                    {t('article_three.options.five.text')}
                </li>
            </ul>

            <ArticleImage
                source="/assets/images/Ackerland2.jpg"
                link="https://unsplash.com/de/fotos/schwarze-sonnenkollektoren-auf-grunem-rasenfeld-tagsuber-IwY-27ceRCA"
            />

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_three.subtitle_5')}
            </h2>

            <p>{t('article_three.p_9')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_three.subtitle_6')}
            </h3>

            <p>{t('article_three.p_10')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_three.subtitle_7')}
            </h3>

            <p>{t('article_three.p_11')}</p>

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_three.subtitle_8')}
            </h2>

            <p>{t('article_three.p_12')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_three.subtitle_9')}
            </h3>

            <p>{t('article_three.p_13')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_three.subtitle_10')}
            </h3>

            <p>{t('article_three.p_14')}</p>

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_three.subtitle_11')}
            </h2>

            <p>{t('article_three.p_15')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_three.subtitle_12')}
            </h3>

            <p>{t('article_three.p_16')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_three.subtitle_13')}
            </h3>

            <p>{t('article_three.p_17')}</p>

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_three.subtitle_14')}
            </h2>

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_three.subtitle_15')}
            </h2>

            <p>{t('article_three.p_18')}</p>

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_three.subtitle_16')}
            </h2>

            <p>{t('article_three.p_19')}</p>

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_three.subtitle_17')}
            </h2>

            <p>{t('article_three.p_20')}</p>

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_three.subtitle_18')}
            </h2>

            <p>{t('article_three.p_21')}</p>

            <div className="mx-[25px] text-[16px] md:mt-[50px]">
                <CallToAction
                    title={t('home.call_to_action_environment')}
                    btnTitle={t('buttons.offer_your_space_now')}
                    bgColor="bg-secondary text-white"
                    onClick={handleNavigate}
                    tag="p"
                />
            </div>
        </div>
    )
}
