import { useTranslation } from "react-i18next";
import { usePageDescription } from "../../hooks";
import { Helmet } from "react-helmet";

export const ServiceTerms = () => {
  const { t } = useTranslation();

  usePageDescription({ 
    title: t('title.service'),
    description: t('description.service')
  });

  return (
    <>
        <Helmet>
            <meta name='title' content={t('title.service')} />
            <meta name='description' content={t('description.service')} />
        </Helmet>
        <div className="flex flex-col py-[50px] md:px-[100px] px-[25px]">
        <p className="md:text-[37px] text-[20px] font-bold text-secondary mb-[10px]">
            {t('home.footer.terms_of_service')}
        </p>

       <div className="md:text-[20px]">
            <p className="mb-[30px]">
                {t('terms_of_service.desc')}
            </p>

            <p className="font-bold uppercase">
                1. {t('terms_of_service.about')}
            </p>

            <div className="pl-[20px] flex flex-col gap-[15px] mb-[30px]">
                <p>1.1. {t('terms_of_service.about_option_one')}</p>
                <p>1.2. {t('terms_of_service.about_option_two')}</p>
                <p>1.3. {t('terms_of_service.about_option_three')}</p>
                <p>1.4. {t('terms_of_service.about_option_four')}</p>
                <p>1.5. {t('terms_of_service.about_option_five')}</p>
                <p>1.6. {t('terms_of_service.about_option_six')}</p>
                <p>1.7. {t('terms_of_service.about_option_seven')}</p>
                <p>1.8. {t('terms_of_service.about_option_eight')}</p>
            </div>

            <p className="font-bold uppercase">
                2. {t('terms_of_service.services')}
            </p>

            <div className="pl-[20px] flex flex-col gap-[15px] mb-[30px]">
                <p>2.1. {t('terms_of_service.services_option_one')}</p>
                <p>2.2. {t('terms_of_service.services_option_two')}</p>
                <p>2.3. {t('terms_of_service.services_option_three')}</p>
                <p>2.4. {t('terms_of_service.services_option_four')}</p>
            </div>

            <p className="font-bold uppercase">
                3. {t('terms_of_service.registration')}
            </p>

            <div className="pl-[20px] flex flex-col gap-[15px] mb-[30px]">
                <p>3.1. {t('terms_of_service.registration_option_one')}</p>
                <p>3.2. {t('terms_of_service.registration_option_two')}</p>
            </div>

            <p className="font-bold uppercase">
                4. {t('terms_of_service.content')}
            </p>

            <p className="mb-[30px] pl-[20px]">
                {t('terms_of_service.content_option')}
            </p>

            <p className="font-bold uppercase">
                5. {t('terms_of_service.duties')}
            </p>

            <div className="pl-[20px] flex flex-col gap-[15px] mb-[30px]">
                <p>5.1. {t('terms_of_service.duties_option_one')}</p>
                <p>5.2. {t('terms_of_service.duties_option_two')}</p>
                <p>5.3. {t('terms_of_service.duties_option_three')}</p>
                <p>5.4. {t('terms_of_service.duties_option_four')}</p>
                <p>5.5. {t('terms_of_service.duties_option_five')}</p>
                <p>5.6. {t('terms_of_service.duties_option_six')}</p>
                <p>5.7. {t('terms_of_service.duties_option_seven')}</p>
		        <p>5.8. {t('terms_of_service.duties_option_eight')}</p>
                <p>5.9. {t('terms_of_service.duties_option_nine')}</p>
                <p>5.10. {t('terms_of_service.duties_option_ten')}</p>
            </div>

            <p className="font-bold uppercase">
                6. {t('terms_of_service.responsibility')}
            </p>

            <div className="pl-[20px] flex flex-col gap-[15px] mb-[30px]">
                <p>6.1. {t('terms_of_service.responsibility_option_one')}</p>
                <p>6.2. {t('terms_of_service.responsibility_option_two')}</p>
                <p>6.3. {t('terms_of_service.responsibility_option_three')}</p>
            </div>

            <p className="font-bold uppercase">
                7. {t('terms_of_service.rights')}
            </p>

            <p className="mb-[30px] pl-[20px]">
                {t('terms_of_service.rights_option')}
            </p>

            <p className="font-bold uppercase">
                8. {t('terms_of_service.warranty')}
            </p>

            <div className="pl-[20px] flex flex-col gap-[15px] mb-[30px]">
                <p>8.1. {t('terms_of_service.warranty_option_one')}</p>
                <p>8.2. {t('terms_of_service.warranty_option_two')}</p>
            </div>

            <p className="font-bold uppercase">
                9. {t('terms_of_service.liability')}
            </p>

            <div className="pl-[20px] flex flex-col gap-[15px] mb-[30px]">
                <p>9.1. {t('terms_of_service.liability_option_one')}</p>
                <div className="pl-[20px]">
                    <p>9.1.1. {t('terms_of_service.liability_option_two')}</p>
                    <p>9.1.2. {t('terms_of_service.liability_option_three')}</p>
                    <p>9.1.3. {t('terms_of_service.liability_option_four')}</p>
                </div>
            </div>

            <p className="font-bold uppercase">
                10. {t('terms_of_service.retention')}
            </p>

            <div className="pl-[20px] flex flex-col gap-[15px] mb-[30px]">
                <p>10.1. {t('terms_of_service.retention_option_one')}</p>
                <p>10.2. {t('terms_of_service.retention_option_two')}</p>
            </div>

            <p className="font-bold uppercase">
                11. {t('terms_of_service.other')}
            </p>

            <div className="pl-[20px] flex flex-col gap-[15px] mb-[30px]">
                <p>11.1. {t('terms_of_service.other_option_one')}</p>
                <p>11.2. {t('terms_of_service.other_option_two')}</p>
                <p>11.3. {t('terms_of_service.other_option_three')}</p>
            </div>
        </div>
    </div>
    </>

  )
}
