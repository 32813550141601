import { useTranslation } from "react-i18next";
import { DrawArea, OfferIntro } from "../Offer/components";
import { Button, Loader } from "../../components";
import { useForm } from "react-hook-form";
import {
    OfferAdsAreaSchema,
    TOfferAdsAreaSchema,
} from '../../helpers'
import { yupResolver } from "@hookform/resolvers/yup";
import { FirstStepForm, FourthStepForm, ThirdStepForm } from "./components";
import { useAreaCreation } from "./hooks";
import { useEffect, useState } from "react";

export const OfferMetaAds = () => {
    const {
        register,
        control,
        formState: { errors },
        setValue,
        handleSubmit
    } = useForm<TOfferAdsAreaSchema>({
        resolver: yupResolver(OfferAdsAreaSchema),
        reValidateMode: 'onChange',
        mode: 'all',
    });

    const { t } = useTranslation();

    const {
        step,
        data,
        phone,
        isLoading,
        fieldsData,
        mapError,
        error,
        areaFeatures,
        onSubmit,
        setAreaSize,
        setData,
        setZoom,
        setPhone,
        setError,
        setMapError,
        setStep,
        firstName,
        lastName,
        clickCount,
        setClickCount,
        showFinishModal,
        setMapElevation,
        setGeolocation,
        submittedError,
        watchedCountry,
        handleChangeName,
        handleChangeSur
    } = useAreaCreation({ control });

    const [showButton, setShowButton] = useState(false);

    const scrollToBottom = () => {
        window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth'
        });
    };

    useEffect(() => {
        const handleScroll = () => {
          const isScrollable = window.innerHeight < document.body.scrollHeight;
          const isAtBottom = window.innerHeight + window.scrollY >= document.body.scrollHeight;
    
          setShowButton(isScrollable && !isAtBottom);
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);

  return (
    <div>
        {step === 1 && (
            <OfferIntro />
        )}

        <div className="pt-[25px]">
           {isLoading && <Loader />}
           {step === 1 && (
             <FirstStepForm
                data={fieldsData}
                setValue={setValue} 
                errors={errors}
                register={register}
            />
           )}

           {step === 2 && (
                <FourthStepForm
                    firstName={firstName}
                    lastName={lastName}
                    phone={phone}
                    error={submittedError}
                    onSetPhone={setPhone}
                    onChangeName={handleChangeName}
                    onChangeSur={handleChangeSur}
                />
           )}

           {step === 3 && (
                <div className="w-full flex flex-col gap-[10px] justify-center items-center md:pt-[30px] lg:pt-0">
                    <p
                        className={`text-[12px] font-bold uppercase tracking-[2px]
                            md:text-[16px] md:tracking-[3px] px-[25px] md:px-[80px] xl:px-[100px] text-center w-full`}
                    >
                        {t('facebook_offer.map')}
                    </p>

                    <DrawArea
                        showFinish={showFinishModal}
                        dataToDraw={data}
                        className="mapBox-facebook"
                        clickCount={clickCount}
                        onDataSize={setAreaSize}
                        setZoom={setZoom}
                        setError={setError}
                        setMapError={setMapError}
                        onElevation={setMapElevation}
                        setData={setData}
                        error={mapError}
                        mapBoxError={error}
                        setClickCount={setClickCount}
                        onGeolocate={setGeolocation}
                    />
                </div>
            )}

            {step === 4 && (
                <ThirdStepForm
                    country={watchedCountry} 
                    features={areaFeatures}
                    fieldsData={fieldsData}
                    register={register}
                    setValue={setValue}
                    errors={errors}
                />
            )}

            {!isLoading && (
                <div className="w-full flex md:justify-end justify-center 
                    px-[25px] md:px-[80px] xl:px-[180px] gap-[15px] mb-[20px]"
                >
                    {step !== 1 && (
                        <Button 
                            color="Primary"
                            textColor="text-white"
                            onClick={() => setStep(step - 1)}
                        >
                            {t('addArea.back')}
                        </Button>
                    )}

                    <Button 
                        color="Secondary"
                        onClick={handleSubmit(onSubmit)}
                    >
                        {step === 4 ? t('addArea.submit_area') : t('addArea.next')}
                    </Button>
                </div>
            )}
        </div>

        {showButton && (
            <button className="fixed bottom-[5px] right-[5px] bg-primary h-[25px] 
                w-[25px] rounded-full flex justify-center items-center"
                onClick={scrollToBottom}
            >
                <img src="/assets/icons/arrowDown.svg" />
            </button>
        )}
    </div>
  )
}
