import { Link } from "react-router-dom";
import { Flaeche } from "../../../API"
import { useTranslation } from "react-i18next";
import { AreaSize } from "../../../components";

interface AreaDescriptionProps {
    area: Flaeche;
}

export const AreaDescOrganization = ({ area }: AreaDescriptionProps) => {
    const { t } = useTranslation();

    return (
        <Link to={`/search/${area.id}`}>
            <div className="flex justify-between gap-[10px] flex-wrap border-b-[1px] border-[#111A2126] px-[15px] py-[12px] h-[135px]">
                <div>
                    <p className="text-[14px] text-[#bdbdbd]">
                        {t('profile.municipality')}
                    </p>

                    <p className="text-[13px] font-medium">
                        {(area.address_country && t(area.address_country))}
                    </p>
                    <p className="text-[13px] font-medium">
                        {(area.address_state && t(area.address_state))}
                    </p>
                </div>

                <div>
                    <p className="text-[14px] text-[#bdbdbd]">
                        {t('placeholders.city')}
                    </p>

                    <p className="text-[13px] font-medium">
                        {area.address_city}
                    </p>
                </div>

                <div>
                    <p className="text-[14px] text-[#bdbdbd]">
                        {t('placeholders.postal_code')}
                    </p>

                    <p className="text-[13px] font-medium">
                        {area.address_postcode}
                    </p>
                </div>

                <div className="areas-center">
                    <p className="text-[14px] text-[#bdbdbd]">
                        {t('profile.size')}
                    </p>

                    <p className="text-[13px] font-medium">
                        <AreaSize areaDetails={area} />
                    </p>
                </div>
            </div>
        </Link>
    )
}
