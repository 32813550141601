/** @format */

import { useCallback } from 'react';
import { Button } from '../Button';
import { useNavigate } from 'react-router-dom';
import { Links } from '../../settings';
import { t } from 'i18next';

interface DrawerProps {
    isOpen: boolean;
    children: JSX.Element;
    onClose: () => void;
}

export const Drawer = ({ isOpen, children, onClose }: DrawerProps) => {
    const navigate = useNavigate();

    const handleNavigate = useCallback((path: string) => {
        navigate(path)
        onClose();
    }, [navigate, onClose]);

    return (
        <div
            className={
                'fixed inset-0 top-[54px] z-[99999] transform overflow-y-scroll ease-in-out ' +
                (isOpen
                    ? ' translate-x-0  transition-opacity duration-500'
                    : ' translate-x-full opacity-0 transition-all delay-500')
            }
        >
            <div
                className={
                    'h-[110vh] w-screen transform bg-[#B0CC55] bg-primary shadow-xl transition-all duration-500 ease-in-out ' +
                    (isOpen ? ' translate-x-0' : ' translate-x-full')
                }
            >
                {children}

                <div className="mt-[80px] flex flex-col items-center gap-y-[15px] md:flex-row md:justify-center md:gap-x-[25px]">
                    <Button 
                        color="Primary" 
                        textColor="text-white"
                        onClick={() => handleNavigate(Links.offer.index)}
                    >
                        {t('buttons.offer_spaces')}
                    </Button>
                    <Button 
                        color="white"
                        onClick={() => handleNavigate(Links.search.index)}
                    >
                        {t('buttons.search')}
                    </Button>
                </div>
            </div>
        </div>
    )
}
