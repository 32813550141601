import { Rating } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FlaecheRating } from "../../../API";

interface RatingBlockProps {
    onRating: (rateSize: number, rateGrid: number, ratePr: number) => void;
    ratedArea: FlaecheRating | null;
}

export const RatingBlock = (props: RatingBlockProps) => {
    const { onRating, ratedArea } = props;
    const [sizeRate, setSizeRate] = useState<number | null>(null);
    const [gridRate, setGridRate] = useState<number | null>(null);
    const [buildingRate, setBuildingRate] = useState<number | null>(null);
    const [isOpen, setIsOpen] = useState(true);
    const { t } = useTranslation();

    const handleOpen = () => {
        setIsOpen(prev => !prev);
    }

    useEffect(() => {
        if (sizeRate &&  gridRate && buildingRate) {
            onRating(sizeRate, gridRate, buildingRate);
        }
    }, [sizeRate, gridRate, buildingRate])

    useEffect(() => {
        if (ratedArea) {
            setSizeRate(ratedArea.ratingSizeLocation);
            setGridRate(ratedArea.ratingGrid);
            setBuildingRate(ratedArea.ratingProbability);
        }
    }, [ratedArea])

    return (
        <div>
            <div className="mb-[15px] flex justify-between font-semibold cursor-pointer" onClick={handleOpen}>
                <p>{t('area.rate')}</p>
                <img
                    src="/assets/icons/arrowDown.svg"
                    alt="arrow"
                    className={`cursor-pointer ${isOpen ? 'rotate-180' : ""}`}
                />
            </div>

            {isOpen && (
                <>
                    <p className="mb-[30px] text-[#9e9e9e]">
                        {t('area.rating_desc')}
                    </p>

                    <div className="mb-[20px]">
                        <p className="mb-[10px] text-[18px] font-medium">
                            {t('area.size_and_area')}
                        </p>

                        <Rating
                            name="simple-controlled"
                            value={sizeRate}
                            onChange={(event, newValue) => {
                                setSizeRate(newValue)
                            }}
                        />
                    </div>

                    <div className="mb-[20px]">
                        <p className="mb-[10px] text-[18px] font-medium">
                            {t('area.grid')}
                        </p>

                        <Rating
                            name="simple-controlled"
                            value={gridRate}
                            onChange={(event, newValue) => {
                                setGridRate(newValue)
                            }}
                        />
                    </div>

                    <div>
                        <p className="mb-[10px] text-[18px] font-medium">
                            {t('area.probability')}
                        </p>

                        <Rating
                            name="simple-controlled"
                            value={buildingRate}
                            onChange={(event, newValue) => {
                                setBuildingRate(newValue)
                            }}
                        />
                    </div>
                </>
            )}
        </div>
    )
}
