import React, { useCallback, useState } from 'react';
import {
    powerElementsDefaultValues,
    powerLayersDefaultValues,
} from '../constants';

export interface PowerElement {
    id: number;
    lat: number;
    lon: number;
    tags: {
        power?: string;
        operator?: string;
        name?: string;
        ref?: string;
        frequency?: string;
        substation?: string;
        building?: string;
        voltage?: string;
        branch?: string;
        location?: string;
        'generator:source'?: string;
        'generator:method'?: string;
        'generator:output'?: string;
        'voltage-high'?: string;
    };
}

export const usePowerElements = () => {
    const [powerElements, setPowerElements] = useState<{
        [key: string]: PowerElement[];
    }>(powerElementsDefaultValues);
    const [powerLayers, setPowerLayers] = useState<{ [key: string]: boolean }>(
        powerLayersDefaultValues
    );

    const elementsInBounds = useCallback(
        (elements: PowerElement[], bounds: mapboxgl.LngLatBounds) => {
            return elements.filter((element) =>
                bounds.contains([element.lon, element.lat])
            );
        },
        []
    );

    const fetchPower = useCallback(
        async (powerType: string, bounds: mapboxgl.LngLatBounds) => {
            const [sw, ne] = bounds.toArray();
            if (
                powerElements[powerType] &&
                elementsInBounds(powerElements[powerType], bounds).length > 0
            ) {
                return;
            }

            const query = `
          [out:json];
          (
            node["power"="${powerType}"](${sw[1]},${sw[0]},${ne[1]},${ne[0]});
            way["power"="${powerType}"](${sw[1]},${sw[0]},${ne[1]},${ne[0]});
            rel["power"="${powerType}"](${sw[1]},${sw[0]},${ne[1]},${ne[0]});
          );
          out body;
        `;

            try {
                const response = await fetch(
                    'https://overpass-api.de/api/interpreter',
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        },
                        body: new URLSearchParams({ data: query }),
                    }
                );

                const data = await response.json();
                setPowerElements((prev) => ({
                    ...prev,
                    [powerType]: [...prev[powerType], ...data.elements],
                }));
            } catch (error) {
                console.error(`Error fetching power ${powerType}:`, error);
            }
        },
        []
    );

    return {
        powerElements,
        powerLayers,
        setPowerLayers,
        fetchPower,
    };
};
