import { Flaeche } from "../API";

export const filterBySize = (areas: (Flaeche | null)[], values?: { min: string; max: string } | null) => {
    if (!values) {
        return areas;
    }

    const { min, max } = values;

    return areas.filter(area => {
        return area?.areaSize && 
            ((max ? area.areaSize <= +max : area.areaSize <= Infinity) 
            && (min ? area.areaSize >= +min : area.areaSize >= 0));
    });
}