import { ChangeEvent, useEffect, useState } from 'react';
import { InputAdornment, MenuItem, TextField } from '@mui/material';
import { useStyles } from '../../styles/jss/useStyles';
import { useTranslation } from 'react-i18next';
import { FieldError, FieldValues, Path, SetFieldValue, UseControllerProps, UseFormRegister } from 'react-hook-form';

type LabelUpInputProps<T extends FieldValues> = {
    value?: string | number | null;
    placeholder: string;
    defaultValue?: string;
    register: UseFormRegister<T>;
    name: Path<T>;
    star?: boolean;
    error: FieldError | undefined | boolean;
    starStyle?: string;
    icon?: boolean;
    iconSx?: string;
    type?: 'password' | 'textarea' | 'dropdown';
    options?: Record<string, string>[] | undefined;
    setValue?: SetFieldValue<T>;
} & UseControllerProps<T>;

export const LabelUpInput = <T extends FieldValues>({
    placeholder,
    register,
    error,
    name,
    type,
    options,
    setValue,
    value,
    starStyle,
    star,
}: LabelUpInputProps<T>) => {
    const [inputValue, setInputValue] = useState<string | number>('');
    const [focused, setIsFocused] = useState(false);
    const classes = useStyles();
    const { t } = useTranslation();

    const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setInputValue(event.target.value);

        if (value && setValue) {
            setValue(name, event.target.value);
        }
    };
    
    useEffect(() => {
        if (value && setValue) {
            setInputValue(value);
            setValue(name, value);
        }
    }, [name, value, setValue, t])

    let renderInput = register ? (
        <div className='relative TextField-with-border-radius'>
            <TextField
                className={classes.root}
                value={inputValue || ''}
                label={placeholder}
                error={Boolean(error)}
                onFocus={() => setIsFocused(true)}
                {...register(name)}
                style={{
                    width: '100%',
                }}
                inputProps={{
                    style: {
                        padding: '18px 20px',
                    },
                }}
                InputProps={{
                    endAdornment: name === 'size' && inputValue && (
                        <InputAdornment position="end">
                            m²
                        </InputAdornment>
                    ),
                }}
                onChange={handleInputChange}               
            />

            {star && !inputValue && !focused && (
                <div className={`text-[#FF0F00] text-[24px] absolute top-[13px] ${starStyle} req_placeholder`}>
                    <span className="req_placeholder">*</span>
                </div>
            )}
        </div>
    ) : null;

    if (type === 'dropdown')
        renderInput = (
            <div className='TextField-with-border-radius w-full relative'>
                <TextField
                    className={classes.root}
                    id="demo-simple-select-helper"
                    label={placeholder}
                    value={inputValue || ''}
                    onFocus={() => setIsFocused(true)}
                    {...register(name)}
                    error={Boolean(error)}
                    select
                    fullWidth
                    SelectProps={{
                        MenuProps: { 
                            PaperProps: { sx: { maxHeight: 250 } } 
                        },
                        inputProps: {
                            sx: {
                                padding: '18px 20px',
                            },
                        }
                    }}
                >

                {options?.map(option => (
                    <MenuItem 
                        key={t(option.label)} 
                        value={option.value} 
                        onClick={() => setInputValue(option.label)
                    }>
                        {t(option.label)}
                    </MenuItem>
                ))}
                </TextField>

                {star && !inputValue && !focused && (
                    <div className={`text-[#FF0F00] text-[24px] absolute top-[14px] ${starStyle} req_placeholder`}>
                        <span className="req_placeholder">*</span>
                    </div>
                )}
            </div>
        )

    if (type === 'textarea')
        renderInput = (
            <div className='TextField-with-border-radius relative'>
                <TextField
                    className={classes.root}
                    label={placeholder}
                    error={Boolean(error)}
                    value={inputValue || ''}
                    {...register(name)}
                    style={{
                        width: '100%',
                    }}
                    multiline
                    rows={4}
                    onChange={e => {
                        if (setValue) {
                            setValue(name, e.target.value);
                        }
                        handleInputChange(e);
                    }}  
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                />

                {star && !inputValue && !focused && (
                    <div className={`text-[#FF0F00] text-[24px] absolute top-[14px] ${starStyle} req_placeholder`}>
                        <span className="req_placeholder">*</span>
                    </div>
                )}
            </div>
        )

    return <div className={`w-full rounded-xl`}>{renderInput}</div>
}