import { Flaeche } from "../../../API";
import {ReactComponent as ClockIcon} from '../../../assets/icons/plan.svg';
import {ReactComponent as QualityIcon} from '../../../assets/icons/Iconbuilding.svg';
import {ReactComponent as PowerIcon} from '../../../assets/icons/power-generation.svg';
import {ReactComponent as ContractIcon} from '../../../assets/icons/contract.svg';
import {ReactComponent as ThumbsUpIcon} from '../../../assets/icons/thumbs-up.svg';
import {ReactComponent as SolarIcon} from '../../../assets/icons/solar-panel.svg';
import {ReactComponent as PlanIcon} from '../../../assets/icons/Unionconstruction.svg';
import { useTranslation } from "react-i18next";
import { handleIconColor } from "../../../helpers/iconColor";
import { ProgressDesc } from "./ProgressDesc";

interface ProgressProps {
    area: Flaeche | null | undefined;
}

export const Progress = ({ area }: ProgressProps) => {
    const { t } = useTranslation();

    return (
        <div className="min-w-[200px]">
            <p className="mb-[22px] text-[16px] text-[#9e9e9e]">
                {t('area.progress')}
            </p>

            <div className="flex flex-wrap gap-[25px] text-white">
                {area?.progressZustimmung &&
                area?.progressZustimmung !== 'false' ? (
                    <div
                        className={`${handleIconColor(
                            area?.progressZustimmung
                        )} group relative cursor-pointer`}
                    >
                        <ThumbsUpIcon className="h-[38px] w-[38px]" />

                        <ProgressDesc 
                            description={t('addArea.planning_process_items.agreement.desc')} 
                            status={t(area?.progressZustimmung)}
                        />
                    </div>
                ) : null}

                {area?.progressPachtvertragAbgeschlossen &&
                area?.progressPachtvertragAbgeschlossen !== 'false' ? (
                    <div
                        className={`${handleIconColor(
                            area?.progressPachtvertragAbgeschlossen
                        )} group relative cursor-pointer`}
                    >
                        <ContractIcon className="h-[38px] w-[38px]" />

                        <ProgressDesc 
                            description={t('addArea.planning_process_items.contract.desc')} 
                            status={t(area?.progressPachtvertragAbgeschlossen)}
                        />
                    </div>
                ) : null}

                {area?.progressEinspeisezusage &&
                area?.progressEinspeisezusage !== 'false' ? (
                    <div
                        className={`${handleIconColor(
                            area?.progressEinspeisezusage
                        )} group relative cursor-pointer`}
                    >
                        <PowerIcon className="h-[38px] w-[38px]" />

                        <ProgressDesc 
                            description={t('addArea.planning_process_items.grid_connection.desc')} 
                            status={t(area?.progressEinspeisezusage)}
                        />
                    </div>
                ) : null}

                {area?.progressAufstellungsbeschluss &&
                area?.progressAufstellungsbeschluss !== 'false' ? (
                    <div
                        className={`${handleIconColor(
                            area?.progressAufstellungsbeschluss
                        )} group relative cursor-pointer`}
                    >
                        <ClockIcon className="h-[38px] w-[38px]" />

                        <ProgressDesc 
                            description={t('addArea.planning_process_items.approval_request.desc')} 
                            status={t(area?.progressAufstellungsbeschluss)}
                        />
                    </div>
                ) : null}

                {area?.progressBebauungsplan &&
                area?.progressBebauungsplan !== 'false' ? (
                    <div
                        className={`${handleIconColor(
                            area?.progressBebauungsplan
                        )} group relative cursor-pointer`}
                    >
                        <QualityIcon className="h-[38px] w-[38px]" />

                        <ProgressDesc 
                            description={t('addArea.planning_process_items.approval_grant.desc')} 
                            status={t(area?.progressBebauungsplan)}
                        />
                    </div>
                ) : null}

                {area?.progressBauvertrag &&
                area?.progressBauvertrag !== 'false' ? (
                    <div
                        className={`${handleIconColor(
                            area?.progressBauvertrag
                        )} group relative cursor-pointer`}
                    >
                        <PlanIcon className="h-[38px] w-[38px]"/>

                        <ProgressDesc 
                            description={t('addArea.planning_process_items.plan.desc')} 
                            status={t(area?.progressBauvertrag)}
                        />
                    </div>
                ) : null}

                {area?.progressAnlageGebaut &&
                area?.progressAnlageGebaut !== 'false' ? (
                    <div
                        className={`${handleIconColor(
                            area?.progressAnlageGebaut
                        )} group relative cursor-pointer`}
                    >
                        <SolarIcon className="h-[38px] w-[38px]"/>

                        <ProgressDesc 
                            description={t('addArea.planning_process_items.building.desc')}
                            status={t(area?.progressAnlageGebaut)}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    )
}
