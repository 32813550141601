import { useTranslation } from "react-i18next";
import { useAuth, usePageDescription } from "../../hooks";
import { Helmet } from "react-helmet";

export const InvestorTerms = () => {
  const { loggedIn } = useAuth();
  const { t } = useTranslation();

  usePageDescription({ 
    title: t('title.invest_terms'),
    description: t('description.invest_terms')
  });

  if (!loggedIn.qualifiedInvestor) {
    return <div className="flex justify-center items-center py-[90px]">
        <p className="text-[25px] font-bold">
            {t('no_access')}
        </p>
    </div>;
  }

  return (
    <>
        <Helmet>
            <meta name='title' content={t('title.invest_terms')} />
            <meta name='description' content={t('description.invest_terms')} />
        </Helmet>
        <div className="flex flex-col py-[50px] md:px-[100px] px-[25px]">
        <h1 className="md:text-[38px] text-[25px] font-bold mb-[10px] text-secondary">
            {t('investor_terms.title')}
        </h1>

       <div className="text-[20px]">
            <p className="mb-[30px] font-bold md:text-[27px] text-[20px]">
                {t('investor_terms.desc')}
            </p>

            <p className="font-bold uppercase">
                1. {t('investor_terms.about')}
            </p>

            <div className="pl-[20px] flex flex-col gap-[15px] mb-[30px]">
                <p>1.1. {t('investor_terms.about_option_one')}</p>
                <p>1.2. {t('investor_terms.about_option_two')}</p>
                <p>1.3. {t('investor_terms.about_option_three')}</p>
                <p>1.4. {t('investor_terms.about_option_four')}</p>
                <p>1.5. {t('investor_terms.about_option_five')}</p>
                <p>1.6. {t('investor_terms.about_option_six')}</p>
                <p>1.7. {t('investor_terms.about_option_seven')}</p>
                <p>1.8. {t('investor_terms.about_option_eight')}</p>
            </div>

            <p className="font-bold uppercase">
                2. {t('investor_terms.services')}
            </p>

            <div className="pl-[20px] flex flex-col gap-[15px] mb-[30px]">
                <p>2.1. {t('investor_terms.services_option_one')}</p>
                <p>2.2. {t('investor_terms.services_option_two')}</p>
                <p>2.3. {t('investor_terms.services_option_three')}</p>
                <p>2.4. {t('investor_terms.services_option_four')}</p>
                <p>2.5. {t('investor_terms.enlapa')} </p>

                <div className="pl-[20px] flex flex-col gap-[15px]">
                    <p>2.5.1. {t('investor_terms.services_option_five')}</p>
                    <p>2.5.2. {t('investor_terms.services_option_six')}</p>
                    <p>2.5.3. {t('investor_terms.services_option_seven')}</p>
                    <p>2.5.4. {t('investor_terms.services_option_eight')}</p>
                    <p>2.5.5. {t('investor_terms.services_option_nine')}</p>
                    <p>2.5.6. {t('investor_terms.services_option_ten')}</p>
                    <p>2.5.7. {t('investor_terms.services_option_11')}</p>
                    <p>2.5.8. {t('investor_terms.services_option_12')}</p>
                </div>
            </div>

            <p className="font-bold uppercase">
                3. {t('investor_terms.registration')}
            </p>

            <div className="pl-[20px] flex flex-col gap-[15px] mb-[30px]">
                <p>3.1. {t('investor_terms.registration_option_one')}</p>
                <p>3.2. {t('investor_terms.registration_option_two')}</p>
            </div>

            <p className="font-bold uppercase">
                4. {t('investor_terms.duties')}
            </p>

            <div className="pl-[20px] flex flex-col gap-[15px] mb-[30px]">
                <p>{t('investor_terms.duties_option_one')}</p>
            </div>

            <p className="font-bold uppercase">
                5. {t('investor_terms.obligation')}
            </p>

            <div className="pl-[20px] flex flex-col gap-[15px] mb-[30px]">
                <p>5.1. {t('investor_terms.obligation_option_one')}</p>
                <p>5.2. {t('investor_terms.obligation_option_2')}</p>

                <div className="pl-[20px] flex flex-col gap-[15px]">
                    <p>5.2.1. {t('investor_terms.obligation_option_3')}</p>
                    <p>5.2.2. {t('investor_terms.obligation_option_4')}</p>
                    <p>5.2.3. {t('investor_terms.obligation_option_5')}</p>
                    <p>5.2.4. {t('investor_terms.obligation_option_6')}</p>
                    <p>5.2.5. {t('investor_terms.obligation_option_7')}</p>
                    <p>5.2.6. {t('investor_terms.obligation_option_8')}</p>
                    <p>5.2.7. {t('investor_terms.obligation_option_9')}</p>
                </div>

                <p>5.3. {t('investor_terms.obligation_option_10')}</p>

                <div className="pl-[20px] flex flex-col gap-[15px]">
                    <p>5.3.1. {t('investor_terms.obligation_option_11')}</p>
                    <p>5.3.2. {t('investor_terms.obligation_option_12')}</p>
                    <p>5.3.3. {t('investor_terms.obligation_option_13')}</p>
                    <p>5.3.4. {t('investor_terms.obligation_option_14')}</p>
                    <p>5.3.5. {t('investor_terms.obligation_option_15')}</p>
                    <p>5.3.6. {t('investor_terms.obligation_option_16')}</p>
                </div>

                <p>5.4. {t('investor_terms.obligation_option_17')}</p>
                <p>5.5. {t('investor_terms.obligation_option_18')}</p>
                <p>5.6. {t('investor_terms.obligation_option_19')}</p>
            </div>

            <p className="font-bold uppercase">
                6. {t('investor_terms.retention')}
            </p>

            <div className="pl-[20px] flex flex-col gap-[15px] mb-[30px]">
                <p>6.1. {t('investor_terms.retention_option_one')}</p>

                <div className="pl-[20px] flex flex-col gap-[15px]">
                        <p>6.1.1. {t('investor_terms.retention_option_2')}</p>
                        <p>6.1.2. {t('investor_terms.retention_option_3')}</p>
                </div>

                <p>6.2. {t('investor_terms.retention_option_4')}</p>
                <p>6.3. {t('investor_terms.retention_option_5')}</p>
                <p>6.4. {t('investor_terms.retention_option_6')}</p>
            </div>

            <p className="font-bold uppercase">
                7. {t('investor_terms.responsibility')}
            </p>

            <p className="mb-[30px] pl-[20px]">
                {t('investor_terms.responsibility_option_one')}
            </p>

            <p className="font-bold uppercase">
                8. {t('investor_terms.rights')}
            </p>

            <p className="mb-[30px] pl-[20px]">
                {t('investor_terms.rights_option')}
            </p>

            <p className="font-bold uppercase">
                9. {t('investor_terms.warranty')}
            </p>

            <div className="pl-[20px] flex flex-col gap-[15px] mb-[30px]"> 
                <p>9.1. {t('investor_terms.warranty_option_one')}</p>
                <p>9.2. {t('investor_terms.warranty_option_two')}</p>
            </div>

            <p className="font-bold uppercase">
                10. {t('terms_of_service.liability')}
            </p>

            <div className="pl-[20px] flex flex-col gap-[15px] mb-[30px]">
                <p>10.1. {t('terms_of_service.liability_option_one')}</p>
                <div className="pl-[20px] flex flex-col gap-[15px]">
                    <p>10.1.1. {t('terms_of_service.liability_option_two')}</p>
                    <p>10.1.2. {t('terms_of_service.liability_option_three')}</p>
                    <p>10.1.3. {t('terms_of_service.liability_option_four')}</p>
                </div>
            </div>

            <p className="font-bold uppercase">
                11. {t('investor_terms.confidentiality')}
            </p>

            <div className="pl-[20px] flex flex-col gap-[15px] mb-[30px]">
                <p>{t('investor_terms.confidentiality_point_one')}</p>
            </div>

            <p className="font-bold uppercase">
                12. {t('investor_terms.advice')}
            </p>

            <div className="pl-[20px] flex flex-col gap-[15px] mb-[30px]">
                <p>{t('investor_terms.advice_point_one')}</p>
            </div>

            <p className="font-bold uppercase">
                13. {t('investor_terms.commitment')}
            </p>

            <div className="pl-[20px] flex flex-col gap-[15px] mb-[30px]">
                <p>{t('investor_terms.commitment_point_one')}</p>
            </div>

            <p className="font-bold uppercase">
                14. {t('investor_terms.other')}
            </p>

            <div className="pl-[20px] flex flex-col gap-[15px] mb-[30px]">
                <p>14.1. {t('investor_terms.other_option_one')}</p>
                <p>14.2. {t('investor_terms.other_option_two')}</p>
                <p>14.3. {t('investor_terms.other_option_three')}</p>
            </div>
        </div>
    </div>
    </>

  )
}
