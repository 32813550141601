export const handleGetMessageTime = (time: Date | null) => {
    if (time) {
        const dateObject = new Date(time);

        const hour = dateObject.getHours();
        const minute = dateObject.getMinutes();

        const formattedMinute = minute < 10 ? `0${minute}` : minute;

        const formattedTime = `${hour}:${formattedMinute}`;

        return formattedTime;
    }
}