import { useTranslation } from "react-i18next";
import { Flaeche, UserInfo } from "../../../API";
import { Close } from "@mui/icons-material";
import { AreaSize, ChatDetails } from "../../../components";
import { Link, useNavigate } from "react-router-dom";
import { Links } from "../../../settings";
import { useDetails } from "../../ProjectDetails/hooks/useDetails";
import { useEffect } from "react";

interface ChatInfoProps {
    image?: string;
    user?: UserInfo | null;
    area?: Flaeche | null;
    onClose: () => void;
    interestedIn?: string;
}

export const ChatInfo = ({ image, user, area, onClose, interestedIn }: ChatInfoProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {
        areaDetails,
        getAreaDetails
    } = useDetails({ areaId: interestedIn });

    const handleNavigate = () => {
        if (user) {
            return;
        }

        navigate(`${Links.search.index}/${area?.id}`)
    }

    useEffect(() => {
        if (!interestedIn) return;

        getAreaDetails();
    }, [interestedIn])

    return (
        <div className="flex items-center justify-between gap-[10px] border-b-[0.5px] border-[#9e9e9e] px-[25px] py-[20px]">
            <div className="flex gap-[10px] items-start">
                <img
                    src={image}
                    alt=""
                    className={`h-[60px] w-[60px] rounded-full ${user ? '' : 'cursor-pointer'}`}
                    onClick={handleNavigate}
                />

                <div>
                    {area?.address_country && (
                        <p 
                            className={`text-[18px] font-medium ${user ? '' : 'cursor-pointer'}`}
                            onClick={handleNavigate}
                        >
                            {t(area.address_state) || t(area.address_country)}
                        </p>
                    )}

                    {user && (
                        <div>
                            <p className="text-[18px] font-medium">
                                {`${user?.firstName} ${user?.lastName}`}
                            </p>

                            {interestedIn && areaDetails && (
                                <p className='text-[13px]'>
                                    {t('profile.interested')} {t('search.area')}:  {" "}

                                    <Link
                                        to={`${Links.search.index}/${interestedIn}`}
                                        className='text-primary underline'
                                    >
                                        {areaDetails?.areaName && t(areaDetails?.areaName)} {" "}
                                        (<AreaSize areaDetails={areaDetails} />)
                                    </Link>
                                </p>
                            )}
                        </div>
                    )}

                    {area && (
                        <div className="flex flex-wrap gap-[15px]">
                            <ChatDetails area={area} />
                        </div>
                    )}
                </div>
            </div>

            <div 
                className="ml-[10%] flex items-center justify-end cursor-pointer"
                onClick={onClose}
            >
                <Close />
            </div>
        </div>
    )
}
