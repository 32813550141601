import { useTranslation } from "react-i18next";

export const AboutUs = () => {
    const { t } = useTranslation();

    return (
        <div className="mb-[20px] w-full">
            <p className="mb-[10px] text-[20px] font-bold uppercase md:text-[30px]">
                {t('terms.about_us.title')}
            </p>

            <p className="mb-[10px] ml-[20px] md:md:text-[18px]">
                {t('terms.about_us.we_are')}
            </p>

            <p className="mb-[10px] ml-[20px] md:text-[18px]">
                {t('terms.about_us.we_operate')}
            </p>

            <p className="mb-[10px] ml-[20px] md:text-[18px]">
                {t('terms.about_us.to_contact')}
            </p>

            <p className="mb-[10px] ml-[20px] md:text-[18px]">
                {t('terms.about_us.these_terms')}
            </p>

            <p className="mb-[10px] ml-[20px] md:text-[18px]">
                {t('terms.about_us.following')}
            </p>

            <p className="mb-[10px] ml-[40px] md:text-[18px]">
                {t('terms.about_us.our_privacy')}
            </p>

            <p className="mb-[10px] ml-[40px] md:text-[18px]">
                {t('terms.about_us.our_cookie')}
            </p>

            <p className="mb-[10px] ml-[40px] md:text-[18px]">
                {t('terms.about_us.our_terms')}
            </p>

            <p className="mb-[10px] ml-[20px] md:text-[18px]">
                {t('terms.about_us.the_name')}
            </p>
        </div>
    )
}
