import { useTranslation } from 'react-i18next';
import { usePageDescription } from '../../hooks';
import { Helmet } from 'react-helmet';

export const Privacy = () => {
  const { t } = useTranslation();

  usePageDescription({ 
    title: t('title.privacy'),
    description: t('description.privacy')
  });

  return (
    <>
        <Helmet>
            <meta name='title' content={t('title.privacy')} />
            <meta name='description' content={t('description.privacy')} />
        </Helmet>
        <div className="flex flex-col py-[50px] md:px-[100px] px-[25px]">
        <p className="md:text-[38px] text-secondary  text-[25px] font-bold mb-[10px]">
            {t('privacy.title')}
        </p>

        <div className='flex flex-col justify-start w-full md:text-[20px]'>
            <p className='mb-[20px]'>
                {t('privacy.we_want')}
            </p>

            <p className='mb-[20px]'>
                {t('privacy.in_this')}
            </p>

            <div>
                <p className='font-bold'>{t('privacy.general.title')}</p>

                <p className='italic'>{t('privacy.general.personal_data')}</p>

                <p className='mb-[30px]'>
                    {t('privacy.general.data')}
                </p>

                <p className='italic'>{t('privacy.general.processing')}</p>

                <p className='mb-[30px]'>
                    {t('privacy.general.means')}
                </p>

                <p className='italic'>{t('privacy.general.who_is')}</p>

                <p className='mb-[30px]'>
                    {t('privacy.general.responsible')}
                </p>

                <p className='italic'>{t('privacy.general.law')}</p>

                <p className='mb-[30px]'>
                    {t('privacy.general.our_use')}
                </p>

                <p className='italic'>{t('privacy.general.legal')}</p>

                <p className='mb-[30px]'>
                    {t('privacy.general.in_accordance')}
                </p>

                <p className='italic'>{t('privacy.general.competent')}</p>

                <p className='mb-[30px]'>
                    {t('privacy.general.supervisory')} {" "}
                    (<a 
                        href="https://www.bfdi.bund.de"
                        target='_blank'
                        className='text-primary' rel="noreferrer"
                    >
                        www.bfdi.bund.de
                    </a>) {" "}
                    {t('privacy.general.commisioner')} {" "}
                    (<a 
                        href="https://www.datenschutz-bayern.de"
                        target='_blank'
                        className='text-primary' rel="noreferrer"
                    >
                        www.datenschutz-bayern.de
                    </a>) {" "}
                    {t('privacy.general.however')}
                </p>

                <p className='italic'>{t('privacy.general.how_long')}</p>

                <p className='mb-[30px]'>
                    {t('privacy.general.we_process')}
                </p>

                <p className='mb-[30px]'>
                    {t('privacy.general.in_general')} {" "}
                    <a 
                        href="https://www.amazon.com/gp/help/customer/display.html?nodeId=468496&ref_=footer_privacy"
                        target='_blank'
                        className='text-primary'
                        rel="noreferrer"
                    >
                        Amazon
                    </a> {" "}
                    {t('privacy.general.amazon')}
                </p>
            </div>

            <div>
                <p className='font-bold'>{t('privacy.personal.title')}</p>

                <p className='italic'>{t('privacy.personal.technical')}</p>

                <p className='mb-[30px]'>
                    {t('privacy.personal.access')}
                </p>

                <p className='italic'>{t('privacy.personal.hosting')}</p>

                <p className='mb-[30px]'>
                    {t('privacy.personal.we_use')} {" "}
                    (<a 
                        href="https://www.amazon.com/gp/help/customer/display.html?nodeId=468496&ref_=footer_privacy"
                        target='_blank'
                        className='text-primary'
                        rel="noreferrer"
                    >
                        Amazon
                    </a>) {" "}
                    {t('privacy.personal.aws')}
                </p>

                <p className='italic'>{t('privacy.personal.cookies')}</p>

                <p className='mb-[30px]'>
                    {t('privacy.personal.use')}
                </p>

                <p className='mb-[30px]'>
                    {t('privacy.personal.as_set')}
                </p>

                <p className='italic'>{t('privacy.personal.consent')}</p>

                <p className='mb-[30px]'>
                    {t('privacy.personal.our_website')}
                </p>

                <p className='italic'>{t('privacy.personal.economic')}</p>

                <p className='mb-[30px]'>
                    {t('privacy.personal.business')} {" "}
                    <a 
                        href="https://policies.google.com/privacy?hl=en-US"
                        target='_blank'
                        className='text-primary'
                        rel="noreferrer"
                    >
                        Google
                    </a> {" "}
                    {t('privacy.personal.google')} {"  "}

                    <a 
                        href="https://matomo.org/matomo-cloud-privacy-policy/"
                        target='_blank'
                        className='text-primary'
                        rel="noreferrer"
                    >
                        {t('privacy.personal.matomo')}
                    </a> {" "}
                    {t('privacy.personal.matomo2')} {" "}
                    {t('privacy.personal.legal')} {" "}
                </p>

                <p className='italic'>{t('privacy.personal.mapbox')}</p>

                <p className='mb-[30px]'>
                    {t('privacy.personal.using')} {" "}
                    <a 
                        href="https://www.mapbox.com/legal/privacy"
                        target='_blank'
                        className='text-primary'
                        rel="noreferrer"
                    >
                        MapBox
                    </a> {" "}
                    {t('privacy.personal.using_next')}
                </p>

                <p className='italic'>{t('privacy.personal.fonts')}</p>

                <p className='mb-[30px]'>
                    {t('privacy.personal.fonts_use')}{" "}
                    <a 
                        href="https://policies.google.com/privacy?hl=en-US"
                        target='_blank'
                        className='text-primary'
                        rel="noreferrer"
                    >
                        Google
                    </a> {" "}
                    {t('privacy.personal.fonts_use_next')}
                </p>

                <p className='italic'>
                    {t('privacy.personal.contacting')}
                </p>

                <p className='mb-[30px]'>
                    {t('privacy.personal.you_can')}
                </p>

                <p className='mb-[30px]'>
                    {t('privacy.personal.we_process')}
                </p>

                <p className='mb-[30px]'>
                    {t('privacy.personal.register')}
                </p>

                <p className='mb-[30px]'>
                    {t('privacy.personal.lastly')}
                </p>

                <p className='mb-[30px]'>
                    {t('privacy.personal.legal_basis')}
                </p>

                <p className='italic'>{t('privacy.personal.testimonials')}</p>

                <p className='mb-[30px]'>
                    {t('privacy.personal.within')}
                </p>

                <p className='italic'>{t('privacy.personal.payment')}</p>

                <p className='mb-[30px]'>
                    {t('privacy.personal.if_you')} {" "}
                    <a 
                        href="https://stripe.com/en-gb-us/privacy"
                        target='_blank'
                        className='text-primary'
                        rel="noreferrer"
                    >
                        Stripe
                    </a> {" "}
                    {t('privacy.personal.stripe')}
                </p>

                <p className='italic'>{t('privacy.personal.admin')}</p>

                <p>
                    {t('privacy.personal.we')} {" "}
                    <a 
                        href="https://www.twilio.com/en-us/legal/privacy#twilio-privacy-notice"
                        target='_blank'
                        className='text-primary'
                        rel="noreferrer"
                    >
                        {t('privacy.personal.twilio')} 
                    </a> {" "}
                    {t('privacy.personal.twilio2')} {" "}
                    <a 
                        href="https://www.twilio.com/en-us/legal/tos"
                        target='_blank'
                        className='text-primary'
                        rel="noreferrer"
                    >
                        {t('privacy.personal.send')} 
                    </a> {" "}
                    {t('privacy.personal.for')} {" "}
                    <a 
                        href="https://zapier.com/privacy"
                        target='_blank'
                        className='text-primary'
                        rel="noreferrer"
                    >
                        {t('privacy.personal.zapier')} 
                    </a> {" "}
                    {t('privacy.personal.zapier2')} {" "}
                    <a 
                        href="https://slack.com/intl/en-gb/trust/privacy/privacy-policy"
                        target='_blank'
                        className='text-primary'
                        rel="noreferrer"
                    >
                        {t('privacy.personal.slack')} 
                    </a> {" "}
                    {t('privacy.personal.slack2')} {" "}
                    <a 
                        href="https://www.atlassian.com/legal/privacy-policy#what-this-policy-covers"
                        target='_blank'
                        className='text-primary'
                        rel="noreferrer"
                    >
                        {t('privacy.personal.ltd')} 
                    </a> {" "}
                    {t('privacy.personal.interest')} {" "}
                </p>

                <p className='mb-[30px]'>
                    {t('privacy.personal.hubspot')} {" "}
                    (
                        <a 
                            href="https://legal.hubspot.com/privacy-policy" 
                            target='_blank'
                            className='text-primary'
                        >
                            Hubspot
                        </a>
                        {" "} <span>{t('privacy.personal.hubspot_links')}</span>
                    ).
                </p>
            </div>

            <div>
                <p className='font-bold'>{t('privacy.data.title')}</p>

                <p className='mb-[30px]'>
                    {t('privacy.data.in_certain')}
                </p>

                <p className='italic'> {t('privacy.data.internal')}</p>

                <p className='mb-[30px]'>
                    {t('privacy.data.if_necessary')}
                </p>

                <p className='mb-[30px]'>
                    {t('privacy.data.we_may')}
                </p>

                <p className='italic'> {t('privacy.data.external')}</p>

                <p className='mb-[30px]'>
                    {t('privacy.data.personal')}
                </p>

                <div className='flex flex-col gap-[15px] mb-[30px]'>
                    <li> {t('privacy.data.one')}</li>
                    <li> {t('privacy.data.two')}</li>
                    <li> {t('privacy.data.three')}</li>
                    <li> {t('privacy.data.four')}</li>
                    <li> {t('privacy.data.five')}</li>
                </div>

                <p className='italic'> {t('privacy.data.international')}</p>

                <p className='mb-[30px]'>
                    {t('privacy.data.transfer')}
                </p>
            </div>

            <div>
                <p className='font-bold'>{t('privacy.security.title')}</p>

                <p className='mb-[30px]'>
                    {t('privacy.security.option_one')}
                </p>

                <p className='mb-[30px]'>
                    {t('privacy.security.option_two')}
                </p>
            </div>

            <div>
                <p className='font-bold'>{t('privacy.marketing.title')}</p>

                <p className='mb-[30px]'>
                    {t('privacy.marketing.option_one')}
                </p>

                <p className='mb-[30px]'>
                    {t('privacy.marketing.option_two')}
                </p>

                <p className='mb-[30px]'>
                    {t('privacy.marketing.option_three')}
                </p>
            </div>

            <div>
                <p className='font-bold'>{t('privacy.social.title')}</p>

                <p className='italic'>{t('privacy.social.general')}</p>

                <p className='mb-[30px]'>
                    {t('privacy.social.present')} {" "}
                    <a 
                        href="https://www.linkedin.com/legal/privacy-policy"
                        target='_blank'
                        className='text-primary'
                        rel="noreferrer"
                    >
                        LinkedIn
                    </a> {" "}
                    {t('privacy.social.linkedin')}
                    {" "}
                    <a 
                        href="https://m.facebook.com/privacy/policy/version/20220104/#:~:text=We%20don't%20sell%20any,who%20use%20our%20analytics%20services."
                        target='_blank'
                        className='text-primary'
                        rel="noreferrer"
                    >
                        Facebook
                    </a> {" "}
                    {t('privacy.social.facebook')}
                    {" "}
                    <a 
                        href="https://help.instagram.com/155833707900388"
                        target='_blank'
                        className='text-primary'
                        rel="noreferrer"
                    >
                        Instagram
                    </a> {" "}
                    {t('privacy.social.instagram')}
                    {" "}
                </p>

                <p className='italic'>{t('privacy.social.market')}</p>

                <p className='mb-[30px]'>
                    {t('privacy.social.in_addition')}
                </p>

                <p className='italic'>{t('privacy.social.visit')}</p>

                <p className='mb-[30px]'>
                    {t('privacy.social.when_visit')}
                </p>
            </div>

            <div>
                <p className='font-bold'>{t('privacy.advertising.title')}</p>

                <p className='mb-[30px]'>
                    {t('privacy.advertising.option_one')}
                </p>

                <p className='mb-[30px]'>
                    {t('privacy.advertising.option_two')}
                </p>
            </div>

            <div>
                <p className='font-bold'>{t('privacy.rights.title')}</p>

                <p className='italic'>{t('privacy.rights.privacy')}</p>

                <p className='mb-[30px]'>
                    {t('privacy.rights.under')}
                </p>

                <div className='flex flex-col gap-[15px] mb-[30px] italic'>
                    <li>{t('privacy.rights.option_one')}</li>
                    <li>{t('privacy.rights.option_two')}</li>
                    <li>{t('privacy.rights.option_three')}</li>
                    <li>{t('privacy.rights.option_four')}</li>
                    <li>{t('privacy.rights.option_five')}</li>
                    <li>{t('privacy.rights.option_six')}</li>
                    <li>{t('privacy.rights.option_seven')}</li>
                </div>

                <p className='italic'>{t('privacy.rights.updating')}</p>

                <p className='mb-[30px]'>
                    {t('privacy.rights.believe')}
                </p>

                <p className='italic'>{t('privacy.rights.withdrawing')}</p>

                <p className='mb-[30px]'>
                    {t('privacy.rights.you_can')}
                </p>

                <p className='italic'>{t('privacy.rights.access')}</p>

                <p className='mb-[30px]'>
                    {t('privacy.rights.event')}
                </p>
            </div>

            <div>
                <p className='font-bold'>{t('privacy.do_not_do.title')}</p>

                <div className='flex flex-col gap-[10px] mb-[30px] italic'>
                    <li>{t('privacy.do_not_do.option_one')}</li>
                    <li>{t('privacy.do_not_do.option_two')}</li>
                    <li>{t('privacy.do_not_do.option_three')}</li>
                </div>
            </div>

            <div>
                <p className='font-bold'>{t('privacy.validity.title')}</p>

                <p>
                    {t('privacy.validity.option')}
                </p>
            </div>
        </div>
    </div>
    </>

  )
}
