import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Links } from "../../../settings";
import { ArticleImage } from "./Image";
import { CallToAction } from "../../../components";

export const TwelveArticle = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleNavigate = useCallback(() => {
        navigate(Links.offer.index);
    }, [navigate])

    return (
        <div className="mb-[20px] flex flex-col gap-[25px] text-[16px] text-[#333] md:text-[18px]">
            <span className="uppercase text-primary">{t('articles.info')}</span>

            <h1 className="text-[25px] font-medium md:text-[35px]">
                {t('article_12.title')}
            </h1>

            <div className="h-[2px] w-[150px] bg-primary" />

            <span className="text-[13px] uppercase text-gray-700 md:text-[16px]">
                {t('articles.article_12.date')}
            </span>

            <p>{t('article_12.p_1')}</p>

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_12.subtitle_1')}
            </h2>

            <p>{t('article_12.p_2')}</p>
            <p>{t('article_12.p_3')}</p>

            <ArticleImage source="/assets/images/TwelveArticle-big.webp" />

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_12.subtitle_2')}
            </h2>

            <p>{t('article_12.p_4')}</p>
            <p>{t('article_12.p_5')}</p>
            <p>{t('article_12.p_6')}</p>
            <p>{t('article_12.p_7')}</p>
            <p>{t('article_12.p_8')}</p>
            <p>{t('article_12.p_9')}</p>
            <p>{t('article_12.p_10')}</p>
            <p>{t('article_12.p_11')}</p>

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_12.subtitle_3')}
            </h2>

            <p>{t('article_12.p_12')}</p>
            <p>{t('article_12.p_13')}</p>
            <p>{t('article_12.p_14')}</p>
            <p>{t('article_12.p_15')}</p>
            <p>{t('article_12.p_16')}</p>

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_12.subtitle_4')}
            </h2>

            <p>{t('article_12.p_17')}</p>
            <p>{t('article_12.p_18')}</p>
            <p>{t('article_12.p_19')}</p>
            <p>{t('article_12.p_20')}</p>
            <p>{t('article_12.p_21')}</p>
            <p>{t('article_12.p_22')}</p>

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_12.subtitle_5')}
            </h2>

            <p>{t('article_12.p_23')}</p>
            <p>{t('article_12.p_24')}</p>
            <p>{t('article_12.p_25')}</p>
            <p>{t('article_12.p_26')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_12.subtitle_6')}
            </h3>

            <p>{t('article_12.p_27')}</p>

            <div className="mx-[25px] text-[16px] md:mt-[50px]">
                <CallToAction
                    title={t('home.call_to_action_urgency')}
                    btnTitle={t('buttons.offer_your_space_now')}
                    styles="mb-[40px]"
                    onClick={handleNavigate}
                    tag="p"
                />
            </div>
        </div>
    )
}