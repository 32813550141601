import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Links } from '../../../settings';
import { CallToAction } from '../../../components';

export const SevenArticle = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleNavigate = useCallback(() => {
        navigate(Links.offer.index);
    }, [navigate]);

    return (
        <div className="mb-[20px] flex flex-col gap-[25px] text-[16px] text-[#333] md:text-[18px]">
            <span className="uppercase text-primary">{t('articles.bsw')}</span>

            <h1 className="text-[25px] font-medium md:text-[35px]">
                {t('article_seven.title')}
            </h1>

            <div className="h-[2px] w-[150px] bg-primary" />

            <span className="text-[13px] uppercase text-gray-700 md:text-[16px]">
                {t('article_seven.date')}
            </span>

            <p>{t('article_seven.p_2')}</p>

            <p>{t('article_seven.p_3')}</p>

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_seven.subtitle_1')}
            </h2>

            <p>{t('article_seven.p_4')}</p>

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_seven.subtitle_2')}
            </h2>

            <p>{t('article_seven.p_5')}</p>

            <p>{t('article_seven.p_6')}</p>

            <p>{t('article_seven.p_7')}</p>

            <div className="h-[2px] w-[150px] bg-primary" />

            <div className="flex items-center gap-[20px]">
                <h2 className="text-[23px] font-medium md:text-[27px]">
                    {t('article_seven.subtitle_3')}
                </h2>
            </div>

            <p>{t('article_seven.p_8')}</p>

            <div className="flex items-center gap-[20px]">
                <h2 className="text-[23px] font-medium md:text-[27px]">
                    {t('article_seven.subtitle_4')}
                </h2>
            </div>

            <p>{t('article_seven.p_9')}</p>

            <div className="mx-[25px] text-[16px] md:mt-[50px]">
                <CallToAction
                    title={t('home.call_to_action_urgency')}
                    btnTitle={t('buttons.offer_your_space_now')}
                    styles="mb-[40px]"
                    onClick={handleNavigate}
                    tag="p"
                />
            </div>
        </div>
    )
}
