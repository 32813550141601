/** @format */

import { useEffect, useRef, useState } from 'react';
import { Explainer } from './Explainer'
import { useTranslation } from 'react-i18next';
import { PauseCircle, PlayCircle, VolumeOff, VolumeUp } from '@mui/icons-material';
import { Heading } from '../../../components';

export const StepsToEarn = () => {
    const { t } = useTranslation();

    const steps: Record<string, string>[] = [
        {
            icon: 'land.svg',
            title: 'home.steps_to_earn.step_one_title',
            description:
            'home.steps_to_earn.step_one',
        },
        {
            icon: 'hand-shake.svg',
            title: 'home.steps_to_earn.step_two_title',
            description:
            'home.steps_to_earn.step_two',
        },
        {
            icon: 'location.svg',
            title: 'home.steps_to_earn.step_three_title',
            description:
            'home.steps_to_earn.step_three',
        },
    ]

    const videoRef = useRef<HTMLVideoElement | null>(null);
    const [videoIsInView, setVideoIsInView] = useState(false);

    const [isMuted, setIsMuted] = useState(true);
    const [isPlaying, setIsPlaying] = useState(false);

    const togglePlay = () => {
        const currentVideo = videoRef.current;

        if (currentVideo) {
            if (currentVideo.paused) {
                currentVideo.play();
                setIsPlaying(true);
            } else {
                currentVideo.pause();
                setIsPlaying(false);
            }
        }
    }

    const toggleMute = () => {
        const currentVideo = videoRef.current;
        if (currentVideo) {
            currentVideo.muted = !currentVideo.muted;
            setIsMuted(currentVideo.muted);
        }
    };

    const handleVideoEnded = () => {
        setIsPlaying(false);
    
        const currentVideo = videoRef.current;
        if (currentVideo) {
            currentVideo.pause();
            currentVideo.currentTime = 0;
        }
    };
    
    useEffect(() => {
        const currentVideo = videoRef.current;
    
        if (currentVideo) {
            currentVideo.addEventListener('ended', handleVideoEnded);
    
            return () => {
                currentVideo.removeEventListener('ended', handleVideoEnded);
            };
        }
    }, [videoRef]);

    const webVideoSource = "https://enlapa-media.s3.eu-central-1.amazonaws.com/ENLAPA_Intro_landscape_subtitles.mp4";
    const mobileVideoSource = "https://enlapa-media.s3.eu-central-1.amazonaws.com/ENLAPA_Intro_vertical_subtitles.mp4";

    const videoSource = window.innerWidth < 768 ? mobileVideoSource : webVideoSource;


    useEffect(() => {
        const handleIntersection = (entries: IntersectionObserverEntry[]) => {
            const entry = entries[0];
            if (entry.isIntersecting) {
                setVideoIsInView(true);
            } else {
                setVideoIsInView(false);
            }
        };

        const observer = new IntersectionObserver(handleIntersection, {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        });

        const currentVideo = videoRef.current;

        if (currentVideo) {
            observer.observe(currentVideo);
        }

        return () => {
            if (currentVideo) {
                observer.unobserve(currentVideo);
            }
        };
    }, [videoRef]);

    useEffect(() => {
        const currentVideo = videoRef.current;

        if (currentVideo) {
            if (videoIsInView) {
                currentVideo.play();
                setIsPlaying(true);
                window.dataLayer.push({
                    event: 'video',
                });
            } else {
                currentVideo.pause();
                setIsPlaying(false);
            }
        }
    }, [videoIsInView]);

    return (
        <div className="mx-[25px] mt-[60px] md:mt-[100px] earn section" id="how%20it%20works">
            <div className="relative box-border rounded-[24px] bg-yellowLight">
                <div className="flex flex-row gap-x-[60px] px-[74px] md:px-[45px] pt-[60px] xl:h-[536px]">
                    <div className="w-full text-center md:max-w-[393px] md:text-left">
                        <span className="bm-[10px] block text-sm md:mb-[20px] md:text-xl">
                            {t('home.steps_to_earn.how_to_get_offer')}
                        </span>
                        <Heading title={t('home.steps_to_earn.title')} />
                    </div>

                    <div className='hidden lg:flex flex-row gap-x-[60px]'>
                        <Explainer steps={steps} />
                    </div>
                </div>

                <div className="xl:absolute mt-[20px] xl:mt-0 md:top-[75%] [@media(max-width:1350px)]:top-[80%] flex fullscreen-video-container justify-center">
                    <div className='relative flex justify-center'>
                        <video autoPlay muted={isMuted} playsInline className="fullscreen-video h-[480px] md:h-full rounded-3xl md:w-[95%]" ref={videoRef}>
                            <source src={videoSource} type="video/mp4" />
                        </video>

                        <div className="absolute z-10 md:right-[50px] right-[20px] top-[30px] gap-[10px] flex">
                            <button onClick={toggleMute}>
                                {isMuted ? <VolumeOff /> : <VolumeUp />}
                            </button>

                            <button onClick={togglePlay} className='md:flex hidden'>
                                {!isPlaying ? <PlayCircle /> : <PauseCircle />}
                            </button>
                        </div>
                    </div>
                </div>

                <div className="mt-[35px] lg:hidden flex flex-col gap-y-1 justify-center items-center">
                    <Explainer
                        steps={steps}
                        styles="flex flex-col gap-y-1 justify-center items-center text-center p-[24px] gap-y-1"
                    />
                </div>
            </div> 
        </div>
    )
}
