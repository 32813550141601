import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useMap, useObserver, useResponsive } from '../../../hooks';
import { MapModal } from './MapModal';
import { useLocation } from 'react-router-dom';
import { Delete } from '@mui/icons-material';

export const DrawArea = (props) => {
    const { 
        error, 
        setData, 
        kml, 
        data,
        zoom,
        setZoom, 
        mapBoxError, 
        onDataSize,
        onElevation,
        setClickCount,
        dataToDraw,
        className,
        showFinish,
        setError,
        setMapError,
        onGeolocate
    } = props;

    const [mapVisible, setMapVisible] = useState(false);
    const [areaModal, setAreaModal] = useState(false);

    const { t } = useTranslation();
    const { pathname } = useLocation();
    const { isMobile } = useResponsive();

    const {
        mapContainerRef,
        polygonArea,
        isClicked,
        mode
    } = useMap({ 
        kml, 
        zoom,
        data,
        setData, 
        setZoom, 
        onElevation, 
        mapVisible, 
        setClickCount,
        dataToDraw,
        onGeolocate
    });

    useObserver({ ref: mapContainerRef, onObserve: setMapVisible });

    const isFacebookAds = pathname === '/offer-meta-ads';

    useEffect(() => {
        onDataSize(polygonArea);
    }, [polygonArea])

    useEffect(() => {
        let timeoutId1;

        if (dataToDraw) {
            setAreaModal(true);

            timeoutId1 = setTimeout(() => {
                setAreaModal(false)
            }, 4000)

            return;
        }

        return () => {
            clearTimeout(timeoutId1)
        }
    }, [dataToDraw])

    useEffect(() => {
        if (dataToDraw) {
            setMapError(false);
        }
    }, [dataToDraw])

    useEffect(() => {
        let timeoutId1;

        if (mapBoxError) {
            timeoutId1 = setTimeout(() => {
                setError(false);
            }, 4000)

            return;
        }

        return () => {
            clearTimeout(timeoutId1)
        }
    }, [mapBoxError])

    return (
        <>
            <div 
                ref={mapContainerRef} 
                className={`${className ? className : 'mapBox'}`} 
                id="map"
            >
                {!isFacebookAds && !isMobile && (
                    <MapModal polygonArea={polygonArea} />
                )}

                {!isClicked &&
                    (isFacebookAds || isMobile) &&
                    !dataToDraw &&
                    !showFinish &&
                    !error && (
                        <div
                            className="absolute left-[20%] top-[15%] z-10 w-[200px] rounded-[8px] 
                                p-[10px] text-center text-white sm:left-[40%]"
                            style={{ backgroundColor: 'rgba(176, 204, 85, 0.8)' }}
                        >
                            {t('facebook_offer.type')}
                        </div>
                    )}

                {showFinish && (isFacebookAds || isMobile) && !dataToDraw && !error && isClicked && (
                    <div
                        className="absolute left-[20%] top-[15%] z-10 w-[200px] rounded-[8px] 
                                p-[10px] text-center text-white sm:left-[40%]"
                        style={{ backgroundColor: 'rgba(176, 204, 85, 0.8)' }}
                    >
                        {t('facebook_offer.double')}
                    </div>
                )}

                {(isFacebookAds || isMobile) && areaModal && isClicked && (
                    <div
                        className="absolute left-[20%] top-[15%] z-10 w-[200px] rounded-[8px] 
                                p-[10px] text-center text-white sm:left-[40%]"
                        style={{ backgroundColor: 'rgba(176, 204, 85, 0.8)' }}
                    >
                        {t('facebook_offer.click')}{' '}
                        <img
                            src="/assets/icons/crop-tool.svg"
                            alt="polygon"
                            className="mb-[2px] inline h-[18px]"
                        />{' '}
                        {t('facebook_offer.add')}{' '}
                        <span className="text-black">
                            <Delete />
                        </span>{' '}
                        {t('facebook_offer.trash')}
                    </div>
                )}

                <div className="absolute bottom-[5px] left-[5px] z-10">
                    {(isFacebookAds || isMobile) && (
                        <div className="rounded-[8px] bg-white p-[5px] w-[100px] flex justify-center">
                            <span>{Math.round(polygonArea)} m²</span>
                        </div>
                    )}
                </div>

                {(isFacebookAds || isMobile) && error && (
                    <div
                        className="absolute left-[20%] top-[15%] z-10 w-[200px] rounded-[8px] 
                                p-[10px] text-center text-white sm:left-[40%]"
                        style={{ backgroundColor: 'rgba(255, 0, 0, 0.6)' }}
                    >
                        {t('addArea.please_draw')}
                    </div>
                )}

                {(isFacebookAds || isMobile) && mapBoxError && (
                    <div
                        className="absolute left-[20%] top-[15%] z-10 w-[200px] rounded-[8px] 
                                p-[10px] text-center text-white sm:left-[40%]"
                        style={{ backgroundColor: 'rgba(255, 0, 0, 0.6)' }}
                    >
                        {t('addArea.please_try_later')}
                    </div>
                )}
            </div>

            {error && !isFacebookAds && !isMobile && (
                <div className="mb-[15px] rounded-[10px] border-[1px] border-red-400 bg-white p-[10px]">
                    <p className="font-medium text-red-400">
                        {t('addArea.please_draw')}
                    </p>
                </div>
            )}

            {mapBoxError && !isFacebookAds && (
                <div className="mb-[15px] rounded-[10px] border-[1px] border-red-400 bg-white p-[10px]">
                    <p className="font-medium text-red-400">
                        {t('addArea.please_try_later')}
                    </p>
                </div>
            )}
        </>
    )
}
