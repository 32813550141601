import { useTranslation } from "react-i18next";
import { Button } from "../Button";
import { Dispatch, SetStateAction, useEffect, useRef } from "react";

interface ModalProps {
    text: string;
    buttonText: string;
    onClick: any;
    isCancel?: boolean;
    onCancel?:  Dispatch<SetStateAction<boolean>>;
}

export const Modal = ({ 
    text, 
    buttonText,
    isCancel,
    onClick,
    onCancel
}: ModalProps) => {
  const { t } = useTranslation();
  const modalRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
        if (modalRef.current && !modalRef.current.contains(event.target as Node) && onCancel) {
            onCancel(false);
        }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, []);

  return (
    <div 
        className="fixed top-0 right-0 h-full w-full bg-[#0000] 
            backdrop-filter backdrop-blur-sm flex justify-center items-center z-10 px-[10px]"
    >
        <div 
            className="bg-secondary text-white px-[20px] py-[15px] rounded-[8px] text-center z-10" 
            ref={modalRef}
        >
            <p className='text-[17px] max-w-[600px] mb-[20px]'>
                {t(text)}
            </p>

            <div className="flex gap-[10px] justify-center">
                <Button
                    width="w-fit" 
                    sx="px-[15px]"
                    onClick={onClick}
                >
                    {t(buttonText)}
                </Button>

                {isCancel && onCancel && (
                    <Button
                        width="w-fit" 
                        sx="px-[15px]"
                        onClick={() => onCancel(false)}
                    >
                        {t('admin_dashboard.cancel')}
                    </Button>
                )}
            </div>
        </div>
    </div>
  )
}
