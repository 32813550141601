import { TableCell, TableRow } from "@mui/material";
import { UserInfo } from "../../../API";
import { useUser } from "../hooks";
import { formatDate } from "../utils";
import { t } from "i18next";


interface UserRowProps {
    user: UserInfo | null;
}

export const UserRow = ({ user }: UserRowProps) => {
    const {
        userImage,
        activeAreas,
        disabledAreas,
        openedAreas
    } = useUser({ user });

    const getSearchCriteriaParams = (params: (string | null | undefined)[]) => {
        if (params && Array.isArray(params)) {
            return params
                .filter(param => param)
                .map((param, index) => t(param!) + (index < params.length - 1 ? ', ' : ''))
                .join('');
        }
        return '';
    };

    const countries = getSearchCriteriaParams(user?.SearchCriteriaCountries || []);
    const states = getSearchCriteriaParams(user?.SearchCriteriaFederalStates || []);
    const offers = getSearchCriteriaParams(user?.SearchCriteriaOfferLookingFor || []);

    const getSearchCriteriaTypes =
        user?.SearchCriteriaTypes && Array.isArray(user.SearchCriteriaTypes)
            ? user.SearchCriteriaTypes.map((item) => {
                  const type = item?.AreaTypeString
                      ? t(item?.AreaTypeString)
                      : '';
                  return {
                      type,
                      minSize: item?.sizeRange?.min,
                      maxSize: item?.sizeRange?.max,
                  };
              })
            : [];

    return (
        <TableRow
            hover
            role="checkbox"
            tabIndex={-1}
            className="cursor-pointer"
        >
            <TableCell>
                <div className="h-[50px] w-[50px] overflow-hidden rounded-[50%] border-[1px]">
                    <img
                        src={userImage 
                            ? userImage 
                            : '/assets/icons/profile-icon.png'
                        }
                        alt=""
                        className="h-[50px] w-[50px] rounded-[50%]"
                    />
                </div>
            </TableCell>

            <TableCell>
                {user?.qualifiedInvestor && (
                    <div className="rounded-[5px] bg-primary text-center text-white px-[2px]">
                        {t('admin_dashboard.investor')}
                    </div>
                )}

                {(user?.adminCustomerService || user?.adminFull) && (
                    <div className="rounded-[5px] bg-red-500 text-center text-white px-[2px]">
                        {t('admin_dashboard.admin')}
                    </div>
                )}
            </TableCell>

            <TableCell>
                {user?.firstName} {user?.lastName}
            </TableCell>

            <TableCell>{user?.email}</TableCell>

            <TableCell>{user?.phone}</TableCell>

            <TableCell>{formatDate(user?.createdAt)}</TableCell>

            <TableCell><div className="w-[90px]">{formatDate(user?.lastloginDateTime)}</div></TableCell>

            <TableCell>{t(user?.interestedIn ? user.interestedIn : '')}</TableCell>

            <TableCell>
                <div className="flex w-full justify-center">{openedAreas?.length}</div>
            </TableCell>

            <TableCell>
                <div className="flex w-full justify-center">{activeAreas?.length}</div>
            </TableCell>

            <TableCell>
                <div className="flex w-full justify-center">{disabledAreas.length}</div>
            </TableCell>

            <TableCell>
                <div className="flex flex-col justify-center">
                    {countries && (
                        <span>
                            <strong>{t('admin_dashboard.country')}</strong>:{' '}
                            {countries}
                        </span>
                    )}
                    {states && (
                        <span>
                            <strong>{t('admin_dashboard.state')}</strong>:{' '}
                            {states}
                        </span>
                    )}
                    {offers && (
                        <span>
                            <strong>{t('admin_dashboard.offer')}</strong>:{' '}
                            {offers}
                        </span>
                    )}
                    {getSearchCriteriaTypes.length > 0 && (
                        <div className='w-[350px]'>
                            <strong>{t('admin_dashboard.type')}:</strong>
                            {getSearchCriteriaTypes.map((item, index) => (
                            <div className="flex flex-row" key={index}>
                                {item.type}
                                {item.minSize && (
                                <>
                                    , {t('Minsize')}: {item.minSize}
                                </>
                                )}
                                {item.maxSize && (
                                <>
                                    , {t('Maxsize')}: {item.maxSize}
                                </>
                                )}
                            </div>
                            ))}
                        </div>
                        )}
                </div>
            </TableCell>
        </TableRow>
    )
}
