import { useForm } from "react-hook-form";
import { CustomInput } from "../../../components/CustomInput"
import { useTranslation } from "react-i18next";
import { PricingUserInfoSchema, TPricingUserInfoSchema } from "../../../helpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { SelectedPlan } from "../../../types";
import { CardForm } from "./CardForm";
import { CreditCard } from "./CreditCard";
import { useCardForm } from "../hooks";
import { Button } from "../../../components";
interface FormProps {
    selected: SelectedPlan | null;
}

export const Form = ({ selected }: FormProps) => {
    const { t } = useTranslation();

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm<TPricingUserInfoSchema>({
        mode: 'all',
        resolver: yupResolver(PricingUserInfoSchema),
    });

    const {
        cards,
        onSubmit,
        selectedCard,
        isAddMode,
        cardError,
        setIsAddMode,
        setSelectedCard,
        handleAddCard,
        isSubmitError,
        setIsSubmitError,
        handleRemoveCard
    } = useCardForm({ selected });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-[35px] flex flex-col gap-[25px]">
                <p className="text-[16px] font-bold uppercase tracking-wider">
                    {t('pricing.billing_address')}
                </p>

                <CustomInput
                    name="fullName"
                    placeholder={t('profile.labels.full_name')}
                    sx={`lg:w-[500px] h-[60px] w-full bg-white border-grey-50 border-solid border-[1px] ${
                        errors['fullName'] && 'border-red-400 outline-red-400'
                    }`}
                    register={register}
                />

                <CustomInput
                    name="email"
                    placeholder={t('pricing.address')}
                    sx={`lg:w-[500px] h-[60px] w-full bg-white border-grey-50 border-solid border-[1px] ${
                        errors['email'] && 'border-red-400 outline-red-400'
                    }`}
                    register={register}
                />
            </div>

            <div className="mb-[35px] flex flex-col gap-[25px] md:mb-[25px]">
                {selected?.name !== 'pricing.enterprise' &&  (
                    <>
                        <p className="text-[16px] font-bold uppercase tracking-wider">
                            {t('profile.method')}
                        </p>

                        {cards.map(card => (
                            <CreditCard 
                                key={card.number}
                                card={card}
                                selectedCard={selectedCard}
                                onSelect={setSelectedCard}
                                onRemove={handleRemoveCard}
                            />
                        ))}
                    </>
                )}

                {!isAddMode &&  (
                    <button 
                        className="mb-[15px] flex gap-[15px] text-[14px] text-[#11470E]" 
                        type="button" 
                        onClick={() => setIsAddMode(true)}
                    >
                        <span>+</span> <span>{t('profile.buttons.add')}</span>
                    </button>
                )}
            </div>

            {isAddMode  && (
                <CardForm onCards={handleAddCard} />
            )}

            {cardError && (
                <p className="text-red-500 mb-[20px]">
                    {t('pricing.card_error')}
                </p>
            )}


            {selected && (
                <button 
                    className="w-full rounded-[10px] bg-[#11470E] py-[17px] 
                        text-[20px] font-medium text-white">
                        {t('pricing.send')}
                </button>
            )}

            {isSubmitError && (
                <div className="fixed top-0 right-0 h-full w-full bg-[#0000] backdrop-filter backdrop-blur-sm flex justify-center items-center z-10 px-[10px]">
                    <div className="bg-[#464547] text-white px-[20px] py-[15px] rounded-[8px] text-center z-10">
                        <p className='text-[17px]'>{t("pricing.ops")}</p>
                        <p className='text-[17px] max-w-[400px] mb-[20px]'>
                        {t("pricing.process")}
                        </p>

                        <Button onClick={() => setIsSubmitError(false)}>
                            Ok
                        </Button>
                    </div>
                </div>
            )}
        </form>
    )
}

