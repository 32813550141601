import { FirebaseApp, initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  onMessage
} from "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASEPROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASESTORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASEMESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASEMEASUREMENT_ID
};

let app;
let messaging;
let initialized = false;

try {
  app = initializeApp(firebaseConfig);
  messaging = getMessaging(app);
  initialized = true;
} catch {
  console.warn("Couldn't initialize firebase app");
}

// Function to remove all listeners
const removeAllListeners = () => {
  if (initialized) {
    // Remove all listeners here
    messaging.onMessage((listener) => { });
    // Add other listeners that need to be removed
  }
};

export const initFcmServiceWorker = async () => {
  if (!initialized) {
    return;
  }

  try {
    const registration = await navigator.serviceWorker.register(
      "firebase-messaging-sw.js"
    );
    console.log("ServiceWorker registered with scope:", registration.scope);
  } catch (e) {
    console.log("ServiceWorker registration failed:", e);
  }
};

export const subscribeFcmNotifications = async (
  convoClient
) => {
  if (!initialized) {
    return;
  }

  const permission = await Notification.requestPermission();
  
  if (permission !== "granted") {
    console.log("FcmNotifications: can't request permission:", permission);
    return;
  }

  const fcmToken = await getToken(messaging);
  if (!fcmToken) {
    console.log("FcmNotifications: can't get fcm token");
    return;
  }

  await convoClient.setPushRegistrationId("fcm", fcmToken);

  onMessage(messaging, (payload) => {
    console.log("FcmNotifications: push received", payload);

    if (convoClient) {
      convoClient.handlePushNotification(payload);
    }
  });

  // // Remove all listeners before setting FCM token
  removeAllListeners();

};


export const showNotification = (pushNotification) => {
  if (!initialized) {
    return;
  }

  const notificationTitle = pushNotification.title || "";

  const notificationOptions = {
    body: pushNotification.body ?? "",
  };

  const notification = new Notification("Test");
  notification.onclick = (event) => {
    console.log("notification.onclick", event);
    event.preventDefault();
    notification.close();
  };
};