/** @format */
import { useTranslation } from 'react-i18next';
import { ContactForm } from './ContactForm';
import { Heading } from '../../../components';

export const GetQuote = () => {
    const { t } = useTranslation();

    return (
        <div className="relative mt-[60px] mb-[10px] h-[631px] overflow-hidden rounded-[24px] md:mx-[25px] md:mt-[150px] md:mb-[40px] lg:h-[690px] md:h-[750px]">
            <img
                className="h-full"
                src="/assets/images/solar-land.png"
                alt="Enlapa Get Quote"
            />

            <div className="md:left-none absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform md:right-0 md:-translate-x-0">
                <div className="m-auto w-[95%] rounded-3xl bg-white px-[26px] py-[30px] text-xs font-bold md:px-[45px] md:py-[39px] md:text-base lg:h-[602px] lg:max-w-[628px]">
                    <p className="mb-[15px] uppercase tracking-[2.4px]">
                        {t('contact-form.register')}
                    </p>
                    <Heading
                        title={t('contact-form.get_your_land')}
                        size="text-[32px] md:text-[38px]"
                        weight="md:font-medium"
                        leading="leading-[36px]"
                    />
                    <ContactForm />
                </div>
            </div>
        </div>
    )
}
