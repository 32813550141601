// @ts-nocheck
import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Links } from '../../settings';
import { useTranslation } from 'react-i18next';
import { useSearch } from '../SearchPage/hooks/useSearch';
import {
    BwsSection,
    EnergyRevolution,
    Intro,
    LandsForLease,
    RoofsForLease,
    StepsToEarn,
    WhatPeopleSayAoutUs,
} from './components'
import { CallToAction } from '../../components';
import { Flaeche } from '../../API';
import { Helmet } from 'react-helmet';
import { homeSchema } from '../../utils';
import { usePageDescription } from '../../hooks';

const filterAndSortAreas = (areas, areaTypes) => {
    return areas
        .filter((area) => !area?.disabled && areaTypes.includes(area?.areaType))
        .sort((a, b) => b.areaSize - a.areaSize);
}

const setFilteredAreas = (setFunction, filteredAreas) => {
    const areasToShow =
        filteredAreas.length > 4 ? filteredAreas.slice(0, 4) : filteredAreas;
    setFunction(areasToShow);
}

export const Home: React.FC<HomeProps> = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [roofs, setRoofs] = useState<Flaeche[]>([]);
    const [fields, setFields] = useState<Flaeche[]>([]);

    const handleNavigate = () => {
        navigate(Links.offer.index);
    }

    const {
        areas,
        getAreas,
        areaImages,
        fetchImages
    } = useSearch({});

    usePageDescription({ 
        title: t('title.home'),
        description: t('description.home')
    });

    const homeSchema = {
        "@context": "https://schema.org",
        "@type": "Article",
        "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://www.enlapa.de"
        },
        "headline": "Enlapa",
        "description": t("schema_home"),
        "author": {
            "@type": "Organization",
            "name": "Enlapa"
        },
        "publisher": {
            "@type": "Organization",
            "name": "Enlapa",
            "logo": {
            "@type": "ImageObject",
            "url": "https://www.enlapa.de/Enlapa logo.png",
            "width": "150",
            "height": "25"
            }
        },
        "keywords": "blog, real estate, Enlapa, solar, lease, sale, areas, field, roof, acker, dach, solarflächen",
        "datePublished": ""
    }

    useEffect(() => {
        getAreas({
            or: [
              { areaType: { eq: "addArea.roof" } },
              { areaType: { eq: "addArea.field" } },
              { areaType: { eq: "addArea.grassland" } }
            ]
          });
        
        window.location.hash =
            window.location.hash !== '' ? window.location.hash : ''
    }, [])

    useEffect(() => {
        const roofTypes = ['addArea.roof'];
        const fieldTypes = ['addArea.field', 'addArea.grassland'];

        const roofs = filterAndSortAreas(areas, roofTypes);
        setFilteredAreas(setRoofs, roofs);
    
        const fields = filterAndSortAreas(areas, fieldTypes);
        setFilteredAreas(setFields, fields);
    }, [areas]);

    return (
        <div className="landing md:px-[30px]">
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify(homeSchema)}
                </script>
                <meta name='description' content={t('description.home')} />
                <meta name='title' content={t('title.home')} />
            </Helmet>

            <Intro />
            <EnergyRevolution />
            <BwsSection />
            <StepsToEarn />
            <div className="m-auto md:mt-[152px] mt-[60px] max-w-[1178px] px-[25px]">
                <CallToAction
                    title={t('home.call_to_action_money')}
                    btnTitle={t('buttons.offer_your_space_now')}
                    bgColor="bg-secondary text-white"
                    onClick={handleNavigate}
                    tag="p"
                />
            </div>
            <LandsForLease 
                lands={fields} 
                images={areaImages} 
                fetchImages={fetchImages}
            />
            <RoofsForLease 
                roofs={roofs} 
                images={areaImages} 
                fetchImages={fetchImages}
            />
            <div className="mx-[25px] md:mt-[171px]">
                <CallToAction
                    title={t('home.call_to_action_urgency')}
                    btnTitle={t('buttons.offer_your_space_now')}
                    styles="mb-[40px]"
                    onClick={handleNavigate}
                    tag="p"
                />
            </div>
            <WhatPeopleSayAoutUs />
        </div>
    )
}
