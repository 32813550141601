/** @format */

import { CallToAction } from '../../../components/CallToAction';
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { useNavigate } from 'react-router-dom';
import { Links } from '../../../settings';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { BlogSection } from './BlogSection';

export interface Feedback {
    /**
     * The feedback text.
     */
    text: string
    /**
     * The picture associated with the feedback.
     */
    pic: string
    /**
     * The name of the person giving the feedback.
     */
    name: string
    /**
     * The bio or description of the person giving the feedback.
     */
    bio: string
}

/**
 * Feedback & Call to action last section
 * @returns rendered WhatPeopleSayAoutUs component
 */
export const WhatPeopleSayAoutUs = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleNavigate = useCallback(() => {
        navigate(Links.offer.index)
    }, [navigate]);

    return (
        <div className=" bg-grey md:ml-[calc(50%-50vw)] md:mt-[100px] md:w-screen" >
            <BlogSection />
            
             <div className="m-auto mx-[25px]" >
                <div className="m-auto md:mt-[152px] mt-[60px] max-w-[1178px]">
                    <CallToAction
                        title={t('home.call_to_action_environment')}
                        btnTitle={t('buttons.offer_your_space_now')}
                        bgColor="bg-secondary text-white"
                        onClick={handleNavigate}
                        tag="p"
                    />
                </div>
            </div> 
            <div className="relative h-full w-full bg-[lightgray]" >
                <div
                    className="absolute z-[999] h-[200px] w-full"
                    style={{
                        background:
                            'linear-gradient(180deg, #F3F3F3 0%, rgba(243, 243, 243, 0.76) 40.12%, rgba(243, 243, 243, 0.00) 89.67%)',
                    }}
                ></div>
                <div className="z-[1] h-[451px] w-full bg-solarplant bg-cover bg-center  bg-no-repeat md:h-[857px]"></div>
            </div>
        </div>
    )
}
