import { FormHeader } from './FormHeader';
import { TOfferAreaSchema } from '../../../helpers';
import { TypeModal } from './TypeModal';
import { types } from '../../../utils/formLists';
import { useTranslation } from 'react-i18next';
import { Flaeche } from '../../../API';
import { LabelUpInput } from '../../../components';
import { useLanguage } from '../../../hooks';
import { FieldErrors, SetFieldValue, UseFormRegister } from 'react-hook-form';

interface AreaInfoProps {
    area?: Flaeche | undefined | null;
    register: UseFormRegister<TOfferAreaSchema>;
    errors: FieldErrors<TOfferAreaSchema>;
    setValue: SetFieldValue<TOfferAreaSchema>;
    visibleType: boolean;
    handleModalType: () => void;
}

export const AreaInfo = (props: AreaInfoProps) => {
    const { t, i18n } = useTranslation();
    const { selectedLanguage } = useLanguage({ i18n });

    const { 
        area,
        register, 
        errors, 
        setValue, 
        visibleType, 
        handleModalType,
    } = props;

    return (
        <div className="mb-[30px] flex w-full flex-col items-end gap-[30px] md:flex-row px-[25px] md:px-[80px] xl:px-[180px]">
            <div className="w-[100%] relative">
                <FormHeader title={t('addArea.area_info')} />
                    <LabelUpInput
                        value={area?.areaName ? t(area?.areaName) : ''}
                        setValue={setValue}
                        name="areaName"
                        star={true}
                        starStyle={
                            selectedLanguage === 'en' 
                                ? `lg:left-[249px] lg:block md:hidden block left-[249px]`
                                : 'left-[270px] lg:block md:hidden block'
                        }
                        register={register}
                        error={errors['areaName' as keyof TOfferAreaSchema]}
                        placeholder={t('addArea.placeholders.area_name')}
                    />
            </div>

            <div className="relative w-[100%]">
                <FormHeader
                    title={t('addArea.type_and_size')}
                    icon={
                        <img
                            src="/assets/icons/error_outline.svg"
                            alt="info"
                            onClick={handleModalType}
                            className="cursor-pointer"
                        />
                    }
                    sx="flex gap-[10px]"
                />

                <LabelUpInput
                    type="dropdown"
                    name="type"
                    value={area?.areaType}
                    star={true}
                    options={types}
                    starStyle={
                        selectedLanguage === 'en'
                            ? `left-[110px]`
                            : 'left-[130px]'
                    }
                    register={register}
                    error={errors['type' as keyof TOfferAreaSchema]}
                    placeholder={t('addArea.placeholders.type')}
                    setValue={setValue}
                />

                {visibleType && <TypeModal />}
            </div>

            <div className="w-[100%]">
                <LabelUpInput
                    name="size"
                    value={area?.areaSize}
                    setValue={setValue}
                    star={true}
                    starStyle={
                        selectedLanguage === 'en'
                            ? `left-[180px] md:hidden lg:left-[180px] lg:block`
                            : `md:hidden left-[200px] lg:block`
                    }
                    register={register}
                    error={errors['size' as keyof TOfferAreaSchema]}
                    placeholder={t('addArea.placeholders.size')}
                />
            </div>
        </div>
    )
}
