import { OfferAreaSchema, TOfferAreaSchema } from '../../../helpers'
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DropZones } from './DropZones';
import { AreaProperties } from './AreaProperties';
import { ProgressBlock } from './ProgressBlock';
import { useOfferForm } from '../useOfferForm';
import { DrawArea } from './DrawArea';
import { AreaInfo } from './AreaInfo';
import { Wrapper } from './Wrapper';
import { Location } from './Location';
import { AreaFor } from './AreaFor';
import { Role } from './Role';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../../components/Loader';
import { useParams } from 'react-router-dom';
import { useDetails } from '../../ProjectDetails/hooks/useDetails';
import { useEffect } from 'react';
import { ConfirmButton } from './ConfirmButton';
import { ManageAreaButton } from './ManageAreaButton';
import { FinishAreaModal } from './FinishAreaModal';
import { BackgroundBlock } from './BackgroundBlock';

export const OfferForm = () => {
    const { t } = useTranslation();
    const { areaId } = useParams();

    const {
        areaDetails,
        areaFiles,
        areaImages,
        getAreaDetails,
        kmlToEdit
    } = useDetails({ areaId });

    const {
        visibleType,
        selectedArea,
        selectedProgress,
        handleCloseProgressModal,
        handleCompleteProcess,
        handleModalType,
        handleSelectProgress,
        handleSelectProperty,
        handleStartProcess,
        handleNotStarted,
        isActive,
        isCompleted,
        filesToUpload,
        error,
        setData,
        loading,
        onSubmit,
        setZoom,
        setFilesToUpload,
        setFilesToDelete,
        setAreaSize,
        mapBoxError,
        size,
        data,
        isCorrectSize,
        handleApproveSize,
        setMapElevation,
        setClickCount,
        showFinishModal
    } = useOfferForm({ area: areaDetails });
    
    const {
        handleSubmit,
        formState: { errors },
        register,
        setValue,
        control
    } = useForm<TOfferAreaSchema>({
        resolver: yupResolver(OfferAreaSchema),
        reValidateMode: 'onChange',
        mode: 'all',
    })

    const watchedRole = useWatch({
        control,
        name: 'role',
        defaultValue: '',
    });

    const watchedCountry = useWatch({
        control,
        name: 'country',
        defaultValue: ''
    });

    const watchedStreet = useWatch({
        control,
        name: 'checked',
        defaultValue: ''
    });

    useEffect(() => {
        if (areaId) {
            getAreaDetails();
        }
    }, [areaId])

    return (
        <div className='relative'>
            {loading && <Loader />}
            <form
                className="flex flex-col items-center"
                onSubmit={handleSubmit(onSubmit)}
            >
                <p className="mb-[30px] px-[25px] text-center text-[24px] font-medium md:mb-[70px] md:text-[28px] md:font-semibold">
                    {t('addArea.form_title')}
                </p>

                <AreaInfo
                    area={areaDetails}
                    register={register}
                    errors={errors}
                    setValue={setValue}
                    visibleType={visibleType}
                    handleModalType={handleModalType}
                />

                <Wrapper />

                <Location
                    register={register}
                    errors={errors}
                    setValue={setValue}
                    area={areaDetails}
                    street={watchedStreet}
                    country={watchedCountry}
                />

                <Wrapper />

                <AreaFor
                    register={register}
                    errors={errors}
                    setValue={setValue}
                    area={areaDetails}
                />

                <Wrapper />

                <Role 
                    register={register} 
                    errors={errors} 
                    role={watchedRole}
                    area={areaDetails}
                    setValue={setValue}
                />

                <Wrapper />

                <DropZones 
                    setFiles={setFilesToUpload} 
                    images={areaImages}
                    files={areaFiles}
                    filesToUpload={filesToUpload}
                    onDelete={setFilesToDelete}
                />

                <Wrapper />

                <ProgressBlock
                    onSelect={handleSelectProgress}
                    selected={selectedProgress}
                    isActive={isActive}
                    isCompleted={isCompleted}
                    onClose={handleCloseProgressModal}
                    onStart={handleStartProcess}
                    onComplete={handleCompleteProcess}
                    onCancel={handleNotStarted}
                />

                <AreaProperties
                    onSelect={handleSelectProperty}
                    selected={selectedArea}
                />

                <DrawArea 
                    error={error} 
                    zoom={areaDetails?.MapboxZoomfactor}
                    data={data}
                    setData={setData} 
                    kml={kmlToEdit} 
                    setZoom={setZoom} 
                    onDataSize={setAreaSize}
                    mapBoxError={mapBoxError}
                    onElevation={setMapElevation}
                    setClickCount={setClickCount}
                />

                <ManageAreaButton areaId={areaId} />
            </form>

            <BackgroundBlock />

            {!isCorrectSize && (
                <ConfirmButton 
                    onClick={handleApproveSize} 
                    size={size}
                />
            )}

            {showFinishModal && (
                <FinishAreaModal />
            )}
        </div>
    )
}
