import { useTranslation } from "react-i18next";
import { ChatInput } from "../../../components/ChatInput";
import { ChatMessaging } from "../../../components/ChatMessaging";
import { Client } from '@twilio/conversations';
import { Flaeche, UserInfo } from "../../../API";
import { ChatHeader } from "./ChatHeader";
import { useChat } from "../hooks/useChat";
import { useNavigate } from "react-router-dom";
import { Links } from "../../../settings";

interface ChatProps {
    onClose: () => void;
    sx?: string;
    chatStyles?: string;
    twilioClient?: Client | null;
    areaId?: string;
    ownerEmail?: string;
    area?: Flaeche | null;
    areaImage?: string;
    areaName?: string | null;
    uniqueName?: string | null;
    user?: UserInfo | null;
    interestedIn?: string;
}

export const Chat = (props: ChatProps) => {
    const { 
        onClose, 
        sx = "fixed bottom-[20px] z-10 sm:right-[20px] px-[10px] flex justify-center sm:justify-end", 
        chatStyles,
        twilioClient,
        areaId,
        area,
        user,
        ownerEmail,
        areaImage,
        areaName,
        uniqueName,
        interestedIn
    } = props;

    const navigate = useNavigate();

    const { t } = useTranslation();
    const {
        messages,
        files,
        error,
        setFiles,
        conversation,
        handleDeleteMessage,
        handleSendMessage,
        isSending
    } = useChat({ 
        twilioClient, 
        uniqueName, 
        areaId, 
        ownerEmail,
        areaName: area?.areaName
    })

    const handleNavigate = () => {
        if (user) {
            return;
        }

        navigate(`${Links.search.index}/${area?.id}`);
    }

    return (
        <div className={`${sx} w-full`}>
            {conversation ? (
                <div className="rounded-[10px] border-[0.5px] border-[#9e9e9e] bg-white h-full">
                    <div className="flex justify-between px-[29px] py-[18px] md:gap-[220px] gap-[50px]">
                        <ChatHeader 
                            area={area} 
                            areaImage={areaImage} 
                            areaName={areaName} 
                            onClick={handleNavigate}
                            user={user}
                            interestedIn={interestedIn}
                        />

                        <img 
                            src="/assets/icons/clear.svg" 
                            alt="cross" 
                            onClick={onClose}
                            className="cursor-pointer"
                        />
                    </div>

                    <hr className="mb-[30px] h-[2px] w-full bg-gray-50" />

                    <ChatMessaging 
                        sx={`h-[250px] md:max-h-[300px] ${chatStyles}`} 
                        messages={messages} 
                        image={areaImage}
                        owner={ownerEmail}
                        onDelete={handleDeleteMessage}
                    />

                    <hr className="mb-[20px] h-[2px] w-full bg-gray-50" />

                    <ChatInput 
                        sx="pb-[25px]"
                        count={files.length}
                        onSend={handleSendMessage}
                        onChange={setFiles}
                        isSending={isSending}
                    />
                </div>
            ) : (
                error ? (
                    <div className="w-full flex justify-center sm:justify-end">
                        <div className="bg-red-700 px-[20px] py-[10px] text-white rounded-[12px] h-fit w-fit">
                            <p>{t('profile.error')}</p>
                        </div>
                    </div>
                ) : (
                    <div className="w-full flex justify-center sm:justify-end">
                        <div className="bg-gray-700 px-[20px] py-[10px] text-white rounded-[12px] h-fit w-fit">
                            <p>{t('profile.wait')}</p>
                        </div>
                    </div>
                )
            )}
        </div>
    )
}
