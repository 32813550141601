import { useEffect, useState } from "react";
import { IPlan, Plan, SelectedPlan } from "../../../types";

export const usePricing = () => {
    const [isMontlyOrYearly, setIsMontlyOrYearly] = useState<Plan>(Plan.Monthly);
    const [isYearly, setIsYearly] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState<SelectedPlan | null>(null);

    const price_finder = (price: number): number => {
        if (isMontlyOrYearly === Plan.Monthly) return price

        switch (price) {
            case 129: 
                return 125;
            case 159:
                return 149;
            case 164:
                return 159;
            default: 
                return 174
        }
    }

    const plans: IPlan[] = [
        {
            icon: 'user',
            name: 'pricing.beginner',
            price: `${price_finder(129)}€ /`,
            yearly: price_finder(129) * 12,
            description: 'pricing.best',
            features: [
                {
                    text: 'pricing.area_500',
                    size: 500,
                    access: true,
                },
                {
                    text: 'pricing.contacts3',
                    access: true,
                },
                {
                    text: 'pricing.email',
                    access: false,
                },
                {
                    text: 'pricing.solar',
                    access: false,
                },
                {
                    text: 'pricing.kml',
                    access: false,
                },
            ],
        },
        {
            icon: 'invester',
            name: 'pricing.advanced',
            price: `${price_finder(159)}€ /`,
            yearly: price_finder(159) * 12,
            description: 'pricing.experienced',
            features: [
                {
                    text: 'pricing.area_1000',
                    size: 1000,
                    access: true,
                },
                {
                    text: 'pricing.contacts10',
                    access: true,
                },
                {
                    text: 'pricing.email',
                    access: true,
                },
                {
                    text: 'pricing.solar',
                    access: true,
                },
                {
                    text: 'pricing.kml',
                    access: false,
                },
            ],
        },
        {
            icon: 'group',
            name: 'pricing.expert',
            price: `${price_finder(164)}€ /`,
            oldPrice: !isYearly ? `${price_finder(189)}` : `${price_finder(174)}`,
            yearly: !isYearly ? 164 * 12 : 159 * 12,
            description: 'pricing.big',
            features: [
                {
                    text: 'pricing.area_10000',
                    size: 2000,
                    access: true,
                },
                {
                    text: 'pricing.contacts50',
                    access: true,
                },
                {
                    text: 'pricing.email',
                    access: true,
                },
                {
                    text: 'pricing.solar',
                    access: true,
                },
                {
                    text: 'pricing.kml',
                    access: true,
                },
            ],
        },
    ]

    useEffect(() => {
        setIsMontlyOrYearly(isYearly 
            ? Plan.Yearly 
            : Plan.Monthly
        )
    }, [isYearly])

    return {
        plans,
        isYearly,
        selectedPackage,
        setIsYearly,
        setSelectedPackage
    }
}