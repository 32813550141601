import { Link } from "react-router-dom";
import { Flaeche } from "../../../API"
import { useTranslation } from "react-i18next";
import { AreaSize } from "../../../components";

interface AreaDescriptionProps {
    area: Flaeche;
}

export const AreaDescription = ({ area }: AreaDescriptionProps) => {
    const { t } = useTranslation();

    return (
        <Link to={`/search/${area.id}`}>
            <div className="flex flex-col gap-[5px] border-b-[1px] border-[#111A2126] px-[15px] py-[12px]">
                <p className="text-[15px] text-[#bdbdbd]">
                    {t('profile.municipality')}
                </p>

                <div className="flex areas-center justify-between">
                    <p className="text-[15px] font-medium">
                        {t(area.address_state) ||
                            (area.address_country && t(area.address_country))}
                    </p>

                    <span className="text-[14px]">
                        {t('search.area')}: {" "}
                        <AreaSize areaDetails={area} />
                    </span>
                </div>
            </div>
        </Link>
    )
}
