interface FormHeaderProps {
    title: string;
    sx?: string;
    icon?: any;
}

export const FormHeader = ({ title, sx, icon }: FormHeaderProps) => {
    return (
        <p
            className={`mb-[20px] text-[12px] font-bold uppercase tracking-[2px] md:mb-[25px] 
                md:text-[16px] md:tracking-[3px] ${sx} w-full`}
        >
            {title}
            {icon}
        </p>
    )
}
