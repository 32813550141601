import { useEffect, useMemo } from 'react';
import { Organization, UserInfo } from '../../../API';
import { Button } from '../../../components';
import { useInvitation } from '../hooks';
import { AcceptInviteModal } from './AcceptInviteModal';
import { useTranslation } from 'react-i18next';
import { NewOwnerModal } from './NewOwnerModal';

interface InvitationProps {
    organization?: Organization | null;
    userCompany?: Organization | null;
    user?: UserInfo | null;
    members?: (UserInfo | null)[];
    onAccept: (organization: Organization) => void;
    onDecline: (organization: Organization) => void;
    onAcceptOwner: (organization: Organization) => void;
    onDeclineOwner: (organization: Organization) => void;
}

export const Invitation = ({
    organization,
    userCompany,
    user,
    members,
    onAccept,
    onDecline,
    onAcceptOwner,
    onDeclineOwner
}: InvitationProps) => {
    const { t } = useTranslation();

    const isTransferOwnerRequest = useMemo(() => {
        return organization?.TransferOwnershipToEMail === user?.email 
    }, [organization, user]);
    
    const {
        error,
        owner,
        openErrorModal,
        setError,
        onApproveNewOwner,
        email,
        setEmail,
        newOwnerError,
        setIsOpenErrorModal,
        handleAccept,
        handleAcceptInvitation,
        handleDeclineInvitation,
        getOrganizationOwner,
    } = useInvitation({
        organization,
        userCompany,
        user,
        members,
        onAccept,
        onDecline,
        isTransferOwnerRequest,
        onAcceptOwner,
        onDeclineOwner
    })

    useEffect(() => {
        getOrganizationOwner()
    }, [organization])

    return (
        <>
            <div key={organization?.id}>
                <p className="mb-[20px]">
                    {owner?.firstName} {owner?.lastName}, {owner?.email} {" "}
                    {isTransferOwnerRequest 
                        ? t('organization.become_owner') 
                        : t('organization.has_invited')
                    }
                </p>

                <div className='flex md:flex-row flex-col md:justify-between gap-[10px]'>
                    <div className="mb-[20px] flex flex-col md:justify-between md:flex-row md:gap-[50px] gap-[15px]">
                        <div className="flex flex-col gap-[10px]">
                            <p className="text-[14px] text-[#9e9e9e]">
                                {t('organization.name')}
                            </p>
                            <p>{organization?.CompanyName}</p>
                        </div>

                        <div className="flex flex-col gap-[10px]">
                            <p className="text-[14px] text-[#9e9e9e]">
                                {t('organization.website')}
                            </p>
                            <p>{organization?.websiteDomain}</p>
                        </div>
                    </div>

                    <div className='flex gap-[10px] md:items-center'>
                        <button
                            className='bg-primary text-white w-fit px-[10px] h-[30px] rounded-[8px] text-[12px]'
                            type="button"
                            onClick={handleAcceptInvitation}
                        >
                            {t('organization.accept')}
                        </button>

                        <button
                            className='text-red-600 w-fit px-[10px] border-red-600 
                                border-[1px] h-[30px] rounded-[8px] text-[12px]'
                            type="button"
                            onClick={handleDeclineInvitation}
                        >
                            {t('organization.decline')}
                        </button>
                    </div>
                </div>

                <hr className="mt-[10px]" />
            </div>

            {error && (
                <NewOwnerModal 
                    email={email}
                    setEmail={setEmail}
                    onError={setError}
                    onApprove={onApproveNewOwner}
                    newOwnerError={newOwnerError}
                />
            )}

            {openErrorModal && !error && (
                <AcceptInviteModal 
                    openModal={openErrorModal}
                    onAccept={handleAccept}
                    onOpenModal={setIsOpenErrorModal}
                />
            )}
        </>
    )
}
