import { useTranslation } from "react-i18next";

export const BwsSection = () => {
    const { t } = useTranslation();

    return (
        <div className="mt-[80px] px-[40px]">
            <p className="mb-[30px] text-[20px] text-secondary md:text-[27px]">
                {t('home.bsw.member')}
            </p>
            <img
                src="/assets/icons/BSWSolarLogo.svg"
                alt="BSW"
                className="mb-[30px] w-full h-[100%]"
            />
            <a
                href="https://www.solarwirtschaft.de/"
                target="_blank"
                className="text-[13px] text-secondary hover:text-primary transition-all"
            >
                www.solarwirtschaft.de
            </a>
        </div>
    )
}
