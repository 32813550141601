import { useCallback, useState } from 'react';
import {
    voltageDefaultValues,
    voltageLayersDefaultValues,
    voltageQueries,
} from '../constants';

export interface VoltageElement {
    type: string;
    id: number;
    bounds: {};
    tags: {};
    nodes: number[];
    geometry: { lat: number; lon: number }[];
}

export const useVoltageElements = () => {
    const [voltageElements, setVoltageElements] = useState<{
        [key: string]: VoltageElement[];
    }>(voltageDefaultValues);
    const [voltageLayers, setVoltageLayers] = useState<{
        [key: string]: boolean;
    }>(voltageLayersDefaultValues);

    const fetchVoltage = useCallback(
        async (voltage: string, bounds: mapboxgl.LngLatBounds) => {
            if (voltage === '380kV (e.g. Germany)') return;

            const [sw, ne] = bounds.toArray();

            const [minVoltage, maxVoltage] = voltageQueries[voltage];

            const queryParts = `
                node["power"="line"]["voltage"~"^(${minVoltage}|${maxVoltage})$"](${sw[1]},${sw[0]},${ne[1]},${ne[0]});
                way["power"="line"]["voltage"~"^(${minVoltage}|${maxVoltage})$"](${sw[1]},${sw[0]},${ne[1]},${ne[0]});
                rel["power"="line"]["voltage"~"^(${minVoltage}|${maxVoltage})$"](${sw[1]},${sw[0]},${ne[1]},${ne[0]});
            `;

            const query = `
            [out:json];
            (
                ${queryParts}
            );
            out geom;
        `;

            try {
                const response = await fetch(
                    'https://overpass-api.de/api/interpreter',
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        },
                        body: new URLSearchParams({ data: query }),
                    }
                );

                const data = await response.json();
                setVoltageElements((prev) => ({
                    ...prev,
                    [voltage]: [...prev[voltage], ...data.elements],
                }));
            } catch (error) {
                console.error(`Error fetching voltage ${voltage}:`, error);
            }
        },
        []
    );

    const fetchGermanyVoltage = useCallback(async (voltage: string) => {
        const queryParts = `
          node["power"="line"]["voltage"="380000"](area.germany);
          way["power"="line"]["voltage"="380000"](area.germany);
      `;

        const query = `
            [out:json];
            area["ISO3166-1"="DE"][admin_level=2]->.germany;

            (
                ${queryParts}
            );
            out geom;
        `;

        try {
            const response = await fetch(
                'https://overpass-api.de/api/interpreter',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: new URLSearchParams({ data: query }),
                }
            );

            const data = await response.json();
            setVoltageElements((prev) => ({
                ...prev,
                '380kV (e.g. Germany)': [...prev[voltage], ...data.elements],
            }));
        } catch (error) {
            console.error(`Error fetching voltage ${voltage}:`, error);
        }
    }, []);

    return {
        voltageElements,
        voltageLayers,
        setVoltageLayers,
        fetchVoltage,
        fetchGermanyVoltage,
    };
};
