import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../hooks";

interface DescriptionProps {
    description: string;
    owner: boolean;
    isVisible: boolean;
}

export const Description = (props: DescriptionProps) => {
    const { description, owner, isVisible } = props;
    const { t } = useTranslation();
    const { isAdmin, isInvestor } = useAuth();

    const [isOpen, setIsOpen] = useState(true);

    const handleOpen = () => {
        setIsOpen(prev => !prev);
    }

    return (
        <div>
            <div 
                className="mb-[15px] flex justify-between font-semibold cursor-pointer" 
                onClick={handleOpen}
            >
                <p>{t('area.desc')}</p>
                <img
                    src="/assets/icons/arrowDown.svg"
                    alt="arrow"
                    className={`cursor-pointer ${isOpen ? 'rotate-180' : ""}`}
                />
            </div>

            {isOpen && (
                description.length < 50 ? (
                    <p className="text-[#9e9e9e]">
                        <span className={`${(!owner && !isInvestor && !isAdmin && !isVisible) 
                            && "text-transparent [text-shadow:_0_0_15px_#9e9e9e] unselectable"}`}
                        >
                            {description}
                        </span>
                    </p>
                ) : (
                    <p className="text-[#9e9e9e]">
                        {description.slice(0, 50)}
                        <span className={`${(!owner && !isInvestor && !isAdmin && !isVisible) 
                            && "text-transparent [text-shadow:_0_0_15px_#9e9e9e] unselectable"}`}
                        >
                            {description.slice(50)}
                        </span>
                    </p>
                )
            )}
        </div>
    )
}
