import { DataTable, Dropdown, Loader } from "../../../components";
import { useTranslation } from "react-i18next";
import { useOrganizationsData } from "../hooks";
import { OrganizationRow } from "./OrganizationRow";
import { filterOptions } from "../utils/options";
import { organizationColumns } from "../utils/columns";

export const OrganizationsDashboard = () => {
  const { t } = useTranslation();
  const { 
    isLoading, 
    setFilterBy, 
    filteredOrganizations
  } = useOrganizationsData();

  return (
    <div>
        <div
            className="mb-[30px] flex w-[275px] w-full flex-col flex-wrap 
            justify-center gap-[10px] md:flex-row md:justify-start"
        >
            <Dropdown
                placeholder={t('admin_dashboard.status')}
                options={filterOptions}
                height="h-[105px]"
                filterStyle="md:w-[275px] w-full py-[11px] rounded-[6px]"
                setFilter={setFilterBy}
                defaultValue={filterOptions[1].label}
            />
        </div>

        {isLoading ? (
            <Loader />
        ) : (
            <DataTable 
                data={filteredOrganizations} 
                columns={organizationColumns} 
                renderBodyCell={el => (
                    <OrganizationRow key={el?.id} organization={el}/>
                )} 
            />
        )}
    </div>
  )
}
