import { useNavigate } from "react-router-dom"
import { Button } from "../../components"
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export const NotFoundPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleNavigate = useCallback(() => {
    navigate('/');
  }, [navigate])

  return (
    <div className="md:p-[60px] p-[30px] flex flex-col items-center justify-center">
        <p className="text-secondary md:text-[70px] text-[30px] text-medium mb-[30px]">
            {t('not_found.text')}
        </p>

        <Button
            width="fit-content"
            color="Secondary"
            sx="px-[20px]"
            textColor="text-white"
            onClick={handleNavigate}
        >
            {t('not_found.button')}
        </Button>
    </div>
  )
}
