import { useTranslation } from 'react-i18next';
import { CallToAction } from '../../../components';
import { ArticleImage } from './Image';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Links } from '../../../settings';

export const FirstArticle = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleNavigate = useCallback(() => {
        navigate(Links.offer.index);
    }, [navigate]);

    return (
        <div className="mb-[20px] flex flex-col gap-[25px] text-[16px] text-[#333] md:text-[18px]">
            <span className="uppercase text-primary">{t('articles.info')}</span>

            <h1 className="text-[25px] font-medium md:text-[35px]">
                {t('article_one.title')}
            </h1>

            <div className="h-[2px] w-[150px] bg-primary" />

            <span className="text-[13px] uppercase text-gray-700 md:text-[16px]">
                {t('articles.article_one.date')}
            </span>

            <p>{t('article_one.p_1')}</p>

            <p>{t('article_one.p_2')}</p>

            <p>{t('article_one.p_3')}</p>

            <ArticleImage
                source="/assets/images/Solaranlage.jpg"
                link="https://unsplash.com/de/fotos/luftaufnahme-des-grunen-rasenfeldes-wahrend-des-tages-rfgsYFb_5ow"
            />

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_one.subtitle')}
            </h2>

            <p>{t('article_one.p_4')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_one.subtitle_2')}
            </h3>

            <p>{t('article_one.p_5')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_one.subtitle_3')}
            </h3>

            <p>{t('article_one.p_6')}</p>

            <p className="font-medium underline">{t('article_one.p_7')}</p>

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_one.subtitle_4')}
            </h2>

            <p>{t('article_one.p_8')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_one.subtitle_5')}
            </h3>

            <p>{t('article_one.p_9')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_one.subtitle_6')}
            </h3>

            <p>{t('article_one.p_10')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_one.subtitle_7')}
            </h3>

            <p>{t('article_one.p_11')}</p>

            <ArticleImage
                source="/assets/images/Solaranlage2.jpg"
                link="https://unsplash.com/de/fotos/blaues-und-weisses-solarpanel-lot-GXiHwHkIdVs"
            />

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_one.subtitle_8')}
            </h2>

            <p>{t('article_one.p_12')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_one.subtitle_9')}
            </h3>

            <p>{t('article_one.p_13')}</p>

            <p className="italic">{t('article_one.list_title')}</p>

            <ul className="flex flex-col gap-[10px] list-disc">
                <li>{t('article_one.point_1')}</li>
                <li>{t('article_one.point_2')}</li>
                <li>{t('article_one.point_3')}</li>
                <li>{t('article_one.point_4')}</li>
                <li>{t('article_one.point_5')}</li>
            </ul>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_one.subtitle_10')}
            </h3>

            <p>{t('article_one.p_14')}</p>

            <p className="italic">{t('article_one.list_title_2')}</p>

            <ul className="flex flex-col gap-[10px] list-disc">
                <li>
                    <b>{t('article_one.points.point_one.bold')}</b>{' '}
                    {t('article_one.points.point_one.text')}
                </li>
                <li>
                    <b>{t('article_one.points.point_two.bold')}</b>{' '}
                    {t('article_one.points.point_two.text')}
                </li>
                <li>
                    <b>{t('article_one.points.point_three.bold')}</b>{' '}
                    {t('article_one.points.point_three.text')}
                </li>
                <li>
                    <b>{t('article_one.points.point_four.bold')}</b>{' '}
                    {t('article_one.points.point_four.text')}
                </li>
            </ul>

            <p>{t('article_one.p_15')}</p>

            <h2 className="text-[23px] font-medium md:text-[27px]">
                {t('article_one.subtitle_11')}
            </h2>

            <p>{t('article_one.p_16')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_one.subtitle_12')}
            </h3>

            <p>{t('article_one.p_17')}</p>

            <h3 className="text-[20px] font-medium md:text-[24px]">
                {t('article_one.subtitle_13')}
            </h3>

            <p>{t('article_one.p_18')}</p>

            <div className="mx-[25px] text-[16px] md:mt-[50px]">
                <CallToAction
                    title={t('home.call_to_action_urgency')}
                    btnTitle={t('buttons.offer_your_space_now')}
                    styles="mb-[40px]"
                    onClick={handleNavigate}
                    tag="p"
                />
            </div>
        </div>
    )
}
