import { useTranslation } from "react-i18next";

interface CompensationItemProps {
    type: string;
    desc: string;
    secondDesc?: string;
    benefit: string;
    image: string;
}

export const CompensationItem = (props: CompensationItemProps) => {
  const { type, desc, secondDesc, benefit, image } = props;

  const { t } = useTranslation();

  return (
    <div className="border-[1px] p-[20px] xl:h-[400px] w-[413px]">
        <img src={image} alt="payment" className="h-[70px] mb-[10px]"/>
        <p className="md:text-[25px] text-[20px] font-bold mb-[20px]">
            {type}
        </p>

        <p className="text-[15px] mb-[10px]">
            {desc}
        </p>

        {secondDesc && (
            <p className="text-[15px] mb-[20px]">
                {secondDesc}
            </p>
        )}

        <p className="text-[15px] text-[#11470E] font-medium">{t('compensation.benefit')}</p>

        <p className="text-[15px] mb-[40px] flex gap-[15px] items-center mb-[60px]">
            {benefit}
        </p>
    </div>
  )
}
