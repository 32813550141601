/** @format */
import { debounce } from 'radash';
import { useState, useEffect, useCallback } from 'react';

export const useResponsive = () => {
    // screen resolutions
    const [state, setState] = useState({
        isMobile: false,
        isTablet: false,
        isDesktop: false,
    });

    useEffect(() => {
        // update the state on the initial load
        onResizeHandler();

        // assign the event
        Setup();

        return () => {
            // remove the event
            Cleanup();
        }
    }, [])

    // update the state on window resize
    const onResizeHandler = () => {
        const isMobile = window.innerWidth <= 768;
        const isTablet = window.innerWidth >= 768 && window.innerWidth <= 990;
        const isDesktop = window.innerWidth > 990;

        setState({ isMobile, isTablet, isDesktop });
    }

    // debounce the resize call
    const debouncedCall = debounce({ delay: 100 }, onResizeHandler);

    // add event listener
    const Setup = useCallback(() => {
        window.addEventListener('resize', debouncedCall, false);
    }, [debouncedCall])

    // remove the listener
    const Cleanup = useCallback(() => {
        window.removeEventListener('resize', debouncedCall, false);
    }, [debouncedCall])

    return state;
}
