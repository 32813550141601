import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Chat } from "../ProjectDetails/components/Chat";
import { ChatInput, ChatMessaging, Loader } from "../../components";
import { ChatInfo, ChatList } from "./components";
import { Client } from '@twilio/conversations';
import { useAuth } from "../../hooks";
import { useAttributes } from "./hooks/useAttributes";
import { useConversations } from "./hooks/useConversations";

interface ChatPageProps {
    client: Client | null;
}

export const ChatPage = ({ client }: ChatPageProps) => {
    const { loggedIn } = useAuth();
    const { t } = useTranslation();

    const {
        conversations,
        messages,
        files,
        setFiles,
        handleCloseChat,
        handleOpenChat,
        selectedConversation,
        handleCloseConversation,
        setSelectedConversation,
        handleDeleteMessage,
        handleSendMessage,
        fetchMessages,
        isSending,
        isLoading
    } = useConversations({ client });

    const {
        user,
        userImage,
        areaDetails,
        imagesArea,
        chatImage,
        getAreaDetails,
        getUserInfo,
        attributes,
        setUser,
        clearCacheImages
    } = useAttributes({ conversation: selectedConversation });

    const interestedIn = useMemo(() => {
        return attributes?.userEmail !== loggedIn?.email ? attributes?.areaId : ''
    }, [selectedConversation]);

    useEffect(() => {
        fetchMessages();

        if (!selectedConversation) {
            return;
        }

        if (attributes?.userEmail === loggedIn?.email) {
            clearCacheImages();
            setUser(null);
            getAreaDetails();

            return;
        }

        clearCacheImages();
        getUserInfo();
    }, [selectedConversation])

    return (
        <div className="px-[25px] pt-[30px] md:pt-[100px] lg:px-[60px] lg:pt-[86px]">
            {process.env.REACT_APP_Deactivate_Chat === '0' && (
                <div className="pb-[70px] lg:pb-[160px]">
                    <p className="mb-[18px] text-[18px] font-medium md:mb-[30px] md:text-[38px] md:font-semibold">
                        {t('profile.chat_history')}
                    </p>

                    {isLoading && <Loader />}

                    {conversations.length > 0 && !isLoading && (
                        <div 
                            className={`relative flex h-[570px] 
                            w-full
                            rounded-[12px] border-[0.5px] border-[#9e9e9e] sm:h-[620px] md:h-[520px] lg:h-[700px]`}
                        >
                            <div className={`overflow-hidden lg:w-[35%] w-full transition-all`}>
                                <ChatList
                                    onOpenChat={handleOpenChat}
                                    conversations={conversations}
                                    selected={selectedConversation}
                                    onSelect={setSelectedConversation}
                                />
                            </div>

                            {selectedConversation && (
                                <Chat
                                    onClose={handleCloseChat}
                                    sx="absolute h-full w-full sm:right-0 lg:hidden"
                                    chatStyles="h-[350px] sm:h-[400px]"
                                    twilioClient={client}
                                    uniqueName={selectedConversation?.uniqueName}
                                    areaImage={chatImage}
                                    areaName={areaDetails 
                                        ? (areaDetails.address_state || areaDetails.address_country) 
                                        : `${user?.firstName} ${user?.lastName}` 
                                    }
                                    area={areaDetails}
                                    user={user}
                                    interestedIn={interestedIn}
                                />
                            )}

                            <div className={`${selectedConversation ? 'lg:w-[65%] lg:flex hidden' : 'hidden'} 
                                flex-col justify-between transition-all`}
                            >
                                <ChatInfo 
                                    image={areaDetails 
                                        ? imagesArea.find(el => el.includes('Screenshot')) 
                                        : userImage || '/assets/icons/profile-icon.png'
                                    }
                                    user={user}
                                    area={areaDetails}
                                    onClose={handleCloseConversation}
                                    interestedIn={interestedIn}
                                />

                                <ChatMessaging
                                    selected={selectedConversation}
                                    messages={messages}
                                    image={chatImage}
                                    onDelete={handleDeleteMessage}
                                    sx="h-[250px] md:h-[500px]"
                                />

                                <ChatInput
                                    sx="py-[25px] border-t-[0.5px] border-[#9e9e9e]"
                                    onSend={handleSendMessage}
                                    onChange={setFiles}
                                    count={files.length}
                                    isSending={isSending}
                                />
                            </div>

                            <div className={`flex flex-col gap-[20px] h-full w-[70%] items-center justify-center 
                                ${selectedConversation ? 'hidden' : 'lg:flex hidden'} `}
                            >
                                <img src="/assets/icons/chat.svg" className="h-[150px]"/>

                                <p className="text-gray-600">
                                    {t('profile.select_chat')}
                                </p>
                            </div>
                        </div>
                    )}

                    {!isLoading && !conversations.length && (
                        <div className="flex justify-center text-medium text-[25px] text-secondary">
                        <p>{t('profile.chat_empty')}</p>
                    </div>
                    )}
                </div>
            )}
        </div>
    )
}
