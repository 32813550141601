import { useTranslation } from 'react-i18next';
import { useUsersData } from '../hooks';
import { DataTable, Dropdown, Loader } from '../../../components';
import { UserRow } from './UserRow';
import { adminUsersOptions } from '../utils/options';
import { usersColumns } from '../utils/columns';
import { useEffect } from 'react';

export const UsersDashboard = () => {
    const { t } = useTranslation();
    const { 
        filteredUsers, 
        setUserType, 
        isLoading,
        getUsers
    } = useUsersData();

    useEffect(() => {
        getUsers({})
    }, [])

    return (
        <div>
            <div
                className="mb-[30px] flex w-[275px] w-full flex-col flex-wrap 
                justify-center gap-[10px] md:flex-row md:justify-start"
            >
                <Dropdown
                    placeholder={t('admin_dashboard.status')}
                    options={adminUsersOptions}
                    height="h-[105px]"
                    filterStyle="md:w-[275px] w-full py-[11px] rounded-[6px]"
                    setFilter={setUserType}
                    defaultValue={adminUsersOptions[0].label}
                />
            </div>

            {isLoading ? (
                <Loader />
            ) : (
                <DataTable 
                    data={filteredUsers} 
                    columns={usersColumns} 
                    renderBodyCell={(user) => (
                        <UserRow 
                            user={user} 
                            key={user?.sub} 
                        />
                    )} 
                />
            )}
        </div>
    )
}
