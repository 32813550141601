import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { 
    Flaeche, 
    GetUserInfoBySubQuery, 
    UpdateFlaecheMutation, 
    UserInfo 
} from "../../../API";
import { useNavigate } from "react-router-dom";
import i18n from "../../../i18n";
import { Links } from "../../../settings";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { updateFlaeche } from "../../../graphql/mutations";
import { getUserInfoBySub } from "../../../graphql/queries";
import { useLanguage } from "../../../hooks";

interface Options {
    area?: Flaeche | null;
    onDelete?: (id: string) => Promise<void>;
}

export const useAreaHandling = ({ area, onDelete }: Options) => {
    const [owner, setOwner] = useState<UserInfo | null>(null);
    const [isOpenDelete, setIsOpenDelete] = useState(false);
    const [selArea, setSelArea] = useState<any>(null);
    const [idModalOpen, setIdModalOpen] = useState(false);
    const [passedId, setPassedId] = useState('');
    const [deleteError, setDeleteError] = useState(false);
    const [isMaximumNumber, setIsMaximumNumber] = useState(false);

    const navigate = useNavigate();
    const { selectedLanguage } = useLanguage({ i18n });

    const handleNavigate = () => {
        navigate(`${Links.search.index}/${area?.id}`);
    }

    const handleEditArea = () => {
        navigate(`${Links.offer.index}/${area?.id}`);
    }

    const handleOpenDelete = () => {
        setIsOpenDelete(true);
    }

    const handleCloseDelete = () => {
        setIsOpenDelete(false);
    }

    const handleCancelDelete = () => {
        setIdModalOpen(false);
        setIsOpenDelete(false);
        setDeleteError(false);
        setPassedId('');
    }

    const changeAreaStatus = async () => {
        const disabledArea = {
            disabled: !selArea?.disabled,
            id: area?.id || '',
        }
    
        try {
          const { data } = await API.graphql<GraphQLQuery<UpdateFlaecheMutation>>(
            graphqlOperation(updateFlaeche, { input: disabledArea })
          );

          setSelArea(data?.updateFlaeche);
        } catch (error: any) {
          console.log("ERROR", error);
        }
    }

    const approveDeleteArea = () => {
        setIdModalOpen(true);
    }

    const getOwner = async () => {
        try {
            if (area?.ownerSub) {
                const { data } = await API.graphql<GraphQLQuery<GetUserInfoBySubQuery>>(
                    graphqlOperation(getUserInfoBySub, {
                        sub: area?.ownerSub
                }))
        
                if (data?.getUserInfoBySub?.items[0]?.email) {
                    setOwner(data?.getUserInfoBySub?.items[0]);
                }
            } 
        } catch (error: any) {
            console.log("ERROR", error);
            return null;
        }
    }

    const handleDeleteArea = async () => {
        if (!area?.id || passedId !== area?.id) {
            setDeleteError(true)

            return;
        }

        if (!onDelete) return;

        await onDelete(area.id);
    }

    const handleStatusChange = async (e: ChangeEvent<HTMLInputElement>) => {
        setIsMaximumNumber(e.target.checked);

        if (!area) return;

        try {
            const editingArea = {
                id: area.id,
                MaximumNumberOfContactsAllowed: e.target.checked,
            };

            await API.graphql<GraphQLQuery<UpdateFlaecheMutation>>(
                graphqlOperation(updateFlaeche, {
                    input: editingArea
                })
            );
        } catch (error) {
            console.log("Error:", error)
        }
    }

    const formattedDate = useMemo(() => {
        if (!area) return;

        const date = new Date(area.createdAt);
        const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;

        return formattedDate;
    }, [area])

    useEffect(() => {
        setSelArea(area);
    }, [])

    useEffect(() => {
        getOwner();
    }, [])

    useEffect(() => {
        if (area?.MaximumNumberOfContactsAllowed) {
            setIsMaximumNumber(true);
        }
    }, [area])

    return {
        selArea,
        owner,
        idModalOpen,
        isMaximumNumber,
        setIdModalOpen,
        handleCancelDelete,
        passedId,
        setPassedId,
        approveDeleteArea,
        deleteError,
        isOpenDelete,
        selectedLanguage,
        handleCloseDelete,
        handleEditArea,
        handleNavigate,
        handleOpenDelete,
        changeAreaStatus,
        setDeleteError,
        formattedDate,
        handleStatusChange,
        handleDeleteArea
    }
}