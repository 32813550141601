import { EnergyProduction } from './EnergyProduction';
import { Buttons } from './Buttons';
import { Contact } from './Contact';
import { RatingBlock } from './Rating';
import { ProjectSlider } from './ProjectSlider';
import { AreaSize, Button } from '../../../components';
import { AboutArea } from './AboutArea';
import { Flaeche, FlaecheRating, UserInfo } from '../../../API';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../hooks';

interface AreaDetailsProps {
    areaDetails?: Flaeche | null;
    isOwner: boolean;
    handleNavigateToEdit: () => void;
    areaFiles: string[];
    areaImages: string[];
    isVisible: boolean;
    isOpenContacts: boolean;
    isJoined: boolean;
    owner: UserInfo | null;
    ratedArea: FlaecheRating | null;
    handleOpenDetails: () => Promise<void>;
    handleOpenChat: () => void;
    handleRateArea: (rateSize: number, rateGrid: number, ratePr: number) => void;
}

export const AreaDetails = ({
    areaDetails,
    areaFiles,
    areaImages,
    isOwner,
    isVisible,
    isOpenContacts,
    isJoined,
    ratedArea,
    owner,
    handleOpenChat,
    handleRateArea,
    handleOpenDetails,
    handleNavigateToEdit
}: AreaDetailsProps) => {
    const { t } = useTranslation();
    const { isAdmin, isInvestor } = useAuth();

    return (
        <div
            className="mb-[70px] flex flex-col items-center gap-[20px] 
                    lg:mb-[70px] xl:flex-row xl:items-start xl:gap-[53px]"
        >
            {areaDetails && (
                <div className="flex flex-col items-center lg:hidden">
                    <h2 className="mb-[15px] text-[24px] font-semibold md:text-[30px]">
                        {t(areaDetails?.areaType)
                            ?.slice(0, 1)
                            ?.toUpperCase() + t(areaDetails?.areaType).slice(1)}
                    </h2>

                    <p className="text-[14px]">
                        {t('search.area')}:{' '}
                        <AreaSize areaDetails={areaDetails} />
                    </p>
                </div>
            )}

            <ProjectSlider images={areaImages} />

            {(isOwner || isAdmin) && (
                <Button
                    color="Primary"
                    textColor="text-white"
                    sx="mb-[20px] lg:hidden block"
                    onClick={handleNavigateToEdit}
                >
                    {t('profile.edit')}
                </Button>
            )}

            <div className="w-full">
                <AboutArea
                    area={areaDetails}
                    files={areaFiles}
                    isOwner={isOwner}
                    isOpenContacts={isVisible}
                />

                {areaDetails?.PvGISCalcOutputperMonthE_y && (
                    <>
                        <EnergyProduction
                            data={areaDetails?.PvGISCalcOutputperMonthE_y}
                            total={
                                areaDetails.PvGISCalcOutputTotalE_y &&
                                Math.round(areaDetails?.PvGISCalcOutputTotalE_y)
                            }
                        />

                        <hr className="my-[25px] mb-[30px] h-[2px] w-full bg-gray-50" />
                    </>
                )}

                <Buttons
                    isOwner={isOwner}
                    onOpenContacts={handleOpenDetails}
                    isOpen={isOpenContacts}
                    isJoined={isJoined}
                    isVisible={isVisible}
                />

                <Contact
                    owner={isOwner}
                    isOpenContacts={isOpenContacts}
                    onOpen={handleOpenChat}
                    contacts={owner}
                    isVisible={isVisible}
                />

                <hr className="my-[25px] mb-[30px] h-[2px] w-full bg-gray-50" />

                {isInvestor && !isOwner && (
                    <RatingBlock
                        onRating={handleRateArea}
                        ratedArea={ratedArea}
                    />
                )}
            </div>
        </div>
    )
}
