import { Conversation } from "@twilio/conversations";
import { useChatClient } from "./useChatClient";
import { useContext } from "react";
import { MessagesContext } from "../contexts";

export const useUnreadMessages = () => {
    const { client } = useChatClient();
    const { setUnreadCount } = useContext(MessagesContext);

    const getUnreadMessages = async () => {
        if (!client) {
            return;
        }
    
        try {
            const conversations = await client?.getSubscribedConversations();
    
            if (!conversations?.items || conversations.items.length === 0) {
                return 0;
            }
    
            const unreadMessages = await Promise.all(conversations.items.map(async (item: Conversation) => {
                const count = await item?.getUnreadMessagesCount();
                return count ? count : 0;
            }));
    
            const unreadCount = unreadMessages.reduce((acc, el) => acc + el, 0);
    
            setUnreadCount(unreadCount);
        } catch (error) {
            console.error("Error fetching unread messages:", error);
        }
    };
    

    return {
        getUnreadMessages
    }
}