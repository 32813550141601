import { useTranslation } from "react-i18next";
import { usePageDescription } from "../../hooks";
import { Helmet } from "react-helmet";

export const GetStartedInvestors = () => {
  const { t } = useTranslation();

  usePageDescription({ 
    title: t('title.invest'),
    description: t('description.invest')
  });

  return (
    <>
        <Helmet>
            <meta name='title' content={t('title.invest')} />
            <meta name='description' content={t('description.invest')} />
        </Helmet>
        <div className="flex flex-col py-[50px] md:px-[100px] px-[25px]">
        <h1 className="md:text-[38px] text-[25px] font-bold mb-[10px] text-secondary">
            {t('investors_get_started.title')}
        </h1>

        <p className="md:text-[27px] text-[20px] mb-[20px] font-bold">
            {t('investors_get_started.join')}
        </p>

        <p className="text-[17px] mb-[20px]">
            {t('investors_get_started.desc')}
        </p>

        <div className="w-full">
            <p className="text-[25px] font-bold text-secondary mb-[20px]">
                {t('investors_get_started.how')}
            </p>

            <p className="md:text-[20px] mb-[15px]">
                {t('investors_get_started.becoming')}
            </p>

            <p className="text-[20px] font-bold text-secondary mb-[40px]">
                1. {t('investors_get_started.point_one')} {" "} 
                <span className="font-normal text-black">{t('investors_get_started.send')}</span> {" "}
                <a href="mailto:info@enlapa.de" className="text-primary">info@enlapa.de.</a>
            </p>

            <p className="text-[20px] font-bold text-secondary mb-[15px]">2. {t('investors_get_started.point_two')}</p>

            <div className="flex flex-col gap-[15px] md:text-[20px] mb-[30px]">
                <li>{t('investors_get_started.option_one')}</li>
                <li>{t('investors_get_started.option_two')}</li>
                <li>{t('investors_get_started.option_three')}</li>
                <li>{t('investors_get_started.option_four')}</li>
                <li>{t('investors_get_started.option_five')}</li>
            </div>

            <p className="text-[25px] font-bold text-secondary mb-[20px]">
                {t('investors_get_started.criteria')}
            </p>

            <p className="text-[20px] font-bold text-secondary mb-[15px]">
                {t('investors_get_started.criteria_desc')}
            </p>

            <div className="flex flex-col gap-[15px] md:text-[20px] mb-[30px]">
                <li>{t('investors_get_started.criteria_option_one')}</li>
                <li>{t('investors_get_started.criteria_option_two')}</li>
                <li>{t('investors_get_started.criteria_option_three')}</li>
            </div>

            <p className="text-[25px] font-bold text-secondary mb-[20px]">
                {t('investors_get_started.steps')}
            </p>

            <p className="mb-[30px]">
                {t('investors_get_started.steps_desc')}
            </p>

            <p className="text-[25px] font-bold text-secondary mb-[20px]">
                {t('investors_get_started.commitment')}
            </p>

            <p className="mb-[30px]">
                {t('investors_get_started.commitment_desc')}
            </p>
        </div>
    </div>
    </>

  )
}
